<div class="mb-3" [formGroup]="group">
    <label for="{{id}}" class="form-label">{{label}}
        <fa-icon 
        *ngIf="group && group.controls[controlName]?.errors?.required" 
        [icon]="iconRequired" 
        size="xs" 
        [styles]="{'color': 'red'}" 
        [classes]="['ms-1']">
    </fa-icon>
    </label>
    <input 
    class="form-control" 
    type="file" 
    [formControlName]="controlName"
    id="{{id}}">
    <p class="mb-2">{{desc}}</p>
  </div>
