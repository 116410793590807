import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TableColumn } from '@p3solved/p3solved-ui';
import { MenuItem } from 'primeng/api';
import { PortfolioItem } from 'src/app/models/customer-item';
import { LookupModel } from 'src/app/models/lookup-model';
import { OfferProductType } from 'src/app/models/pricing-offer';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { v4 as uuidv4 } from 'uuid';
declare var require: any;

@Component({
  selector: 'app-partner-lead',
  templateUrl: './partner-lead.component.html',
  styleUrls: ['./partner-lead.component.scss']
})
export class PartnerLeadComponent implements OnInit {

  partnerGuid = '';
  leadForm: FormGroup = {} as FormGroup;
  leadFormLoaded: boolean = false;
  procedureForm: FormGroup = {} as FormGroup;
  procedureFormLoaded: boolean = false;
  acceptDisclosure = {
    AgreeToPrivacyPolicy: '',
    AgreeToServicingComms: '',
    AgreeToCreditCheckAuthorization: '',
    AgreeToEsignConsent: '',
    AgreeDateTime: ''
  };
  predefined = new FormControl('0');
  showEdit = false;
  bcItems: MenuItem[] = [{ label: 'Partner Integration Dashboard  >' }];
  columns: TableColumn[] = [];
  showDetails = {
    show: false,
    post: '',
    response: ''
  };
  lookups: any = {};
  allOfferProductTypes: OfferProductType[] =[];
  offerProductTypes: OfferProductType[] =[];
  customers: any[] = [{}];
  first: number = 0;
  requests: any[] = [];
  selected: any = {};
  loading = false;
  portfolioItem: PortfolioItem | null | undefined;
  filterFields: string[] = ['PostDate', 'CustomerName', 'StartURL', 'template', 'LoanStatusDescription'];
  disclosures: LookupModel[] = [{ id: '1', desc: 'Agreed to Disclosures' }, { id: '0', desc: 'Still need to see Disclosures' }];
  trueFalse: LookupModel[] = [{ id: '1', desc: 'True' }, { id: '0', desc: 'False' }];
  languages: LookupModel[] = [{ id: 'EN', desc: 'EN' }, { id: 'ES', desc: 'ES' }];
  appLinks: LookupModel[] = [{ id: 'Link', desc: 'Link' }, { id: 'Device', desc: 'Device' }];
  constructor(private api: ApiService,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    private portfolioService: PortfolioService) { }

  ngOnInit(): void {
    this.initColumns();
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      this.getRequests();
      this.getLookups(null, true);
      this.getCustomers();
      this.getAllOfferProducts();
    });
  }

  getRequests() {
    if (this.portfolioItem && this.portfolioItem.customerGuid) {
      this.loading = true;
      this.api.get(`PartnerIntegration/requests/${this.portfolioItem.customerGuid}`).subscribe(data => {
        this.requests = data;
        this.requests.forEach((item: any) => {
          item.offers = this.hasMultipleOffer(item);
        })
        this.loading = false;
      });
    } else {
      //toast error?
    }
  }

  getLookups(providerGuid: any, init: boolean) {
    var g = providerGuid || this.portfolioItem?.customerGuid;
    this.api.get(`PartnerIntegration/lookups/${g}`).subscribe(data => {
      this.lookups = data;
      this.lookups.predefinedLeads?.unshift({ id: '0', desc: '--Select (Reset Form)--' });
      if (init) {
        this.initForm(null);
        this.initProcedure(null);
      }
    });
  }

  getCustomers() {
    this.api.get(`PartnerIntegration/select-customers/${this.portfolioItem?.customerGuid}`).subscribe(data => {
      this.customers = data;
    });
  }

  getColumns(): TableColumn[] {
    var cols = this.columns.filter(x => x.show);
    return cols;
  }

  initForm(lead: any) {
    var leadJson = lead && lead.postLeadJson ? JSON.parse(lead.postLeadJson) : {};
    var info = leadJson?.LeadInformation;
    var contact = info?.LeadContactInfo;
    var loan = info?.LoanRequest;

    this.leadForm = this.fb.group({
      PartnerGUID: [info?.PartnerGUID || '', Validators.required],
      BranchLocationGUID: [info?.BranchLocationGUID, Validators.required],
      TrackingGUID: [uuidv4()],
      OfferProductTypeGuid: [info?.OfferProductTypeGuid, Validators.required],
      LeadGuid: [contact?.LeadGuid || uuidv4()],
      AgreeToSignDisclosures: ['0'],
      LeadPatientID: [info?.LeadPatientID],
      AssignTo: [''],
      SendEmail: ['1'],
      SendSMS: ['1'],
      SendAppLink: [info?.SendAppLink || 'Link'],
      Language: [info?.Language || 'EN'],
      LeadFirstName: [contact?.LeadFirstName, Validators.required],
      LeadLastName: [contact?.LeadLastName, Validators.required],
      LeadDateOfBirth: [contact?.LeadDateOfBirth],
      LeadAddress: [contact?.LeadAddress, Validators.required],
      LeadAddress2: [contact?.LeadAddress2],
      LeadCity: [contact?.LeadCity, Validators.required],
      LeadState: [contact?.LeadState, Validators.required],
      LeadZip: [contact?.LeadZip, Validators.required],
      LeadEmailAddress: [contact?.LeadEmailAddress, Validators.required],
      LeadPhone: [contact?.LeadPhone, Validators.required],
      LeadSSN: [contact?.LeadSSN],
      RequestedAmount: [loan?.RequestedAmount || '0'],
      LoanType: [loan?.LoanType || 'I'],
      PartnerName: [info?.PartnerName],
      PartnerAddress1: [info?.PartnerAddress1],
      PartnerAddress2: [info?.PartnerAddress2],
      PartnerCity: [info?.PartnerCity],
      PartnerState: [info?.PartnerState],
      PartnerZipCode: [info?.PartnerZipCode],
      PartnerVertical: [info?.PartnerVertical]
    });

    this.leadFormLoaded = true;
  }

  onSsnBlur(val: string){
    if (val){
      var v = val.toString().replace(/\D/g, '');
      this.leadForm.patchValue({LeadSSN: val});
    }
  }

  getFormData() {
    var d = new Date();
    var amt = this.leadForm.value.RequestedAmount || '0'.toString().replace(',', '');
    var data = this.leadForm.value;
    var formData = {
      "LeadInformation": {
        ...this.acceptDisclosure,
        "SendAppLink": this.leadForm.value.SendAppLink,
        "SendEmail": this.leadForm.value.SendEmail,
        "SendSMS": this.leadForm.value.SendSMS,
        "Language": this.leadForm.value.Language,
        "PartnerGUID": this.partnerGuid,
        "BranchLocationGUID": this.leadForm.value.BranchLocationGUID,
        "TrackingGUID": uuidv4(),
        "OfferProductTypeGuid": this.leadForm.value.OfferProductTypeGuid,
        "LeadPatientID": this.leadForm.value.LeadPatientID,
        "RequestDate": d.toLocaleDateString(),
        "AssignTo": this.leadForm.value.AssignTo,
        "PartnerName":data.PartnerName,
        "PartnerAddress1":data.PartnerAddress1,
        "PartnerAddress2":data.PartnerAddress2,
        "PartnerCity":data.PartnerCity,
        "PartnerState":data.PartnerState,
        "PartnerZipCode":data.PartnerZipCode,
        "PartnerVertical":data.PartnerVertical,
        "LeadContactInfo": {
          "LeadGuid": uuidv4(),
          "LeadFirstName": this.leadForm.value.LeadFirstName,
          "LeadLastName": this.leadForm.value.LeadLastName,
          "LeadDateOfBirth": this.leadForm.value.LeadDateOfBirth,
          "LeadAddress": this.leadForm.value.LeadAddress,
          "LeadAddress2": this.leadForm.value.LeadAddress2,
          "LeadCity": this.leadForm.value.LeadCity,
          "LeadState": this.leadForm.value.LeadState,
          "LeadZip": this.leadForm.value.LeadZip,
          "LeadEmailAddress": this.leadForm.value.LeadEmailAddress,
          "LeadPhone": this.leadForm.value.LeadPhone,
          "LeadSSN": this.leadForm.value.LeadSSN && this.leadForm.value.LeadSSN.replace('-', '').replace('-', ''),
        },
        "PatientInformation": {
          "PatientGuid": uuidv4(),
          "PatientRelationship": "SELF",
          "IsPatientSameAsLead": "1",
          "PatientFirstName": this.leadForm.value.LeadFirstName,
          "PatientLastName": this.leadForm.value.LeadLastName,
        },
        "LoanRequest": {
          "RequestedAmount": amt,
          "LoanType": "I",
          "Procedures": {
            "ProcedureService": [{
              "ProcedureId": "1",
              "ProcedureDate": this.procedureForm.value.ProcedureDate,
              "ProcedureAmount": amt,
              "ProcedureName": this.procedureForm.value.ProcedureName,
              "Doctor": this.procedureForm.value.Doctor,
            }]
          }
        }
      }
    };

    return formData;
  }

  initProcedure(lead: any) {
    var leadJson = lead && lead.postLeadJson ? JSON.parse(lead.postLeadJson) : {};
    var info = leadJson?.LeadInformation;
    var procedure = info?.LoanRequest?.Procedures?.ProcedureService;
    let procName = procedure?.ProcedureName;
    if (!procName && this.lookups.providerProducts.length == 2) procName = this.lookups.providerProducts[1].id;

    this.procedureForm = this.fb.group({
      ProcedureId: [procedure?.ProcedureId || '1', Validators.required],
      ProcedureDate: [procedure?.ProcedureDate],
      ProcedureAmount: [procedure?.ProcedureAmount],
      ProcedureName: [procName, Validators.required],
      Doctor: [procedure?.Doctor]
    });
    this.procedureFormLoaded = true;
  }

  initColumns() {
    this.columns = [
      {
        field: 'CustomerName',
        header: 'Customer',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'PostDate',
        header: 'Post Date',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'TrackingNumber',
        header: 'Tracking Number',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'OfferAccepted',
        header: 'Offer Accepted',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: '',
        header: 'Loan App',
        clickable: true,
        show: true,
        sortable: false,
        class: null
      },
      {
        field: 'StartURL',
        header: '',
        clickable: true,
        show: false,
        sortable: false,
        class: null
      },
      {
        field: 'PostData',
        header: '',
        clickable: true,
        show: false,
        sortable: false,
        class: null
      },
      {
        field: 'ResponseData',
        header: '',
        clickable: true,
        show: false,
        sortable: false,
        class: null
      },
      {
        field: '',
        header: 'Details',
        clickable: true,
        show: true,
        sortable: false,
        class: null
      },
      {
        field: 'LoanStatusDescription',
        header: 'Loan App Status',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      }
    ];
  }

  addPredefined() {
    this.router.navigate(['/partner/edit-predefined']);
  }

  editPre() {
    this.router.navigate(['/partner/edit-predefined/' + this.predefined.value]);
  }

  showDetailsData(rowData: any) {
    const formatter = require('xml-formatter');
    this.selected = rowData;
    var xml = this.decodeEntities(rowData.PostData);
    this.showDetails.post = formatter(xml);
    this.showDetails.response = formatter(this.decodeEntities(rowData.ResponseData));
    this.showDetails.show = true;
  }

  isMultipleOffer(rowData: any) {
    if (rowData && rowData.offers && rowData.offers.length && rowData.offers.length > 1) {
      return true;
    }
    return false;
  }

  decodeEntities(encodedString: string) {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    var translate: any = {
      nbsp: " ",
      amp: "&",
      quot: "\"",
      lt: "<",
      gt: ">"
    };
    return encodedString.replace(translate_re, function (match, entity: string) {
      return translate[entity];
    }).replace(/&#(\d+);/gi, function (match, numStr) {
      var num = parseInt(numStr, 10);
      return String.fromCharCode(num);
    });
  }

  predefinedSelected(ev: any) {
    this.showEdit = ev && ev != '0';
    this.predefined.setValue(ev);
    if (ev && ev != '0') {
      this.api.get(`PartnerIntegration/predefinedLead/${this.portfolioItem?.customerGuid}/${ev}`).subscribe(data => {
        if (data != null) {
          this.initForm(data);
        }
      });
    } else {
      this.initForm(null);
    }
  }

  partnerSelected(ev: any) {
    if (this.partnerGuid != ev) {
      this.partnerGuid = ev;
      this.getLookups(ev, false);
      this.offerProductTypes = [];
    }
  }

  getAllOfferProducts() {
    let offerSub = this.api.get(`PartnerIntegration/all-offer-products/${this.portfolioItem?.customerGuid}`)
    .subscribe({
      next: (offerProds: OfferProductType[]) => { 
        this.allOfferProductTypes = offerProds;
      },
      error: (err: any) => {
        this.allOfferProductTypes = [];
        console.error(err);
      },
      complete: () => { offerSub.unsubscribe(); }
    })
  }

  branchSelected(branchGuid: string) {
    let prodSub = this.api.get(`PartnerIntegration/offer-products/${this.portfolioItem?.customerGuid}/${branchGuid}`)
    .subscribe({
      next: (offerProds: OfferProductType[]) => { 
        if (offerProds && offerProds.length > 0) this.offerProductTypes = offerProds;
        else this.offerProductTypes = this.allOfferProductTypes;
      },
      error: (err: any) => {
        this.offerProductTypes = [];
        this.toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get Offer Product Types based on Branch selected. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { prodSub.unsubscribe(); }
    });
  }

  postLead() {
    if (this.leadForm.valid && this.procedureForm.valid) {
      if (this.leadForm.value.AgreeToSignDisclosures === '1') {
        this.acceptDisclosure.AgreeDateTime = new Date().toISOString();
        this.acceptDisclosure.AgreeToPrivacyPolicy = 'true';
        this.acceptDisclosure.AgreeToServicingComms = 'true';
        this.acceptDisclosure.AgreeToEsignConsent = 'true';
        this.acceptDisclosure.AgreeToCreditCheckAuthorization = 'true';
      } else {
        this.acceptDisclosure.AgreeToPrivacyPolicy = 'false';
        this.acceptDisclosure.AgreeToServicingComms = 'false';
        this.acceptDisclosure.AgreeToEsignConsent = 'false';
        this.acceptDisclosure.AgreeToCreditCheckAuthorization = 'false';
      }

      var lead = this.getFormData();
      var json = JSON.stringify(JSON.stringify(lead));
      var path = `app/submit-app/${this.portfolioItem?.customerGuid}/${this.leadForm.value.BranchLocationGUID}`;
      this.api.post(path, json).subscribe(data => {
        if (data) {
          var desc = data.COACK?.ERRSTATUS?.ERRORDESCRIPTION;
          if (desc) {
            if (desc.indexOf('Error') !== -1) {
              this.toast.add({ severity: 'error', summary: 'Error', detail: desc });
            } else {
              this.toast.add({ severity: 'success', summary: 'Success', detail: `Lead ${this.leadForm.value.LeadGuid} posted successfully. If it does not immediately populate in the grid below, please wait a minute and then refresh` });
            }
          } else {
            this.toast.add({ severity: 'error', summary: 'Error', detail: 'ERROR Posting Lead, API has error response, please contact admin!' });
          }

        } else {
          this.toast.add({ severity: 'error', summary: 'Error', detail: 'ERROR Posting Lead, empty API response, please contact admin!' });
        }
      },
        (err: any) => {
          console.error(err);
          this.toast.add({ severity: 'error', summary: 'Error', detail: 'ERROR Posting Lead, API has error response, please contact admin!' });
        });

    } else {
      this.leadForm.markAsTouched();
      this.procedureForm.markAsTouched();
      this.leadForm.markAllAsTouched();
      this.procedureForm.markAllAsTouched();
      this.toast.add({ severity: 'error', summary: 'Error', detail: 'Please emter all the required fields.' });
    }
  }

  hasMultipleOffer(rowData: any) {
    if (rowData && rowData.MultipleOffers) {
      var json = JSON.parse(rowData.MultipleOffers);
      if (json && json.length && json.length > 1) {
        json.forEach((item: any) => {
          item.desc = `Offer ${item.OfferOrder} ($${item.LoanAmount})`;
        });
        return json;
      }
    }
    return [];
  }

  onOfferChange(ev: any) {
    window.open(ev.target.value, "_blank");
  }

}
