import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CustomGridComponent } from 'src/app/components/custom-grid/custom-grid.component';
import { PortfolioItem } from 'src/app/models/customer-item';
import { OfferMatrix } from 'src/app/models/decision/offer-matrix';
import { OfferMatrixLookups } from 'src/app/models/decision/offer-matrix-lookups';
import { OfferMatrixRow, OfferMatrixRowDB, OfferMatrixRowUpload } from 'src/app/models/decision/offer-matrix-row';
import { TestOfferReturn } from 'src/app/models/decision/test-offer';
import { MatrixRowMarketingFormGroup, OfferMatrixNameFormGroup, OfferMatrixRowFormGroup, OfferMatrixTestFormGroup } from 'src/app/models/form-groups';
import { NameValuePair } from 'src/app/models/name-value-pair';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NavService, NavSettings } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-decision-offer-matrix',
  templateUrl: './decision-offer-matrix.component.html',
  styleUrls: ['./decision-offer-matrix.component.scss']
})
export class DecisionOfferMatrixComponent implements OnInit, AfterViewInit, OnDestroy {

  subscriptions: Subscription[] = [];
  lookups: OfferMatrixLookups = {} as OfferMatrixLookups;

  loading: boolean = true;
  hasError: boolean = false;
  showMatrix: boolean = false;
  showMatrixEdit: boolean = false;
  showMatrixRowEdit: boolean = false;
  showCopyMatrix: boolean = false;
  showTestMatrix: boolean = false;
  disableMinPayment: boolean = false;

  newOfferMatrixName: string | null = null;

  leftNavExpanded: boolean = false;
  bcItems: MenuItem[] = [];
  selectedMatrix: OfferMatrix | null = null;
  portfolio: PortfolioItem | null = null;
  deOfrMtxDialKey: string = "DecisionOfferMatrixComponentDialogKey";
  matrixEditTitle: string = "Add";
  matrixEditRowTitle: string = "Add";

  matrixNameForm: FormGroup<OfferMatrixNameFormGroup> = {} as FormGroup<OfferMatrixNameFormGroup>;
  matrixNameFormLoaded: boolean = false;

  matrixRowForm: FormGroup<OfferMatrixRowFormGroup> = {} as FormGroup<OfferMatrixRowFormGroup>;
  matrixRowFormLoaded: boolean = false;
  selectedMatrixRow: OfferMatrixRow | null = null;

  testOfferForm: FormGroup<OfferMatrixTestFormGroup> = {} as FormGroup<OfferMatrixTestFormGroup>
  testOfferFormLoaded: boolean = false;
  testOfferResults: TestOfferReturn[] =[];
  showTestMatrixResults: boolean = false;
  testOfferError: boolean = false;
  first: number = 0;

  @ViewChild('cgOfrMtx') cgOfrMtx: CustomGridComponent = {} as CustomGridComponent;
  @ViewChild('cgOfrMtxRow') cgOfrMtxRow: CustomGridComponent = {} as CustomGridComponent;

  downPayTypes: NameValuePair[] = [
    { name: 'Percent (%)', value: '%' },
    { name: 'Amount ($)', value: '$' },
    { name: 'Equal Payment (=)', value: '=' }
  ];

  private parser = new DOMParser();
  private readonly pageBaseBc: string = "Offer Matrixes";
  private readonly pageAddBc: string = "Offer Matrix Add";
  private readonly pageEditBc: string = "Offer Matrix Edit";
  private readonly pageAddRowBc: string = "Offer Option Add";
  private readonly pageEditRowBc: string = "Offer Option Edit";
  private readonly pageNavId: string = "DecisionEngine.OfferMatrix";

  constructor(
    private apiService: ApiService,
    private portfolioService: PortfolioService,
    private toastService: ToastService,
    private confirmService: ConfirmationService,
    private navService: NavService,
    private formBuilder: FormBuilder,
    private breadCrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.navService.leftNavExpanded$.subscribe((isExpanded: boolean) => {
        this.leftNavExpanded = isExpanded;
      }),
      this.navService.leftNaveSelection$.subscribe((navObj: NavSettings) => { 
        if (navObj.navId && navObj.navId == this.pageNavId) {          
          this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
        }
      })
    );

    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Decision Engine', routerLink: ['/decision/dashboard'], command: () => { this.navService.setLeftNavSelection('DecisionEngine.Dashboard'); } },
      { label: this.pageBaseBc }
    ];
    this.loading = false;
    this.showMatrix = true;
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(    
      this.portfolioService.portfolio$.subscribe(p => {
        this.portfolio = p;
        this.lookups = {} as OfferMatrixLookups;
        this.getOfferMatrixLookups();
        this.cgOfrMtx.setCustomerInfo(p?.customerGuid ?? null, null);
        this.cgOfrMtx.getDataInfo();
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      })
    }
  }
  
  reloadMainPage(attempts: number = 0) {
    if (this.cgOfrMtx) {
      this.cgOfrMtx.setCustomerInfo(this.portfolio?.customerGuid ?? null, null);
      this.cgOfrMtx.getDataInfo();
    }
    else {
      if (attempts > 10) {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to relaod Offer Matrix. Please refresh page.'
        });
      }
      else {
        setTimeout(() => {
          attempts++;
          this.reloadMainPage(attempts);  
        }, 100);        
      }
    }
  }

  getOfferMatrixLookups() {
    if (!this.lookups || !this.lookups.aprs || !this.lookups.aprs.length) {
      let lookSub = this.apiService.get(`decision/matrix/lookups/${this.portfolio?.customerGuid}`)
        .subscribe({
          next: (data: OfferMatrixLookups) => {
            this.lookups = data;
          },
          error: (err: any) => {
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to Offer Matrix lookups for Customer. See log for details.'
            });
            console.error(err);
          },
          complete: () => { lookSub.unsubscribe(); }
        });
    }
  }

  addOfferMatrix() {
    this.prepOfferMatrixName(this.pageAddBc);
    this.selectedMatrix = null;
    this.showMatrix = false;
    this.showMatrixEdit = true;
  }

  editOfferMatrix(matrix: OfferMatrix) {
    this.selectedMatrix = matrix;
    this.prepOfferMatrixName(this.pageEditBc);
    this.matrixNameForm.patchValue({
      name: matrix.Name,
      description: matrix.Description
    });
    this.showMatrix = false;
    this.showMatrixEdit = true;
    this.setRowsCustomGridXML(`<params><f fn=\"OfferMatrixGUID\" fv=\"${matrix.OfferMatrixGUID}\" /></params>`, 1);
  }

  setRowsCustomGridXML(xml: string, attempt: number) {
    if (this.cgOfrMtxRow) {
      this.cgOfrMtxRow.SearchXML = xml;
      this.cgOfrMtxRow.getDataFromSource();
    }
    else {
      if (attempt > 10) {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to set filter on Offer Matrix Rows.'
        });
        console.error("Unable to set filter (SearchXML) on Offer Matrix Rows custom grid component!");
      }
      else {
        setTimeout(() => {
          attempt++;
          this.setRowsCustomGridXML(xml, attempt);
        }, 100);
      }
    }
  }

  prepOfferMatrixName(label: string) {
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => {
        this.matrixNameFormLoaded = false;
        this.showMatrix = true;
        this.showMatrixEdit = false;
        this.showMatrixRowEdit = false;
        this.reloadMainPage();
        this.breadCrumbService.reduceBcList(this.bcItems, prevItem?.label);
      };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: label });
    this.matrixEditTitle = label.replace('Offer Matrix ', '');
    this.createMatrixNameForm();
  }

  createMatrixNameForm() {
    this.matrixNameForm = new FormGroup<OfferMatrixNameFormGroup>({
      name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      description: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] })
    });
    this.matrixNameFormLoaded = true;
  }

  saveMatrixName() {
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      offerMatrixGuid: this.selectedMatrix?.OfferMatrixGUID ?? uuidv4(),
      name: this.matrixNameForm.value.name,
      description: this.matrixNameForm.value.description
    };
    let saveSub = this.apiService.post('decision/matrix', body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Offer Matrix successfully added/updated.'
          });
          this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
          this.cgOfrMtx.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save Offer Matrix. See log for details.'
          }, 'center');
          console.error(err);
          this.matrixNameForm.reset();
        },
        complete: () => { saveSub.unsubscribe(); }
      });
  }

  copyOfferMatrix(matrix: OfferMatrix) {
    this.selectedMatrix = matrix;
    this.newOfferMatrixName = 'Copy Of - ' + matrix.Name;
    this.showCopyMatrix = true;
  }

  saveCopyOfferMatrix() {
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      matrixGuid: this.selectedMatrix?.OfferMatrixGUID,
      newMatrixName: this.newOfferMatrixName
    };

    let copySub = this.apiService.post('decision/matrix/copy', body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Offer Matrix successfully copied.'
          });
          this.showCopyMatrix = false;
          this.cgOfrMtx.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to copy Offer Matrix. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { copySub.unsubscribe(); }
      });
  }

  confirmCopyOfferMatrixRow(row: OfferMatrixRow) {
    this.confirmService.confirm({
      key: this.deOfrMtxDialKey,
      message: `Copy Offer Matrix Row ${row.RowNumber}`,
      header: ' ',
      icon: 'pi pi-exclamation-circle de-copy-row-icon',
      accept: () => {
        this.copyOfferMatrixRow(row);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Row has not been copied.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  copyOfferMatrixRow(row: OfferMatrixRow) {
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      loanAmountMatrixGuid: row.LoanAmountMatrixGUID
    };
    let copySub = this.apiService.post('decision/matrix/copy-row', body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Offer Matrix Row successfully copied.'
          });
          this.cgOfrMtxRow.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to copy Offer Matrix Row. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { copySub.unsubscribe(); }
      });
  }

  addMatrixRow() {
    this.prepOfferOptionForm(this.pageAddRowBc);
    this.selectedMatrixRow = null;
    this.showMatrix = false;
    this.showMatrixEdit = false;

    this.showMatrixRowEdit = true;

  }

  editOfferMatrixRow(row: OfferMatrixRow) {
    this.selectedMatrixRow = row;
    let rowSub = this.apiService.get(`decision/matrix/${this.portfolio?.customerGuid}/row/${row.LoanAmountMatrixGUID}`)
      .subscribe({
        next: (row: OfferMatrixRowDB) => {
          this.prepOfferOptionForm(this.pageEditRowBc);
          this.matrixRowForm.patchValue({
            productType: row.offerProductTypeID,
            creditBand: row.loanGradeID,
            state: this.lookups.offerStates.find(s => s.stateAbb === row.state)?.stateid ?? this.lookups.offerStates[0].stateid,
            procedure: row.loanProviderProductID,
            minProcAmt: row.minProAmt,
            maxProcAmt: row.maxProAmt,
            apr: row.loanInterest,
            term: row.termMO,
            cashTerm: row.sacTerm,
            minPayType: row.minPaymentTypeID,
            minPayment: row.minPaymentAmt,
            downPayType: row.downPaymentType,
            downPayAmt: row.downPaymentMinAmt,
            downPayPct: row.downPaymentMinPct,
            discountRate: row.mdr
          });

          if (row.marketingMessage != null) {
            if (this.matrixRowForm.controls.marketingMessages.length > 0) {
              this.matrixRowForm.controls.marketingMessages.clear();
            }
            const doc: XMLDocument = this.parser.parseFromString(row.marketingMessage, 'application/xml');
            doc.querySelectorAll('msg').forEach(val => {
              var language = this.lookups.languages.find(l => l.language.toLowerCase() === val.attributes.getNamedItem('lang')?.value.toLowerCase());
              if (language) {
                this.matrixRowForm.controls.marketingMessages.push(
                  this.formBuilder.group<MatrixRowMarketingFormGroup>({
                    language: new FormControl<string>(language.language, { nonNullable: true }),
                    message: new FormControl<string>(val.attributes.getNamedItem('message')?.value ?? '', { nonNullable: true })
                  })
                );
              }
            });
          }

          this.showMatrix = false;
          this.showMatrixEdit = false;
          this.showMatrixRowEdit = true;

        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Offer Option data. See log for details.'
          }, 'center');
          console.error(err);
          this.selectedMatrixRow = null;
        },
        complete: () => { rowSub.unsubscribe(); }
      });
  }

  prepOfferOptionForm(label: string) {
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => {
        this.matrixRowFormLoaded = false;
        this.showMatrix = false;
        this.showMatrixEdit = true;
        this.showMatrixRowEdit = false;
        this.breadCrumbService.reduceBcList(this.bcItems, prevItem?.label);
      };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: label });
    this.matrixEditRowTitle = label.replace('Offer Option ', '');
    this.createMatrixRowForm();
  }

  createMatrixRowForm() {
    this.matrixRowForm = this.formBuilder.group<OfferMatrixRowFormGroup>({
      productType: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      marketingMessages: this.formBuilder.array([
        this.formBuilder.group<MatrixRowMarketingFormGroup>({
          language: new FormControl<string>('', { nonNullable: true }),
          message: new FormControl<string>('', { nonNullable: true })
        })
      ]),
      creditBand: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      state: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      procedure: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      minProcAmt: new FormControl<number | null>(null, {
        nonNullable: true,
        validators: [Validators.required,
        Validators.min(this.lookups.minMaxProcedureAmounts[0].customer_Min_ProcedureAmt),
        Validators.max(this.lookups.minMaxProcedureAmounts[0].customer_Max_ProcedureAmt)
        ]
      }),
      maxProcAmt: new FormControl<number | null>(null, {
        nonNullable: true,
        validators: [Validators.required,
        Validators.min(this.lookups.minMaxProcedureAmounts[0].customer_Min_ProcedureAmt),
        Validators.max(this.lookups.minMaxProcedureAmounts[0].customer_Max_ProcedureAmt)
        ]
      }),
      apr: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      term: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      cashTerm: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      minPayType: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      minPayment: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      downPayType: new FormControl<string>('%', { nonNullable: true }),
      downPayAmt: new FormControl<number | null>(null, { nonNullable: true }),
      downPayPct: new FormControl<number | null>(null, { nonNullable: true }),
      discountRate: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] })
    });
    //  clear out arrays
    this.matrixRowForm.controls.marketingMessages.removeAt(0);

    //  fill in lookups
    this.lookups.languages.forEach(lang => {
      this.matrixRowForm.controls.marketingMessages.push(
        this.formBuilder.group<MatrixRowMarketingFormGroup>({
          language: new FormControl<string>(lang.language, { nonNullable: true }),
          message: new FormControl<string>('', { nonNullable: true })
        })
      );
    });


    this.matrixRowFormLoaded = true;
  }

  minPayTypeSelected(typeId: number) {
    if (typeId == 1) {  //  Amount 
      this.disableMinPayment = false;
    }
    else {              //  Variable
      this.disableMinPayment = true;
      this.matrixRowForm.patchValue({
        minPayment: this.lookups.minDefaultPayments[0].minPaymentAmountDefault
      });
    }
  }

  downPayTypeSelected(type: '%' | '$' | '=') {

    if (type === '%') {
      this.matrixRowForm.controls.downPayAmt.clearValidators();
      this.matrixRowForm.controls.downPayPct.setValidators([Validators.required]);
      this.matrixRowForm.patchValue({
        downPayAmt: null
      });
      if (!this.matrixRowForm.controls.minPayment.hasValidator(Validators.required)) {
        this.matrixRowForm.controls.minPayment.setValidators([Validators.required]);
      }
    }
    else if (type == '$') {
      this.matrixRowForm.controls.downPayPct.clearValidators();
      this.matrixRowForm.controls.downPayAmt.setValidators([Validators.required]);
      this.matrixRowForm.patchValue({
        downPayPct: null
      });
      if (!this.matrixRowForm.controls.minPayment.hasValidator(Validators.required)) {
        this.matrixRowForm.controls.minPayment.setValidators([Validators.required]);
      }
    }
    else {
      this.matrixRowForm.controls.downPayAmt.clearValidators();
      this.matrixRowForm.controls.downPayPct.clearValidators();
      this.matrixRowForm.controls.minPayment.clearValidators();
      this.matrixRowForm.patchValue({
        downPayAmt: null,
        downPayPct: null
      });

    }

    this.matrixRowForm.updateValueAndValidity();

  }

  saveMatrixRow() {
    let body: OfferMatrixRowUpload = new OfferMatrixRowUpload();
    body.customerGuid = this.portfolio?.customerGuid ?? '';
    body.offerMatrixGuid = this.selectedMatrix?.OfferMatrixGUID ?? '';
    body.loanMatrixGuid = this.selectedMatrixRow ? this.selectedMatrixRow.LoanAmountMatrixGUID : uuidv4(),
      body.offerProductTypeID = this.matrixRowForm.value.productType ?? 0;
    body.loanGradeID = this.matrixRowForm.value.creditBand ?? null;
    body.offerMatrixID = 0;
    body.mdr = this.matrixRowForm.value.discountRate ?? null;
    body.loanProviderProductID = this.matrixRowForm.value.procedure ?? null;
    body.loanProviderTypeID = null;
    body.loanAmount = 0;
    body.loanInterest = this.matrixRowForm.value.apr ?? 0;
    body.minProAmt = this.matrixRowForm.value.minProcAmt ?? 0;
    body.maxProAmt = this.matrixRowForm.value.maxProcAmt ?? 0;
    body.downPaymentType = this.matrixRowForm.value.downPayType ?? '';
    body.downPayIsPayment = false;
    body.minPaymentTypeID = this.matrixRowForm.value.minPayType ?? 0;
    body.minPaymentAmt = this.matrixRowForm.value.minPayment ?? 0;
    body.minDownPaymentAmt = 0;
    body.maxDownPaymentAmt = 0;
    body.downPaymentMinPct = this.matrixRowForm.value.downPayPct ?? 0;
    body.downPaymentMinAmt = this.matrixRowForm.value.downPayAmt ?? 0;
    body.termBW = 0;
    body.termSM = 0;
    body.termSM14 = 0;
    body.termMO = this.matrixRowForm.value.term ?? 0;
    body.state = this.lookups.offerStates.find(s => s.stateid == this.matrixRowForm.value.state)?.stateAbb ?? '';
    body.sacTerm = this.matrixRowForm.value.cashTerm ?? 0;
    body.marketingMessage = this.buildMarketingMessageXML(this.matrixRowForm.controls.marketingMessages);

    let postRowSub = this.apiService.post(`decision/matrix/row`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Offer Matrix Option successfully added/updated.'
          });
          this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.bcItems[this.bcItems.length - 2].label);
          this.cgOfrMtxRow.getDataFromSource();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save Offer Matrix Option. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { postRowSub.unsubscribe(); }
      });

  }

  buildMarketingMessageXML(marketingMessages: FormArray<FormGroup<MatrixRowMarketingFormGroup>>): string | null {
    if (!marketingMessages || marketingMessages.length == 0) return null;

    let doc = document.implementation.createDocument('', 'messages');
    let messages = doc.querySelector('messages');
    marketingMessages.controls.forEach(ctrl => {
      let childNode = doc.createElement('msg');
      childNode.setAttribute('lang', ctrl.value.language ?? '');
      childNode.setAttribute('message', ctrl.value.message ?? '');
      if (ctrl.value.language && ctrl.value.language.toLowerCase() == "en") {
        childNode.setAttribute('default', '1');
      }
      messages?.appendChild(childNode);
    });

    const serializer = new XMLSerializer();
    return serializer.serializeToString(doc);
  }

  testOfferMatrix() {
    // this.toastService.underConstruction();

    this.testOfferForm = new FormGroup<OfferMatrixTestFormGroup>({
      creditBand: new FormControl<string|null>(null, {nonNullable:true, validators: [Validators.required]}),
      state: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]}),
      procedure: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]}),
      amount: new FormControl<number|null>(null, {nonNullable: true, validators: [Validators.required]})
    });
    this.testOfferFormLoaded = true;
    this.showTestMatrix = true;
  }

  executeTestOfferMatrix() {
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      matrixGuid: this.selectedMatrix?.OfferMatrixGUID,
      creditBand: this.testOfferForm.value.creditBand,
      state: this.testOfferForm.value.state,
      procedure: this.testOfferForm.value.procedure,
      amount: this.testOfferForm.value.amount
    };

    let testSub = this.apiService.post(`decision/matrix/test`, body)
      .subscribe({
        next: (xmlStr: {result: string}) => {
          this.loadTestOffer(xmlStr.result);
          this.testOfferError = false;
          this.showTestMatrix = false;
          this.showTestMatrixResults = true;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error testing Offer Matrix. See log for details.'
          }, 'center');
          console.error(err);
          this.testOfferError = true;
          this.showTestMatrix = false;
          this.showTestMatrixResults = true;
        },
        complete: () => { testSub.unsubscribe(); }
      });
  }

  loadTestOffer(xmlStr: string) {
    let loanAmount: string = (this.testOfferForm.value.amount ?? 0).toFixed(2);
    this.testOfferResults = []
    const doc: XMLDocument = this.parser.parseFromString(xmlStr, 'application/xml');
    doc.querySelectorAll('offer').forEach(offer => {
      let testAmount = (+(offer.attributes.getNamedItem('LoanAmount')?.value ?? 0)).toFixed(2);
      
      if (loanAmount == testAmount) {       //  Only take offers that match the amount submitted.
        let result = new TestOfferReturn();
        result.offerOrder = +(offer.attributes.getNamedItem('OfferOrder')?.value ?? 0);
        result.downPayment = +(offer.attributes.getNamedItem('DownPayment')?.value ?? 0);
        result.downPaymentMinPct = +(offer.attributes.getNamedItem('DownPaymentMinPct')?.value ?? 0);
        result.downPaymentResult = offer.attributes.getNamedItem('DownPaymentResult')?.value ?? '';
        result.estMonthlyPaymentAmt = +(offer.attributes.getNamedItem('EstMonthlyPaymentAmt')?.value ?? 0);
        result.loanAmount = +(offer.attributes.getNamedItem('LoanAmount')?.value ?? 0);
        result.loanGradeDescription = offer.attributes.getNamedItem('LoanGradeDescription')?.value ?? '';
        result.loanInterest = +(offer.attributes.getNamedItem('LoanInterest')?.value ?? 0);
        result.loanMDR = +(offer.attributes.getNamedItem('LoanMDR')?.value ?? 0);
        result.marketingMessage = offer.attributes.getNamedItem('MarketingMessage')?.value ?? '';
        result.maxDownPaymentAmt = +(offer.attributes.getNamedItem('MaxDownPaymentAmt')?.value ?? 0);
        result.minDownPaymentAmt = +(offer.attributes.getNamedItem('MinDownPaymentAmt')?.value ?? 0);
        result.minPaymentAmt = +(offer.attributes.getNamedItem('MinPaymentAmt')?.value ?? 0);
        result.minPaymentTypeID = +(offer.attributes.getNamedItem('MinPaymentTypeID')?.value ?? 0);
        result.term = +(offer.attributes.getNamedItem('Term')?.value ?? 0);
  
        let expire = offer.attributes.getNamedItem('ExpireDate')?.value;        
        if (expire) {
          const [month, day, year] = expire.split('/');
          result.expireDate = new Date(+year, +month - 1, +day);
        }
        this.testOfferResults.push(result);
      }
      
    });
  }

  showCancelToast() { this.toastService.showCancelToast(); }
}
