export class CustomerSettlement {
    customerBranchSettlementID: number = 0;
    customerID: number = 0;
    branchID: number = 0;
    branchSettlementGUID: string | null = null;
    bankName: string = '';
    accountName: string = '';
    bankAccount: string = '';
    bankAccountType: string = '';
    bankRouting: string = '';
    bankWiringInstr: string = '';
    contactName: string = '';
    contactPhone: string = '';
    contactEmail: string = '';
    lockBox: string = '';
    isClawback?: boolean;

}

export class CustomerSettlementWithBranch extends CustomerSettlement {
    customerGuid: string = '';
    branchGUID: string = '';
    branch_ID: string = '';
    branchName: string = '';
}

export class SettlementUpload {
    customerGuid: string | null = null;
    customerTrancheId: number = 0;
    bankName: string = '';
    bankAccount: string = '';
    accountName: string | null = null;
    bankAccountType: string | null = null;
    routingNumber: string = '';
    wireInstruction: string = '';
    contactDetailsName: string = '';
    contactDetailsPhone: string = '';
    contactDetailsEmail: string = '';
    LockBoxFullAddress: string = '';
}