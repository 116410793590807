<span class="text-2xl font-medium">Exception Checks</span>
<div class="w-full mt-4 border-1 border-black-alpha-10">
  <div class="p-3 report-item-link" (click)="sendClick()">Returns</div>
  <div class="p-3 report-item-link" (click)="sendClick()">Remits</div>
  <div class="p-3 report-item-link" (click)="sendClick()">Correction</div>
  <div class="p-3 report-item-link" (click)="sendClick()">Exception</div>
  <div class="p-3 report-item-link" (click)="sendClick()">Settlement Echo</div>
  <div class="p-3 report-item-link" (click)="sendClick()">Chargebacks</div>
</div>
<div class="w-full py-3">
  <p3solved-button
    id="btnDownload"
    label="Download XLSX"
    class="outlined"
    (click)="sendClick()"
  ></p3solved-button>
</div>
