export class ProviderLocation {
    Address1: string = '';
    Address2: string = '';
    "Branch GUID": string = '';
    BranchGUID: string = '';
    BranchSettlementGUID: string = '';
    City: string = '';
    Contact: string = '';
    Country: string = '';
    CreateDate: Date = new Date();
    Email: string = '';
    ID: string = '';
    Name: string = '';
    Phone: string = '';
    Region: string = '';
    RowNumber: string = '';
    State: string = '';
    SubRegion: string = '';
    Zip: string = '';
}

export class ProviderLocationUpload {
    branchGuid?: string;
    customerGuid: string = '';
    branchId: string = '';
    branchName: string = '';
    taxId: string | null = null;
    licenseNo: string | null = null;
    licenseState: string | null = null;
    yearsOwnerShip: string | null = null;
    businessType: string | null = null;
    legalName: string | null = null;
    branchAddress1: string | null = null;
    branchAddress2: string | null = null;
    branchCity: string | null = null;
    branchState: string | null = null;
    branchZip: string | null = null;
    branchCountry: string | null = null;
    branchContact: string | null = null;
    branchPhone: string | null = null;
    branchEmail: string | null = null;
    branchWebsite: string | null = null;
    annualRevenue: string | null = null;
    isParent: boolean | null = null;
    regionGuid: string | null = null;
    subRegionGuid: string | null = null;
    allyOfficeId: string | null = null;
}