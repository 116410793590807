<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <p3solved-progress-bar [style]="{'height': '0.5rem'}"></p3solved-progress-bar>
</div>

<div *ngIf="!loading">
    <span class="text-2xl font-medium">{{ heading }}</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card w-screen-fit p-2">
            <div class="p-5">
                <div *ngFor="let vendor of achVendors.vendors; index as i"
                    class="grid border-top-1 border-black-alpha-20">
                    <div class="col-12 flex py-5">
                        <span
                            class="text-2xl font-bold align-self-center">{{vendor[0].vendorName}}-{{vendor[0].transTypeName}}</span>
                        <div class="pl-4 flex" *ngIf="vendor[0].transTypeID == 1 || vendor[0].transTypeID == 2">
                            <p3solved-button *ngIf="hasTestFeature" label="Send Test" class="px-4" (buttonClick)="sendTestAction(vendor[0])"></p3solved-button>
                            <p3solved-button *ngIf="hasProcessFeature" label="Process" class="px-4" (buttonClick)="processAction(vendor[0])"></p3solved-button>
                        </div>
                    </div>
                    <div *ngFor="let v of vendor; index as j" class="col-12 grid border-top-1 border-black-alpha-20"
                        [ngClass]="j == (vendor.length - 1) ? 'font-bold': ''">

                        <div class="col-2">
                            <span *ngIf="v.name1?.length > 0 && j < vendor.length -1; else n1Blank"
                                class="cds-text-color cursor-pointer" (click)="filterVendor(v, 1)">
                                {{ v.name1 }}
                            </span>
                            <ng-template #n1Blank>{{ v.name1 }}</ng-template>
                        </div>
                        <div class="col-1">{{ v.value1 }}</div>
                        <div class="col-2">
                            <span *ngIf="v.name2?.length > 0 && j < vendor.length -1; else n2Blank"
                                class="cds-text-color cursor-pointer" (click)="filterVendor(v, 2)">
                                {{ v.name2 }}
                            </span>
                            <ng-template #n2Blank>{{ v.name2 }}</ng-template>
                        </div>
                        <div class="col-1">{{ v.value2 }}</div>
                        <div class="col-2">
                            <span *ngIf="v.name3?.length > 0 && j < vendor.length -1; else n3Blank"
                                class="cds-text-color cursor-pointer" (click)="filterVendor(v, 3)">
                                {{ v.name3 }}
                            </span>
                            <ng-template #n3Blank>{{ v.name3 }}</ng-template>
                        </div>
                        <div class="col-1">{{ v.value3 }}</div>
                        <div class="col-2">
                            <span *ngIf="v.name4?.length > 0 && j < vendor.length -1; else n4Blank"
                                class="cds-text-color cursor-pointer" (click)="filterVendor(v, 4)">
                                {{ v.name4 }}
                            </span>
                            <ng-template #n4Blank>{{ v.name4 }}</ng-template>
                        </div>
                        <div class="col-1">{{ v.value4 }}</div>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-12 grid py-5">
                        <div class="col-6 text-xl font-bold cds-hover-bg">Page Totals</div>
                        <div class="col-6 text-xl font-bold cds-hover-bg">Page Totals</div>
                    </div>
                    <div class="col-12 grid border-top-1 border-black-alpha-20 font-bold">

                        <div class="col-2">{{ achVendors.totals.name1 }}</div>
                        <div class="col-1">{{ achVendors.totals.value1 }}</div>

                        <div class="col-2">{{ achVendors.totals.name2 }}</div>
                        <div class="col-1">{{ achVendors.totals.value2 }}</div>

                        <div class="col-2">{{ achVendors.totals.name3 }}</div>
                        <div class="col-1">{{ achVendors.totals.value3 }}</div>

                        <div class="col-2">{{ achVendors.totals.name4 }}</div>
                        <div class="col-1">{{ achVendors.totals.value4 }}</div>
                    </div>
                </div>
                <div class="grid pt-5">
                    <div class="col-12 font-bold">Exception Checks</div>
                    <div *ngFor="let c of checks; index as i" class="col-12 grid">
                        <div class="col-5 border-top-1 border-black-alpha-20">
                            <span class="cds-text-color cursor-pointer" (click)="filterCheck(c)">{{ c.description }}</span>
                        </div>
                        <div class="col-1 border-top-1 border-black-alpha-20">{{ c.count }}</div>
                    </div>
                </div>
            </div>
            <p-table #dt [value]="transactions" styleClass="p-datatable-lg col-12" [paginator]="true"
                [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="first" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                responsiveLayout="scroll" [rows]="rows" [rowHover]="true" [columns]="transactionColumns"
                [globalFilterFields]="filterFields">
                <ng-template pTemplate="caption">
                    <div class="grid">
                        <div class="col-6">
                            <span *ngIf="removeFilter" class="cds-text-color cursor-pointer"
                                (click)="removeFilters()">{{ removeFilter }}</span>
                        </div>
                        <div class="col-6 flex flex-row-reverse">
                            <div class="p-1">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" aria-hidden="true"></i>
                                    <input #txtSearch type="text" class="w-15rem" pInputText placeholder="Search"
                                        (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <ng-template ngFor let-col [ngForOf]="transactionColumns" let-i="index">
                            <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field" 
                                scope="col">{{ col.header }}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
                        </ng-template>
                        <th scope="col">Dupe Trans Type</th>
                        <th scope="col">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-transaction>
                    <tr>
                        <td>{{ transaction.acctRefNo }}</td>
                        <td>
                            <span class="cds-text-color cursor-pointer" (click)="goToTarget(transaction)">
                                {{ transaction.loanNumber }}
                            </span>
                        </td>
                        <td>{{ transaction.fullName }}</td>
                        <td>{{ transaction.tranCode }}</td>
                        <td>{{ transaction.tranRefNo }}</td>
                        <td>{{ transaction.transAsDate | date: 'MM/dd/yyyy'}}</td>
                        <td>{{ transaction.vendorName }}</td>
                        <td>{{ transaction.tranAmount | currency }}</td>
                        <td>
                            <span class="text-xl font-bold cds-warn-text">{{getDupeTransType(transaction)}}</span>
                        </td>
                        <td>
                            <i *ngIf="transaction.allowDelete" class="pi pi-trash cursor-pointer cds-error-text"
                                title="Delete" aria-hidden="true" (click)="confirmDeleteTransaction(transaction)"></i>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [colSpan]="10">No ACH Transactions found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p3solved-dialog [isVisible]="showDeleteTransMsg" [showHeader]="true" [isModal]="true" [isClosable]="true"
    header="Delete Transaction {{delTransaction?.tranRefNo}}" headerClass="text-2xl cds-error-text"
    (HideClick)="delTransaction=null;showDeleteTransMsg=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="grid">
            <div class="col-8">
                <p3solved-dropdown id="delOption" [items]="tranDelOptions" [(ngModel)]="selectedDelOption"
                    appendTo="body" optionLabel="name" optionValue="value"></p3solved-dropdown>
            </div>
            <div class="col-12">
                <p3solved-form-textarea id="txtAreaNotes" placeholder="NLS Notes" [rows]="5"
                    [(ngModel)]="nlsNotes"></p3solved-form-textarea>
            </div>
            <div class="col-12">
                <p-checkbox [binary]="true" label="Update CSR notes with this message"
                    [(ngModel)]="blnUpdateCSR"></p-checkbox>
            </div>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnDelTransaction" label="Remove" class="danger" (buttonClick)="deleteTransaction();"
                [disabled]="!delTransaction"></p3solved-button>
            <p3solved-button id="btnCancelDel" label="Cancel" class="outline"
                (buttonClick)="delTransaction=null;showDeleteTransMsg=false;"></p3solved-button>
        </div>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showFileAction" [showHeader]="true" [isModal]="true" [isClosable]="true"
    header="{{fileActionHeader}}" headerClass="text-2xl font-bold"
    (HideClick)="showFileAction=false;" [style]="{'width': '25vw'}">
    <!-- <div body>
        <div class="grid">
            <div class="col-8">
                <p3solved-dropdown id="delOption" [items]="tranDelOptions" [(ngModel)]="selectedDelOption"
                    appendTo="body" optionLabel="name" optionValue="value"></p3solved-dropdown>
            </div>
            <div class="col-12">
                <p3solved-form-textarea id="txtAreaNotes" placeholder="NLS Notes" [rows]="5"
                    [(ngModel)]="nlsNotes"></p3solved-form-textarea>
            </div>
            <div class="col-12">
                <p-checkbox [binary]="true" label="Update CSR notes with this message"
                    [(ngModel)]="blnUpdateCSR"></p-checkbox>
            </div>
        </div>
    </div> -->
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnSendFile" label="Confirm" (buttonClick)="executeFileAction();"></p3solved-button>
            <p3solved-button id="btnCancelFile" label="Cancel" class="outline"
                (buttonClick)="showFileAction=false;"></p3solved-button>
        </div>
    </div>
</p3solved-dialog>