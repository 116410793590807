export class CustomField {
    fieldID: number = 0;
    customerID: number = 0;
    fieldGUID: string = '';
    fieldName: string = '';
    fieldFormula: string | null = null;
    fieldDataTypeID: number = 0;
    displayOrder: number = 0;
    readOnly: boolean = false;
    storageEncryptionID: number | null = null;
    deleted: boolean = false;
    createUserID: number = 0;
    createDate: Date = new Date();
    modUserID: number | null = null;
    modDate: Date | null = null;
    deleteUserID: number | null = null;
    deleteDate: Date | null = null;
    storageTypeID: number = 0;
    aliasFieldID: number | null = null;
    storageField: string | null = null;
    binaryStorage: boolean = false;
    hidden: boolean = false;
    lookupTable: string | null = null;
    lookupExpression: string | null = null;
    mailSourceField: string | null = null;
    demoField: boolean = false;
    storageTime: number = 0;
    conditionalValidation: string | null = null;
    hideOnText: boolean = false;
    usedInTTPL: boolean = false;
    fieldAlias: string | null = null;
    customFieldDefinition: string | null = null;
    doNotTrackChanges: boolean = false;
    searchIndex: boolean = false;
    saveHash: boolean = false;
    ruleSetAllowed: boolean = false;
    replaceTagsOnRead: boolean = false;
}

export class CustomFieldSettings {
    matrix: CustomFieldMatrix | null = null;
    case: CustomFieldCase | null = null;
}

export class CustomFieldMatrix {
    type: string = '';
    xAxis: CustomFieldMatrixAxis = {} as CustomFieldMatrixAxis;
    yAxis: CustomFieldMatrixAxis = {} as CustomFieldMatrixAxis;
    results: CustomFieldMatrixCell[] = []; 
}

export class CustomFieldMatrixAxis {
    functionName: string = '';
    fId: number = 0;
    dsId: number = 0;
    rowCount: number = 0;
    numeric: boolean = false;
    ranges: CustomFieldMatrixRange[] = [];
}

export class CustomFieldMatrixRange {
    operation: string = 'between';
    min: string = '';
    max: string = '';
    rowNum: number = 0;
}

export class CustomFieldMatrixCell {
    x: number = 0;
    y: number = 0;
    result: string = '';
}

export class CustomFieldCase {
    type: string = '';
    fId: number = 0;
    functionName: string = '';
    dsId: number = 0;
    rowCount: number = 0;
    numeric: boolean = false;
    values: CustomFieldCaseValue[] = [];
}

export class CustomFieldCaseValue {
    operation: string = '';
    min: string = '';
    max: string = '';
    result: string = '';
    rowNum: number = 0;
}