<div class="card">
    <div class="card-body">
        <form [formGroup]="loanAppForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="nlsLoanPortfolioName"
                        [group]="loanAppForm"
                        label="NLS Loan Portfolio Name">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="loanTemplatePrefix"
                        [group]="loanAppForm"
                        label="Loan Template Prefix">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="loanNumPrefix"
                        [group]="loanAppForm"
                        label="Loan Number Prefix">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="loanAmount"
                        [group]="loanAppForm"
                        label="Loan Amount">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="campainName"
                        [group]="loanAppForm"
                        label="Campaign Name">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="domain"
                        [group]="loanAppForm"
                        label="Domain">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="customFolder"
                        [group]="loanAppForm"
                        label="Custom control(s) Folder">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="startUrl"
                        [group]="loanAppForm"
                        label="Start URL">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="campaignFolder"
                        [group]="loanAppForm"
                        label="Campaign Folder">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="accountDomain"
                        [group]="loanAppForm"
                        label="My Account Domain">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="invitationUrl"
                        [group]="loanAppForm"
                        label="Invitation Page URL">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="invitationDomain"
                        [group]="loanAppForm"
                        label="Web Invite Domain">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="accountDefaultPage"
                        [group]="loanAppForm"
                        label="My Account Default Page">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="adminDomain"
                        [group]="loanAppForm"
                        label="Admin Domain">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="logoutTimeout"
                        [group]="loanAppForm"
                        label="My Account Logout Timeout (Seconds)">
                    </app-cc-input>
                </div>

            </div>
            <button class="btn btn-primary btn-main btn-large" type="submit">Update Loan App</button>
        </form>
    </div>
</div>
