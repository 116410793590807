import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TableColumn } from '@p3solved/p3solved-ui';
import { MenuItem } from 'primeng/api';
import { PortfolioItem } from 'src/app/models/customer-item';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit {

  columns: TableColumn[] = [];
  filterFields: string[] = ['UserTypeName'];
  bcItems: MenuItem[] = [{ label: 'Users' , url: 'users/dashboard'}, { label: 'Roles' }];
  portfolioItem: PortfolioItem | null | undefined;
  loading = false;
  userTypes: any[] = [];
  first: number = 0;
  constructor(private api: ApiService,
    private router: Router,
    private portfolioService: PortfolioService) { }

  ngOnInit(): void {    
    this.initColumns();
    this.portfolioService.portfolio$.subscribe(p => {
    this.portfolioItem = p;
    this.getUsers();
  });
  }

  getUsers(){
    if (this.portfolioItem && this.portfolioItem.customerGuid) {
      this.loading = true;
      this.api.get(`AdminUser/get-user-types/${this.portfolioItem.customerGuid}`).subscribe(data => {
        this.userTypes = data;
        this.loading = false;
      });
    } else {
      //toast error?
    }
  }

  create(){
    this.router.navigate(['/users/edit-role/']);
  }

  entities(){
    this.router.navigate(['/users/role-entities/']);
  }

  getColumns(): TableColumn[] {
    var cols = this.columns.filter(x => x.show);
    return cols;
  }

  initColumns() {
    this.columns = [
      {
        field: 'UserTypeName',
        header: 'User Role',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: '',
        header: '',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      }
    ];
  }

  deleteUser(rowData: any){

  }

  edit(rowData: any){
    this.router.navigate(['/users/edit-role/' + rowData.UserTypeID]);
  }

}
