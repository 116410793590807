<div class="w-100">
  <div class="d-flex justify-content-center">
    <div class="d-flex align-items-center">
      <div class="flex-shrink-0">
        <fa-icon [icon]="spinner" [spin]="true" size="2x"></fa-icon>
      </div>
      <div class="flex-grow-1 ms-3">
        {{loadingText}}
      </div>
    </div>
  </div>
</div>
