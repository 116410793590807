import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { EditLocationEntryComponent } from '../edit-location-entry/edit-location-entry.component';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-edit-location-info',
  templateUrl: './edit-location-info.component.html',
  styleUrls: ['./edit-location-info.component.scss']
})
export class EditLocationInfoComponent implements OnInit {

  locations = [];
  iconPlus = faPlus as IconProp;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this.dialog.open(EditLocationEntryComponent, {
      width: '70vw',
      data: {name: ''}

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

}
