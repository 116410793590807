import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { LookupModel } from 'src/app/models/lookup-model';
import { LookupService } from 'src/app/services/lookup.service';
import { ProviderService } from 'src/app/services/provider.service';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-edit-provider-info',
  templateUrl: './edit-provider-info.component.html',
  styleUrls: ['./edit-provider-info.component.scss']
})
export class EditProviderInfoComponent implements OnInit {
  iconRequired = faAsterisk as IconProp;
  countries: LookupModel[] = [];
  providerRelationships: LookupModel[] = [];
  providerTypes: LookupModel[] = [];
  states: LookupModel[] = [];
  infoForm = this.fb.group({
    providerType: ['', Validators.required],
    vertical: ['', Validators.required],
    providerName: ['', Validators.required],
    website: ['', Validators.required],
    contactName: ['', Validators.required],
    contactPhone: ['', Validators.required],
    email: ['', Validators.required],
    address1: ['', Validators.required],
    address2: [''],
    city: ['', Validators.required],
    state: ['', Validators.required],
    zip: ['', Validators.required],
    country: ['', Validators.required]
  });
  constructor(private providerService: ProviderService, private lookupService: LookupService, private fb: UntypedFormBuilder) {
    this.initLookups();
    this.states = lookupService.getStates();
   }

  ngOnInit(): void {
  }

  initLookups(){
    this.providerService.customerLookups$.subscribe(
      (lookups: ICustomerLookups) => {
        this.countries = lookups.countries || [];
        this.providerRelationships = lookups.providerRelationships || [];
        this.providerTypes = lookups.providerTypes || [];
      });
  }

  onSubmit(){

    console.warn(this.infoForm.value);
  }

}
