export class TestOfferReturn {
    offerOrder: number = 0;
    loanAmount: number = 0;
    loanInterest: number = 0;
    downPayment: number = 0;
    minDownPaymentAmt: number = 0;
    maxDownPaymentAmt: number = 0;
    minPaymentTypeID: number = 0;
    minPaymentAmt: number = 0;
    downPaymentMinPct: number = 0;
    term: number = 0;
    marketingMessage: string = '';
    expireDate: Date = new Date();
    downPaymentResult: string = '';
    loanMDR: number = 0;
    loanGradeDescription: string = '';
    estMonthlyPaymentAmt: number = 0;
}