<span class="text-2xl font-medium">User Role Entities</span>
<p-breadcrumb [model]="bcItems"></p-breadcrumb>
<div class="mt-4">
    <div class="cds-card w-screen-fit p-2">
        <div class="p-2">
            <p-tabView>
                <p-tabPanel header="Tabs">
                    <div *ngIf="loading" class="m-5">
                        <div class="text-2xl">Loading...</div>
                        <p3solved-progress-bar [style]="{'height': '0.5rem'}"></p3solved-progress-bar>
                    </div>
                    <div *ngIf="!loading" class="m-2">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="d-flex mb-2">
                                    <div class="me-auto p-2">
                                        <p class="h5">Site Tabs</p>
                                    </div>
                                    <div class="p-2">
                                        <p3solved-button id="btnSaveRecs" label="ADD NEW" (buttonClick)="editTabItem()">
                                        </p3solved-button>
                                    </div>
                                </div>
                                <div class="card">
                                    <table class="table table-hover table-bordered table-sm mb-0">
                                        <thead>
                                            <tr class="table-info">
                                                <th scope="col">#</th>
                                                <th scope="col">Tab Name</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let t of entities.tabs;let i=index;trackBy:trackByIndex;">
                                                <td>{{i+1}}</td>
                                                <td>{{t.SiteTabName}}</td>
                                                <td>
                                                    <i class="pi pi-pencil text-primary cursor-pointer mr-4" title="Edit"
                                                        aria-hidden="true" (click)="editTabItem(t)"></i>
                                                </td>
                                                <td>
                                                    <i class="pi pi-trash text-primary cursor-pointer" title="Delete"
                                                        aria-hidden="true" (click)="delete(t, 'tab')"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </p-tabPanel>
                <p-tabPanel header="Pages">
                    <div *ngIf="loading" class="m-5">
                        <div class="text-2xl">Loading...</div>
                        <p3solved-progress-bar [style]="{'height': '0.5rem'}"></p3solved-progress-bar>
                    </div>
                    <div *ngIf="!loading" class="m23">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="d-flex mb-2">
                                    <div class="me-auto p-2">
                                        <p class="h5">Pages</p>
                                    </div>
                                    <div class="p-2">
                                        <p3solved-button id="btnSaveRecs" label="ADD NEW" (buttonClick)="editPageItem()">
                                        </p3solved-button>
                                    </div>
                                </div>
                                <div class="card">
                                    <table class="table table-hover table-bordered table-sm mb-0">
                                        <thead>
                                            <tr class="table-info">
                                                <th scope="col">#</th>
                                                <th scope="col">Tab Name</th>
                                                <th scope="col">Page Name</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let p of entities.pages;let i=index;trackBy:trackByIndex;">
                                                <td>{{i+1}}</td>
                                                <td>{{p.SiteTabName}}</td>
                                                <td>{{p.SitePageName}}</td>
                                                <td>
                                                    <i class="pi pi-pencil text-primary cursor-pointer mr-4" title="Edit"
                                                        aria-hidden="true" (click)="editPageItem(p)"></i>
                                                </td>
                                                <td>
                                                    <i class="pi pi-trash text-primary cursor-pointer" title="Delete"
                                                        aria-hidden="true" (click)="delete(p, 'page')"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </p-tabPanel>
                <p-tabPanel header="Features">
                    <div *ngIf="loading" class="m-5">
                        <div class="text-2xl">Loading...</div>
                        <p3solved-progress-bar [style]="{'height': '0.5rem'}"></p3solved-progress-bar>
                    </div>
                    <div *ngIf="!loading" class="m-2">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="d-flex mb-2">
                                    <div class="me-auto p-2">
                                        <p class="h5">Site Features</p>
                                    </div>
                                    <div class="p-2">
                                        <p3solved-button id="btnSaveRecs" label="ADD NEW" (buttonClick)="editFeatureItem()">
                                        </p3solved-button>
                                    </div>
                                </div>
                                <div class="card">
                                    <table class="table table-hover table-bordered table-sm mb-0">
                                        <thead>
                                            <tr class="table-info">
                                                <th scope="col">#</th>
                                                <th scope="col">Page Name</th>
                                                <th scope="col">Feature Code</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let f of entities.features;let i=index;trackBy:trackByIndex;">
                                                <td>{{i+1}}</td>
                                                <td>{{f.SitePageName}}</td>
                                                <td>{{f.SiteFeatureCode}}</td>
                                                <td>
                                                    <i class="pi pi-pencil text-primary cursor-pointer mr-4" title="Edit"
                                                        aria-hidden="true" (click)="editFeatureItem(f)"></i>
                                                </td>
                                                <td>
                                                    <i class="pi pi-trash text-primary cursor-pointer" title="Delete"
                                                        aria-hidden="true" (click)="delete(f, 'feature')"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
<p3solved-dialog [isVisible]="editTab.show" [header]="editTab.header" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="editTab.show = false" [style]="{'width': '25vw'}">
    <div body>
        <div class="w-12">
            <form [formGroup]="tabForm">
                <div class="p-1">
                    <p3solved-form-text id="" placeholder="Tab Name" formControlName="tabName" [required]="true">
                    </p3solved-form-text>
                    <div *ngIf="tabForm.touched && tabForm.invalid" class="invalid-feedback">
                        Tab Name is required
                    </div>
                </div>

            </form>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btn55955" label="Save" (buttonClick)="saveTab();"
                [disabled]="editTab.saving"></p3solved-button>
            <p3solved-button id="btnuluuu" label="Cancel" (buttonClick)="editTab.show = false;" class="outline"
                [disabled]="editTab.saving"></p3solved-button>
        </div>
    </div>
</p3solved-dialog>
<p3solved-dialog [isVisible]="editPage.show" [header]="editPage.header" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="editPage.show = false" [style]="{'width': '25vw', 'height': '50vh'}">
    <div body>
        <div class="w-12">
            <form [formGroup]="pageForm">
                <div class="m-2">
                    <p3solved-dropdown id="ddlole" [items]="entities.tabs" optionLabel="SiteTabName"
                        placeholder="Site Tab" optionValue="SiteTabID" [showClear]="false" [required]="true"
                        formControlName="SiteTabID">
                    </p3solved-dropdown>
                </div>
                <div class="m-2">
                    <p3solved-form-text id="" placeholder="Page Name" formControlName="SitePageName" [required]="true">
                    </p3solved-form-text>
                </div>

            </form>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btn5555" label="Save" (buttonClick)="savePage();"
                [disabled]="editPage.saving"></p3solved-button>
            <p3solved-button id="btnuuuu" label="Cancel" (buttonClick)="editPage.show = false;" class="outline"
                [disabled]="editPage.saving"></p3solved-button>
        </div>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="editFeature.show" [header]="editFeature.header" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="editFeature.show = false" [style]="{'width': '25vw', 'height': '50vh'}">
    <div body>
        <div class="w-12">
            <form [formGroup]="featureForm">
                <div class="m-2">
                    <p3solved-dropdown id="ddlofle" [items]="entities.pages" optionLabel="SitePageName"
                        placeholder="Site Page" optionValue="SitePageID" [showClear]="false" [required]="true"
                        formControlName="SitePageId">
                    </p3solved-dropdown>
                </div>
                <div class="m-2">
                    <p3solved-form-text id="" placeholder="Feature Name" formControlName="SiteFeatureName" [required]="true">
                    </p3solved-form-text>
                </div>
                <div class="m-2">
                    <p3solved-form-text id="" placeholder="Feature Code" formControlName="SiteFeatureCode" [required]="true">
                    </p3solved-form-text>
                </div>

            </form>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btn5555" label="Save" (buttonClick)="saveFeature();"
                [disabled]="editFeature.saving"></p3solved-button>
            <p3solved-button id="btnuuuu" label="Cancel" (buttonClick)="editFeature.show = false;" class="outline"
                [disabled]="editFeature.saving"></p3solved-button>
        </div>
    </div>
</p3solved-dialog>
<p-confirmDialog [style]="{width: '45vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>