import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConfigs } from 'src/app/configs/app-config';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { CustomerProviderModel } from 'src/app/models/customer-provider-model';
import { OtherFormGroup } from 'src/app/models/form-groups';
import { ProviderInfo } from 'src/app/models/provider-info';
import { ApiService } from 'src/app/services/api.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-provider-other',
  templateUrl: './provider-other.component.html',
  styleUrls: ['./provider-other.component.scss']
})
export class ProviderOtherComponent implements OnInit {

  private _provider: CustomerProviderModel | null = null;
  @Input() set provider(value: CustomerProviderModel | null) { this._provider = value; }
  get provider(): CustomerProviderModel | null { return this._provider; }
  
  lookups: ICustomerLookups = {} as ICustomerLookups;
  providerInfo: ProviderInfo | null = null;
  otherForm: FormGroup<OtherFormGroup> = {} as FormGroup;
  
  formInStorage: boolean = false;
  toggleOptions: any[] = [{label: 'Yes', value: true}, {label: 'No', value: false}]

  constructor(
    private apiService: ApiService,
    private providerService: ProviderService,
    private toastService: ToastService
    ) { }

  ngOnInit(): void {
    this.createForm();
    this.loadFromStorage();

    let lookupSub = this.providerService.customerLookups$
    .subscribe({
      next: (lookups: ICustomerLookups) => {
        this.lookups = lookups;
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get Customer information. See log for details'
        }, 'center');
        console.error(err);
      },
      complete: () => { lookupSub.unsubscribe(); }
    });

    let provInfoSub = this.providerService.infoSectionProvider$
      .subscribe({
        next: (provider: ProviderInfo | null) => { 
          this.providerInfo = provider;
          if (!this.formInStorage) {
            this.loadOtherDate();
          }
        },
        error: (err: any) => { 
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Customer information. See log for details'
          });
          console.error(err);
        },
        complete: () => { provInfoSub.unsubscribe(); }
      });


    this.otherForm.valueChanges
    .subscribe(value => { 
      this.setAllyFieldValidators(value.allyEnabled);
      localStorage.setItem(AppConfigs.otherForm, JSON.stringify(this.otherForm.value));
    });
  }
  
  createForm() {
    this.otherForm = new FormGroup<OtherFormGroup>({
      allyEnabled: new FormControl<boolean>(false, {nonNullable: true}),
      blockPOAdd: new FormControl<boolean>(false, {nonNullable: true}),
      singleElemLoan: new FormControl<boolean>(false, {nonNullable: true}),
      procPayState: new FormControl<boolean>(false, {nonNullable: true}),
      more1PayDay: new FormControl<boolean>(false, {nonNullable: true}),
      clarityLive: new FormControl<boolean>(false, {nonNullable: true}),
      autoAssignApps: new FormControl<boolean>(false, {nonNullable: true}),
      clarityFile: new FormControl<string>('', {nonNullable: true}),
      clarityVersion: new FormControl<string>('', {nonNullable: true}),
      clarityLocation: new FormControl<string>('', {nonNullable: true}),
      clarityPurpose: new FormControl<string>('', {nonNullable: true}),
      clarityTradeline: new FormControl<string>('', {nonNullable: true}),
      appsPerRep: new FormControl<number>(0, {nonNullable: true}),
      waitReset: new FormControl<number>(0, {nonNullable: true}),
      repInactivity: new FormControl<number>(0, {nonNullable: true}),
      productType: new FormControl<string>('2', {nonNullable: true}),
      loanAdjust: new FormControl<string>('1', {nonNullable: true}),
      serviceDays: new FormControl<number>(0, {nonNullable:true, validators: [Validators.min(0), Validators.max(365)]}),
      inactivityTimeout: new FormControl<number>(0, {nonNullable:true}),
      incompleteAppExpiry: new FormControl<number>(0, {nonNullable:true}),
      allyMerchantId: new FormControl<string>('', {nonNullable: true}),
      allyApiKey: new FormControl<string>('', {nonNullable: true}),
    });
  }

  loadFromStorage() {
    this.formInStorage = false;
    let storage = localStorage.getItem(AppConfigs.otherForm);
    if (storage) {
      this.formInStorage = true;
      this.otherForm.setValue(JSON.parse(storage));
    }    
  }

  setAllyFieldValidators(allyEnabled: boolean | undefined) {
    let merchId = this.otherForm.controls.allyMerchantId;
    let apiKey = this.otherForm.controls.allyApiKey;

    if (allyEnabled) {
      merchId.setValidators([Validators.required, Validators.maxLength(100)]);
      apiKey.setValidators([Validators.required, Validators.maxLength(250)]);
    }
    else {
      merchId.clearValidators();      
      apiKey.clearValidators();
    }
  }

  loadOtherDate() {
    this.otherForm.patchValue({
      allyEnabled: this.providerInfo?.isAllyEnabled,
      blockPOAdd: this.providerInfo?.blockPOBoxAddress ?? false,
      singleElemLoan: this.providerInfo?.saveLoanDetailsOnSingleElement,
      procPayState: this.providerInfo?.processPaymentStatment,
      more1PayDay: this.providerInfo?.allowMoreThan1PaymentPerDay,
      clarityLive: this.providerInfo?.clarityLive,
      autoAssignApps: this.providerInfo?.vQ_AutoAssignApps,
      clarityFile: this.providerInfo?.clarity_xml_control_file_name,
      clarityVersion: this.providerInfo?.clarity_xml_control_file_version_number,
      clarityLocation: this.providerInfo?.clarity_xml_location_id || '',
      clarityPurpose: this.providerInfo?.clarity_xml_inquiry_purpose_type || '',
      clarityTradeline: this.providerInfo?.clarity_xml_inquiry_tradeline_type || '',
      appsPerRep: this.providerInfo?.vQ_MaxAppsPerRep,
      waitReset: this.providerInfo?.vQ_MinWaitResetApp,
      repInactivity: this.providerInfo?.vQ_RepInactivityApp,
      productType: this.providerInfo?.offerProductTypeID.toString() || '2',
      loanAdjust: this.providerInfo?.bopAmountAdjustmentTypeID.toString() || '1',
      serviceDays: this.providerInfo?.adjustServiceDateDays || 0,
      incompleteAppExpiry: this.providerInfo?.incompleteAppExpiry || 0,
      inactivityTimeout: this.providerInfo?.inactivityTimeout || 0,
      allyMerchantId: this.providerInfo?.ally_MerchantId || '',
      allyApiKey: this.providerInfo?.allyApiKey || ''
    });
  }
  
  saveOther() { 
    let provider = {...this.providerInfo};
    provider.isAllyEnabled = this.otherForm.value.allyEnabled || false;
    provider.blockPOBoxAddress = this.otherForm.value.blockPOAdd || false;
    provider.saveLoanDetailsOnSingleElement = this.otherForm.value.singleElemLoan || false;
    provider.processPaymentStatment = this.otherForm.value.procPayState || false;
    provider.allowMoreThan1PaymentPerDay = this.otherForm.value.more1PayDay || false;
    provider.clarity_xml_control_file_name = this.otherForm.value.clarityFile || '';
    provider.clarity_xml_control_file_version_number;
    provider.clarity_xml_location_id = this.otherForm.value.clarityLocation || '';
    provider.clarity_xml_inquiry_purpose_type = this.otherForm.value.clarityPurpose || '';
    provider.clarity_xml_inquiry_tradeline_type = this.otherForm.value.clarityTradeline || '';
    provider.clarityLive = this.otherForm.value.clarityLive || false;
    provider.vQ_AutoAssignApps = this.otherForm.value.autoAssignApps || false;
    provider.vQ_MaxAppsPerRep = this.otherForm.value.appsPerRep || -1;
    provider.vQ_MinWaitResetApp = this.otherForm.value.waitReset || -1;
    provider.vQ_RepInactivityApp = this.otherForm.value.repInactivity || -1;
    provider.offerProductTypeID = this.otherForm.value.productType ? +this.otherForm.value.productType : 2;
    provider.bopAmountAdjustmentTypeID = this.otherForm.value.loanAdjust ? +this.otherForm.value.loanAdjust : 1;
    provider.adjustServiceDateDays = this.otherForm.value.serviceDays || 0;
    provider.incompleteAppExpiry = this.otherForm.value.incompleteAppExpiry || 0;
    provider.inactivityTimeout = this.otherForm.value.inactivityTimeout || 0;
    provider.ally_MerchantId = this.otherForm.value.allyMerchantId;
    provider.allyApiKey = this.otherForm.value.allyApiKey;

    let otherSub = this.apiService.post('provider/other', provider)
    .subscribe({
      next: () => { 
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Customer Information successfully updated.'
        });
        this.providerService.loadInfoSectionProvider((this.provider?.customerGuid || ''));
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save Customer Information. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { otherSub.unsubscribe(); }
    });
  }

}
