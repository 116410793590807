<p>Access Location IP-s</p>
<form [formGroup]="securityForm">
    <div class="d-flex flex-row mb-3">
        <div class="p-2">
            <p-radioButton name="AnyIp" [value]="1" label="All IP-s" formControlName="AnyIp"></p-radioButton>
        </div>
        <div class="p-2">
            <p-radioButton name="AnyIp" [value]="2" label="Distinct IPs" formControlName="AnyIp"></p-radioButton>
        </div>
    </div>
    <div *ngIf="!anyIp">
        <p>Distinct IPs</p>
        <div *ngFor="let ip of distinctIps; let i=index;" class="d-flex flex-row mb-2">
            <div class="p-1">{{i + 1}}</div>
            <div class="p-1">{{ip}}</div>
            <div class="p-1"><i class="pi pi-trash text-primary cursor-pointer" title="Delete" aria-hidden="true" (click)="distinctIps.splice(i, 1)"></i></div>
        </div>

        <div class="d-flex flex-row mb-2">
            <div class="p-1">
                <input [ngClass]="newIp.touched && newIp.invalid ? 'is-invalid': ''" type="text" class="form-control" [formControl]="newIp" />
                <div *ngIf="newIp.touched && newIp.invalid" class="invalid-feedback">
                    IP is not valid
                  </div>
            </div>
            <div class="p-1">
                <button class="btn btn-light btn-sm" (click)="addnewIp()">add new</button>
            </div>
        </div>
    </div>
    <p class="m-2"><strong>Access Time</strong></p>
    <div class="mb-3">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Day</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">End Time</th>
                </tr>
            </thead>
            <tbody>
                <ng-container formArrayName="rangeForms">
                    <ng-container *ngFor="let rng of schedules.controls; let i = index">
                        <tr [formGroup]="rng">
                            <th scope="row">{{getDay(i)}}</th>
                            <td>
                                <select class="form-select form-select-sm" formControlName="start" (change)="updateSchedules()">
                                    <option *ngFor="let a of accessTimes" [ngValue]="a.id">
                                        {{ a.desc }}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select class="form-select form-select-sm" formControlName="end" (change)="updateSchedules()">
                                    <option *ngFor="let a of accessTimes" [ngValue]="a.id">
                                        {{ a.desc }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</form>