import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { combineLatest, Subscription } from 'rxjs';
import { CustomGridComponent } from 'src/app/components/custom-grid/custom-grid.component';
import { Campaign } from 'src/app/models/campaign';
import { CampaignVersionRow } from 'src/app/models/campaign-version';
import { PortfolioItem } from 'src/app/models/customer-item';
import { VersionFormGroup } from 'src/app/models/form-groups';
import { ApiService } from 'src/app/services/api.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-campaign-versions',
  templateUrl: './campaign-versions.component.html',
  styleUrls: ['./campaign-versions.component.scss']
})
export class CampaignVersionsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  bcItems: MenuItem[] = [];

  guidsLoaded: boolean = true;
  showVersionsCard: boolean = false;
  showEditCard: boolean = false;
  leftNavExpanded: boolean = false;

  editCardTitle: string = 'Add New';

  selectedPortfolio: PortfolioItem | null = null;
  selectedCampaign: Campaign | null = null;

  versionForm: FormGroup<VersionFormGroup> = {} as FormGroup;
  versionFormLoaded: boolean = false;

  @ViewChild('cgVersions') cgVersions: CustomGridComponent = {} as CustomGridComponent;

  constructor(
    private portfolioService: PortfolioService,
    private campaignService: CampaignService,
    private apiService: ApiService,
    private toastService: ToastService,
    private navService: NavService
  ) { }


  ngOnInit(): void {
    this.subscriptions.push(
      this.navService.leftNavExpanded$.subscribe((isExpanded: boolean) => {
        this.leftNavExpanded = isExpanded;
      })
    );
    let combSub = combineLatest([this.portfolioService.portfolio$, this.campaignService.selectedCampaign$])
      .subscribe({
        next: ([p, c]) => {
          this.selectedPortfolio = p;
          this.selectedCampaign = c;

          if (p && c) {
            this.guidsLoaded = true;
            this.showVersionsCard = true;
          }
          else {
            setTimeout(() => {                
              if (!this.selectedPortfolio || !this.selectedCampaign) {                
                this.guidsLoaded = false;
              }              
            }, 500);
          }

        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get customer and/or campaign'
          });
          console.error(err);
        },
        complete: () => { combSub.unsubscribe(); }
      });

    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Campaigns', routerLink: ['/campaign'], command: () => { this.navService.setLeftNavSelection('Campaign'); } },
      { label: 'Versions' }
    ];
  }

  showVersionAdd() {
    this.createVersionForm();
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showEditCard = false; this.showVersionsCard = true; this.versionFormLoaded = false; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: 'Version Add' });

    this.editCardTitle = 'Add New';
    this.showEditCard = true;
    this.showVersionsCard = false;
  }

  editVersion(row: CampaignVersionRow) {
    this.createVersionForm();
    this.versionForm.patchValue({
      versionGuid: row.VersionGUID,
      name: row.VersionName,
      description: row.VersionDescription,
      custom1: row.Custom1_Version,
      custom2: row.Custom2_Version,
      custom3: row.Custom3_Version,
      custom4: row.Custom4_Version,
      custom5: row.Custom5_Version,
      custom6: row.Custom6_Version,
      custom7: row.Custom7_Version,
      custom8: row.Custom8_Version,
      custom9: row.Custom9_Version,
      custom10: row.Custom10_Version,
      custom11: row.Custom11_Version,
      custom12: row.Custom12_Version,
      custom13: row.Custom13_Version,
      custom14: row.Custom14_Version,
      custom15: row.Custom15_Version,
      custom16: row.Custom16_Version,
      custom17: row.Custom17_Version,
      custom18: row.Custom18_Version,
      custom19: row.Custom19_Version,
      custom20: row.Custom20_Version,
      upsell: row.UpSellAmountOptions,
      laserCode: row.LaserCode,
      remailCode: row.Remail_EnvelopePrintCode
    })
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showEditCard = false; this.showVersionsCard = true; this.versionFormLoaded = false; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: 'Version Edit' });

    this.editCardTitle = 'Edit';
    this.showEditCard = true;
    this.showVersionsCard = false;
  }

  createVersionForm() {
    this.versionForm = new FormGroup({
      versionGuid: new FormControl<string>(uuidv4(), {nonNullable: true}),
      name: new FormControl<string>('', {nonNullable:true, validators: [Validators.required]}),
      description: new FormControl<string>('', {nonNullable:true}),
      custom1: new FormControl<string>('', {nonNullable: true}),
      custom2: new FormControl<string>('', {nonNullable: true}),
      custom3: new FormControl<string>('', {nonNullable: true}),
      custom4: new FormControl<string>('', {nonNullable: true}),
      custom5: new FormControl<string>('', {nonNullable: true}),
      custom6: new FormControl<string>('', {nonNullable: true}),
      custom7: new FormControl<string>('', {nonNullable: true}),
      custom8: new FormControl<string>('', {nonNullable: true}),
      custom9: new FormControl<string>('', {nonNullable: true}),
      custom10: new FormControl<string>('', {nonNullable: true}),
      custom11: new FormControl<string>('', {nonNullable: true}),
      custom12: new FormControl<string>('', {nonNullable: true}),
      custom13: new FormControl<string>('', {nonNullable: true}),
      custom14: new FormControl<string>('', {nonNullable: true}),
      custom15: new FormControl<string>('', {nonNullable: true}),
      custom16: new FormControl<string>('', {nonNullable: true}),
      custom17: new FormControl<string>('', {nonNullable: true}),
      custom18: new FormControl<string>('', {nonNullable: true}),
      custom19: new FormControl<string>('', {nonNullable: true}),
      custom20: new FormControl<string>('', {nonNullable: true}),
      upsell: new FormControl<string>('', {nonNullable: true}),
      laserCode: new FormControl<string>('', {nonNullable: true}),
      remailCode: new FormControl<string>('', {nonNullable: true}),
    });
    this.versionFormLoaded = true;
  }

  saveVersion() {
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      campaignGuid: this.selectedCampaign?.campaignGuid,
      ...this.versionForm.value
    };

    let versSub = this.apiService.post(`campaign/version`, body)
    .subscribe({
      next: () => {  
        this.bcItems.pop();
        let currItem = this.bcItems.pop();
        if (currItem) {
          currItem.command = () => { };
          this.bcItems.push(currItem);
        }
        this.showEditCard = false; 
        this.showVersionsCard = true; 
        this.versionFormLoaded = false;
        this.toastService.add({          
          severity: 'success',
          summary: 'Success',
          detail: 'Campaign Version successfully saved.'
        });
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save Campaign Version. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { versSub.unsubscribe(); }   
    });
  }

  removeBcCommand(label: string | undefined) {
    if (!label) return;
    let item = this.bcItems.find(b => b.label === label);
    if (item) {
      let index = this.bcItems.indexOf(item);
      item.command = undefined;
      this.bcItems.splice(index, 1, item);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        let sub = this.subscriptions[i];
        sub.unsubscribe();
      }
    }
  }

}
