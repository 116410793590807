<form [formGroup]="settingsForm">
    <div class="grid m-4">
        <div class="col-12">
            <p3solved-dropdown id="ddlolk" [items]="customers" optionLabel="desc" 
            (optionSelected)="onCustomerChange($event);"
            placeholder="Customer" optionValue="value" [showClear]="false" [required]="true"
            formControlName="CustomerID">
        </p3solved-dropdown>
        </div>
        <div class="d-flex flex-row mb-5">
            <div class="m-3">
                <p class="h4">Assigned Clients</p>
                <div class="card">
                    <div class="card-body list-container">
                        <app-checkbox-list [items]="assigned" (onSelect)="onSelectChange($event, 'unassignClient')"></app-checkbox-list>
                    </div>
                </div>
                
            </div>
            <div class="m-3">
                <div class="d-flex align-items-center assign-controls">
                    <div class="d-flex flex-column">
                        <div class="m-2">
                            <button class="btn btn-light btn-assign">
                                <i class="pi pi-angle-double-right text-primary cursor-pointer mr-4" title="Assign" aria-hidden="true"
                                (click)="moveCustomer('unassign')"></i>
                            </button>
                        </div>
                        <div class="m-2">
                            <button class="btn btn-light btn-assign">
                                <i class="pi pi-angle-double-left text-primary cursor-pointer mr-4" title="UnAssign" aria-hidden="true"
                                (click)="moveCustomer('assign')"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="m-3">
                <p class="h4">Available Clients</p>
                <div class="card">
                    <div class="card-body list-container">
                        <app-checkbox-list [items]="available" (onSelect)="onSelectChange($event, 'assignClient')"></app-checkbox-list>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="d-flex flex-row mb-3">
            <div class="m-3">
                <p class="h4">Assigned Branches</p>
                <div class="card">
                    <div class="card-body list-container">
                        <app-checkbox-list [items]="assignedBranches" (onSelect)="onSelectChange($event, 'unassignBranch')"></app-checkbox-list>
                    </div>
                </div>
                
            </div>
            <div class="m-3">
                <div class="d-flex align-items-center assign-controls">
                    <div class="d-flex flex-column">
                        <div class="m-2">
                            <button class="btn btn-light btn-assign">
                                <i class="pi pi-angle-double-right text-primary cursor-pointer mr-4" title="Assign" aria-hidden="true"
                                (click)="moveBranch('unassign')"></i>
                            </button>
                        </div>
                        <div class="m-2">
                            <button class="btn btn-light btn-assign">
                                <i class="pi pi-angle-double-left text-primary cursor-pointer mr-4" title="UnAssign" aria-hidden="true"
                                (click)="moveBranch('assign')"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="m-3">
                <p class="h4">Available Branches</p>
                <div class="card">
                    <div class="card-body list-container">
                        <app-checkbox-list [items]="availableBranches" (onSelect)="onSelectChange($event, 'assignBranch')"></app-checkbox-list>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="col-12 mb-5">
            <p3solved-dropdown id="ddlolks" [items]="userTypes" optionLabel="desc"
            (optionSelected)="onUserTypeChange($event);"
             placeholder="User Type" optionValue="id" [showClear]="false" [required]="true"
            formControlName="UserTypeID">
        </p3solved-dropdown>
        </div>
    </div>
</form>