<div *ngIf="!guidsLoaded">
    <div class="text-3xl text-red-500">
        You MUST select a Customer and Campaign before accessing this page.
    </div>
</div>

<div *ngIf="guidsLoaded && showVersionsCard">
    <span class="text-2xl font-medium">Version List</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4" [ngClass]="leftNavExpanded ? 'max-w-86':'max-w-92'">
            <div class="flex flex-row-reverse">
                <p3solved-button id="btnNewVersion" label="Add Version" (buttonClick)="showVersionAdd()"></p3solved-button>
            </div>
            <div class="w-12">
                <app-custom-grid #cgVersions id="cgVersions"
                    DataSourceName="dsAdminCampaignVersion" (editRow)="editVersion($event)">
                </app-custom-grid>
            </div>
        </div>
    </div>
</div>

<div *ngIf="guidsLoaded && showEditCard">
    <span class="text-2xl font-medium">{{editCardTitle}} Version</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <form *ngIf="versionFormLoaded" [formGroup]="versionForm" (ngSubmit)="saveVersion()">
                <div class="grid">
                    <div class="col-6">
                        <p3solved-form-text id="txtName" placeholder="Version Name" [required]="true"
                            formControlName="name">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtDesc" placeholder="Version Description"
                            formControlName="description">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom1" placeholder="Custom 1 Version" 
                            formControlName="custom1">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom2" placeholder="Custom 2 Version" 
                            formControlName="custom2">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom3" placeholder="Custom 3 Version" 
                            formControlName="custom3">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom4" placeholder="Custom 4 Version" 
                            formControlName="custom4">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom5" placeholder="Custom 5 Version" 
                            formControlName="custom5">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom6" placeholder="Custom 6 Version" 
                            formControlName="custom6">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom7" placeholder="Custom 7 Version" 
                            formControlName="custom7">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom8" placeholder="Custom 8 Version" 
                            formControlName="custom8">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom9" placeholder="Custom 9 Version" 
                            formControlName="custom9">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom10" placeholder="Custom 10 Version" 
                            formControlName="custom10">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom11" placeholder="Custom 11 Version" 
                            formControlName="custom11">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom12" placeholder="Custom 12 Version" 
                            formControlName="custom12">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom13" placeholder="Custom 13 Version" 
                            formControlName="custom13">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom14" placeholder="Custom 14 Version" 
                            formControlName="custom14">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom15" placeholder="Custom 15 Version" 
                            formControlName="custom15">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom16" placeholder="Custom 16 Version" 
                            formControlName="custom16">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom17" placeholder="Custom 17 Version" 
                            formControlName="custom17">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom18" placeholder="Custom 18 Version" 
                            formControlName="custom18">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom19" placeholder="Custom 19 Version" 
                            formControlName="custom19">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtCustom20" placeholder="Custom 20 Version" 
                            formControlName="custom20">
                        </p3solved-form-text>
                    </div>

                    <div class="col-6">
                        <p3solved-form-text id="txtUpsell" placeholder="UpSell Amount Options"
                            formControlName="upsell">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtLaserCode" placeholder="Laser Code"
                            formControlName="laserCode">
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        <p3solved-form-text id="txtRemailCode" placeholder="Remail Envelope Print Code"
                            formControlName="remailCode">
                        </p3solved-form-text>
                    </div>
                    <div class="col-12 pt-2 flex flex-row-reverse">
                        <p3solved-button buttonType="submit" id="btnSaveForm">
                            <span class="px-4">Save</span>
                        </p3solved-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>