import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppConfigs } from "../configs/app-config";
import { IUserGuids } from "../models/user-guids";
import { AuthService } from "./auth.service";


@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        var userGuid = '';
        var jsonUser = localStorage.getItem(AppConfigs.loggedInuserGuid);
        if (jsonUser) {
            var result = <IUserGuids>JSON.parse(jsonUser);
            userGuid = result.loggedUserGuid || '';
        }
        var customerGuid: string | null = localStorage.getItem(AppConfigs.selectedCampaignStorageKey);
        const authReq = req.clone({
            headers: req.headers.set('loggedInUserId', userGuid).set('customerGuid', customerGuid || '')
        });
        return next.handle(authReq)
        .pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.auth.logout();
                    return throwError(new Error("Unauthorized"));
                }
                else {
                    return throwError(err);
                }
            })
        );
    }
}