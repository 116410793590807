import { Injectable } from '@angular/core';
import { LookupModel } from '../models/lookup-model';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor() { }

  getCommTimeList(): LookupModel[] {
    var result = [
      { id: '-1', desc: '-- Select -- ' },
      { id: '00:00:00', desc: '12:00 AM' },
      { id: '00:30:00', desc: '12:30 AM' }
    ];
    var h = '';
    var hv = '';
    for (var i = 1; i < 13; i++) {
      h = i.toString();
      hv = `${i < 10 ? '0' : ''}${h}`;
      result.push({ id: `${hv}:00:00`, desc: `${h}:00 AM` });
      result.push({ id: `${hv}:30:00`, desc: `${h}:30 AM` });
    }

    for (var i = 13; i < 24; i++) {
      h = (i - 12).toString();
      hv = i.toString();
      result.push({ id: `${hv}:00:00`, desc: `${h}:00 PM` });
      result.push({ id: `${hv}:30:00`, desc: `${h}:30 PM` });
    }

    result.push({ id: '23:59:59', desc: '11:59 PM' });
    return result;
  }

  getStates(): LookupModel[] {
    var states = [
      { id: 'AL', desc: 'Alabama' },
      { id: 'AK', desc: 'Alaska' },
      { id: 'AZ', desc: 'Arizona' },
      { id: 'AR', desc: 'Arkansas' },
      { id: 'CA', desc: 'California' },
      { id: 'CO', desc: 'Colorado' },
      { id: 'CT', desc: 'Connecticut' },
      { id: 'DE', desc: 'Delaware' },
      { id: 'DC', desc: 'District Of Columbia' },
      { id: 'FL', desc: 'Florida' },
      { id: 'GA', desc: 'Georgia' },
      { id: 'HI', desc: 'Hawaii' },
      { id: 'ID', desc: 'Idaho' },
      { id: 'IL', desc: 'Illinois' },
      { id: 'IN', desc: 'Indiana' },
      { id: 'IA', desc: 'Iowa' },
      { id: 'KS', desc: 'Kansas' },
      { id: 'KY', desc: 'Kentucky' },
      { id: 'LA', desc: 'Louisiana' },
      { id: 'ME', desc: 'Maine' },
      { id: 'MD', desc: 'Maryland' },
      { id: 'MA', desc: 'Massachusetts' },
      { id: 'MI', desc: 'Michigan' },
      { id: 'MN', desc: 'Minnesota' },
      { id: 'MS', desc: 'Mississippi' },
      { id: 'MO', desc: 'Missouri' },
      { id: 'MT', desc: 'Montana' },
      { id: 'NE', desc: 'Nebraska' },
      { id: 'NV', desc: 'Nevada' },
      { id: 'NH', desc: 'New Hampshire' },
      { id: 'NJ', desc: 'New Jersey' },
      { id: 'NM', desc: 'New Mexico' },
      { id: 'NY', desc: 'New York' },
      { id: 'NC', desc: 'North Carolina' },
      { id: 'ND', desc: 'North Dakota' },
      { id: 'OH', desc: 'Ohio' },
      { id: 'OK', desc: 'Oklahoma' },
      { id: 'OR', desc: 'Oregon' },
      { id: 'PA', desc: 'Pennsylvania' },
      { id: 'RI', desc: 'Rhode Island' },
      { id: 'SC', desc: 'South Carolina' },
      { id: 'SD', desc: 'South Dakota' },
      { id: 'TN', desc: 'Tennessee' },
      { id: 'TX', desc: 'Texas' },
      { id: 'UT', desc: 'Utah' },
      { id: 'VT', desc: 'Vermont' },
      { id: 'VA', desc: 'Virginia' },
      { id: 'WA', desc: 'Washington' },
      { id: 'WV', desc: 'West Virginia' },
      { id: 'WI', desc: 'Wisconsin' },
      { id: 'WY', desc: 'Wyoming' }
    ]
    return states;
  }

  getYesNo(): LookupModel[] {
    var arr = [
      { id: false, desc: 'No' },
      { id: true, desc: 'Yes' }
    ]
    return arr;
  }
}
