import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-offer-lookup',
  templateUrl: './edit-offer-lookup.component.html',
  styleUrls: ['./edit-offer-lookup.component.scss']
})
export class EditOfferLookupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
