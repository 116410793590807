import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { Campaign } from 'src/app/models/campaign';
import { ApiService } from 'src/app/services/api.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { PortfolioItem } from 'src/app/models/customer-item';
import { AlertTime, CampaignAlertDetail, CampaignAlertItem } from 'src/app/models/campaign-alert';
import { AlertFormGroup } from 'src/app/models/form-groups';
import { AlertDelay, AlertLoanStatus, AlertLookups } from 'src/app/models/lookup-model';
import { CampaignNotificationSelect } from 'src/app/models/campaign-notifications';
import { NotificationType } from 'src/app/models/enums';
import { NavService } from 'src/app/services/nav-service.service';

@Component({
  selector: 'app-campaign-alerts',
  templateUrl: './campaign-alerts.component.html',
  styleUrls: ['./campaign-alerts.component.scss']
})
export class CampaignAlertsComponent implements OnInit {

  loading: boolean = false;
  hasError: boolean = false;
  showList: boolean = true;
  showEdit: boolean = false;


  bcItems: MenuItem[] = [];
  columnHeaders: string[] = ['isGeneric', 'notificationName', 'type', 'emailSubject'];
  globalFilters: string[] = ['isGeneric', 'notificationName', 'type', 'emailSubject'];

  campaigns: Campaign[] = [];
  campaignGuid: string | null = null;
  selectedCampaign: Campaign | null = null;
  selectedPortfolio: PortfolioItem | null = null;


  delays: AlertDelay[] = [];
  loanStatuses: AlertLoanStatus[] = [];
  disableBusinessDelay: boolean = true;

  times: AlertTime[] = [];

  emailList: CampaignNotificationSelect[] = [];
  smsList: CampaignNotificationSelect[] = [];
  serverActList: CampaignNotificationSelect[] = [];
  webhookList: CampaignNotificationSelect[] = [];

  alertForm: FormGroup<AlertFormGroup> = {} as FormGroup;

  alertToEdit: CampaignAlertItem | null = null;

  constructor(
    private fb: FormBuilder,
    private campaignService: CampaignService,
    private portfolioService: PortfolioService,
    private toastService: ToastService,
    private apiService: ApiService,
    private navService: NavService
  ) { }

  ngOnInit(): void {
    this.loadBookMarks();
    this.loadTimes();
    let combSub = combineLatest([this.portfolioService.portfolio$, this.campaignService.selectedCampaign$])
      .subscribe({
        next: ([p, c]) => {
          this.selectedPortfolio = p;
          this.selectedCampaign = c;
          if (c) {
            this.campaignGuid = c.campaignGuid;
          }
          else {
            this.campaignGuid = null;
          }

          if (p && c) {
            this.getAlertLookups();
            this.getAlertList();
          }

        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get customer and/or campaign'
          });
          console.error(err);
        },
        complete: () => { combSub.unsubscribe(); }
      });


  }

  loadTimes() {
    this.times = [];
    for (let k: number = 0; k <= 23; k++) {
      for (let q: number = 0; q < 60; q = q + 15) {
        let time: string = "";
        if (k == 0) time = "12";
        else if (k <= 12) time = k.toString();
        else time = (k % 12).toString();

        time += ":" + (q >= 10 ? q.toString() : "0" + q.toString());
        time += (k < 12 ? " AM" : " PM");

        this.times.push({
          label: time,
          value: (k * 60 + q).toString()
        })
      }
    }
  }

  getAlertLookups() {
    let lookSub = this.apiService.get(`alert/alert-lookups/${this.selectedPortfolio?.customerGuid}`)
      .subscribe({
        next: (lookups: AlertLookups) => {
          this.delays = lookups.delays;
          this.loanStatuses = lookups.loanAppStatuses;
          this.createForm();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error getting alert lookups. See log for details'
          });
          console.error(err);
        },
        complete: () => { lookSub.unsubscribe(); }
      })
  }

  getAlertList() {
    let notiSub = this.campaignService.getNotifications()
      .subscribe({
        next: (notifications: CampaignNotificationSelect[]) => {
          this.emailList = notifications.filter(n => n.notificationTypeID == NotificationType.Email && (!n.isGeneric || n.campaignID == (+this.selectedCampaign!.campaignId ?? -1)));
          this.smsList = notifications.filter(n => n.notificationTypeID == NotificationType.SMS && (!n.isGeneric || n.campaignID == (+this.selectedCampaign!.campaignId ?? -1)));
          this.serverActList = notifications.filter(n => n.notificationTypeID == NotificationType.ServerAction && (!n.isGeneric || n.campaignID == (+this.selectedCampaign!.campaignId ?? -1)));
          this.webhookList = notifications.filter(n => n.notificationTypeID == NotificationType.Webhook && (!n.isGeneric || n.campaignID == (+this.selectedCampaign!.campaignId ?? -1)));
        },
        error: (err: any) => { console.error(err); },
        complete: () => { notiSub.unsubscribe(); }
      });
  }

  loadBookMarks() {
    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Campaigns', routerLink: ['/campaign'], command: () => { this.navService.setLeftNavSelection('Campaign'); } },
      { label: 'Alerts' }
    ];
  }

  createForm() {
    this.alertForm = new FormGroup<AlertFormGroup>({
      generic: new FormControl<boolean>(false, { nonNullable: true, initialValueIsDefault: true }),
      delay: new FormControl<number>(this.delays[0].alertDelayID, { nonNullable: true, initialValueIsDefault: true, validators: [Validators.required] }),
      businessDayDelay: new FormControl<boolean>(false, { nonNullable: true }),
      sendOnce: new FormControl<boolean>(false, { nonNullable: true }),
      sendBusHours: new FormControl<boolean>(false, { nonNullable: true }),
      sendTargTime: new FormControl<boolean>(false, { nonNullable: true }),
      time: new FormControl<string | null>(null),
      triggerStatus: new FormControl<number | null>(null),
      resetStatus: new FormControl<number[] | null>(null),
      emails: new FormControl<number[] | null>(null),
      sms: new FormControl<number[] | null>(null),
      serverActs: new FormControl<number[] | null>(null),
      webhooks: new FormControl<number[] | null>(null),
      temp: new FormControl<boolean>(false, { nonNullable: true })
    }, {
      validators: [NotificationValidator()]
    });
  }

  colSelected(event: any) {
    // console.log(event);
  }

  editAlert(event: CampaignAlertItem) {
    let alertSub = this.campaignService.getAlertByGuid(event.AlertGUID)
      .subscribe({
        next: (data: CampaignAlertDetail) => {
          this.alertToEdit = event;
          let notificationIds: number[] | null = null;
          if (data.notificationID.length > 0) {
            notificationIds = data.notificationID
              .split(',')
              .filter(num => {
                if (num.trim() === '') return false;
                return !isNaN(+num);
              })
              .map(number => { return Number(number); });
          }

          let frmEmails = this.emailList.filter(el => notificationIds && notificationIds.indexOf(el.notificationID) > -1).map(l => { return l.notificationID });
          let frmSms = this.smsList.filter(el => notificationIds && notificationIds.indexOf(el.notificationID) > -1).map(l => { return l.notificationID });
          let frmServerActs = this.serverActList.filter(el => notificationIds && notificationIds.indexOf(el.notificationID) > -1).map(l => { return l.notificationID });
          let frmWebhooks = this.webhookList.filter(el => notificationIds && notificationIds.indexOf(el.notificationID) > -1).map(l => { return l.notificationID });

          let resetStatus: number[] | null = null;

          if (data.resetStatusIDs) {
            resetStatus = data.resetStatusIDs
              .split(',')
              .filter(num => {
                if (num.trim() === '') return false;
                return !isNaN(+num);
              })
              .map(number => { return Number(number); });
          }

          this.alertForm.patchValue({
            generic: false,
            delay: data.alertDelayID,
            businessDayDelay: data.alertDelayBusinessDays,
            sendOnce: data.sendOnlyOnce,
            sendBusHours: data.sendBusinessHours,
            sendTargTime: data.targetTimeZone,
            time: data.useSpecificTime ? data.specificTime : null,
            triggerStatus: data.triggerStatusID,
            resetStatus: resetStatus,
            emails: frmEmails.length > 0 ? frmEmails : null,
            sms: frmSms.length > 0 ? frmSms : null,
            serverActs: frmServerActs.length > 0 ? frmServerActs : null,
            webhooks: frmWebhooks.length > 0 ? frmWebhooks : null
          });
          this.alertForm.updateValueAndValidity();
          frmEmails.length > 0 ? this.notificiatonClicked('emails') :
            frmSms.length > 0 ? this.notificiatonClicked('sms') :
              frmServerActs.length > 0 ? this.notificiatonClicked('serverActs') :
                frmWebhooks.length > 0 ? this.notificiatonClicked('webhooks') : () => { };

          let prevItem = this.bcItems.pop();
          if (prevItem) {
            prevItem.command = () => { this.showEdit = false; this.showList = true; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
            this.bcItems.push(prevItem);
          }
          this.bcItems.push({
            label: 'Edit Alert'
          });
          this.showList = false;
          this.showEdit = true;
        },
        error: (err: any) => {
          console.error(err);
        },
        complete: () => { alertSub.unsubscribe(); }
      })
  }

  newAlert() {
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showEdit = false; this.showList = true; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({
      label: 'New Alert'
    });
    this.alertToEdit = null;
    this.alertForm.reset();
    this.showList = false;
    this.showEdit = true;
  }

  removeBcCommand(label: string | undefined) {
    if (!label) return;
    let item = this.bcItems.find(b => b.label === label);
    if (item) {
      let index = this.bcItems.indexOf(item);
      item.command = undefined;
      this.bcItems.splice(index, 1, item);
    }
  }

  checkBusinessDelay(event: number) {
    this.disableBusinessDelay = !this.delays.find(d => d.alertDelayID == event)?.delayBusinessDaysSupport;
  }

  resetAppStatusListBx() {
    this.alertForm.patchValue({ resetStatus: null })
  }

  resetEmailSendListBx() {
    this.alertForm.patchValue({ emails: null })
  }

  resetSmsSendListBx() {
    this.alertForm.patchValue({ sms: null })
  }

  resetWebhookListBx() {
    this.alertForm.patchValue({ webhooks: null })
  }

  resetSvrActListBx() {
    this.alertForm.patchValue({ serverActs: null })
  }

  notificiatonClicked(control: string) {
    switch (control) {
      case 'emails':
        this.alertForm.controls.emails.markAsTouched();
        break;
      case 'sms':
        this.alertForm.controls.sms.markAsTouched();
        break;
      case 'serverActs':
        this.alertForm.controls.serverActs.markAsTouched();
        break;
      case 'webhooks':
        this.alertForm.controls.webhooks.markAsTouched();
        break;
    }
    setTimeout(() => {
      this.alertForm.patchValue({ temp: true });
    }, 100);
  }

  cancelForm() {
    this.alertForm.reset();
    this.bcItems.pop();
    this.removeBcCommand('Alerts');
    this.showEdit = false;
    this.showList = true;
  }

  saveAlert() {
    let postNotifications: number[] = [];
    if (this.alertForm.value.emails) postNotifications.push(...this.alertForm.value.emails);
    if (this.alertForm.value.sms) postNotifications.push(...this.alertForm.value.sms);
    if (this.alertForm.value.serverActs) postNotifications.push(...this.alertForm.value.serverActs);
    if (this.alertForm.value.webhooks) postNotifications.push(...this.alertForm.value.webhooks);
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      campaignGuid: this.campaignGuid,
      alertGuid: this.alertToEdit?.AlertGUID ?? null,
      alertDelayId: this.alertForm.value.delay,
      delayBusinessDays: this.alertForm.value.businessDayDelay,
      sendOnlyOnce: this.alertForm.value.sendOnce,
      sendBusinessHours: this.alertForm.value.sendBusHours,
      targetTimeZone: this.alertForm.value.sendTargTime,
      specificTime: this.alertForm.value.time ?? null,
      triggerOnStatusIds: this.alertForm.value.triggerStatus ? this.alertForm.value.triggerStatus.toString() : null,
      resetByStatusIds: this.alertForm.value.resetStatus ? this.alertForm.value.resetStatus.join(',') : null,
      notificationIds: postNotifications.join(',')
    };

    let postSub = this.apiService.post(`alert/post-alert`, body)
    .subscribe({
      next: (alertId: number) => {
        if (alertId > 0) {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Alert successfully added'
          });
        }
        this.getAlertList();
        this.alertForm.reset();
        this.bcItems.pop();
        this.removeBcCommand('Alerts');
        this.showEdit = false;
        this.showList = true;
       },
      error: (err: any) => {
        console.error(err);
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save Alert. See log for details'
        }, 'center');
      },
      complete: () => { postSub.unsubscribe(); }      
    });
  }

}

export function NotificationValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let form = control as FormGroup;
    let hasError: boolean = false;
    let errors: any = null;

    let emailsCtrl = form.get('emails');
    let smsCtrl = form.get('sms');
    let serverActsCtrl = form.get('serverActs');
    let webhooksCtrl = form.get('webhooks');

    if (emailsCtrl?.untouched && smsCtrl?.untouched &&
      serverActsCtrl?.untouched && webhooksCtrl?.untouched) {

      form.controls['emails'].markAsPristine();
      form.controls['sms'].markAsPristine()
      form.controls['serverActs'].markAsPristine();
      form.controls['webhooks'].markAsPristine();
      return null;
    }

    if ((emailsCtrl?.touched || smsCtrl?.touched || serverActsCtrl?.touched || webhooksCtrl?.touched)
      && (emailsCtrl?.value == null && smsCtrl?.value == null && serverActsCtrl?.value == null && webhooksCtrl?.value == null)
    ) {

      hasError = true;
      errors = { 'notificationInvalid': true };

      form.controls['emails'].markAsDirty();
      form.controls['sms'].markAsDirty();
      form.controls['serverActs'].markAsDirty();
      form.controls['webhooks'].markAsDirty();

      form.controls['emails'].setErrors({ required: true });
      form.controls['sms'].setErrors({ required: true });
      form.controls['serverActs'].setErrors({ required: true });
      form.controls['webhooks'].setErrors({ required: true });
    }
    else {

      hasError = false;
      errors = null;
      form.controls['emails'].setErrors(null);
      form.controls['sms'].setErrors(null);
      form.controls['serverActs'].setErrors(null);
      form.controls['webhooks'].setErrors(null);
    }

    return errors;
  }
}
