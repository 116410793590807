import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { LookupModel } from 'src/app/models/lookup-model';
import { ProviderService } from 'src/app/services/provider.service';

@Component({
  selector: 'app-edit-offer-details',
  templateUrl: './edit-offer-details.component.html',
  styleUrls: ['./edit-offer-details.component.scss']
})
export class EditOfferDetailsComponent implements OnInit {
  minProcedureAmounts: LookupModel[] = [];
  maxProcedureAmounts: LookupModel[] = [];
  terms: LookupModel[] = [];
  mdrList: LookupModel[] = [];
  minDownPayments: LookupModel[] = [];
  maxDownPayments: LookupModel[] = [];
  aprList: LookupModel[] = [];
  minVantages: LookupModel[] = [];
  maxVantages: LookupModel[] = [];
  minPaymentAmounts: LookupModel[] = [];
  maxPaymentAmounts: LookupModel[] = [];
  loanAmounts: LookupModel[] = [];
  offerDetailsForm = this.fb.group({
    minLoanAmount: ['', Validators.required],
    maxLoanAmount: ['', Validators.required],
    minProcedureValue: ['', Validators.required],
    maxProcedureValue: ['', Validators.required],
    minApr: ['', Validators.required],
    maxApr: ['', Validators.required],
    minDownPayment: ['', Validators.required],
    maxDownPayment: ['', Validators.required],
    minTerm: ['', Validators.required],
    maxTerm: ['', Validators.required],
    minMdr: ['', Validators.required],
    maxMdr: ['', Validators.required],
    minVantage: ['', Validators.required],
    maxVantage: ['', Validators.required],
    minPaymentAmount: ['', Validators.required],
    maxPaymentAmount: ['', Validators.required]
  });
  constructor(private providerService: ProviderService, private fb: UntypedFormBuilder) {
    this.initLookups();
   }

  ngOnInit(): void {
  }

  initLookups(){
    this.providerService.customerLookups$.subscribe(
      (lookups: ICustomerLookups) => {
        this.minProcedureAmounts = lookups.minProcedureAmounts || [];
        this.maxProcedureAmounts = lookups.maxProcedureAmounts || [];
        this.terms = lookups.terms || [];
        this.mdrList = lookups.mdrList || [];
        this.minDownPayments = lookups.minDownPayments || [];
        this.maxDownPayments = lookups.maxDownPayments || [];
        this.aprList = lookups.aprList || [];
        this.minVantages = lookups.minVantages || [];
        this.maxVantages = lookups.maxVantages || [];
        this.minPaymentAmounts = lookups.minPaymentAmounts || [];
        this.maxPaymentAmounts = lookups.maxPaymentAmounts || [];
        this.loanAmounts = lookups.loanAmounts || [];
      });
  }

  onSubmit(){
    console.warn(this.offerDetailsForm.value);
  }
}
