import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus, AuthError } from '@azure/msal-browser';
import { faChevronDown, faSearch, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PortfolioItem } from './models/customer-item';
import { AuthService } from './services/auth.service';
import { IconService } from './services/icon.service';
import { PortfolioService } from './services/portfolio.service';
import { ToastService } from './services/toast.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'admin-p3solved';
  authenticated = false;
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: any = [];
  showSelectProvider = false;
  layout = 'home';
  providerSelected: boolean = false;
  isIframe = false;
  expanded = true;
  wasExpanded = false;
  languages = [{ language: 'English', code: 'en', icon: '/assets/images/us-icon.png' }];
  iconChevronDown = faChevronDown;
  iconSearch = faSearch;
  iconClose = faTimes;
  iconUser = faUser;
  customerGuid: string | null = "";

  toastKey: string = 'appCompToast';
  toastPos: string = 'bottom-center';
  toastSub: Subscription = {} as Subscription;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private msal: MsalService,
    private broadcastService: MsalBroadcastService,
    private authService: AuthService,
    private meta: Meta,
    private route: ActivatedRoute,
    private portfolioService: PortfolioService,
    private iconService: IconService,
    private userService: UserService,
    private toastService: ToastService,
    private router: Router
    ) {
    this.portfolioService.setPortfolios();
    this.userService.initPortSub();
    this.meta.addTag( { name:'app_version', content: environment.appVersion}, true);
    this.router.events.subscribe((event: any) => {
      
      if (event instanceof NavigationEnd) {      
        
    }

      if (event instanceof NavigationError) {
          console.error(event.error);
      }
  });
  }

  async ngOnInit(): Promise<void> {
    this.portfolioService.portfolio$.subscribe((p: PortfolioItem | null) => {
      this.providerSelected = p != null;
    });
    await this.userService.subscribeAccess();
    this.toastSub = this.toastService.toastPos$
    .subscribe((position: string) => {
      this.toastPos = position;
    });
    this.iconService.registerIcons();
    this.isIframe = window !== window.parent && !window.opener;
    this.subscribeAcquireSilentFail();
    this.subscribeLoginFailure();
    this.subscribeLoggedIn();
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    let combData = combineLatest([this.portfolioService.portfolios$, this.route.queryParams])
      .subscribe({
        next: async ([portfolios, params]) => {          
          if (params && portfolios?.portfolios) {
            let campGuid: string | undefined = undefined;
            if (params.campaignGuid) campGuid = params.campaignGuid;
            if (params.customerGuid) {              
              let item = portfolios.portfolios.find(p => p.customerGuid == params.customerGuid);
              if (item) {
                this.portfolioService.portfolioSelected(item, campGuid);
                await this.userService.subscribeAccess();
              }
            }
          }
        },
        error: (err: any) => { console.error(err); },
        complete: () => { combData.unsubscribe(); }
      });
  }

  changeLanguage(lang: any) {
    // this.selectedLanguage = lang;
  }

  mouseEnter() {
    this.wasExpanded = this.expanded ? true : false;
    this.expanded = true;
  }

  mouseLeave() {
    if (!this.wasExpanded)
      this.expanded = false;
  }

  subscribeLoggedIn() {
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        var payload = <AuthenticationResult>result.payload;
        this.authService.authenticated(payload);
        this.setAuthenticated();
      });
  }

  subscribeAcquireSilentFail() {
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      )
      .subscribe((result: EventMessage) => {
        this.authService.login();
        return;
      });
  }

  subscribeLoginFailure() {
    this.broadcastService.msalSubject$
      .subscribe((result: EventMessage) => {

        if (result && result.eventType === 'msal:acquireTokenFailure') {
          this.authService.login();
          return;
        }

        if (result && result.error) {
          var error = <AuthError>result.error;
          // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
          if (error && error.errorMessage.indexOf('AADB2C90118') > -1) {
            this.authService.resetPassword();
          }

          if (error && (error.errorMessage.indexOf('AADB2C90091') > -1)) {
            this.authService.login();
          }
        }
      });
  }


  checkAndSetActiveAccount() {
    if (this.msal.instance.getAllAccounts().length > 0) {
      this.authenticated = true;
      let activeAccount = this.msal.instance.getActiveAccount();
      if (!activeAccount) {
        let accounts = this.msal.instance.getAllAccounts();
        activeAccount = accounts[0];
        this.msal.instance.setActiveAccount(activeAccount);
        this.userService.subscribeAccess();
      } else {
        this.authService.authenticatedAccount(activeAccount);
        this.userService.subscribeAccess();
      }
    }

  }

  setAuthenticated() {
    this.authenticated = this.msal.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.toastSub.unsubscribe();
  }

}
