<div class="card">
    <div class="card-body">
        <form [formGroup]="brandingForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-select 
                        controlName="logoStrip"
                        [group]="brandingForm"
                        [options]="colorStrips"
                        label="Logo Strip">
                    </app-cc-select>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-select 
                        controlName="colorPallete"
                        [group]="brandingForm"
                        [options]="pallettes"
                        label="Color Pallette">
                    </app-cc-select>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-file-upload
                    controlName="imgLogo"
                    [group]="brandingForm"
                    label="Logo"
                    desc="PNG or SVG format only. Recommended minimum size of 225X60px. Must be on a transparent background.">
                    </app-cc-file-upload>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-file-upload
                    controlName="imgLanding"
                    [group]="brandingForm"
                    label="Landing Page"
                    desc="PNG or JPG format only. Size 1024x405 px (120 dpi)">
                    </app-cc-file-upload>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-file-upload
                    controlName="imgRecap"
                    [group]="brandingForm"
                    label="Recap Page"
                    desc="PNG or JPG format only. Size 600x402 px (120 dpi)">
                    </app-cc-file-upload>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-file-upload
                        controlName="imgLogin"
                        [group]="brandingForm"
                        label="Login Page"
                        desc="PNG or JPG format only. Size 513x548 px (120 dpi)">
                    </app-cc-file-upload>
                </div>
            </div>
            <button class="btn btn-primary btn-main btn-large" type="submit">Save Branding</button>
        </form>
    </div>
</div>
