import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { LookupModel } from 'src/app/models/lookup-model';
import { LookupService } from 'src/app/services/lookup.service';
import { ProviderService } from 'src/app/services/provider.service';

@Component({
  selector: 'app-edit-communication',
  templateUrl: './edit-communication.component.html',
  styleUrls: ['./edit-communication.component.scss']
})
export class EditCommunicationComponent implements OnInit {
  commTimes: LookupModel[] = [];
  smtpProfiles: LookupModel[] = [];
 commForm = this.fb.group({
    notifyFromEmail: [''],
    notifyFromName: [''],
    notifyReplyEmail: [''],
    notifyReplyName: [''],
    contactEmail: [''],
    contactFax: [''],
    commStartTime: ['08:00:00'],
    commEndTime: ['17:00:00'],
    commSmtpProfile: [''],
    notifySmtpProfile: [''],
    enableSms: [true],
    smsApiKey: [''],
    smsOrgCode: [''],
    smsOrgCodeMobile: [''],
    smsOrgCodeComms: [''],
    smsSbtOrgCode: ['']
  });
  constructor(private providerService: ProviderService, private lookupService: LookupService, private fb: UntypedFormBuilder) {
    this.initLookups();
    this.commTimes = lookupService.getCommTimeList();
   }

  ngOnInit(): void {
  }

  initLookups(){
    this.providerService.customerLookups$.subscribe(
      (lookups: ICustomerLookups) => {
        this.smtpProfiles = lookups.smtpProfiles || [];
      });
  }

  onSubmit(){
    console.warn(this.commForm.value);
  }
}
