<div *ngIf="!portfolioLoaded">
    <div class="text-3xl text-red-500">
        You MUST select a Customer before accessing this page.
    </div>
</div>

<div *ngIf="portfolioLoaded">
    <span class="text-2xl font-medium">Campaigns</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card w-screen-fit p-2">
            <div class="d-flex p-2">
                <div class="me-auto p-2">
                    <span class="text-2xl font-medium">Campaign List</span>
                </div>
                <div class="p-2" *ngIf="userService.hasWrite('CAMPAIGN_ADD_EDIT_COPY_DELETE')">
                    <p3solved-button id="btnAddNew" label="Create Campaign" class="" (buttonClick)="create()">
                    </p3solved-button>
                </div>
            </div>            
            <div> 
                <app-custom-grid #cgCampaigns
                    DataSourceName="dsAdminCampaignList" 
                    [overrideCustomer]="true"
                    [allowCustomCRUD]="userService.hasWrite('CAMPAIGN_ADD_EDIT_COPY_DELETE')"
                    customCRUDClass="pi pi-copy cursor-pointer"
                    customCRUDTitle="Copy Campaign"
                    (customCRUDSelect)="copyCampaign($event)"
                    (editRow)="edit($event)">
                </app-custom-grid>
            </div>
        </div>
    </div>
</div>

<p3solved-dialog [isVisible]="showCopy" header="Copy Campaign" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showCopy=false;" [style]="{'width': '25vw'}">
    <div body>
        <form *ngIf="copyFormLoaded" [formGroup]="copyForm">
            <div class="m-2">
                <p3solved-form-text id="txtProduct1" placeholder="Name" formControlName="campaignName" [required]="true">
                </p3solved-form-text>
            </div>
            <div class="m-2">
                <p3solved-form-text id="txtProduct2" placeholder="Domain" formControlName="domain" [required]="true">
                </p3solved-form-text>
            </div>
            <div class="m-2">
                <p3solved-form-text id="txtProduct3" placeholder="Start URL" formControlName="startUrl">
                </p3solved-form-text>
            </div>
            <div class="m-2">
                <p3solved-form-text id="txtProduct4" placeholder="Folder" formControlName="campaignFolder">
                </p3solved-form-text>
            </div>
            <div class="m-2">
                <p3solved-form-text id="txtProduct5" placeholder="My Account Domain" formControlName="myAccountDomain">
                </p3solved-form-text>
            </div>
            <div class="m-2">
                <p3solved-form-text id="txtProduct6" placeholder="Invitational Page URL"
                    formControlName="invitationPageUrl">
                </p3solved-form-text>
            </div>
            <div class="m-2">
                <p3solved-datepicker id="dt1" placeholder="Start Date" formControlName="startDate" 
                    appendTo="body" [required]="true">
                </p3solved-datepicker>
            </div>
            <div class="m-2">
                <p3solved-datepicker id="dt2" placeholder="End Date" formControlName="endDate" appendTo="body" ></p3solved-datepicker>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="m-2">
                        <div class="d-flex flex-row-reverse">
                            <div class="p-1"><p-inputSwitch formControlName="copyVersions"></p-inputSwitch></div>
                            <div class="p-1">Copy Versions</div>
                          </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <div class="d-flex flex-row-reverse">
                            <div class="p-1"><p-inputSwitch formControlName="copyPages"></p-inputSwitch></div>
                            <div class="p-1">Copy Pages</div>
                          </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <div class="d-flex flex-row-reverse">
                            <div class="p-1"><p-inputSwitch formControlName="copyRuleSets"></p-inputSwitch></div>
                            <div class="p-1">Copy RuleSets</div>
                          </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <div class="d-flex flex-row-reverse">
                            <div class="p-1"><p-inputSwitch formControlName="copyAlerts"></p-inputSwitch></div>
                            <div class="p-1">Copy Alerts</div>
                          </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <div class="d-flex flex-row-reverse">
                            <div class="p-1"><p-inputSwitch formControlName="copyAuditFlags"></p-inputSwitch></div>
                            <div class="p-1">Copy Audit Flags</div>
                          </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <div class="d-flex flex-row-reverse">
                            <div class="p-1"><p-inputSwitch formControlName="copyLabels"></p-inputSwitch></div>
                            <div class="p-1">Copy Labels</div>
                          </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnSaveOffer" (buttonClick)="saveCopy();" [disabled]="!copyForm.valid || copying">
                SAVE
            </p3solved-button>
            <p3solved-button id="btnCancelCopy" label="Cancel" class="outline" (buttonClick)="cancelCopy()"></p3solved-button>
        </div>
    </div>
</p3solved-dialog>