import { Component, OnInit } from '@angular/core';
import { TableColumn } from '@p3solved/p3solved-ui';
import { MenuItem } from 'primeng/api';
import { PortfolioItem } from 'src/app/models/customer-item';
import { PortfoliosModel } from 'src/app/models/portfolios-model';
import { ProviderContentCampaign, ProviderContentUpload, ProviderContentUploadCustomer } from 'src/app/models/provider-content-campaign';
import { ApiService } from 'src/app/services/api.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-provider-content',
  templateUrl: './provider-content.component.html',
  styleUrls: ['./provider-content.component.scss']
})
export class ProviderContentComponent implements OnInit {

  bcItems: MenuItem[] = [];
  portfoliosLoaded: boolean = false;
  
  columns: TableColumn[] = [];
  portfolios: PortfoliosModel | null = null;
  providers: ProviderContent[] = [];
  first: number = 0;
  filterFields: string[] = ['PostDate', 'CustomerName', 'StartURL', 'template', 'LoanStatusDescription'];
  contentCampaigns: ProviderContentCampaign[] = [];
  selectedCampaign: string | null = null;

  constructor(
    private portfolioService: PortfolioService,
    private apiService: ApiService,
    private toastService: ToastService,
    private navService: NavService) { }

  ngOnInit(): void {
    let portSub = this.portfolioService.portfolios$
    .subscribe({
      next: (portfolios: PortfoliosModel | null) => {
        this.portfolios = portfolios;
        if (!portfolios?.error && portfolios?.portfolios) {
          this.portfoliosLoaded = true;
          this.setProviderContent(portfolios.portfolios);
        }
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get allowed Portfolios. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { portSub.unsubscribe(); }
    });

    let campSub = this.apiService.get('provider/content-campaigns')
    .subscribe({
      next: (campaigns: ProviderContentCampaign[]) => {
        this.contentCampaigns = campaigns;
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get content campaigns. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { campSub.unsubscribe(); }
      
    })

    this.bcItems = [
      { label: 'Customer List', routerLink: ['/provider/list'], command: () => { this.navService.setLeftNavSelection('Client.List')} },
      { label: 'Customer Content' }
    ];
  }

  setProviderContent(portfolios: PortfolioItem[]) {
    this.providers = portfolios.map(p => {
      return {
        customerGuid: p.customerGuid,
        customerName: p.name,
        htmlPages: false,
        alerts: false,
        pageData: false,
        deRules: false,
        offerMatrix: false
      }
    });
  }

  processContent() { 
    let body: ProviderContentUpload = new ProviderContentUpload();
    body.requestGuid = uuidv4();
    body.sourceCampaignGuid = this.selectedCampaign ?? ''; 
    body.customers = [];
    for (let i = 0; i < this.providers.length; i++) {
      let provider = this.providers[i];
      let upload = new ProviderContentUploadCustomer();
      if (this.providerChecked(provider)) {
        upload.customerGuid = provider.customerGuid;
        upload.alerts = provider.alerts;
        upload.deRules = provider.deRules;
        upload.htmlPages = provider.htmlPages;
        upload.offerMatrix = provider.offerMatrix;
        upload.pageData = provider.pageData;
        body.customers.push(upload);
      }      
    }
    if (!body || !body.customers || body.customers.length == 0) {
      this.toastService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'You must select at least 1 Content for at least 1 Customer.'
      }, 'center');
      return;
    }

    let postBody = JSON.stringify(body);
    let postSub = this.apiService.post(`provider/content`, postBody) 
    .subscribe({
      next: () => {  
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Reuqest successfully submitted.'
        });
        this.clearProviders();
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to submit request. See log for details'
        }, 'center');
        console.error(err);
      },
      complete: () => { postSub.unsubscribe(); }
    });
  }

  clearProviders() {
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].alerts = false;
      this.providers[i].htmlPages = false;
      this.providers[i].deRules = false;
      this.providers[i].offerMatrix = false;
      this.providers[i].pageData = false;
    }
  }

  providerChecked(provider: ProviderContent) {
    return provider.alerts || provider.deRules || provider.htmlPages || provider.offerMatrix || provider.pageData;
  }

  allHtml() {
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].htmlPages = true;
    }
  }

  noHtml() { 
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].htmlPages = false;
    }
  }

  allAlerts() {
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].alerts = true;
    }
  }

  noAlerts() { 
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].alerts = false;
    }
  }

  allPageData() {
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].pageData = true;
    }
  }

  noPageData() { 
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].pageData = false;
    }
  }

  allDE() {
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].deRules = true;
    }
  }

  noDE() { 
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].deRules = false;
    }
  }

  allOffer() {
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].offerMatrix = true;
    }
  }

  noOffer() { 
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].offerMatrix = false;
    }
  }
}

export interface ProviderContent {
  customerGuid: string;
  customerName: string;
  htmlPages: boolean;
  alerts: boolean;
  pageData: boolean;
  deRules: boolean;
  offerMatrix: boolean;
}