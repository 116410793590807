<div class="m-2">
  <div class="w-full text-2xl font-medium">Report Dashboard</div>
  <div class="grid">
    <div class="col-12 md:col-4">
      <div class="cds-card p-4">
        <span class="text-2xl font-medium">Counts for Month (Snapshot)</span>
        <div class="grid py-3">
          <div class="col-6 mt-3">
            <div class="w-full text-2xl font-medium">Fundings this month</div>
            <div class="w-full text-4xl font-medium pt-3">
              ${{ dashboardData?.fundingsThismonth }}
            </div>
          </div>
          <div class="col-6 mt-3">
            <div class="w-full text-color-secondary font-light">
              Previous month
            </div>
            <div class="w-full text-color-secondary font-light">
              ${{ dashboardData?.previous_FundingsThismonth }}
            </div>
          </div>
          <div class="col-6 mt-3">
            <div class="w-full text-2xl font-medium">Payments this month</div>
            <div class="w-full text-4xl font-medium pt-3">
              ${{ dashboardData?.paymentsThismonth }}
            </div>
          </div>
          <div class="col-6 mt-3">
            <div class="w-full text-color-secondary font-light">
              Previous month
            </div>
            <div class="w-full text-color-secondary font-light">
              ${{ dashboardData?.previous_PaymentsThismonth }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-8">
      <div class="cds-card p-4">
        <p class="text-2xl font-medium">
          Daily Settlements: {{ currDate | date : "mediumDate" }}
        </p>
        <p>
          <span class="mr-4"
            ><a routerLink="/report/settlement-summary"
              >View More Details</a
            ></span
          >
          <span class="mr-2"
            ><a routerLink="/report/settlement-listing"
              >Past Settlements</a
            ></span
          >
        </p>
        <div class="border-top-1 border-black-alpha-20 pt-4 grid">
          <div class="col-6 md:col-3 pt-2">
            <div class="w-full pt-2 text-2xl font-medium">Payment Count</div>
            <div class="w-full pt-2 text-4xl font-medium">
              {{ dashboardData?.paymentCount }}
            </div>
            <div class="w-full pt-2 text-color-secondary font-light">
              Previous Day: {{ dashboardData?.previous_PaymentCount }}
            </div>
          </div>
          <div class="col-6 md:col-3 pt-2">
            <div class="w-full pt-2 text-2xl font-medium">Payment</div>
            <div class="w-full pt-2 text-4xl font-medium">
              ${{ dashboardData?.payments }}
            </div>
            <div class="w-full pt-2 text-color-secondary font-light">
              Previous Day: ${{ dashboardData?.previous_Payments }}
            </div>
          </div>
          <div class="col-6 md:col-3 pt-2">
            <div class="w-full pt-2 text-2xl font-medium">Draw Count</div>
            <div class="w-full pt-2 text-4xl font-medium">
              {{ dashboardData?.drawCount }}
            </div>
            <div class="w-full pt-2 text-color-secondary font-light">
              Previous Day: {{ dashboardData?.previous_DrawCount }}
            </div>
          </div>
          <div class="col-6 md:col-3 pt-2">
            <div class="w-full pt-2 text-2xl font-medium">
              Total Draw Amount
            </div>
            <div class="w-full pt-2 text-4xl font-medium">
              ${{ dashboardData?.totalDrawAmount }}
            </div>
            <div class="w-full pt-2 text-color-secondary font-light">
              Previous Day: ${{ dashboardData?.previous_TotalDrawAmount }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-4 -mt-4">
      <div class="cds-card p-4">
        <app-exception-checks-report
          (itemClicked)="notWorkingMsg()"
        ></app-exception-checks-report>
      </div>
    </div>
    <div class="col-12 md:col-4 -mt-4">
      <div class="cds-card p-4">
        <app-settlement-report></app-settlement-report>
      </div>
    </div>
    <div class="col-12 md:col-4 -mt-4">
      <div class="cds-card p-4">
        <app-accounting-report
          (itemClicked)="notWorkingMsg()"
        ></app-accounting-report>
      </div>
    </div>
    <div class="col-12 md:col-4 -mt-4">
      <div class="cds-card p-4">
        <app-nls-report (itemClicked)="notWorkingMsg()"></app-nls-report>
      </div>
    </div>
    <div class="col-12 md:col-4 -mt-4">
      <div class="cds-card p-4">
        <app-misc-report></app-misc-report>
      </div>
    </div>
  </div>
</div>
