<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <p3solved-progress-bar [style]="{'height': '0.5rem'}"></p3solved-progress-bar>
</div>

<div *ngIf="hasError" class="text-5xl font-medium text-red-500">Unable to load Campaign Notifications.
</div>

<div *ngIf="!loading && showList">
    <span class="text-2xl font-medium">Notification List</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div class="col-12 text-2xl">Notification List</div>
            </div>
            <div class="grid">
                <div class="col-12 flex flex-row-reverse">
                    <p3solved-button *ngIf="hasEditAccess" id="btnNewNotification" label="New Notificaiton" class="m-2"
                        (buttonClick)="newNotification()"></p3solved-button>
                    <p3solved-button *ngIf="hasCampNotAlertFeature" id="btnSendTestAllEmails" label="Send Test - ALL EMAILS" class="m-2"
                        (buttonClick)="showTestEmailModal()"></p3solved-button>
                </div>
                <div class="col-12 pt-4">
                    <p3solved-table
                        [data]="campaignNotifications"
                        [globalFilterFields]="filterFields"
                        [columns]="columns"
                        [rows]="100"
                        [allowRowSelect]="false"
                        (editRow)="editRecord($event)"
                        (deleteRow)="confirmDelete($event)">
                    </p3solved-table>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loading && showEdit">
    <span class="text-2xl font-medium">Notification Add/Edit</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div class="col-1">
                    <p-checkbox [(ngModel)]="isGeneric" [binary]="true" label="Generic"></p-checkbox>
                </div>
                <div class="col-1">
                    <p-checkbox [(ngModel)]="isMarketing" [binary]="true" label="Marketing"></p-checkbox>
                </div>
                <div class="col-12">
                    <div class="w-6">
                        <p3solved-dropdown id="ddType" placeholder="Type" [items]="campaignLookups.notificationTypes"
                            optionLabel="desc" [displayFirst]="false" [showClear]="false" [(ngModel)]="selectedType"
                            [required]="true" (optionSelected)="typeSelected($event)">
                        </p3solved-dropdown>
                    </div>
                </div>
                <div class="col-12">
                    <div class="w-6">
                        <p3solved-dropdown id="ddVersion" placeholder="Version" [items]="versions"
                            optionLabel="versionName" [displayFirst]="true" [showClear]="false"
                            [(ngModel)]="selectedVersion" [required]="true">
                        </p3solved-dropdown>
                    </div>
                </div>
                <div class="col-12">
                    <div class="w-6">
                        <p3solved-form-text id="txtName" [required]="true" placeholder="Name"
                            [(ngModel)]="notificationName" (blurEvent)="notificationNameBlur($event)">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="showNotificationNameError">
                                <p>Notification Name is required</p>
                            </div>
                        </p3solved-form-text>
                    </div>
                </div>

                <div *ngIf="selectedType && selectedType.id === '1'" class="col-12">
                    <form [formGroup]="emailFormGroup">
                        <div class="grid">
                            <div class="col-3">From Email:</div>
                            <div class="col-9">
                                {{fromEmail}}
                            </div>

                            <div class="col-3">From Display Name:</div>
                            <div class="col-9">
                                {{fromDisplay}}
                            </div>

                            <div class="col-3">Reply Email:</div>
                            <div class="col-9">
                                {{replyEmail}}
                            </div>

                            <div class="col-3">Reply Display Name:</div>
                            <div class="col-9">
                                {{replyDisplay}}
                            </div>
                            <div class="col-12">
                                <div class="w-6">
                                    <p3solved-form-text id="txtEmailTo" [required]="true" placeholder="To" formControlName="to">
                                        <div class="w-12 my-1 p-error text-xs"
                                            *ngIf="emailFormGroup.controls['to'].touched && emailFormGroup.controls['to'].invalid">
                                            <p>To address is required</p>
                                        </div>
                                    </p3solved-form-text>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="w-6 pt-4">
                                    <p3solved-form-textarea id="txtEmailBcc" [rows]="2" placeholder="BCC"
                                        labelStyleClass="font-medium" formControlName="bcc">
                                    </p3solved-form-textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="w-6">
                                    <p3solved-form-text id="txtEmailSubj" [required]="true" placeholder="Subject"
                                        formControlName="subject">
                                        <div class="w-12 my-1 p-error text-xs"
                                            *ngIf="emailFormGroup.controls['subject'].touched && emailFormGroup.controls['subject'].invalid">
                                            <p>Subject is required</p>
                                        </div>
                                    </p3solved-form-text>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="w-6 pt-4">
                                    <p3solved-form-textarea [pTooltip]="getAttachTooltip()" tooltipPosition="top"
                                        placeholder="Attachments &#9432;" id="txtEmailAtt" [rows]="2"
                                        labelStyleClass="font-medium" formControlName="attachments">
                                    </p3solved-form-textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="w-12">Body:</div>
                                <div class="w-12 pt-4">
                                    <p-editor #emailEditor placeholder="Notification Text" [style]="{'height':'320px'}" formControlName="body">
                                    </p-editor>
                                    <div class="w-12 my-1 p-error text-xs"
                                        *ngIf="emailFormGroup.controls['body'].touched && emailFormGroup.controls['body'].invalid">
                                        <p>Body is required</p>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="pt-4 grid">
                                    <div class="col-12">Available Tags:</div>
                                    <div class="col-6 pt-4">
                                        <span class="p-float-label">
                                            <p-autoComplete #emailTag inputId="emailTagAC" [showEmptyMessage]="true"
                                                [suggestions]="tagResults" (completeMethod)="searchTags($event)"
                                                field="fieldName" [forceSelection]="true" [dropdown]="true"
                                                styleClass="w-12" inputStyleClass="border-noround"></p-autoComplete>
                                            <label for="emailTagAC">Basic</label>
                                        </span>
                                    </div>
                                    <div class="col-2 pt-4">
                                        <p3solved-button id="btnEmailAddTag" label="Insert Tag" class="ml-2 text"
                                            [disabled]="!emailTag.value" (buttonClick)="insertEmailTag(emailTag.value)">
                                        </p3solved-button>
                                    </div>
                                    <div class="col-6 pt-4">
                                        <span class="p-float-label">
                                            <p-autoComplete #emailTagVersion inputId="emailTagVersionAC" [showEmptyMessage]="true"
                                                [suggestions]="versionTagResults" (completeMethod)="searchVersionTags($event)"
                                                field="fieldName" [forceSelection]="true" [dropdown]="true"
                                                styleClass="w-12" inputStyleClass="border-noround"></p-autoComplete>
                                            <label for="emailTagVersionAC">Version</label>
                                        </span>
                                    </div>
                                    <div class="col-2 pt-4">
                                        <p3solved-button id="btnEmailAddVersionTag" label="Insert Tag" class="ml-2 text"
                                            [disabled]="!emailTagVersion.value" (buttonClick)="insertVersionEmailTag(emailTagVersion.value)">
                                        </p3solved-button>
                                    </div>
                                    <div class="col-6 pt-4">
                                        <span class="p-float-label">
                                            <p-autoComplete #emailTagCustom inputId="emailTagCustomAC" [showEmptyMessage]="true"
                                                [suggestions]="customTagResults" (completeMethod)="searchCustomTags($event)"
                                                field="customTagName" [forceSelection]="true" [dropdown]="true"
                                                styleClass="w-12" inputStyleClass="border-noround"></p-autoComplete>
                                            <label for="emailTagCustomAC">Custom</label>
                                        </span>
                                    </div>
                                    <div class="col-2 pt-4">
                                        <p3solved-button id="btnEmailAddCustomTag" label="Insert Tag" class="ml-2 text"
                                            [disabled]="!emailTagCustom.value" (buttonClick)="insertCustomEmailTag(emailTagCustom.value)">
                                        </p3solved-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div *ngIf="selectedType && selectedType.id === '2'" class="col-12 grid">
                    <div class="col-12">
                        <div class="w-6">
                            <p3solved-form-text id="txtSmsTo" [(ngModel)]="smsTo" placeholder="To:"
                                labelStyleClass="font-medium" (blurEvent)="smsToBlur($event)"
                                [ngClass]="{'ng-invalid':showSMSToError, 'ng-dirty':showSMSToError}">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="showSMSToError">
                                    <p>SMS To is required</p>
                                </div>                                
                            </p3solved-form-text>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="w-6 pt-4">
                            <p3solved-form-textarea id="txtSmsBody" [(ngModel)]="smsBody" [required]="true" [rows]="5"
                                placeholder="Body:" labelStyleClass="font-medium" (blurEvent)="smsBodyBlur($event)"
                                [ngClass]="{'ng-invalid':showSMSBodyError, 'ng-dirty':showSMSBodyError}">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="showSMSBodyError">
                                    <p>SMS Body is required</p>
                                </div>                                
                            </p3solved-form-textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pt-4 grid">
                            <div class="col-12">Available Tags:</div>
                            <div class="col-6 pt-4">
                                <span class="p-float-label">
                                    <p-autoComplete #smsTag inputId="smsTagAC" [showEmptyMessage]="true"
                                        [suggestions]="tagResults" (completeMethod)="searchTags($event)"
                                        field="fieldName" [forceSelection]="true" [dropdown]="true"
                                        styleClass="w-12" inputStyleClass="border-noround"></p-autoComplete>
                                    <label for="smsTagAC">Basic</label>
                                </span>
                            </div>
                            <div class="col-2 pt-4">
                                <p3solved-button id="btnSmsAddTag" label="Insert Tag" class="ml-2 text"
                                    [disabled]="!smsTag.value" (buttonClick)="insertSMSTag(smsTag.value)">
                                </p3solved-button>
                            </div>
                            <div class="col-6 pt-4">
                                <span class="p-float-label">
                                    <p-autoComplete #smsTagVersion inputId="smsTagVersionAC" [showEmptyMessage]="true"
                                        [suggestions]="versionTagResults" (completeMethod)="searchVersionTags($event)"
                                        field="fieldName" [forceSelection]="true" [dropdown]="true"
                                        styleClass="w-12" inputStyleClass="border-noround"></p-autoComplete>
                                    <label for="smsTagVersionAC">Version</label>
                                </span>
                            </div>
                            <div class="col-2 pt-4">
                                <p3solved-button id="btnsmsAddVersionTag" label="Insert Tag" class="ml-2 text"
                                    [disabled]="!smsTagVersion.value" (buttonClick)="insertVersionSMSTag(smsTagVersion.value)">
                                </p3solved-button>
                            </div>
                            <div class="col-6 pt-4">
                                <span class="p-float-label">
                                    <p-autoComplete #smsTagCustom inputId="smsTagCustomAC" [showEmptyMessage]="true"
                                        [suggestions]="customTagResults" (completeMethod)="searchCustomTags($event)"
                                        field="customTagName" [forceSelection]="true" [dropdown]="true"
                                        styleClass="w-12" inputStyleClass="border-noround"></p-autoComplete>
                                    <label for="smsTagCustomAC">Custom</label>
                                </span>
                            </div>
                            <div class="col-2 pt-4">
                                <p3solved-button id="btnsmsAddCustomTag" label="Insert Tag" class="ml-2 text"
                                    [disabled]="!smsTagCustom.value" (buttonClick)="insertCustomSMSTag(smsTagCustom.value)">
                                </p3solved-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedType && selectedType.id === '3'" class="col-12 grid">
                    <div class="col-12">
                        <div class="w-6">
                            <p3solved-dropdown id="ddAction" placeholder="Action" [items]="campaignLookups.actions"
                                optionLabel="desc" [displayFirst]="false" [showClear]="false"
                                [(ngModel)]="selectedAction" [required]="true"></p3solved-dropdown>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="w-6">
                            <p3solved-form-text id="txtSrvActParam" [required]="false" placeholder="Parameters"
                                [(ngModel)]="actionParameters"></p3solved-form-text>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedType && selectedType.id === '4'" class="col-12">
                    <form [formGroup]="webhookForm">
                        <div class="grid">
                            <div class="col-12">
                                <div class="w-6">
                                    <p3solved-dropdown id="ddHttpReq" placeholder="HTTP Request" [required]="true"
                                        [items]="campaignLookups.requestTypes" optionLabel="desc" [displayFirst]="false"
                                        [showClear]="false" formControlName="request"></p3solved-dropdown>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="w-6">
                                    <p3solved-form-text id="txtURL" [required]="true" placeholder="HTTP URL"
                                        formControlName="url">
                                        <div class="w-12 my-1 p-error text-xs"
                                            *ngIf="webhookForm.controls['url'].touched && webhookForm.controls['url'].invalid">
                                            <p>Content Type is required</p>
                                        </div>
                                    </p3solved-form-text>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="w-6 pt-4">
                                    <p3solved-form-textarea id="txtwebhookHeaders" formControlName="headers" [rows]="5"
                                        placeholder="HTTP Headers" labelStyleClass="font-medium">
                                    </p3solved-form-textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="w-6">
                                    <p3solved-dropdown id="ddHttpCont" placeholder="HTTP ContentType" [required]="true"
                                        [items]="campaignLookups.contentTypes" optionLabel="desc" [displayFirst]="false"
                                        [showClear]="false" formControlName="contentType">
                                        <div class="w-12 my-1 p-error text-xs"
                                            *ngIf="webhookForm.controls['contentType'].touched && webhookForm.controls['contentType'].invalid">
                                            <p>Content Type is required</p>
                                        </div>
                                    </p3solved-dropdown>
                                </div>
                            </div>
                            <div class="col-12">HTTP Response Validation:</div>
                            <div class="col-12 grid">
                                <div class="col-2">
                                    <p3solved-dropdown id="ddRespValStatus" [required]="false" [items]="valResponses"
                                        optionLabel="value" [showClear]="false" [displayFirst]="true"
                                        formControlName="responseStatus"></p3solved-dropdown>
                                </div>
                                <div class="col-2">
                                    <p3solved-dropdown id="ddRespValCompare" [required]="false" [items]="valComparer"
                                        optionLabel="value" [showClear]="false" [displayFirst]="true"
                                        formControlName="responseCompare"></p3solved-dropdown>
                                </div>
                                <div class="col-2">
                                    <p3solved-form-text id="txtRespVal" [required]="false"
                                        formControlName="responseValue"></p3solved-form-text>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="w-6 pt-4">
                                    <p3solved-form-textarea id="txtwebhookPostData" formControlName="postData"
                                        [rows]="10" placeholder="HTTP Post Data" labelStyleClass="font-medium">
                                    </p3solved-form-textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="pt-4 grid">
                                    <div class="col-12">Available Tags:</div>
                                    <div class="col-6 pt-4">
                                        <span class="p-float-label">
                                            <p-autoComplete #whTag inputId="whTagAC" [showEmptyMessage]="true"
                                                [suggestions]="tagResults" (completeMethod)="searchTags($event)"
                                                field="fieldName" [forceSelection]="true" [dropdown]="true"
                                                styleClass="w-12" inputStyleClass="border-noround"></p-autoComplete>
                                            <label for="whTagAC">Basic</label>
                                        </span>
                                    </div>
                                    <div class="col-2 pt-4">
                                        <p3solved-button id="btnEmailAddTag" label="Insert Tag" class="ml-2 text"
                                            [disabled]="!whTag.value" (buttonClick)="insertWHTag(whTag.value)">
                                        </p3solved-button>
                                    </div>
                                    <div class="col-6 pt-4">
                                        <span class="p-float-label">
                                            <p-autoComplete #whTagVersion inputId="whTagVersionAC" [showEmptyMessage]="true"
                                                [suggestions]="versionTagResults" (completeMethod)="searchVersionTags($event)"
                                                field="fieldName" [forceSelection]="true" [dropdown]="true"
                                                styleClass="w-12" inputStyleClass="border-noround"></p-autoComplete>
                                            <label for="whTagVersionAC">Version</label>
                                        </span>
                                    </div>
                                    <div class="col-2 pt-4">
                                        <p3solved-button id="btnwhAddVersionTag" label="Insert Tag" class="ml-2 text"
                                            [disabled]="!whTagVersion.value" (buttonClick)="insertVersionWHTag(whTagVersion.value)">
                                        </p3solved-button>
                                    </div>
                                    <div class="col-6 pt-4">
                                        <span class="p-float-label">
                                            <p-autoComplete #whTagCustom inputId="whTagCustomAC" [showEmptyMessage]="true"
                                                [suggestions]="customTagResults" (completeMethod)="searchCustomTags($event)"
                                                field="customTagName" [forceSelection]="true" [dropdown]="true"
                                                styleClass="w-12" inputStyleClass="border-noround"></p-autoComplete>
                                            <label for="whTagCustomAC">Custom</label>
                                        </span>
                                    </div>
                                    <div class="col-2 pt-4">
                                        <p3solved-button id="btnwhAddCustomTag" label="Insert Tag" class="ml-2 text"
                                            [disabled]="!whTagCustom.value" (buttonClick)="insertCustomWHTag(whTagCustom.value)">
                                        </p3solved-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
                <div class="col-12">
                    <div class="flex flex-row-reverse">
                        <p3solved-button id="btnCancelNotification" label="Cancel" class="m-2 outline"
                        (buttonClick)="cancelNotification()"></p3solved-button>
                        <p3solved-button id="btnSaveNotification" class="m-2"
                        (buttonClick)="saveNotification()" [disabled]="disableSave()"><span class="mx-5">Save</span></p3solved-button>

                        <p3solved-button *ngIf="notificationToEdit && hasCampNotAlertFeature" id="btnTestNotification" class="m-2"
                        (buttonClick)="testNotification()"><span class="mx-4">Send Test</span></p3solved-button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<p-confirmDialog [style]="{width: '45vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>

<p3solved-dialog [isVisible]="testEmailModal" header="Send Test" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="testEmailModal=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="row">
            <div class="text-center">
                <span>Where do you want to send the test emails?</span>
            </div>
            <div class="text-center p-3">
                <span class="emailNote">NOTE: Multiple emails separated by comma.</span>
            </div>
            <div class="row">
                <span>
                    <p3solved-form-text id="toEmailsList" type="text" placeholder="To:" [(ngModel)]="toEmailsList">
                    </p3solved-form-text>
                </span>
            </div>
        </div>
    </div>
    <div footer class="justify-content-around">
        <p3solved-button id="btnDialogSend" label="Send" class="w-3"
            (buttonClick)="sendTestEmails();testEmailModal=false;"></p3solved-button>
        <p3solved-button id="btnDialogCancel" label="Cancel" class="p-button-outlined w-3"
            (buttonClick)="testEmailModal=false;"></p3solved-button>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="sendTestEmailModal" header="Send Test" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="sendTestEmailModal=false;"
    [style]="{'width': '25vw'}">
    <div body>
        <div class="flex flex-wrap align-items-center justify-content-center">
            <div class="w-12 text-center">Where do you want to send the test email?</div>
            <div class="w-12 text-center text-sm cds-text-color-secondary">NOTE: Multiple emails separated by a comma.</div>
            <div class="w-12 text-center">
                <p3solved-form-text id="txtTestEmail" placeholder="To:" [(ngModel)]="sendTestEmailAddress" [required]="true"></p3solved-form-text>
            </div>
            <div class="w-12 text-center"> 
                <p3solved-dropdown id="emailTestSeeds" [required]="false" [items]="testSeeds"
                optionLabel="text" [showClear]="false" [displayFirst]="false" appendTo="body"
                [(ngModel)]="selectedTestSeed" placeholder="Tags from seed"></p3solved-dropdown>
            </div>
        </div>
    </div>        
    <div footer class="flex flex-wrap align-items-center justify-content-around">
        <p3solved-button id="btnSendTestEmail" class="p-2"><span class="px-4" (click)="sendTestEmail()">Send</span></p3solved-button>
        <p3solved-button id="btnCancelTestEmail" class="p-2 outline" (buttonClick)="sendTestEmailModal=false;">
            <span class="px-4">Cancel</span>
        </p3solved-button>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="sendTestSMSModal" header="Manual Payment Review" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="sendTestSMSModal=false;"
    [style]="{'width': '25vw'}">
    <div body>
        <div class="flex flex-wrap align-items-center justify-content-center">
            <div class="w-12 text-center">Where do you want to send the test SMS?</div>
            <div class="w-12 text-center text-sm cds-text-color-secondary">NOTE: Multiple phone numbers separated by a comma.</div>
            <div class="w-12 text-center">
                <p3solved-form-text id="txtTestSMS" placeholder="To:" [(ngModel)]="sendTestSMSPhone" [required]="true"></p3solved-form-text>
            </div>
            <div class="w-12 text-center"> 
                <p3solved-dropdown id="smsTestSeeds" [required]="false" [items]="testSeeds"
                optionLabel="text" [showClear]="false" [displayFirst]="false" appendTo="body"
                [(ngModel)]="selectedTestSeed" placeholder="Tags from seed"></p3solved-dropdown>
            </div>
        </div>
    </div>        
    <div footer class="flex flex-wrap align-items-center justify-content-around">
        <p3solved-button id="btnSendTestSMS" class="p-2"><span class="px-4" (click)="sendTestSMS()">Send</span></p3solved-button>
        <p3solved-button id="btnCancelTestSMS" class="p-2 outline" (buttonClick)="sendTestSMSModal=false;">
            <span class="px-4">Cancel</span>
        </p3solved-button>
    </div>
</p3solved-dialog>