import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { PortfolioItem, PortfolioItemSimple } from 'src/app/models/customer-item';
import { BankFormGroup } from 'src/app/models/form-groups';
import { RemitSummary, SettlementRecord, SettlementSummary } from 'src/app/models/settlement';
import { ApiService } from 'src/app/services/api.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-settlement-report-page',
  templateUrl: './settlement-report-page.component.html',
  styleUrls: ['./settlement-report-page.component.scss']
})
export class SettlementReportPageComponent implements OnInit, AfterViewInit {

  loading: boolean = true;
  noType: boolean = false;
  showSummary: boolean = false;
  showRecords: boolean = false;
  showFinal: boolean = false;
  activeNachaBtn: boolean = false;
  rptFull: boolean = false;
  rptProvider: boolean = false;
  rptDay: boolean = false;
  hasError: boolean = false;
  settlementsLoaded: boolean = false;
  recordsLoaded: boolean = false;
  rowApproved: boolean = false;
  allRecords: boolean = false;
  allRecordsApproved: boolean = false;
  allRecordsMissingBank: boolean = false;
  showEditRecord: boolean = false;
  showEditBankInfo: boolean = false;
  showFullRecord: boolean = false;
  deleteChecked: boolean = false;
  recordToEdit: SettlementRecord | null = null;
  recordToShow: SettlementRecord | null = null;

  bcItems: MenuItem[] = [];

  customerGuid: string | null = null;
  rawSettlements: SettlementSummary[] = [];
  settlements: SettlementSummary[] = [];

  settlementRecords: SettlementRecord[] = [];
  selectedRecords: SettlementRecord[] = [];

  summaryHeaders: string[] = ['customerName', 'settlementDate', 'totalAmount', 'payToProvider', 'clawbackFromProvider'];
  recordsHeaders: string[] = ['customerName', 'branchName', 'loanNumber',];
  summaryColumns: any[] = [];
  recordColumns: any[] = [];
  first: number = 0;
  firstRec: number = 0;
  settlementRows: number = 100;
  settlementsTblStoreKey: string = 'settlementsTbl-local';
  recordsRows: number = 100;
  recordsTblStoreKey: string = 'recordsTbl-local';

  portfolios: PortfolioItemSimple[] = [];
  selectedPortfolio: PortfolioItemSimple | null = null;
  minNoteLength: number = 10;
  dueToProviderEdit: number = 0;
  updateReason: string = '';

  RemitSummary: RemitSummary[] = [];
  RemitPayTo: RemitSummary[] = [];
  RemitClawback: RemitSummary[] = [];
  RemitNetOut: RemitSummary[] = [];
  payToProvTotLoans: number = 0;
  payToProvTotAmt: number = 0;
  clawTotLoans: number = 0;
  clawTotAmt: number = 0;
  netTotAmt: number = 0;
  netTotBack: number = 0;

  bankFormLoaded: boolean = false;
  bankForm: FormGroup<BankFormGroup> = {} as FormGroup;  
  accountList: any[] = [{ name: "Checking" }, { name: "Savings" }];

  apprFilter: string = 'pending';
  pendingCount: number = 0;
  approveCount: number = 0;
  settlementsFiltered: boolean = false;
  @ViewChild('dt') dt: Table = {} as Table;
  settlementRecReqBody: any;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private portfolioService: PortfolioService,
    private toastService: ToastService,
    private navService: NavService
  ) { 
    this.isRowSelectable = this.isRowSelectable.bind(this);
  }

  onChange($event: any) {
    if ($event && $event.customerGuid) {
      this.customerGuid = $event.customerGuid;
      this.settlements = this.rawSettlements.filter(s => s.customerGUID == this.customerGuid);
      this.rowApproved = this.showBankFileBtn();
      this.settlementsFiltered = false;
      this.filterSettlements();
    }
  }

  ngOnInit(): void {
    this.clearStateStorage();
    this.bcItems = [
      { label: 'Report Dashboard', routerLink: ['/report'], command: () => { this.navService.setLeftNavSelection('Report'); } }
    ]
    this.route.params.subscribe(params => {
      if (params && params.type) {
        switch (params.type) {
          case 'full':
            this.noType = false;
            this.showSummary = true;
            this.showRecords = false;
            this.rptProvider = false;
            this.rptFull = true;
            this.rptDay = false;
            this.bcItems.push({ label: 'Settlement Report - Full' });
            this.setupColumns();
            this.getSummary();
            break;
          case 'provider':
            this.noType = false;
            this.showSummary = true;
            this.showRecords = false;
            this.rptProvider = true;
            this.rptFull = false;
            this.rptDay = false;
            this.loading = false;
            this.bcItems.push({ label: 'Settlement Report - Customer' });
            this.setupColumns();
            this.getSummary();
            break;
          case 'day':
            this.noType = false;
            this.showSummary = true;
            this.showRecords = false;
            this.rptProvider = false;
            this.rptFull = false;
            this.rptDay = true;
            this.bcItems.push({ label: 'Settlement Report - By Day' });
            this.setupColumns();
            this.getSummaryByDate();
            break;
          case 'records':
            this.noType = false;
            this.showSummary = false;
            this.showRecords = true;
            this.rptFull = false;
            this.rptProvider = false;
            this.rptDay = false;
            this.allRecords = true;
            this.bcItems.push({ label: 'All Records - Previous Day' });
            this.setupColumns();
            this.getRecords();
            break;
          default:
            this.loading = false;
            this.noType = true;
            break;
        }
      }
      else {
        this.noType = true;
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.settlementsFiltered) this.filterSettlements();
  }

  loadPortfolios() {
    if (this.rawSettlements && this.rawSettlements.length > 0) {
      let uniqueSett = [...new Map(this.rawSettlements.map(item => 
        [item['customerGUID'], item])).values()];

      let uniqueCust = uniqueSett.map(us => {
        const prov: PortfolioItemSimple = {
          customerGuid: us.customerGUID,
          name: us.customerName
        };
        return prov;
      }).sort((a, b) => a.name > b.name ? 1 : -1);

      this.portfolios = uniqueCust;         
    }
  }

  getSummary(customerGuid?: string, startDate?: Date, endDate?: Date) {
    let body: any = {};
    if (customerGuid && customerGuid.length) {
      body.customerGuid = customerGuid;
    }
    if (startDate) {
      body.startDate = startDate;
    }
    if (endDate) {
      body.endDate = endDate;
    }
    let settSumm = this.apiService.post(`adminreport/settlement/provider`, body)
      .subscribe({
        next: (data: SettlementSummary[]) => {
          if (data && data.length) {
            
            data.forEach(d => {
              d.settlementDate = new Date(d.settlementDate);
              d.totalAmount = d.payToProvider - d.adjustedAmount - d.canceledAmount;
            });

            this.rawSettlements = data;

            if (!this.rptProvider) { 
              this.settlements = data;
              this.rowApproved = this.showBankFileBtn();
              this.settlementsFiltered = false;
              this.filterSettlements();
            } 
            else {
              this.loadPortfolios();
            }             
          }
          else {
            this.rawSettlements = [];
            this.settlements = [];
            this.portfolios = [];
            this.rowApproved = false;
          }
          this.settlementsLoaded = true;
          this.loading = false;
        },
        error: (err: any) => {
          this.loading = false;
          this.hasError = true;
          console.error(err);
        },
        complete: () => { settSumm.unsubscribe(); }
      });
  }
  
  clearDt() {
    if (this.dt != null) {
      this.dt.clear();
      this.settlementsFiltered = false;
      this.filterSettlements();
    }
    else {
      setTimeout(() => {
        this.clearDt();
      }, 100);
    }    
  }

  filterSettlements() {
    if (this.settlements.length == 0) return;

    if (this.settlementsFiltered) return;

    let element: HTMLElement = document.getElementById('spanAll') as HTMLElement;
    if (this.apprFilter == 'pending') element = document.getElementById('spanPending') as HTMLElement;
    else if (this.apprFilter == 'approved') element = document.getElementById('spanApproved') as HTMLElement;

    if (element != null) {
      element.click();
      this.settlementsFiltered = true;
    }
    else {
      setTimeout(() => {
        this.filterSettlements();
      }, 100);
    }

  }

  getSummaryByDate() {
    let body: any = {};
    let settSumm = this.apiService.post(`adminreport/settlement/provider`, body)
      .subscribe({
        next: (data: SettlementSummary[]) => {
          if (data && data.length) {
            data.forEach(d => { d.settlementDate = new Date(d.settlementDate); });
            data = data.filter(d => d.settlementDate <= new Date());
            let uniqueDays = [...new Set(
              data.map(d => d.settlementDate.getTime())
                .filter((s, i, a) => a.indexOf(s) == i)
                .map(s => new Date(s))
            )];
            this.rawSettlements = [];
            this.settlements = [];
            uniqueDays = uniqueDays.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
            uniqueDays.forEach(ud => {
              let days = data.filter(d => d.settlementDate.getTime() == ud.getTime());
              let settle: SettlementSummary = {
                customerGUID: '',
                customerName: '',
                settlementDate: ud,
                payToProvider: days.reduce((subtotal, day) => subtotal + day.payToProvider, 0),
                clawbackFromProvider: days.reduce((subtotal, day) => subtotal + day.clawbackFromProvider, 0),
                clawbackCount: days.reduce((subtotal, day) => subtotal + day.clawbackCount, 0),
                unApprovedCount: days.reduce((subtotal, day) => subtotal + day.unApprovedCount, 0),
                approvedCount: days.reduce((subtotal, day) => subtotal + day.approvedCount, 0),
                approvalStatus: this.getStatus(days.map(d => d.approvalStatus)),
                transactionCount: days.reduce((subtotal, day) => subtotal + day.transactionCount, 0),
                canceledCount: days.reduce((subtotal, day) => subtotal + day.canceledCount, 0),
                canceledAmount: days.reduce((subtotal, day) => subtotal + day.canceledAmount, 0),
                adjustedCount: days.reduce((subtotal, day) => subtotal + day.adjustedCount, 0),
                adjustedAmount: days.reduce((subtotal, day) => subtotal + day.adjustedAmount, 0),
                numberProviders: data.filter(d => d.settlementDate.getTime() == ud.getTime()).length,
                isMissingBankInfo: days.filter(d => d.isMissingBankInfo).length > 0,
                totalAmount: days.reduce((subtotal, day) => subtotal + (day.payToProvider - day.adjustedAmount - day.canceledAmount), 0)
              };
              this.settlements.push(settle);
            });
            this.rowApproved = this.showBankFileBtn();
            this.settlementsFiltered = false;
            this.filterSettlements();
          }
          else {
            this.rawSettlements = [];
            this.settlements = [];
            this.rowApproved = false;
          }
          this.settlementsLoaded = true;
          this.loading = false;
        },
        error: (err: any) => {
          this.loading = false;
          this.hasError = true;
          console.error(err);
        },
        complete: () => { settSumm.unsubscribe(); }
      });
  }

  getSettlementsCount(type: string) {
    if (type == 'approved') return this.settlements.filter(s => s.approvalStatus == 'all').length;
    return this.settlements.filter(s => s.approvalStatus != 'all').length;
  }

  getStatus(statuses: string[]): string {
    let filterIndexAll = statuses.includes('all');
    let filterIndexNone = statuses.includes('none');
    let filterIndexSome = statuses.includes('some');

    if (filterIndexSome) return 'some';
    if (filterIndexAll && !filterIndexNone) return 'all';
    if (filterIndexNone && !filterIndexAll) return 'none';
    return 'some'
  }

  getApproveBadge(status: string) {
    switch (status) {
      case 'all':
        return 'success';
        break;
      case 'some':
        return 'warning';
        break;
      default:
        return 'danger';
        break;
    }
  }

  getSummaryRecords(row: SettlementSummary) {
    let prevItem = this.bcItems.pop();
    this.bcItems.push({
      label: prevItem?.label || 'Settlement Summary',
      command: () => {
        this.showRecords = false;
        this.showSummary = true;
        this.showFinal = false;
        this.bcItems.pop();
        this.bcItems.pop();
        this.bcItems.push({ label: prevItem?.label || "Settlement Summary" });
        this.clearDt();
      }
    });
    this.bcItems.push({ label: 'Summary Records' });
    this.allRecords = false;
    this.allRecordsApproved = row.approvalStatus.toLowerCase() === 'all';
    this.getRecords(row.customerGUID, row.settlementDate);
  }

  getRecords(customerGuid?: string, startDate?: Date, endDate?: Date) {
    this.recordsLoaded = false;
    this.settlementRecReqBody = {};
    if (customerGuid && customerGuid.length) {
      this.customerGuid = customerGuid;
      this.settlementRecReqBody.customerGuid = customerGuid;
    }
    if (startDate) {
      this.settlementRecReqBody.startDate = startDate;
    }
    if (endDate) {
      this.settlementRecReqBody.endDate = endDate;
    }
    
    let recSumm = this.apiService.post('adminreport/settlement/provider/records', this.settlementRecReqBody)
      .subscribe({
        next: (data: SettlementRecord[]) => {
          if (data && data.length) {
            this.settlementRecords = data;
            this.selectedRecords = data.filter(d => !d.isMissingBankInfo);
            this.allRecordsMissingBank = data.filter(d => !d.isMissingBankInfo).length === 0;
          }
          else {
            this.settlementRecords = [];
          }
          this.recordsLoaded = true;
          this.loading = false;
          this.showSummary = false;
          this.showRecords = true;
        },
        error: (err: any) => {
          this.loading = false;
          this.hasError = true;
          console.error(err);
        },
        complete: () => { recSumm.unsubscribe(); }
      })
  }

  displayFullRecord(row: SettlementRecord) {
    this.recordToShow = row;
    this.showFullRecord = true;
  }

  isRowSelectable(event: any): boolean {
    return !event.data.isMissingBankInfo;
  }

  saveApprovals() {
    let body: any = {
      settlementDate: this.settlementRecords[0].settlementDate
    }
    if (this.customerGuid) body.customerGuid = this.customerGuid;

    if (this.selectedRecords.length !== this.settlementRecords.length) {
      let dna = this.settlementRecords.filter(sr => !this.selectedRecords.includes(sr));
      let dnaIds = dna.map(d => d.dailySettlementID).join(',');
      body.doNotApproveList = dnaIds;
    }

    this.postApprovals(body)
      .then(() => {
        this.restoreSettlements();
        let prevBc = this.bcItems[this.bcItems.length - 2];
        if (prevBc.command != null) prevBc.command();
        else {
          this.showRecords = false;
          this.showSummary = true;
        }        
      }, error => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to Save approvals. See log for details.'
        }, 'center')
        console.error(error);
      });
  }

  approveSummary(row: SettlementSummary) {
    let customerGuid: any = row.customerGUID && row.customerGUID.length > 10 ? row.customerGUID : null;
    let body: any = {
      settlementDate: row.settlementDate,
    }
    if (customerGuid) {
      body.customerGuid = customerGuid;
    }

    this.postApprovals(body).then(() => {
      this.rowApproved = true;
    });
  }

  async postApprovals(body: any) {
    let apprSumm = this.apiService.post(`adminreport/settlement/provider/approve`, body)
      .subscribe({
        next: (res: any) => {
          if (this.rptFull) this.getSummary();
          if (this.rptDay) this.getSummaryByDate();
          if (this.rptProvider && body.customerGuid) {
            this.settlementsLoaded = false;
            this.getSummary(body.customerGuid);
          }
          this.displayMessage('success', 'Approved', 'Records have been approved.');
        },
        error: (err: any) => {
          console.error(err);
          this.displayMessage('error', 'Error', 'Unable to approve records.');
          throw err;
        },
        complete: () => { apprSumm.unsubscribe(); }
      });
  }

  editRecord(row: SettlementRecord) {
    this.recordToEdit = row;
    this.updateReason = '';
    this.deleteChecked = false;
    this.dueToProviderEdit = row.dueToProvider ?? 0;
    this.showEditRecord = true;
  }

  cancelEditRecord(showMsg: boolean) {
    this.showEditRecord = false;
    this.recordToEdit = null;
    this.updateReason = '';
    if (showMsg) this.displayMessage('warn', 'Operation cancelled');
  }

  finalizeEditRecord() {
    let body: any = {
      dailySettlementId: this.recordToEdit?.dailySettlementID,
      delete: this.deleteChecked,
      note: this.updateReason,
      dueToProvider: this.dueToProviderEdit
    };

    let editSub = this.apiService.post(`adminreport/settlement/provider/edit`, body)
      .subscribe({
        next: () => {
          if (this.deleteChecked && this.recordToEdit) {
            let index = this.settlementRecords.indexOf(this.recordToEdit);
            if (index > -1) {
              this.settlementRecords.splice(index, 1);
            }
          }
          else if (this.recordToEdit) {            
            let index = this.settlementRecords.indexOf(this.recordToEdit);
            this.recordToEdit.dueToProvider = this.dueToProviderEdit;
            this.settlementRecords.splice(index, 1, this.recordToEdit);
          }

          this.rawSettlements = [];
          this.settlements = [];
          this.restoreSettlements();
          this.getRecords(this.settlementRecReqBody.customerGuid ?? null, this.settlementRecReqBody.startDate ?? null, this.settlementRecReqBody.endDate ?? null);
          this.showEditRecord = false;
          this.recordToEdit = null;
          this.displayMessage('success', 'Settlement record has been updated.', 'Success');
        },
        error: (err: any) => {
          console.error(err);
          this.recordToEdit = null;
          this.showEditRecord = false;
          this.displayMessage('error', 'Error', 'Unable to update record.');
        },
        complete: () => { editSub.unsubscribe(); }
      });
  }

  editBankInfo(row: SettlementRecord) {    
    this.recordToEdit = row;
    this.bankForm = new FormGroup<BankFormGroup>({
      bankRouting: new FormControl<string>('', {nonNullable: true, validators: [Validators.required, Validators.pattern('[0-9]{9}')]}),
      bankAccount: new FormControl<string>('', {nonNullable: true, validators: [Validators.required, Validators.pattern('[0-9]*')]}),
      bankAccountType: new FormControl<string>('Checking', {nonNullable: true, validators: [Validators.required]}),
      isClawback: new FormControl<boolean>(row.isClawback ?? false, {nonNullable: true, validators: [Validators.required]}),
    });
    this.bankFormLoaded = true;
    this.showEditBankInfo = true;
  }

  cancelEditBank(showMsg: boolean) {
    this.showEditBankInfo = false;
    this.recordToEdit = null;
    if (showMsg) this.displayMessage('warn', 'Operation cancelled');
  }

  finalizeEditBank() {
    let body = {
      customerBranchSettlementID: this.recordToEdit?.dailySettlementID,
      branchID: this.recordToEdit?.branchID,
      bankRouting: this.bankForm.value.bankRouting?.toString(),
      bankAccount: this.bankForm.value.bankAccount?.toString(),
      isClawback: this.bankForm.value.isClawback,
      bankAccountType: this.bankForm.value.bankAccountType
    };

    let editSub = this.apiService.post(`adminreport/settlement/bank/edit`, body)
    .subscribe({
      next: () => {
        if (this.recordToEdit) {
          let index = this.settlementRecords.indexOf(this.recordToEdit);
          if (index > -1) {
            this.recordToEdit.bankAccount = this.bankForm.value.bankAccount;
            this.recordToEdit.bankRouting = this.bankForm.value.bankRouting;
            this.settlementRecords.splice(index, 1, this.recordToEdit);
          }
        }
        this.restoreSettlements();
        this.showEditBankInfo = false;        
        this.recordToEdit = null;
        this.displayMessage('success', 'Bank Information has been updated.', 'Success');
      },
      error: (err: any) => {
        this.showEditBankInfo = false;
        this.displayMessage('error', 'Unable to update bank information. See log for details.', 'Error');
        console.error(err);
      },
      complete: () => { editSub.unsubscribe(); }
    });
  }
  
  showBankFileBtn() {
    let allApproved = this.settlements.filter(s => s.approvalStatus != 'all').length == 0;
    let allBank = this.settlements.filter(s => s.isMissingBankInfo).length == 0;
    return allApproved && allBank;
  }

  showRemitSummary() {
    this.loadRemitSummary();

    let prevItem = this.bcItems.pop();
    this.bcItems.push({
      label: prevItem?.label || 'Summary Records',
      command: () => {
        this.showRecords = false;
        this.showFinal = false;
        this.activeNachaBtn = false;
        this.showSummary = true;
        this.bcItems.pop();
        this.bcItems.pop();
        this.bcItems.push({ label: prevItem?.label || "Summary Records" });
      }
    });
    this.bcItems.push({ label: 'Final Summary' });
    this.showSummary = false;
    this.showRecords = false;
    this.showFinal = true;
    this.activeNachaBtn = true;
  }

  loadRemitSummary() {
    let summSub = this.apiService.get(`adminreport/settlement/provider/summary`)
      .subscribe({
        next: (res: any) => {
          this.RemitSummary = res;
          this.RemitPayTo = this.RemitSummary.filter(rs => rs.section.toLowerCase() == 'pay to provider');
          this.RemitClawback = this.RemitSummary.filter(rs => rs.section.toLowerCase() == 'clawback');
          this.RemitNetOut = this.RemitSummary.filter(rs => rs.section.toLowerCase() == 'net out');
          this.payToProvTotLoans = this.RemitPayTo.reduce((subTotal, row) => subTotal + row.loanCount, 0);
          this.payToProvTotAmt = this.RemitPayTo.reduce((subTotal, row) => subTotal + row.totalAmountOut, 0);
          this.clawTotLoans = this.RemitClawback.reduce((subTotal, row) => subTotal + row.loanCount, 0);
          this.clawTotAmt = this.RemitClawback.reduce((subTotal, row) => subTotal + row.totalAmountBack, 0);
          this.netTotAmt = this.RemitNetOut.reduce((subTotal, row) => subTotal + row.totalAmountOut, 0);
          this.netTotBack = this.RemitNetOut.reduce((subTotal, row) => subTotal + row.totalAmountBack, 0);
        },
        error: (err: any) => {
          console.error(err);
          this.displayMessage('error', 'Unable to Load Summary', 'Error');
        },
        complete: () => { summSub.unsubscribe(); }
      });
  }

  restoreSettlements() {
    if (this.rptFull) this.getSummary();
    else if (this.rptProvider && this.customerGuid) this.getSummary(this.customerGuid);
    else if (this.rptDay) this.getSummaryByDate();
    else if (this.allRecords) this.getRecords();
  }

  setupColumns() {
    this.summaryColumns = [];
    if (!this.rptDay) {
      this.summaryColumns.push({ field: 'customerName', header: 'Customer Name', sortable: this.rptFull, show: true });
    }
    this.summaryColumns.push({ field: 'settlementDate', header: 'Settlement Date', sortable: true, show: true });
    if (this.rptDay) {
      this.summaryColumns.push({ field: 'numberProviders', header: 'Number Customer', sortable: false, show: true });
    }
    this.summaryColumns.push({ field: 'transactionCount', header: 'Number Transactions', sortable: false, show: true });
    this.summaryColumns.push({ field: 'totalAmount', header: 'Total Amount', sortable: true, show: true });
    this.summaryColumns.push({ field: 'adjustedCount', header: 'Number Adjust', sortable: false, show: true });
    this.summaryColumns.push({ field: 'adjustedAmount', header: 'Total Adjust', sortable: false, show: true });
    this.summaryColumns.push({ field: 'canceledCount', header: 'Number Cancel', sortable: false, show: true });
    this.summaryColumns.push({ field: 'canceledAmount', header: 'Total Cancel', sortable: false, show: true });
    this.summaryColumns.push({ field: 'payToProvider', header: 'Pay To Customer', sortable: true, show: true });
    this.summaryColumns.push({ field: 'clawbackCount', header: 'Number Clawback', sortable: false, show: true });
    this.summaryColumns.push({ field: 'clawbackFromProvider', header: 'Clawback Amount', sortable: true, show: true });
    this.summaryColumns.push({ field: '', header: 'Statuses - Approval / Bank', sortable: false, show: true })
    this.summaryColumns.push({ field: 'approvalStatus', header: 'Status - Approval', sortable: false, show: false });
    this.summaryColumns.push({ field: 'isMissingBankInfo', header: 'Status - Bank', sortable: false, show: false });

    this.recordColumns = [];
    this.recordColumns.push({ field: 'dailySettlementID', header: 'Daily Settlement ID', sortable: false, show: false });
    this.recordColumns.push({ field: 'customerID', header: 'Customer ID', sortable: false, show: false });
    this.recordColumns.push({ field: 'customerGUID', header: 'Customer Guid', sortable: false, show: false });
    this.recordColumns.push({ field: 'customerName', header: 'Customer Name', sortable: this.allRecords, show: true });
    this.recordColumns.push({ field: 'branchID', header: 'branchID', sortable: false, show: false });
    this.recordColumns.push({ field: 'branch_ID', header: 'branch_ID', sortable: false, show: false });
    this.recordColumns.push({ field: 'branchName', header: 'Branch Name', sortable: true, show: true });
    this.recordColumns.push({ field: 'targetID', header: 'Target ID', sortable: false, show: false });
    this.recordColumns.push({ field: 'targetGUID', header: 'Target Guid', sortable: false, show: false });
    this.recordColumns.push({ field: 'patientID', header: 'Patient ID', sortable: false, show: false });
    this.recordColumns.push({ field: 'loanNumber', header: 'Loan Number', sortable: true, show: true });
    this.recordColumns.push({ field: 'originationDate', header: 'Origination Date', sortable: true, show: true });
    this.recordColumns.push({ field: 'bankModel', header: 'Bank Model', sortable: false, show: false });
    this.recordColumns.push({ field: 'bankRouting', header: 'Bank Routing', sortable: true, show: true });
    this.recordColumns.push({ field: 'bankAccount', header: 'Bank Account', sortable: true, show: true });
    this.recordColumns.push({ field: 'bankFileProducedDate', header: 'Bank File Date', sortable: false, show: false });
    this.recordColumns.push({ field: 'procedureServiceDate', header: 'Procedure Date', sortable: true, show: true });
    this.recordColumns.push({ field: 'purchasePricePct', header: 'Purchase Price Percent', sortable: false, show: false });
    this.recordColumns.push({ field: 'dueToProvider', header: 'Due To Customer', sortable: true, show: true });
    this.recordColumns.push({ field: 'totalProcedureAmount', header: 'Procedure Total', sortable: false, show: false });
    this.recordColumns.push({ field: 'discount', header: 'Discount', sortable: false, show: false });
    this.recordColumns.push({ field: 'downPaymentAmount', header: 'Down Payment Amount', sortable: false, show: false });
    this.recordColumns.push({ field: 'mdr', header: 'MDR', sortable: false, show: false });
    this.recordColumns.push({ field: 'contractAdjustment', header: 'Contract Adjustment', sortable: false, show: false });
    this.recordColumns.push({ field: 'contractAdjustmentDate', header: 'Contract Adjustment Date', sortable: false, show: false });
    this.recordColumns.push({ field: 'providerPurchaseDate', header: 'Customer Purchase Date', sortable: false, show: false });
    this.recordColumns.push({ field: 'settlementDate', header: 'Settlement Date', sortable: false, show: false });
    this.recordColumns.push({ field: 'settlementFileProducedDate', header: 'Settlement File Date', sortable: false, show: false });
    this.recordColumns.push({ field: 'createdDate', header: 'Created Date', sortable: false, show: false });
    this.recordColumns.push({ field: 'cancelDate', header: 'Cancel Date', sortable: false, show: false });
    this.recordColumns.push({ field: 'cancelFlag', header: 'Cancel Flag', sortable: false, show: false });
    this.recordColumns.push({ field: 'modified', header: 'Modified', sortable: false, show: false });
    this.recordColumns.push({ field: 'modifiedDate', header: 'Modified Date', sortable: false, show: false });
    this.recordColumns.push({ field: 'modifiedNotes', header: 'Modified Notes', sortable: false, show: false });
    this.recordColumns.push({ field: 'modifierID', header: 'Modifier ID', sortable: false, show: false });
    this.recordColumns.push({ field: 'approverID', header: 'Approver Id', sortable: false, show: false });
    this.recordColumns.push({ field: 'approvedDate', header: 'Approved Date', sortable: false, show: false });
    this.recordColumns.push({ field: 'approved', header: 'Approved', sortable: true, show: true });
    this.recordColumns.push({ field: 'isMissingBankInfo', header: 'Status - Bank', sortable: false, show: false });
  }

  createNacha() {
    let nachaSub = this.apiService.get(`adminreport/settlement/provider/nacha`).subscribe({
      next: () => {
        this.displayMessage('success', 'File creation process started', 'Files being created');
        this.activeNachaBtn = false;
        this.rowApproved = false;
      },
      error: (err: any) => {
        console.error(err);
        this.displayMessage('error', 'Unable to create bank file(s)', 'Error');
      },
      complete: () => { nachaSub.unsubscribe(); }
    });
  }

  displayMessage(severity: string, detail: string, summary?: string) {
      this.toastService.add({
        severity: severity,
        summary: summary,
        detail: detail
      });
  }

  clearStateStorage() {
    localStorage.removeItem(this.recordsTblStoreKey);
    localStorage.removeItem(this.settlementsTblStoreKey);
  }
}
