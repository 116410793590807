import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PortfolioItem } from 'src/app/models/customer-item';
import { LookupModel } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-edituser-info',
  templateUrl: './edituser-info.component.html',
  styleUrls: ['./edituser-info.component.scss']
})
export class EdituserInfoComponent implements OnInit {

  @Input() userGuid = '';
  @Output() onEditComplete = new EventEmitter<string>();
  @Input()
  get user(): any { return this._user; }
  set user(user: any) {
    this._user = user;
    this.initForm();
  }
  private _user = '';
  userForm: FormGroup = new FormGroup({});
  portfolioItem: PortfolioItem | null | undefined;
  timeZones: LookupModel[] = [];
  saving = false;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    private portfolioService: PortfolioService) { }

  ngOnInit(): void {
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      this.getTimeZones();
    });
  }

  initForm() {
    this.userForm = this.fb.group({
      CustomerID: [this.user?.CustomerID || '', Validators.required],
      FirstName: [this.user?.FirstName || '', Validators.required],
      LastName: [this.user?.LastName, Validators.required],
      Company: [this.user?.Company],
      LoginEmail: [this.user?.LoginEmail, Validators.required],
      Mobile: [this.user?.Mobile || '', Validators.required],
      BusinessPhone: [this.user?.BusinessPhone || '', Validators.required],
      TimeZoneID: [this.user?.TimeZoneID.toString()]
    });
  }

  save() {
    if (!this.userForm.valid) {
      this.userForm.markAllAsTouched();
      this.userForm.markAsDirty();
      this.toast.addError("Invalid form. Please enter required fields");
      return;
    }

    let obj = {
      UserGuid: this.userGuid,
      ...this.userForm.value
    };
    this.saving = true;
    var json = JSON.stringify(JSON.stringify(obj));
    this.api.post(`adminuser/update-contact`, json).subscribe(res => {
      this.user.FirstName = this.userForm.value.FirstName;
      this.user.LastName = this.userForm.value.LastName;
      this.user.Company = this.userForm.value.Company;
      this.user.LoginEmail = this.userForm.value.LoginEmail;
      this.user.Mobile = this.userForm.value.Mobile;
      this.user.BusinessPhone = this.userForm.value.BusinessPhone;
      this.user.TimeZoneID = this.userForm.value.TimeZoneID;
      this.saving = false;
      this.onEditComplete.emit('updated');
    }, err => {
      console.error(err);
      this.saving = false;
      this.toast.addError("Invalid form. Server Error occured.");
    });
  }

  cancel() {
    this.onEditComplete.emit('cancel');
  }

  getTimeZones() {
    if (this.portfolioItem && this.portfolioItem.customerGuid) {
      this.api.get(`lookup/time-zones/${this.portfolioItem.customerGuid}`).subscribe(data => {
        this.timeZones = data;
      });
    } else {
      //toast error?
    }
  }

}
