<div *ngIf="!portfoliosLoaded">
    <div class="text-3xl text-red-500">
        Unable to get allowed Portfolios. Please try again.
    </div>
</div>

<div *ngIf="portfoliosLoaded">
    <span class="text-2xl font-medium">Customer Content</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">                        
            <div>
                <p-table #dt [value]="providers" styleClass="p-datatable-lg col-12"
                [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]"
                [(first)]="first" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                responsiveLayout="scroll" [rows]="100" [rowHover]="true">
                <ng-template pTemplate="caption">
                    <div class="d-flex flex-row-reverse mb-3">
                        <p3solved-button id="btnProcess" label="Process" (buttonClick)="processContent()" [disabled]="!selectedCampaign"></p3solved-button>
                        <p3solved-dropdown id="ddCampaigns" class="w-3" placeholder="Source Customer" [items]="contentCampaigns"
                            optionLabel="campaignName" optionValue="campaignGUID" [showClear]="false" [required]="true" [(ngModel)]="selectedCampaign">
                        </p3solved-dropdown>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr class="text-lg font-bold">
                        <td>Customer Name</td>
                        <td>Html Pages  
                            <span class="text-secondary cursor-pointer pl-2" (click)="allHtml()">all</span> / 
                            <span class="text-secondary cursor-pointer" (click)="noHtml()">none</span>
                        </td>
                        <td>Alerts / Notifications  
                            <span class="text-secondary cursor-pointer pl-2" (click)="allAlerts()">all</span> / 
                            <span class="text-secondary cursor-pointer" (click)="noAlerts()">none</span>
                        </td>
                        <td>Page Data  
                            <span class="text-secondary cursor-pointer pl-2" (click)="allPageData()">all</span> / 
                            <span class="text-secondary cursor-pointer" (click)="noPageData()">none</span>
                        </td>
                        <td>DE Rules  
                            <span class="text-secondary cursor-pointer pl-2" (click)="allDE()">all</span> / 
                            <span class="text-secondary cursor-pointer" (click)="noDE()">none</span>
                        </td>
                        <td>Offer Matrix  
                            <span class="text-secondary cursor-pointer pl-2" (click)="allOffer()">all</span> / 
                            <span class="text-secondary cursor-pointer" (click)="noOffer()">none</span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-provider>
                    <tr>
                        <td>{{provider.customerName}}</td>
                        <td>
                            <p-checkbox [(ngModel)]="provider.htmlPages" [binary]="true"></p-checkbox>
                        </td>
                        <td>
                            <p-checkbox [(ngModel)]="provider.alerts" [binary]="true"></p-checkbox>
                        </td>
                        <td>
                            <p-checkbox [(ngModel)]="provider.pageData" [binary]="true"></p-checkbox>
                        </td>
                        <td>
                            <p-checkbox [(ngModel)]="provider.deRules" [binary]="true"></p-checkbox>
                        </td>
                        <td>
                            <p-checkbox [(ngModel)]="provider.offerMatrix" [binary]="true"></p-checkbox>
                        </td>                        
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [colSpan]="7">No Customer Content found.</td>
                    </tr>
                </ng-template>
            </p-table>
            </div>

        </div>
    </div>
</div>
