export class OfferRanges {
    offerDetailRangeID: number = 0;
    customerID: number = 0;
    minLoanAmt?: number; 
    maxLoanAmt?: number; 
    min_ProcedureAmt?: number; 
    max_ProcedureAmt?: number; 
    min_APRAmt?: number; 
    max_APRAmt?: number; 
    min_DownPaymentPct?: number; 
    max_DownPaymentPct?: number; 
    min_Term?: number; 
    max_Term?: number; 
    min_MDR?: number; 
    max_MDR?: number; 
    min_VantageScore?: number; 
    max_VantageScore?: number; 
    min_MinPaymentAmount?: number; 
    max_MinPaymentAmount?: number; 
}

export class OfferRangesUpload {
    customerGuid: string = '';
    minLoanAmt?: number; 
    maxLoanAmt?: number; 
    min_ProcedureAmt?: number; 
    max_ProcedureAmt?: number; 
    min_APRAmt?: number; 
    max_APRAmt?: number; 
    min_DownPaymentPct?: number; 
    max_DownPaymentPct?: number; 
    min_Term?: number; 
    max_Term?: number; 
    min_MDR?: number; 
    max_MDR?: number; 
    min_VantageScore?: number; 
    max_VantageScore?: number; 
    min_MinPaymentAmount?: number; 
    max_MinPaymentAmount?: number; 
}