import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PortfolioItem } from 'src/app/models/customer-item';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  saving = false;
  showEdit = false;
  userGuid: any = null;;
  portfolioItem: PortfolioItem | null | undefined;
  loading = false;
  user: any;
  bcItems: MenuItem[] = [
    { label: 'Roles' },
    { label: 'Users' },
    { label: 'User Edit' },
  ];
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toast: ToastService,
    private api: ApiService,
    private portfolioService: PortfolioService) { }

  ngOnInit(): void {
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      this.getUser();
    });

    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.userGuid = paramMap.get('userGuid');
      this.getUser();
    });
  }

  getUser(){
    if (this.portfolioItem && this.portfolioItem.customerGuid && this.userGuid) {
      this.loading = true;
      this.api.get(`AdminUser/get-details/${this.portfolioItem.customerGuid}/${this.userGuid}`).subscribe(data => {
        this.user = data;
        this.loading = false;
      });
    }
  }

  edit(){
    this.showEdit = true;
  }

  onEditComplate(ev: any){
    this.showEdit = false;

  }

  save(){
    let obj = {
      UserGUID: this.userGuid,
      ...this.user
    };
    this.saving = true;
    var json = JSON.stringify(JSON.stringify(obj));
    this.api.post(`adminuser/update-settings`, json).subscribe(res => {
      this.toast.addSuccess("User successfully updated!");
      this.saving = false;
    }, err => {
      console.error(err);
      this.saving = false;
      this.toast.addError("Invalid form. Server Error occured.");
    });
  }

}
