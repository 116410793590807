<div *ngIf="emptyDataMsg">
    <div class="text-2xl cds-error-text">ERROR! Unable to find data.</div>
</div>

<div *ngIf="!emptyDataMsg">
    <form *ngIf="buttonFormLoaded" [formGroup]="buttonForm" (ngSubmit)="saveButtonForm();">
        <div class="grid pr-4">
            <div class="col-6">
                <p3solved-form-text id="txtNm" placeholder="Name" formControlName="name"
                    [disabled]="data.versionId != 0"></p3solved-form-text>
            </div>
            <div class="col-6">
                <p3solved-form-text id="txtObjId" placeholder="Object ID" formControlName="objectId"
                    [disabled]="data.versionId != 0"></p3solved-form-text>
            </div>

            <div class="col-6">
                <p3solved-form-text id="txtAcopy" placeholder="Copy" formControlName="text"></p3solved-form-text>
            </div>
            <div class="col-6">
                <p3solved-form-text id="txtDestUrl" placeholder="Destination URL"
                    formControlName="destUrl"></p3solved-form-text>
            </div>

            <div class="col-6">
                <p3solved-dropdown id="ddPages" placeholder="Go to Page" [items]="pages" [showClear]="true"
                    optionLabel="pageName" optionValue="pageID" formControlName="goToPg">
                </p3solved-dropdown>
            </div>
            <div class="col-6">
                <p3solved-form-text id="txtCss" placeholder="Css Class" formControlName="css"></p3solved-form-text>
            </div>

            <div class="col-3 align-self-center">
                <p-checkbox [formControl]="$any(buttonForm.controls['required'])" [binary]="true"
                    label="Required"></p-checkbox>
            </div>
            <div class="col-3 align-self-center">
                <p-checkbox [formControl]="$any(buttonForm.controls['autoLogin'])" [binary]="true"
                    label="Auto Login"></p-checkbox>
            </div>
            <div class="col-12 flex flex-wrap">
                <div class="w-3 align-self-center">
                    <p-checkbox [formControl]="$any(buttonForm.controls['useActions'])" [binary]="true"
                        label="Button Actions"></p-checkbox>
                </div>
                <div class="w-8 grid">&nbsp;</div>
                <div class="w-6 grid" *ngIf="buttonForm.value.useActions">
                    <div class="col-6">
                        <p3solved-dropdown id="ddBntActs" placeholder="Select Button Action" [items]="buttonActionList"
                            optionLabel="serverValidationName" optionValue="serverValidationID"
                            formControlName="buttonActionDD"></p3solved-dropdown>
                    </div>
                    <span class="pl-2 cds-text-color cursor-pointer align-self-center " (click)="addButtonAction()">Add
                        Button Action</span>
                </div>
                <div class="w-12 p-5" *ngIf="buttonForm.value.useActions">
                    <table>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Error Message</th>
                            <th></th>
                        </tr>
                        <ng-container formArrayName="buttonActions">
                            <tr *ngFor="let ba of buttonForm.controls.buttonActions.controls; index as i">
                                <ng-container [formGroupName]="i">
                                    <td>#{{ba.value.id}}</td>
                                    <td>
                                        {{ba.value.name}}
                                    </td>
                                    <td>
                                        <p3solved-form-text id="txtSvrErr{{i}}"
                                            formControlName="errMsg"></p3solved-form-text>
                                    </td>
                                    <td class="pl-2">
                                        <i class="cursor-pointer pi pi-trash text-primary" (click)="deleteButtonAction(i)"></i>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>
            <div class="col-12 flex flex-row-reverse">
                <p3solved-button id="btnS" label="Save" buttonType="submit"></p3solved-button>
                <p3solved-button id="btnCncl" label="Cancel" class="px-4 outline"
                    (buttonClick)="cancelForm()"></p3solved-button>
            </div>
        </div>
    </form>
</div>