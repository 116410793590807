import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { LookupModel } from 'src/app/models/lookup-model';
import { ProviderService } from 'src/app/services/provider.service';

@Component({
  selector: 'app-edit-branding',
  templateUrl: './edit-branding.component.html',
  styleUrls: ['./edit-branding.component.scss']
})
export class EditBrandingComponent implements OnInit {
  colorStrips: LookupModel[] = [];
  pallettes: LookupModel[] = [];
  brandingForm = this.fb.group({
    logoStrip: ['', Validators.required],
    colorPallete: ['', Validators.required],
    imgLogo: [''],
    imgLanding: [''],
    imgRecap: [''],
    imgLogin: ['']
  });
  constructor(private providerService: ProviderService, private fb: UntypedFormBuilder) {
    this.initLookups();
   }

  ngOnInit(): void {
  }

  initLookups(){
    this.providerService.customerLookups$.subscribe(
      (lookups: ICustomerLookups) => {
        this.colorStrips = lookups.colorStrips || [];
        this.pallettes = lookups.colorPallettes || [];
      });
  }

  onSubmit(){

    console.warn(this.brandingForm.value);
  }
}
