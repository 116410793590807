export class LookupModel {
    id: any = '0';
    desc: string = '';
    value?: string = '';
}

export class CampaignLookups {
    actions: LookupModel[] = [];
    contentTypes: LookupModel[] = [];
    notificationTypes: LookupModel[] = [];
    requestTypes: LookupModel[] = [];
}

export class AlertLookups {
    delays: AlertDelay[] = [];
    loanAppStatuses: AlertLoanStatus[] = [];
}

export class AlertDelay {
    alertDelayID: number = 0;
    customerID: number = 0;
    delayValue: number = 0;
    delayText: string | null = null;
    campaignEndSubstract: boolean = false;
    deleted: boolean = false;
    delayBusinessDaysSupport: boolean = false;
    delayTypeID: number = -1;
}

export class AlertLoanStatus {
    loanAppStatusID: number = 0;
    customerID: number = -1;
    loanAppStatusShortDescription: string = '';
    loanAppStatusLongDescription: string = '';
    statusColorID: number = 0;
    loanAppRolledStatusID: number = 1;
    loanAppStatusPage: string = '';
    userLoginStatusId?: number;
    defaultLoanStatus: boolean = false;
    redirectToPage: boolean = false;
    redirectToPageID?: number;
    allowMyAccount: boolean = false;
    statusOrder: number = 0;
    overwriteByLower: boolean = false;
    overwriteHigher: boolean = false;
    onChangeTo_Trigger: string | null = null;
    onChangeFrom_Trigger: string | null = null;
    myAccountRedirect: string | null = null;
    reapplyAvailable: boolean = false;
    refinanceAvailable: boolean = false;
    cSRSearchShow: boolean = false;
    ruleReasonShow: boolean = false;
    accountSummaryRedirect: string | null = null;
    verifyQueueAutoAssign: boolean = false;
    cSRVerifyQueueShow: boolean = false;
    cSRLeadQueueShow: boolean = false;
    accountSummaryMessage: string | null = null;
    bopStatusID: number = -1;
    aPIEnabled: boolean = false;
    createNewLead: boolean = true;
    lenderID: number = -1;
}

export class ComplianceLookups {
    channels: LookupModel[] = [];
    legalStatuses: LookupModel[] = [];
    loanAppStatuses: LookupModel[] = [];
    loanStatuses: LookupModel[] = [];
    noteCategories: LookupModel[] = [];
    states: LookupModel[] = [];
}