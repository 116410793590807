import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provider-dashboard',
  templateUrl: './provider-dashboard.component.html',
  styleUrls: ['./provider-dashboard.component.scss']
})
export class ProviderDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
