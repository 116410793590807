import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-cc-textarea-alt',
  templateUrl: './cc-textarea-alt.component.html',
  styleUrls: ['./cc-textarea-alt.component.scss']
})
export class CcTextareaAltComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() maxLen: number = 5000;
  @Input() id?: string;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() val: string = "";
  @Input() rows: number = 2;
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  iconRequired = faAsterisk as IconProp;
  constructor() {
    var d = new Date();
    this.id = d.getTime().toString();
  }

  ngOnInit(): void {
  }

}
