<form [formGroup]="procedureForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-sm-12">
            <app-cc-input
                controlName="productDesc"
                [group]="procedureForm"
                label="Product Desc">
            </app-cc-input>
        </div>
        <div class="col-sm-12">
            <app-cc-input
                controlName="searchValue"
                [group]="procedureForm"
                label="DE Search Value">
            </app-cc-input>
        </div>
        <div class="col-sm-12">
            <app-cc-input
                controlName="productSku"
                [group]="procedureForm"
                label="Product SKU">
            </app-cc-input>
        </div>
        <div class="col-sm-12">
            <app-cc-input
                controlName="productMrsp"
                [group]="procedureForm"
                label="Product MRSP">
            </app-cc-input>
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <div class="p-2">
            <button class="btn btn-secondary btn-sm" (click)="onCancel()" type="button">Cancel</button>
        </div>
        <div class="p-2">
            <button class="btn btn-primary btn-main btn-sm" type="submit">Save Procedure</button>
        </div>
    </div>
    
</form>
