<div class="left-nav-container" (mouseenter)="toggleSideMenu('open')" (mouseleave)="toggleSideMenu('close')">
    <div class="left-nav-items">
        <div>&nbsp;</div>
        <div *ngIf="!sideMenuPinned" class="cds-left-nav-item cds-left-nav-menu-icon cds-left-nav-close"
            (click)="toggleSideMenuPin();">
            <i class="pi pi-lock-open text-xl"></i>
        </div>
        <div *ngIf="sideMenuPinned" class="cds-left-nav-item cds-left-nav-menu-icon cds-left-nav-close"
            (click)="toggleSideMenuPin();">
            <i class="pi pi-lock text-xl"></i>
        </div>
        <ng-template ngFor let-item [ngForOf]="menus" let-i="index">
            <div *ngIf="item.show" class="cds-left-nav-item cds-left-nav-menu-icon" (click)="menuSelected(item, true, true)">
                <mat-icon [svgIcon]="item.icon"></mat-icon>
            </div>
            <div *ngIf="item.show" class="cds-left-nav-item cds-left-nav-menu-text" (click)="menuSelected(item, true, true)">
                <span *ngIf="showText" class="cds-left-nav-menu-text" [ngClass]="item.selected ? 'font-bold': 'font-light'">{{item.title}}</span>
                <span *ngIf="item.children.length">
                    <i [ngClass]="item.selected ? 'pi pi-angle-down' : 'pi pi-angle-right'"></i>
                </span>
            </div>
            <ng-template ngFor let-subItem [ngForOf]="item.children" let-j="index">
                <div *ngIf="item.selected" class="cds-left-nav-item cds-left-nav-menu-icon sub-icon"
                (click)="childSelected(subItem, item)">
                <mat-icon [svgIcon]="subItem.icon"></mat-icon>
            </div>
            <div *ngIf="item.selected" class="cds-left-nav-item cds-left-nav-menu-text"
                (click)="childSelected(subItem, item)">
                <span *ngIf="showText" class="cds-left-nav-menu-text" [ngClass]="subItem.selected ? 'font-bold': 'font-light'">{{subItem.title}}</span>
            </div>
            </ng-template>
        </ng-template>
    </div>
</div>
<ng-template #spacer>
    &nbsp;
</ng-template>