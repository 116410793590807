
  <div [formGroup]="group" class="mb-0">
    <mat-form-field class="form-field" appearance="standard">
        <mat-label>{{label}}
            <fa-icon 
            *ngIf="group && group.controls[controlName]?.errors?.required" 
            [icon]="iconRequired" 
            size="xs" 
            [styles]="{'color': 'red'}" 
            [classes]="['ms-1']">
        </fa-icon>
        </mat-label>
        <input matInput [formControlName]="controlName">
        <!-- <mat-error *ngIf="group && group.controls[controlName]?.invalid">{{getErrorMessage()}}</mat-error> -->
        <!-- <mat-error *ngIf="loginForm.controls.email.touched && loginForm.controls.email.invalid">
          <span *ngIf="loginForm.controls.email.errors.required">This field is mandatory.</span>
          <span *ngIf="loginForm.controls.email.errors.pattern">This field is invalid.</span>
        </mat-error> -->
      </mat-form-field>
  </div>
