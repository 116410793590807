import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { LookupModel } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { ipregex } from 'src/app/services/util.service';

@Component({
  selector: 'app-edituser-security',
  templateUrl: './edituser-security.component.html',
  styleUrls: ['./edituser-security.component.scss']
})
export class EdituserSecurityComponent implements OnInit {

  newIp = new FormControl('', [Validators.required, Validators.pattern(ipregex)]);
  anyIp = true;
  accessTimes: LookupModel[] = [];
  @Input() userGuid = '';
  @Output() onEditComplete = new EventEmitter<string>();
  @Input()
  get user(): any { return this._user; }
  set user(user: any) {
    this._user = user;
    this.initForm();
    this.initRangeForms();
    this.initDistinctIps();
  }
  private _user = '';
  securityForm: FormGroup = new FormGroup({});
  distinctIps: string[] = [];
  constructor(private api: ApiService,
    private fb: FormBuilder,
    private toast: ToastService,
    private portfolioService: PortfolioService) {
    this.setTimes();
  }

  ngOnInit(): void {
  }

  initForm() {
    var ip = this.user.LoginAllowedIPs == '127.0.0.1' ? '1' : '2';
    this.securityForm = this.fb.group({
      AnyIp: [ip, [Validators.required]],
      schedules: this.fb.array([])
    });
    this.anyIp = ip == "1";
    this.securityForm.get("AnyIp")?.valueChanges.subscribe(x => {
      this.anyIp = x == "1";
      if (this.anyIp){
        this.distinctIps = [];
      }else{
        this.user.LoginAllowedIPs = '127.0.0.1';
      }
    })
  }

  get schedules() {
    return this.securityForm.controls["schedules"] as FormArray;
  }

  getSchedule(range: iAccessTime) {
    return this.fb.group({
      start: new FormControl(range.start, Validators.required),
      end: new FormControl(range.end, Validators.required),
      ix: new FormControl(range.ix),
    });
  }

  setTimes() {
    this.accessTimes = [];
    this.accessTimes.push({ desc: 'ALL DAY', id: '-1' });
    this.accessTimes.push({ desc: 'NONE', id: '-2' });
    for (var h = 0; h < 24; h++) {
      for (var m = 0; m < 60; m += 15) {
        var hour = h < 12 ? h : h - 12;
        var text = (hour == 0 ? "12" : hour.toString()) + ":" + (m < 10 ? "0" + m : "" + m) + (h < 12 ? " AM" : " PM");
        var v = h * 60 + m;
        var value = v.toString();
        var min = 0;
        var max = 1430;

        if (v >= min && v <= max) {
          this.accessTimes.push({ id: value, desc: text });
        }
      }
    }
  }

  initDistinctIps() {
    this.distinctIps = [];
    var val = this.user.LoginAllowedIPs;
    if (val != '127.0.0.1') {
      this.distinctIps = val.split(',');
    }
  }

  initRangeForms() {
    this.schedules.setValue([]);
    let jsonModel: any = this.user.LoginAccessTimeJson ? JSON.parse(this.user.LoginAccessTimeJson) : {};
    var day = jsonModel?.schedule?.day || [];
    for (var i = 1; i <= 7; i++) {
      var exist = day.find((x: iAccessTime) => x.ix == i);
      this.schedules.push(this.getSchedule(exist || { start: '-1', end: '-1', ix: i }));
    }
    this.updateSchedules();
  }

  getDay(i: number) {
    var dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return dayOfWeek[i];
  }

  updateSchedules(){
    // let arr:any[] = [];
    // this.schedules.value.forEach((item: any) => {
    //   arr.push(item);
    // });
    this.user.AccessSchedules = this.schedules.value;
  }

  addnewIp() {
    if (this.newIp.valid) {
      if (this.newIp.value) {
        var val = this.newIp.value.trim();
        var i = this.distinctIps.findIndex(x => x == val);
        if (i == -1) {
          this.distinctIps.push(this.newIp.value);
          this.newIp.setValue('');
          this.newIp.markAsUntouched();
          this.user.LoginAllowedIPs = this.distinctIps.map(ele=>ele).join(',');
        } else {
          this.toast.addError("IP already in list");
        }
      }
    } else {
      this.newIp.markAllAsTouched();
    }
  }

}


export interface iAccessTime {
  start: number,
  end: number,
  ix: number
}