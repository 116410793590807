import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import {
  MsalRedirectComponent
} from '@azure/msal-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CcLoadingComponent } from './components/cc-loading/cc-loading.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProviderListPageComponent } from './pages/provider/provider-list-page/provider-list-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './material-module';
import { EditProviderInfoComponent } from './components/edit-provider/edit-provider-info/edit-provider-info.component';
import { EditBrandingComponent } from './components/edit-provider/edit-branding/edit-branding.component';
import { EditSettlementDetailComponent } from './components/edit-provider/edit-settlement-detail/edit-settlement-detail.component';
import { EditLocationInfoComponent } from './components/edit-provider/edit-location-info/edit-location-info.component';
import { EditOfferDetailsComponent } from './components/edit-provider/edit-offer-details/edit-offer-details.component';
import { EditProcedureInfoComponent } from './components/edit-provider/edit-procedure-info/edit-procedure-info.component';
import { EditCommunicationComponent } from './components/edit-provider/edit-communication/edit-communication.component';
import { EditLoanAppComponent } from './components/edit-provider/edit-loan-app/edit-loan-app.component';
import { EditOtherComponent } from './components/edit-provider/edit-other/edit-other.component';
import { CcSelectComponent } from './components/form-controls/cc-select/cc-select.component';
import { CcInputComponent } from './components/form-controls/cc-input/cc-input.component';
import { CcFileUploadComponent } from './components/form-controls/cc-file-upload/cc-file-upload.component';
import { EditLocationEntryComponent } from './components/edit-provider/edit-location-entry/edit-location-entry.component';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { EditProcedureEntryComponent } from './components/edit-provider/edit-procedure-entry/edit-procedure-entry.component';
import { CcToggleInputComponent } from './components/form-controls/cc-toggle-input/cc-toggle-input.component';
import { CcRadioComponent } from './components/form-controls/cc-radio/cc-radio.component';
import { CcTelInputComponent } from './components/form-controls/cc-tel-input/cc-tel-input.component';
import { CcDatePickerComponent } from './components/form-controls/cc-date-picker/cc-date-picker.component';
import { CcInputDecimalComponent } from './components/form-controls/cc-input-decimal/cc-input-decimal.component';
import { CcNumberComponent } from './components/form-controls/cc-number/cc-number.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { CcInputAltComponent } from './components/form-controls/cc-input-alt/cc-input-alt.component';
import { CcSelectAltComponent } from './components/form-controls/cc-select-alt/cc-select-alt.component';
import { CcTextareaAltComponent } from './components/form-controls/cc-textarea-alt/cc-textarea-alt.component';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { NavHeaderComponent } from './components/nav-header/nav-header.component';
import { P3SolvedLibModule } from '@p3solved/p3solved-ui';
import { LeftNavComponent } from './components/left-nav/left-nav.component';
import { ReportPageComponent } from './pages/report-page/report-page.component';
import { CampaignPageComponent } from './pages/campaign-page/campaign-page.component';
import { SystemPageComponent } from './pages/system-page/system-page.component';
import { ProviderDashboardComponent } from './pages/provider/provider-dashboard/provider-dashboard.component';
import { OriginatorDashboardComponent } from './pages/originator/originator-dashboard/originator-dashboard.component';
import { OriginatorListComponent } from './pages/originator/originator-list/originator-list.component';
import { OriginatorAddComponent } from './pages/originator/originator-add/originator-add.component';
import { DecisionDashboardComponent } from './pages/decision/decision-dashboard/decision-dashboard.component';
import { DecisionRulesetsComponent } from './pages/decision/decision-rulesets/decision-rulesets.component';
import { DecisionRulesComponent } from './pages/decision/decision-rules/decision-rules.component';
import { DecisionReasonsComponent } from './pages/decision/decision-reasons/decision-reasons.component';
import { DecisionLineMatrixComponent } from './pages/decision/decision-line-matrix/decision-line-matrix.component';
import { DecisionOfferMatrixComponent } from './pages/decision/decision-offer-matrix/decision-offer-matrix.component';
import { DecisionBlackListComponent } from './pages/decision/decision-black-list/decision-black-list.component';
import { DecisionCustomFieldsComponent } from './pages/decision/decision-custom-fields/decision-custom-fields.component';
import { ComplianceDashboardComponent } from './pages/compliance/compliance-dashboard/compliance-dashboard.component';
import { CompliancePaymentsComponent } from './pages/compliance/compliance-payments/compliance-payments.component';
import { ComplianceStatementsComponent } from './pages/compliance/compliance-statements/compliance-statements.component';
import { UserDashboardComponent } from './pages/users/user-dashboard/user-dashboard.component';
import { UserRolesComponent } from './pages/users/user-roles/user-roles.component';
import { PartnerDashboardComponent } from './pages/partner/partner-dashboard/partner-dashboard.component';
import { PartnerInstructionsComponent } from './pages/partner/partner-instructions/partner-instructions.component';
import { PartnerLeadComponent } from './pages/partner/partner-lead/partner-lead.component';
import { PartnerDocsComponent } from './pages/partner/partner-docs/partner-docs.component';
import { PartnerFaqComponent } from './pages/partner/partner-faq/partner-faq.component';
import { ExceptionChecksReportComponent } from './components/reports/exception-checks-report/exception-checks-report.component';
import { SettlementReportComponent } from './components/reports/settlement-report/settlement-report.component';
import { AccountingReportComponent } from './components/reports/accounting-report/accounting-report.component';
import { NlsReportComponent } from './components/reports/nls-report/nls-report.component';
import { MiscReportComponent } from './components/reports/misc-report/misc-report.component';
import { SettlementReportPageComponent } from './pages/reports/settlement-report-page/settlement-report-page.component';
import { CampaignNotificationsComponent } from './pages/campaign/campaign-notifications/campaign-notifications.component';
import { ProviderMenuComponent } from './components/provider-menu/provider-menu.component';

import { TableModule } from 'primeng/table';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BadgeModule } from 'primeng/badge';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditorModule } from 'primeng/editor';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ListboxModule } from 'primeng/listbox';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DialogModule } from 'primeng/dialog';

import { CampaignAlertsComponent } from './pages/campaign/campaign-alerts/campaign-alerts.component';
import { CustomGridComponent } from './components/custom-grid/custom-grid.component';
import { ProviderInfoComponent } from './components/provider/provider-info/provider-info.component';
import { ProviderBrandingComponent } from './components/provider/provider-branding/provider-branding.component';
import { ProviderSettlementComponent } from './components/provider/provider-settlement/provider-settlement.component';
import { ProviderLocationComponent } from './components/provider/provider-location/provider-location.component';
import { ProviderOfferComponent } from './components/provider/provider-offer/provider-offer.component';
import { ProviderProcedureComponent } from './components/provider/provider-procedure/provider-procedure.component';
import { ProviderCommunicationComponent } from './components/provider/provider-communication/provider-communication.component';
import { ProviderLoanComponent } from './components/provider/provider-loan/provider-loan.component';
import { ProviderProcessorComponent } from './components/provider/provider-processor/provider-processor.component';
import { ProviderOtherComponent } from './components/provider/provider-other/provider-other.component';
import { ProviderPricingComponent } from './pages/provider/provider-pricing/provider-pricing.component';
import { ProviderOfferLookupComponent } from './pages/provider/provider-offer-lookup/provider-offer-lookup.component';
import { OfferLookupTableComponent } from './pages/provider/offer-lookup-table/offer-lookup-table.component';
import { EditOfferLookupComponent } from './pages/provider/edit-offer-lookup/edit-offer-lookup.component';

import { NewPredefinedLeadComponent } from './pages/partner/new-predefined-lead/new-predefined-lead.component';
import { CampaignPagesComponent } from './pages/campaign/campaign-pages/campaign-pages.component';
import { CampaignSettingsComponent } from './pages/campaign/campaign-settings/campaign-settings.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { CampaignVersionsComponent } from './pages/campaign/campaign-versions/campaign-versions.component';
import { ProviderContentComponent } from './pages/provider/provider-content/provider-content.component';
import { DeExpressionSetupComponent } from './components/de-engine/de-expression-setup/de-expression-setup.component';
import { EditDecisionLineMatrixComponent } from './pages/decision/edit-decision-line-matrix/edit-decision-line-matrix.component';
import { NumericOnlyDirective } from './directives/numeric-only.directive';
import { MsalApplicationModule } from './msal-application.module';
import { TestCssComponent } from './pages/test-css/test-css.component';
import { CampaignPageDataComponent } from './pages/campaign/campaign-page-data/campaign-page-data.component';
import { PageDataScriptComponent } from './components/page-data/page-data-script/page-data-script.component';
import { UserEditComponent } from './pages/users/user-edit/user-edit.component';
import { EdituserInfoComponent } from './components/user/edituser-info/edituser-info.component';
import { EdituserSettingsComponent } from './components/user/edituser-settings/edituser-settings.component';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { CreateUserPageComponent } from './pages/users/create-user-page/create-user-page.component';
import { EdituserSecurityComponent } from './components/user/edituser-security/edituser-security.component';
import { UserRoleEditComponent } from './pages/users/user-role-edit/user-role-edit.component';
import { RoleEntitiesComponent } from './pages/users/role-entities/role-entities.component';
import { PastSettlementsComponent } from './pages/reports/past-settlements/past-settlements.component';
import { SettlementSummaryComponent } from './pages/reports/settlement-summary/settlement-summary.component';
import { PageDataLabelComponent } from './components/page-data/page-data-label/page-data-label.component';
import { PageDataTextboxComponent } from './components/page-data/page-data-textbox/page-data-textbox.component';
import { PageDataHrefComponent } from './components/page-data/page-data-href/page-data-href.component';
import { PageDataButtonComponent } from './components/page-data/page-data-button/page-data-button.component';
import { PageDataDropdownComponent } from './components/page-data/page-data-dropdown/page-data-dropdown.component';
import { PageDataRadiobuttonComponent } from './components/page-data/page-data-radiobutton/page-data-radiobutton.component';
import { PageDataCheckboxlistComponent } from './components/page-data/page-data-checkboxlist/page-data-checkboxlist.component';
import { PageDataUnsortlistComponent } from './components/page-data/page-data-unsortlist/page-data-unsortlist.component';
import { CampaignSeedComponent } from './pages/campaign/campaign-seed/campaign-seed.component';
import { CompliancePaymentStatementComponent } from './components/compliance-payment-statement/compliance-payment-statement.component';
import { UserAccessGuard } from './guards/user-access.guard';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    CcLoadingComponent,
    DashboardPageComponent,
    ProviderListPageComponent,
    EditProviderInfoComponent,
    EditBrandingComponent,
    EditSettlementDetailComponent,
    EditLocationInfoComponent,
    EditOfferDetailsComponent,
    EditProcedureInfoComponent,
    EditCommunicationComponent,
    EditLoanAppComponent,
    EditOtherComponent,
    CcSelectComponent,
    CcInputComponent,
    CcFileUploadComponent,
    EditLocationEntryComponent,
    EditProcedureEntryComponent,
    CcToggleInputComponent,
    CcRadioComponent,
    CcTelInputComponent,
    CcDatePickerComponent,
    CcInputDecimalComponent,
    CcNumberComponent,
    ProfileMenuComponent,
    CcInputAltComponent,
    CcSelectAltComponent,
    CcTextareaAltComponent,
    FormatDatePipe,
    NavHeaderComponent,
    LeftNavComponent,
    ReportPageComponent,
    CampaignPageComponent,
    SystemPageComponent,
    ProviderDashboardComponent,
    OriginatorDashboardComponent,
    OriginatorListComponent,
    OriginatorAddComponent,
    DecisionDashboardComponent,
    DecisionRulesetsComponent,
    DecisionRulesComponent,
    DecisionReasonsComponent,
    DecisionLineMatrixComponent,
    DecisionOfferMatrixComponent,
    DecisionBlackListComponent,
    DecisionCustomFieldsComponent,
    ComplianceDashboardComponent,
    CompliancePaymentsComponent,
    ComplianceStatementsComponent,
    UserDashboardComponent,
    UserRolesComponent,
    PartnerDashboardComponent,
    PartnerInstructionsComponent,
    PartnerLeadComponent,
    PartnerDocsComponent,
    PartnerFaqComponent,
    ExceptionChecksReportComponent,
    SettlementReportComponent,
    AccountingReportComponent,
    NlsReportComponent,
    MiscReportComponent,
    SettlementReportPageComponent,
    CampaignNotificationsComponent,
    ProviderMenuComponent,
    CampaignAlertsComponent,
    CustomGridComponent,
    ProviderInfoComponent,
    ProviderBrandingComponent,
    ProviderSettlementComponent,
    ProviderLocationComponent,
    ProviderOfferComponent,
    ProviderProcedureComponent,
    ProviderCommunicationComponent,
    ProviderLoanComponent,
    ProviderProcessorComponent,
    ProviderOtherComponent,
    ProviderPricingComponent,
    ProviderOfferLookupComponent,
    OfferLookupTableComponent,
    EditOfferLookupComponent,
    NewPredefinedLeadComponent,
    CampaignPagesComponent,
    CampaignSettingsComponent,
    NotAuthorizedComponent,
    CampaignVersionsComponent,
    ProviderContentComponent,
    DeExpressionSetupComponent,
    EditDecisionLineMatrixComponent,
    NumericOnlyDirective,
    TestCssComponent,
    CampaignPageDataComponent,
    PageDataScriptComponent,
    UserEditComponent,
    EdituserInfoComponent,
    EdituserSettingsComponent,
    CheckboxListComponent,
    CreateUserPageComponent,
    EdituserSecurityComponent,
    UserRoleEditComponent,
    RoleEntitiesComponent,
    PastSettlementsComponent,
    SettlementSummaryComponent,
    PageDataLabelComponent,
    PageDataTextboxComponent,
    PageDataHrefComponent,
    PageDataButtonComponent,
    PageDataDropdownComponent,
    PageDataRadiobuttonComponent,
    PageDataCheckboxlistComponent,
    PageDataUnsortlistComponent,
    CampaignSeedComponent,
    CompliancePaymentStatementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    P3SolvedLibModule,
    TableModule,
    BreadcrumbModule,
    InputTextModule,
    InputTextareaModule,
    BadgeModule,
    ConfirmDialogModule,
    CheckboxModule,
    EditorModule,
    TooltipModule,
    AutoCompleteModule,
    ListboxModule,
    TabMenuModule,
    TabViewModule,
    DialogModule,
    SelectButtonModule,
    InputSwitchModule,
    RadioButtonModule,
    ToggleButtonModule,
    MsalApplicationModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    AppMaterialModule,
    FontAwesomeModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule,
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appSettingsLoader,
    //   deps: [SettingService,],
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ApiInterceptor,
    //   multi: true
    // },
    // {
    //   provide: MSAL_INSTANCE,
    //   useFactory: MSALInstanceFactory
    // },
    // MsalService,
    // MsalGuard,
    // MsalBroadcastService,
    // ApiService,
    UserAccessGuard,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
