import { AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CustomGridComponent } from 'src/app/components/custom-grid/custom-grid.component';
import { PortfolioItem } from 'src/app/models/customer-item';
import { DEReasonDecisionType, DEReasonLookups } from 'src/app/models/decision/reason-lookups';
import { DEReason, DEReasonSavedDocument, DEReasonSavedVerification, DERuleReason } from 'src/app/models/decision/rule-reason';
import { DEReasonDocumentFormGroup, DEReasonFormGroup, DEReasonVerificationFormGroup } from 'src/app/models/form-groups';
import { AdminQueryParams } from 'src/app/models/query-params';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NavService, NavSettings } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-decision-reasons',
  templateUrl: './decision-reasons.component.html',
  styleUrls: ['./decision-reasons.component.scss']
})
export class DecisionReasonsComponent implements OnInit, AfterViewInit, OnDestroy {

  editFeatureCode: string = 'DECISION_ENGINE_RULE_REASON_EDIT';
  hasEditFeature: boolean = false;

  queryParams: AdminQueryParams[] = [];
  isInventory: boolean = true;
  subscriptions: Subscription[] = [];
  loading: boolean = true;
  hasError: boolean = false;
  showCard: boolean = false;
  showReasonEdit: boolean = false;

  showDisclosure: boolean = true;
  showNOAACheckbox: boolean = false;
  showDocuments: boolean = false;
  showVerifications: boolean = false;

  selectedReason: DERuleReason | null = null;
  portfolio: PortfolioItem | null = null;
  leftNavExpanded: boolean = false;
  bcItems: MenuItem[] = [];
  filterDecisionTypes: DEReasonDecisionType[] = [];

  reasonFormLoaded: boolean = false;
  reasonForm: FormGroup<DEReasonFormGroup> = {} as FormGroup;
  txtDisclosurePlaceholder: string = 'Disclosure';

  @ViewChild('cgRuleReas') cgRuleReas: CustomGridComponent = {} as CustomGridComponent;
  private readonly pageBaseBc: string = "Rule Reasons";
  private readonly pageAddBc: string = "Rule Reason Add";
  private readonly pageEditBc: string = "Rule Reason Edit";
  private readonly pageNavId: string = "DecisionEngine.Reasons";

  reasonEditTitle: string = this.pageAddBc;
  lookups: DEReasonLookups = {} as DEReasonLookups;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private portfolioService: PortfolioService,
    private toastService: ToastService,
    private navService: NavService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private breadCrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.navService.setLeftNavSelection('DecisionEngine.Reasons');
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params && params.data) {
          let decoded = JSON.parse(atob(params.data)) as AdminQueryParams[];
          if (decoded && decoded.length > 0) {
            this.queryParams = decoded;
          }
        }
      }),
      this.navService.leftNavExpanded$.subscribe((isExpanded: boolean) => {
        this.leftNavExpanded = isExpanded;
      }),
      this.navService.leftNaveSelection$.subscribe((navObj: NavSettings) => {
        if (navObj.navId && navObj.navId == this.pageNavId) {
          this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
        }
      })
    );

    this.hasEditFeature = this.userService.hasWrite(this.editFeatureCode);

    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Decision Engine', routerLink: ['/decision/dashboard'], command: () => { this.navService.setLeftNavSelection('DecisionEngine.Dashboard'); } },
      { label: this.pageBaseBc }
    ];
    this.loading = false;
    this.showCard = true;
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.portfolioService.portfolio$.subscribe(p => {
        this.portfolio = p;
        this.lookups = {} as DEReasonLookups;
        this.getReasonLookups();
        this.cgRuleReas.setCustomerInfo(p?.customerGuid ?? null, null);
        this.cgRuleReas.getDataInfo();
        if (this.queryParams.length > 0) {
          this.runQueryParams();
        }
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      })
    }
  }

  runQueryParams(attempts: number = 0) {    
      let ruleParam = this.queryParams.pop();    
      if (ruleParam) {
        if (ruleParam.providerGuid != this.portfolio?.customerGuid) {
          this.queryParams = [];
          return;
        }
        if (ruleParam.page != this.route.component?.name) {
          this.queryParams.push(ruleParam);
          return;
        }

        if (ruleParam.data && ruleParam.data.ruleReasonGuid) {
          let reason = this.cgRuleReas.findDataItem('RuleReasonGUID', ruleParam.data.ruleReasonGuid);          
          if (reason) {
            this.isInventory = ruleParam.data.isInventory ?? true;
            this.editRuleReason(reason);
          }
          else if (attempts < 5) {
            this.queryParams.push(ruleParam);
            attempts++;
            setTimeout(() => {
              this.runQueryParams(attempts);
            }, 100);
          }
          else {
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to find matching Reason to edit. See log for details'
            });
          }
        }
      }    
  }

  reloadMainPage(attempts: number = 0) {
    this.queryParams = [];
    if (this.cgRuleReas) {
      this.cgRuleReas.setCustomerInfo(this.portfolio?.customerGuid ?? null, null);
      this.cgRuleReas.getDataInfo();
    }
    else {
      if (attempts > 10) {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to relaod Rule Reasons. Please refresh page.'
        });
      }
      else {
        setTimeout(() => {
          attempts++;
          this.reloadMainPage(attempts);
        }, 100);
      }
    }
  }

  getReasonLookups() {
    if (!this.lookups || !this.lookups.appStatuses || !this.lookups.appStatuses.length) {
      let lookSub = this.apiService.get(`decision/reasons/lookups/${this.portfolio?.customerGuid}`)
        .subscribe({
          next: (data: DEReasonLookups) => {
            this.lookups = data;
            this.filterDecisionTypes = [...this.lookups.decisionTypes];
            this.filterDecisionTypes.unshift({
              decisionTypeID: -1,
              decisionType: 'All Items',
              documentRequired: false,
              verificationRequired: false,
              customerID: 0,
              deleted: false,
              computeMatrixAmount: false
            });
          },
          error: (err: any) => {
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to Offer Matrix lookups for Customer. See log for details.'
            });
            console.error(err);
          },
          complete: () => { lookSub.unsubscribe(); }
        });
    }
  }

  decisionTypeFilterChanged(filter: DEReasonDecisionType) {
    if (filter.decisionTypeID == -1) {
      this.setRowsCustomGridXML('', 1);
    }
    else {
      this.setRowsCustomGridXML(`<params><f fn=\"DecisionTypeID\" fv=\"${filter.decisionTypeID}\" /></params>`, 1);
    }
  }

  setRowsCustomGridXML(xml: string, attempt: number) {
    if (this.cgRuleReas) {
      this.cgRuleReas.SearchXML = xml;
      this.cgRuleReas.getDataFromSource();
    }
    else {
      if (attempt > 10) {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to set filter on Rule Reasons.'
        });
        console.error("Unable to set filter (SearchXML) on Rule Reasons custom grid component!");
      }
      else {
        setTimeout(() => {
          attempt++;
          this.setRowsCustomGridXML(xml, attempt);
        }, 100);
      }
    }
  }

  addRuleReason() {
    this.prepReasonForm(this.pageAddBc);
    this.selectedReason = null;
    this.showCard = false;
    this.showReasonEdit = true;
  }

  editRuleReason(rule: DERuleReason) {
    if (!this.hasEditFeature) {
      this.toastService.addError('You do not have permissions to edit this Rule Reason.');
      return;
    }
    this.selectedReason = rule;
    let editSub = this.apiService.get(`decision/reason/${rule.RuleReasonGUID}/customer/${this.portfolio?.customerGuid}`)
      .subscribe({
        next: (reason: DEReason) => {
          this.prepReasonForm(this.pageEditBc);
          this.reasonForm.patchValue({
            name: reason.ruleReasonName,
            decisionType: reason.decisionTypeID,
            appStatus: reason.loanAppStatusID,
            disclosure: reason.customDescription ?? '',
            ignoreRFAIDocs: reason.ignoreRFAIDocsNOAADisclosure
          });

          if (rule.Documents && rule.Documents.length > 0 && +rule.Documents > 0) {
            this.getDocuments(rule);
          }
          if (rule.Verifications && rule.Verifications.length > 0 && +rule.Verifications > 0) {
            this.getVerifications(rule);
          }
          this.decisionTypeSelected(reason.decisionTypeID);
          this.showCard = false;
          this.showReasonEdit = true;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Rule Reason data.'
          });
          console.error(err);
        },
        complete: () => { editSub.unsubscribe(); }
      });
  }

  getDocuments(rule: DERuleReason) {
    let docSub = this.apiService.get(`decision/reason/${rule.RuleReasonGUID}/documents/${this.portfolio?.customerGuid}`)
      .subscribe({
        next: (docs: DEReasonSavedDocument[]) => {
          docs.forEach(doc => {
            let defaultDoc = this.lookups.documents.find(d => d.documentID == doc.documentID);
            let existDoc = this.reasonForm.value.documents?.find(d => d.id == doc.documentID);
            if (existDoc) {
              let docIndex = this.reasonForm.value.documents?.indexOf(existDoc);
              if (docIndex != undefined && docIndex >= 0) {
                this.reasonForm.controls.documents.at(docIndex).patchValue({
                  selected: true,
                  document: doc.document,
                  description: defaultDoc?.noaaDisclosure ?? '',
                  pass: doc.passShow,
                  neutral: doc.neutralShow,
                  fail: doc.failShow
                });
              }
            }
            else {
              this.reasonForm.controls.documents.push(
                this.formBuilder.group<DEReasonDocumentFormGroup>({
                  id: new FormControl<number>(doc.documentID, { nonNullable: true }),
                  selected: new FormControl<boolean>(true, { nonNullable: true }),
                  document: new FormControl<string>(doc.document ?? '', { nonNullable: true }),
                  description: new FormControl<string>(doc.documentDescription ?? '', { nonNullable: true }),
                  disclosure: new FormControl<string>(defaultDoc?.noaaDisclosure ?? '', { nonNullable: true }),
                  pass: new FormControl<boolean>(doc.passShow, { nonNullable: true }),
                  neutral: new FormControl<boolean>(doc.neutralShow, { nonNullable: true }),
                  fail: new FormControl<boolean>(doc.failShow, { nonNullable: true })
                })
              );
            }
          });
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Rule Reason documents.'
          });
          console.error(err);
        },
        complete: () => { docSub.unsubscribe(); }
      });
  }

  getVerifications(rule: DERuleReason) {
    let verSub = this.apiService.get(`decision/reason/${rule.RuleReasonGUID}/verifications/${this.portfolio?.customerGuid}`)
      .subscribe({
        next: (verifications: DEReasonSavedVerification[]) => {
          verifications.forEach(ver => {
            let defaultVer = this.lookups.verifications.find(v => v.verificationID == ver.verificationID);
            let existVer = this.reasonForm.value.verifications?.find(v => v.id == ver.verificationID);
            if (existVer) {
              let verIndex = this.reasonForm.value.verifications?.indexOf(existVer);
              if (verIndex != undefined && verIndex >= 0) {
                this.reasonForm.controls.verifications.at(verIndex).patchValue({
                  selected: true,
                  verification: ver.verification,
                  description: ver.verificationDescription ?? '',
                  pass: ver.passShow,
                  neutral: ver.neutralShow,
                  fail: ver.failShow
                });
              }
            }
            else {
              this.reasonForm.controls.verifications.push(
                this.formBuilder.group<DEReasonVerificationFormGroup>({
                  id: new FormControl<number>(ver.verificationID, { nonNullable: true }),
                  selected: new FormControl<boolean>(true, { nonNullable: true }),
                  verification: new FormControl<string>(ver.verification ?? '', { nonNullable: true }),
                  description: new FormControl<string>(ver.verificationDescription ?? '', { nonNullable: true }),
                  pass: new FormControl<boolean>(ver.passShow, { nonNullable: true }),
                  neutral: new FormControl<boolean>(ver.neutralShow, { nonNullable: true }),
                  fail: new FormControl<boolean>(ver.failShow, { nonNullable: true })
                })
              );
            }
          });
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Rule Reason verifications.'
          });
          console.error(err);
        },
        complete: () => { verSub.unsubscribe(); }
      });
  }

  deleteReason(reason: DERuleReason) {
    if (!this.hasEditFeature) {
      this.toastService.addError('You do not have permissions to delete this Rule Reason.');
      return;
    }
    let delSub = this.apiService.get(`decision/reason/${reason.RuleReasonGUID}/delete/${this.portfolio?.customerGuid}`)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Rule Reason successfully deleted.'
          });
          this.cgRuleReas.getDataFromSource();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to delete Rule Reason. See log for details.'
          });
          console.error(err);
        },
        complete: () => { delSub.unsubscribe(); }
      })
  }

  prepReasonForm(label: string) {
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => {
        this.reasonFormLoaded = false;
        this.showCard = true;
        this.showReasonEdit = false;
        this.reloadMainPage();
        this.breadCrumbService.reduceBcList(this.bcItems, prevItem?.label);
      };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: label });
    this.reasonEditTitle = label;
    this.createReasonForm();
  }

  createReasonForm() {
    this.reasonForm = this.formBuilder.group<DEReasonFormGroup>({
      name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      decisionType: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      appStatus: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      disclosure: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      ignoreRFAIDocs: new FormControl<boolean>(false, { nonNullable: true }),
      verifications: this.formBuilder.array([
        this.formBuilder.group<DEReasonVerificationFormGroup>({
          id: new FormControl<number>(0, { nonNullable: true }),
          verification: new FormControl<string>('', { nonNullable: true }),
          selected: new FormControl<boolean>(false, { nonNullable: true }),
          description: new FormControl<string>('', { nonNullable: true }),
          pass: new FormControl<boolean>(false, { nonNullable: true }),
          neutral: new FormControl<boolean>(false, { nonNullable: true }),
          fail: new FormControl<boolean>(false, { nonNullable: true })
        })
      ]),
      documents: this.formBuilder.array([
        this.formBuilder.group<DEReasonDocumentFormGroup>({
          id: new FormControl<number>(0, { nonNullable: true }),
          selected: new FormControl<boolean>(false, { nonNullable: true }),
          document: new FormControl<string>('', { nonNullable: true }),
          description: new FormControl<string>('', { nonNullable: true }),
          disclosure: new FormControl<string>('', { nonNullable: true }),
          pass: new FormControl<boolean>(false, { nonNullable: true }),
          neutral: new FormControl<boolean>(false, { nonNullable: true }),
          fail: new FormControl<boolean>(false, { nonNullable: true })
        })
      ])
    });

    //  Remove array entries.
    this.reasonForm.controls.verifications.removeAt(0);
    this.reasonForm.controls.documents.removeAt(0);

    this.lookups.documents.forEach(doc => {
      this.reasonForm.controls.documents.push(
        this.formBuilder.group<DEReasonDocumentFormGroup>({
          id: new FormControl<number>(doc.documentID, { nonNullable: true }),
          selected: new FormControl<boolean>(false, { nonNullable: true }),
          document: new FormControl<string>(doc.document, { nonNullable: true }),
          description: new FormControl<string>(doc.documentDescription ?? '', { nonNullable: true }),
          disclosure: new FormControl<string>(doc.noaaDisclosure ?? '', { nonNullable: true }),
          pass: new FormControl<boolean>(doc.passShow, { nonNullable: true }),
          neutral: new FormControl<boolean>(doc.neutralShow, { nonNullable: true }),
          fail: new FormControl<boolean>(doc.failShow, { nonNullable: true })
        })
      )
    });

    this.lookups.verifications.forEach(ver => {
      this.reasonForm.controls.verifications.push(
        this.formBuilder.group<DEReasonVerificationFormGroup>({
          id: new FormControl<number>(ver.verificationID, { nonNullable: true }),
          verification: new FormControl<string>(ver.verification, { nonNullable: true }),
          selected: new FormControl<boolean>(false, { nonNullable: true }),
          description: new FormControl<string>(ver.verificationDescription ?? '', { nonNullable: true }),
          pass: new FormControl<boolean>(ver.passShow, { nonNullable: true }),
          neutral: new FormControl<boolean>(ver.neutralShow, { nonNullable: true }),
          fail: new FormControl<boolean>(ver.failShow, { nonNullable: true })
        })
      )
    });
    this.reasonFormLoaded = true;
  }

  decisionTypeSelected(decisionTypeId: number) {
    switch (decisionTypeId) {
      case 1:     //  NOAA
        this.reasonForm.controls.appStatus.setValidators([Validators.required]);
        this.reasonForm.controls.disclosure.setValidators([Validators.required]);
        this.txtDisclosurePlaceholder = 'NOAA Disclosure';
        this.showDisclosure = true;
        this.showNOAACheckbox = true;
        this.showVerifications = false;
        this.showDocuments = false;
        break;
      case 3:     //  FACTA
        this.reasonForm.controls.appStatus.setValidators([Validators.required]);
        this.reasonForm.controls.disclosure.setValidators([Validators.required]);
        this.txtDisclosurePlaceholder = 'Overview for CSR';
        this.showDisclosure = true;
        this.showNOAACheckbox = false;
        this.showVerifications = true;
        this.showDocuments = false;

        break;
      case 5:     //  RFAI
        this.reasonForm.controls.appStatus.setValidators([Validators.required]);
        this.reasonForm.controls.disclosure.setValidators([Validators.required]);
        this.txtDisclosurePlaceholder = 'Overview for CSR';
        this.showDisclosure = true;
        this.showNOAACheckbox = false;
        this.showVerifications = false;
        this.showDocuments = true;

        break;
      case 6:     //  VERIF
        this.reasonForm.controls.appStatus.setValidators([Validators.required]);
        this.reasonForm.controls.disclosure.setValidators([Validators.required]);
        this.txtDisclosurePlaceholder = 'Overview for CSR';
        this.showDisclosure = true;
        this.showNOAACheckbox = false;
        this.showVerifications = true;
        this.showDocuments = false;

        break;
      case 7:     //  TRACK
        this.reasonForm.controls.appStatus.setValidators([Validators.required]);
        this.reasonForm.controls.disclosure.setValidators([Validators.required]);
        this.txtDisclosurePlaceholder = 'Tracking Notes';
        this.showDisclosure = true;
        this.showNOAACheckbox = false;
        this.showVerifications = false;
        this.showDocuments = false;

        break;
      case 10:     //  Segment
        this.txtDisclosurePlaceholder = '';
        this.reasonForm.controls.appStatus.clearValidators();
        this.reasonForm.controls.appStatus.reset();
        this.reasonForm.controls.disclosure.clearValidators();
        this.reasonForm.controls.disclosure.reset();
        this.showDisclosure = false;
        this.showNOAACheckbox = false;
        this.showVerifications = false;
        this.showDocuments = false;
        break;
      case 12:     //  No Offer
        this.txtDisclosurePlaceholder = 'Disclosure';
        this.showDisclosure = true;
        this.reasonForm.controls.appStatus.setValidators([Validators.required]);
        this.reasonForm.controls.disclosure.setValidators([Validators.required]);
        this.showNOAACheckbox = false;
        this.showVerifications = false;
        this.showDocuments = false;

        break;
      default:
        break;
    }

    this.reasonForm.updateValueAndValidity();
  }

  // testFormValidity() {
  //   this.testValidators(this.reasonForm);
  //   console.log('Test Form Validity complete');
  // }

  // testValidators(formGroup: FormGroup| FormArray): void {
  //   Object.keys(formGroup.controls).forEach(key => {
  //     const control = formGroup.get(key) as FormControl | FormGroup | FormArray;
  //     if(control instanceof FormControl) {
  //       if (control.errors) {
  //         for (const err in control.errors) {
  //           console.log(`${key} has error: ${err}`);
  //         }
  //       }
  //     } else if(control instanceof FormGroup || control instanceof FormArray) {        
  //       this.testValidators(control);
  //     } else {
  //       console.log("ignoring control: ", key)
  //     }
  //   });
  // }


  saveReason() {
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      reasonGuid: this.selectedReason ? this.selectedReason.RuleReasonGUID : uuidv4(),
      name: this.reasonForm.value.name,
      decisionTypeId: this.reasonForm.value.decisionType,
      appStatusId: this.reasonForm.value.appStatus,
      customDescription: this.reasonForm.value.disclosure ?? '',
      ignoreNOAADisclosure: this.reasonForm.value.ignoreRFAIDocs ?? false,
      documents: this.buildDocumentsXML(),
      verifications: this.buildVerificationsXML()
    };

    let postSub = this.apiService.post(`decision/reason`, body)
      .subscribe({
        next: () => {
          if (this.queryParams.length > 0) {
            this.goToQueryParamPage();
          }
          else {
            this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
            if (this.cgRuleReas) this.cgRuleReas.getDataInfo();
            else {
              setTimeout(() => {
                if (this.cgRuleReas) this.cgRuleReas.getDataInfo();
              }, 100);
            }
            this.toastService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Rule Reason successfully added/updated.'
            });
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to add/edit Rule Reason. See log for details.'
          });
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  buildVerificationsXML(): string {
    if (this.reasonForm.value.decisionType != 3 && this.reasonForm.value.decisionType != 6) {
      return '';
    }

    if (!this.reasonForm.value.verifications || this.reasonForm.value.verifications.filter(v => v.selected).length == 0) {
      return '';
    }

    let doc = document.implementation.createDocument('', 'verifications');
    let verifications = doc.querySelector('verifications');
    this.reasonForm.value.verifications.forEach(ver => {
      if (ver.selected) {
        let childNode = doc.createElement('v');
        childNode.setAttribute('id', (ver.id ?? '').toString());
        childNode.setAttribute('passShow', ver.pass ? 'true' : 'false');
        childNode.setAttribute('neutralShow', ver.neutral ? 'true' : 'false');
        childNode.setAttribute('failShow', ver.fail ? 'true' : 'false');
        childNode.innerHTML = ver.description ?? '';
        verifications?.appendChild(childNode);
      }
    });

    const serializer = new XMLSerializer();
    return serializer.serializeToString(doc);
  }

  buildDocumentsXML(): string {
    if (this.reasonForm.value.decisionType != 5) {
      return '';
    }
    if (!this.reasonForm.value.documents || this.reasonForm.value.documents.filter(d => d.selected).length == 0) {
      return '';
    }

    let docXML = document.implementation.createDocument('', 'documents');
    let documents = docXML.querySelector('documents');
    this.reasonForm.value.documents.forEach(doc => {
      if (doc.selected) {
        let childNode = docXML.createElement('d');
        childNode.setAttribute('id', (doc.id ?? '').toString());
        childNode.setAttribute('noaad', doc.disclosure ?? '');
        childNode.setAttribute('passShow', doc.pass ? 'true' : 'false');
        childNode.setAttribute('neutralShow', doc.neutral ? 'true' : 'false');
        childNode.setAttribute('failShow', doc.fail ? 'true' : 'false');
        childNode.innerHTML = doc.description ?? '';
        documents?.appendChild(childNode);
      }
    });

    const serializer = new XMLSerializer();
    return serializer.serializeToString(docXML);
  }

  cancelReason() {
    if (this.queryParams.length > 0) {
      this.goToQueryParamPage();
    }
    else {
      this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
    }
  }

  goToQueryParamPage() {
    let pageAndParams = this.queryParams[this.queryParams.length-1];
    this.router.navigate([pageAndParams.route], {
      queryParams: {
        data: btoa(JSON.stringify(this.queryParams))
      }
    });

  }

}
