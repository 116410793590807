
<div class="card">
    <div class="card-body d-flex" >
        <div class="me-auto p-2">
            <h3>Locations</h3>
        </div>
        <div class="p-2 bd-highlight">
            <button class="btn btn-primary btn-main btn-sm" type="button" (click)="openDialog()">
                <fa-icon [icon]="iconPlus" [classes]="['me-2']"></fa-icon>
                Add New</button>
        </div>
    </div>
</div>
