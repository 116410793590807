<div elemClickOutside (clickOutside)="toggleMenus('')">
    <div class="admin-header relative flex flex-row align-items-center">
        <div class="w-1 ml-5 flex">
            <div (click)="goToHome();" class="cursor-pointer">
                <img [src]="headerIcon" alt="Logo">  
                <!--    ALTERNATE METHOD (use default icon if original not found) 
                    <img [src]="headerIcon" (error)="logo.src = 'assets/images/' + defaultIcon" #logo> 
                -->
            </div>
        </div>
        <div class="w-7 flex flex-row">
            <div class="mr-5">
                <div (click)="toggleMenus('create');" class="text-3xl relative cursor-pointer">Create New</div>
                <div *ngIf="createDD" class="nav-menu-container create animate__animated animate__bounceInDown" (click)="underConstruction()">Add User</div>
            </div>
            <div>
                <div *ngIf="showCampEdit" (click)="toggleMenus('campaign');" class="text-3xl relative cursor-pointer">Campaign Edit</div>
                <div *ngIf="campaignDD" class="nav-menu-container campaign animate__animated animate__bounceInDown">
                    <div class="campaign-menu-item" (click)="gotoCampaignPage('settings')">Settings</div>
                    <div class="campaign-menu-item" (click)="gotoCampaignPage('versions')">Versions</div>
                    <div class="campaign-menu-item" (click)="gotoCampaignPage('pages')">Pages</div>
                    <div class="campaign-menu-item" (click)="gotoCampaignPage('alerts')">Alerts</div>
                    <div class="campaign-menu-item" (click)="gotoCampaignPage('notifications')">Notifications</div>
                    <div class="campaign-menu-item" (click)="gotoCampaignPage('seed')" *ngIf="hasCampSeedAccess">Seeds</div>
                </div>
            </div>
            <div>
                <div *ngIf="portfolio" (click)="toggleMenus('provider')" class="ml-8 relative cursor-pointer" [title]="portfolio.customerGuid">{{portfolio.name}}</div>
                <div *ngIf="!portfolio" (click)="toggleMenus('provider')" class="ml-8 relative cursor-pointer">Select Customer</div>
                <div *ngIf="providerDD" class="nav-menu-container provider animate__animated animate__bounceInDown"><app-provider-menu></app-provider-menu></div>
            </div>
        </div>
        <div class="w-4 flex flex-row-reverse mr-7">
            <div class="cursor-pointer relative">
                <div (click)="toggleMenus('profile');">
                    <i class="pi pi-user text-primary text-3xl"></i>
                </div>
                <div *ngIf="profileDD" class="nav-menu-container admin animate__animated animate__bounceInDown">
                    <app-profile-menu (itemClicked)="profileDD = false;"></app-profile-menu>
                </div>
            </div>
        </div>
    </div>
</div>