import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { Campaign } from 'src/app/models/campaign';
import { CampaignSettings } from 'src/app/models/campaign-settings';
import { PortfolioItem } from 'src/app/models/customer-item';
import { NumValDropDown } from 'src/app/models/dropdown';
import { CampaignSettingsFormGroup, TextValueFormGroup } from 'src/app/models/form-groups';
import { ApiService } from 'src/app/services/api.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-campaign-settings',
  templateUrl: './campaign-settings.component.html',
  styleUrls: ['./campaign-settings.component.scss']
})
export class CampaignSettingsComponent implements OnInit, OnDestroy {
  hasEditAccess: boolean = false;
  editPageId: number = 45;
  bcItems: MenuItem[] = [];
  subscriptions: Subscription[] = [];
  listTypes: NumValDropDown[] = [];
  customers: any[] = [];
  templateOptions: NumValDropDown[] = [];
  
  campaignGuid = '';
  loading = false;
  guidsLoaded: boolean = true;
  settingsFormLoaded: boolean = false;
  settingsForm: FormGroup<CampaignSettingsFormGroup> = {} as FormGroup;
  tplFormLoaded: boolean = false;
  tplForm: FormGroup<TextValueFormGroup> = {} as FormGroup;
  saving = false;
  showTemplateOption = false;
  portfolio: PortfolioItem | null = null;
  campaign: Campaign | null = null;
  campaignSettings: CampaignSettings | null = null;

  private parser = new DOMParser();
  constructor(private api: ApiService,
    private router: Router,
    private portfolioService: PortfolioService,
    private toast: ToastService,
    private campaignService: CampaignService,
    private userService: UserService,
    private navService: NavService
    ) {
    this.loading = true;
    this.bcItems = [
      { label: 'Campaigns', routerLink: ['/campaign'], command: () => { this.navService.setLeftNavSelection('Campaign'); } },
      { label: 'Campaign Settings' }
    ];
  }

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([this.portfolioService.portfolio$, this.campaignService.selectedCampaign$])
        .subscribe({
          next: ([p, c]) => {            
            if (this.portfolio && p && this.portfolio.customerId != p.customerId) {
              this.router.navigate(['/campaign']);
            }
            this.portfolio = p;

            if (this.campaign && c && this.campaign.campaignId != c.campaignId) {
              this.router.navigate(['/campaign']);
            }
            this.campaign = c;

            if (p && c) {
              this.campaignGuid = c.campaignGuid;  
              this.getLookups();
              this.guidsLoaded = true; 
            }
            else {
              setTimeout(() => {                
                if (!this.portfolio || !this.campaign) {
                  this.loading = false;
                  this.guidsLoaded = false;
                }              
              }, 500);
            }
          }
        })
    );
    this.hasEditAccess = this.userService.hasPageAccess(this.editPageId);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  initSettingsForm(c: CampaignSettings | null) {
    this.settingsForm = new FormGroup<CampaignSettingsFormGroup>({
      customerId: new FormControl<number>(c?.customerID || this.portfolio?.customerId || 0, { nonNullable: true }),
      campaignId: new FormControl<number>(c?.campaignID ?? 0, { nonNullable: true }),
      campaignGuid: new FormControl<string>(this.campaignGuid, { nonNullable: true }),
      sourceCampaignGuid: new FormControl<string|null>(this.campaignGuid, { nonNullable: true }),
      campaignName: new FormControl<string>(c?.campaignName ?? '', {nonNullable: true}),
      customerGuid: new FormControl<string|null>(c?.customerGUID ?? null, { nonNullable: true , validators: [Validators.required]}),
      listTypeId: new FormControl<number|null>(c?.listTypeID ?? null, { nonNullable: true , validators: [Validators.required]}),
      domain: new FormControl<string>(c?.domain ?? '', { nonNullable: true , validators: [Validators.required]}),
      startUrl: new FormControl<string>(c?.startURL ?? '', { nonNullable: true }),
      startDate: new FormControl<Date|null>(c?.startDate ? new Date(c.startDate) : null, { nonNullable: true }),
      endDate: new FormControl<Date|null>(c?.endDate ? new Date(c.startDate) : null, { nonNullable: true }),
      clarityXmlControlFileName: new FormControl<string>(c?.clarityXmlControlFileName ?? '', {nonNullable: true}),
      clarityXmlControlFileVersionNumber: new FormControl<string>(c?.clarityXmlControlFileVersionNumber ?? '', {nonNullable: true}),
      campaignFolder: new FormControl<string>(c?.campaignFolder ?? '', { nonNullable: true }),
      templatePrefixOptions: new FormControl<string>(c?.templatePrefixOptions ?? '', { nonNullable: true }),
    });

    if (c && c.templatePrefixOptions && c.templatePrefixOptions.length > 5) {
      this.setTemplatePrefixOpts(c.templatePrefixOptions);
    }

    this.settingsFormLoaded = true;
  }

  setTemplatePrefixOpts(prefixOpts: string) {
    prefixOpts = `<template>${prefixOpts}</template>`;
    let doc: XMLDocument = this.parser.parseFromString(prefixOpts, 'application/xhtml+xml');
    
    doc.querySelectorAll('option').forEach(opt => {
      if (opt) {
        let tOpt: NumValDropDown = {
          text: opt.attributes.getNamedItem('prefix')?.value ?? '',
          value: +(opt.attributes.getNamedItem('weight')?.value ?? 0)
        };
        this.templateOptions.push(tOpt);
      }
    });
  }

  createTemplateOptionsXML(): string {
    let retOpts:string = '';
    if (this.templateOptions.length > 0) {
      let doc = document.implementation.createDocument('', 'template');
      let template = doc.querySelector('template');
      this.templateOptions.forEach(opt => {
        let childNode = doc.createElement('option');
        childNode.setAttribute('prefix',  opt.text);
        childNode.setAttribute('weight', opt.value.toString());
        template?.appendChild(childNode);
      });

      const serializer = new XMLSerializer();      
      retOpts = serializer.serializeToString(doc);
      retOpts = retOpts.replace('<template>', '').replace('</template>', '');
    }
    return retOpts;
  }

  initTplForm(option: NumValDropDown | null) {
    this.tplForm = new FormGroup<TextValueFormGroup>({
      text: new FormControl<string>(option?.text ?? '', { nonNullable: true, validators: [Validators.required] }),
      value: new FormControl<number|null>(option?.value ?? null, { nonNullable: true, validators: [Validators.required] })
    });
    this.tplFormLoaded = true;
    this.showTemplateOption = true;
  }

  getLookups() {    
    forkJoin({
      listTypes: this.api.get(`campaign/get-lookups/${this.portfolio?.customerGuid}`),
      customers: this.api.get(`PartnerIntegration/select-customers/${this.portfolio?.customerGuid}`),
      settings: this.api.get(`Campaign/get-details/${this.campaign?.campaignGuid}`)
    })
    .subscribe(data => {
      if (data.listTypes.listTypes.length) {
        this.listTypes = data.listTypes.listTypes.map((itm: any) => { return { text: itm.desc, value: +itm.id}});
      }
      else this.listTypes = [];
      
      this.customers = data.customers || [];
      this.campaignSettings = data.settings;
      this.initSettingsForm(this.campaignSettings);
      this.loading = false;
    }, err => {
      this.toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Unable to get page lookups. See log for details.'
      }, 'center');
      console.error(err);
    });
  }

  addTempOption(){
    this.initTplForm(null);
  }

  editTempOption(i: number) {
    let temp = this.templateOptions[i];
    this.initTplForm(temp);
  }

  deleteTempOption(i: number) {
    this.templateOptions.splice(i, 1);
  }
  
  saveTempOption(){
    this.templateOptions.push({
      text: this.tplForm.value.text ?? '',
      value: this.tplForm.value.value ?? 0
    });
    this.showTemplateOption = false;
  }

  save(){
    if (this.saving) return;

    if (!this.settingsForm.valid) {
      this.settingsForm.markAsTouched();
      this.settingsForm.markAllAsTouched();
      this.toast.add({ severity: 'error', summary: 'Error', detail: 'Please emter all the required fields.' });
      return;
    }

    this.settingsForm.value.templatePrefixOptions = this.createTemplateOptionsXML();
    var path = `Campaign/post`;
    this.saving = true;
    this.api.post(path, this.settingsForm.value).subscribe(data => {
      this.saving = false;
      if (data) {
        this.toast.add({ severity: 'success', summary: 'Success', detail: `Campaign successfully saved!` });
        this.router.navigate([`/campaign`]);
      } else {
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'ERROR saving campaign, please contact admin!' });
      }
    },
      (err: any) => {
        console.error(err);
        this.saving = false;
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'ERROR saving campaign, API has error response, please contact admin!' });
      });
  }

  cancel(){
    this.router.navigate([`/campaign`]);
  }

}
