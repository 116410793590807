<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <p3solved-progress-bar [style]="{'height': '0.5rem'}"></p3solved-progress-bar>
</div>

<div *ngIf="!loading && hasError" class="text-5xl font-medium text-red-500">Unable to load RuleSets.
</div>

<div *ngIf="!loading && !hasError && showRuleSets">
    <span class="text-2xl font-medium">Decision Engine RuleSets</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div class="col-12 pt-4">
                    <p-table #dt [value]="ruleSets" styleClass="p-datatable-lg col-12" [paginator]="true"
                        [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="first" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        responsiveLayout="scroll" [rows]="100" [rowHover]="true">
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <div class="flex justify-content-start">
                                    <span class="text-2xl font-medium">RuleSets</span>
                                </div>
                                <div class="flex flex-grow-1 justify-content-end">
                                    <p3solved-button *ngIf="hasTestFeature" id="btnTestRS" label="Test RuleSet" class="px-4"
                                        (buttonClick)="testRuleSet()">
                                    </p3solved-button>
                                    <p3solved-button *ngIf="hasEditFeature" id="btnAddRS" label="Add RuleSet" (buttonClick)="addRuleSet()">
                                    </p3solved-button>
                                </div>
                            </div>
                            <div class="flex flex-grow-1 justify-content-end pt-4">
                                <p3solved-dropdown id="ddRSCat" placeholder="Category" class="px-4 w-2"
                                    [items]="categories" optionLabel="ruleSetCategoryDescription"
                                    [(ngModel)]="selectedCategory" (optionSelected)="categorySelected($event)">
                                </p3solved-dropdown>
                                <p3solved-dropdown id="ddRSStat" placeholder="Status" class="w-2" [items]="statOptions"
                                    optionLabel="desc" [(ngModel)]="selectedStatus"
                                    (optionSelected)="statusSelected($event)"></p3solved-dropdown>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr scope="row">
                                <th>Campaign</th>
                                <th>Offer Matrix</th>
                                <th>Create Date</th>
                                <th>Activation Date</th>
                                <th>Create User</th>
                                <th>RuleSet</th>
                                <th>%</th>
                                <th>Status</th>
                                <th *ngIf="hasEditFeature"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rs>
                            <tr [style.background-color]="getRowStyle(rs)">
                                <td>{{ rs.campaignName }}</td>
                                <td>{{ rs.offerMatrixName }}</td>
                                <td>{{ rs.createDate | date: 'MM/dd/yyyy hh:mm:ss a' }}</td>
                                <td>{{ rs.activeDate | date: 'MM/dd/yyyy hh:mm:ss a' }}</td>
                                <td>{{ rs.createFirstName }} {{ rs.createLastName }}</td>
                                <td>
                                    <span *ngIf="hasEditFeature" class="cds-text-color cursor-pointer" (click)="ruleSetSelected(rs)">
                                        {{ rs.rulesetName }} v{{ rs.rulesetVersion }}
                                    </span>
                                    <span *ngIf="!hasEditFeature">
                                        {{ rs.rulesetName }} v{{ rs.rulesetVersion }}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        *ngIf="hasEditFeature && rs.rulesetStatus && rs.rulesetStatus.toLowerCase() == 'live'; else statusNotLive"
                                        class="cursor-pointer cds-text-color" (click)="updateRSWeight(rs)">
                                        {{ rs.rulesetWeight }}
                                    </span>
                                    <ng-template #statusNotLive>
                                        {{ rs.rulesetWeight }}
                                    </ng-template>
                                </td>
                                <td>
                                    <p-badge class="cursor-pointer" *ngIf="hasEditFeature && rs.rulesetStatus; else inactiveBadge"
                                        [severity]="getRuleSetBadge(rs)" (click)="updateRSStatus(rs)"></p-badge>
                                    <ng-template #inactiveBadge>
                                        <p-badge [severity]="getRuleSetBadge(rs)"></p-badge>
                                    </ng-template>
                                </td>
                                <td *ngIf="hasEditFeature">
                                    <p class="m-0 p-0">
                                        <i class="pi pi-pencil text-primary cursor-pointer mr-4" title="Edit" aria-hidden="true"
                                            (click)="editRuleSet(rs)"></i>
                                        <i class="pi pi-copy text-primary cursor-pointer mr-4" title="Copy" aria-hidden="true"
                                            (click)="confirmCopyRuleSet(rs)"></i>
                                        <i class="pi pi-trash text-primary cursor-pointer" title="Delete" aria-hidden="true"
                                            (click)="confirmDeleteRS(rs)"></i>
                                    </p>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [colSpan]="hasEditFeature ? 9 : 8">No RuleSets found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="col-12 flex flex-grow-1 justify-content-end">
                        <span class="pr-4">
                            <p-badge severity="success"></p-badge> Live
                        </span>
                        <span class="pr-4">
                            <p-badge severity="warning"></p-badge> Design
                        </span>
                        <span>
                            <p-badge severity="danger"></p-badge> Closed
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loading && !hasError && showRuleSetRules">
    <span class="text-2xl font-medium">Decision Engine RuleSet</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div class="col-12 flex">
                    <div class="w-10 flex flex-wrap">
                        <div class="w-1 font-bold">
                            RuleSet:
                        </div>
                        <div class="w-11">
                            {{ portfolio?.name }} / {{ selectedRuleSet.campaignName }} / {{ selectedRuleSet.rulesetName
                            }} v{{ selectedRuleSet.rulesetVersion }}
                        </div>
                        <div class="w-1 font-bold">
                            %:
                        </div>
                        <div class="w-11">
                            {{ selectedRuleSet.rulesetWeight }}%
                        </div>
                        <div class="w-1 font-bold">
                            Status:
                        </div>
                        <div class="w-11">
                            {{ selectedRuleSet.rulesetStatus }}
                        </div>
                    </div>
                    <div *ngIf="hasEditFeature" class="w-2 flex justify-content-end align-self-center">
                        <p3solved-button id="btnEditRS" label="Edit" (buttonClick)="editRuleSet(selectedRuleSet)"
                            [disabled]="editMode"></p3solved-button>
                    </div>
                </div>
                <div class="col-12 pt-4" *ngIf="!editMode">
                    <p-table #dt [value]="ruleSetRules" styleClass="p-datatable-lg col-12" [paginator]="true"
                        [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="rulesFirst" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        responsiveLayout="scroll" [rows]="100" [rowHover]="true">
                        <ng-template pTemplate="caption">
                            <div class="flex justify-content-start">
                                <span class="text-2xl font-medium">Rules</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr scope="row">
                                <th>Order</th>
                                <th>Status</th>
                                <th>Name</th>
                                <th>Category</th>
                                <th>If Meets</th>
                                <th>Action</th>
                                <th>If Does Not Meet</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rule let-index="rowIndex">
                            <tr [style.background-color]="getRuleRowBgStyle(rule)"
                                [style.color]="getRuleRowColorStyle(rule)">
                                <td [style.color]="getRuleOrderNoColor(rule)">{{ (index + 1) }}</td>
                                <td>{{ rule.ruleActive ? 'Live' : 'Design' }}</td>
                                <td>
                                    <span class="cds-text-color cursor-pointer" (click)="goToRulePg(rule)">
                                        {{ rule.ruleName }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="!rule.isBreakRule">{{ rule.ruleCategoryName }}</span>
                                </td>
                                <td>
                                    <span *ngIf="!rule.isBreakRule">{{ rule.failAction }}</span>
                                </td>
                                <td>
                                    <span *ngIf="!rule.isBreakRule">{{ rule.decisionType }}</span>
                                </td>
                                <td>
                                    <span *ngIf="!rule.isBreakRule">{{ rule.successAction }}</span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [colSpan]="9">No RuleSets found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="col-12 pt-4" *ngIf="editMode">
                    <p-table #dt [value]="ruleSetRules" styleClass="p-datatable-lg col-12" [paginator]="true"
                        [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="rulesFirst" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        responsiveLayout="scroll" [rows]="100" [rowHover]="true"
                        (onRowReorder)="editRuleReorder($event)">
                        <ng-template pTemplate="caption">
                            <div class="flex justify-content-start">
                                <span class="text-2xl font-medium">Rules</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr scope="row">
                                <th>Order</th>
                                <th>Status</th>
                                <th>Name</th>
                                <th>Category</th>
                                <th>If Meets</th>
                                <th>Action</th>
                                <th>If Does Not Meet</th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rule let-index="rowIndex">
                            <tr [pReorderableRow]="index" [style.background-color]="getRuleRowBgStyle(rule)"
                                [style.color]="getRuleRowColorStyle(rule)">
                                <td [style.color]="getRuleOrderNoColor(rule)" pReorderableRowHandle>{{ (index + 1) }}
                                </td>
                                <td>{{ rule.ruleActive ? 'Live' : 'Design' }}</td>
                                <td>
                                    <span class="cds-text-color cursor-pointer" (click)="goToRulePg(rule)">
                                        {{ rule.ruleName }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="!rule.isBreakRule">{{ rule.ruleCategoryName }}</span>
                                </td>
                                <td>
                                    <span *ngIf="!rule.isBreakRule">{{ rule.failAction }}</span>
                                </td>
                                <td>
                                    <span *ngIf="!rule.isBreakRule">{{ rule.decisionType }}</span>
                                </td>
                                <td>
                                    <span *ngIf="!rule.isBreakRule">{{ rule.successAction }}</span>
                                </td>
                                <td><i class="pi pi-trash text-primary cursor-pointer" (click)="confirmdeleteRuleSetRule(rule)"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [colSpan]="9">No RuleSets found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div *ngIf="editMode" class="col-12 py-4">
                    <div class="flex justify-content-center">
                        <div class="px-2"><span class="cds-text-color cursor-pointer"
                                (click)="showAddRulesInventory()">Add Rules From Inventory</span></div>
                        <div class="px-2"><span class="cds-text-color cursor-pointer" (click)="addNewRule()">Add New
                                Rule</span></div>
                    </div>
                    <div class="flex flex-row-reverse">
                        <p3solved-button id="btnSaveEditRS" label="Save" (buttonClick)="saveEditRS()"></p3solved-button>
                        <p3solved-button id="btnCancelEditRS" class="outline px-4" label="Cancel"
                            (buttonClick)="cancelEditRS()"></p3solved-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="cds-card p-4">
            <div class="grid">
                <div class="col-12 pt-4 grid" *ngIf="providerInfo && providerInfo.showOfferMatrix != undefined">
                    <div class="col-1 text-xl font-bold">{{ matrixType }} Matrix</div>
                    <div class="col-11 text-right" *ngIf="providerInfo && !providerInfo.showOfferMatrix">
                        <p3solved-button id="btnAddMatrix" label="Add {{ matrixType }} Matrix"
                            (buttonClick)="addMatrix()"></p3solved-button>
                    </div>
                </div>
                <div class="col-6" *ngIf="providerInfo && providerInfo.showOfferMatrix != undefined">
                    <p3solved-dropdown id="ddMatrix" [items]="matrices" [(ngModel)]="selectedMatrix"
                        placeholder="Default {{ matrixType }} Matrix" optionLabel="desc"
                        (optionSelected)="matrixOptionChanged($event)"></p3solved-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>

<p3solved-dialog [isVisible]="showTestRuleSet" header="Test RuleSet" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showTestRuleSet=false;showTestRSResult=false;" [style]="{'width': '25vw'}">
    <div *ngIf="!showTestRSResult" body>
        <form *ngIf="testRSFormLoaded" [formGroup]="testRSForm">
            <div class="grid">
                <div class="col-12">
                    <p3solved-dropdown id="ddTestRSSeed" placeholder="Seed" [required]="true" appendTo="body"
                        [items]="customerSeeds" optionLabel="desc" optionValue="id" formControlName="seed">
                    </p3solved-dropdown>
                </div>
                <div class="col-12">
                    <p3solved-dropdown id="ddTestRSRuleSet" placeholder="RuleSet" [required]="true" appendTo="body"
                        [items]="testRuleSets" optionLabel="desc" optionValue="id" formControlName="ruleSet">
                    </p3solved-dropdown>
                </div>
                <div class="col-12">
                    <p3solved-dropdown id="ddTestRSData" placeholder="Testing Data" [required]="true" appendTo="body"
                        [items]="testRuleData" optionLabel="text" optionValue="value" formControlName="data">
                    </p3solved-dropdown>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="!showTestRSResult" footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnProcessTSTest" label="Process" (buttonClick)="processRuleSetTest();"
                [disabled]="!testRSForm.valid"></p3solved-button>
        </div>
    </div>

    <div *ngIf="showTestRSResult" body>
        <form *ngIf="testRSFormLoaded" [formGroup]="testRSForm">
            <div class="grid">
                <div class="col-12">
                    Seed: <strong>{{testRSForm.value.seed}}</strong>
                </div>
                <div class="col-12">
                    RuleSet: <strong>{{testRSForm.value.ruleSet}}</strong>
                </div>
                <div class="col-12">
                    Procedure: <strong>{{getTestDataName(testRSForm.value.data)}}</strong>
                </div>
                <div class="col-12 pt-4 grid">
                    <div class="col-12 border-1">
                        {{ruleSetTestDataResponse}}
                    </div>
                </div>                
            </div>
        </form>
    </div>
    <div *ngIf="showTestRSResult" footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnCloseRSTest" label="Cloes" class="outline px-4" (buttonClick)="showTestRuleSet=false;showTestRSResult=false;"></p3solved-button>
        </div>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showAddRuleSet" header="Add RuleSet" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showAddRuleSet=false;" [style]="{'width': '25vw'}">
    <div body>
        <form *ngIf="newRSFormLoaded" [formGroup]="newRSForm">
            <div class="grid">
                <div class="col-offset-1 col-9">
                    <p3solved-form-text id="txtNewName" placeholder="Name" formControlName="name" [required]="true">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="!newRSForm.controls.name.pristine && newRSForm.controls.name.errors">
                            <p *ngIf="newRSForm.controls.name.errors?.required">Name is required</p>
                        </div>
                    </p3solved-form-text>
                </div>
                <div class="col-1 rs-new-bg">
                    <span>v1</span>
                </div>
                <div class="col-offset-1 col-10">
                    <p3solved-dropdown id="ddNewRSCamp" placeholder="Campaign" [required]="true" appendTo="body"
                        [items]="campaigns" optionLabel="desc" optionValue="id" formControlName="campaignGuid">
                    </p3solved-dropdown>
                </div>
                <div class="col-offset-1 col-10">
                    <p3solved-dropdown id="ddNewRSType" placeholder="Type" appendTo="body" [items]="types"
                        formControlName="type" optionLabel="ruleSetTypeDescription" optionValue="ruleSetTypeID">
                    </p3solved-dropdown>
                </div>
                <div class="col-offset-1 col-10">
                    <p3solved-dropdown id="ddNewRSCat" placeholder="Category" appendTo="body" [items]="categories"
                        formControlName="category" optionLabel="ruleSetCategoryDescription"
                        optionValue="ruleSetCategoryID">
                    </p3solved-dropdown>
                </div>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnSaveNewRS" label="Add" (buttonClick)="saveNewRuleSet();"
                [disabled]="!newRSForm.valid"></p3solved-button>
            <p3solved-button id="bthCloseNewRS" label="Cancel" class="outline px-4"
                (buttonClick)="showAddRuleSet=false;">
            </p3solved-button>
        </div>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showWeightUpdate" header="Update RuleSet Percentages" headerClass="text-2xl font-bold pl-4"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showWeightUpdate=false;"
    [style]="{'width': '35vw'}">
    <div body class="p-4">
        <p>Do you want to update traffic percentages for the campaign <strong>"{{ selectedRuleSet?.campaignName
                }}"</strong>?</p>
        <p>Please allocate your traffic and confirm you changes. Total must equal 100%.</p>
        <div class="grid pt-4">
            <span class="col-6 font-bold">RuleSet</span>
            <span class="col-6 font-bold">%</span>
        </div>
        <form *ngIf="rsWeightFormLoaded" [formGroup]="rsWeightForm">
            <div formArrayName="ruleSets">
                <div *ngFor="let ruleSet of rsWeightForm.controls.ruleSets.controls index as i">
                    <div class="grid" [formGroupName]="i">
                        <span class="col-6 pt-4 align-self-center">{{ ruleSet.value.name }}</span>
                        <div class="col-6">
                            <p3solved-input-number id="txtWeight{{i}}" formControlName="weight" mode="decimal">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="!ruleSet.controls.weight.pristine && ruleSet.controls.weight.errors">
                                    <p *ngIf="ruleSet.controls.weight.errors?.required">Weight is required</p>
                                    <p
                                        *ngIf="ruleSet.controls.weight.errors?.min || ruleSet.controls.weight.errors?.max">
                                        Weight must be between 0 and 100</p>
                                </div>
                            </p3solved-input-number>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <span class="col-6 font-bold">Total</span>
                <span class="col-6 font-bold">{{ rsWeightForm.value.total }}%</span>
                <span class="col-offset-6 col-6 p-error text-xs"
                    *ngIf="rsWeightForm.controls.total.errors && (rsWeightForm.controls.total.errors?.max || rsWeightForm.controls.total.errors?.min)">
                    Invalid Total! Must equal 100.
                </span>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnSaveWeight" label="Save" (buttonClick)="saveRuleSetWeights();"
                [disabled]="!rsWeightForm.valid"></p3solved-button>
            <p3solved-button id="bthCloseWeight" label="Cancel" class="outline pr-4"
                (buttonClick)="showWeightUpdate=false;">
            </p3solved-button>
        </div>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showStatusUpdate" header="Status Update" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showStatusUpdate=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="grid">
            <div class="col-12">
                Are you sure you want to update the status for Rule Set "{{ selectedRuleSet?.rulesetName }}
                v{{selectedRuleSet?.rulesetVersion }}"
            </div>
            <div class="col-12">
                <p3solved-dropdown id="ddRSUpdStat" placeholder="Status" appendTo="body" [items]="updateStatOptions"
                    optionLabel="desc" [(ngModel)]="selectedStatusUpdate"></p3solved-dropdown>
            </div>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnSaveStatus" label="Confirm" (buttonClick)="saveRuleSetStatus();"
                [disabled]="!selectedStatusUpdate.id"></p3solved-button>
            <p3solved-button id="bthCloseStatus" label="Cancel" class="outline pr-4"
                (buttonClick)="showStatusUpdate=false;">
            </p3solved-button>
        </div>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showAddMatrix" header="Add Line Matrix" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showAddMatrix=false;" [style]="{'width': '25vw'}">
    <div body>
        <form *ngIf="rsLinMatrixFormLoaded" [formGroup]="rsLinMatrixForm">
            <div class="grid">
                <div class="col-12">
                    <p3solved-dropdown id="ddLineMat" placeholder="Line Matrix" [required]="true" [items]="lineMatrices"
                        optionLabel="desc" optionValue="id" formControlName="lineMatrix"></p3solved-dropdown>
                </div>
                <div class="col-12">
                    <p3solved-dropdown id="ddMatRule" placeholder="Rule" [required]="true" [items]="ruleSetRules"
                        optionLabel="ruleName" optionValue="ruleGUID" formControlName="rule"></p3solved-dropdown>
                </div>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnSaveMatrix" label="Save" (buttonClick)="saveRuleSetMatrix();"
                [disabled]="!selectedStatusUpdate.id"></p3solved-button>
            <p3solved-button id="bthCloseStatus" label="Cancel" class="outline pr-4"
                (buttonClick)="showAddMatrix=false;">
            </p3solved-button>
        </div>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showDeletRuleWithGroup" header="Delete Rule" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showDeletRuleWithGroup=false;" [style]="{'width': '25vw'}">
    <div body>
        <p>Are you sure you want to delete the Rule Group <strong>{{deleteRule?.ruleName}}?</strong></p>
        <p class="w-12 pt-4 cds-text-red">NOTE: If you delete this rule group, undo will no longer be available.</p>
        <div class="grid">
            <div class="col-12">
                <p-radioButton name="rblTest" [value]="false"
                    label="Delete only the group, and move the child rules outside it" [(ngModel)]="deleteRuleChildren"
                    class="mr-7">
                </p-radioButton>
                <p-radioButton name="rblTest" [value]="false" label="Delete the group and all its children."
                    [(ngModel)]="deleteRuleChildren" class="mr-7">
                </p-radioButton>
            </div>
        </div>

    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnCrfmDelRule" label="Confirm" (buttonClick)="confirmDeleteRule();"
                [disabled]="!selectedStatusUpdate.id"></p3solved-button>
            <p3solved-button id="bthCnclDelRule" label="Cancel" class="outline px-4"
                (buttonClick)="showDeletRuleWithGroup=false;deleteRule=null;deleteRuleChildren=false;">
            </p3solved-button>
        </div>
    </div>
</p3solved-dialog>

<p3solved-dialog [isVisible]="showAddInventoryRules" header="Add Rules From Inventory" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showAddInventoryRules=false;"
    [style]="{'width': '50vw'}">
    <div body>
        <div class="flex flex-grow-1 justify-content-end pt-4">
            <p3solved-dropdown id="ddAddRleInvCat" class="w-4" [items]="addRuleCats" appendTo="body" optionLabel="text"
                optionValue="value" (optionSelected)="addRuleCategorySelected($event)" [(ngModel)]="addRuleInvCategory">
            </p3solved-dropdown>

            <p3solved-dropdown id="ddAddRleInvFilter" class="px-4 w-4" appendTo="body" [items]="filterAddRules"
                optionLabel="text" optionValue="value" (optionSelected)="addRuleFilterSelected($event)"
                [(ngModel)]="addRuleInvFilter">
            </p3solved-dropdown>
        </div>
        <div class="grid">
            <div class="col-12 pt-4">
                <p-table [value]="filteredInventoryRules" styleClass="p-datatable-lg col-12"
                    [paginator]="true" [(selection)]="selectedAddRules" dataKey="ruleID"
                    [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="filterInvFirst" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    responsiveLayout="scroll" [rows]="100" [rowHover]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="w-3rem checkbox-table-center" scope="col">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Create Date</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rule>
                        <tr>
                            <td class="checkbox-table-center" (click)="$event.stopPropagation();">
                                <p-tableCheckbox [value]="rule"></p-tableCheckbox>
                            </td>
                            <td>{{rule.ruleName}}</td>
                            <td>{{rule.ruleCategoryName}}</td>
                            <td>{{rule.createDate | date: 'MM/dd/yyyy' }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colSpan="4">No Rules found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnSaveRules" label="Save" (buttonClick)="addRulesInventory();"></p3solved-button>
            <p3solved-button id="bthCnclDelRule" label="Cancel" class="outline px-4"
                (buttonClick)="showAddInventoryRules=false;">
            </p3solved-button>
        </div>
    </div>
</p3solved-dialog>

<p-confirmDialog [key]="deRuleSetConfirmKey" [style]="{width: '45vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-outlined" styleClass="text-xl">
</p-confirmDialog>