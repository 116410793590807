import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-cc-input-decimal',
  templateUrl: './cc-input-decimal.component.html',
  styleUrls: ['./cc-input-decimal.component.scss']
})
export class CcInputDecimalComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() max: string = "";
  @Input() min: string = "";
  @Input() id?: string;
  @Input() label: string = "";
  @Input() type: string = "number";
  @Input() placeholder: string = "";
  @Input() val: string = "";
  @Input() errorMessage: string = "";
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  iconRequired = faAsterisk as IconProp;
  constructor() {
    var d = new Date();
    this.id = d.getTime().toString();
  }

  ngOnInit(): void {
  }

}
