
import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
// export const b2cPolicies = {
//     names: {
//         signUpSignIn: "B2C_1_coveredcare_signin",
//         resetPassword: 'B2C_1_coveredcare_password_reset',
//         editProfile: "B2C_1_coveredcare_signin"
//     },
//     authorities: {
//         signUpSignIn: {
//             authority: environment.signInPolicy,
//         },
//         editProfile: {
//             authority: environment.signInPolicy
//         },
//         resetPassword: {
//             authority: "https://devCoveredb2c.b2clogin.com/devCoveredb2c.onmicrosoft.com/B2C_1_coveredcare_password_reset",
//             scopes: environment.scopeUris
//         }
//     },
//     authorityDomain: environment.authorityDomain
// };

export function getMsalConfig(settings: any){
    var msalConfig: Configuration = {
        auth: {
            clientId: settings['AD_AppReg_ClientId'],
            authority: settings['B2C_SignInPolicy'],
            knownAuthorities: [settings['B2C_AuthorityDomain']],
            redirectUri: settings['URL_Admin'], 
        },
       cache: {
           cacheLocation: BrowserCacheLocation.LocalStorage,
           storeAuthStateInCookie: true
       },
       system: {
           loggerOptions: {
               loggerCallback(logLevel: LogLevel, message: string){
                   // console.log(message);
               },
               logLevel: LogLevel.Error,
               piiLoggingEnabled: false
           }
       }
    }

    return msalConfig;
}