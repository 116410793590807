<p-table #dt [value]="offers" styleClass="p-datatable-lg col-12"
[globalFilterFields]="filterFields" [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]"
[(first)]="first" [showCurrentPageReport]="true"
currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
responsiveLayout="scroll" [rows]="100" [rowHover]="true" [columns]="columns">
<ng-template pTemplate="caption">
    <div class="d-flex mb-3">
        <div class="me-auto p-1">
            <p3solved-button id="btnAddNew" label="Add New" class="outlined"
                (buttonClick)="addNew()">
            </p3solved-button>
        </div>
        <div class="p-1">
            <span class="p-input-icon-left">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input #txtSearch type="text" class="w-15rem" pInputText placeholder="Search"
                    (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
            </span>
        </div>
      </div>
</ng-template>
<ng-template pTemplate="header">
    <tr>
        <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
            <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field"
                class="font-medium" scope="col">{{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
        </ng-template>
        <th scope="col"></th>
    </tr>
</ng-template>
<ng-template pTemplate="body" let-rowData>
    <tr>
        <td *ngFor="let c of getColumns()">{{rowData[c.field]}}</td>

        <td>
            <div class="flex">
                <i class="pi pi-pencil text-primary cursor-pointer mr-4" title="Edit" aria-hidden="true" (click)="edit(rowData)"></i>
                <i class="pi pi-trash text-primary cursor-pointer" title="Delete" aria-hidden="true" (click)="delete(rowData)"></i>
            </div>
        </td>
    </tr>
</ng-template>
<ng-template pTemplate="emptymessage">
    <tr>
        <td [colSpan]="7">No Offers found.</td>
    </tr>
</ng-template>
</p-table>

<p-dialog [header]="editModalHeader" [modal]="true" [(visible)]="showEditModal" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <div *ngIf="showEditModal">
        <form [formGroup]="offerForm">
            <div class="m-3">
                <app-cc-select-alt label="Customer" [options]="customers" [group]="offerForm" controlName="customerGuid"></app-cc-select-alt>
            </div>
            <div class="m-3">
                <p3solved-form-text id="" [placeholder]="title + ' Desc'" formControlName="desc">
                </p3solved-form-text>
            </div>
            <div class="m-3" *ngIf="offerType === 'amt'">
                <p3solved-form-text id="" placeholder="Offer Term Amt" formControlName="amt">
                </p3solved-form-text>
            </div>
            <div class="m-3" *ngIf="offerType === 'amt'">
                <p3solved-dropdown id="ddlol" [items]="yesno" optionLabel="desc" placeholder="Is Min Amt" optionValue="id" [showClear]="false" [required]="true"
                formControlName="isMinAmt">
            </p3solved-dropdown>
            </div>
            <div class="m-3" *ngIf="offerType === 'amt'">
                <p3solved-dropdown id="ddlole" [items]="yesno" optionLabel="desc"placeholder="Is Down Amt" optionValue="id" [showClear]="false" [required]="true"
                formControlName="isDownPmt">
            </p3solved-dropdown>
            </div>
            <div class="m-3" *ngIf="offerType !== 'term' && offerType !== 'amt'">
                <p3solved-form-text id="" [placeholder]="getPctLabel()" formControlName="pct">
                </p3solved-form-text>
            </div>
            <div class="m-3" *ngIf="offerType === 'term'">
                <p3solved-dropdown id="ddlol" [items]="yesno" optionLabel="desc"placeholder="Is Same As Cash" optionValue="id" [showClear]="false"
                formControlName="sameAsCash">
            </p3solved-dropdown>
            </div>
        </form>

    </div>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check text-primary" (click)="save()" label="SAVE" class="p-button-text"></p-button>
        <p-button icon="pi pi-times text-primary" (click)="showEditModal=false" label="No"></p-button>
    </ng-template>
</p-dialog>