
  <div [formGroup]="group" class="mb-1">
    <mat-form-field appearance="standard">
        <mat-label>{{label}}
            <fa-icon 
            *ngIf="group && group.controls[controlName]?.errors?.required" 
            [icon]="iconRequired" 
            size="xs" 
            [styles]="{'color': 'red'}" 
            [classes]="['ms-1']">
        </fa-icon>
        </mat-label>
        <mat-select [formControlName]="controlName">
          <mat-option *ngFor="let item of options" [value]="item.id">
            {{item.desc}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </div>
