import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettlementSummary } from 'src/app/models/settlement';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-settlement-report',
  templateUrl: './settlement-report.component.html',
  styleUrls: ['./settlement-report.component.scss']
})
export class SettlementReportComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
    
  }

  goToSettlementPage(type: string) { 
    this.router.navigate([`/report/settlement/${type}`]);
  }

}
