import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { combineLatest, Subscription } from 'rxjs';
import { BranchModel } from 'src/app/models/branch-model';
import { PortfolioItem } from 'src/app/models/customer-item';
import { AddPricingFormGroup, OfferProductFormGroup } from 'src/app/models/form-groups';
import { LookupModel } from 'src/app/models/lookup-model';
import { OfferPricingModel, OfferProductType, ProviderPricingOffer } from 'src/app/models/pricing-offer';
import { ApiService } from 'src/app/services/api.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-provider-pricing',
  templateUrl: './provider-pricing.component.html',
  styleUrls: ['./provider-pricing.component.scss']
})
export class ProviderPricingComponent implements OnInit, OnDestroy {

  bcItems: MenuItem[] = [];
  productTypes: any[] = [];

  providerName: string = '';
  editPriceHeader: string = 'Add New';
  offerProdBtnTxt: string = 'Add'

  showOPTCard: boolean = true;
  showEditOffer: boolean = false;
  showLocationCard: boolean = false;
  showPricingCard: boolean = false;
  showEditPrice: boolean = false;
  showAddBranchOffer: boolean = false;
  pricingFormLoaded: boolean = false;
  offerProductFormLoaded: boolean = false;

  selectedOffer: ProviderPricingOffer | null = null;

  portSub: Subscription | null = null;
  portfolio: PortfolioItem | null = null;
  providerBranches: BranchModel[] = [];

  ddlProvBranches: LookupModel[] = [];
  selectedproviderBranch: LookupModel | null = null;
  ddlOffers: OfferProductType[] = [];
  selectedOfferType: OfferProductType | null = null;
  offerProducts: OfferProductType[] = [];

  addPricingForm: FormGroup<AddPricingFormGroup> = {} as FormGroup;
  offerProductForm: FormGroup<OfferProductFormGroup> = {} as FormGroup;

  selectedOfferProdType: string | null = null;

  constructor(
    private portfolioService: PortfolioService,
    private apiService: ApiService,
    private toastService: ToastService,
    private navService: NavService
  ) { }

  ngOnInit(): void {
    this.bcItems = [
      { label: 'Customer List', routerLink: ['/provider/list'], command: () => { this.navService.setLeftNavSelection('Client.List'); }  },
      { label: 'Manage Pricing' }
    ];

    let prodSub = this.apiService.get('pricing/offer-product')
      .subscribe({
        next: (offerProds: OfferProductType[]) => {
          this.offerProducts = offerProds;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Offer Product Type list. See log for details.'
          });
          console.error(err);
        },
        complete: () => { prodSub.unsubscribe(); }
      });


    this.portSub = this.portfolioService.portfolio$
      .subscribe({
        next: (p: PortfolioItem | null) => {
          this.portfolio = p;
          if (p) {
            this.providerName = p.name;
            this.getCustomerOfferProduct();
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.portSub) this.portSub.unsubscribe();
  }

  getCustomerOfferProduct() {
    let opSub = this.apiService.get(`pricing/offer-product/${this.portfolio?.customerGuid}`)
    .subscribe({
      next: (offerProd: OfferProductType) => { 
        if (offerProd) {
          this.selectedOfferProdType = offerProd.offerProductTypeGuid;
        }
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to retrieve customer Offer Product. See log for details.'
        }, 'center')
      },
      complete: () => { opSub.unsubscribe(); }
    });
  }

  showLocations() {
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showLocationCard = false; this.showPricingCard = false; this.showOPTCard = true; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: 'Manage Location' });
    this.showOPTCard = false;
    this.showPricingCard = false;
    this.showLocationCard = true;

  }

  showPricing() {
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showLocationCard = false; this.showPricingCard = false; this.showOPTCard = true; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: 'Pricing List' });
    this.showOPTCard = false;
    this.showLocationCard = false;
    this.showPricingCard = true;
  }

  offerProdTypeSelected(event: ProviderPricingOffer) {
    this.selectedOffer = event;
    this.showEditOffer = true;
  }

  showAddPricingModal() {
    this.editPriceHeader = 'Add New';
    this.offerProdBtnTxt = 'Add';
    this.setupShowPriceModal();
  }

  pricingSelected(event: OfferPricingModel) {
    this.editPriceHeader = 'Edit';
    this.offerProdBtnTxt = 'Edit';
    this.setupShowPriceModal(event);
  }

  setupShowPriceModal(pricingModel?: OfferPricingModel) {
    this.offerProductForm = new FormGroup<OfferProductFormGroup>({
      productGuid: new FormControl<string>(pricingModel?.OfferProductTypeGUID || '', {nonNullable:true}),
      productDesc: new FormControl<string>(pricingModel?.['Offer Product Type Description'] || '', {nonNullable: true, validators: [Validators.required]})
    });

    this.offerProductFormLoaded = true;
    this.showEditPrice = true;
  }

  showAddLocationModal() {
    this.selectedproviderBranch = null;
    this.selectedOfferType = null;
    let combSub = combineLatest([this.apiService.get(`provider/provider-branches/${this.portfolio?.customerGuid}`),
    this.apiService.get(`provider/${this.portfolio?.customerGuid}/offer-product`)])
      .subscribe({
        next: ([b, o]) => {
          if (b && o) {
            let branches = b as BranchModel[];
            let offers = o as OfferProductType[];
            this.providerBranches = branches;
            this.ddlProvBranches = branches.map(branch =>
              ({ id: branch.branchGUID, desc: `${branch.branchName} (${branch.branch_ID})` }) as LookupModel
            );
            this.ddlOffers = offers;
            this.showAddBranchOffer = true;
            this.addPricingForm = new FormGroup<AddPricingFormGroup>({
              provider: new FormControl<string>(this.providerName, { nonNullable: true }),
              branch: new FormControl<string | null>(null, { nonNullable: true, validators: [Validators.required] }),
              productType: new FormControl<string | null>(null, { nonNullable: true, validators: [Validators.required] })
            });
            this.pricingFormLoaded = true;
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Customer Branches and/or Offer Types. See log for details.'
          });
          console.error(err);
        },
        complete: () => { combSub.unsubscribe(); }
      });
  }

  assignOffer() {
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      productGuid: this.selectedOfferProdType
    };

    let offerSub = this.apiService.post(`pricing/offer-product/assign`, body)
    .subscribe({
      next: () => {        
        this.showEditOffer = false;
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Offer Product Type assigned to Customer.'
        });
       },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save Offer Product Type to Customer. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { offerSub.unsubscribe(); }
    });

  }

  addEditPricingType() {
    let body = { 
      customerGuid: this.portfolio?.customerGuid,
      productGuid: this.offerProductForm.value.productGuid || null,
      productDesc: this.offerProductForm.value.productDesc || null
    };

    let offerSub = this.apiService.post(`pricing/offer-product/add-update`, body)
    .subscribe({
      next: () => {        
        this.showEditPrice = false;
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Offer Product added/updated.'
        });
       },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to add/update Offer Product. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { offerSub.unsubscribe(); }
    });
  }

  addLocationPricing() {

    let body = { 
      customerGuid: this.portfolio?.customerGuid,
      branchGuid: this.addPricingForm.value.branch || null,
      productGuid: this.addPricingForm.value.productType || null
    };

    let offerSub = this.apiService.post(`pricing/offer-product/branch`, body)
    .subscribe({
      next: () => {   
        this.pricingFormLoaded = false;
        this.showAddBranchOffer = false;
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Offer Product assigned to location.'
        });
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to assign Offer Product to location. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { offerSub.unsubscribe(); }
    });

  }

  removeBcCommand(label: string | undefined) {
    if (!label) return;
    let item = this.bcItems.find(b => b.label === label);
    if (item) {
      let index = this.bcItems.indexOf(item);
      item.command = undefined;
      this.bcItems.splice(index, 1, item);
    }
  }

  showCancelToast() { this.toastService.showCancelToast(); }
}
