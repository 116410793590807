import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { LookupModel } from 'src/app/models/lookup-model';
import { LookupService } from 'src/app/services/lookup.service';
import { ProviderService } from 'src/app/services/provider.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-location-entry',
  templateUrl: './edit-location-entry.component.html',
  styleUrls: ['./edit-location-entry.component.scss']
})
export class EditLocationEntryComponent implements OnInit {
  countries: LookupModel[] = [];
  states: LookupModel[] = [];
  locationForm = this.fb.group({
    country: ['', Validators.required],
    locationId: ['', Validators.required],
    locationName: ['', Validators.required],
    contactName: ['', Validators.required],
    phone: ['', Validators.required],
    email: ['', Validators.required],
    address1: ['', Validators.required],
    address2: [''],
    city: ['', Validators.required],
    state: ['', Validators.required],
    zip: ['', Validators.required],
    website: ['', Validators.required]
  });
  constructor(private providerService: ProviderService, 
    private lookupService: LookupService, 
    public dialogRef: MatDialogRef<EditLocationEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {name: string},
    private fb: UntypedFormBuilder) {
    this.initLookups();
    this.states = lookupService.getStates();
   }

  ngOnInit(): void {
  }

  initLookups(){
    this.providerService.customerLookups$.subscribe(
      (lookups: ICustomerLookups) => {
        this.countries = lookups.countries || [];
      });
  }

  onSubmit(){

    console.warn(this.locationForm.value);
    this.dialogRef.close();
  }

  onCancel(){
    this.dialogRef.close();
  }
}
