import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { AppConfigs } from '../configs/app-config';
import { IUserGuids } from '../models/user-guids';
import { IUser } from '../models/user-interface';
import { ApiService } from './api.service';
import { SettingService } from './setting.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSource: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);
  private _userGuid = new BehaviorSubject<IUserGuids | undefined | null>(null);
  userGuid$ = this._userGuid.asObservable();
  userSubject$ = this.userSource.asObservable();

  constructor(private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private apiService: ApiService, private settings: SettingService) {
  }

  async logout() {
    this.userSource.next(null);
    this._userGuid.next(null);
    localStorage.removeItem(AppConfigs.loggedInuserGuid);
    localStorage.removeItem(AppConfigs.authenticatedUserAccount);
    localStorage.removeItem(AppConfigs.selectedPortfolioStorageKey);
    localStorage.removeItem(AppConfigs.selectedCampaignStorageKey);

    let params: any = {
      authority: await this.settings.signInPolicy(),
      postLogoutRedirectUri: window.location.origin
    };

    let homeAccountId = localStorage.getItem(AppConfigs.homeAccountId);
    if (homeAccountId) {
      params.account = this.msalService.instance.getAccountByHomeId(homeAccountId);
      localStorage.removeItem(AppConfigs.homeAccountId);
    }

    this.msalService.logoutRedirect(params);
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      console.log('auth.login msalGuardConfig: ', this.msalGuardConfig.authRequest);
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      console.log('auth.login missing msalGuardConfi');
      this.msalService.loginRedirect();
    }
  }

  resetPassword() {
    this.msalService.loginRedirect(this.settings.get('B2C_ResetPasswordPolicy'));
  }

  private setUser(email: string | null | undefined): void {
    this.apiService.get('user/get-user/' + email)
      .subscribe((user: IUser) => {
        let prevUser = this.userSource.getValue();
        if (prevUser && prevUser.userGuid && prevUser.userGuid?.length > 5) {
          if (user && user.portfolios.length) {
            prevUser.portfolios = user.portfolios;
            this.userSource.next(prevUser);
          }
        }
        else {
          if (user && user.userGuid) {
            this.userSource.next(user);
          } else {
            this.userSource.next(this.getErrorUser(email));
          }
        }
      });
  }

  authenticated(result: AuthenticationResult) {
    var account = result.account;
    let claims: any = result.idTokenClaims;
    this.setUserGuids(account, this.getClaims(claims));
    this.setUser(account?.username);
  }

  authenticatedAccount(account: AccountInfo) {
    this.setUser(account?.username);
    let claims: any = account.idTokenClaims;
    this.setUserGuids(account, this.getClaims(claims));
  }

  private getClaims(claims: any) {
    let list: Claim[] = new Array<Claim>();
    if (claims) {
      Object.keys(claims).forEach(function (k, v) {
        let c = new Claim();
        c.claim = k;
        c.value = claims ? claims[k] : null;
        list.push(c);
      });
    }
    return list;
  }

  private setUserGuids(account: AccountInfo | null, claims: Claim[]) {
    if (account) {
      var val = this._userGuid.getValue();
      if (!val || val.localAccountGuid !== account.localAccountId) {
        var json = JSON.stringify(JSON.stringify(claims));
        var obj = {
          accountId: account.localAccountId,
          email: account.username,
          accountJson: json
        }
        var apiUrl = `app/loggedin-user`;
        this.apiService.post(apiUrl, JSON.stringify(obj)).subscribe((res) => {
          let userGuids: IUserGuids = {
            localAccountGuid: account.localAccountId,
            loggedUserGuid: res.userGUID || ''
          }
          localStorage.setItem(AppConfigs.loggedInuserGuid, JSON.stringify(userGuids));
          this._userGuid.next(userGuids);
          let currSource = this.userSource.getValue();
          let userSource: IUser = {
            userGuid: res.userGUID,
            email: res.loginEmail,
            firstName: res.firstName,
            lastName: res.lastName,
            portfolios: currSource?.portfolios || [],
            userId: res.userID,
            error: ''
          }
          this.userSource.next(userSource);
        }, error => {
          console.error(error);
          let userGuids: IUserGuids = {
            localAccountGuid: account.localAccountId,
            loggedUserGuid: ''
          }
          this._userGuid.next(userGuids);
        })
      }
    }
  }

  private getErrorUser(username: string | undefined | null): IUser {
    return {
      userGuid: '',
      email: '',
      firstName: '',
      lastName: '',
      portfolios: [],
      userId: 0,
      error: `User ${username} not found in application.`,
    }
  }
}

export class Claim {
  claim: string | undefined;
  value: string | undefined;
}
