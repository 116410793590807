import { Component, Input, OnInit, Provider } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConfigs } from 'src/app/configs/app-config';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { CustomerProviderModel } from 'src/app/models/customer-provider-model';
import { ProviderInfoFormGroup } from 'src/app/models/form-groups';
import { ProviderInfo } from 'src/app/models/provider-info';
import { State } from 'src/app/models/states';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-provider-info',
  templateUrl: './provider-info.component.html',
  styleUrls: ['./provider-info.component.scss']
})
export class ProviderInfoComponent implements OnInit {

  private _provider: CustomerProviderModel | null = null;

  @Input() set  provider(value: CustomerProviderModel | null) {
    this._provider = value;
    this.loadLookups();
    this.loadProvider();
  }
  get provider(): CustomerProviderModel | null { return this._provider; }

  providerInfo: ProviderInfo | null = null;
  lookups: ICustomerLookups = {} as ICustomerLookups; 
  infoForm: FormGroup<ProviderInfoFormGroup> = {} as FormGroup;
  showStateDD: boolean = true;
  states: State[] = [];
  formInStorage: boolean = false;

  constructor(
    private apiService: ApiService,
    private providerService: ProviderService,
    private toastService: ToastService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.states = this.helperService.usStates();
    this.createForm();
    this.loadFromStorage();
    this.setSubscriptions();   
  }

  setSubscriptions() {

    this.infoForm.valueChanges
    .subscribe(value => { 
      localStorage.setItem(AppConfigs.providerInfoForm, JSON.stringify(this.infoForm.value));
    });

    this.providerService.customerLookups$
    .subscribe({
      next: (lookups: ICustomerLookups) => {
        this.lookups = lookups;
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get Customer information. See log for details'
        }, 'center');
        console.error(err);
      }
    });

    let provInfoSub = this.providerService.infoSectionProvider$
      .subscribe({
        next: (provider: ProviderInfo | null) => { 
          this.providerInfo = provider;
          if (!this.formInStorage) {
            this.loadProvider();
          }
        },
        error: (err: any) => { 
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Customer information. See log for details'
          });
          console.error(err);
        },
        complete: () => { provInfoSub.unsubscribe(); }
      });
  }

  loadLookups() {
    this.providerService.loadInfoSectionLookups(this._provider?.customerGuid ?? '');
  }

  createForm() {
    this.infoForm = new FormGroup<ProviderInfoFormGroup>({
      type: new FormControl<string>('0', {nonNullable: true, validators: [Validators.required]}),
      vertical: new FormControl<string>('0', {nonNullable: true, validators: [Validators.required]}),
      name: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      displayName: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      website: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      country: new FormControl<string>('1', {nonNullable: true, validators: [Validators.required]}),
      address1: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      address2: new FormControl<string>('', {nonNullable: true}),
      city: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      state: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      zip: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      contactName: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      phone: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      email: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    });
  }

  loadFromStorage() {
    this.formInStorage = false;
    let storage = localStorage.getItem(AppConfigs.providerInfoForm);
    if (storage) {
      this.formInStorage = true;
      this.infoForm.setValue(JSON.parse(storage));
    }
    
  }

  loadProvider() {
    if (!this.infoForm.value) this.createForm();

    let frmCountry = '0';
    if (this.providerInfo?.country) {
      frmCountry = this.lookups.countries.find(c => c.desc.toLowerCase() == this.providerInfo?.country?.toLowerCase())?.id || '0';
    }
    this.infoForm.patchValue({
      type: this.providerInfo?.providerTypeID.toString(),
      vertical: this.providerInfo?.providerRelationshipID.toString(),
      name: this.providerInfo?.customerName,
      displayName: this.providerInfo?.customerDisplayName || '',
      website: this.providerInfo?.website || '',
      country: frmCountry,
      address1: this.providerInfo?.address1 || '',
      address2: this.providerInfo?.address2 || '',
      city: this.providerInfo?.city || '',
      state: this.providerInfo?.state || '',
      zip: this.providerInfo?.zipCode || '',
      contactName: this.providerInfo?.customerContact || '',
      phone: this.providerInfo?.phone || '',
      email: this.providerInfo?.emailAddress || ''
    });
    
  }
  
  toggelShowState(countryId: number) {
    this.showStateDD = countryId == 1;
  }

  checkProviderName(name: string) {
    if (this.providerInfo && this.providerInfo.customerName) return;
    if (!name || name.trim() == '') return;

    let nameSub = this.apiService.get(`provider/check-name/${name}`)
    .subscribe({
      next: () => { },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Customer Name already exisits. Please change'
        }, 'center');
        console.error(err);
        this.infoForm.patchValue({
          name: undefined
        });
      },
      complete: () => { nameSub.unsubscribe(); }
    })
  }

  saveProviderInfo() { 
    let isNew = !this.providerInfo;
     
    let provider = new ProviderInfo();
    if (this.providerInfo && this.providerInfo.customerGuid) {
      provider = this.providerInfo;
    }
    provider = this.loadFormIntoProvider(provider);

    let provSub = this.apiService.post(`provider/add-update`, provider)
    .subscribe({
      next: () => { 
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Customer successfully added / updated.'
        });
        if (isNew) {
          let idSub = this.apiService.get(`provider/get-customer-id/${provider.customerName}`)
          .subscribe({
            next: (data: any) => { 
              let customerGuid = data["customerGuid"];
              this.providerService.loadInfoSectionLookups(customerGuid);
              this.providerService.loadInfoSectionProvider(customerGuid);
            },
            error: (err: any) => {
              this.toastService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Unable load customer after save.'
              });
            },
            complete: () => { idSub.unsubscribe(); }
          });
        }
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save information. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { provSub.unsubscribe(); }
    });
  }

  loadFormIntoProvider(Tprovider: ProviderInfo): ProviderInfo {
   
    let provider = {...Tprovider};
    provider.providerTypeID = +(this.infoForm.value.type || -1);
    provider.providerRelationshipID = +(this.infoForm.value.vertical || -1);
    provider.customerName = this.infoForm.value.name || '';
    provider.customerDisplayName = this.infoForm.value.displayName || '';
    provider.website = this.infoForm.value.website || null;
    provider.country = this.infoForm.value.country || 'USA';
    provider.state = this.infoForm.value.state || null;
    provider.address1 = this.infoForm.value.address1 || null;
    provider.address2 = this.infoForm.value.address2 || null;
    provider.city = this.infoForm.value.city || null;
    provider.zipCode = this.infoForm.value.zip || null;
    provider.customerContact = this.infoForm.value.contactName || null;
    provider.contact_Phone = this.infoForm.value.phone || null;
    provider.contact_Email = this.infoForm.value.email || null;
    return provider;
  }

}
