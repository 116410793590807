import { NgModule, APP_INITIALIZER } from '@angular/core';
import {
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SettingService } from './services/setting.service';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { getMsalConfig } from './configs/auth-config';
import { ApiInterceptor } from './services/api-interceptor';
import { environment } from 'src/environments/environment';

export function MSALGuardConfigFactory(setting: SettingService): MsalGuardConfiguration {
    let msalConfig = setting.get();
    var scopes = msalConfig['App_ScopeURLs'];
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: scopes.split(',')
        }
    };
}

export function MSALInterceptorConfigFactory(setting: SettingService): MsalInterceptorConfiguration {
    let msalConfig = setting.get();
    var scopes = msalConfig['App_ScopeURLs'];
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(msalConfig['API_Endpoint'], scopes.split(','));

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALInstanceFactory(setting: SettingService): PublicClientApplication {
    var msalConfig = getMsalConfig(setting.get());
    return new PublicClientApplication(msalConfig);
}

function appSettingsLoader(appSettingsService: SettingService): any {
    const promise = appSettingsService.load().then((res) => {
        // console.log(res)
    });
    return () => promise;
}

@NgModule({
    providers: [
    ],
    imports: [MsalModule]
})
export class MsalApplicationModule {
    static forRoot() {
        return {
            ngModule: MsalApplicationModule,
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map([
                [environment.apiEndpoint, ['user.read']]
            ]),
            providers: [
                SettingService,
                {
                    provide: APP_INITIALIZER,
                    useFactory: appSettingsLoader,
                    deps: [SettingService],
                    multi: true
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ApiInterceptor,
                    multi: true
                  },
                {
                    provide: MSAL_GUARD_CONFIG,
                    useFactory: MSALGuardConfigFactory,
                    deps: [SettingService]
                },
                {
                    provide: MSAL_INTERCEPTOR_CONFIG,
                    useFactory: MSALInterceptorConfigFactory,
                    deps: [SettingService]
                },
                MsalService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: MsalInterceptor,
                    multi: true
                },
                MsalGuard,
                MsalBroadcastService,
                {
                    provide: MSAL_INSTANCE,
                    useFactory: MSALInstanceFactory,
                    deps: [SettingService]
                }
            ]
        };
    }
}