<div class="container-fluid admin-bg">
    <div class="d-flex justify-content-center">
        <div class="card portfolio-list shadow my-4 mx-3">
            <div class="logo mb-3">
              <img [src]="logoUrl" class="img-center max-h-10rem" alt="Logo" />
            </div>
            <div *ngIf="loadingPortfolios">
              <div class="text-2xl">Loading...</div>
              <p3solved-progress-bar [style]="{'height': '0.5rem'}"></p3solved-progress-bar>
          </div>
            <p *ngIf="error" class="mb-3 text-danger">{{error}}</p>
            <div *ngIf="!loadingPortfolios" class="mx-auto w-6">
              <div class="flex flex-row-reverse pb-2">
                <input #txtSearch [(ngModel)]="searchFilter" type="text" class="w-15rem" pInputText placeholder="Search"
                  (input)="dtPortfolios.filterGlobal(txtSearch.value, 'contains')" />
              </div>
              <p-table #dtPortfolios [value]="portfolios" styleClass="p-datatable-lg"
                [globalFilterFields]="displayedColumns" [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]"
                [rows]="rows" [(first)]="first" [showCurrentPageReport]="false" responsiveLayout="scroll" [rowHover]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="name" scope="col">Customer Name <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th class="text-center" scope="col"></th>
                    <th class="text-center" scope="col"></th>
                    <!-- <th class="text-center" scope="col"></th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-portfolio>
                  <tr>
                    <td>
                      <img *ngIf="portfolio.adminIcon" [src]="portfolio.adminIcon" alt="" height="25" class="pr-3">               
                      {{ portfolio.name }}
                    </td>
                    <td class="w-2">
                      <p3solved-button id="btn{{portfolio.customerGuid}}" label="Admin" (click)="gotoAdmin(portfolio)"></p3solved-button>
                    </td>
                    <td class="w-2">
                      <p3solved-button id="btn{{portfolio.customerGuid}}" label="CS" class="px-4" (click)="gotoCS(portfolio)"></p3solved-button>
                    </td>
                    <!-- <td class="w-2">
                      <p3solved-button id="btn{{portfolio.customerGuid}}" label="BOP" class="px-4" (click)="gotoBop(portfolio)"></p3solved-button>
                    </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="4">No customers to display.</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="flex justify-content-center text-sm">
                    App Version: {{currentApplicationVersion}}
                  </div>
              </ng-template>
              </p-table>
            </div>
          </div>
    </div>
</div>
