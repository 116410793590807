import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerProviderModel } from 'src/app/models/customer-provider-model';
import { ProcedureFormGroup } from 'src/app/models/form-groups';
import { ProcedureUpload } from 'src/app/models/procedure';
import { ApiService } from 'src/app/services/api.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ToastService } from 'src/app/services/toast.service';
import { CustomGridComponent } from '../../custom-grid/custom-grid.component';

@Component({
  selector: 'app-provider-procedure',
  templateUrl: './provider-procedure.component.html',
  styleUrls: ['./provider-procedure.component.scss']
})
export class ProviderProcedureComponent implements OnInit, AfterViewInit {

  private _provider: CustomerProviderModel | null = null;
  @Input() set provider(value: CustomerProviderModel | null) { this._provider = value; }
  get provider(): CustomerProviderModel | null { return this._provider; }

  showProcedure: boolean = false;
  showAddProcedure: boolean = false;
  procedureFormLoaded: boolean = false;

  procedureForm: FormGroup<ProcedureFormGroup> = {} as FormGroup;

  @ViewChild('cgProcedures') cgProcedures: CustomGridComponent = {} as CustomGridComponent;

  constructor(
    private apiService: ApiService,
    private providerService: ProviderService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.showProcedure = true;
  }

  ngAfterViewInit(): void {
    if (this.provider) {
      this.cgProcedures.SearchXML = `<params><f fn=\"EditCustomerGUID\" fv=\"${this.provider.customerGuid}\" /></params>`;
      this.cgProcedures.setCustomerInfo(this.provider.customerGuid, null);
    }
  }

  addNewProcedure() {
    this.createProcedureForm();
    this.procedureFormLoaded = true;
    this.showAddProcedure = true;
  }

  createProcedureForm() {
    this.procedureForm = new FormGroup<ProcedureFormGroup>({
      description: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      searchValue: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      sku: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      msrp: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required] }),
      showBop: new FormControl<boolean>(true, { nonNullable: true, validators: [Validators.required] }),
    });
  }

  saveProcedure() {
    let procedure: ProcedureUpload = new ProcedureUpload();
    procedure.customerGuid = this.provider?.customerGuid || '';
    procedure.description = this.procedureForm.value.description || '';
    procedure.msrp = this.procedureForm.value.msrp || 0;
    procedure.searchValue = this.procedureForm.value.searchValue || '';
    procedure.showBop = this.procedureForm.value.showBop || true;
    procedure.sku = this.procedureForm.value.sku || '';

    let procSub = this.apiService.post(`provider/procedure`, procedure)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Procedure added successfully'
          });
          this.cgProcedures.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save procedure. See log for details.'
          });
          console.error(err);
        },
        complete: () => { procSub.unsubscribe(); }
      });
  }

}
