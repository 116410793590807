<div [formGroup]="group" class="mb-2">
    <label *ngIf="label" [for]="id" class="form-label clabel">{{label}}</label>
    <input type="text"
    [readonly]="readonly"
    [formControlName]="controlName"
    [ngClass]="group && group.controls[controlName].touched && group.controls[controlName].invalid ? 'is-invalid' : ''"
     class="form-control form-control-sm" [id]="id" [placeholder]="placeholder">
     <div *ngIf="group && group.controls[controlName].touched && group.controls[controlName]?.invalid" class="invalid-feedback">
        This field is invalid
      </div>
  </div>