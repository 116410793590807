import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CustomerProviderModel } from 'src/app/models/customer-provider-model';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { CustomGridComponent } from '../../custom-grid/custom-grid.component';

@Component({
  selector: 'app-provider-processor',
  templateUrl: './provider-processor.component.html',
  styleUrls: ['./provider-processor.component.scss']
})
export class ProviderProcessorComponent implements OnInit, AfterViewInit {

  private _provider: CustomerProviderModel | null = null;
  @Input() set provider(value: CustomerProviderModel | null) { this._provider = value; }
  get provider(): CustomerProviderModel | null { return this._provider; }
  
  showProcessors: boolean = false;
  showProcessorEdit: boolean = false;

  serviceName: string = '';
  apiSecret: string | null = null;
  serviceGuid: string = '';

  @ViewChild('cgProcessors') cgProcessors: CustomGridComponent = {} as CustomGridComponent;
  
  constructor(
    private apiService: ApiService,
    private toastService: ToastService
    ) { }

  ngOnInit(): void {
    this.showProcessors = true;
  }

  ngAfterViewInit(): void {
    if (this.provider) {
      this.cgProcessors.SearchXML = `<params><f fn=\"EditCustomerGUID\" fv=\"${this.provider.customerGuid}\" /></params>`;
      this.cgProcessors.setCustomerInfo(this.provider.customerGuid, null);
    }
  }

  editProcessor(row: any) {
    this.serviceGuid = row["TransVendorServiceNameGUID"];
    this.serviceName = row["Service Name"];
    this.apiSecret = row["Payliance Card API Token Secret"];
    this.showProcessorEdit = true;
  }

  saveProcessor() { 
    let processor = {
      customerGuid: this.provider?.customerGuid,
      vendorCustomerGuid: this.provider?.customerGuid,
      serviceGuid: this.serviceGuid,
      apiSecret: this.apiSecret
    };

    let procSub = this.apiService.post(`provider/processor`, processor)
      .subscribe({
        next: () => {
          this.showProcessorEdit = false;
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'API Secret successfully updated.'
          });
          this.cgProcessors.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save processor info. See log for details.'
          });
          console.error(err);
        },
        complete: () => { procSub.unsubscribe(); }
      });
  }
}
