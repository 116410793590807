import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { combineLatest, forkJoin } from 'rxjs';
import { CustomGridComponent } from 'src/app/components/custom-grid/custom-grid.component';
import { Campaign } from 'src/app/models/campaign';
import { CampaignPage, CampaignPageRow, CampaignVariable, PageBlock, PageDependency, PageEvent, PageFlow, PageGroup, PageTrigger, PageTriggerRow } from 'src/app/models/campaign-pages';
import { PortfolioItem } from 'src/app/models/customer-item';
import { BoolValDropdown, NumValDropDown } from 'src/app/models/dropdown';
import { CopyPageFormGroup, PageFormGroup, PageBlockFormGroup, PageFlowFormGroup, PageTriggerFormGroup } from 'src/app/models/form-groups';
import { AlertLoanStatus } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-campaign-pages',
  templateUrl: './campaign-pages.component.html',
  styleUrls: ['./campaign-pages.component.scss']
})
export class CampaignPagesComponent implements OnInit {

  bcItems: MenuItem[] = [];
  editCardTitle: string = 'Add New';
  pageFlowTitle: string = 'Add New';
  pageBlockTitle: string = 'Add New';
  pageTriggerTitle: string = 'Add New';

  guidsLoaded: boolean = true;
  showPagesCard: boolean = true;
  showActionsCard: boolean = false;
  showEditCard: boolean = false;
  showPageDataCard: boolean = false;

  pageForm: FormGroup<PageFormGroup> = {} as FormGroup;
  pageFormLoaded: boolean = false;

  copyPageForm: FormGroup<CopyPageFormGroup> = {} as FormGroup;
  showCopyModal: boolean = false;
  copyPageFormLoaded: boolean = false;

  pageFlowForm: FormGroup<PageFlowFormGroup> = {} as FormGroup;
  showPageFlowModal: boolean = false;
  pageFlowFormLoaded: boolean = false;
  selectedPageFlow: PageFlow | null = null;

  pageTriggerForm: FormGroup<PageTriggerFormGroup> = {} as FormGroup;
  showPageTriggerModal: boolean = false;
  pageTriggerFormLoaded: boolean = false;
  selectedPageTrigger: PageTriggerRow | null = null;

  pageBlockForm: FormGroup<PageBlockFormGroup> = {} as FormGroup;
  showPageBlockModal: boolean = false;
  pageBlockFormLoaded: boolean = false;
  selectedPageBlock: PageBlock | null = null;

  loanStatuses: AlertLoanStatus[] = [];
  selectedPortfolio: PortfolioItem | null = null;
  selectedCampaign: Campaign | null = null;
  selectedPage: CampaignPage | null = null;
  pageDependcies: PageDependency[] = [];
  campVars: CampaignVariable[] = [];

  triggerPages: NumValDropDown[] = [];

  pageEventsRaw: PageEvent[] = [];
  pageGroups: PageGroup[] = [];
  pageEvents: NumValDropDown[] = [];
  pageTypes: NumValDropDown[] = [
    { text: 'Form', value: 1 },
    { text: 'Link', value: 2 },
    { text: 'Inline Form', value: 3 },
    { text: 'Global controls', value: 4 },
  ];
  yesNoOptions: BoolValDropdown[] = [{ text: 'No', value: false }, { text: 'Yes', value: true }];

  @ViewChild('cgPages') cgPages: CustomGridComponent = {} as CustomGridComponent;
  @ViewChild('cgPageFlow') cgPageFlow: CustomGridComponent = {} as CustomGridComponent;
  @ViewChild('cgPageBlock') cgPageBlock: CustomGridComponent = {} as CustomGridComponent;
  @ViewChild('cgPageTrigger') cgPageTrigger: CustomGridComponent = {} as CustomGridComponent;
  
  constructor(
    private portfolioService: PortfolioService,
    private campaignService: CampaignService,
    private apiService: ApiService,
    private toastService: ToastService,
    private navService: NavService,
    private router: Router
  ) { }

  ngOnInit(): void {
    let combSub = combineLatest([this.portfolioService.portfolio$, this.campaignService.selectedCampaign$])
      .subscribe({
        next: ([p, c]) => {
          this.selectedPortfolio = p;
          this.selectedCampaign = c;

          if (p && c) {
            this.guidsLoaded = true;
            this.populateNewPageDDs(null).then(() => { });
          }
          else {
            setTimeout(() => {                
              if (!this.selectedPortfolio || !this.selectedCampaign) {                
                this.guidsLoaded = false;
              }              
            }, 500);
          }

        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get customer and/or campaign'
          });
          console.error(err);
        },
        complete: () => { combSub.unsubscribe(); }
      });

    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Campaigns', routerLink: ['/campaign'], command: () => { this.navService.setLeftNavSelection('Campaign'); } },
      { label: 'Pages' }
    ];
  }

  showNewPage() {
    this.getDefaultCampaignDeps()
      .then(() => {
        this.createNewPageForm();
        let prevItem = this.bcItems.pop();
        if (prevItem) {
          prevItem.command = () => { this.showActionsCard = false; this.showEditCard = false; this.showPagesCard = true; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
          this.bcItems.push(prevItem);
        }
        this.bcItems.push({ label: 'Add New Page' });

        this.selectedPage = null;
        this.editCardTitle = 'Add New';
        this.showPagesCard = false;
        this.showEditCard = true;
        this.showActionsCard = false;
      });
  }

  editCamPage(event: CampaignPageRow) {
    this.populateNewPageDDs(event.PageGUID)
      .then(() => {
        let getPageSub = this.apiService.get(`pages/page/${event.PageGUID}/${this.selectedCampaign?.campaignGuid}`)
          .subscribe({
            next: (page: CampaignPage) => {
              this.selectedPage = page;
              this.createNewPageForm();
              let prevItem = this.bcItems.pop();
              if (prevItem) {
                prevItem.command = () => {
                  this.showActionsCard = false;
                  this.showEditCard = false;
                  this.showPagesCard = true;
                  this.bcItems.pop();
                  this.removeBcCommand(prevItem?.label);
                };
                this.bcItems.push(prevItem);
              }
              this.bcItems.push({ label: 'Edit Page' });

              this.pageForm.patchValue({
                appStatus: page.loanAppStatusID,
                pageType: page.pageTypeID,
                startPage: page.isStartPage,
                pageName: page.pageName,
                pageUrl: page.pageURL,
                pageScore: page.pageScore,
                multiScore: page.pageTrackMulti,
                pageDependencies: page.dependencies,
                csr: page.csrShow,
                pageGroup: page.pageGroupID,
                variable: page.campaignVariableID,
                varValue: page.campaignVariableValue,
                varWeight: page.campaignVariableWeight
              });
              this.editCardTitle = 'Edit';
              this.showPagesCard = false;
              this.showEditCard = true;
              this.showActionsCard = false;
            },
            error: (err: any) => {
              this.toastService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Unable to get page data. See log for details.'
              });
              console.error(err);
            },
            complete: () => { getPageSub.unsubscribe(); }
          });
      });
  }

  savePage() {
    let deps = '';
    if (this.pageForm.value.pageDependencies && this.pageForm.value.pageDependencies.length > 0) {
      deps = this.pageForm.value.pageDependencies.map(d => d.toString()).join(',');
    }
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      campaignGuid: this.selectedCampaign?.campaignGuid,
      pageGuid: this.selectedPage != null ? this.selectedPage.pageGuid : uuidv4(),
      pageName: this.pageForm.value.pageName,
      pageUrl: this.pageForm.value.pageUrl,
      isStartPage: this.pageForm.value.startPage,
      loanAppStatusId: this.pageForm.value.appStatus,
      pageScore: this.pageForm.value.pageScore,
      pageTypeId: this.pageForm.value.pageType,
      pageTrackMulti: this.pageForm.value.multiScore,
      dependencies: deps,
      cSRShow: this.pageForm.value.csr,
      pageGroupId: this.pageForm.value.pageGroup,
      campaignVariableId: this.pageForm.value.variable,
      campaignVariableValue: this.pageForm.value.varValue,
      campaignVariableWeight: this.pageForm.value.varWeight,      
    };

    let pageSub = this.apiService.post(`pages/save-page`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Page successfully added/updated'
          });
          this.bcItems.pop();
          let currItem = this.bcItems.pop();
          if (currItem) {
            currItem.command = () => { };
            this.bcItems.push(currItem);
          }
          this.showActionsCard = false;
          this.selectedPage = null;
          this.showEditCard = false;
          this.showPagesCard = true;
          this.cgPages.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to add/update Page. See log for details.'
          });
          console.error(err);
        },
        complete: () => { pageSub.unsubscribe(); }
      });
  }

  cancelPageEdit() {
    this.showActionsCard = false;
    this.selectedPage = null;
    this.showEditCard = false;
    this.showPagesCard = true;
    this.bcItems.pop();
    let currItem = this.bcItems.pop();
    if (currItem) {
      currItem.command = () => { };
      this.bcItems.push(currItem);
    }
  }
  
  deletePage(page: CampaignPageRow) {
    let body = {
      campaignGuid: this.selectedCampaign?.campaignGuid,
      pageGuid: page.PageGUID
    }; 

    let pageSub = this.apiService.post(`pages/delete-page`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Page successfully deleted'
          });
          this.cgPages.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to delete Page. See log for details.'
          });
          console.error(err);
        },
        complete: () => { pageSub.unsubscribe(); }
      });

  }

  async populateNewPageDDs(pageGuid: string | null) {
    let Apis: any = {};

    if (this.loanStatuses.length == 0) {
      Apis.lookups = this.apiService.get(`alert/alert-lookups/${this.selectedPortfolio?.customerGuid}`);
    }

    if (this.pageDependcies.length == 0 || pageGuid != null) {
      let depUrl = `pages/dependencies/${this.selectedCampaign?.campaignGuid}`;
      if (pageGuid != null) depUrl += `/${pageGuid}`;
      Apis.dependencies = this.apiService.get(depUrl)
    }

    if (this.pageGroups.length == 0) {
      Apis.groups = this.apiService.get(`pages/page-groups/${this.selectedCampaign?.campaignGuid}`);
    }

    if (this.campVars.length == 0) {
      Apis.vars = this.apiService.get(`campaign/${this.selectedCampaign?.campaignGuid}/variables`);
    }

    if (this.pageEvents.length == 0) {
      Apis.pageEvents = this.apiService.get(`pages/events`);
    }

    if (Object.keys(Apis).length > 0) {
      const subscript = forkJoin(Apis).toPromise()
        .then((data: any) => {
          if (data.lookups && data.lookups.loanAppStatuses) {
            this.loanStatuses = data.lookups.loanAppStatuses;
          }
          if (data.dependencies) {
            this.pageDependcies = data.dependencies;
          }
          if (data.groups) {
            (data.groups as any[]).forEach(group => {
              group.pageName =group.pageURL.length > 0 ? `${group.pageName} (${group.pageURL})` : group.pageName;
            });
            console.log(data.groups);
            this.pageGroups = data.groups;
          }
          if (data.vars) {
            this.campVars = data.vars;
          }
          if (data.pageEvents) {
            this.pageEventsRaw = data.pageEvents;
            for (let i = 0; i < data.pageEvents.length; i++) {
              let pe = data.pageEvents[i] as PageEvent;
              this.pageEvents.push({
                text: `${pe.pageEvent} (${pe.eventDescription})`,
                value: pe.pageEventID
              })
            }
          }
        });
    }
  }

  async getDefaultCampaignDeps() {
    let depUrl = `pages/dependencies/${this.selectedCampaign?.campaignGuid}`;

    let depSub = this.apiService.get(depUrl)
      .subscribe({
        next: (deps: PageDependency[]) => {
          this.pageDependcies = deps;
        },
        error: (err: any) => {
          console.error(err);
          this.pageDependcies = [];
        },
        complete: () => { depSub.unsubscribe(); }
      })
  }

  createNewPageForm() {
    this.pageForm = new FormGroup<PageFormGroup>({
      appStatus: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      pageType: new FormControl<number>(1, { nonNullable: true, validators: [Validators.required] }),
      startPage: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.required] }),
      pageName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      pageUrl: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      pageScore: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required] }),
      multiScore: new FormControl<boolean>(false, { nonNullable: true }),
      pageDependencies: new FormControl<number[] | null>(null, { nonNullable: true }),
      csr: new FormControl<boolean | null>(null, { nonNullable: true }),
      pageGroup: new FormControl<number>(-1, { nonNullable: true }),
      variable: new FormControl<number>(-1, { nonNullable: true }),
      varValue: new FormControl<string>('', { nonNullable: true }),
      varWeight: new FormControl<number>(0, { nonNullable: true })
    });
    this.pageFormLoaded = true;
  }

  showSetPageActions() {

    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showActionsCard = false; this.showEditCard = false; this.showPagesCard = true; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: 'Page Flow' });

    this.showPagesCard = false;
    this.showEditCard = false;
    this.showActionsCard = true;
  }

  addPageFlow() {
    this.selectedPageFlow = null;
    this.createPageFlowForm();
    this.pageFlowTitle = 'Add New';
    this.pageFlowFormLoaded = true;
    this.showPageFlowModal = true;
  }

  editPageFlow(event: PageFlow) {
    this.selectedPageFlow = event;
    this.createPageFlowForm();
    this.pageFlowForm.patchValue({
      pageId: +event.BeforeThisPageCanBeLoadedID,
      completedPageId: +event.ThisPageMustBeCompletedID
    });
    this.pageFlowTitle = 'Edit';
    this.pageFlowFormLoaded = true;
    this.showPageFlowModal = true;
  }
  
  deletePageFlow(pageFlow: PageFlow) {
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      campaignGuid: this.selectedCampaign?.campaignGuid,
      pageId: +pageFlow.BeforeThisPageCanBeLoadedID,
      reqPageId: +pageFlow.ThisPageMustBeCompletedID
    };

    let pageSub = this.apiService.post(`pages/delete-flow`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Page Flow successfully deleted'
          });
          this.cgPageFlow.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to delete Page Flow. See log for details.'
          });
          console.error(err);
        },
        complete: () => { pageSub.unsubscribe(); }
      });
  }

  createPageFlowForm() {
    this.pageFlowForm = new FormGroup<PageFlowFormGroup>({
      pageId: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      completedPageId: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] })
    });
  }

  savePageFlow() {
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      campaignGuid: this.selectedCampaign?.campaignGuid,
      pageIdOrig: this.selectedPageFlow != null ? +this.selectedPageFlow.BeforeThisPageCanBeLoadedID : 0,
      pageIdNew: this.pageFlowForm.value.pageId,
      reqPageIdOrig: this.selectedPageFlow != null ? +this.selectedPageFlow.ThisPageMustBeCompletedID : 0,
      reqPageIdNew: this.pageFlowForm.value.completedPageId
    };

    let flowSub = this.apiService.post(`pages/page-flow`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Page Flow successfully added/updated'
          });
          this.cgPageFlow.getDataInfo();
          this.pageFlowFormLoaded = false;
          this.showPageFlowModal = false;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to add/update Page Flow. See log for details.'
          });
          console.error(err);
        },
        complete: () => { flowSub.unsubscribe(); }
      });
  }

  addPageBlock() {
    this.selectedPageBlock = null;
    this.createPageBlockForm();
    this.pageBlockTitle = 'Add New';
    this.pageBlockFormLoaded = true;
    this.showPageBlockModal = true;
  }

  editPageBlock(event: PageBlock) {
    this.selectedPageBlock = event;
    this.createPageBlockForm();
    this.pageBlockForm.patchValue({
      pageId: +event.CompleteThisPageID,
      blockPageId: +event.BlockThisPageFromLoadingID
    })
    this.pageBlockTitle = 'Edit';
    this.pageBlockFormLoaded = true;
    this.showPageBlockModal = true;
  }
  
  deletePageBlock(event: PageBlock) {
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      campaignGuid: this.selectedCampaign?.campaignGuid,
      pageId: +event.BlockThisPageFromLoadingID,
      blockByPageId: +event.CompleteThisPageID
    };

    let pageSub = this.apiService.post(`pages/delete-block`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Page Block successfully deleted'
          });
          this.cgPageBlock.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to delete Page Block. See log for details.'
          });
          console.error(err);
        },
        complete: () => { pageSub.unsubscribe(); }
      });
  }

  createPageBlockForm() {
    this.pageBlockForm = new FormGroup<PageBlockFormGroup>({
      pageId: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      blockPageId: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] })
    });
  }

  savePageBlock() {
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      campaignGuid: this.selectedCampaign?.campaignGuid,
      pageIdOrig: this.selectedPageBlock != null ? +this.selectedPageBlock.BlockThisPageFromLoadingID : 0,
      pageIdNew: this.pageBlockForm.value.blockPageId,
      blockByPageIdOrig: this.selectedPageBlock != null ? +this.selectedPageBlock.CompleteThisPageID : 0,
      blockByPageIdNew: this.pageBlockForm.value.pageId
    };

    let blockSub = this.apiService.post(`pages/page-block`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Page Block successfully added/updated'
          });
          this.cgPageBlock.getDataInfo();
          this.pageBlockFormLoaded = false;
          this.showPageBlockModal = false;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to add/update Page Block. See log for details.'
          });
          console.error(err);
        },
        complete: () => { blockSub.unsubscribe(); }
      });
  }

  addPageTrigger() {
    this.selectedPageTrigger = null;
    this.createTriggerForm();
    this.pageTriggerTitle = 'Add New';
    this.pageTriggerFormLoaded = true;
    this.showPageTriggerModal = true;

  }

  editPageTrigger(event: PageTriggerRow) {
    this.selectedPageTrigger = event;
    this.apiService.get(`pages/trigger/${event.PageTriggerID}`)
      .subscribe({
        next: (trigger: PageTrigger) => {
          this.createTriggerForm();
          this.pageTriggerForm.patchValue({
            pageId: trigger.pageID,
            eventId: trigger.pageEventID,
            sql: trigger.sqlCall,
            order: trigger.executeOrder
          });
          this.pageTriggerTitle = 'Edit';
          this.pageTriggerFormLoaded = true;
          this.showPageTriggerModal = true;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Page Trigger. See log for details'
          });
          console.error(err);
        }
      });
  }

  deletePageTrigger(event: PageTriggerRow) {
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      pageTriggerId: +event.PageTriggerID
    };

    let pageSub = this.apiService.post(`pages/delete-trigger`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Page Trigger successfully deleted'
          });
          this.cgPageTrigger.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to delete Page Trigger. See log for details.'
          });
          console.error(err);
        },
        complete: () => { pageSub.unsubscribe(); }
      });
  }

  savePageTrigger() {
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      campaignGuid: this.selectedCampaign?.campaignGuid,
      pageTriggerId: this.selectedPageTrigger != null ? +this.selectedPageTrigger.PageTriggerID : 0,
      pageId: this.pageTriggerForm.value.pageId,
      pageEventId: this.pageTriggerForm.value.eventId,
      order: this.pageTriggerForm.value.order,
      sql: this.pageTriggerForm.value.sql
    };

    let triggerSub = this.apiService.post(`pages/page-trigger`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Page Trigger successfully added/updated'
          });
          this.cgPageTrigger.getDataInfo();
          this.pageTriggerFormLoaded = false;
          this.showPageTriggerModal = false;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to add/update Page Trigger. See log for details.'
          });
          console.error(err);
        },
        complete: () => { triggerSub.unsubscribe(); }
      });
  }

  createTriggerForm() {
    this.triggerPages = [];
    for (let i = 0; i < this.pageGroups.length; i++) {
      let pg = this.pageGroups[i];
      this.triggerPages.push({
        text: `${pg.pageName} (${pg.pageURL})`,
        value: pg.pageID
      });
    }
    this.pageTriggerForm = new FormGroup<PageTriggerFormGroup>({
      pageId: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      eventId: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      sql: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      order: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required] })
    });
  }

  copyCamPage(event: CampaignPageRow) {
    this.copyPageForm = new FormGroup<CopyPageFormGroup>({
      pageName: new FormControl<string>(`${event['Page Name Small']}_copy`, { nonNullable: true, validators: [Validators.required] }),
      pageUrl: new FormControl<string>(`${event['Page URL']}`, { nonNullable: true, validators: [Validators.required] }),
      flashName: new FormControl<string>(`${event['Flash Name']}`, { nonNullable: true, validators: [Validators.required] }),
      pageGuid: new FormControl<string>(`${event.PageGUID}`, { nonNullable: true })
    });
    this.copyPageFormLoaded = true;
    this.showCopyModal = true;
  }

  saveCopyPage() {
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      pageGuid: this.copyPageForm.value.pageGuid,
      newPageName: this.copyPageForm.value.pageName,
      newPageUrl: this.copyPageForm.value.pageUrl,
      newFlashName: this.copyPageForm.value.flashName
    };

    let copySub = this.apiService.post(`pages/copy-page`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Page successfully copied.'
          });
          this.showCopyModal = false;
          this.cgPages.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to copy page. See log for details.'
          });
          console.error(err);
        },
        complete: () => { copySub.unsubscribe(); }
      });
  }

  editPageData(page: CampaignPageRow) {
    this.router.navigate([`/campaign/page/data`], {
      queryParams: {
        pGuid: page.PageGUID.trim()
      }
    });
  }

  removeBcCommand(label: string | undefined) {
    if (!label) return;
    let item = this.bcItems.find(b => b.label === label);
    if (item) {
      let index = this.bcItems.indexOf(item);
      item.command = undefined;
      this.bcItems.splice(index, 1, item);
    }
  }

}
