import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  private leftNavExpanded = new BehaviorSubject<boolean>(false);
  leftNavExpanded$ = this.leftNavExpanded.asObservable();

  private leftNaveSelection = new BehaviorSubject<NavSettings>({} as NavSettings);
  leftNaveSelection$ = this.leftNaveSelection.asObservable();

  // private headerNav = new BehaviorSubject<boolean>(false);
  // headerNav$ = this.headerNav.asObservable();
  
  constructor() { }

  setLeftNavExpanded(isExpanded: boolean) {
    this.leftNavExpanded.next(isExpanded);
  }

  setLeftNavSelection(navId: string | null, referrer: string | null = null, navigate: boolean = true) {
    let navObj: NavSettings = {
      navId,
      referrer,
      navigate      
    };
    this.leftNaveSelection.next(navObj);
  }  
}

export class NavSettings {
  navId: string | null = null;
  referrer: string | null = null;
  navigate: boolean = true;
}