import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { P3SolvedAutocompleteComponent } from '@p3solved/p3solved-ui';
import { DERuleField } from 'src/app/models/decision/rule';
import { PgDataTextFormGroup, pgDataTextRegexValidation, pgDataTextServerValidation } from 'src/app/models/form-groups';
import { NameValuePair } from 'src/app/models/name-value-pair';
import { PageDataFunctionLk, PageDataRegexValidator, PageDataServerValidation, PageDataText } from 'src/app/models/page-data';
import { Validation } from 'src/app/models/validation';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-page-data-textbox',
  templateUrl: './page-data-textbox.component.html',
  styleUrls: ['./page-data-textbox.component.scss']
})
export class PageDataTextboxComponent implements OnInit {

  @Input() data: PageDataText | null | undefined = null;
  @Input() pageFields: DERuleField[] = [];
  @Input() functions: PageDataFunctionLk[] = [];
  @Input() dupItems: NameValuePair[] = [];
  @Input() validations: Validation[] = [];
  @Input() serverVals: PageDataServerValidation[] = [];
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<any>();

  replaceResults: DERuleField[] = [];
  dbPreSel: NameValuePair[] = [{ name: 'None', value: '0' }, { name: 'Target', value: '1' }, { name: 'CSR', value: '2' }, { name: 'Both Target/CSR', value: '3' }];
  textModes: NameValuePair[] = [{ name: 'Single Line', value: '0' }, { name: 'Multi Line', value: '1' }, { name: 'Password', value: '2' }];
  textTypes: NameValuePair[] = [{ name: 'Text', value: 'text' }, { name: 'Tel', value: 'tel' }, { name: 'Number', value: 'number' }];
  masterRgxValidators: PageDataRegexValidator[] = [
    { id: -1, regex: '', name: 'NEW', valCss: '', invalCss: '' },
    { id: 1, regex: '.{6,}', name: '6 characters', valCss: '', invalCss: '' },
    { id: 2, regex: '(?=.*[A-Z])', name: '1 uppercase', valCss: '', invalCss: '' },
    { id: 3, regex: '(?=.*[0-9])', name: '1 number', valCss: '', invalCss: '' },
    { id: 4, regex: '(?=.*[a-z])', name: '1 lowercase', valCss: '', invalCss: '' },
  ];
  regexValList: PageDataRegexValidator[] = [];
  serverValList: PageDataServerValidation[] = [];
  textForm: FormGroup<PgDataTextFormGroup> = {} as FormGroup;
  textFormLoaded: boolean = false;
  emptyDataMsg: boolean = false;
  selectedFunction: PageDataFunctionLk | null = null;

  @ViewChild('acTags') acTags: P3SolvedAutocompleteComponent = {} as P3SolvedAutocompleteComponent;
  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (!this.data) {
      this.emptyDataMsg = true;
      return;
    }
    this.initForm();
    this.emptyDataMsg = false;
    this.textFormLoaded = true;
  }


  initForm() {
    this.textForm = this.formBuilder.group<PgDataTextFormGroup>({
      name: new FormControl<string>(this.data?.alias ?? '', { nonNullable: true }),
      objectId: new FormControl<string>(this.data?.name ?? '', { nonNullable: true, validators: [Validators.required] }),
      version: new FormControl<number | null>(this.data?.versionId ?? null, { nonNullable: true }),
      copy: new FormControl<string>(this.data?.copy ?? '', { nonNullable: true }),
      css: new FormControl<string>(this.data?.class ?? '', { nonNullable: true }),
      charLimit: new FormControl<string>(this.data?.mC ?? '', { nonNullable: true }),
      required: new FormControl<boolean>(this.data?.fReq == "1", { nonNullable: true }),
      duplicateId: new FormControl<string | null>(this.data?.dcId ?? null, { nonNullable: true }),
      label: new FormControl<string>(this.data?.lbl ?? '', { nonNullable: true }),
      dbField: new FormControl<string | null>(this.data?.dn ?? null, { nonNullable: true }),
      dbPreSel: new FormControl<string | null>(this.data?.for ?? null, { nonNullable: true }),
      order: new FormControl<string>(this.data?.cOrder ?? '', { nonNullable: true }),
      textMode: new FormControl<string>(this.data?.tm ?? '0', { nonNullable: true }),
      type: new FormControl<string>(this.data?.type ?? 'text', { nonNullable: true }),
      encrypt: new FormControl<boolean>(this.data?.crypt == "1", { nonNullable: true }),
      errorMessage: new FormControl<string>(this.data?.em ?? '', { nonNullable: true }),
      noMatchErr: new FormControl<string>(this.data?.dcEM ?? '', { nonNullable: true }),
      valType: new FormControl<number | null>(this.data?.v ?? null, { nonNullable: true }),
      valErrMsg: new FormControl<string>(this.data?.reem ?? '', { nonNullable: true }),
      disabled: new FormControl<boolean>(this.data?.disabled == "1", { nonNullable: true }),
      compVal: new FormControl<boolean>(this.data?.cvv == "1", { nonNullable: true }),
      compValValue: new FormControl<string>(this.data?.cv ?? '', { nonNullable: true }),
      rgxVal: new FormControl<boolean>(this.data?.crv == "1", { nonNullable: true }),
      serverVal: new FormControl<boolean>(this.data?.sv == "1", { nonNullable: true }),
      headerText: new FormControl<string>(this.data?.crht ?? '', { nonNullable: true }),
      holderCss: new FormControl<string>(this.data?.crhc ?? '', { nonNullable: true }),
      regexDDVal: new FormControl<number>(-1, { nonNullable: true }),
      serverValDD: new FormControl<number|null>(null, {nonNullable: true}),
      rgxVals: this.formBuilder.array([
        this.formBuilder.group<pgDataTextRegexValidation>({
          id: new FormControl<string>('', { nonNullable: true }),
          cId: new FormControl<string>('', { nonNullable: true }),
          regex: new FormControl<string>('', { nonNullable: true }),
          errMsg: new FormControl<string>('', { nonNullable: true }),
          validCss: new FormControl<string>('', { nonNullable: true }),
          invalidCss: new FormControl<string>('', { nonNullable: true }),
        })
      ]),
      serverVals: this.formBuilder.array([
        this.formBuilder.group<pgDataTextServerValidation>({
          id: new FormControl<string>('', { nonNullable: true }),
          cId: new FormControl<string>('', { nonNullable: true }),
          valId: new FormControl<string>('', { nonNullable: true }),
          name: new FormControl<string>('', { nonNullable: true }),
          errMsg: new FormControl<string>('', { nonNullable: true }),
        })
      ])
    });

    this.textForm.controls.rgxVals.removeAt(0);
    this.textForm.controls.serverVals.removeAt(0);

    let regExIds: number[] = [];
    if (this.data?.rgxValidations && this.data.rgxValidations.length > 0) {
      this.data.rgxValidations.forEach(rgx => {
        let rgxId = this.masterRgxValidators.find(m => m.regex == rgx.re)?.id;
        if (rgxId && rgxId > 0) regExIds.push(rgxId);
        this.textForm.controls.rgxVals.push(
          this.formBuilder.group<pgDataTextRegexValidation>({
            id: new FormControl<string>(rgx.id ?? '', { nonNullable: true }),
            cId: new FormControl<string>(rgx.cId ?? '', { nonNullable: true }),
            regex: new FormControl<string>(rgx.re ?? '', { nonNullable: true }),
            errMsg: new FormControl<string>(rgx.em ?? '', { nonNullable: true }),
            validCss: new FormControl<string>(rgx.vc ?? '', { nonNullable: true }),
            invalidCss: new FormControl<string>(rgx.ivc ?? '', { nonNullable: true }),
          })
        );
      });
      this.textForm.patchValue({
        rgxVal: true,
        headerText: this.data?.crht ?? '',
        holderCss: this.data?.crhc ?? ''
      });
    }
    this.regexValList = this.masterRgxValidators.filter(m => m.id == -1 || !regExIds.includes(m.id));

    let svrIds: number[] = [];
    if (this.data?.serverValidations && this.data.serverValidations.length > 0) {
      this.data.serverValidations.forEach(svr => {
        svrIds.push(+svr.vId);
        this.textForm.controls.serverVals.push(
          this.formBuilder.group<pgDataTextServerValidation>({
            id: new FormControl<string>(svr.id ?? '', { nonNullable: true }),
            cId: new FormControl<string>(svr.cId ?? '', { nonNullable: true }),
            valId: new FormControl<string>(svr.vId ?? '', { nonNullable: true }),
            name: new FormControl<string>(svr.vName ?? '', { nonNullable: true }),
            errMsg: new FormControl<string>(svr.em ?? '', { nonNullable: true }),
          })
        );
      });
      this.textForm.patchValue({
        serverVal: true
      });
    }
    this.serverValList = this.serverVals.filter(s => !svrIds.includes(s.serverValidationID));
  }

  saveTextBoxForm() {
    if (this.data) {
      this.data.alias = this.textForm.value.name ?? this.data.alias;
      this.data.name = this.textForm.value.objectId ?? this.data.name;
      this.data.versionId = this.textForm.value.version ?? this.data.versionId;
      this.data.copy = this.textForm.value.copy ?? this.data.copy;
      this.data.class = this.textForm.value.css ?? this.data.class;
      this.data.mC = this.textForm.value.charLimit ?? this.data.mC;
      this.data.fReq = this.textForm.value.required ? '1' : '0';
      this.data.dcId = this.textForm.value.duplicateId ?? this.data.dcId;
      this.data.lbl = this.textForm.value.label ?? this.data.lbl;
      this.data.dn = this.textForm.value.dbField ?? this.data.dn;
      this.data.for = this.textForm.value.dbPreSel ?? this.data.for;
      this.data.cOrder = this.textForm.value.order ?? this.data.cOrder;
      this.data.tm = this.textForm.value.textMode ?? this.data.tm;
      this.data.type = this.textForm.value.type ?? this.data.type;
      this.data.v = this.textForm.value.valType ?? this.data.v;
      this.data.crypt = this.textForm.value.encrypt ? '1' : '0';
      this.data.em = this.textForm.value.errorMessage ?? this.data.em;
      this.data.dcEM = this.textForm.value.noMatchErr ?? this.data.dcEM;
      this.data.disabled = this.textForm.value.disabled ? '1' : '0';
      this.data.cvv = this.textForm.value.compVal ? '1' : '0';
      this.data.cv = this.textForm.value.compValValue ?? this.data.cv;
      this.data.crht = this.textForm.value.headerText ?? this.data.crht;
      this.data.crhc = this.textForm.value.holderCss ?? this.data.crhc;
      this.data.sv = this.textForm.value.serverVal ? '1' : '0';
      this.data.crv = this.textForm.value.rgxVal ? '1' : '0';
      this.data.rgxValidations = [];
      this.data.serverValidations = []
    }

    let idx: number = 0;
    this.textForm.controls.rgxVals.controls.forEach(ctrl => {
      idx++;
      this.data?.rgxValidations.push({
        id: idx.toString(),
        cId: ctrl.value.cId ?? '',
        re: ctrl.value.regex ?? '',
        em: ctrl.value.errMsg ?? '',
        vc: ctrl.value.validCss ?? '',
        ivc: ctrl.value.invalidCss ?? ''
      });
    });

    idx = 0;
    this.textForm.controls.serverVals.controls.forEach(ctrl => {
      idx++;
      this.data?.serverValidations.push({
        id: idx.toString(),
        cId: ctrl.value.cId ?? '',
        vId: ctrl.value.valId ?? '',
        vName: ctrl.value.name ?? '',
        em: ctrl.value.errMsg ?? ''
      });
    });

    let saveObj = {
      element: 'textbox',
      data: this.data
    };

    this.onSave.emit(saveObj);
  }

  cancelForm() {
    this.onCancel.emit();
  }

  searchTags(event: any) {
    let search = this.pageFields.filter(pf => pf.fieldName.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    if (search && search.length) this.replaceResults = search;
    else this.replaceResults = [];
  }

  insertTag() {
    let tagTxt = this.acTags.value?.fieldName ?? '';
    if (tagTxt.length) {
      let newVal = this.textForm.value.copy + `{!${tagTxt}}`;
      this.textForm.patchValue({
        copy: newVal
      });
    }
    this.acTags.value = null;
    this.acTags.innerValue = null;
  }

  functionSelected(funcId: number) {
    this.selectedFunction = this.functions.find(f => f.pageFunctionID == funcId) ?? null;
  }

  insertFunc() {
    if (this.selectedFunction) {
      let newVal = this.textForm.value.copy + `.${this.selectedFunction.pageFunctionName}()`;
      this.textForm.patchValue({
        copy: newVal
      });
    }
  }

  addRegexValidator() {
    let rgx = this.regexValList.find(r => r.id === this.textForm.value.regexDDVal);
    if (rgx) {
      this.textForm.controls.rgxVals.push(
        this.formBuilder.group<pgDataTextRegexValidation>({
          id: new FormControl<string>((this.textForm.controls.rgxVals.length + 1).toString(), { nonNullable: true }),
          cId: new FormControl<string>(uuidv4(), { nonNullable: true }),
          regex: new FormControl<string>(rgx.regex ?? '', { nonNullable: true }),
          errMsg: new FormControl<string>(rgx.id > 0 ? rgx.name : '', { nonNullable: true }),
          validCss: new FormControl<string>(rgx.valCss ?? '', { nonNullable: true }),
          invalidCss: new FormControl<string>(rgx.invalCss ?? '', { nonNullable: true }),
        })
      );
      if (rgx.id > 0) {
      let rgxIdx = this.regexValList.indexOf(rgx);
      if (rgxIdx) this.regexValList.splice(rgxIdx, 1);
      }
      this.textForm.patchValue({
        regexDDVal: -1
      });
    }
  }

  deleteRegexVal(i: number) {
    if (this.textForm && this.textForm.value.rgxVals?.length) {
      let rgx = this.textForm.value.rgxVals[i];
      let mstrRgx = this.masterRgxValidators.find(m => m.regex == rgx.regex);
      if (mstrRgx && mstrRgx.id > 0 && this.regexValList.indexOf(mstrRgx) < 0) {
        this.regexValList.splice(mstrRgx.id, 0, mstrRgx);
      }
    }

    this.textForm.controls.rgxVals.removeAt(i);
  }

  addServerValidator() {
    let svr = this.serverValList.find(s => s.serverValidationID == this.textForm.value.serverValDD);
    if (svr) {
      this.textForm.controls.serverVals.push(
        this.formBuilder.group<pgDataTextServerValidation>({
          id: new FormControl<string>((this.textForm.controls.serverVals.length + 1).toString(), { nonNullable: true }),
          cId: new FormControl<string>(uuidv4(), { nonNullable: true }),
          valId: new FormControl<string>(svr.serverValidationID.toString() ?? '', { nonNullable: true }),
          name: new FormControl<string>(svr.serverValidationName ?? '', { nonNullable: true }),
          errMsg: new FormControl<string>(svr.errorMessage ?? '', { nonNullable: true }),
        })
      );
      let svrIdx = this.serverValList.indexOf(svr);
      if (svrIdx) this.serverValList.splice(svrIdx, 1);
      this.textForm.patchValue({
        serverValDD: null
      });
    }
  }

  deleteServerVal(j: number) {
    if (this.textForm && this.textForm.value.serverVals?.length) {
      let svr = this.textForm.value.serverVals[j];
      let mstrSvr = this.serverVals.find(s => s.serverValidationID == +(svr.valId ?? -100));
      if (mstrSvr && this.serverValList.indexOf(mstrSvr) < 0) {
        this.serverValList.splice(this.serverValList.length, 0, mstrSvr);
      }
    }

    this.textForm.controls.serverVals.removeAt(j);
  }

}
