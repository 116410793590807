import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TableColumn } from '@p3solved/p3solved-ui';
import { LookupModel } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ProviderService } from 'src/app/services/provider.service';

@Component({
  selector: 'app-offer-lookup-table',
  templateUrl: './offer-lookup-table.component.html',
  styleUrls: ['./offer-lookup-table.component.scss']
})
export class OfferLookupTableComponent implements OnInit {

  @Input() offers: any[] = [];
  filterFields: string[] = ['addDate', 'customerName', 'relationship', 'template', 'website'];
  yesno = this.lookupService.getYesNo();
  first: number = 0;
  @Input() columns: TableColumn[] = [];
  @Input() offerType: string = '';
  @Input() title: string = '';
  @Output() selected = new EventEmitter<any>();
  showEditModal = false;
  editModalHeader = '';
  customerGuid = new FormControl('');
  customers: LookupModel[] = [];
  offerForm: FormGroup = new FormGroup({});
  spName = '';
  selectedRow: any = {};

  constructor(private api: ApiService, 
    private lookupService: LookupService,
    private provider: ProviderService) { }

  ngOnInit(): void {
    this.provider.customerNames$.subscribe(data => {
      if (data && data.length){
        this.customers = [{desc: 'Any', id: '00000000-0000-0000-0000-000000000000'}];
        this.customers = this.customers.concat(data);
      }
    });
  }

  addNew(){
    this.selectedRow = {};
    this.showEditModal = true;
    if (this.offerType === 'amt'){
      this.offerForm = new FormGroup({
        customerGuid: new FormControl('', Validators.required),
        desc: new FormControl('', Validators.required),
        amt: new FormControl(0, Validators.required),
        isMinAmt: new FormControl(false, Validators.required),
        isDownPmt: new FormControl(false, Validators.required),
        id: new FormControl(0, Validators.required)
      });
      this.spName = 'spLookup_Offer_CreateUpdate_OfferAmt_Core';
    }

    if (this.offerType === 'apr'){
      this.offerForm = new FormGroup({
        customerGuid: new FormControl('', Validators.required),
        desc: new FormControl('', Validators.required),
        pct: new FormControl(0, Validators.required),
        id: new FormControl(0, Validators.required)
      });
      this.spName = 'spLookup_Offer_CreateUpdate_OfferApr_Core';
    }

    if (this.offerType === 'mdr'){
      this.offerForm = new FormGroup({
        customerGuid: new FormControl('', Validators.required),
        desc: new FormControl('', Validators.required),
        pct: new FormControl(0, Validators.required),
        id: new FormControl(0, Validators.required)
      });
      this.spName = 'spLookup_Offer_CreateUpdate_OfferMerchantDiscountRate_Core';
    }

    if (this.offerType === 'min_down_pct'){
      this.offerForm = new FormGroup({
        customerGuid: new FormControl('', Validators.required),
        desc: new FormControl('', Validators.required),
        pct: new FormControl(0, Validators.required),
        id: new FormControl(0, Validators.required)
      });
      this.spName = 'spLookup_Offer_CreateUpdate_OfferMinDownPct_Core';
    }

    if (this.offerType === 'term'){
      this.offerForm = new FormGroup({
        customerGuid: new FormControl('', Validators.required),
        desc: new FormControl('', Validators.required),
        sameAsCash: new FormControl(false, Validators.required),
        id: new FormControl(0, Validators.required)
      });
      this.spName = 'spLookup_Offer_CreateUpdate_OfferTerm_Core';
    }
    this.editModalHeader = `Add ${this.title} Offer`;
  }

  edit(rowData: any){
    this.selectedRow = rowData;
    this.showEditModal = true;
    this.initForm(rowData);
    this.editModalHeader = `Edit ${this.title} Offer`;
    this.selected.emit({
      item: rowData,
      offerType: this.offerType
    });
  }

  initForm(rowData: any) {
    if (this.offerType === 'amt'){
      this.offerForm = new FormGroup({
        customerGuid: new FormControl(rowData.customerGuid, Validators.required),
        desc: new FormControl(rowData.offerTermDesc, Validators.required),
        amt: new FormControl(rowData.offerTermAmt, Validators.required),
        isMinAmt: new FormControl(rowData.isMinAmt, Validators.required),
        isDownPmt: new FormControl(rowData.isDownPmt, Validators.required),
        id: new FormControl(rowData.offerAmtId, Validators.required)
      });
      this.spName = 'spLookup_Offer_CreateUpdate_OfferAmt_Core';
    }

    if (this.offerType === 'apr'){
      this.offerForm = new FormGroup({
        customerGuid: new FormControl(rowData.customerGuid, Validators.required),
        desc: new FormControl(rowData.offerAprDesc, Validators.required),
        pct: new FormControl(rowData.offerAprPct, Validators.required),
        id: new FormControl(rowData.offerAprId, Validators.required)
      });
      this.spName = 'spLookup_Offer_CreateUpdate_OfferApr_Core';
    }

    if (this.offerType === 'mdr'){
      this.offerForm = new FormGroup({
        customerGuid: new FormControl(rowData.customerGuid, Validators.required),
        desc: new FormControl(rowData.offerMdrDesc, Validators.required),
        pct: new FormControl(rowData.offerMdrPct, Validators.required),
        id: new FormControl(rowData.offerMdrId, Validators.required)
      });
      this.spName = 'spLookup_Offer_CreateUpdate_OfferMerchantDiscountRate_Core';
    }

    if (this.offerType === 'min_down_pct'){
      this.offerForm = new FormGroup({
        customerGuid: new FormControl(rowData.customerGuid, Validators.required),
        desc: new FormControl(rowData.offerMinDownPctDesc, Validators.required),
        pct: new FormControl(rowData.offerMinDownPct, Validators.required),
        id: new FormControl(rowData.offerMinDownPctId, Validators.required)
      });
      this.spName = 'spLookup_Offer_CreateUpdate_OfferMinDownPct_Core';
    }

    if (this.offerType === 'term'){
      this.offerForm = new FormGroup({
        customerGuid: new FormControl(rowData.customerGuid, Validators.required),
        desc: new FormControl(rowData.offerTermDesc, Validators.required),
        sameAsCash: new FormControl(rowData.isSameAsCash, Validators.required),
        id: new FormControl(rowData.offerTermId, Validators.required)
      });
      this.spName = 'spLookup_Offer_CreateUpdate_OfferTerm_Core';
    }
  }

  getColumns(): TableColumn[]{
    var cols = this.columns.filter(x => x.show);
    return cols;
  }

  getPctLabel(){
    if (this.offerType === 'amt'){
      return 'Offer Amount PCT';
    }

    if (this.offerType === 'apr'){
      return 'Offer APR PCT';
    }

    if (this.offerType === 'mdr'){
      return 'Offer MDR PCT';
    }

    if (this.offerType === 'min_down_pct'){
      return 'Offer Min Down PCT';
    }

    return '';
  }

  save(){
    if (!this.offerForm.valid) return;

    var json = JSON.stringify(JSON.stringify(this.offerForm.value));
    this.api.post(`offerlookups/create-update/${this.spName}`, json).subscribe(res => {
      this.showEditModal = false;
      var customer = this.customers.find(x => x.id === this.offerForm.value.customerGuid);
      if (customer){
        this.selectedRow.customerGuid = customer.id;
        this.selectedRow.customerName = customer.desc;
      }
      
      if (this.offerType === 'amt'){
        this.selectedRow.offerTermDesc = this.offerForm.value.desc;
        this.selectedRow.offerTermAmt = this.offerForm.value.amt;
        this.selectedRow.isMinAmt = this.offerForm.value.isMinAmt;
        this.selectedRow.isDownPmt = this.offerForm.value.isDownPmt;
      }
  
      if (this.offerType === 'apr'){
        this.selectedRow.offerAprDesc = this.offerForm.value.desc;
        this.selectedRow.offerAprPct = this.offerForm.value.pct;
      }
  
      if (this.offerType === 'mdr'){
        this.selectedRow.offerMdrDesc = this.offerForm.value.desc;
        this.selectedRow.offerMdrPct = this.offerForm.value.pct;
      }
  
      if (this.offerType === 'min_down_pct'){
        this.selectedRow.offerMinDownPctDesc = this.offerForm.value.desc;
        this.selectedRow.offerMinDownPct = this.offerForm.value.pct;
      }
  
      if (this.offerType === 'term'){
        this.selectedRow.offerTermDesc = this.offerForm.value.desc;
        this.selectedRow.isSameAsCash = this.offerForm.value.sameAsCash;
      }
    })
  }

  delete(row: any){

  }

}
