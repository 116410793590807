<div *ngIf="authenticated && !isIframe">
  <app-nav-header></app-nav-header>
  <div class="flex">
      <app-left-nav *ngIf="providerSelected"></app-left-nav>
      <div class="m-5 flex-grow-1">
          <router-outlet></router-outlet>
      </div>
    </div>
</div>
<p3solved-toast [key]="toastKey" [position]="toastPos" [preventDuplicates]="false"></p3solved-toast>
