<div [formGroup]="group" class="mb-1">
    <mat-form-field appearance="standard">
        <mat-label>{{label}}
            <fa-icon 
                *ngIf="group && group.controls[controlName]?.errors?.required" 
                [icon]="iconRequired" 
                size="xs" 
                [styles]="{'color': 'red'}" 
                [classes]="['ms-1']">
            </fa-icon>
        </mat-label>
        <input matInput [matDatepicker]="picker" [formControlName]="controlName">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
</div>
