import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SelectTime } from '../models/select-time';
import { State } from '../models/states';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  usStates(): State[] {
    return [
      { abbr: "AL", name: 'Alabama' },
      { abbr: "AK", name: 'Alaska' },
      { abbr: "AZ", name: 'Arizona' },
      { abbr: "AR", name: 'Arkansas' },
      { abbr: "CA", name: 'California' },
      { abbr: "CO", name: 'Colorado' },
      { abbr: "CT", name: 'Connecticut' },
      { abbr: "DE", name: 'Delaware' },
      { abbr: "DC", name: 'District Of Columbia' },
      { abbr: "FL", name: 'Florida' },
      { abbr: "GA", name: 'Georgia' },
      { abbr: "HI", name: 'Hawaii' },
      { abbr: "ID", name: 'Idaho' },
      { abbr: "IL", name: 'Illinois' },
      { abbr: "IN", name: 'Indiana' },
      { abbr: "IA", name: 'Iowa' },
      { abbr: "KS", name: 'Kansas' },
      { abbr: "KY", name: 'Kentucky' },
      { abbr: "LA", name: 'Louisiana' },
      { abbr: "ME", name: 'Maine' },
      { abbr: "MD", name: 'Maryland' },
      { abbr: "MA", name: 'Massachusetts' },
      { abbr: "MI", name: 'Michigan' },
      { abbr: "MN", name: 'Minnesota' },
      { abbr: "MS", name: 'Mississippi' },
      { abbr: "MO", name: 'Missouri' },
      { abbr: "MT", name: 'Montana' },
      { abbr: "NE", name: 'Nebraska' },
      { abbr: "NV", name: 'Nevada' },
      { abbr: "NH", name: 'New Hampshire' },
      { abbr: "NJ", name: 'New Jersey' },
      { abbr: "NM", name: 'New Mexico' },
      { abbr: "NY", name: 'New York' },
      { abbr: "NC", name: 'North Carolina' },
      { abbr: "ND", name: 'North Dakota' },
      { abbr: "OH", name: 'Ohio' },
      { abbr: "OK", name: 'Oklahoma' },
      { abbr: "OR", name: 'Oregon' },
      { abbr: "PA", name: 'Pennsylvania' },
      { abbr: "RI", name: 'Rhode Island' },
      { abbr: "SC", name: 'South Carolina' },
      { abbr: "SD", name: 'South Dakota' },
      { abbr: "TN", name: 'Tennessee' },
      { abbr: "TX", name: 'Texas' },
      { abbr: "UT", name: 'Utah' },
      { abbr: "VT", name: 'Vermont' },
      { abbr: "VA", name: 'Virginia' },
      { abbr: "WA", name: 'Washington' },
      { abbr: "WV", name: 'West Virginia' },
      { abbr: "WI", name: 'Wisconsin' },
      { abbr: "WY", name: 'Wyoming' }
    ]
  }

  times(startHour: number, endHour: number, minuteInterval: number): SelectTime[] {
    
    var times: SelectTime[] = []; // time array
    var tt = startHour; // start time
    var ap = ['AM', 'PM']; // AM-PM

    //loop to increment the time and push results in array
    for (var i = 0; tt < endHour * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = (tt % 60); // getting minutes of the hour in 0-55 format
      let displayHr = hh == 0 ? '12' : ("0" + (hh % 12)).slice(-2);


      let tempTime: SelectTime = {} as SelectTime;
      tempTime.display = displayHr + ':' + ("0" + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tempTime.value = ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2) + ':00'; 

      times.push(tempTime);
      
      tt = tt + minuteInterval;
    }

    return times
  }

  startDate(): Date {
    return new Date('1970-01-01T0:00:00');     
  }


  testFormValidators(form: FormGroup | FormArray): void {
    console.log('Test Form Validity start');
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key) as FormControl | FormGroup | FormArray;
      if (control instanceof FormControl) {
        if (control.errors) {
          for (const err in control.errors) {
            console.log(`${key} has error: ${err}`);
          }
        }
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.testFormValidators(control);
      } else {
        console.log("ignoring control: ", key)
      }
    });
    console.log('Test Form Validity complete');
  }

}