import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

export interface DataGridInfo {
  customerGuid?: string;
  campaignGuid?: string;
  targetGuid?: string;
  dataSourceName: string;
  columnList?: string;
  remoteIPAddress:string;
  pageSize?: number
}

export interface DataGridDelete {
  customerGuid?: string;
  campaignGuid?: string;
  targetGuid?: string;
  dataSourceName: string;
  keyFieldValues: string;
}

export interface DataGridResult {
  info?: any;
  data?: any;
  error?: any;
}


@Injectable({
  providedIn: 'root'
})
export class GridDataService {

  constructor(private apiService: ApiService) { }



  async getDataInfo(gridData: DataGridInfo): Promise<DataGridResult> {
    let body: any = {
      dataSourceName: gridData.dataSourceName,
      remoteIPAddress: gridData.remoteIPAddress ?? ""
    };
    if (gridData.customerGuid) body.customerGuid = gridData.customerGuid;
    if (gridData.campaignGuid) body.campaignGuid = gridData.campaignGuid;
    if (gridData.targetGuid) body.targetGuid = gridData.targetGuid;
    let result: DataGridResult = {} as DataGridResult;

    try {
      let info = await this.apiService.post(`dataGrid/grid-info`, body).toPromise();      
      result.info = info;
      result = await this.getDataFromSource(result, body, gridData.pageSize || 3000);
      return result;
    } catch (error) {
      result.error = error;
      return result;
    }    
  }

  private async getDataFromSource(result: DataGridResult, body: any, pageSize: number): Promise<DataGridResult> {
    body.columnList = result.info.columnNames.join(',');
    body.pageSize = pageSize;

    try {
      let data = await this.apiService.post(`dataGrid/grid-data`, body).toPromise();      
      if (data) result.data = data;
      return result;
    } catch (error) {
      result.error = error;
      return result;
    }
  }

  async deleteOneElement(row: DataGridDelete): Promise<boolean> {
    let body = {
      customerGuid: row.customerGuid,
      campaignGuid: row.campaignGuid,
      targetGuid: row.targetGuid,
      dataSourceName: row.dataSourceName,
      keyFieldValues: row.keyFieldValues
    }

    try {
      let result = await this.apiService.post(`datagrid/grid-delete-one`, body).toPromise();
      if (result.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {  
      console.error(error);    
      return false;
    }
  }
}
