import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';
import { LookupModel } from 'src/app/models/lookup-model';
import { iRangeForm, MatrixService } from 'src/app/services/matrix.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-de-expression-setup',
  templateUrl: './de-expression-setup.component.html',
  styleUrls: ['./de-expression-setup.component.scss']
})
export class DeExpressionSetupComponent implements OnInit {

  @Output() selectedField = new EventEmitter<any>();
  @Output() selectedDS = new EventEmitter<any>();
  @Input() title: string = '';
  @Input() name: string = '';
  @Input()
  get jsonModel(): any { return this._jsonModel; }
  set jsonModel(jsonModel: any) {
    this._jsonModel = jsonModel;
    this.initForm();
  }
  private _jsonModel: any;
  obj: any;
  dsList: LookupModel[] = [];
  fieldList: LookupModel[] = [];
  searchFieldList: LookupModel[] = [];
  rangeList: LookupModel[] = [];
  expForm: FormGroup = new FormGroup({});
  annouceSubscription: Subscription | undefined;
  fieldChangeSubscription: Subscription | undefined;
  id = '';

  constructor(private matrixService: MatrixService,
    private portfolioService: PortfolioService,
    private toastService: ToastService,
    private fb: FormBuilder) {
    for (var i = 0; i < 10; i++) {
      var val = i + 1;
      this.rangeList.push({ id: val.toString(), desc: val.toString() });
    }

    this.id = Math.floor(Math.random() * 100000).toString();
  }

  ngOnInit(): void {
    this.matrixService.expressionDataSource$.subscribe(x => {
      this.dsList = x || [];
      var dsId = this.expForm.value.dsID;
      if (!dsId && this.dsList[0]?.id) {
        this.expForm.patchValue({ dsID: this.dsList[0].id });
      }
      this.dsSelected();
    });
    this.matrixService.expressionFields$.subscribe((x: any) => {
      var dsId = this.expForm.value.dsID;
      if (dsId && x[dsId]) {
        this.fieldList = [];
        var arr = x[dsId] || [];
        arr.forEach((item: any) => {
          this.fieldList.push(item);
        });
        // this.fieldList.unshift({ id: '0', desc: 'Select' });
        this.fieldSelected();
      }
    });

    this.annouceSubscription = this.matrixService.actionAnnounced$.subscribe(action => {
      if (action == 'build') {
        this.getRanges(true);
      }

      if (action == 'save') {
        this.save();
      }
    });

    this.fieldChangeSubscription = this.expForm?.get("field")?.valueChanges.subscribe(res => {
      var id = res?.id || 0;
      var desc = res?.desc || '';
      this.expForm.patchValue({fieldID: id, fieldName: desc});
      this.selectedField.emit({ [this.name]: desc });
    });
  }

  initForm() {
    if (this.jsonModel?.num) {
      this.jsonModel.num = this.jsonModel?.num == 'true' ? true : false;
    }
    this.expForm = this.fb.group({
      dsID: [this.jsonModel?.dsID, Validators.required],
      fieldID: [this.jsonModel?.fieldID, Validators.required],
      fieldName: [this.jsonModel?.fieldName],
      field: [],
      num: [this.jsonModel?.num, Validators.required],
      ranges: [this.jsonModel?.ranges, Validators.required],
      rangeForms: this.fb.array([])
    });
    this.dsSelected();
    this.initRangeForms();
    this.getRanges(false);
  }

  get rangeForms() {
    return this.expForm.controls["rangeForms"] as FormArray;
  }

  dsSelected(val: any = null, resetField = false) {
    var dsId = val || this.expForm.value.dsID;
    if (dsId) {
      this.expForm.patchValue({ dsID: dsId });
      var custGuid = this.portfolioService.getGuid();
      this.matrixService.loadFields(custGuid, dsId);
      var d = this.dsList.find(x => x.id == dsId);
      if (d){
        // this.expForm.patchValue({field: d});
        this.selectedDS.emit({ [this.name]: d.desc });
      }
    }

    if (resetField){
      this.expForm.patchValue({field: ''});
    }
  }

  fieldSelected(val: any = null) {
    var fieldId = val || this.expForm.value.fieldID;
    this.expForm.patchValue({ fieldID: fieldId });
    var f = this.fieldList.find(x => x.id == fieldId);
    if (f) {
      this.expForm.patchValue({field: f});
      // this.expForm.patchValue({ fieldName: f.desc });
      this.selectedField.emit({ [this.name]: f.desc });
    }
  }

  getRanges(showError = true) {
    if (this.rangeForms.valid) {
      this.matrixService.build(this.name, this.rangeForms.value);
    } else {
      this.rangeForms.markAllAsTouched();
      this.rangeForms.markAsDirty();
      if (showError) {
        let message: Message = {
          severity: 'error',
          summary: 'Error',
          detail: `${this.title} form is invalid. Make sure all fields are entered`
        };
        this.toastService.add(message);
      }
    }
  }

  save() {
    if (this.expForm.valid) {
      const { rangeForms, ...obj } = this.expForm.value;
      this.matrixService.save(this.name, obj)
    } else {
      this.expForm.markAllAsTouched();
      this.expForm.markAsDirty();
      let message: Message = {
        severity: 'error',
        summary: 'Error',
        detail: `${this.title} form is invalid. Make sure all fields are entered`
      };
      this.toastService.add(message);
    }
  }

  initRangeForms(maxRange: any = null) {
    this.initJsonModel();
    if (maxRange) {
      this.jsonModel.range = this.rangeForms.value;
    }
    var max = Number(maxRange || this.expForm.value.ranges);
    while (this.rangeForms.length !== 0) {
      this.rangeForms.removeAt(0)
    }
    this.rangeForms.setValue([]);
    if (this.jsonModel?.range?.min || this.jsonModel?.range?.max || this.jsonModel?.range?.ix)
      this.jsonModel.range = [this.jsonModel?.range];

    for (var i = 1; i <= max; i++) {
      var exist = (this.jsonModel?.range || []).find((x: iRangeForm) => x.ix == i);
      this.rangeForms.push(this.getRangeForm(exist || { min: '', max: '', ix: i }));
    }
  }

  getRangeForm(range: iRangeForm) {
    return this.fb.group({
      min: new FormControl(range.min, Validators.required),
      max: new FormControl(range.max, Validators.required),
      ix: new FormControl(range.ix),
    });
  }

  searchFields = (search: any) => {
    let query = search.query.toLowerCase();
    this.searchFieldList = [];
    if (query && query.length && this.fieldList && this.fieldList.length) {
      this.searchFieldList = this.fieldList.filter(x => x.desc.toLocaleLowerCase().includes(query));
    }else{
      this.searchFieldList = this.fieldList;
    }
  }

  initJsonModel() {
    if (!this.jsonModel) {
      this.jsonModel = {
        dsID: "",
        fieldID: "",
        fieldName: "",
        num: true,
        range: [],
        ranges: "1"
      }
    }
  }

  ngOnDestroy() {
    if (this.annouceSubscription)
      this.annouceSubscription.unsubscribe();
      this.fieldChangeSubscription?.unsubscribe();
  }

}
