<div class="card">
    <div class="card-body">
        <form [formGroup]="commForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <app-cc-input
                        controlName="notifyFromEmail"
                        [group]="commForm"
                        label="Notification From Email">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <app-cc-input
                        controlName="notifyFromName"
                        [group]="commForm"
                        label="Notification From Display Name">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <app-cc-input
                        controlName="notifyReplyEmail"
                        [group]="commForm"
                        label="Notification Reply Email">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <app-cc-input
                        controlName="notifyReplyName"
                        [group]="commForm"
                        label="Notification Reply Display Name">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <app-cc-input
                        controlName="contactEmail"
                        [group]="commForm"
                        label="Contact Phone">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <app-cc-input
                        controlName="contactFax"
                        [group]="commForm"
                        label="Contact Fax">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <app-cc-select 
                        controlName="commStartTime"
                        [group]="commForm"
                        [options]="commTimes"
                        label="Communication Start Time (CST)">
                    </app-cc-select>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <app-cc-select 
                        controlName="commEndTime"
                        [group]="commForm"
                        [options]="commTimes"
                        label="Communication End Time (CST)">
                    </app-cc-select>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <app-cc-select 
                        controlName="commSmtpProfile"
                        [group]="commForm"
                        [options]="smtpProfiles"
                        label="Communication SMTP Profile">
                    </app-cc-select>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <app-cc-select 
                        controlName="notifySmtpProfile"
                        [group]="commForm"
                        [options]="smtpProfiles"
                        label="Notifications SMTP Profile">
                    </app-cc-select>
                </div>
            </div>
            <h3 class="mb-3">SMS
                <span class="ms-4" [formGroup]="commForm">
                    <mat-checkbox 
                        controlName="enableSms" >Enable SMS
                    </mat-checkbox>
                </span>
            </h3>

            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="smsApiKey"
                        [group]="commForm"
                        label="Api Key">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-2">
                    <app-cc-input
                        controlName="smsOrgCode"
                        [group]="commForm"
                        label="Org Code">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-2">
                    <app-cc-input
                        controlName="smsOrgCodeMobile"
                        [group]="commForm"
                        label="Org Code Mobile">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-2">
                    <app-cc-input
                        controlName="smsOrgCodeComms"
                        [group]="commForm"
                        label="Org Code Comms">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-2">
                    <app-cc-input
                        controlName="smsSbtOrgCode"
                        [group]="commForm"
                        label="SBT Org Code Help">
                    </app-cc-input>
                </div>
            </div>
            <button class="btn btn-primary btn-main btn-large" type="submit">Save Communication</button>
        </form>
    </div>
</div>
