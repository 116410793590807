import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  constructor() { }


  reduceBcList(menu: MenuItem[], label: string | undefined): MenuItem[] {
    if (!label) return menu;
    let item = menu.find(b => b.label === label);
    if (item) {
      let index = menu.indexOf(item);
      item.command = undefined;
      menu.splice(index, 1, item);
      let bcLen = menu.length;
      for (let i = 1; i < bcLen - index; i++) {
        menu.pop();
      }
    }
    return menu;
  }

  findExecuteBcCommand(menu: MenuItem[], label: string | undefined) {
    if (!label) return;
    let item = menu.find(b => b.label === label);
    if (item && item.command) {
      item.command();
    }
  }

  removeBcCommand(menu: MenuItem[], label: string | undefined) {
    if (!label) return;
    let item = menu.find(b => b.label === label);
    if (item) {
      let index = menu.indexOf(item);
      item.command = undefined;
      menu.splice(index, 1, item);
    }
  }
}
