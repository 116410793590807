<div *ngIf="emptyDataMsg">
    <div class="text-2xl cds-error-text">ERROR! Unable to find data.</div>
</div>

<div *ngIf="!emptyDataMsg">
    <form *ngIf="labelFormLoaded" [formGroup]="labelForm" (ngSubmit)="saveLabelForm();">
        <div class="grid pr-4">
            <div class="col-6">
                <p3solved-form-text id="txtNm" placeholder="Name" formControlName="name" [disabled]="data.versionId != 0"></p3solved-form-text>
            </div>
            <div class="col-6">
                <p3solved-form-text id="txtObjId" placeholder="Object ID" formControlName="objectId" [disabled]="data.versionId != 0"></p3solved-form-text>
            </div>
           
            <div class="col-12">
                <p3solved-form-textarea id="txtAcopy" placeholder="Copy" [rows]="5" formControlName="label"></p3solved-form-textarea>
            </div>
           
            <div class="col-12 grid align-items-center">
                <div class="col-5">
                    <span class="p-float-label">
                        <p-autoComplete #acTags inputId="replTagAC" [showEmptyMessage]="true"
                            [suggestions]="replaceResults" (completeMethod)="searchTags($event)"
                            field="fieldName" styleClass="w-12" inputStyleClass="border-noround w-12"
                            [dropdown]="false" [completeOnFocus]="true" [forceSelection]="true"></p-autoComplete>
                        <label for="replTagAC">Replace Tags</label>
                    </span>
                </div>
                <div class="col-1 flex">
                    <span class="cds-text-color cursor-pointer" (click)="insertTag()">Insert</span>
                </div>
                <div class="col-5">
                    <p3solved-dropdown id="ddJSFunc" placeholder="Functions" [items]="functions" 
                        optionLabel="pageFunctionName" optionValue="pageFunctionID" 
                        (optionSelected)="functionSelected($event)"></p3solved-dropdown>
                </div>
                <div class="col-1 flex">
                    <span class="cds-text-color cursor-pointer" (click)="insertFunc()">Insert</span>
                </div>
            </div>
            
            <div class="col-6">
                <p3solved-form-text id="txtCss" placeholder="Css Class" formControlName="css"></p3solved-form-text>
            </div>
            <div class="col-3">
                <p3solved-input-number id="txtLimit" placeholder="Character Limit" formControlName="charLimit"
                    mode="decimal" [useGrouping]="false"></p3solved-input-number>                
            </div>
            <div class="col-1 align-self-center">
                <p-checkbox [formControl]="$any(labelForm.controls['required'])" [binary]="true" label="Required"></p-checkbox>
            </div>
        <div class="col-2 flex flex-row-reverse">
            <p3solved-button id="btnS" label="Save" buttonType="submit"></p3solved-button>
            <p3solved-button id="btnCncl" label="Cancel" class="px-4 outline" (buttonClick)="cancelForm()"></p3solved-button>
        </div>
       
        </div>
    </form>
</div>