export class ProviderContentCampaign {
    providerTypeDesc: string = '';
    campaignName: string = '';
    campaignID: number = 0;
    campaignGUID: string = '';
    customerGUID: string = '';
}

export class ProviderContentUpload {
    requestGuid: string = '';
    sourceCampaignGuid: string = '';
    customers: ProviderContentUploadCustomer[] = [];
}

export class ProviderContentUploadCustomer {
    customerGuid: string = '';
    htmlPages: boolean = false;
    alerts: boolean = false;
    pageData: boolean = false;
    deRules: boolean = false;
    offerMatrix: boolean = false;
}