<div *ngIf="!guidsLoaded">
    <div class="text-3xl text-red-500">
        You MUST select a Customer and Campaign before accessing this page.
    </div>
</div>

<div *ngIf="guidsLoaded && showDashboard">
  <span class="text-2xl font-medium">Compliance & Audit</span>
  <p-breadcrumb [model]="bcItems"></p-breadcrumb>
  <div class="mt-4">
    <div class="cds-card p-4">
      <div class="grid">
        <div class="col-6">
          <span class="text-2xl">Your Compliance & Audit Requests</span>
        </div>
        <div *ngIf="hasEditFeature" class="col-6 flex flex-row-reverse">
          <p3solved-button
            id="btnNew"
            label="Create New"
            (buttonClick)="newRequest()"
          ></p3solved-button>
        </div>
        <div class="col-12">
          <app-custom-grid
            #cgCompDB
            id="cgCompDB"
            [overrideCustomer]="false"
            DataSourceName="dsAdmin_Compliance_RequestsByUser"
          >
          </app-custom-grid>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="guidsLoaded && showCreate">
  <span class="text-2xl font-medium">Compliance & Audit</span>
  <p-breadcrumb [model]="bcItems"></p-breadcrumb>
  <div class="mt-4">
    <div class="cds-card p-4">
      <div class="grid">
        <div class="col-12">
          <span class="w-12 text-2xl font-bold">Data by Loan</span>
        </div>
        <div class="col-10">
          <p3solved-form-text
            id="txtLoanSrch"
            placeholder="Loan Number(s)"
            [(ngModel)]="loanNumsSearch"
          >
          </p3solved-form-text>
        </div>
        <div class="col-2 align-self-center">
          <p3solved-button
            id="btnLoanSrch"
            label="Search"
            class="px-4 outline"
            [disabled]="!loanNumsSearch.length"
            (buttonClick)="searchLoans()"
          ></p3solved-button>
        </div>
        <div *ngIf="dataByLoan.length > 0" class="col-12 pt-4">
          <p3solved-table
            id="tblDataByLoan"
            [data]="dataByLoan"
            [showCurrentPageReport]="false"
            [showColToggle]="false"
            [allowCustomCRUD]="false"
            [columns]="loanColumns"
            [rows]="100"
            [allowDownload]="true"
            [allowSearch]="false"
            [allowEdit]="false"
            [allowRowSelect]="false"
            (deleteRow)="deleteLoan($event)"
          >
          </p3solved-table>
        </div>
        <div *ngIf="dataByLoan.length > 0" class="col-12 pt-4 flex flex-wrap">
          <div *ngFor="let check of blnChecksLoans" class="col-3">
            <p-checkbox
              [binary]="true"
              label="{{ check.text }}"
              [(ngModel)]="check.value"
            ></p-checkbox>
          </div>
          <div class="col-12 flex flex-row-reverse">
            <p3solved-button
              id="btnLoanDownload"
              label="Download"
              class="px-4"
              (buttonClick)="loanDownload()"
            ></p3solved-button>
          </div>
        </div>

        <div class="col-12 pt-8">
          <span class="w-12 pt-5 text-2xl font-bold">Data by Criteria</span>
        </div>
        <div class="col-12">
          <form
            *ngIf="criteriaFormLoaded"
            [formGroup]="criteriaForm"
            (ngSubmit)="submitCriteriaForm()"
          >
            <div class="grid">
              <div class="col-6">
                <p3solved-dropdown
                  id="State"
                  placeholder="State"
                  [items]="lookups.states"
                  [showClear]="true"
                  optionLabel="desc"
                  optionValue="id"
                  formControlName="state"
                ></p3solved-dropdown>
              </div>
              <div class="col-6">
                <p3solved-dropdown
                  id="LegalStatus"
                  placeholder="Legal Status"
                  [items]="lookups.legalStatuses"
                  [showClear]="true"
                  optionLabel="desc"
                  optionValue="id"
                  formControlName="legal"
                ></p3solved-dropdown>
              </div>
              <div class="col-6">
                <p-listbox
                  [options]="lookups.noteCategories"
                  [multiple]="true"
                  formControlName="commentCodes"
                  optionLabel="desc"
                  optionValue="id"
                  listStyleClass="w-full h-20rem"
                ></p-listbox>
              </div>
              <div class="col-6 flex flex-wrap">
                <div class="w-12">
                  <p3solved-dropdown
                    id="loanStatus"
                    placeholder="Loan Status"
                    [items]="lookups.loanStatuses"
                    [showClear]="true"
                    optionLabel="desc"
                    optionValue="id"
                    formControlName="loanStatus"
                  ></p3solved-dropdown>
                </div>
                <div class="w-12">
                  <p3solved-dropdown
                    id="AppStatus"
                    placeholder="Applications Status"
                    [items]="lookups.loanAppStatuses"
                    [showClear]="true"
                    optionLabel="desc"
                    optionValue="id"
                    formControlName="appStatus"
                  ></p3solved-dropdown>
                </div>
                <div class="w-12">
                  <p3solved-dropdown
                    id="Channel"
                    placeholder="Channel"
                    [items]="lookups.channels"
                    [showClear]="true"
                    optionLabel="desc"
                    optionValue="id"
                    formControlName="channel"
                  ></p3solved-dropdown>
                </div>
              </div>

              <div class="col-12 pt-4 flex flex-wrap align-self-center">
                <div class="w-12 text-xl font-bold">Date Range</div>
                <div class="w-4">
                  <p3solved-datepicker
                    id="dtFrom"
                    placeholder="Start"
                    panelStyleClass="dp-max"
                    [maxDate]="today"
                    formControlName="startDate"
                  >
                    <div
                      class="w-12 my-1 p-error text-xs"
                      *ngIf="
                        !criteriaForm.controls.startDate.pristine &&
                        criteriaForm.controls.startDate.errors
                      "
                    >
                      <p
                        *ngIf="
                          criteriaForm.controls.startDate.errors
                            ?.invalidDateRange
                        "
                      >
                        Start Date must be before End Date.
                      </p>
                    </div>
                  </p3solved-datepicker>
                </div>
                <div class="w-2 px-4 align-self-center text-center">
                  <span class="font-bold text-xl">TO</span>
                </div>
                <div class="w-4">
                  <p3solved-datepicker
                    id="dtTo"
                    placeholder="End"
                    panelStyleClass="dp-max"
                    [maxDate]="today"
                    formControlName="endDate"
                  >
                    <div
                      class="w-12 my-1 p-error text-xs"
                      *ngIf="
                        !criteriaForm.controls.startDate.pristine &&
                        criteriaForm.controls.startDate.errors
                      "
                    >
                      <p
                        *ngIf="
                          criteriaForm.controls.startDate.errors
                            ?.invalidDateRange
                        "
                      >
                        Start Date must be before End Date.
                      </p>
                    </div>
                  </p3solved-datepicker>
                </div>
                <div class="w-2 pl-5 align-self-center">
                  <p3solved-button
                    id="btnFormSubmit"
                    buttonType="submit"
                    class="outline"
                    label="Search"
                    [disabled]="!criteriaForm.valid"
                  ></p3solved-button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div *ngIf="dataByCriteria.length > 0" class="col-12 pt-4">
          <p3solved-table
            id="tblDataByCriteria"
            [data]="dataByCriteria"
            [showCurrentPageReport]="false"
            [showColToggle]="false"
            [allowCustomCRUD]="false"
            [columns]="criteriaColumns"
            [rows]="100"
            [allowDownload]="true"
            [allowSearch]="false"
            [allowEdit]="false"
            [allowRowSelect]="false"
            [allowDelete]="false"
          >
          </p3solved-table>
        </div>
        <div
          *ngIf="dataByCriteria.length > 0"
          class="col-12 pt-4 flex flex-wrap"
        >
          <div *ngFor="let check of blnChecksCriteria" class="col-3">
            <p-checkbox
              [binary]="true"
              label="{{ check.text }}"
              [(ngModel)]="check.value"
            ></p-checkbox>
          </div>
          <div class="col-12 flex flex-row-reverse">
            <p3solved-button
              id="btnLoanDownload"
              label="Download"
              class="px-4"
              (buttonClick)="criteriaDownload()"
            ></p3solved-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog
  [key]="pageCompDBConfirmKey"
  [style]="{ width: '45vw' }"
  [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-outlined"
  styleClass="text-xl"
>
</p-confirmDialog>
