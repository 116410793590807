import { Injectable } from '@angular/core';
import { ThemeService, ThemeStyle } from '@p3solved/p3solved-ui';
import { BehaviorSubject } from 'rxjs';
import { AppConfigs } from '../configs/app-config';
import { ActivatedIds } from '../models/activated-ids';
import { PortfolioItem } from '../models/customer-item';
import { PortfoliosModel } from '../models/portfolios-model';
import { ApiService } from './api.service';
import { CampaignService } from './campaign.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
 
  private customerSettings = new BehaviorSubject<any | null>(null);
  private portfolios = new BehaviorSubject<PortfoliosModel | null>(null);
  private selectedPortfolioSource = new BehaviorSubject<PortfolioItem | null>(null);
  private _activatedIds = new BehaviorSubject<ActivatedIds>({});
  portfolio$ = this.selectedPortfolioSource.asObservable();
  customerSettings$ = this.customerSettings.asObservable();
  portfolios$ = this.portfolios.asObservable();
  activatedIds$ = this._activatedIds.asObservable();
  cachedPortfolios: PortfoliosModel | null = null;

  constructor(
    private apiService: ApiService,
    private campaignService: CampaignService,
    private themeService: ThemeService
    ) {
      let selPort = localStorage.getItem(AppConfigs.selectedPortfolioStorageKey); 
      if (selPort) {
        this.portfolioSelected(JSON.parse(selPort));
      }
    }

  updateActivatedIds(propname: keyof ActivatedIds, propValue: any){
    var obj = this._activatedIds.getValue();
    obj[propname] = propValue;
    this._activatedIds.next(obj);
  }

  updateActivatedIdsMany(arr: {propname: keyof ActivatedIds, propValue: any}[]){
    var obj = this._activatedIds.getValue();
    arr.forEach(item => {
      obj[item.propname] = item.propValue;
    });
    this._activatedIds.next(obj);
  }

  portfolioSelected(item: PortfolioItem, campaignGuid?: string) {
    localStorage.setItem(AppConfigs.selectedPortfolioStorageKey, JSON.stringify(item));
    if (item.cssStyles && item.cssStyles.length) {
      let styles = JSON.parse(item.cssStyles) as ThemeStyle[];
      this.themeService.updateThemeStyles(styles);
    }
    else {
      this.themeService.applyDefaultStyles();
    }
    this.selectedPortfolioSource.next(item);
    this.loadCustomerSettings(item);
    this.campaignService.getCampaigns(item.customerGuid, campaignGuid);
  }

  loadCustomerSettings(item: PortfolioItem){
    this.apiService.get(`app/customer-settings2/${item.customerGuid}/${item.branchGuid}`)
        .subscribe((res: any) => {
          this.customerSettings.next(res || {});
        },
          (err: any) => {
          }
        );
  }

 setPortfolioByGuid(customerGuid: any, campaignGuid?: string): boolean {
    if (this.cachedPortfolios && this.cachedPortfolios.portfolios) {
      let item = this.cachedPortfolios.portfolios.find(p => p.customerGuid == customerGuid);
      if (item) {
        this.portfolioSelected(item, campaignGuid);
        return true;
      }
      else return false;
    }
    else return false;
  }

  setPortforlioFromStorage(){
    this.portfolio$.subscribe(p => {
      if (!p || !p.customerGuid){
        var stored = localStorage.getItem(AppConfigs.selectedPortfolioStorageKey);
        if (stored){
          var portfolio = JSON.parse(stored);
          if (portfolio && portfolio.customerGuid){
            this.portfolioSelected(portfolio);
          }
        }
      }
    })
  }

  getGuid(){
    var val = this.selectedPortfolioSource.getValue();
    return val?.customerGuid;
  }

  setPortfolios() {
    var result = this.portfolios.getValue();
    if (result && result.portfolios && result.portfolios.length) {
      return;
    }

      result = new PortfoliosModel();
      result.loading = true;
      this.portfolios.next(result);
      this.apiService.get(`user/portfolios`)
        .subscribe((res: any) => {
          var list = res || [];
          if (list.length) {
            list.forEach((item: PortfolioItem) => {
              if (item.adminIcon.indexOf('bytearchive') !== -1)
                item.adminIcon = 'bytepay.ico';

              item.dashboardUrl = `/dashboard/${item.customerGuid}`;
              item.adminIcon = (item.adminIcon && item.adminIcon.indexOf('blank') === -1)
                ? `/assets/images/${item.adminIcon}`
                : '';
            });
          }
          result = new PortfoliosModel();
          result.loading = false;
          result.portfolios = list;
          this.cachedPortfolios = list;
          result.error = list.length ? '' : 'No portfolio found for authenticated user';
          this.portfolios.next(result);
        },
          (err: any) => {
            result = new PortfoliosModel();
            result.loading = false;
            result.error = 'Error occured while getting portfolios';
            this.portfolios.next(result);
          }
        );
  }
}
