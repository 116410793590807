import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-decision-black-list',
  templateUrl: './decision-black-list.component.html',
  styleUrls: ['./decision-black-list.component.scss']
})
export class DecisionBlackListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
