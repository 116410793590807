export class CampaignNotifications {
    campaignGuid: string = '';
    customerGuid: string = '';
    emailSubject: string = '';
    isGeneric: boolean = false;
    isMarketing: boolean = false;
    notificationName: string = '';
    notificationGuid: string = '';
    rowCount: number = 0;
    rowNumber: number = 0;
    type: string = '';    
}

export class CampaignNotification {
    campaignID: number = 0;
    contentType: string = '';
    emailAttachement: string = '';
    emailBCC: string = '';
    emailBody: string = '';
    emailCC: string = '';
    emailSubject: string = '';
    isMarketing: boolean = false;
    isNew: boolean = false;
    notificationGUID: string = '';
    notificationID: number = 0;
    notificationName: string = '';
    notificationType: string = '';
    notificationTypeID: number = 0;
    postData: string = '';
    requestHeaders: string = '';
    requestType: string = '';
    requestURL: string = '';
    responseValidation: string = '';
    returnPath: string = '';
    sMTPProfileID: number | null = null;
    toEmail: string = '';
    versionGUID: string = '';    
}

export class CampaignNotificationSave {
    customerGuid: string = '';
    campaignGuid: string = '';
    notificationGuid: string | null = null;
    notificationTypeId: number = 0;
    notificationName: string = '';
    smtpProfileId: number | null = null;
    returnPath: string = '';
    toEmail: string = '';
    emailCc: string | null = null;
    emailBcc: string | null = null;
    emailSubject: string | null = null;
    emailBody: string | null = null;
    emailAttachment: string | null = null;
    requestType: string | null = null;
    requestUrl: string | null = null;
    requestHeaders: string | null = null;
    contentType: string | null = null;
    postData: string | null = null;
    responseValidation: string | null = null;
    sendTest: boolean = false;
    isMarketing: boolean = false;
    massEmailGuid: string | null = null;
    versionGuid: string = '';
}

export class CampaignNotificationSelect {
    campaignID: number = 0;
    contentType: string = '';
    emailAttachement: string = '';
    emailBCC: string = '';
    emailBody: string = '';
    emailCC: string = '';
    emailSubject: string = '';
    isMarketing: boolean = false;
    isGeneric: boolean = false;
    notificationGUID: string = '';
    notificationID: number = 0;
    notificationName: string = '';
    notificationType: string = '';
    notificationTypeID: number = 0;
    postData: string = '';
    requestHeaders: string = '';
    requestType: string = '';
    requestURL: string = '';
    responseValidation: string = '';
    returnPath: string = '';
    sMTPProfileID: number | null = null;
    toEmail: string = '';
    versionId: number = -1;  
}