import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { PortfolioItem } from 'src/app/models/customer-item';
import { CustomField, CustomFieldCase, CustomFieldMatrix, CustomFieldSettings } from 'src/app/models/decision/custom-field';
import { CustomFieldLookups, RuleDataSourceField } from 'src/app/models/decision/custom-field-lookups';
import { NumValDropDown } from 'src/app/models/dropdown';
import { CustomFieldCaseFormGroup, CustomFieldMatrixFormGroup, CustomFieldNameFormGroup, MinMaxGroup, MinMaxValueGroup, XYResultFormGroup } from 'src/app/models/form-groups';
import { NameValuePair } from 'src/app/models/name-value-pair';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NavService, NavSettings } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-decision-custom-fields',
  templateUrl: './decision-custom-fields.component.html',
  styleUrls: ['./decision-custom-fields.component.scss']
})
export class DecisionCustomFieldsComponent implements OnInit, OnDestroy {

  editFeatureCode: string = 'DECISION_ENGINE_CUSTOM_FIELDS_LIST_EDIT';
  hasEditFeature: boolean = false;
  subscriptions: Subscription[] = [];
  loading: boolean = true;
  hasError: boolean = false;
  showList: boolean = false;
  showEdit: boolean = false;
  showMatrixTable: boolean = false;
  customFieldFormsLoaded: boolean = false;

  bcItems: MenuItem[] = [];
  first: number = 0;
  fields: CustomField[] = [];
  fieldTypes: NameValuePair[] = [
    { name: 'Matrix', value: 'matrix' }, { name: 'Case', value: 'case' }
  ];

  rangeNums: NumValDropDown[] = [
    { text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 },
    { text: '8', value: 8 },
    { text: '9', value: 9 },
    { text: '10', value: 10 },
    { text: '11', value: 11 },
    { text: '12', value: 12 },
    { text: '13', value: 13 },
    { text: '14', value: 14 },
    { text: '15', value: 15 },
    { text: '16', value: 16 },
    { text: '17', value: 17 },
    { text: '18', value: 18 },
    { text: '19', value: 19 },
    { text: '20', value: 20 }
  ];

  portfolio: PortfolioItem | null = null;

  customFieldTitle: string = 'Add';
  selectedField: CustomField | null = null;
  customFieldNameForm: FormGroup<CustomFieldNameFormGroup> = {} as FormGroup;
  customFieldMatrixForm: FormGroup<CustomFieldMatrixFormGroup> = {} as FormGroup;
  customFieldCaseForm: FormGroup<CustomFieldCaseFormGroup> = {} as FormGroup;

  private parser = new DOMParser();
  lookups: CustomFieldLookups = {} as CustomFieldLookups;

  vertDataFields: RuleDataSourceField[] = [];
  horzDataFields: RuleDataSourceField[] = [];
  caseDataFields: RuleDataSourceField[] = [];
  private readonly pageBaseBc: string = 'Custom Fields';
  private readonly pageNavId: string = 'DecisionEngine.CustomFields';
  constructor(
    private apiService: ApiService,
    private portfolioService: PortfolioService,
    private toastService: ToastService,
    private confirmService: ConfirmationService,
    private navService: NavService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private breadCrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {

    this.subscriptions.push(
      this.navService.leftNaveSelection$.subscribe((navObj: NavSettings) => { 
        if (navObj.navId && navObj.navId == this.pageNavId) {
          this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
        }
      }),
      this.portfolioService.portfolio$.subscribe(p => {
        this.portfolio = p;
          if (p) {
            this.getCustomFields();
            this.getRuleLookups(null);
          }
      })
    );

    this.hasEditFeature = this.userService.hasWrite(this.editFeatureCode);
    
    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Decision Engine', routerLink: ['/decision/dashboard'], command: () => { this.navService.setLeftNavSelection('DecisionEngine.Dashboard'); } },
      { label: this.pageBaseBc }
    ];
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      })
    }
  }

  getCustomFields() {
    let fieldSub = this.apiService.get(`decision/custom-fields/${this.portfolio?.customerGuid}`)
      .subscribe({
        next: (fields: CustomField[]) => {
          if (fields) this.fields = fields;
          this.loading = false;
          this.showList = true;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get custom Fields. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { fieldSub.unsubscribe(); }
      });
  }

  getFieldType(fieldDefinition: string | null): string | null {
    if (!fieldDefinition || fieldDefinition == null) return null;

    try {
      const doc: XMLDocument = this.parser.parseFromString(fieldDefinition, 'application/xml');
      let type = doc.querySelector('custom')?.attributes.getNamedItem('type')?.value;

      return type ?? 'Unknown';

    } catch (error) {
      console.error(error);
      return 'Unknown';
    }

  }

  goToAddField() {
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showEdit = false; this.showList = true; this.selectedField = null; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: 'Add' });
    this.customFieldTitle = 'Add';

    this.createForms(null);
    this.selectedField = null;
    this.showList = false;
    this.showEdit = true;
  }

  editField(field: CustomField) {
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showEdit = false; this.showList = true; this.selectedField = null; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: 'Edit' });
    this.customFieldTitle = 'Edit';

    this.selectedField = field;
    this.createForms(field);
    this.showList = false;
    this.showEdit = true;

  }

  createForms(field: CustomField | null) {
    this.showMatrixTable = false;
    this.customFieldNameForm = new FormGroup<CustomFieldNameFormGroup>({
      name: new FormControl<string | null>(null, { nonNullable: true, validators: [Validators.required] }),
      type: new FormControl<string>('matrix', { nonNullable: true, validators: [Validators.required] })
    });

    this.customFieldMatrixForm = this.formBuilder.group<CustomFieldMatrixFormGroup>({
      vertDataSource: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      vertDataField: new FormControl<RuleDataSourceField | null>(null, { nonNullable: true, validators: [Validators.required] }),
      vertIsNumeric: new FormControl<boolean>(false, { nonNullable: true, initialValueIsDefault: true }),
      vertRows: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      vertRanges: this.formBuilder.array([
        this.formBuilder.group<MinMaxGroup>({
          min: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
          max: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] })
        })
      ]),
      horzDataSource: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      horzDataField: new FormControl<RuleDataSourceField | null>(null, { nonNullable: true, validators: [Validators.required] }),
      horzIsNumeric: new FormControl<boolean>(false, { nonNullable: true, initialValueIsDefault: true }),
      horzRows: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      horzRanges: this.formBuilder.array([
        this.formBuilder.group<MinMaxGroup>({
          min: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
          max: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] })
        })
      ]),
      results: this.formBuilder.array([
        this.formBuilder.group<XYResultFormGroup>({
          x: new FormControl<number>(0, {nonNullable:true}),
          y: new FormControl<number>(0, {nonNullable:true}),
          result: new FormControl<string>('', {nonNullable:true })
        })
      ])
    });

    //  clear out arrays
    this.customFieldMatrixForm.controls.vertRanges.removeAt(0);
    this.customFieldMatrixForm.controls.horzRanges.removeAt(0);
    this.customFieldMatrixForm.controls.results.removeAt(0);

    this.customFieldCaseForm = this.formBuilder.group<CustomFieldCaseFormGroup>({
      dataSource: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      dataField: new FormControl<RuleDataSourceField | null>(null, { nonNullable: true, validators: [Validators.required] }),
      isNumeric: new FormControl<boolean>(false, { nonNullable: true, initialValueIsDefault: true }),
      rows: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      results: this.formBuilder.array([
        this.formBuilder.group<MinMaxValueGroup>({
          min: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
          max: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
          value: new FormControl<string>('', {nonNullable:true })
        })
      ])
    });
    
    this.customFieldCaseForm.controls.results.removeAt(0);

    if (field != null) {

      let fieldSettings: CustomFieldSettings = new CustomFieldSettings();

      let fieldType = this.getFieldType(field.customFieldDefinition);
      if (fieldType && fieldType === 'matrix') fieldSettings.matrix = this.parseMatrixtoJSON(field.customFieldDefinition);
      else if (fieldType && fieldType === 'case') fieldSettings.case = this.parseCasetoJSON(field.customFieldDefinition);

      this.customFieldNameForm.patchValue({
        name: field.fieldName,
        type: fieldType || 'matrix'
      });

      if (fieldSettings.matrix != null) {
        let VdsId = fieldSettings.matrix.xAxis.dsId || 0;
        let VfId = fieldSettings.matrix.xAxis.fId || 0;
        let vertDF = this.lookups.ruleDataSourceFields.find(a => a.dataSourceID == VdsId && a.fieldID == VfId);

        let HdsId = fieldSettings.matrix.yAxis.dsId || 0;
        let HfId = fieldSettings.matrix.yAxis.fId || 0;
        let horzDF = this.lookups.ruleDataSourceFields.find(a => a.dataSourceID == HdsId && a.fieldID == HfId);
        this.customFieldMatrixForm.patchValue({
          vertDataSource: fieldSettings.matrix.xAxis.dsId,
          vertDataField: vertDF,
          vertIsNumeric: fieldSettings.matrix.xAxis.numeric,
          vertRows: fieldSettings.matrix.xAxis.rowCount,
          horzDataSource: fieldSettings.matrix.yAxis.dsId,
          horzDataField: horzDF,
          horzIsNumeric: fieldSettings.matrix.yAxis.numeric,
          horzRows: fieldSettings.matrix.yAxis.rowCount
        });

        fieldSettings.matrix.xAxis.ranges.forEach(range => {
          this.customFieldMatrixForm.controls.vertRanges.push(
            this.formBuilder.group<MinMaxGroup>({
              min: new FormControl<string>(range.min, { nonNullable: true, validators: [Validators.required] }),
              max: new FormControl<string>(range.max, { nonNullable: true, validators: [Validators.required] })
            })
          );
        });

        fieldSettings.matrix.yAxis.ranges.forEach(range => {
          this.customFieldMatrixForm.controls.horzRanges.push(
            this.formBuilder.group<MinMaxGroup>({
              min: new FormControl<string>(range.min, { nonNullable: true, validators: [Validators.required] }),
              max: new FormControl<string>(range.max, { nonNullable: true, validators: [Validators.required] })
            })
          );
        });

        fieldSettings.matrix.results.forEach(result => {
          this.customFieldMatrixForm.controls.results.push(
            this.formBuilder.group<XYResultFormGroup>({
              x: new FormControl<number>(result.x, {nonNullable:true}),
              y: new FormControl<number>(result.y, {nonNullable:true}),
              result: new FormControl<string>(result.result, {nonNullable:true }),
            })
          );
        });
        this.showMatrixTable = true;

      }
      else if (fieldSettings.case != null) {
        let dsId = fieldSettings.case.dsId || 0;
        let fId = fieldSettings.case.fId || 0;
        let DF = this.lookups.ruleDataSourceFields.find(a => a.dataSourceID == dsId && a.fieldID == fId);

        this.customFieldCaseForm.patchValue({
          dataSource: dsId,
          dataField: DF,
          isNumeric: fieldSettings.case.numeric,
          rows: fieldSettings.case.rowCount,          
        });

        fieldSettings.case.values.forEach(value => {
          this.customFieldCaseForm.controls.results.push(
            this.formBuilder.group<MinMaxValueGroup>({
              min: new FormControl<string>(value.min, {nonNullable: true}),
              max: new FormControl<string>(value.max, {nonNullable: true}),
              value: new FormControl<string>(value.result, {nonNullable:true})
            })
          );
        });        
      }
    }
    this.customFieldFormsLoaded = true;
  }

  confirmDeleteField(field: CustomField) {
    this.confirmService.confirm({
      message: 'Are you sure that you want to delete this Custom Field?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        this.deleteField(field);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Custom Field has not been deleted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  deleteField(field: CustomField) {
    let delSub = this.apiService.get(`decision/custom-field/${this.portfolio?.customerGuid}/delete/${field.fieldGUID}`)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Custom Field successfully deleted.'
          });
          let fIndex = this.fields.indexOf(field);
          this.fields.splice(fIndex, 1);
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unalbe to delete Custom Field. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { delSub.unsubscribe(); }
      });

  }

  getRuleLookups(ruleGuid: string | null) {
    let url = `decision/custom-field/${this.portfolio?.customerGuid}/lookups`;
    if (ruleGuid != null) url += `/${ruleGuid}`;

    let lookupSub = this.apiService.get(url)
      .subscribe({
        next: (lookups: CustomFieldLookups) => {
          this.lookups = lookups;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Custom Field Lookups. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { lookupSub.unsubscribe(); }
      })

  }

  searchVertDataFields = (search: any) => {
    let query = search.query.toLowerCase();
    this.vertDataFields = [];
    let dsId = this.customFieldMatrixForm.value.vertDataSource;
    if (dsId == null) return;
    if (query && query.length) {
      let tempResults = this.lookups.ruleDataSourceFields.filter(a => a.dataSourceID == dsId && a.fieldName.toLowerCase().includes(query));
      if (tempResults && tempResults.length) {
        this.vertDataFields = tempResults;
      }
    }
  }

  searchHorzDataFields = (search: any) => {
    let query = search.query.toLowerCase();
    this.horzDataFields = [];
    let dsId = this.customFieldMatrixForm.value.horzDataSource;
    if (dsId == null) return;
    if (query && query.length) {
      let tempResults = this.lookups.ruleDataSourceFields.filter(a => a.dataSourceID == dsId && a.fieldName.toLowerCase().includes(query));
      if (tempResults && tempResults.length) {
        this.horzDataFields = tempResults;
      }
    }
  }

  searchCaseDataFields = (search: any) => {
    let query = search.query.toLowerCase();
    this.caseDataFields = [];
    let dsId = this.customFieldCaseForm.value.dataSource;
    if (dsId == null) return;
    if (query && query.length) {
      let tempResults = this.lookups.ruleDataSourceFields.filter(a => a.dataSourceID == dsId && a.fieldName.toLowerCase().includes(query));
      if (tempResults && tempResults.length) {
        this.caseDataFields = tempResults;
      }
    }
  }

  setVertAxisNumRows(rows: number) {
    this.showMatrixTable = false;
    this.customFieldMatrixForm.controls.vertRanges.clear();
    for (let i = 0; i < rows; i++) {
      this.customFieldMatrixForm.controls.vertRanges.push(
        this.formBuilder.group<MinMaxGroup>({
          min: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
          max: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] })
        })
      )
    }
  }

  setHorzAxisNumRows(rows: any) {
    this.showMatrixTable = false;
    this.customFieldMatrixForm.controls.horzRanges.clear();
    for (let i = 0; i < rows; i++) {
      this.customFieldMatrixForm.controls.horzRanges.push(
        this.formBuilder.group<MinMaxGroup>({
          min: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
          max: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] })
        })
      )
    }
  }

  setCaseNumRows(rows: any) {
    this.customFieldCaseForm.controls.results.clear();
    for (let i = 0; i < rows; i++) {
      this.customFieldCaseForm.controls.results.push(
        this.formBuilder.group<MinMaxValueGroup>({
          min: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
          max: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
          value: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] })
        })
      )
    }
  }

  disableMatrixBtn(): boolean {
    let disable: boolean = false;
    if (!this.customFieldMatrixForm.value.horzDataField || 
        !this.customFieldMatrixForm.value.horzDataSource ||
        !this.customFieldMatrixForm.value.horzRows ||
        !this.customFieldMatrixForm.value.horzRanges ||
        this.customFieldMatrixForm.value.horzRanges?.length < 1 ||
        !this.customFieldMatrixForm.value.vertDataField || 
        !this.customFieldMatrixForm.value.vertDataSource ||
        !this.customFieldMatrixForm.value.vertRows ||
        !this.customFieldMatrixForm.value.vertRanges ||
        this.customFieldMatrixForm.value.vertRanges?.length < 1) {

          disable = true;
          return disable;
        }

    for (let i = 0; i < (this.customFieldMatrixForm.value.horzRanges?.length ?? 0); i++ )
    {
      let range = this.customFieldMatrixForm.controls.horzRanges.at(i);
      if (!range || !range.value.min || !range.value.max) {
        disable = true;
        break;
      }
    }

    if (!disable) {
      for (let i = 0; i < (this.customFieldMatrixForm.value.vertRanges?.length ?? 0); i++ )
      {
        let range = this.customFieldMatrixForm.controls.vertRanges.at(i);
        if (!range || !range.value.min || !range.value.max) {
          disable = true;
          break;
        }
      }
    }

    return disable;
  }
  
  buildMatrix() { 
    this.customFieldMatrixForm.controls.results.clear();
    for (let x = 1; x <= this.customFieldMatrixForm.controls.vertRanges.length; x++) {
      for (let y = 1; y <= this.customFieldMatrixForm.controls.horzRanges.length; y++) {
        this.customFieldMatrixForm.controls.results.push(
          this.formBuilder.group<XYResultFormGroup>({
            x: new FormControl<number>(x, {nonNullable:true}),
            y: new FormControl<number>(y, {nonNullable:true}),
            result: new FormControl<string>('', {nonNullable:true}),
          })
        );
      }
    }
    this.showMatrixTable = true;
  }

  getMatrixRow(x: number) {
    return this.customFieldMatrixForm.controls.results.controls.filter(f => f.value.x == (x + 1));
  }

  getVerticalFieldName(): string {
    let field = this.customFieldMatrixForm.value.vertDataField;
    if (field) return field.fieldName;
    else return 'Not configured';
  }

  getHorizontalFieldName(): string {
    let field = this.customFieldMatrixForm.value.horzDataField;
    if (field) return field.fieldName;
    else return 'Not configured';
  }

  getMatrixCellClass(result: FormGroup<XYResultFormGroup>): string {
    let cssClass = 'col cell-border';
    if (result.value.result) {
      if (result.value.result == '1' || result.value.result.toLowerCase() == 'a') cssClass += ' cell-A';
      else if (result.value.result == '2' || result.value.result.toLowerCase() == 'b') cssClass += ' cell-B';
      else cssClass += ' cell-dflt'
    }

    return cssClass;
  }

  saveMatrix() { 
    if (!this.selectedField) {
      this.selectedField = new CustomField();
      this.selectedField.hidden = true;
      this.selectedField.readOnly = true;
      this.selectedField.fieldGUID = uuidv4();
      this.selectedField.storageTime = -1;
      this.selectedField.storageTypeID = 6;
    }
    this.selectedField.fieldName = this.customFieldNameForm.value.name ?? 'Unknown'; 
    
    let fieldSettings: CustomFieldSettings = new CustomFieldSettings();
    let matrix = new CustomFieldMatrix();
    matrix.type = 'matrix';
    matrix.xAxis.dsId = this.customFieldMatrixForm.value.vertDataSource ?? 0;
    matrix.xAxis.fId = this.customFieldMatrixForm.value.vertDataField?.fieldID ?? 0;
    matrix.xAxis.functionName = this.lookups.ruleDataSourceFields.find(f => f.fieldID == matrix.xAxis.fId)?.fieldName ?? '';
    matrix.xAxis.numeric = this.customFieldMatrixForm.value.vertIsNumeric ?? true;
    matrix.xAxis.rowCount = this.customFieldMatrixForm.value.vertRows ?? 0;
    matrix.xAxis.ranges = [];
    this.customFieldMatrixForm.value.vertRanges?.forEach(range => {
      matrix.xAxis.ranges.push({
        operation: 'between',
        min: range.min ?? '',
        max: range.max ?? '',
        rowNum: +(this.customFieldMatrixForm.value.vertRanges?.indexOf(range) ?? 0) + 1,
      })
    });
    matrix.yAxis.dsId = this.customFieldMatrixForm.value.horzDataSource ?? 0;
    matrix.yAxis.fId = this.customFieldMatrixForm.value.horzDataField?.fieldID ?? 0;
    matrix.yAxis.functionName = this.lookups.ruleDataSourceFields.find(f => f.fieldID == matrix.yAxis.fId)?.fieldName ?? '';
    matrix.yAxis.numeric = this.customFieldMatrixForm.value.horzIsNumeric ?? true;
    matrix.yAxis.rowCount = this.customFieldMatrixForm.value.horzRows ?? 0;
    matrix.yAxis.ranges = [];
    this.customFieldMatrixForm.value.horzRanges?.forEach(range => {
      matrix.yAxis.ranges.push({
        operation: 'between',
        min: range.min ?? '',
        max: range.max ?? '',
        rowNum: +(this.customFieldMatrixForm.value.horzRanges?.indexOf(range) ?? 0) + 1,
      })
    });

    matrix.results = [];
    this.customFieldMatrixForm.value.results?.forEach(result => {
      matrix.results.push({
        x: result.x ?? 0,
        y: result.y ?? 0,
        result: result.result ?? '',
      })
    });    

    fieldSettings.matrix = matrix;
    
    let doc: XMLDocument = this.convertSettingtoXML(fieldSettings);
    const serializer = new XMLSerializer();
    this.selectedField.customFieldDefinition = serializer.serializeToString(doc);    

    this.saveCustomField();
  }

  saveCase() { 
    if (!this.selectedField) {
      this.selectedField = new CustomField();
      this.selectedField.hidden = true;
      this.selectedField.readOnly = true;
      this.selectedField.fieldGUID = uuidv4();
      this.selectedField.storageTime = -1;
      this.selectedField.storageTypeID = 6;
    }
    this.selectedField.fieldName = this.customFieldNameForm.value.name ?? 'Unknown'; 
    
    let fieldSettings: CustomFieldSettings = new CustomFieldSettings();
    let settCase = new CustomFieldCase();
    settCase.type = 'case';
    settCase.dsId = this.customFieldCaseForm.value.dataSource ?? 0;
    settCase.fId = this.customFieldCaseForm.value.dataField?.fieldID ?? 0;
    settCase.functionName = this.lookups.ruleDataSourceFields.find(f => f.fieldID == settCase.fId)?.fieldName ?? '';
    settCase.numeric = this.customFieldCaseForm.value.isNumeric ?? true;
    settCase.rowCount = this.customFieldCaseForm.value.rows ?? 0;
    settCase.values = [];
    this.customFieldCaseForm.value.results?.forEach(result => {
      settCase.values.push({
        operation: 'between',
        min: result.min ?? '',
        max: result.max ?? '',
        result: result.value ?? '',
        rowNum: +(this.customFieldCaseForm.value.results?.indexOf(result) ?? 0) + 1,
      })
    });

    fieldSettings.case = settCase;
    
    let doc: XMLDocument = this.convertSettingtoXML(fieldSettings);
    const serializer = new XMLSerializer();
    this.selectedField.customFieldDefinition = serializer.serializeToString(doc);    

    this.saveCustomField();
  }

  saveCustomField() {
    let postSub = this.apiService.post(`decision/custom-field/${this.portfolio?.customerGuid}`, this.selectedField)
    .subscribe({
      next: () => {
        this.cancelEdit();
        this.getCustomFields();
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Custom field successfully saved.'
        });
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save Custom Field. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { postSub.unsubscribe(); }
    });
  }

  cancelEdit() { 
    this.showEdit = false; 
    this.showMatrixTable = false;
    this.showList = true; 
    this.selectedField = null; 
    this.bcItems.pop(); 
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => {};
      this.bcItems.push(prevItem);
    }
    
  }

  parseMatrixtoJSON(xmlDoc: string | null): CustomFieldMatrix {

    let fieldSettings: CustomFieldMatrix = new CustomFieldMatrix();
    if (!xmlDoc) return fieldSettings;

    fieldSettings.type = this.getFieldType(xmlDoc) ?? 'unknown';

    const doc: XMLDocument = this.parser.parseFromString(xmlDoc, 'application/xml');

    let xAxis = doc.querySelector('x');
    let yAxis = doc.querySelector('y');

    fieldSettings.xAxis.dsId = +(xAxis?.attributes.getNamedItem('dsID')?.value ?? 0);
    fieldSettings.xAxis.fId = +(xAxis?.attributes.getNamedItem('fID')?.value ?? 0);
    fieldSettings.xAxis.functionName = xAxis?.attributes.getNamedItem('fn')?.value || '';
    fieldSettings.xAxis.numeric = (xAxis?.attributes.getNamedItem('num')?.value || 0) === '1';
    fieldSettings.xAxis.rowCount = +(xAxis?.attributes.getNamedItem('cnt')?.value || 0);
    fieldSettings.xAxis.ranges = [];
    xAxis?.querySelectorAll('val').forEach(val => {
      fieldSettings.xAxis.ranges.push({
        operation: val.attributes.getNamedItem('op')?.value ?? '',
        min: val.attributes.getNamedItem('min')?.value ?? '',
        max: val.attributes.getNamedItem('max')?.value ?? '',
        rowNum: +(val.attributes.getNamedItem('ix')?.value || 0)
      });
    });

    fieldSettings.yAxis.dsId = +(yAxis?.attributes.getNamedItem('dsID')?.value ?? 0);
    fieldSettings.yAxis.fId = +(yAxis?.attributes.getNamedItem('fID')?.value ?? 0);
    fieldSettings.yAxis.functionName = yAxis?.attributes.getNamedItem('fn')?.value || '';
    fieldSettings.yAxis.numeric = (yAxis?.attributes.getNamedItem('num')?.value || 0) === '1';
    fieldSettings.yAxis.rowCount = +(yAxis?.attributes.getNamedItem('cnt')?.value || 0);
    fieldSettings.yAxis.ranges = [];
    yAxis?.querySelectorAll('val').forEach(val => {
      fieldSettings.yAxis.ranges.push({
        operation: val.attributes.getNamedItem('op')?.value ?? '',
        min: val.attributes.getNamedItem('min')?.value ?? '',
        max: val.attributes.getNamedItem('max')?.value ?? '',
        rowNum: +(val.attributes.getNamedItem('ix')?.value || 0)
      });
    });

    fieldSettings.results = [];
    doc.querySelectorAll('cell').forEach(cell => {
      fieldSettings.results.push({
        x: +(cell.attributes.getNamedItem('ix')?.value || 0),
        y: +(cell.attributes.getNamedItem('iy')?.value || 0),
        result: cell.attributes.getNamedItem('res')?.value || ''
      });
    });

    return fieldSettings;
  }

  parseCasetoJSON(xmlDoc: string | null): CustomFieldCase {
    let fieldSettings: CustomFieldCase = new CustomFieldCase();
    if (!xmlDoc) return fieldSettings;
    fieldSettings.type = this.getFieldType(xmlDoc) ?? 'unknown';

    const doc: XMLDocument = this.parser.parseFromString(xmlDoc, 'application/xml');

    fieldSettings.dsId = +(doc.querySelector('custom')?.attributes.getNamedItem('dsID')?.value || 0);
    fieldSettings.fId = +(doc.querySelector('custom')?.attributes.getNamedItem('fID')?.value || 0);
    fieldSettings.functionName = doc.querySelector('custom')?.attributes.getNamedItem('fn')?.value ?? '';
    fieldSettings.numeric = (doc.querySelector('custom')?.attributes.getNamedItem('num')?.value || 0) === '1';
    fieldSettings.rowCount = +(doc.querySelector('custom')?.attributes.getNamedItem('cnt')?.value || 0);
    fieldSettings.values = [];
    doc.querySelectorAll('val').forEach(val => {
      fieldSettings.values.push({
        operation: val.attributes.getNamedItem('op')?.value || '',
        min: val.attributes.getNamedItem('min')?.value || '',
        max: val.attributes.getNamedItem('max')?.value || '',
        result: val.attributes.getNamedItem('res')?.value || '',
        rowNum: +(val.attributes.getNamedItem('ix')?.value || 0),
      });
    });

    return fieldSettings;
  }

  convertSettingtoXML(fieldSettings: CustomFieldSettings): XMLDocument {
    let doc = document.implementation.createDocument('', 'custom');

    let custom = doc.querySelector('custom');

    if (fieldSettings.case != null) {
      custom?.setAttribute('type', 'case');
      custom?.setAttribute('fID', fieldSettings.case.fId.toString());
      custom?.setAttribute('fn', fieldSettings.case.functionName);
      custom?.setAttribute('dsID', fieldSettings.case.dsId.toString());
      custom?.setAttribute('cnt', fieldSettings.case.rowCount.toString());
      custom?.setAttribute('num', fieldSettings.case.numeric ? '1' : '0');

      fieldSettings.case.values.forEach(val => {
        let childNode = doc.createElement('val');
        childNode.setAttribute('op', val.operation);
        childNode.setAttribute('min', val.min);
        childNode.setAttribute('max', val.max);
        childNode.setAttribute('res', val.result);
        childNode.setAttribute('ix', val.rowNum.toString());
        custom?.appendChild(childNode);
      });
    }
    else if (fieldSettings.matrix != null) {
      custom?.setAttribute('type', 'matrix');
      let axes = doc.createElement('axes');
      let xAxis = doc.createElement('x');
      let yAxis = doc.createElement('y');

      xAxis.setAttribute('fID', fieldSettings.matrix.xAxis.fId.toString());
      xAxis.setAttribute('fn', fieldSettings.matrix.xAxis.functionName);
      xAxis.setAttribute('dsID', fieldSettings.matrix.xAxis.dsId.toString());
      xAxis.setAttribute('cnt', fieldSettings.matrix.xAxis.rowCount.toString());
      xAxis.setAttribute('num', fieldSettings.matrix.xAxis.numeric ? '1' : '0');

      fieldSettings.matrix.xAxis.ranges.forEach(range => {
        let valNode = doc.createElement('val');
        valNode.setAttribute('op', range.operation);
        valNode.setAttribute('min', range.min);
        valNode.setAttribute('max', range.max);
        valNode.setAttribute('ix', range.rowNum.toString())
        xAxis.appendChild(valNode);
      });

      yAxis.setAttribute('fID', fieldSettings.matrix.yAxis.fId.toString());
      yAxis.setAttribute('fn', fieldSettings.matrix.yAxis.functionName);
      yAxis.setAttribute('dsID', fieldSettings.matrix.yAxis.dsId.toString());
      yAxis.setAttribute('cnt', fieldSettings.matrix.yAxis.rowCount.toString());
      yAxis.setAttribute('num', fieldSettings.matrix.yAxis.numeric ? '1' : '0');

      fieldSettings.matrix.yAxis.ranges.forEach(range => {
        let valNode = doc.createElement('val');
        valNode.setAttribute('op', range.operation);
        valNode.setAttribute('min', range.min);
        valNode.setAttribute('max', range.max);
        valNode.setAttribute('iy', range.rowNum.toString())
        yAxis.appendChild(valNode);
      });

      axes.appendChild(xAxis);
      axes.appendChild(yAxis);
      custom?.appendChild(axes);

      let resultNode = doc.createElement('result');

      fieldSettings.matrix.results.forEach(result => {
        let cellNode = doc.createElement('cell');
        cellNode.setAttribute('ix', result.x.toString());
        cellNode.setAttribute('iy', result.y.toString());
        cellNode.setAttribute('res', result.result);
        resultNode.appendChild(cellNode);
      });


      custom?.appendChild(resultNode);
    }

    return doc;
  }

  removeBcCommand(label: string | undefined) {
    if (!label) return;
    let item = this.bcItems.find(b => b.label === label);
    if (item) {
      let index = this.bcItems.indexOf(item);
      item.command = undefined;
      this.bcItems.splice(index, 1, item);
    }
  }

}
