import { Component, Inject, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-procedure-entry',
  templateUrl: './edit-procedure-entry.component.html',
  styleUrls: ['./edit-procedure-entry.component.scss']
})
export class EditProcedureEntryComponent implements OnInit {
  procedureForm = this.fb.group({
    productDesc: ['', Validators.required],
    searchValue: ['', Validators.required],
    productSku: ['', Validators.required],
    productMrsp: ['', Validators.required]
  });
  constructor(public dialogRef: MatDialogRef<EditProcedureEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {name: string},
    private fb: UntypedFormBuilder) {
   }

  ngOnInit(): void {
  }

  onSubmit(){

    console.warn(this.procedureForm.value);
    this.dialogRef.close();
  }

  onCancel(){
    this.dialogRef.close();
  }
}
