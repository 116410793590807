import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { PortfolioItem } from 'src/app/models/customer-item';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-role-entities',
  templateUrl: './role-entities.component.html',
  styleUrls: ['./role-entities.component.scss']
})
export class RoleEntitiesComponent implements OnInit {

  editTab: any = { show: false, item: {}, saving: false, header: '' };
  editPage: any = { show: false, item: {}, saving: false, header: '' };
  editFeature: any = { show: false, item: {}, saving: false, header: '' };
  tabForm: FormGroup = this.fb.group({tabName: ['', Validators.required]});
  pageForm: FormGroup = this.fb.group({
    SiteTabID: [0, Validators.required],
    SitePageID: [0],
    SitePageName: ['', Validators.required]
  });
  featureForm: FormGroup = this.fb.group({
    SiteFeatureId: [0],
    SitePageId: [0, Validators.required],
    SiteFeatureCode: ['', Validators.required],
    SiteFeatureName: ['', Validators.required]
  });
  bcItems: MenuItem[] = [{ label: 'Users' , url: 'users/dashboard'}, { label: 'Roles' , url: 'users/roles'}, { label: 'Role Entities' }];
  portfolioItem: PortfolioItem | null | undefined;
  entities: any = {};
  loading = false;
  constructor(private api: ApiService,
    private router: Router,
    private confirmService: ConfirmationService,
    private toast: ToastService,
    private fb: FormBuilder,
    private portfolioService: PortfolioService) { }

  ngOnInit(): void {
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      this.getEntities();
    });
  }

  getEntities(){
    if (this.portfolioItem && this.portfolioItem.customerGuid) {
      this.loading = true;
      this.api.get(`AdminUser/get-entities/${this.portfolioItem.customerGuid}`).subscribe(data => {
        this.entities = data;
        this.loading = false;
      });
    }
  }

  delete(item: any, typ: string){
    var name = item.SiteTabName || item.SiteFeatureCode || item.SitePageName || 'Item';
    this.confirmService.confirm({
      message: `Are you sure that you want to delete ${name}?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        this.deleteConfirmed(item, typ);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      }
    });
  }

  deleteConfirmed(item: any, typ: string){
    let obj:any = {CustomerGuid: this.portfolioItem?.customerGuid};
    if (typ == 'feature'){
      obj.SpName = 'spSiteFeature_Delete';
      obj.ParamName = '@SiteFeatureID';
      obj.Id = item.SiteFeatureID
    }
    if (typ == 'tab'){
      obj.SpName = 'spSiteTab_Delete';
      obj.ParamName = '@SiteTabID';
      obj.Id = item.SiteTabID
    }
    if (typ == 'page'){
      obj.SpName = 'spSitePage_Delete';
      obj.ParamName = '@SitePageID';
      obj.Id = item.SitePageID
    }
    var json = JSON.stringify(obj);
    this.api.post(`adminuser/delete-entity`, json).subscribe(res => {
      this.toast.addSuccess("Successfully deleted!");
      this.getEntities();
    }, err => {
      console.error(err);
      this.toast.addError("Invalid request. Server Error occured.");
    });
  }

  editTabItem(item: any = null){
    this.tabForm.setValue({tabName: item?.SiteTabName || ""});
    this.editTab.header = item ? `Edit Site Tab:${item.SiteTabName}` : 'New Site Tab';
    this.editTab.item = item;
    this.editTab.show = true;

  }

  editPageItem(item: any = null){
    this.pageForm.setValue({
      SiteTabID: item?.SiteTabID || 0,
      SitePageID: item?.SitePageID || 0,
      SitePageName: item?.SitePageName || ''
    });
    this.editPage.header = item ? `Edit Site Page:${item.SitePageName}` : 'New Site Page';
    this.editPage.item = item;
    this.editPage.show = true;
  }

  editFeatureItem(item: any = null){
    this.featureForm.setValue({
      SiteFeatureId: item?.SiteFeatureID || 0,
      SitePageId: item?.SitePageID || 0,
      SiteFeatureCode: item?.SiteFeatureCode || '',
      SiteFeatureName: item?.SiteFeatureName || ''
    });
    this.editFeature.header = item ? `Edit Site Feature:${item.SiteFeatureCode}` : 'New Site Feature';
    this.editFeature.item = item;
    this.editFeature.show = true;
  }

  saveFeature(){
    if (this.featureForm.valid){
      var id = this.editFeature.item?.SiteFeatureId|| 0;
      let obj = {
        ...this.featureForm.value,
        Name: this.featureForm.value.SiteFeatureCode,
        CustomerGuid: this.portfolioItem?.customerGuid
      };
      this.editFeature.saving = true;
      var json = JSON.stringify(obj);
      this.api.post(`adminuser/features/update`, json).subscribe(res => {
        this.toast.addSuccess("Feature successfully updated!");
        this.editFeature.saving = false;
        this.editFeature.show = false;
        this.getEntities();
        this.featureForm.reset();
      }, err => {
        console.error(err);
        this.editFeature.saving = false;
        this.toast.addError("Invalid form. Server Error occured.");
      });
    }
  }

  savePage(){
    if (this.pageForm.valid){
      var id = this.editPage.item?.SitePageID|| 0;
      let obj = {
        SiteTabId: this.pageForm.value.SiteTabID,
        SitePageId: id,
        Name: this.pageForm.value.SitePageName,
        CustomerGuid: this.portfolioItem?.customerGuid
      };
      this.editPage.saving = true;
      var json = JSON.stringify(obj);
      this.api.post(`adminuser/pages/update`, json).subscribe(res => {
        this.toast.addSuccess("Page successfully updated!");
        this.editPage.saving = false;
        this.editPage.show = false;
        this.getEntities();
        this.pageForm.reset();
      }, err => {
        console.error(err);
        this.editPage.saving = false;
        this.toast.addError("Invalid form. Server Error occured.");
      });
    }
  }

  saveTab(){
    if (this.tabForm.valid){
      var id = this.editTab.item?.SiteTabID|| 0;
      let obj = {
        SiteTabId: id,
        Name: this.tabForm.value.tabName,
        CustomerGuid: this.portfolioItem?.customerGuid
      };
      this.editTab.saving = true;
      var json = JSON.stringify(obj);
      this.api.post(`adminuser/tabs/update`, json).subscribe(res => {
        this.toast.addSuccess("Tab successfully updated!");
        this.editTab.saving = false;
        this.editTab.show = false;
        this.getEntities();
      }, err => {
        console.error(err);
        this.editTab.saving = false;
        this.toast.addError("Invalid form. Server Error occured.");
      });
    }else{
      this.tabForm.markAsTouched();
    }
  }

}
