import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { Campaign } from 'src/app/models/campaign';
import { PortfolioItem } from 'src/app/models/customer-item';
import { DERule } from 'src/app/models/decision/rule';
import { DERuleSet, DERuleSetCategory, DERuleSetComplete, DERuleSetRule, DERuleSetTestData, DERuleSetType } from 'src/app/models/decision/rule-set';
import { NumValDropDown } from 'src/app/models/dropdown';
import { DERuleSetMatrixFormGroup, DERuleSetNew, DERuleSetWeight, DERuleSetWeigthRS, TestRSFormGroup } from 'src/app/models/form-groups';
import { LookupModel } from 'src/app/models/lookup-model';
import { ProviderInfo } from 'src/app/models/provider-info';
import { AdminQueryParams } from 'src/app/models/query-params';
import { CampaignSeeds } from 'src/app/models/seeds';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NavService, NavSettings } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-decision-rulesets',
  templateUrl: './decision-rulesets.component.html',
  styleUrls: ['./decision-rulesets.component.scss']
})
export class DecisionRulesetsComponent implements OnInit, AfterViewInit, OnDestroy {

  testPageId: number = 53;
  hasTestAccess: boolean = false;

  editFeatureCode: string = 'DECISION_ENGINE_RULESET_EDIT';
  testFeatureCode: string = 'DECISION_ENGINE_RULESET_TEST';

  hasEditFeature: boolean = false;
  hasTestFeature: boolean = false;

  loading: boolean = false;
  hasError: boolean = false;
  showRuleSets: boolean = false;
  leftNavExpanded: boolean = false;
  showTestRuleSet: boolean = false;
  showTestRSResult: boolean = false;
  showAddRuleSet: boolean = false;
  showRuleSetRules: boolean = false;
  showStatusUpdate: boolean = false;
  showWeightUpdate: boolean = false;
  showAddMatrix: boolean = false;
  editMode: boolean = false;
  showDeletRuleWithGroup: boolean = false;
  deleteRuleChildren: boolean = false;
  showAddInventoryRules: boolean = false;

  queryParams: AdminQueryParams[] = [];
  subscriptions: Subscription[] = [];
  bcItems: MenuItem[] = [];
  rawRuleSets: DERuleSet[] = [];
  ruleSets: DERuleSet[] = [];
  ruleSetRules: DERuleSetRule[] = [];
  categories: DERuleSetCategory[] = [];
  types: DERuleSetType[] = [];
  campaigns: LookupModel[] = [];
  matrices: LookupModel[] = [];
  lineMatrices: LookupModel[] = [];
  customerSeeds: LookupModel[] = [];
  testRuleSets: LookupModel[] = [];
  addRuleCats: NumValDropDown[] = [];
  filterAddRules: NumValDropDown[] = [];
  testRuleData: NumValDropDown[] = [];
  rawInventoryRules: DERule[] = [];
  filteredInventoryRules: DERule[] = [];
  selectedAddRules: DERule[] = [];
  selectedRuleSet: DERuleSet | null = null;
  ruleSetComplete: DERuleSetComplete | null = null;
  deleteRule: DERuleSetRule | null = null;

  addRuleInvCategory: number = -1;
  addRuleInvFilter: number = -1;
  selectedMatrix: LookupModel | null = null;

  statOptions: LookupModel[] = [
    { desc: 'All', id: 'all' },
    { desc: 'Live', id: 'live' },
    { desc: 'Design', id: 'design' },
    { desc: 'Closed', id: 'closed' }
  ];
  selectedStatus: LookupModel = {} as LookupModel;
  selectedCategory: DERuleSetCategory = {} as DERuleSetCategory;

  portfolio: PortfolioItem | null = null;
  providerInfo: ProviderInfo | null = null;
  campaign: Campaign | null = null;

  first: number = 0;
  rulesFirst: number = 0;
  filterInvFirst: number = 0;

  testRSForm: FormGroup<TestRSFormGroup> = {} as FormGroup;
  testRSFormLoaded: boolean = false;

  newRSForm: FormGroup<DERuleSetNew> = {} as FormGroup;
  newRSFormLoaded: boolean = false;

  rsWeightForm: FormGroup<DERuleSetWeight> = {} as FormGroup;
  rsWeightFormLoaded: boolean = false;

  rsLinMatrixForm: FormGroup<DERuleSetMatrixFormGroup> = {} as FormGroup;
  rsLinMatrixFormLoaded: boolean = false;

  ruleSetTestDataResponse: string = 'Test returned empty result!';
  deRuleSetConfirmKey: string = "DE RuleSet conf Key";
  ruleSetStatusName: string = '';
  matrixType: string = 'Offer';
  updateStatOptions: LookupModel[] = this.statOptions.filter(s => s.id != 'all');
  selectedStatusUpdate: LookupModel = {} as LookupModel;
  private readonly pageBaseBc: string = "Decision Engine RuleSets";
  private readonly pageViewBc: string = "RuleSet";
  private readonly pageEditBc: string = "Edit";
  private readonly pageNavId: string = "DecisionEngine.RuleSets";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private providerService: ProviderService,
    private apiService: ApiService,
    private portfolioService: PortfolioService,
    private toastService: ToastService,
    private navService: NavService,
    private confirmService: ConfirmationService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private breadCrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.selectedStatus = this.statOptions.find(c => c.id == 'live') || this.statOptions[1];
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params && params.data) {
          let decoded = JSON.parse(atob(params.data)) as AdminQueryParams[];
          if (decoded && decoded.length > 0) {
            this.queryParams = decoded;
          }
        }
      }),
      this.navService.leftNavExpanded$.subscribe((isExpanded: boolean) => {
        this.leftNavExpanded = isExpanded;
      }),
      this.navService.leftNaveSelection$.subscribe((navObj: NavSettings) => {
        if (navObj.navId && navObj.navId == this.pageNavId) {
          this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
          window.scrollTo(0, 0);
        }
      }),
      this.portfolioService.portfolio$.subscribe(p => {
        this.portfolio = p;
        this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
        window.scrollTo(0, 0);
        this.getCustomerData();
        if (p) {
          this.providerService.loadInfoSectionProvider(p.customerGuid);
        }
      }),
      this.providerService.infoSectionProvider$
        .subscribe((provider: ProviderInfo | null) => {
          this.providerInfo = provider;
          if (provider && provider.showOfferMatrix != undefined && provider.showOfferMatrix) {
            this.matrixType = 'Offer';
            this.getOfferMatrices();
          }
          else if (provider && provider.showOfferMatrix != undefined && !provider.showOfferMatrix) {
            this.matrixType = "Line";
            this.getLineMatrices();
          }
        })
    );
    this.hasTestAccess = this.userService.hasPageAccess(this.testPageId);
    this.hasEditFeature = this.userService.hasWrite(this.editFeatureCode);
    this.hasTestFeature = this.userService.hasWrite(this.testFeatureCode);

    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Decision Engine', routerLink: ['/decision/dashboard'], command: () => { this.navService.setLeftNavSelection('DecisionEngine.Dashboard'); } },
      { label: this.pageBaseBc }
    ];
    this.loading = false;
    this.showRuleSets = true;
  }

  ngAfterViewInit(): void {
    this.navService.setLeftNavSelection(this.pageNavId, null, false);
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      })
    }
  }

  getCustomerData() {
    const apiTags = forkJoin({
      categories: this.apiService.get(`decision/ruleset/categories/${this.portfolio?.customerGuid}`),
      types: this.apiService.get(`decision/ruleset/types/${this.portfolio?.customerGuid}`)
    }).subscribe(data => {
      this.categories = data.categories as DERuleSetCategory[];
      this.types = data.types as DERuleSetType[];
      this.selectedCategory = this.categories.find(c => c.isDefault) || this.categories[0];
      this.getCustomerRuleSets(this.selectedCategory);
    }, (err: any) => {
      this.toastService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Unable to get RuleSets Categories and/or Types. See log for details.'
      }, 'center');
      console.error(err);
    });
  }

  getCustomerRuleSets(category: DERuleSetCategory) {
    this.ruleSets = [];
    this.campaigns = [];
    let rsSub = this.apiService.get(`decision/ruleset/all/${this.portfolio?.customerGuid}/${category.ruleSetCategoryID}`)
      .subscribe({
        next: (rs: DERuleSet[]) => {
          this.rawRuleSets = rs;
          this.testRuleSets = [];
          this.rawRuleSets.forEach(rs => {
            this.testRuleSets.push({
              id: `${rs.rulesetGUID}`,
              desc: `${rs.rulesetName} v${rs.rulesetVersion}`
            })
          });          
          if (this.selectedStatus.id == 'all') {
            this.ruleSets = this.rawRuleSets;
          }
          else {
            this.ruleSets = this.rawRuleSets.filter(r => r.rulesetStatus && r.rulesetStatus.toLowerCase() == this.selectedStatus.id.toLowerCase());
          }
          this.setRSCampaignAlternate();
          if (this.campaigns.length == 0) {
            this.loadCustomerCampaigns();
          }
          if (this.queryParams.length > 0) {
            this.runQueryParams();
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get customer RuleSets. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { rsSub.unsubscribe(); }
      });
  }

  setRSCampaignAlternate() {
    let campId: number = -2;
    this.ruleSets.forEach(rs => {
      if (campId != rs.campaignID) {
        campId = rs.campaignID;
        rs.campaignAlternate = true;
      }
    });
  }

  runQueryParams() {
    let ruleParam = this.queryParams.pop();
    if (ruleParam) {
      if (ruleParam.providerGuid != this.portfolio?.customerGuid ||
        ruleParam.page != this.route.component?.name) {
        this.queryParams = [];
        return;
      }
      if (ruleParam.data && ruleParam.data.ruleSetGuid) {
        if (ruleParam.data.editMode) {
          this.editMode = ruleParam.data.editMode;
        }
        let searchRS = this.rawRuleSets.find(r => r.rulesetGUID == ruleParam?.data.ruleSetGuid);
        if (searchRS != null) {
          this.editMode ? this.checkEditRuleSet(searchRS) : this.ruleSetSelected(searchRS);
        }
      }
    }
  }

  loadCustomerCampaigns() {
    let uniqueRuleSets = [...new Map(this.ruleSets.map(item =>
      [item['campaignGUID'], item])).values()];

    let uniqueCamp = uniqueRuleSets.map(rs => {
      const camp: LookupModel = {
        id: rs.campaignGUID,
        desc: rs.campaignName || ''
      };
      return camp;
    }).filter(f => f.desc && f.desc.length > 0)
      .sort((a, b) => a.desc > b.desc ? 1 : -1);

    this.campaigns = uniqueCamp;
    this.loadCustomerSeeds();
    this.loadCustomerTestData();
  }

  loadCustomerSeeds() {
    this.customerSeeds = [];
    this.campaigns.forEach(c => {
      this.apiService.get(`campaign/get-seeds/${this.portfolio?.customerGuid}/${c.id}`)
      .subscribe((seeds: CampaignSeeds[]) => {
        if (seeds && seeds.length > 0) {
          seeds.forEach(seed => {
            this.customerSeeds.push({
              id: seed.targetGuid,
              desc: `${seed.firstName} ${seed.lastName} (${seed.invitationCode})`
            });
          })
        }
      });
    });
  }

  loadCustomerTestData() {
    this.testRuleData = [];
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      dataSourceName: 'dsAdminDERuleSetTestData',
      columnList: 'TestDataID,Name',
      startRow: 0,
      outputFormat: 'rs'
    };
    let dataSub = this.apiService.post(`decision/ruleset/test-data`, body)
    .subscribe({
      next: (data: DERuleSetTestData[]) => {
        if (data && data.length) {
          data.forEach(d => {
            this.testRuleData.push({
              text: d.name,
              value: d.testDataID
            });
          });
        }
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get customer RuleSets Test Data! See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { dataSub.unsubscribe(); }
    });
  }

  getRowStyle(rs: DERuleSet) {
    let result = '';
    if (rs.bgColor && rs.bgColor.length > 0) result = rs.bgColor;
    else if (rs.campaignAlternate) result = '#fafafa';

    return result;
  }

  categorySelected(category: DERuleSetCategory) {
    this.getCustomerRuleSets(category);
  }

  statusSelected(status: LookupModel) {
    if (status.id == 'all') this.ruleSets = this.rawRuleSets;
    else this.ruleSets = this.rawRuleSets.filter(r => r.rulesetStatus && r.rulesetStatus.toLowerCase() == status.id.toLowerCase());
    this.setRSCampaignAlternate();
  }

  addRuleSet() {
    this.newRSForm = this.formBuilder.group<DERuleSetNew>({
      name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      campaignGuid: new FormControl<string | null>(null, { nonNullable: true, validators: [Validators.required] }),
      type: new FormControl<number | null>(null, { nonNullable: true }),
      category: new FormControl<number | null>(null, { nonNullable: true })
    });
    this.newRSFormLoaded = true;
    this.showAddRuleSet = true;
  }

  saveNewRuleSet() {
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      campaignGuid: this.newRSForm.value.campaignGuid,
      name: this.newRSForm.value.name,
      typeId: this.newRSForm.value.type ?? 1,
      categoryId: this.newRSForm.value.category ?? 1
    };

    let postSub = this.apiService.post(`decision/ruleset/create`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'RuleSet successfully saved.'
          });
          this.getCustomerRuleSets(this.selectedCategory);
          this.showAddRuleSet = false;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save customer RuleSet. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  testRuleSet() { 
    this.testRSForm = new FormGroup<TestRSFormGroup>({
      seed: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]}),
      ruleSet: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]}),
      data: new FormControl<number|null>(null, {nonNullable: true, validators: [Validators.required]}),
    });
    this.testRSFormLoaded = true;
    this.showTestRuleSet = true;

  }

  processRuleSetTest() { 
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      seed: this.testRSForm.value.seed,
      ruleSet: this.testRSForm.value.ruleSet,
      data: this.testRSForm.value.data
    };
    let testSub = this.apiService.post(`decision/ruleset/test`, body)
    .subscribe({
      next: (res: any) => {
        this.ruleSetTestDataResponse = res.response;
        this.showTestRSResult = true;
      },
      error: (err: any) => {
        this.ruleSetTestDataResponse = 'Error! Data could not be tested.';
        this.showTestRSResult = true;
        console.error(err);
      },
      complete: () => { testSub.unsubscribe(); }
    });
    
  }

  getTestDataName(dataId: number): string {
    let testItem = this.testRuleData.find(t => t.value == dataId);
    if (testItem) return testItem.text;
    return 'N/A';
  }

  getRuleSetBadge(rs: any) {
    let bstatus = '';
    if (!rs.rulesetStatus || rs.rulesetStatus.length == 0) return bstatus;
    if (rs.rulesetStatus.toLowerCase() == 'live') bstatus = 'success';
    else if (rs.rulesetStatus.toLowerCase() == 'design') bstatus = 'warning';
    else if (rs.rulesetStatus.toLowerCase() == 'closed') bstatus = 'danger';
    return bstatus;
  }

  updateRSWeight(rs: DERuleSet) {
    this.selectedRuleSet = rs;
    let updRS = this.rawRuleSets.filter(r => r.campaignGUID == rs.campaignGUID && r.rulesetStatus.toLowerCase() == 'live');
    let updTot = updRS.reduce((prev, next) => prev + next.rulesetWeight, 0);
    this.rsWeightForm = this.formBuilder.group<DERuleSetWeight>({
      ruleSets: this.formBuilder.array([
        this.formBuilder.group<DERuleSetWeigthRS>({
          guid: new FormControl<string>('', { nonNullable: true }),
          name: new FormControl<string>('', { nonNullable: true }),
          weight: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required] })
        })
      ]),
      total: new FormControl<number>(updTot, { nonNullable: true, validators: [Validators.min(100), Validators.max(100)] })
    });

    this.rsWeightForm.controls.ruleSets.removeAt(0);

    updRS.forEach(ur => {
      let rsName = `${ur.rulesetName} v${ur.rulesetVersion}`;
      this.rsWeightForm.controls.ruleSets.push(
        this.formBuilder.group<DERuleSetWeigthRS>({
          guid: new FormControl<string>(ur.rulesetGUID, { nonNullable: true }),
          name: new FormControl<string>(rsName, { nonNullable: true }),
          weight: new FormControl<number>(ur.rulesetWeight, { nonNullable: true, validators: [Validators.required, Validators.min(0), Validators.max(100)] })
        })
      )
    });

    this.rsWeightForm.controls.ruleSets.valueChanges.subscribe(() => {
      setTimeout(() => {
        let newTotal = this.rsWeightForm.value.ruleSets?.reduce((prev, next) => prev + (next.weight ?? 0), 0);
        this.rsWeightForm.patchValue({
          total: newTotal
        });
        this.rsWeightForm.updateValueAndValidity();
      });
    });

    this.rsWeightFormLoaded = true;
    this.showWeightUpdate = true;
  }

  saveRuleSetWeights() {
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      ruleSetXml: this.generateWeightXml()
    };

    let postSub = this.apiService.post(`decision/ruleset/weights`, body)
      .subscribe({
        next: () => {
          this.getCustomerRuleSets(this.selectedCategory);
          this.showWeightUpdate = false;
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Rule Set percentages successfully updated.'
          })
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to update RuleSet percentages. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  generateWeightXml() {
    let doc = document.implementation.createDocument('', 'rulesets');
    let ruleSets = doc.querySelector('rulesets');
    this.rsWeightForm.controls.ruleSets.controls.forEach(ctrl => {
      let childNode = doc.createElement('rs');
      childNode.setAttribute('guid', ctrl.value.guid ?? '');
      childNode.setAttribute('w', ctrl.value.weight?.toString() ?? '0');
      ruleSets?.appendChild(childNode);
    });

    const serializer = new XMLSerializer();
    return serializer.serializeToString(doc);
  }

  updateRSStatus(rs: DERuleSet) {
    if (rs.rulesetWeight && rs.rulesetWeight > 0) {
      this.selectedRuleSet = null;
      this.toastService.add({
        severity: 'error',
        summary: 'Status Update',
        detail: 'You cannot disable a Rule Set with a weight greater than 0!'
      }, 'center');
      return;
    }
    this.selectedRuleSet = rs;
    this.selectedStatusUpdate = this.updateStatOptions.find(s => s.id == rs.rulesetStatus.toLowerCase()) ?? this.updateStatOptions[0];
    this.showStatusUpdate = true;
  }

  saveRuleSetStatus() {
    if (this.selectedRuleSet == null) {
      this.toastService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Unable to determine Rule Set to update.'
      }, 'center');
      return;
    }

    if (this.selectedRuleSet && this.selectedRuleSet.rulesetStatus.toLowerCase() == this.selectedStatusUpdate.id) {
      this.showStatusUpdate = false;
      return;
    }

    let rsActive: number = this.selectedStatusUpdate.id == 'live' ? 1 : this.selectedStatusUpdate.id == 'design' ? 0 : -1;

    let body = {
      ruleSetGuid: this.selectedRuleSet.rulesetGUID,
      customerGuid: this.portfolio?.customerGuid,
      ruleSetActive: rsActive
    };

    let postSub = this.apiService.post(`decision/ruleset/status`, body)
      .subscribe({
        next: () => {
          this.getCustomerRuleSets(this.selectedCategory);
          this.showStatusUpdate = false;
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Rule Set status successfully updated.'
          })
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to update RuleSet status. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });

  }

  confirmCopyRuleSet(rs: DERuleSet) {
    this.confirmService.confirm({
      key: this.deRuleSetConfirmKey,
      message: `Are you sure you want to copy the Rule Set ${rs.rulesetName} v${rs.rulesetVersion}?`,
      header: 'Copy Rule Set',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        this.copyRuleSet(rs);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'error', summary: 'Declined', detail: 'Rule Set has not been copied.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });

  }

  copyRuleSet(rs: DERuleSet) {
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      campaignGuid: rs.campaignGUID,
      templateGuid: rs.rulesetGUID,
      name: '',
      typeId: 1,
      categoryId: 1
    };

    let postSub = this.apiService.post(`decision/ruleset/create`, body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'RuleSet successfully copied.'
          });
          this.getCustomerRuleSets(this.selectedCategory);
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to copy customer RuleSet. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  confirmDeleteRS(rs: DERuleSet) {
    this.confirmService.confirm({
      key: this.deRuleSetConfirmKey,
      message: this.generateDeleteMsg(rs),
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-circle cds-text-red',
      accept: () => {
        this.deleteRuleSet(rs);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'error', summary: 'Declined', detail: 'Rule Set has not been deleted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });

  }

  deleteRuleSet(rs: DERuleSet) {
    let delSub = this.apiService.get(`decision/ruleset/remove/${this.portfolio?.customerGuid}/${rs.rulesetGUID}`)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'RuleSet successfully removed.'
          });
          this.getCustomerRuleSets(this.selectedCategory);
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save remove RuleSet. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { delSub.unsubscribe(); }
      });
  }

  generateDeleteMsg(rs: DERuleSet): string {
    return `<div class="flex flex-wrap">
    <span class="w-12 text-2xl">Delete Rule Set</span>
    <span class="w-12 pt-2">Are you sure you want to delete the Rule Set "${rs.rulesetName} v${rs.rulesetVersion}"?</span>
    <span class="w-12 pt-4 cds-text-red">NOTE: If you delete this Rule Set, undo will no longer be available.</span>
    </div>`;
  }

  getOfferMatrices() {
    this.matrices = [];
    this.matrices.push({ id: '-1', desc: 'No Offer Matrix' });
    let getSub = this.apiService.get(`matrix/offer/all/${this.portfolio?.customerGuid}`)
      .subscribe({
        next: (data: any) => {
          if (data && data.length > 0) {
            data.forEach((mat: any) => {
              this.matrices.push({
                id: mat.offerMatrixID,
                desc: mat.offerMatrixName
              });
            });
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Offer Matrices for customer. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { getSub.unsubscribe(); }
      });
  }

  getLineMatrices() {
    this.matrices = [];
    this.matrices.push({ id: '-1', desc: 'No Line Matrix' });
    let getSub = this.apiService.get(`matrix/all/${this.portfolio?.customerGuid}`)
      .subscribe({
        next: (data: any) => {
          if (data && data.length > 0) {
            data.forEach((mat: any) => {
              this.matrices.push({
                id: mat.deLineMatrixID,
                desc: mat.deLineMatrixName
              });
            });
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Line Matrices for customer. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { getSub.unsubscribe(); }
      });
  }

  ruleSetSelected(rs: DERuleSet) {
    this.selectedRuleSet = rs;
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      ruleSetGuid: rs.rulesetGUID,
      filterXml: "<filter status=\"-1\" dataSource=\"-1\" field=\"-1\"></filter>"
    };

    const apiTags = forkJoin({
      rules: this.apiService.post(`decision/ruleset/get-rules`, body),
      ruleSet: this.apiService.get(`decision/ruleset/${rs.rulesetGUID}/${this.portfolio?.customerGuid}`)
    }).subscribe(data => {
      let prevItem = this.bcItems.pop();
      if (prevItem) {
        prevItem.command = () => {
          this.showRuleSetRules = false;
          this.showRuleSets = true;
          this.selectedRuleSet = null;
          this.editMode = false;
          this.breadCrumbService.reduceBcList(this.bcItems, prevItem?.label);
        };
        this.bcItems.push(prevItem);
      }
      this.bcItems.push({ label: this.pageViewBc });

      this.ruleSetRules = data.rules as DERuleSetRule[];
      this.ruleSetComplete = data.ruleSet as DERuleSetComplete;

      let matrixId: number = -1;
      if (this.providerInfo && this.ruleSetComplete.offerMatrixID &&
        this.providerInfo.showOfferMatrix != undefined && this.providerInfo.showOfferMatrix) {
        matrixId = this.ruleSetComplete.offerMatrixID;
      }
      else if (this.providerInfo && this.providerInfo.showOfferMatrix != undefined &&
        !this.providerInfo.showOfferMatrix) {
        matrixId = this.ruleSetComplete.deLineMatrixID;
      }

      let fMatrix = this.matrices.find(m => m.id == matrixId.toString());
      if (fMatrix) this.selectedMatrix = fMatrix;

      this.showRuleSets = false;
      this.showRuleSetRules = true;
    }, (err: any) => {
      this.toastService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Unable to get RuleSet Data and Rules. See log for details.'
      }, 'center');
      console.error(err);
    });
  }

  editRuleSet(rs: DERuleSet) {
    if (this.selectedRuleSet?.rulesetID != rs.rulesetID) this.selectedRuleSet = rs;
    this.confirmService.confirm({
      key: this.deRuleSetConfirmKey,
      message: `<div class="flex flex-wrap">
      <span class="w-12 text-2xl">Edit Rule Set</span>
      <span class="w-12 pt-2">Are you sure you want to edit the Rule Set "${rs.rulesetName} v${rs.rulesetVersion}"?</span>
      <span class="w-12 pt-4 cds-text-red">NOTE: If you start editing an active rule set, or the rule set is having traffic then a new version will be created.</span>
      </div>`,
      header: 'Edit Rule Set',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        this.checkEditRuleSet(rs);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'error', summary: 'Declined', detail: 'Declined edit of Rule Set.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });


  }

  checkEditRuleSet(rs: DERuleSet) {
    let checkSub = this.apiService.get(`decision/ruleset/${rs.rulesetGUID}/check-edit/${this.providerInfo?.customerGuid}`)
      .subscribe({
        next: (rsGuid: string) => {

          this.editMode = true;
          let sameGuid: boolean = rsGuid == rs.rulesetGUID;
          if (sameGuid && !this.showRuleSetRules) {
            this.ruleSetSelected(rs);
          }
          if (!sameGuid) {
            this.updateCustomerRuleSets()
              .then(() => {
                let newRS = this.rawRuleSets.find(r => r.rulesetGUID == rsGuid);
                if (!newRS) {
                  this.toastService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Unable to load new RuleSet for Edit'
                  }, 'center');
                  this.editMode = false;
                  this.showRuleSetRules = false;
                  this.showRuleSets = true;
                  this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
                }
                else {
                  this.selectedRuleSet = newRS;
                  if (!this.showRuleSetRules) {
                    this.ruleSetSelected(newRS);
                  }
                }
              });
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to check/verify RuleSet edit capability. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { checkSub.unsubscribe(); }
      });
  }

  updateCustomerRuleSets(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.ruleSets = [];
      this.campaigns = [];
      let rsSub = this.apiService.get(`decision/ruleset/all/${this.portfolio?.customerGuid}/${this.selectedCategory.ruleSetCategoryID}`)
        .subscribe({
          next: (rs: DERuleSet[]) => {
            this.rawRuleSets = rs;
            if (this.selectedStatus.id == 'all') {
              this.ruleSets = this.rawRuleSets;
            }
            else {
              this.ruleSets = this.rawRuleSets.filter(r => r.rulesetStatus && r.rulesetStatus.toLowerCase() == this.selectedStatus.id.toLowerCase());
            }
            this.setRSCampaignAlternate();
            if (this.campaigns.length == 0) {
              this.loadCustomerCampaigns();
            }
          },
          error: (err: any) => {
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to get customer RuleSets. See log for details.'
            }, 'center');
            console.error(err);
            reject();
          },
          complete: () => { rsSub.unsubscribe(); resolve(); }
        });
    });
  }

  updateCustomerRuleSetRules(): Promise<void> {
    return new Promise((resolve, reject) => {
      let body = {
        customerGuid: this.portfolio?.customerGuid,
        ruleSetGuid: this.selectedRuleSet?.rulesetGUID,
        filterXml: "<filter status=\"-1\" dataSource=\"-1\" field=\"-1\"></filter>"
      };
      const apiTags = forkJoin({
        rules: this.apiService.post(`decision/ruleset/get-rules`, body),
        ruleSet: this.apiService.get(`decision/ruleset/${this.selectedRuleSet?.rulesetGUID}/${this.portfolio?.customerGuid}`)
      }).subscribe(data => {
        this.ruleSetRules = data.rules as DERuleSetRule[];
        this.ruleSetComplete = data.ruleSet as DERuleSetComplete;
        resolve();
      }, (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get RuleSet Data and Rules. See log for details.'
        }, 'center');
        console.error(err);
        reject();
      });
    });
  }

  saveEditRS() { 
    let doc = document.implementation.createDocument('', 'rules');
    let rules = doc.querySelector('rules');
    this.ruleSetRules.forEach(r => {
      let childNode = doc.createElement('r');
      childNode.setAttribute('rGuid', r.ruleGUID);
      childNode.setAttribute('stepNo', (this.ruleSetRules.indexOf(r) + 1).toString());
      childNode.setAttribute('ruleId', r.ruleID.toString());
      childNode.setAttribute('parentId', r.parentRuleID?.toString() ?? '');
      rules?.appendChild(childNode);
    });

    const serializer = new XMLSerializer();
    let rulesXml = serializer.serializeToString(doc);

    let body = {
      customerGuid: this.portfolio?.customerGuid,
      ruleSetGuid: uuidv4(),
      rulesXml: rulesXml
    };

    let rulesetSub = this.apiService.post(`decision/ruleset/steps`, body)
    .subscribe({
      next: () => {
        this.editMode = false;
        window.scrollTo(0, 0);
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Rule Set order successfully updated.'
        });
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save Rule Set order. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { rulesetSub.unsubscribe(); }
    });

  }

  cancelEditRS() {
    this.editMode = false;
    this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
    window.scrollTo(0, 0);
  }

  getRuleRowBgStyle(rule: DERuleSetRule) {
    let result = '';
    if (rule.isBreakRule && rule?.backgroundColor?.length > 0) {
      result = rule.backgroundColor;
    }
    return result;
  }

  getRuleRowColorStyle(rule: DERuleSetRule) {
    let result = '';
    if (!rule.ruleActive) {
      result = 'var(--badge-warn-bg)';
    }
    else if (rule.isBreakRule && rule.textColor?.length > 0) {
      result = rule.textColor;
    }
    return result;
  }

  getRuleOrderNoColor(rule: DERuleSetRule) {
    let result = 'var(--text-color)';
    if (rule.isBreakRule && rule.textColor?.length > 0) {
      result = rule.textColor;
    }
    return result;
  }

  goToRulePg(rule: DERuleSetRule) {
    let exists = this.queryParams.find(q => q.page === this.route.component?.name);
    if (exists) {
      let paramIndex = this.queryParams.indexOf(exists);
      exists.route = this.router.url.split('?')[0];
      exists.providerGuid = this.portfolio?.customerGuid ?? '';
      exists.data = {
        ruleSetGuid: this.selectedRuleSet?.rulesetGUID ?? "",
        editMode: this.editMode
      };
      this.queryParams.splice(paramIndex, 1, exists);
    }
    else {
      this.queryParams.push({
        page: this.route.component?.name ?? '',
        route: this.router.url.split('?')[0],
        providerGuid: this.portfolio?.customerGuid ?? '',
        campaignGuid: '',
        data: {
          ruleSetGuid: this.selectedRuleSet?.rulesetGUID ?? "",
          editMode: this.editMode
        }
      });
    }

    this.queryParams.push({
      page: 'DecisionRulesComponent',
      route: '/decison/rules',
      providerGuid: this.portfolio?.customerGuid ?? '',
      campaignGuid: '',
      data: {
        ruleGuid: rule.ruleGUID,
        ruleSetGuid: this.selectedRuleSet?.rulesetGUID ?? '',
        editMode: this.editMode
      }
    });

    this.router.navigate([`/decision/rules`], {
      queryParams: {
        data: btoa(JSON.stringify(this.queryParams))
      }
    });
  }

  confirmdeleteRuleSetRule(rule: DERuleSetRule) {
    this.deleteRule = rule;
    if (!rule.isGroup) {
      this.confirmService.confirm({
        key: this.deRuleSetConfirmKey,
        message: `<div class="flex flex-wrap">
      <span class="w-12 pt-2">Are you sure you want to delete the Rule <strong>"${rule.ruleName}"?</strong></span>
      <span class="w-12 pt-4 cds-text-red">NOTE: If you delete this rule, undo will no longer be available.</span>
      </div>`,
        header: 'Delete Rule',
        icon: 'pi pi-exclamation-circle',
        accept: () => {
          this.deleteRuleSetRule(rule);
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.toastService.add({ severity: 'error', summary: 'Declined', detail: 'Rule has not been deleted.' });
              break;
            case ConfirmEventType.CANCEL:
              this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
              break;
          }
        }
      });
    }
    else {
      this.showDeletRuleWithGroup = true;
    }
  }

  editRuleReorder(event: any) {
    // console.log("rule reorder event fired: ", event);
    // NOT GOING TO USE AT THIS TIME. SAVED ENTIRE TABLE WITH EACH REORDER. 
    //  USING SAVE BUTTON INSTEAD.
  }

  deleteRuleSetRule(rule: DERuleSetRule, deleteChildren: boolean = false) {
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      ruleGuid: rule.ruleGUID,
      deleteChildren: deleteChildren
    };

    let delSub = this.apiService.post('decision/rule/delete', body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Rule has been removed.'
          });
          this.updateCustomerRuleSetRules().then(() => { });
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to remove Rule. See log for details.'
          });
          console.error(err);
        },
        complete: () => { delSub.unsubscribe(); }
      });
  }

  confirmDeleteRule() {
    if (this.deleteRule) this.deleteRuleSetRule(this.deleteRule, this.deleteRuleChildren);
    this.showDeletRuleWithGroup = false;
  }

  matrixOptionChanged(matrix: LookupModel) {
    let showOfrMat = this.providerInfo?.showOfferMatrix;
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      ruleSetGuid: this.selectedRuleSet?.rulesetGUID,
      lineMatrixId: showOfrMat ? -1 : +matrix.id,
      offerMatrixId: showOfrMat ? +matrix.id : -1
    };
    let matSub = this.apiService.post(`decision/ruleset/matrix`, body)
    .subscribe({
      next: () => { 
        this.updateCustomerRuleSets();
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Matrix entry successfully updated.'
        });
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to update matrix selection. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { matSub.unsubscribe(); }
    });
  }

  addMatrix() {
    let tempMatrices = this.matrices.filter(m => m.id != this.selectedMatrix?.id);
    let ruleMatrices = this.ruleSetRules.map(r => r.deLineMatrixID.toString());
    tempMatrices = tempMatrices.filter(t => !ruleMatrices.includes(t.id));
    this.lineMatrices = tempMatrices;

    this.rsLinMatrixForm = new FormGroup<DERuleSetMatrixFormGroup>({
      lineMatrix: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]}),
      rule: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]}),
    });

    this.rsLinMatrixFormLoaded = true;
    this.showAddMatrix = true;
  }

  saveRuleSetMatrix() {
    let body = { 
      customerGuid: this.portfolio?.customerGuid,
      ruleGuid: this.rsLinMatrixForm.value.rule ?? '',
      lineMatrixId: +(this.rsLinMatrixForm.value.lineMatrix ?? -1)
    };

    let postSub = this.apiService.post(`decision/rule/line-matrix`, body)
    .subscribe({
      next: () => { 
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Matrix entry successfully added.'
        });
        this.showAddMatrix = false;

      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to add matrix selection. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { postSub.unsubscribe(); }
    })

  }

  showAddRulesInventory() { 
    this.selectedAddRules = [];
    this.filteredInventoryRules = [];
    let ruleSub = this.apiService.get(`decision/rule/list/${this.portfolio?.customerGuid}/-1`)
    .subscribe({
      next: (rules: DERule[]) => { 
        this.rawInventoryRules = rules;
        this.filteredInventoryRules = rules;
        this.addRuleInvCategory = -1;
        this.addRuleInvFilter = -1;
        let uniqueRules = [...new Map(rules.map(item =>
          [item['ruleCategoryID'], item])).values()];
    
        let uniqueCats = uniqueRules.map(rs => {
          const camp: NumValDropDown = {
            value: rs.ruleCategoryID ?? -2,
            text: rs.ruleCategoryName
          };
          return camp;
        }).filter(f => f.value > -2)
          .sort((a, b) => a.text > b.text ? 1 : -1);

        this.addRuleCats = uniqueCats;
        this.addRuleCats.unshift({
          value: -1,
          text: 'All Categories'
        });

        this.filterAddRules = [];
        this.filterAddRules.push({
          value: -1,
          text: 'All'
        });
        if (this.addRuleCats.length > 1) {
          this.filterAddRules.push(
            {
              value: 0,
              text: 'Not in RuleSet'
            },
            {
              value: 1,
              text: 'Already in RuleSet'
            }
          )
        }
        this.showAddInventoryRules = true;
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get Customer Rules. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { ruleSub.unsubscribe(); }
    });

  }

  addRuleCategorySelected(cat: NumValDropDown) {
    setTimeout(() => {
      this.updateFilteredInventoryRules();      
    });
  }

  addRuleFilterSelected(filter: NumValDropDown) {
    setTimeout(() => {
      this.updateFilteredInventoryRules();      
    });
  }

  updateFilteredInventoryRules() {
    this.selectedAddRules = [];
    this.filteredInventoryRules = [];
    if (this.addRuleInvCategory == -1 && this.addRuleInvFilter == -1) {
      this.filteredInventoryRules = this.rawInventoryRules;
      return;
    }

    let tempRules = this.rawInventoryRules;
    if (this.addRuleInvCategory > -1) {
      tempRules = tempRules.filter(t => t.ruleCategoryID == this.addRuleInvCategory);
    }

    let currRules = this.ruleSetRules.map(r => r.inventoryRuleID);
    
    if (this.addRuleInvFilter == 0) {
      tempRules = tempRules.filter(t => !currRules.includes(t.ruleID));
    }
    else if (this.addRuleInvFilter == 1) {
      tempRules = tempRules.filter(t => currRules.includes(t.ruleID));
    }

    this.filteredInventoryRules = tempRules;
  }

  addRulesInventory() { 
    let doc = document.implementation.createDocument('', 'rules');
    let rules = doc.querySelector('rules');
    this.selectedAddRules.forEach(ar => {
      let childNode = doc.createElement('r');
      childNode.setAttribute('guid', ar.ruleGUID);
      rules?.appendChild(childNode);
    });

    const serializer = new XMLSerializer();
    let rulesXml = serializer.serializeToString(doc);

    let body = {
      customerGuid: this.portfolio?.customerGuid,
      ruleSetGuid: this.selectedRuleSet?.rulesetGUID,
      rulesXml: rulesXml
    };

    let rulesSub = this.apiService.post(`decision/ruleset/rules`, body)
    .subscribe({
      next: () => {
        this.updateCustomerRuleSetRules();
        this.showAddInventoryRules = false;
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Rules successfully added to Rule Set.'
        });
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save new Rules. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { rulesSub.unsubscribe(); }
    });
  }

  addNewRule() { 
    
    let exists = this.queryParams.find(q => q.page === this.route.component?.name);
    if (exists) {
      let paramIndex = this.queryParams.indexOf(exists);
      exists.route = this.router.url.split('?')[0];
      exists.providerGuid = this.portfolio?.customerGuid ?? '';
      exists.data = {
        ruleSetGuid: this.selectedRuleSet?.rulesetGUID ?? "",
        editMode: this.editMode
      };
      this.queryParams.splice(paramIndex, 1, exists);
    }
    else {
      this.queryParams.push({
        page: this.route.component?.name ?? '',
        route: this.router.url.split('?')[0],
        providerGuid: this.portfolio?.customerGuid ?? '',
        campaignGuid: '',
        data: {
          ruleSetGuid: this.selectedRuleSet?.rulesetGUID ?? "",
          editMode: this.editMode
        }
      });
    }

    this.queryParams.push({
      page: 'DecisionRulesComponent',
      route: '/decison/rules',
      providerGuid: this.portfolio?.customerGuid ?? '',
      campaignGuid: this.selectedRuleSet?.campaignGUID ?? '',
      data: {
        ruleGuid: '',
        ruleSetGuid: this.selectedRuleSet?.rulesetGUID ?? '',
        editMode: this.editMode
      }
    });

    this.router.navigate([`/decision/rules`], {
      queryParams: {
        data: btoa(JSON.stringify(this.queryParams))
      }
    });
  }

}
