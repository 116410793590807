export class ProviderInfo {
    campaignInvitationPageURL: string | null = null;
    campaignWebInviteDomain: string | null = null;
    campaignMyAccountDefaultPage: string | null = null;
    campaignMyAccountDomain: string | null = null;
    campaignMyAccountLogoutTimeout: number | null = null;
    campaignMouseFlowTag: string = '';
    providerParentGUID: string = '';
    providerTypeID: number = 0;
    providerRelationshipID: number = 0;
    website: string | null = null;
    city: string | null = null;
    state: string | null = null;
    zipCode: string | null = null;
    address1: string | null = null;
    address2: string | null = null;
    country: string | null = null;
    customerID: number = 0;
    customerGuid: string = '';
    customerName: string = '';
    customerDisplayName: string | null = null;
    phone: string | null = null;
    customerContact: string | null = null;
    emailAddress: string | null = null;
    customerFolderName: string | null = null;
    campaignName: string = '';
    domain: string = '';
    campaignCustomFolder: string = '';
    startURL: string = '';
    campaignFolder: string = '';
    sbT_ApiKey: string = '';
    sbT_OrgCode: string = '';
    sbT_OrgCodeMobile: string = '';
    sbT_OrgCodeCommunication: string | null = null;
    sbT_OrgCodeHelp: string | null = null;
    myAccountDomain: string = '';
    loanTemplatePrefix: string = '';
    loanNumberPrefix: string = '';
    loanAmountTemplate: string = '';
    nLSLoanPortfolioName: string = '';
    notificationFromEmail: string = '';
    notificationFromDisplayName: string = '';
    notificationReplyEmail: string = '';
    notificationReplyDisplayName: string = '';
    communicationStartTime?: Date;
    communicationEndTime?: Date;
    communicationAWSProfileID?: number;
    notificationsAWSProfileID?: number;
    rEFI_BDaysSinceLastPayment: number = 0;
    rEFI_BDaysToTheNextPayment: number = 0;
    rEFI_BDaysToTheNextPaymentFirstOffer: number = 0;
    rEFI_DaysOfferReminderInterval: number = 0;
    rEFI_MinimumBalanceAmount: number = 0;
    rEFI_MinimumPaidAmount: number = 0;
    rEFI_MinimumDaysSinceLastReversal: number = 0;
    clarity_xml_control_file_name: string = '';
    clarity_xml_control_file_version_number: string = '';
    clarity_xml_control_file_name_2nd: string | null = null;
    clarity_xml_control_file_version_number_2nd: string | null = null;
    clarity_xml_location_id: string | null = null;
    clarity_xml_inquiry_purpose_type: string | null = null;
    clarity_xml_inquiry_tradeline_type: string | null = null;
    cCIUsername: string = '';
    cCITokenInactivityExpire: number = 0;
    logoutTimeout: number = 0;
    invitationPageURL: string | null = null;
    saveLoanDetailsOnSingleElement: boolean = false;
    contact_Email: string | null = null;
    contact_Email_Support: string | null = null;
    contact_Phone: string | null = null;
    contact_Fax: string | null = null;
    contact_SMS: string | null = null;
    communicationSMTPProfileID?: number;
    communicationUseSMTP: boolean = false;
    communicationShowSMS: boolean = false;
    notificationSMTPProfileID?: number;
    notificationUseSMTP: boolean = false;
    adminDomain: string | null = null;
    myAccountDefaultPage: string | null = null;
    processPaymentStatment: boolean = false;
    cSRCSSFile: string | null = null;
    adminCSSFile: string | null = null;
    loginCSSFile: string | null = null;
    allowMoreThan1PaymentPerDay: boolean = false;
    vQ_AutoAssignApps: boolean = false;
    vQ_MaxAppsPerRep: number = 0;
    vQ_MinWaitResetApp: number = 0;
    vQ_RepInactivityApp: number = 0;
    aCHCompanyName: string | null = null;
    rCCCompanyName: string | null = null;
    shortName: string | null = null;
    customerSupportPhone: string | null = null;
    payoffAddress: string | null = null;
    newTargetEmailDefaultHtml: string | null = null;
    paymentAmountOverrideJob: boolean = false;
    multiFactorAuthenticationConsumer: boolean = false;
    multiFactorAuthenticationAgent: boolean = false;
    multiFactorAuthenticationExpireSeconds: number = 0;
    settlementDetails_CustomerTrancheID?: number;
    settlementDetails_BankName: string | null = null;
    settlementDetails_BankAccount: string | null = null;
    settlementDetails_BankRouting: string | null = null;
    settlementDetails_BankWiringInstr: string | null = null;
    settlementDetails_ContactName: string | null = null;
    settlementDetails_ContactPhone: string | null = null;
    settlementDetails_ContactEmail: string | null = null;
    settlementDetails_LockBox: string | null = null;
    showOfferMatrix?: boolean;
    paymentMailingAddress: string | null = null;
    supportHours: string | null = null;
    firstStepPreviewURL: string = '';
    clarityLive: boolean = false;
    isAllyEnabled: boolean = false;
    allyApiKey: string | null = null;
    ally_MerchantId: string | null = null;
    blockPOBoxAddress: boolean = false;
    adjustServiceDateDays: number = 0;
    incompleteAppExpiry: number = 0;
    inactivityTimeout:number = 0;
    bopAmountAdjustmentTypeID: number = 1;
    offerProductTypeID: number = 2;
    assignLocationEnabled: boolean = false;
}
