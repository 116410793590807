<div class="card">
    <div class="card-body">
        <form [formGroup]="offerDetailsForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-lg-12 col-xl-6">
                    <h3 class="mb-3">Loan Amt</h3>
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="minLoanAmount"
                            [group]="offerDetailsForm"
                            [options]="loanAmounts"
                            label="MIN">
                        </app-cc-select>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="maxLoanAmount"
                            [group]="offerDetailsForm"
                            [options]="loanAmounts"
                            label="MAX">
                        </app-cc-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-6">
                    <h3 class="mb-3">Procedure Value</h3>
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="minProcedureValue"
                            [group]="offerDetailsForm"
                            [options]="minProcedureAmounts"
                            label="MIN">
                        </app-cc-select>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="maxProcedureValue"
                            [group]="offerDetailsForm"
                            [options]="maxProcedureAmounts"
                            label="MAX">
                        </app-cc-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-6">
                    <h3 class="mb-3">APR</h3>
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="minApr"
                            [group]="offerDetailsForm"
                            [options]="aprList"
                            label="MIN">
                        </app-cc-select>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="maxApr"
                            [group]="offerDetailsForm"
                            [options]="aprList"
                            label="MAX">
                        </app-cc-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-6">
                    <h3 class="mb-3">Down Payment</h3>
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="minDownPayment"
                            [group]="offerDetailsForm"
                            [options]="minDownPayments"
                            label="MIN">
                        </app-cc-select>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="maxDownPayment"
                            [group]="offerDetailsForm"
                            [options]="maxDownPayments"
                            label="MAX">
                        </app-cc-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-6">
                    <h3 class="mb-3">Term</h3>
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="minTerm"
                            [group]="offerDetailsForm"
                            [options]="terms"
                            label="MIN">
                        </app-cc-select>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="maxTerm"
                            [group]="offerDetailsForm"
                            [options]="terms"
                            label="MAX">
                        </app-cc-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-6">
                    <h3 class="mb-3">MDR</h3>
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="minMdr"
                            [group]="offerDetailsForm"
                            [options]="mdrList"
                            label="MIN">
                        </app-cc-select>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="maxMdr"
                            [group]="offerDetailsForm"
                            [options]="mdrList"
                            label="MAX">
                        </app-cc-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-6">
                    <h3 class="mb-3">Vantage score</h3>
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="minVantage"
                            [group]="offerDetailsForm"
                            [options]="minVantages"
                            label="MIN">
                        </app-cc-select>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="maxVantage"
                            [group]="offerDetailsForm"
                            [options]="maxVantages"
                            label="MAX">
                        </app-cc-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-6">
                    <h3 class="mb-3">Payment Amount</h3>
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="minPaymentAmount"
                            [group]="offerDetailsForm"
                            [options]="minPaymentAmounts"
                            label="MIN">
                        </app-cc-select>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <app-cc-select 
                            controlName="maxPaymentAmount"
                            [group]="offerDetailsForm"
                            [options]="maxPaymentAmounts"
                            label="MAX">
                        </app-cc-select>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary btn-main btn-large" type="submit">Save Offer Details</button>
        </form>
    </div>
</div>
