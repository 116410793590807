<div *ngIf="loading">
  <div class="text-2xl">Loading...</div>
  <p3solved-progress-bar [style]="{ height: '0.5rem' }"></p3solved-progress-bar>
</div>

<div *ngIf="hasError" class="text-5xl font-medium text-red-500">
  Unable to load Campaign Alerts.
</div>

<div *ngIf="!loading && showList">
  <span class="text-2xl font-medium">Alert List</span>
  <p-breadcrumb [model]="bcItems"></p-breadcrumb>
  <div class="mt-4">
    <div class="cds-card p-4">
      <div class="grid">
        <div class="col-12 flex flex-row-reverse">
          <p3solved-button
            id="btnNewAlert"
            class="m-2"
            (buttonClick)="newAlert()"
          >
            <span class="px-4">New Alert</span>
          </p3solved-button>
        </div>
        <div class="col-12 pt-4">
          <app-custom-grid
            DataSourceName="dsAdminCampaignAlert"
            (editRow)="editAlert($event)"
          ></app-custom-grid>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading && showEdit">
  <span class="text-2xl font-medium">Add/Edit Alert</span>
  <p-breadcrumb [model]="bcItems"></p-breadcrumb>
  <div class="mt-4">
    <div class="cds-card p-4">
      <form [formGroup]="alertForm" (ngSubmit)="saveAlert()">
        <div class="grid">
          <div class="col-12">
            <p-checkbox
              [formControl]="$any(alertForm.controls['generic'])"
              [binary]="true"
              label="Generic"
            >
            </p-checkbox>
          </div>
          <div class="col-12 pt-4">
            <p3solved-dropdown
              id="ddDelay"
              placeholder="Delay"
              [items]="delays"
              optionLabel="delayText"
              optionValue="alertDelayID"
              [displayFirst]="true"
              [showClear]="false"
              [required]="true"
              formcontrolName="delay"
              (optionSelected)="checkBusinessDelay($event)"
            >
            </p3solved-dropdown>
          </div>
          <div class="col-3 pt-4 align-self-center">
            <p-checkbox
              [formControl]="$any(alertForm.controls['businessDayDelay'])"
              [binary]="true"
              label="Business Day Delay"
              [disabled]="disableBusinessDelay"
            >
            </p-checkbox>
          </div>
          <div class="col-3 pt-4 align-self-center">
            <p-checkbox
              [formControl]="$any(alertForm.controls['sendOnce'])"
              [binary]="true"
              label="Send Only Once"
            >
            </p-checkbox>
          </div>
          <div class="col-3 pt-4 align-self-center">
            <p-checkbox
              [formControl]="$any(alertForm.controls['sendBusHours'])"
              [binary]="true"
              label="Send During Business Hours"
            >
            </p-checkbox>
          </div>
          <div class="col-3 pt-4 align-self-center">
            <p-checkbox
              [formControl]="$any(alertForm.controls['sendTargTime'])"
              [binary]="true"
              label="Send Using Target Time Zone"
            >
            </p-checkbox>
          </div>
          <div class="col-12 pt-4">
            <p3solved-dropdown
              id="ddTime"
              placeholder="Specific Time"
              [items]="times"
              optionLabel="label"
              optionValue="value"
              [displayFirst]="false"
              [showClear]="false"
              formControlName="time"
            >
            </p3solved-dropdown>
          </div>
          <div class="col-12 pt-4">
            <p3solved-dropdown
              id="ddTriggerStatus"
              placeholder="Trigger On App Status"
              [items]="loanStatuses"
              optionLabel="loanAppStatusShortDescription"
              optionValue="loanAppStatusID"
              [displayFirst]="false"
              [showClear]="false"
              formControlName="triggerStatus"
            >
            </p3solved-dropdown>
          </div>
          <div class="col-12 pt-4 grid">
            <div class="col-6">Reset By App Status: *</div>
            <div class="col-6 text-align-end">
              <i
                class="pi pi-refresh text-primary text-xl"
                (click)="resetAppStatusListBx()"
              ></i>
            </div>
          </div>
          <div class="col-12">
            <p-listbox
              [options]="loanStatuses"
              [multiple]="true"
              formControlName="resetStatus"
              optionLabel="loanAppStatusShortDescription"
              optionValue="loanAppStatusID"
              listStyleClass="w-full h-20rem"
            ></p-listbox>
          </div>
          <div class="col-12 pt-4 grid">
            <div class="col-6">Email To Send: *</div>
            <div class="col-6 text-align-end">
              <i
                class="pi pi-refresh text-primary text-xl"
                (click)="resetEmailSendListBx()"
              ></i>
            </div>
          </div>
          <div class="col-12">
            <p-listbox
              [options]="emailList"
              [multiple]="true"
              formControlName="emails"
              optionLabel="notificationName"
              optionValue="notificationID"
              listStyleClass="w-full h-20rem"
              (onClick)="notificiatonClicked('emails')"
            ></p-listbox>
          </div>
          <div class="col-12 pt-4 grid">
            <div class="col-6">SMS To Send: *</div>
            <div class="col-6 text-align-end">
              <i
                class="pi pi-refresh text-primary text-xl"
                (click)="resetSmsSendListBx()"
              ></i>
            </div>
          </div>
          <div class="col-12">
            <p-listbox
              [options]="smsList"
              [multiple]="true"
              formControlName="sms"
              optionLabel="notificationName"
              optionValue="notificationID"
              listStyleClass="w-full h-20rem"
              (onClick)="notificiatonClicked('sms')"
            ></p-listbox>
          </div>
          <div class="col-12 pt-4 grid">
            <div class="col-6">Webhook: *</div>
            <div class="col-6 text-align-end">
              <i
                class="pi pi-refresh text-primary text-xl"
                (click)="resetWebhookListBx()"
              ></i>
            </div>
          </div>
          <div class="col-12">
            <p-listbox
              [options]="webhookList"
              [multiple]="true"
              formControlName="webhooks"
              optionLabel="notificationName"
              optionValue="notificationID"
              listStyleClass="w-full h-20rem"
              (onClick)="notificiatonClicked('webhooks')"
            ></p-listbox>
          </div>
          <div class="col-12 pt-4 grid">
            <div class="col-6">Server Action: *</div>
            <div class="col-6 text-align-end">
              <i
                class="pi pi-refresh text-primary text-xl"
                (click)="resetSvrActListBx()"
              ></i>
            </div>
          </div>
          <div class="col-12">
            <p-listbox
              [options]="serverActList"
              [multiple]="true"
              formControlName="serverActs"
              optionLabel="notificationName"
              optionValue="notificationID"
              listStyleClass="w-full h-20rem"
              (onClick)="notificiatonClicked('serverActs')"
            ></p-listbox>
          </div>
        </div>
        <div class="flex flex-row-reverse">
          <p3solved-button
            id="btnCancelAlert"
            label="Cancel"
            class="m-2 outline"
            (click)="cancelForm()"
          ></p3solved-button>
          <p3solved-button
            id="btnSaveAlert"
            class="m-2"
            buttonType="submit"
            [disabled]="alertForm.untouched || alertForm.invalid"
          >
            <span class="px-4">Save</span>
          </p3solved-button>
        </div>
      </form>
    </div>
  </div>
</div>
