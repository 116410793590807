import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LookupModel } from '../models/lookup-model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MatrixService {

  private _expressionDataSource = new BehaviorSubject<LookupModel[]>([]);
  private _expressionFields = new BehaviorSubject<{ [name: string]: LookupModel[] }>({});
  private actionSource = new Subject<string>();
  private buildSource = new Subject<{ [name: string]: iRangeForm[] }>();
  private saveSource = new Subject<{ [name: string]: any[] }>();
  saveSource$ = this.saveSource.asObservable();
  buildSource$ = this.buildSource.asObservable();
  actionAnnounced$ = this.actionSource.asObservable();
  expressionDataSource$ = this._expressionDataSource.asObservable();
  expressionFields$ = this._expressionFields.asObservable();
  constructor(private api: ApiService) { }

  loadDatasource(customerGuid: any){
    this.api.get(`matrix/datasources/${customerGuid}`)
    .subscribe({
      next: (result: any) => {
        this._expressionDataSource.next(result);
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {  }
    });
  }

  loadFields(customerGuid: any, dataSourceId: string){
    this.api.get(`matrix/fields/${customerGuid}/${dataSourceId}`)
    .subscribe({
      next: (result: any) => {
        var obj = this._expressionFields.getValue();
        obj[dataSourceId] = result || [];
        this._expressionFields.next(obj);
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {  }
    });
  }

  action(action: string){
    this.actionSource.next(action);
  }

  build(name: string, builds: iRangeForm []){
    let obj:any = {};
    obj[name]=builds;
    this.buildSource.next(obj);
  }

  save(name: string, result: any []){
    let obj:any = {};
    obj[name]=result;
    this.saveSource.next(obj);
  }
}

export interface iRangeForm {
  min: number,
  max: number,
  ix: number
}
