export class ProviderRegion {
    providerRegionID: number = 0;
    providerRegionGuid: string = '';
    providerReginoDesc: string = '';
    customerID: number = 0;
    subRegions: ProviderSubRegion[] = [];

}

export class ProviderSubRegion {
    providerSubRegionID: number = 0;
    providerSubRegionGuid: string = '';
    providerRegionID: number = 0;
    providerSubRegionDesc: string = '';
    customerID: number = 0;
}

export class ProviderSubRegionUpload {
    customerGuid: string = '';
    regionGuid: string = '';
    subRegionGuid: string | null = null;
    subRegiondesc: string = '';
}

export class ProviderRegionUpload {
    customerGuid: string = '';
    regionGuid: string | null = null;
    regiondesc: string = '';    
}