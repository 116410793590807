import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ComplianceCriteriaFormGroup } from "../models/form-groups";

export function ComplianceCriteriaFormValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      
      let form = control as FormGroup<ComplianceCriteriaFormGroup>;
      let state = form.value.state;
      let legal = form.value.legal;
      let commentCode = form.value.commentCode;
      let commentCodes = form.value.commentCodes;
      let loanStatus = form.value.loanStatus;
      let appStatus = form.value.appStatus;
      let channel = form.value.channel;
      let startDate = form.value.startDate;
      let endDate = form.value.endDate;
  
      if (!state && !legal && !commentCode && (!commentCodes || commentCodes.length == 0) && 
        !loanStatus && !appStatus && !channel && !startDate && !endDate) {
        return {oneValueNeeded: true};
      }
  
      return null;
    }
  }
  
  
  export function ComplianceCriteriaDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      
      let form = control as FormGroup<ComplianceCriteriaFormGroup>;        
      let startDate = form.value.startDate;
      let endDate = form.value.endDate;
  
      if (startDate && endDate && startDate > endDate) {
        form.controls.startDate.setErrors({invalidDateRange: true});
        form.controls.endDate.setErrors({invalidDateRange: true});
        return {invalidDateRange: true};
      } 
      else {
        form.controls.startDate.setErrors(null);
        form.controls.endDate.setErrors(null);
        return null;
      }
  
    }
  }