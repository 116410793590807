<form [formGroup]="userForm">
    <div class="grid m-4">
        <div class="col-6">
            <p3solved-form-text id="" placeholder="First Name" formControlName="FirstName" [required]="true">
            </p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="" placeholder="Last Name" formControlName="LastName" [required]="true">
            </p3solved-form-text>
        </div>
        <div class="col-12">
            <p3solved-form-text id="" placeholder="Company" formControlName="Company" >
            </p3solved-form-text>
        </div>
        <hr class="m-3" />
        <div class="col-12">
            <p3solved-form-text id="" placeholder="Email" formControlName="LoginEmail" [required]="true">
            </p3solved-form-text>
        </div>
        <div class="col-12">
            <p3solved-form-text id="" placeholder="Cell Phone" formControlName="Mobile" [required]="true">
            </p3solved-form-text>
        </div>
        <div class="col-12">
            <p3solved-form-text id="" placeholder="Work phone" formControlName="BusinessPhone"[required]="true">
            </p3solved-form-text>
        </div>
        <div class="col-12">
            <p3solved-dropdown id="ddlol" [items]="timeZones" optionLabel="desc" placeholder="Time Zone" optionValue="id" [showClear]="false" [required]="true"
            formControlName="TimeZoneID">
        </p3solved-dropdown>
        </div>

    </div>
    <div class="d-flex flex-row-reverse">
        <div class="m-3">
            <p3solved-button id="btncs" label="SAVE" (buttonClick)="save()" [disabled]="saving">
            </p3solved-button>
        </div>
        <div class="m-3">
            <p3solved-button id="btncs" label="CANCEL" (buttonClick)="cancel()" [disabled]="saving">
            </p3solved-button>
        </div>
      </div>
</form>