import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortfolioItem } from 'src/app/models/customer-item';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-compliance-statements',
  templateUrl: './compliance-statements.component.html',
  styleUrls: ['./compliance-statements.component.scss']
})
export class ComplianceStatementsComponent implements OnInit, OnDestroy {

  guidsLoaded: boolean = true;
  showCard: boolean = false;
  subscriptions: Subscription[] = [];
  
  selectedPortfolio: PortfolioItem | null = null;
  
  constructor(
    private portfolioService: PortfolioService,
    private toastService: ToastService
    ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.portfolioService.portfolio$
        .subscribe({
          next: (p: PortfolioItem | null) => {
            this.selectedPortfolio = p;

            if (p) {
              this.guidsLoaded = true;
              this.showCard = true;
            }
            else {
              setTimeout(() => {
                if (!this.selectedPortfolio) {
                  this.guidsLoaded = false;
                }
              }, 500);
            }
          },
          error: (err: any) => {
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to get customer.'
            });
            console.error(err);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
