import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { LookupModel } from 'src/app/models/lookup-model';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-cc-select',
  templateUrl: './cc-select.component.html',
  styleUrls: ['./cc-select.component.scss']
})
export class CcSelectComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() isRequired: boolean = false;
  @Input() id?: string;
  @Input() options: LookupModel[] = [];
  @Input() label: string = "";
  iconRequired = faAsterisk as IconProp;
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  constructor() {
    var d = new Date();
    this.id = d.getTime().toString();
  }

  ngOnInit(): void {
  }

}
