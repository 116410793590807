import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { P3SolvedAutocompleteComponent } from '@p3solved/p3solved-ui';
import { PageGroup } from 'src/app/models/campaign-pages';
import { DERuleField } from 'src/app/models/decision/rule';
import { NumValDropDown } from 'src/app/models/dropdown';
import { PgDataCheckBoxFormGroup, PgDataOptionGroup } from 'src/app/models/form-groups';
import { NameValuePair } from 'src/app/models/name-value-pair';
import { PageDataFunctionLk, PageDataServerValidation, PageDataListItem, PageDataCheckBox } from 'src/app/models/page-data';
import { Validation } from 'src/app/models/validation';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-page-data-checkboxlist',
  templateUrl: './page-data-checkboxlist.component.html',
  styleUrls: ['./page-data-checkboxlist.component.scss']
})
export class PageDataCheckboxlistComponent implements OnInit, AfterViewInit {

  
  @Input() data: PageDataCheckBox | null | undefined = null;
  @Input() pageFields: DERuleField[] = [];
  @Input() pages: PageGroup[] = [];
  @Input() functions: PageDataFunctionLk[] = [];
  @Input() validations: Validation[] = [];
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<any>();

  replaceResults: DERuleField[] = [];
  dbPreSel: NameValuePair[] = [{ name: 'None', value: '0' }, { name: 'Target', value: '1' }, { name: 'CSR', value: '2' }, { name: 'Both Target/CSR', value: '3' }];
  addItemsCount: NumValDropDown[] = [];  
  serverValList: PageDataServerValidation[] = [];
  checkboxForm: FormGroup<PgDataCheckBoxFormGroup> = {} as FormGroup;
  checkboxFormLoaded: boolean = false;
  emptyDataMsg: boolean = false;
  selectedFunction: PageDataFunctionLk | null = null;

  @ViewChild('acTags') acTags: P3SolvedAutocompleteComponent = {} as P3SolvedAutocompleteComponent;
  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.emptyDataMsg = false;
    if (!this.data) {
      this.emptyDataMsg = true;
      return;
    }
    this.initAddItemsCount();
    if (!this.emptyDataMsg) {
      this.initForm();
      this.checkboxFormLoaded = true;
    }
  }

  ngAfterViewInit(): void {
  }

  initAddItemsCount() {
    this.addItemsCount = [];
    for (let i = 1; i < 30; i++) {
      this.addItemsCount.push({
        text: i.toString(),
        value: i
      });
    }
  }

  initForm() {
    this.checkboxForm = this.formBuilder.group<PgDataCheckBoxFormGroup>({
      name: new FormControl<string>(this.data?.alias ?? '', { nonNullable: true }),
      objectId: new FormControl<string>(this.data?.name ?? '', { nonNullable: true }),
      version: new FormControl<number | null>(this.data?.versionId ?? null, { nonNullable: true }),
      css: new FormControl<string>(this.data?.class ?? '', { nonNullable: true }),
      charLimit: new FormControl<string>(this.data?.mC ?? '', { nonNullable: true }),
      required: new FormControl<boolean>(this.data?.fReq == "1", { nonNullable: true }),
      label: new FormControl<string>(this.data?.lbl ?? '', { nonNullable: true }),
      dbField: new FormControl<string | null>(this.data?.dn ?? null, { nonNullable: true }),
      dbPreSel: new FormControl<string | null>(this.data?.for ?? null, { nonNullable: true }),
      order: new FormControl<string>(this.data?.cOrder ?? '', { nonNullable: true }),
      errorMessage: new FormControl<string>(this.data?.em ?? '', { nonNullable: true }),
      valType: new FormControl<number | null>(this.data?.v ?? null, { nonNullable: true }),
      valErrMsg: new FormControl<string>(this.data?.reem ?? '', { nonNullable: true }),
      disabled: new FormControl<boolean>(this.data?.disabled == "1", { nonNullable: true }),
      addItmCnt: new FormControl<number | null>(1, { nonNullable: true }),
      horizontal: new FormControl<boolean>(this.data?.horiz == "1", { nonNullable: true }),
      boxes: this.formBuilder.array([
        this.formBuilder.group<PgDataOptionGroup>({
          id: new FormControl<string>('', { nonNullable: true }),
          cId: new FormControl<string>('', { nonNullable: true }),
          req: new FormControl<boolean>(false, { nonNullable: true }),
          val: new FormControl<string>('', { nonNullable: true }),
          sel: new FormControl<boolean>(false, { nonNullable: true }),
          npid: new FormControl<number|null>(null, { nonNullable: true }),
          text: new FormControl<string>('', { nonNullable: true })
        })
      ])
    });

    this.checkboxForm.controls.boxes.removeAt(0);

    if (this.data?.boxes && this.data.boxes.length > 0) {
      this.data.boxes.forEach(box => {
        this.checkboxForm.controls.boxes.push(
          this.formBuilder.group<PgDataOptionGroup>({
            id: new FormControl<string>(box.id ?? '', { nonNullable: true }),
            cId: new FormControl<string>(box.cId ?? '', { nonNullable: true }),
            req: new FormControl<boolean>(box.req, { nonNullable: true }),
            val: new FormControl<string>(box.val ?? '', { nonNullable: true }),
            sel: new FormControl<boolean>(box.sel, { nonNullable: true }),
            npid: new FormControl<number|null>(box.npid ?? null, { nonNullable: true }),
            text: new FormControl<string>(box.text ?? '', { nonNullable: true })
          })
        );
      });
    }

  }

  saveChkBxForm() {
    if (this.data) {
      this.data.alias = this.checkboxForm.value.name ?? this.data.alias;
      this.data.name = this.checkboxForm.value.objectId ?? this.data.name;
      this.data.versionId = this.checkboxForm.value.version ?? this.data.versionId;
      this.data.class = this.checkboxForm.value.css ?? this.data.class;
      this.data.mC = this.checkboxForm.value.charLimit ?? this.data.mC;
      this.data.fReq = this.checkboxForm.value.required ? '1' : '0';
      this.data.lbl = this.checkboxForm.value.label ?? this.data.lbl;
      this.data.dn = this.checkboxForm.value.dbField ?? this.data.dn;
      this.data.for = this.checkboxForm.value.dbPreSel ?? this.data.for;
      this.data.cOrder = this.checkboxForm.value.order ?? this.data.cOrder;
      this.data.v = this.checkboxForm.value.valType ?? this.data.v;
      this.data.em = this.checkboxForm.value.errorMessage ?? this.data.em;
      this.data.disabled = this.checkboxForm.value.disabled ? '1' : '0';
      this.data.horiz = this.checkboxForm.value.horizontal ? '1' : '0';
      this.data.boxes = [];
    }

    this.checkboxForm.value.boxes?.forEach(box => {
      this.data?.boxes.push({
        id: box.id ?? '',
        cId: box.cId ?? '',
        req: box.req ?? false,
        val: box.val ?? '',
        sel: box.sel ?? false,
        npid: box.npid ?? null,
        text: box.text ?? ''
      })
    });

    let saveObj = {
      element: 'checkboxlist',
      data: this.data
    };

    this.onSave.emit(saveObj);
  }

  cancelForm() {
    this.onCancel.emit();
  }

  addChkItems() {
    let itemCount: number = this.checkboxForm.value.addItmCnt ?? 0;

    if (itemCount > 0) {

      for (let i: number = 0; i < itemCount; i++) {
        this.checkboxForm.controls.boxes.push(
          this.formBuilder.group<PgDataOptionGroup>({
            id: new FormControl<string>((this.checkboxForm.controls.boxes.controls.length + 1).toString(), { nonNullable: true }),
            cId: new FormControl<string>(uuidv4(), { nonNullable: true }),
            req: new FormControl<boolean>(true, { nonNullable: true }),
            val: new FormControl<string>('', { nonNullable: true }),
            sel: new FormControl<boolean>(false, { nonNullable: true }),
            npid: new FormControl<number|null>(null, { nonNullable: true }),
            text: new FormControl<string>('', { nonNullable: true })
          })
        );
      }
    }
  }

  deleteChkItem(i: number) {
    this.checkboxForm.controls.boxes.removeAt(i);
  }

  searchTags(event: any) {
    let search = this.pageFields.filter(pf => pf.fieldName.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    if (search && search.length) this.replaceResults = search;
    else this.replaceResults = [];
  }

  insertTag() {
    let tagTxt = this.acTags.value?.fieldName ?? '';
    if (tagTxt.length) {
      let newVal = this.checkboxForm.value.label + `{!${tagTxt}}`;
      this.checkboxForm.patchValue({
        label: newVal
      });
    }
    this.acTags.value = null;
    this.acTags.innerValue = null;
  }

  functionSelected(funcId: number) {
    this.selectedFunction = this.functions.find(f => f.pageFunctionID == funcId) ?? null;
  }

  insertFunc() {
    if (this.selectedFunction) {
      let newVal = this.checkboxForm.value.label + `.${this.selectedFunction.pageFunctionName}()`;
      this.checkboxForm.patchValue({
        label: newVal
      });
    }
  }

}
