import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TableColumn } from '@p3solved/p3solved-ui';
import { ConfirmationService, ConfirmEventType, MenuItem, Message } from 'primeng/api';
import { PortfolioItem } from 'src/app/models/customer-item';
import { ApiService } from 'src/app/services/api.service';
import { MatrixService } from 'src/app/services/matrix.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-decision-line-matrix',
  templateUrl: './decision-line-matrix.component.html',
  styleUrls: ['./decision-line-matrix.component.scss']
})
export class DecisionLineMatrixComponent implements OnInit {

  editFeatureCode: string = 'DECISION_ENGINE_LINE_MATRIX_EDIT';
  hasEditFeature: boolean = false;

  portfolioItem: PortfolioItem | null | undefined;
  bcItems: MenuItem[] = [];
  matrixes = [];
  loading = true;
  filterFields: string[] = ['dELineMatrixName', 'ruleSetsNo', 'orderNo'];
  columns: TableColumn[] = [];
  saving = false;
  constructor(private apiService: ApiService,
    private router: Router,
    private matrixService: MatrixService,
    private confirmService: ConfirmationService,
    private toastService: ToastService,
    private portfolioService: PortfolioService, 
    public userService: UserService
    ) {
    this.bcItems = [
      { label: 'Decision Engine', routerLink: ['/campaign'] },
      { label: 'Line Matrixes' }
    ];
    this.createColumns();
  }

  ngOnInit(): void {
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      this.matrixService.loadDatasource(this.portfolioItem?.customerGuid);
      this.getList();
    });
    // this.hasEditFeature = this.userService.hasWrite(this.editFeatureCode);
  }

  getList() {
    this.apiService.get(`matrix/all/${this.portfolioItem?.customerGuid}`)
      .subscribe({
        next: (result: any) => {
          this.matrixes = result || [];
          this.loading = false;
        },
        error: (err: any) => {
          this.loading = false;

          let message: Message = {
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to load Customers. See log for details.'
          };
          // this.setError(err, message);
        },
        complete: () => { }
      });
  }

  addLine() {
    this.router.navigate(['/decision/line-matrixes/edit/']);
  }

  editLine(row: any) {
    this.router.navigate(['/decision/line-matrixes/edit/' + row.dELineMatrixGUID]);
  }

  deleteLine(row: any) {
    if (this.saving) return;

    this.confirmService.confirm({
      message: `Are you sure that you want to delete line matrix - ${row.dELineMatrixName}?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-circle',
      key: "key11",
      accept: () => {
        this.deleteConfirmed(row);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Line Matrix has not been deleted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  deleteConfirmed(row: any){
    this.saving = true;
    var customerGuid = this.portfolioService.getGuid();
    var model = {
      CustomerGuid: customerGuid,
      DeLineMatrixGuid: row.dELineMatrixGUID
    }

    this.apiService.post(`matrix/delete`, JSON.stringify(model))
      .subscribe(data => {
        if (data) {
          this.toastService.add({ severity: 'success', summary: 'Success', detail: 'Line Matrix successfully deleted.' });
          this.getList();
        }
        this.saving = false;
      }, err => {
        this.saving = false;

        let message: Message = {
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save. See log for details.'
        };
        console.error(err);
        this.toastService.add(message);
      });
  }

  createColumns() {
    this.columns = [
      {
        field: 'orderNo',
        header: 'Order',
        clickable: true,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'dELineMatrixName',
        header: 'Name',
        clickable: true,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'ruleSetsNo',
        header: '# Rule Sets Using Line Matrix',
        clickable: true,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'ruleSetsActiveNo',
        header: '# Active Rule Sets Using Line Matrix',
        clickable: true,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: '',
        header: '',
        clickable: false,
        show: true,
        sortable: false,
        class: null
      }
    ];
  }

}
