import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private accessToken: any;
  private url = this.getApiBaseUrl();
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };


  constructor(private http: HttpClient) {
  }

  get(path: string) {
    var requestUrl = `${this.url}${path}`;
    // return this.http.get<any>(requestUrl, this.getHttpOptions());
    return this.http.get<any>(requestUrl);
  }

  post(path: string, body: any, contentType: string = 'application/json') {
    var requestUrl = `${this.url}${path}`;
    return this.http.post<any>(requestUrl, body, this.getHttpOptions(contentType));
  }

  getBlob(path: string, body: any, contentType: string = 'application/json') {
    var requestUrl = `${this.url}${path}`;
    return this.http.post<any>(requestUrl, body, this.getBlobOptions(contentType));
  }

  getUpperCaseModel(obj: any) {
    var newObj: { [k: string]: any } = {};
    for (const key in obj) {
      var newKey = key.charAt(0).toUpperCase() + key.slice(1);
      ;
      newObj[newKey] = obj[key];
    }
    return newObj;
  }

  private getHttpOptions(contentType: string) {
    return {
      headers: new HttpHeaders({
        "Content-Type": contentType,
        //   Authorization: "Bearer " + this.getAccessToken(),
      }),
    };
  }

  private getBlobOptions(contentType: string){
    return {
      headers: new HttpHeaders({
        "Content-Type": contentType,
        responseType: 'blob'
      }),
    };
  }

  private getApiBaseUrl() {
    return environment.apiEndpoint;
  }
}
