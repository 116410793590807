import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, Message } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { iRangeForm, MatrixService } from 'src/app/services/matrix.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-edit-decision-line-matrix',
  templateUrl: './edit-decision-line-matrix.component.html',
  styleUrls: ['./edit-decision-line-matrix.component.scss']
})
export class EditDecisionLineMatrixComponent implements OnInit {

  columns: any = {
    vertical: [],
    horizontal: []
  };
  line: any = {
    vertical: {},
    horizontal: {}
  };
  field: any = {
    vertical: '',
    horizontal: ''
  };
  datasource: any = {
    vertical: '',
    horizontal: ''
  };
  loading = false;
  saving = false;
  showLineAssignment = false;
  bcItems: MenuItem[] = [];
  matrixGuid: string | null = '';
  matrix: any;
  lineMatrix: any;
  isReady = false;
  matrixForm = this.fb.group({
    name: ['', Validators.required],
    desc: ['', Validators.required]
  });
  errorColumns: any = {};
  formValues: any[] = [];
  constructor(private activatedRoute: ActivatedRoute,
    private portfolioService: PortfolioService,
    private toastService: ToastService,
    private apiService: ApiService,
    private router: Router,
    private matrixService: MatrixService,
    private fb: FormBuilder) {
    this.bcItems = [
      { label: 'Decision Engine', routerLink: ['/campaign'] },
      { label: 'Line Matrixes', routerLink: ['/decision/line-matrixes'] },
      { label: 'Edit Line Matrixes' }
    ];
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.matrixGuid = paramMap.get('matrixGuid');
      this.getMatrix();
    });

    this.matrixService.buildSource$.subscribe(item => {
      this.addColumns('horizontal', item);
      this.addColumns('vertical', item);
      this.createMatrix();
    });

    this.matrixService.saveSource$.subscribe(item => {
      this.addLine('horizontal', item);
      this.addLine('vertical', item);
      this.postMatrix();
    });
  }

  getMatrix() {
    var customerGuid = this.portfolioService.getGuid();
    this.matrixService.loadDatasource(customerGuid);
    if (this.matrixGuid) {
      this.loading = true;
      this.apiService.get(`matrix/line-matrix/${customerGuid}/${this.matrixGuid}`)
        .subscribe({
          next: (result: any) => {
            this.matrix = result;
            this.matrixForm.patchValue({
              name: this.matrix.DELineMatrixName, desc: this.matrix.DELineMatrixDescription
            })
            this.lineMatrix = JSON.parse(this.matrix.ExpressionJson);
            if (this.lineMatrix?.linematrix?.values?.v?.v){
              this.lineMatrix.linematrix.values.v = [this.lineMatrix.linematrix?.values.v]
            }
            if (this.lineMatrix?.linematrix?.values?.v){
              this.lineMatrix?.linematrix?.values?.v.forEach((item: any) => {
                this.formValues.push(item);
              });
            }
            this.loading = false;
            this.isReady = true;
          },
          error: (err: any) => {
            this.loading = false;

            let message: Message = {
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to load. See log for details.'
            };
            console.error(err);
            this.toastService.add(message);
          },
          complete: () => { }
        });
    } else {
      this.isReady = true;
    }

  }

  buildMatrix() {
    this.columns['horizontal'] = [];
    this.columns['vertical'] = [];
    this.matrixService.action('build');
  }

  saveMatrix() {
    if (this.matrixForm.valid && this.isValidColumns()) {
      this.line.horizontal = null;
      this.line.vertical = null;
      this.matrixService.action('save');
    } else {
      this.toastService.add({ severity: 'error', summary: 'Error', detail: 'Error occured. action cannot be completed. Please enter required fields' });
    }

  }

  isValidColumns(){
    var verticals = this.columns.vertical || [];
    var horizontals = this.columns.horizontal || [];

    var hasErrror = false;
    for(var i = 0; i < verticals.length; i++){
      for(var j = 0; j < horizontals.length; j++){
        var val = this.getValue(i, j);
        if (!val || val == '0'){
          
          hasErrror = true;
        }
      }
    }

    return hasErrror === false;
  }

  cancel(){
    this.router.navigate(['/decision/line-matrixes']);
  }

  addColumns(name: string | number, item: { [x: string]: any; }) {
    if (item[name])
      this.columns[name] = item[name];
  }

  addLine(name: string | number, item: { [x: string]: any; }) {
    if (item[name])
      this.line[name] = item[name];
  }

  postMatrix() {
    if (this.line.horizontal && this.line.vertical && !this.saving) {
      this.saving = true;

      let mtx: any = {
        ha: {
          ...this.line.horizontal,
          range: this.columns.horizontal
        },
        va: {
          ...this.line.vertical,
          range: this.columns.vertical
        },
        values: this.formValues || []
      }

      var customerGuid = this.portfolioService.getGuid();
      var model = {
        CustomerGuid: customerGuid,
        DeLineMatrixGuid: this.matrixGuid,
        DeLineMatrixName: this.matrixForm.value.name,
        DeLineMatrixExpression: this.buildLineMatrixXML(mtx),
        DeLineMatrixDescription: this.matrixForm.value.desc,
        DeLineMatrixCategoryId: 1
      }

      this.apiService.post(`matrix/create-update`, JSON.stringify(model)).subscribe(data => {
        this.toastService.add({ severity: 'success', summary: 'Success', detail: 'Line Matrix successfully updated.' });
        this.saving = false;
        this.router.navigate(['/decision/line-matrixes']);
      }, err => {
        this.saving = false;

        let message: Message = {
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save. See log for details.'
        };
        console.error(err);
        this.toastService.add(message);
      });
    }
  }

  buildLineMatrixXML(mtx: any): string {

    let doc = document.implementation.createDocument('', 'linematrix');
    let matrixes = doc.querySelector('linematrix');
    matrixes?.append(this.getAxisDocument(doc, mtx.va, 'va'));
    matrixes?.append(this.getAxisDocument(doc, mtx.ha, 'ha'));
    var valuesNode = doc.createElement('values');
    mtx.values.forEach((val: any) => {
      let childNode = doc.createElement('v');
      childNode.setAttribute('v', val.v);
      childNode.setAttribute('h', val.h);
      childNode.setAttribute('val', val.val);
      valuesNode?.appendChild(childNode);
    });
    matrixes?.append(valuesNode);
    const serializer = new XMLSerializer();
    return serializer.serializeToString(doc);
  }

  getAxisDocument(doc: XMLDocument, axis: any, name: string){
    let childNode = doc.createElement(name);
    childNode.setAttribute('dsID', axis.dsID);
    childNode.setAttribute('fieldID', axis.fieldID);
    childNode.setAttribute('fieldName', axis.fieldName);
    childNode.setAttribute('num', axis.num);
    childNode.setAttribute('ranges', axis.ranges);
    axis.range.forEach((val: any) => {
      let cc = doc.createElement('range');
      cc.setAttribute('min', val.min);
      cc.setAttribute('max', val.max);
      cc.setAttribute('ix', val.ix);
      childNode?.appendChild(cc);
    })
    return childNode;
  }

  createMatrix() {
    if (this.columns && this.columns.horizontal?.length && this.columns.vertical?.length) {
      this.showLineAssignment = true;
    } else {
      this.showLineAssignment = false;
    }
  }

  getValue(v: any, h: any) {
    var arr = this.formValues || [];
    var val = '';
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].v == v + 1 && arr[i].h == h + 1)
      val = arr[i].val || '';
    }

    this.errorColumns['column' + v + h] = !val || val == '0';
    return val;
  }

  inputChanged(ev: any, v: any, h: any) {
    var val = ev.target.value;
    var arr = this.formValues || [];
    var found = false;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].v == v + 1 && arr[i].h == h + 1){
        arr[i].val = val;
        found = true;
      }
    }

    if (!found){
      this.formValues.push({v: v+ 1, h: h + 1, val : val});
    }
  }

  selectedField(ev:any, name: string){
    this.field[name] = ev[name];
  }

  selectedDs(ev:any, name: string){
    this.datasource[name] = ev[name];
  }

}
