import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NumValDropDown } from 'src/app/models/dropdown';
import { PgDataUnsortFormGroup, PgDataUnsortItemFormGroup } from 'src/app/models/form-groups';
import { PageDataUnsortList } from 'src/app/models/page-data';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-page-data-unsortlist',
  templateUrl: './page-data-unsortlist.component.html',
  styleUrls: ['./page-data-unsortlist.component.scss']
})
export class PageDataUnsortlistComponent implements OnInit {

  @Input() data: PageDataUnsortList | null | undefined = null;
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<any>();

  ulForm: FormGroup<PgDataUnsortFormGroup> = {} as FormGroup;
  ulFormLoaded: boolean = false;
  emptyDataMsg: boolean = false;
  addItemsCount: NumValDropDown[] = [];
  
  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.emptyDataMsg = false;
    if (!this.data) {
      this.emptyDataMsg = true;
      return;
    }
    this.initAddItemsCount();
    this.initForm();
    this.ulFormLoaded = true;
  }

  initAddItemsCount() {
    this.addItemsCount = [];
    for (let i = 1; i < 30; i++) {
      this.addItemsCount.push({
        text: i.toString(),
        value: i
      });
    }
  }

  initForm() {
    this.ulForm = this.formBuilder.group<PgDataUnsortFormGroup>({
      name: new FormControl<string>(this.data?.alias ?? '', { nonNullable: true }),
      objectId: new FormControl<string>(this.data?.name ?? '', { nonNullable: true, validators: [Validators.required] }),
      version: new FormControl<number | null>(this.data?.versionId ?? null, { nonNullable: true }),
      css: new FormControl<string>(this.data?.class ?? '', { nonNullable: true }),
      charLimit: new FormControl<string>(this.data?.mC ?? '', { nonNullable: true }),
      required: new FormControl<boolean>(this.data?.req == "1", { nonNullable: true }),
      addItmCnt: new FormControl<number|null>(null, { nonNullable: true }),
      listItems: this.formBuilder.array([
        this.formBuilder.group<PgDataUnsortItemFormGroup>({
          id: new FormControl<string>('', { nonNullable: true }),
          cId: new FormControl<string>('', { nonNullable: true }),
          text: new FormControl<string>('', { nonNullable: true })
        })
      ])
    });

    this.ulForm.controls.listItems.removeAt(0);
    
    if (this.data?.list && this.data.list.length > 0) {
      this.data.list.forEach(li => {        
        this.ulForm.controls.listItems.push(
          this.formBuilder.group<PgDataUnsortItemFormGroup>({
            id: new FormControl<string>(li.id ?? '', { nonNullable: true }),
            cId: new FormControl<string>(li.cId ?? '', { nonNullable: true }),
            text: new FormControl<string>(li.text ?? '', { nonNullable: true })
          })
        );
      });
    }
  }

  saveULForm() {
    if (this.data) {
      this.data.alias = this.ulForm.value.name ?? this.data.alias;
      this.data.name = this.ulForm.value.objectId ?? this.data.name;
      this.data.versionId = this.ulForm.value.version ?? this.data.versionId;
      this.data.class = this.ulForm.value.css ?? this.data.class;
      this.data.mC = this.ulForm.value.charLimit ?? this.data.mC;
      this.data.req = this.ulForm.value.required ? '1' : '0';
      this.data.list = [];
    }

    let idx: number = 0;
    this.ulForm.controls.listItems.controls.forEach(li => {
      idx++;
      this.data?.list.push({
        id: idx.toString(),
        cId: li.value.cId ?? '',
        req: false,
        val: '',
        sel: false,
        npid: null,
        text: li.value.text ?? ''      
      });
    });

    let saveObj = {
      element: 'unsorted list',
      data: this.data
    };

    this.onSave.emit(saveObj);
  }

  cancelForm() {
    this.onCancel.emit();
  }

  
  addULItems() {
    let itemCount: number = this.ulForm.value.addItmCnt ?? 0;

    if (itemCount > 0) {
      for (let i: number = 0; i < itemCount; i++) {
        this.ulForm.controls.listItems.push(
          this.formBuilder.group<PgDataUnsortItemFormGroup>({
            id: new FormControl<string>((this.ulForm.controls.listItems.length + 1).toString(), { nonNullable: true }),
            cId: new FormControl<string>(uuidv4(), { nonNullable: true }),
            text: new FormControl<string>('li default text', { nonNullable: true })
          })
        );
      }
    }
  }

  deleteULItem(i: number) {
    this.ulForm.controls.listItems.removeAt(i);
  }

}
