import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TableColumn } from '@p3solved/p3solved-ui';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CustomGridComponent } from 'src/app/components/custom-grid/custom-grid.component';
import { ComplianceCriteraData, ComplianceLoanData } from 'src/app/models/compliance-models';
import { PortfolioItem } from 'src/app/models/customer-item';
import { BoolValDropdown } from 'src/app/models/dropdown';
import { ComplianceCriteriaFormGroup } from 'src/app/models/form-groups';
import { ComplianceLookups } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { ComplianceCriteriaDateValidator, ComplianceCriteriaFormValidator } from 'src/app/validators/compliance-validators';

@Component({
  selector: 'app-compliance-dashboard',
  templateUrl: './compliance-dashboard.component.html',
  styleUrls: ['./compliance-dashboard.component.scss']
})
export class ComplianceDashboardComponent implements OnInit, OnDestroy {
  
  editFeatureCode: string = 'COMPLIANCE_DASHBOARD_EDIT';
  hasEditFeature: boolean = false;
  guidsLoaded: boolean = true;
  showDashboard: boolean = false;
  showCreate: boolean = false;
  criteriaFormLoaded: boolean = false;
  today: Date = new Date();

  blnChecksLoans: BoolValDropdown[] = [
    {text: 'Loan Agreement - PDF', value: false},
    {text: 'Loan Application - PDF', value: false},
    {text: 'Decision Engine Rules - PDF', value: false},
    {text: 'Email Communications - PDF', value: false},
    {text: 'SMS Communications - PDF', value: false},
    {text: 'Loan Ledger - XLS', value: false},
    {text: 'IP Address - XLS', value: false},
    {text: 'Down Payment - XLS', value: false}
  ];
  blnChecksCriteria: BoolValDropdown[] = [
    {text: 'Loan Agreement', value: false},
    {text: 'Loan Application', value: false},
    {text: 'Loan Ledger as PDF', value: false},
    {text: 'Loan Ledger as XLS', value: false},
    {text: 'Decision Engine Rules', value: false},
    {text: 'IP Address as XLS', value: false},
    {text: 'Email Communications', value: false},
    {text: 'SMS Communications', value: false},
    {text: 'XML of Down Payment', value: false}
  ];


  
  bcItems: MenuItem[] = [];
  subscriptions: Subscription[] = [];
  loanNumbers: string[] = [];
  dataByLoan: ComplianceLoanData[] = [];
  dataByCriteria: ComplianceCriteraData[] = [];
  loanColumns: TableColumn[] = [];
  criteriaColumns: TableColumn[] = [];
  datePipe: DatePipe = new DatePipe('en-US');
  
  selectedPortfolio: PortfolioItem | null = null;
  lookups: ComplianceLookups = {} as ComplianceLookups;

  criteriaForm: FormGroup<ComplianceCriteriaFormGroup> = {} as FormGroup;

  loanNumsSearch: string = '';
  pageCompDBConfirmKey: string = 'CompAndAuditDBConfirmSvcKey';
  

  @ViewChild('cgCompDB') cgCompDB: CustomGridComponent = {} as CustomGridComponent;

  private readonly pageBaseBc: string = 'Compliance & Audit';

  constructor(
    private portfolioService: PortfolioService,
    private apiService: ApiService,
    private toastService: ToastService,
    private breadcrumbService: BreadcrumbService,
    private navService: NavService,
    private userService: UserService,
    private confirmService: ConfirmationService
    ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.portfolioService.portfolio$
      .subscribe({
        next: (p: PortfolioItem | null) => {
          this.selectedPortfolio = p;

          if (p) {
            this.guidsLoaded = true;
            this.showDashboard = true;
            this.initSearch();
          }
          else {
            setTimeout(() => {                
              if (!this.selectedPortfolio) {                
                this.guidsLoaded = false;
              }              
            }, 500);
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get customer.'
          });
          console.error(err);
        }
      })      
    );

    this.hasEditFeature = this.userService.hasWrite(this.editFeatureCode);

    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: this.pageBaseBc }
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  initSearch() {
    this.getLookups();
    this.blnChecksLoans.forEach(l => l.value = false);
    this.blnChecksCriteria.forEach(c => c.value = false);
    this.loanColumns = [
      {
        field: 'loanNumber',
        header: 'Loan Number',
        class: null,
        sortable: false,
        show: true,
        clickable: false,
      },
      {
        field: 'nlsAcctRefNo',
        header: 'Acct Ref No',
        class: null,
        sortable: false,
        show: true,
        clickable: false,
      },
      {
        field: 'name',
        header: 'Name',
        class: null,
        sortable: false,
        show: true,
        clickable: false,
      },
      {
        field: 'state',
        header: 'State',
        class: null,
        sortable: false,
        show: true,
        clickable: false,
      },
      {
        field: 'legalStatus',
        header: 'Legal',
        class: null,
        sortable: false,
        show: true,
        clickable: false,
      },
      {
        field: 'loanStatus',
        header: 'Loan',
        class: null,
        sortable: false,
        show: true,
        clickable: false,
      },
      {
        field: 'loanAppStatus',
        header: 'App Status',
        class: null,
        sortable: false,
        show: true,
        clickable: false,
      },
      {
        field: 'loanAppDate',
        header: 'Application Date',
        class: null,
        sortable: false,
        show: true,
        clickable: false,
      },
      {
        field: 'campaignName',
        header: 'Channel',
        class: null,
        sortable: false,
        show: true,
        clickable: false,
      }
    ];

    this.criteriaColumns = JSON.parse(JSON.stringify(this.loanColumns));

    this.initCriteriaForm();
  }

  getLookups() {
    let lookSub = this.apiService.get(`compliance/${this.selectedPortfolio?.customerGuid}/lookups`)
    .subscribe({
      next: (data: ComplianceLookups) => {
        this.lookups = data;
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Unable to get Compliance Lookups. See log for details.`
        }, 'center');
        console.error(err);
      },
      complete: () => { lookSub.unsubscribe(); }
    });
  }

  initCriteriaForm() {
    this.criteriaForm = new FormGroup<ComplianceCriteriaFormGroup>({
      state: new FormControl<string|null>(null, {nonNullable: true}),
      legal: new FormControl<string|null>(null, {nonNullable: true}),
      commentCode: new FormControl<string|null>(null, {nonNullable: true}),
      commentCodes: new FormControl<string[]|null>(null, {nonNullable:true}),
      loanStatus: new FormControl<string|null>(null, {nonNullable: true}),
      appStatus: new FormControl<string|null>(null, {nonNullable: true}),
      channel: new FormControl<string|null>(null, {nonNullable: true}),
      startDate: new FormControl<Date|null>(null, {nonNullable:true}),
      endDate: new FormControl<Date|null>(null, {nonNullable:true}),
    }, {
      validators: [ComplianceCriteriaFormValidator(), ComplianceCriteriaDateValidator()]
    });

    this.criteriaFormLoaded = true;
  }

  newRequest() {
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showCreate = false; this.showDashboard = true; this.bcItems.pop(); this.breadcrumbService.removeBcCommand(this.bcItems, prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({
      label: 'Create'
    });
    
    this.loanNumsSearch = '';
    this.showDashboard = false;
    this.showCreate = true;

  }

  async searchLoans() {
    let loanNums: string[] = this.loanNumsSearch.split(',');
    let newLoans: string[] = [];
   
    for (let i: number = 0; i < loanNums.length; i++) {
      let loanNumber: string = loanNums[i].trim();
      if (this.loanNumbers.includes(loanNumber)) {
        this.toastService.add({
          severity: 'warn',
          detail: `Loan Number ${loanNumber} already loaded, ignoring...`
        }, 'center');
      }
      else {      
        let isValid: boolean = await this.apiService.get(`compliance/${this.selectedPortfolio?.customerGuid}/loan/${loanNumber}/check`).toPromise<boolean>();        
        if (isValid) {
          newLoans.push(loanNumber);
          this.loanNumbers.push(loanNumber);
        }
        else {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Loan Number ${loanNumber} is not valid!`
          }, 'center');
        }
      }
    }
    this.loanNumsSearch = '';
   this.getLoanData(newLoans);
  }

  getLoanData(loans: string[]) {
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      loanNumbers: loans.join(',')
    };
    let dataSub = this.apiService.post('compliance/loans/data', body)
    .subscribe({
      next: (loans: ComplianceLoanData[]) => {
        this.dataByLoan = this.dataByLoan.concat(loans);
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to retrieve loan data. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { dataSub.unsubscribe(); }
    })
  }

  // confirmLoanDelete(loan: ComplianceLoanData) {
  //   console.log(loan);
  //   this.confirmService.confirm({
  //     key: this.pageCompDBConfirmKey,
  //     message: 'Are you sure that you want to delete this Loan?',
  //     header: 'Delete Confirmation',
  //     icon: 'pi pi-exclamation-circle',
  //     accept: () => {
  //       this.deleteLoan(loan);
  //     },
  //     reject: (type: any) => {
  //       switch (type) {
  //         case ConfirmEventType.REJECT:
  //           this.toastService.add({ severity: 'error', summary: 'Declined', detail: 'Loan has not been deleted.' });
  //           break;
  //         case ConfirmEventType.CANCEL:
  //           this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
  //           break;
  //       }
  //     }
  //   });

  // }

  deleteLoan(loan: ComplianceLoanData) {
    let numIdx = this.loanNumbers.indexOf(loan.loanNumber ?? '');
    if (numIdx != -1) this.loanNumbers.splice(numIdx, 1);
    let loanIdx = this.dataByLoan.indexOf(loan);
    if (loanIdx != -1) this.dataByLoan.splice(loanIdx, 1);
  }

  loanDownload() {
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      loanNumbers: this.loanNumbers.join(','),
      criteriaXML: null,
      loanCount: this.loanNumbers.length,
      chkLoanAgreement: this.blnChecksLoans.find(b => b.text == 'Loan Agreement - PDF')?.value ?? false,
      chkLoanApplication: this.blnChecksLoans.find(b => b.text == 'Loan Application - PDF')?.value ?? false,
      chkLoanLedgerPDF: false,
      chkLoanLedgerXLS: this.blnChecksLoans.find(b => b.text == 'Loan Ledger - XLS')?.value ?? false,
      chkDERules: this.blnChecksLoans.find(b => b.text == 'Decision Engine Rules - PDF')?.value ?? false,
      chkIPAddress: this.blnChecksLoans.find(b => b.text == 'IP Address - XLS')?.value ?? false,
      chkCommunicationEmail: this.blnChecksLoans.find(b => b.text == 'Email Communications - PDF')?.value ?? false,
      chkCommunicationSMS: this.blnChecksLoans.find(b => b.text == 'SMS Communications - PDF')?.value ?? false,
      chkDownPayment: this.blnChecksLoans.find(b => b.text == 'Down Payment - XLS')?.value ?? false,
    };

    let downSub = this.apiService.post(`compliance/setup`, body)
    .subscribe({
      next: () => { 
        this.breadcrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Compliance request has been scheduled to be processed.'
        });
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to schedule compliance requeset. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { downSub.unsubscribe(); }
    });

  }
  
  submitCriteriaForm() {
    let criteriaXml = this.getCriteriaXML();
    
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      criteria: criteriaXml
    };

    let criteriaSub = this.apiService.post(`compliance/criteria/data`, body)
    .subscribe({
      next: (data: ComplianceCriteraData[]) => {
        this.dataByCriteria = data;

      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to retrieve criteria data. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { criteriaSub.unsubscribe(); }
    });    
  }

  criteriaDownload() {
    let criteria = this.getCriteriaXML();
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid,
      loanNumbers: null,
      criteriaXML: criteria,
      loanCount: this.dataByCriteria.length,
      chkLoanAgreement: this.blnChecksCriteria.find(b => b.text == 'Loan Agreement')?.value ?? false,
      chkLoanApplication: this.blnChecksCriteria.find(b => b.text == 'Loan Application')?.value ?? false,
      chkLoanLedgerPDF: this.blnChecksCriteria.find(b => b.text == 'Loan Ledger as PDF')?.value ?? false,
      chkLoanLedgerXLS: this.blnChecksCriteria.find(b => b.text == 'Loan Ledger as XLS')?.value ?? false,
      chkDERules: this.blnChecksCriteria.find(b => b.text == 'Decision Engine Rules')?.value ?? false,
      chkIPAddress: this.blnChecksCriteria.find(b => b.text == 'IP Address as XLS')?.value ?? false,
      chkCommunicationEmail: this.blnChecksCriteria.find(b => b.text == 'Email Communications')?.value ?? false,
      chkCommunicationSMS: this.blnChecksCriteria.find(b => b.text == 'SMS Communications')?.value ?? false,
      chkDownPayment: this.blnChecksCriteria.find(b => b.text == 'XML of Down Payment')?.value ?? false,
    };

    let downSub = this.apiService.post(`compliance/setup`, body)
    .subscribe({
      next: () => { 
        this.breadcrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Compliance request has been scheduled to be processed.'
        });
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to schedule compliance requeset. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { downSub.unsubscribe(); }
    });

  }

  getCriteriaXML(): string {
    let criteriaXml: string = '';
    let noteCategory: string = '';
    let startDate: string = '';
    let endDate: string = '';
    if (this.criteriaForm.value.commentCodes && this.criteriaForm.value.commentCodes.length > 0) {
      noteCategory = this.criteriaForm.value.commentCodes.join(',');
    }
    if (this.criteriaForm.value.startDate) {
      startDate = this.datePipe.transform(this.criteriaForm.value.startDate, 'MM/dd/yyyy') ?? '';
    }
    if (this.criteriaForm.value.endDate) {
      endDate = this.datePipe.transform(this.criteriaForm.value.endDate, 'MM/dd/yyyy') ?? '';
    }
    let doc = document.implementation.createDocument('', 'template');
    let template = doc.querySelector('template');
    let childNode = doc.createElement('criteria');
    
    childNode.setAttribute('state', this.criteriaForm.value.state ?? '');
    childNode.setAttribute('legal_status', this.criteriaForm.value.legal ?? '');
    childNode.setAttribute('note_category', noteCategory);
    childNode.setAttribute('loan_status', this.criteriaForm.value.loanStatus ?? '');
    childNode.setAttribute('loan_app_status', this.criteriaForm.value.appStatus ?? '');
    childNode.setAttribute('campaign', this.criteriaForm.value.channel ?? '');
    childNode.setAttribute('date_from', startDate);
    childNode.setAttribute('date_to', endDate);
    
    template?.appendChild(childNode);
    const serializer = new XMLSerializer();      
    criteriaXml = serializer.serializeToString(doc);
    criteriaXml = criteriaXml.replace('<template>', '').replace('</template>', '');
    return criteriaXml;
  }


}
