<div *ngIf="emptyDataMsg">
    <div class="text-2xl cds-error-text">ERROR! Unable to find data.</div>
</div>

<div *ngIf="!emptyDataMsg && ddFormLoaded">
    <form [formGroup]="ddForm">
        <div class="grid pr-4">
            <div class="col-4">
                <p3solved-form-text id="txtNm" placeholder="Name" formControlName="name"
                    [disabled]="data.versionId != 0"></p3solved-form-text>
            </div>
            <div class="col-4">
                <p3solved-form-text id="txtObjId" placeholder="Object ID" formControlName="objectId"
                    [disabled]="data.versionId != 0"></p3solved-form-text>
            </div>
            <div class="col-4">
            </div>
            <div class="col-4 flex flex-wrap">
                <div class="w-6">
                    <p3solved-form-text id="txtLabel" placeholder="Label" formControlName="label"></p3solved-form-text>
                </div>
                <div class="w-6 pl-2">
                    <p3solved-form-text id="txtCss" placeholder="Css Class" formControlName="css"></p3solved-form-text>
                </div>
            </div>
            <div class="col-4 flex flex-wrap align-items-center">
                <div class="w-10">
                    <span class="p-float-label">
                        <p-autoComplete #acTags inputId="replTagAC" [showEmptyMessage]="true"
                            [suggestions]="replaceResults" (completeMethod)="searchTags($event)" field="fieldName"
                            styleClass="w-12" inputStyleClass="border-noround w-12" [dropdown]="false"
                            [completeOnFocus]="true" [forceSelection]="true"></p-autoComplete>
                        <label for="replTagAC">Replace Tags</label>
                    </span>
                </div>
                <div class="w-2 pl-2 flex">
                    <span class="cds-text-color cursor-pointer" (click)="insertTag()">Insert</span>
                </div>
                <div class="w-10">
                    <p3solved-dropdown id="ddJSFunc" placeholder="Functions" [items]="functions"
                        optionLabel="pageFunctionName" optionValue="pageFunctionID" appendTo="body"
                        (optionSelected)="functionSelected($event)"></p3solved-dropdown>
                </div>
                <div class="w-2 pl-2 flex">
                    <span class="cds-text-color cursor-pointer" (click)="insertFunc()">Insert</span>
                </div>
            </div>
            <div class="col-4 flex flex-wrap">
                <div class="w-6">
                    <p3solved-dropdown id="ddDBFld" placeholder="DB Field" [items]="pageFields" optionLabel="fieldName"
                        optionValue="fieldGUID" [showClear]="true" formControlName="dbField"
                        appendTo="body"></p3solved-dropdown>
                </div>
                <div class="w-6 pl-2">
                    <p3solved-dropdown id="ddShowDBVal" placeholder="Pre-Select DB Value" [items]="dbPreSel"
                        optionLabel="name" optionValue="value" [showClear]="true" formControlName="dbPreSel"
                        appendTo="body"></p3solved-dropdown>
                </div>
                <div class="w-6">
                    <p3solved-input-number id="txtLimit" placeholder="Max Length" formControlName="charLimit"
                        mode="decimal" [useGrouping]="false"></p3solved-input-number>
                </div>
                <div class="w-6">
                    <p3solved-input-number id="txtOrder" placeholder="Order" formControlName="order" mode="decimal"
                        [useGrouping]="false"></p3solved-input-number>
                </div>
            </div>
            <div class="col-4 flex">
                <div class="w-6">
                    <p3solved-form-text id="txtErrMsg" placeholder="Error Message"
                        formControlName="errorMessage"></p3solved-form-text>
                </div>
            </div>
            <div class="col-4 flex">
                <div class="w-6">
                    <p3solved-dropdown id="ddValType" placeholder="Validation Type" [items]="validations"
                        optionValue="validationID" optionLabel="validationName" formControlName="valType"
                        appendTo="body" [showClear]="true"></p3solved-dropdown>
                </div>
                <div class="w-6 pl-2">
                    <p3solved-form-text id="txtValErr" placeholder="Validation Error"
                        formControlName="valErrMsg"></p3solved-form-text>
                </div>
            </div>

            <div class="col-2 align-self-center">
                <p-checkbox [formControl]="$any(ddForm.controls['required'])" [binary]="true"
                    label="Required"></p-checkbox>
            </div>
            <div class="col-2 align-self-center">
                <p-checkbox [formControl]="$any(ddForm.controls['disabled'])" [binary]="true"
                    label="Disabled"></p-checkbox>
            </div>
            <div class="col-12 flex flex-wrap">
                <div class="w-3">
                    <p-checkbox [formControl]="$any(ddForm.controls['serverVal'])" [binary]="true"
                        label="Server Validation"></p-checkbox>
                </div>
                <div class="w-8 grid">&nbsp;</div>
                <div class="w-6 grid" *ngIf="ddForm.value.serverVal">
                    <div class="col-6">
                        <p3solved-dropdown id="ddSvrVals" placeholder="Select Validation" [items]="serverValList"
                            optionLabel="serverValidationName" optionValue="serverValidationID"
                            formControlName="serverValDD"></p3solved-dropdown>
                    </div>
                    <span class="pl-2 cds-text-color cursor-pointer align-self-center "
                        (click)="addServerValidator()">Add Server Validator</span>
                </div>
                <div class="w-12 p-5" *ngIf="ddForm.value.serverVal">
                    <table>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Error Message</th>
                            <th></th>
                        </tr>
                        <ng-container formArrayName="serverVals">
                            <tr *ngFor="let svr of ddForm.controls.serverVals.controls; index as i">
                                <ng-container [formGroupName]="i">
                                    <td>#{{svr.value.id}}</td>
                                    <td>
                                        {{svr.value.name}}
                                    </td>
                                    <td>
                                        <p3solved-form-text id="txtSvrErr{{i}}"
                                            formControlName="errMsg"></p3solved-form-text>
                                    </td>
                                    <td class="pl-2">
                                        <i class="cursor-pointer pi pi-trash text-primary" (click)="deleteServerVal(i)"></i>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>
            <div class="col-12 flex flex-wrap">
                <div class="w-2 align-self-center text-align-end">
                    <span class="cursor-pointer cds-text-color" (click)="addDDItems()">Add Options</span>
                </div>
                <div class="w-1 pl-2">
                    <p3solved-dropdown id="ddAddNum" placeholder="Num" [items]="addItemsCount" optionLabel="text"
                        optionValue="value" formControlName="addItmCnt">
                    </p3solved-dropdown>
                </div>
                <div class="w-2 pl-2">
                    <p3solved-dropdown id="ddAddLoc" placeholder="Position" [items]="addItemPositions" optionLabel="text"
                        optionValue="value" formControlName="addItmPos">
                    </p3solved-dropdown>
                </div>
                <div class="w-1 pl-2" *ngIf="ddForm.value.addItmPos == 3">
                    <p3solved-dropdown id="ddAddPosNum" placeholder="Item" [items]="getCurrentItemCountDD()"
                        optionLabel="text" optionValue="value" formControlName="addItmCurr">
                    </p3solved-dropdown>
                </div>           
                <div class="w-12 p-5">
                    <table>
                        <tr>
                            <th></th>
                            <th>Text</th>
                            <th>Value</th>
                            <th>Go To Page</th>
                            <th>Select</th>
                            <th></th>
                        </tr>
                        <ng-container formArrayName="items">
                            <tr *ngFor="let itm of ddForm.controls.items.controls; index as i">
                                <ng-container [formGroupName]="i">
                                    <td>#{{itm.value.id}}</td>
                                    <td class="pr-4">
                                        <p3solved-form-text id="txt{{i}}"
                                            formControlName="text"></p3solved-form-text>
                                    </td>
                                    <td class="pr-4">
                                        <p3solved-form-text id="txtVal{{i}}"
                                            formControlName="val"></p3solved-form-text></td>
                                    <td class="pr-4">
                                        <p3solved-dropdown id="goTo{{i}}" placeholder="Select" [items]="pages"
                                            optionLabel="pageName" optionValue="pageID" formControlName="npid"
                                            appendTo="body" [showClear]="true">
                                        </p3solved-dropdown>
                                    </td>
                                    <td class="pr-4">
                                        <p-radioButton [value]="true" formControlName="sel"
                                            (onClick)="ddItemSelChanged($event, i)" class="mr-7"></p-radioButton>
                                        </td>
                                    <td>
                                        <i class="cursor-pointer pi pi-trash text-primary" (click)="deleteDDItem(i)"></i>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>
            <div class="col-12 flex flex-row-reverse">
                <p3solved-button id="btnS" label="Save" (buttonClick)="saveDDForm()"></p3solved-button>
                <p3solved-button id="btnCncl" label="Cancel" class="px-4 outline" (buttonClick)="cancelForm()"></p3solved-button>
            </div>
        </div>
    </form>
</div>