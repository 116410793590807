<div class="flex align-items-center bg-bluegray-600 text-white">
  <div class="flex-shrink-0">
    <i class="bi bi-person-circle m-2" style="font-size: 2rem"></i>
  </div>
  <div class="flex-grow-1 my-2">
    <p class="h4 mb-1">{{ user?.firstName }} {{ user?.lastName }}</p>
    <p class="mb-2">{{ user?.email }}</p>
  </div>
</div>
<ul class="list-group profile-menu">
  <li
    class="list-group-item list-group-item-action"
    (click)="doUserMenu('profile')"
  >
    <fa-icon [icon]="iconUserCircle" [classes]="['me-2']"></fa-icon> My Profile
  </li>
  <li
    class="list-group-item list-group-item-action"
    (click)="doUserMenu('admin')"
  >
    <fa-icon [icon]="iconBars" [classes]="['me-2 far']"></fa-icon> Admin
  </li>
  <li class="list-group-item list-group-item-action" (click)="doUserMenu('cs')">
    <fa-icon [icon]="iconThLarge" [classes]="['me-2']"></fa-icon> CS
  </li>
  <!-- <li class="list-group-item list-group-item-action" (click)="doUserMenu('bop')">
        <fa-icon [icon]="iconTh" [classes]="['me-2']"></fa-icon> BOP
    </li> -->
  <li
    class="list-group-item list-group-item-action"
    (click)="doUserMenu('logout')"
  >
    <fa-icon [icon]="iconPowerOff" [classes]="['me-2']"></fa-icon> Logout
  </li>
</ul>
