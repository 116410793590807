<span class="text-2xl font-medium">Upcoming {{ header }}</span>
<p-breadcrumb [model]="bcItems"></p-breadcrumb>
<div class="row">
  <div class="col-6">
    <div class="cds-card p-4">
      <div class="row">
        <div class="col-12 text-2xl">Upcoming {{ header }}</div>
        <div
          class="col-6"
          [ngClass]="
            upcomingSelected == 0
              ? 'cds-placeholder-color'
              : 'cds-text-color cursor-pointer'
          "
          (click)="getUpcoming(0)"
        >
          Today
        </div>
        <div
          class="col-6"
          [ngClass]="
            upcomingSelected == 1
              ? 'cds-placeholder-color'
              : 'cds-text-color cursor-pointer'
          "
          (click)="getUpcoming(1)"
        >
          Tomorrow
        </div>
      </div>
    </div>
    <div class="cds-card p-4">
      <app-custom-grid
        #cgComplianceData
        id="cgComplianceData"
        [overrideCustomer]="true"
      >
      </app-custom-grid>
    </div>
  </div>

  <div class="col-6">
    <div class="cds-card p-4">
      <span class="text-2xl">Data by Criteria</span>
      <form
        *ngIf="criteriaFormLoaded"
        [formGroup]="criteriaForm"
        (ngSubmit)="submitCriteriaForm()"
      >
        <div class="w-12 pt-1">
          <p3solved-dropdown
            id="State"
            placeholder="State"
            [items]="lookups.states"
            [showClear]="true"
            optionLabel="desc"
            optionValue="id"
            formControlName="state"
          ></p3solved-dropdown>
        </div>
        <div class="w-12 pt-1">
          <p3solved-dropdown
            id="LegalStatus"
            placeholder="Legal Status"
            [items]="lookups.legalStatuses"
            [showClear]="true"
            optionLabel="desc"
            optionValue="id"
            formControlName="legal"
          ></p3solved-dropdown>
        </div>
        <div class="w-12 pt-1">
          <p3solved-dropdown
            id="CommentCode"
            placeholder="Comment Code"
            [items]="lookups.noteCategories"
            [showClear]="true"
            optionLabel="desc"
            optionValue="id"
            formControlName="commentCode"
          >
          </p3solved-dropdown>
        </div>
        <div class="w-12 pt-1">
          <p3solved-dropdown
            id="loanStatus"
            placeholder="Loan Status"
            [items]="lookups.loanStatuses"
            [showClear]="true"
            optionLabel="desc"
            optionValue="id"
            formControlName="loanStatus"
          ></p3solved-dropdown>
        </div>

        <div class="w-12 pt-3 flex flex-wrap align-self-center">
          <div class="w-12 text-lg">Date Range</div>
          <div class="w-4">
            <p3solved-datepicker
              id="dtFrom"
              placeholder="Start"
              panelStyleClass="dp-max"
              [maxDate]="today"
              formControlName="startDate"
            >
              <div
                class="w-12 my-1 p-error text-xs"
                *ngIf="
                  !criteriaForm.controls.startDate.pristine &&
                  criteriaForm.controls.startDate.errors
                "
              >
                <p
                  *ngIf="
                    criteriaForm.controls.startDate.errors?.invalidDateRange
                  "
                >
                  Start Date must be before End Date.
                </p>
              </div>
            </p3solved-datepicker>
          </div>
          <div class="w-1 align-self-center text-center">
            <span class="text-lg">TO</span>
          </div>
          <div class="w-4">
            <p3solved-datepicker
              id="dtTo"
              placeholder="End"
              panelStyleClass="dp-max"
              [maxDate]="today"
              formControlName="endDate"
            >
              <div
                class="w-12 my-1 p-error text-xs"
                *ngIf="
                  !criteriaForm.controls.startDate.pristine &&
                  criteriaForm.controls.startDate.errors
                "
              >
                <p
                  *ngIf="
                    criteriaForm.controls.startDate.errors?.invalidDateRange
                  "
                >
                  Start Date must be before End Date.
                </p>
              </div>
            </p3solved-datepicker>
          </div>
          <div class="w-3 align-self-center mt-3">
            <p3solved-button
              id="btnFormSubmit"
              buttonType="submit"
              class="outline"
              label="Search"
              [disabled]="!criteriaForm.valid"
            ></p3solved-button>
          </div>
        </div>
      </form>
    </div>

    <div class="cds-card p-4 =">
      <span class="w-12 text-2xl">Data by Loan</span>
      <div class="flex flex-wrap align-self-center">
        <div class="w-6">
          <p3solved-form-text
            id="txtLoanSrch"
            placeholder="Loan Number(s)"
            [(ngModel)]="loanNumsSearch"
          >
          </p3solved-form-text>
        </div>
        <div class="w-4 align-self-center mt-3">
          <p3solved-button
            id="btnLoanSrch"
            label="Search"
            class="px-4 outline"
            [disabled]="!loanNumsSearch.length"
            (buttonClick)="searchLoans()"
          ></p3solved-button>
        </div>
      </div>
    </div>
  </div>
</div>
