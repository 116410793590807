

export const AppConfigs = {
    bopAppsLastSearchStorageKey: 'bopAppsLastSearchStorageKey',
    selectedPortfolioStorageKey: "selected-portfolio",
    loggedInuserGuid: "loggedInuserGuidCC1",
    selectedCustomerGuid: "selectedCustomerGuid",
    authenticatedUserAccount: "authenticatedUserAccountCC",
    homeAccountId: "msalhomeAccountId",
    selectedCampaignStorageKey: "selectedCampaign",
    providerInfoForm: 'providerInfoForm',
    settlementForm: 'settlementForm',
    providerCommForm: 'providerCommForm',
    providerLoanForm: 'providerLoanForm',
    otherForm: 'otherForm'
}