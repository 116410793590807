import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PortfolioItem } from 'src/app/models/customer-item';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-user-role-edit',
  templateUrl: './user-role-edit.component.html',
  styleUrls: ['./user-role-edit.component.scss']
})
export class UserRoleEditComponent implements OnInit {

  saving = false;
  roleForm: FormGroup = new FormGroup({});
  userTypeId = 0;
  bcItems: MenuItem[] = [{ label: 'Users' , url: 'users/dashboard'}, { label: 'Roles', url: 'users/roles' }, { label: 'Role Edit' }];
  portfolioItem: PortfolioItem | null | undefined;
  loading = false;
  userType:any;
  isNew = false;
  constructor(private api: ApiService,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    private activatedRoute: ActivatedRoute,
    private portfolioService: PortfolioService) { }

  ngOnInit(): void {
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      this.getRole();
    });

    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.userTypeId = Number(paramMap.get('userTypeId'));
      this.isNew = !this.userTypeId;
      this.getRole();
    });
  }

  initForm() {
    this.roleForm = this.fb.group({
      UserTypeName: [this.isNew ? "" : this.userType?.pages[0].UserTypeName || '', Validators.required],
      HierarchyOrder: [this.userType?.pages[0].HierarchyOrder || 0, Validators.required]
    });
  }

  getRole(){
    if (this.portfolioItem && this.portfolioItem.customerGuid && (this.userTypeId || this.isNew)) {
      this.loading = true;
      this.api.get(`AdminUser/user-type/${this.portfolioItem.customerGuid}/${this.userTypeId}`).subscribe(data => {
        this.userType = data;
        this.userType.hierarchy.sort((a: { hierarchyOrder: number; }, b: { hierarchyOrder: number; }) => a.hierarchyOrder - b.hierarchyOrder);
        (this.userType.features || []).forEach((item: any) => {
          if (item.AllowWrite){
            item.access = 'write';
          }else if(item.AllowRead && !item.AllowWrite){
            item.access = 'read';
          }else{
            item.access = 'hide';
          }
        });
        this.initForm();
        this.loading = false;
      });
    } else {
      //toast error?
    }
  }

  isReadDisabled(fCode: string){
    var allowReadFeatures = ["CSR_CUSTOMER_CHANGE_LEGAL_STATUS", "CSR_CUSTOMER_CHANGE_PAY_STATUS", "CSR_CUSTOMER_CHANGE_CONCESSION",
    "CSR_CUSTOMER_COMMUNICATIONS_NOTE_LIST", "CSR_CUSTOMER_SHOW_MCC_PAYMENT"];
    var i = allowReadFeatures.findIndex(x => x.toLocaleLowerCase() == fCode.toLocaleLowerCase());
    return i == -1;
  }

  cancel(){
    this.router.navigate(['/users/roles/']);
  }

  save(){
    if (this.roleForm.valid){

      this.userType.features.forEach((item: any) => {
        if (item.access == 'write'){
          item.AllowWrite = true;
          item.AllowRead = true;
        }else if (item.access == 'read'){
          item.AllowWrite = false;
          item.AllowRead = true;
        }else{
          item.AllowWrite = false;
          item.AllowRead = false;
        }
      });
      let obj = {
        CustomerGuid: this.portfolioItem?.customerGuid,
        UserTypeID: this.userTypeId,
        UserTypeName: this.roleForm.value.UserTypeName,
        HierarchyOrder: Number(this.roleForm.value.HierarchyOrder),
        tabs: this.userType.tabs,
        pages: this.userType.pages,
        features: this.userType.features
      };
      this.saving = true;
      var json = JSON.stringify(JSON.stringify(obj));
      this.api.post(`adminuser/role-update`, json).subscribe(res => {
        this.toast.addSuccess("Role updated!");
        this.saving = false;
        this.router.navigate(['/users/roles/']);
      }, err => {
        console.error(err);
        this.saving = false;
        this.toast.addError("Invalid form. Server Error occured.");
      });
    }else{

    }
  }

}
