import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { Campaign } from 'src/app/models/campaign';
import { CampaignNotification, CampaignNotifications, CampaignNotificationSave } from 'src/app/models/campaign-notifications';
import { PortfolioItem } from 'src/app/models/customer-item';
import { CampaignService } from 'src/app/services/campaign.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { DataGridInfo, DataGridResult, GridDataService } from 'src/app/services/grid-data.service';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { CampaignLookups, LookupModel } from 'src/app/models/lookup-model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailFormGroup, WebhookFormGroup } from 'src/app/models/form-groups';
import { DropdownString } from 'src/app/models/dropdown';
import { ProviderService } from 'src/app/services/provider.service';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { BasicField, VersionTag, CustomTag } from 'src/app/models/basic-field';
import { Editor } from 'primeng/editor';
import { AutoComplete } from 'primeng/autocomplete';
import { forkJoin } from 'rxjs';
import { Version } from 'src/app/models/version';
import { CampaignSeeds, CampaingSeedDropDown } from 'src/app/models/seeds';
import { SendTest } from 'src/app/models/send-test';
import { NotificationType } from 'src/app/models/enums';
import { NavService } from 'src/app/services/nav-service.service';
import { UserService } from 'src/app/services/user.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TableColumn } from '@p3solved/p3solved-ui';

@Component({
  selector: 'app-campaign-notifications',
  templateUrl: './campaign-notifications.component.html',
  styleUrls: ['./campaign-notifications.component.scss']
})
export class CampaignNotificationsComponent implements OnInit {

  editPageId: number = 38;
  hasEditAccess: boolean = false;

  campNotAlertFeatureCode: string = 'CAMPAIGN_NOTIFICATION_ACCESS_ALERT_GENERIC';
  hasCampNotAlertFeature: boolean = false;

  valResponses: DropdownString[] = [{ value: 'Response Status' }, { value: 'Response Status Text' }, { value: 'Response Headers' }, { value: 'Response Text' }];
  valComparer: DropdownString[] = [{ value: 'Equal' }, { value: 'Contains' }, { value: 'One Of' }, { value: 'In' }]
  valValue: string = '';

  bcItems: MenuItem[] = [];
  loading: boolean = false;
  hasError: boolean = false;

  showList: boolean = true;
  showEdit: boolean = false;

  portfolio: PortfolioItem | null = null;
  campaignNotifications: CampaignNotifications[] = [];

  campaignGuid: string = '';

  campaigns: Campaign[] = [];
  selectedCampaign: Campaign | null = null;

  dataSourceNotifications: string = 'dsAdminCampaignNotification';

  testEmailModal: boolean = false;
  toEmailsList: string = '';

  columns: TableColumn[] = [];
  filterFields: string[] = [];

  isGeneric: boolean = false;
  isMarketing: boolean = false;
  campaignLookups: CampaignLookups = new CampaignLookups();
  selectedType: LookupModel | null = null;

  versions: Version[] = [];
  selectedVersion: Version | null = null;

  selectedAction: LookupModel | null = null;
  actionParameters: string = '';

  notificationName: string | null = null;

  smsTo: string = '';
  smsBody: string = '';

  selectedHttpRequest: LookupModel | null = null;

  emailFormGroup: FormGroup = {} as FormGroup;
  webhookForm: FormGroup = {} as FormGroup;
  customerLookups: ICustomerLookups = {} as ICustomerLookups;
  fromEmail: string | undefined;
  fromDisplay: string | undefined;
  replyEmail: string | undefined;
  replyDisplay: string | undefined;


  basicFieldsRaw: BasicField[] = [];
  availTags: BasicField[] = [];
  tagResults: BasicField[] = [];

  versionTagsRaw: VersionTag[] = [];
  availVersionTags: VersionTag[] = [];
  versionTagResults: VersionTag[] = [];

  customTagsRaw: CustomTag[] = [];
  availCustomTags: CustomTag[] = [];
  customTagResults: CustomTag[] = [];

  notificationGuid: string | null = null;
  notificationToEdit: CampaignNotification | null = null;

  showNotificationNameError: boolean = false;
  showSMSToError: boolean = false;
  showSMSBodyError: boolean = false;

  sendTestEmailModal: boolean = false;
  sendTestSMSModal: boolean = false;
  sendTestEmailAddress: string | null = null;
  sendTestSMSPhone: string | null = null;
  testSeeds: CampaingSeedDropDown[] = [];
  selectedTestSeed: CampaingSeedDropDown | null = null;

  @ViewChild('emailEditor') private emailEditor: Editor = {} as Editor;
  @ViewChild('emailTag') private emailTag: AutoComplete = {} as AutoComplete;
  @ViewChild('smsTag') private smsTag: AutoComplete = {} as AutoComplete;
  @ViewChild('whTag') private whTag: AutoComplete = {} as AutoComplete;

  @ViewChild('emailTagVersion') private emailTagVersion: AutoComplete = {} as AutoComplete;
  @ViewChild('smsTagVersion') private smsTagVersion: AutoComplete = {} as AutoComplete;
  @ViewChild('whTagVersion') private whTagVersion: AutoComplete = {} as AutoComplete;

  @ViewChild('emailTagCustom') private emailTagCustom: AutoComplete = {} as AutoComplete;
  @ViewChild('smsTagCustom') private smsTagCustom: AutoComplete = {} as AutoComplete;
  @ViewChild('whTagCustom') private whTagCustom: AutoComplete = {} as AutoComplete;

  private readonly pageBaseBc: string = 'Notifications';
  constructor(
    private fb: FormBuilder,
    private campaignService: CampaignService,
    private gridDataService: GridDataService,
    private bcService: BreadcrumbService,
    private portfolioService: PortfolioService,
    private toastService: ToastService,
    private confirmService: ConfirmationService,
    private apiService: ApiService,
    private providerService: ProviderService,
    private navService: NavService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.loadBookMarks();
    this.portfolioService.portfolio$
      .subscribe((data: any) => {
        this.campaignNotifications = [];
        this.selectedCampaign = null;
        if (data) {
          this.portfolio = data as PortfolioItem;
          this.getCampaignLookups();
        }
      });

    this.campaignService.campaigns$.subscribe(c => {
      this.campaignNotifications = [];
      this.campaigns = c != null ? c : [];
      if (this.campaigns.length == 1) {
        this.campaignGuid = this.campaigns[0].campaignGuid;
        this.showNotificationData();
      }      
    });

    this.campaignService.selectedCampaign$.subscribe(c => {
      this.selectedCampaign = c;
      if (c) {
        this.campaignGuid = c.campaignGuid;
        this.showNotificationData();

        const apiTags = forkJoin({
          basicTags: this.campaignService.getBasicFields(),
          versionTags: this.campaignService.getVersionTags(),
          customTags: this.campaignService.getCustomTags(),
          seeds: this.campaignService.getSeeds()
        })
          .subscribe(data => {
            let basicData = data.basicTags as BasicField[];
            this.basicFieldsRaw = basicData.filter(t => t.fieldName != "Custom Version").sort((a, b) => a.fieldName > b.fieldName ? 1 : -1);
            this.availTags = [...this.basicFieldsRaw];

            this.versionTagsRaw = [];
            for (let i = 1; i < 21; i++) {
              let show: boolean = data.versionTags[0][`labelV${i}Show`].toLowerCase() === 'true';
              if (show) {
                this.versionTagsRaw.push({ fieldName: data.versionTags[0][`labelV${i}`] });
              }
            }
            this.availVersionTags = [...this.versionTagsRaw];

            if (data.seeds && data.seeds.length > 0) {
              let seedsList = data.seeds as CampaignSeeds[];
              this.selectedTestSeed = null;
              this.testSeeds = [];
              seedsList.forEach(sl => {
                if (sl.firstName || sl.lastName) {
                  this.testSeeds.push({
                    text: `${sl.firstName} ${sl.lastName} (${sl.invitationCode || 'not available'})`,
                    value: sl.targetGuid
                  });                  
                }
              });
            }
          });
      }
    });

    this.providerService.customerLookups$.subscribe(custLookups => {
      if (custLookups) {
        this.customerLookups = custLookups;
        let emails = this.customerLookups.smtpEmails;
        if (emails) {
          this.fromEmail = emails.find(e => e.id === "1")?.desc;
          this.fromDisplay = emails.find(e => e.id === "2")?.desc;
          this.replyEmail = emails.find(e => e.id === "3")?.desc;
          this.replyDisplay = emails.find(e => e.id === "4")?.desc;
        }
        else {
          this.fromDisplay = undefined;
          this.fromEmail = undefined;
          this.replyDisplay = undefined;
          this.replyEmail = undefined;
        }
      }
    });
    this.hasEditAccess = this.userService.hasPageAccess(this.editPageId);
    this.hasCampNotAlertFeature = this.userService.hasWrite(this.campNotAlertFeatureCode);
  }

  loadBookMarks() {
    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Campaigns', routerLink: ['/campaign'], command: () => { this.navService.setLeftNavSelection('Campaign'); } },
      { label: this.pageBaseBc }
    ];
  }

  showNotificationData() {
    this.loading = true;
    let dataGrid: DataGridInfo = {
      customerGuid: this.portfolio?.customerGuid,
      campaignGuid: this.campaignGuid,
      dataSourceName: this.dataSourceNotifications,
      remoteIPAddress: ''
    };

    this.gridDataService.getDataInfo(dataGrid)
      .then((result: DataGridResult) => {
        if (result.error) {
          return this.setError(result.error);
        } else {
          this.setupColumns();
          this.campaignNotifications = [];
          for (let i: number = 0; i < result.data.length; i++) {
            this.campaignNotifications.push({
              campaignGuid: result.data[i]['CampaignGUID'],
              customerGuid: result.data[i]['CustomerGUID'],
              emailSubject: result.data[i]['Email Subject'],
              isGeneric: result.data[i]['Is Generic'],
              isMarketing: result.data[i]['Is Marketing'],
              notificationName: result.data[i]['Notification Name'],
              notificationGuid: result.data[i]['NotificationGUID'],
              rowCount: result.data[i]['RowCount'],
              rowNumber: result.data[i]['RowNumber'],
              type: result.data[i]['Type']
            });
          };
          this.loading = false;
        }
      }, (err: any) => {
        this.setError(err);
      });
  }

  setupColumns() {
    this.columns = [    
      {
        field: 'campaignGuid',
        header: 'Campaign Guid',
        show: false,
        sortable: false,
        clickable: false, 
        class: null
      },
      {
        field: 'customerGuid',
        header: 'Customer Guid',
        show: false,
        sortable: false,
        clickable: false, 
        class: null
      },
      {
        field: 'isGeneric',
        header: 'Generic',
        show: true,
        sortable: true,
        clickable: false, 
        class: null
      },
      {
        field: 'notificationName',
        header: 'Notification Name',
        show: true,
        sortable: true,
        clickable: false, 
        class: null
      },
      {
        field: 'type',
        header: 'Type',
        show: true,
        sortable: true,
        clickable: false, 
        class: null
      },
      {
        field: 'emailSubject',
        header: 'Email Subject',
        show: true,
        sortable: true,
        clickable: false, 
        class: null
      },  
      {
        field: 'isMarketing',
        header: 'Marketing',
        show: false,
        sortable: false,
        clickable: false, 
        class: null
      },
      {
        field: 'notificationGuid',
        header: 'Notification Guid',
        show: false,
        sortable: false,
        clickable: false, 
        class: null
      }
    ];
    this.filterFields = this.columns.filter(c => c.show).map(m => { return m.field; });
  }

  showTestEmailModal() {
    this.testEmailModal = true;
  }

  sendTestEmails() {
    let body = {
      CustomerGuid: this.portfolio?.customerGuid,
      CampaignGuid: this.campaignGuid,
      ToEmails: this.toEmailsList
    };

    let sendTestEmails = this.apiService.post(`campaign/send-test-all-email/`, body)
      .subscribe({
        next: (result: boolean) => {
          if (result) {
            this.toastService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Test Emails Sent.'
            });
          }
          else {
            this.toastService.add({
              severity: 'error',
              summary: 'Unable to process',
              detail: 'An error occurred.'
            });
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Unable to process',
            detail: 'An exception occurred.'
          });
        },
        complete: () => { sendTestEmails.unsubscribe(); }
      });
  }

  confirmDelete(row: any) {
    if (!this.hasEditAccess) {
      this.toastService.addError('You do not have permissions to perform this action!');
      return;
    }
    this.confirmService.confirm({
      message: 'Are you sure that you want to delete this Notification?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        this.deleteRecord(row);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'error', summary: 'Declined', detail: 'Notification has not been deleted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  deleteRecord(row: any) {
    let keyFieldXML = "<Field Name=\"NotificationGUID\" Value=\"" + row['notificationGuid'] + "\" />";

    let body = {
      customerGuid: row.customerGuid,
      campaignGuid: row.campaignGuid,
      targetGuid: '00000000-0000-0000-0000-000000000000', // this is never set so use all zero's since legacy does this.
      dataSourceName: this.dataSourceNotifications,
      keyFieldValues: keyFieldXML,
      remoteIPAddress: ''
    };

    this.gridDataService.deleteOneElement(body)
      .then((result: boolean) => {
        if (result) {
          let index = this.campaignNotifications.indexOf(row);
          this.campaignNotifications.splice(index, 1);

          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Campaign Notification Deleted.'
          });

        } else {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred.  Please retry.'
          });
        }
      }, (err: any) => {
        this.setError(err);
        this.toastService.add({
          severity: 'error',
          summary: 'Campaign Notification Delete ERROR',
          detail: 'There was an exception'
        }, 'center');
      });
  }

  newNotification() {
    this.createForms();
    this.isGeneric = false;
    this.isMarketing = false;
    this.selectedType = null;
    this.selectedAction = null;
    this.actionParameters = '';
    this.notificationToEdit = null;
    this.notificationGuid = null;
    if (this.versions) this.selectedVersion = this.versions[0];
    this.notificationName = null;

    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showEdit = false; this.showList = true; this.bcItems.pop(); this.bcService.removeBcCommand(this.bcItems, prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({
      label: 'New Notification'
    });
    this.showList = false;
    this.showEdit = true;
  }

  setCampaign(c: Campaign) {
    this.campaignService.selectCampaign(c);
    this.campaignGuid = c.campaignGuid;
    this.showNotificationData();
  }

  setError(error: any): any {
    this.hasError = true;
    setTimeout(() => {
      this.hasError = false;
    }, 10000);
    this.campaignNotifications = [];
    console.error(error);
  }

  getCampaignLookups() {
    let campSub = this.apiService.get(`campaign/notification-lookups/${this.portfolio?.customerGuid}`)
      .subscribe({
        next: (data: CampaignLookups) => {
          this.campaignLookups = data;
        },
        error: (err: any) => { console.error(err); },
        complete: () => { campSub.unsubscribe(); }
      });
  }

  createForms() {
    this.notificationName = null;
    this.smsBody = '';
    this.smsTo = '';


    let request = this.campaignLookups.requestTypes.find(rt => rt.desc.toLowerCase() === 'post');
    if (!request) request = {} as LookupModel;
    let type = this.campaignLookups.contentTypes.find(ct => ct.desc.toLowerCase() === 'application/json');
    if (!type) type = {} as LookupModel;
    this.webhookForm = this.fb.group<WebhookFormGroup>({
      request: new FormControl<LookupModel>(request, { nonNullable: true }),
      url: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      headers: new FormControl<string | null>('', { nonNullable: false }),
      contentType: new FormControl<LookupModel>(type, { nonNullable: true, validators: [Validators.required] }),
      responseStatus: new FormControl<DropdownString>(this.valResponses[0], { nonNullable: true }),
      responseCompare: new FormControl<DropdownString>(this.valComparer[0], { nonNullable: true }),
      responseValue: new FormControl<string>('200', { nonNullable: true }),
      postData: new FormControl<string | null>('', { nonNullable: false })
    });

    this.emailFormGroup = this.fb.group<EmailFormGroup>({
      to: new FormControl<string | null>(null, { nonNullable: false, validators: [Validators.required] }),
      bcc: new FormControl<string | null>(null, { nonNullable: false }),
      subject: new FormControl<string | null>(null, { nonNullable: false, validators: [Validators.required] }),
      attachments: new FormControl<string | null>(null, { nonNullable: false }),
      body: new FormControl<string | null>(null, { nonNullable: false, validators: [Validators.required] })
    });
  }

  typeSelected(event: LookupModel) {
    this.createForms();
    this.showNotificationNameError = false;
    this.showSMSBodyError = false;
    this.showSMSToError = false;
    this.availTags = [...this.basicFieldsRaw];
    this.availVersionTags = [...this.versionTagsRaw];
    this.tagResults = [];
    switch (+event.id) {
      case 1:
        let emailAddTag = this.availTags.find(at => at.fieldName.toLowerCase() === 'email address');
        if (emailAddTag) {
          this.emailFormGroup.patchValue({ to: this.formatTagInsert(emailAddTag.fieldName) });
        }
        break;
      case 2:
        let phoneAddTag = this.availTags.find(at => at.fieldName.toLowerCase() === 'phone');
        if (phoneAddTag) {
          this.smsTo = this.formatTagInsert(phoneAddTag.fieldName);
        }
        break;
      default:
        break;
    }

    let verSub = this.getNotificationVersions()
      .subscribe({
        next: (data: Version[]) => {
          this.versions = data;
          this.selectedVersion = this.versions[0];
        },
        error: (err: any) => { console.error(err); },
        complete: () => { verSub.unsubscribe(); }
      });
  }

  getNotificationVersions() {
    let url = `campaign/get-versions/${this.portfolio?.customerGuid}`;

    // ADD NOTIFICATION GUID FOR EXISTING NOTIFICATIONS
    if (this.notificationGuid) url += `/${this.notificationGuid}`;

    return this.apiService.get(url);
  }

  searchTags(event: any) {
    let search = this.availTags.filter(at => at.fieldName.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    if (search && search.length) this.tagResults = search;
    else this.tagResults = [];
  }

  insertEmailTag(tag: BasicField) {
    let quill = this.emailEditor.getQuill();
    let selection = quill.getSelection(true);
    quill.insertText(selection.index, this.formatTagInsert(tag.fieldName));
    this.emailFormGroup.patchValue({ body: quill.root.innerHTML });
    let index = this.availTags.indexOf(tag);
    if (index > -1) {
      this.availTags.splice(index, 1);
      this.emailTag.value = null;
      this.emailTag.inputFieldValue = "";
      this.emailTag.clear();
      this.tagResults = [];
    }
  }

  insertSMSTag(tag: BasicField) {
    this.smsBody += this.formatTagInsert(tag.fieldName);
    let index = this.availTags.indexOf(tag);
    if (index > -1) {
      this.availTags.splice(index, 1);
      this.smsTag.value = null;
      this.smsTag.inputFieldValue = "";
      this.smsTag.clear();
      this.tagResults = [];
    }
  }

  insertWHTag(tag: BasicField) {
    let currVal = this.webhookForm.value.postData;
    let tagInsert = this.formatTagInsert(tag.fieldName);
    this.webhookForm.patchValue({ postData: currVal + tagInsert });
    let index = this.availTags.indexOf(tag);
    if (index > -1) {
      this.availTags.splice(index, 1);
      this.whTag.value = null;
      this.whTag.inputFieldValue = "";
      this.whTag.clear();
      this.tagResults = [];
    }
  }

  searchVersionTags(event: any) {
    let search = this.availVersionTags.filter(at => at.fieldName.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    if (search && search.length) this.versionTagResults = search;
    else this.versionTagResults = [];
  }

  insertVersionEmailTag(tag: VersionTag) {
    let quill = this.emailEditor.getQuill();
    let selection = quill.getSelection(true);
    quill.insertText(selection.index, this.formatTagInsert(tag.fieldName));
    this.emailFormGroup.patchValue({ body: quill.root.innerHTML });
    let index = this.availVersionTags.indexOf(tag);
    if (index > -1) {
      this.availVersionTags.splice(index, 1);
      this.emailTagVersion.value = null;
      this.emailTagVersion.inputFieldValue = "";
      this.emailTagVersion.clear();
      this.versionTagResults = [];
    }
  }

  insertVersionSMSTag(tag: VersionTag) {
    this.smsBody += this.formatTagInsert(tag.fieldName);
    let index = this.availVersionTags.indexOf(tag);
    if (index > -1) {
      this.availVersionTags.splice(index, 1);
      this.smsTagVersion.value = null;
      this.smsTagVersion.inputFieldValue = "";
      this.smsTagVersion.clear();
      this.versionTagResults = [];
    }
  }

  insertVersionWHTag(tag: VersionTag) {
    let currVal = this.webhookForm.value.postData;
    let tagInsert = this.formatTagInsert(tag.fieldName);
    this.webhookForm.patchValue({ postData: currVal + tagInsert });
    let index = this.availVersionTags.indexOf(tag);
    if (index > -1) {
      this.availVersionTags.splice(index, 1);
      this.whTagVersion.value = null;
      this.whTagVersion.inputFieldValue = "";
      this.whTagVersion.clear();
      this.versionTagResults = [];
    }
  }

  searchCustomTags(event: any) {
    let search = this.availCustomTags.filter(at => at.customTagName.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    if (search && search.length) this.customTagResults = search;
    else this.customTagResults = [];
  }

  insertCustomEmailTag(tag: CustomTag) {
    let quill = this.emailEditor.getQuill();
    let selection = quill.getSelection(true);
    quill.insertText(selection.index, this.formatTagInsert(tag.customTagName));
    this.emailFormGroup.patchValue({ body: quill.root.innerHTML });
    let index = this.availCustomTags.indexOf(tag);
    if (index > -1) {
      this.availCustomTags.splice(index, 1);
      this.emailTagCustom.value = null;
      this.emailTagCustom.inputFieldValue = "";
      this.emailTagCustom.clear();
      this.customTagResults = [];
    }
  }

  insertCustomSMSTag(tag: CustomTag) {
    this.smsBody += this.formatTagInsert(tag.customTagName);
    let index = this.availCustomTags.indexOf(tag);
    if (index > -1) {
      this.availCustomTags.splice(index, 1);
      this.smsTagCustom.value = null;
      this.smsTagCustom.inputFieldValue = "";
      this.smsTagCustom.clear();
      this.customTagResults = [];
    }
  }

  insertCustomWHTag(tag: CustomTag) {
    let currVal = this.webhookForm.value.postData;
    let tagInsert = this.formatTagInsert(tag.customTagName);
    this.webhookForm.patchValue({ postData: currVal + tagInsert });
    let index = this.availCustomTags.indexOf(tag);
    if (index > -1) {
      this.availCustomTags.splice(index, 1);
      this.whTagCustom.value = null;
      this.whTagCustom.inputFieldValue = "";
      this.whTagCustom.clear();
      this.customTagResults = [];
    }
  }

  formatTagInsert(fieldName: string): string {
    return `{!${fieldName}}`;
  }

  getAttachTooltip() {
    return "File names separated by a semicolon. Files are relative to the campaign folder path.";
  }

  editRecord(row: CampaignNotifications) {    
    if (!this.hasEditAccess) {
      this.toastService.addError("You do not have permissions to perform this action!");
      return;
    }
    this.notificationGuid = row.notificationGuid;
    let dataSub = forkJoin([this.apiService.get(`campaign/notification-get/${this.portfolio?.customerGuid}/${this.notificationGuid}`),
    this.getNotificationVersions()])
      .subscribe({
        next: ([campNotification, versions]) => {
          if (versions) {
            this.versions = versions as Version[];
            this.selectedVersion = this.versions[0];
          }
          if (campNotification) {
            this.createForms();
            let editNotification = campNotification as CampaignNotification;
            this.notificationToEdit = editNotification;
            let ntType = this.campaignLookups.notificationTypes.find(n => n.id == editNotification.notificationTypeID.toString());
            if (ntType) this.selectedType = ntType;

            let ntVersion = this.versions.find(v => v.versionGUID == editNotification.versionGUID);
            if (ntVersion) this.selectedVersion = ntVersion;

            this.isMarketing = editNotification.isMarketing;
            this.notificationName = editNotification.notificationName;

            switch (editNotification.notificationTypeID) {
              case NotificationType.Email:
                this.editEmail(editNotification);
                break;
              case NotificationType.SMS:
                this.editSMS(editNotification);
                break;
              case NotificationType.ServerAction:
                this.editServerAction(editNotification);
                break;
              case NotificationType.Webhook:
                this.editWebhook(editNotification);
                break;
              default:
                this.toastService.add({
                  severity: 'error',
                  summary: 'Unknown Type',
                  detail: 'Unknown Notification Type. Unable to edit record.'
                });
                console.error("Unknown Notification Type. Cannot configure form");
                return;
                break;
            }

            let prevItem = this.bcItems.pop();
            if (prevItem) {
              prevItem.command = () => { this.showEdit = false; this.showList = true; this.bcItems.pop(); this.bcService.removeBcCommand(this.bcItems, prevItem?.label); };
              this.bcItems.push(prevItem);
            }
            this.bcItems.push({
              label: 'Edit Notification'
            });
          }
          this.showList = false;
          this.showEdit = true;
        },
        error: (err: any) => { console.error(err); },
        complete: () => { dataSub.unsubscribe(); }
      });

  }

  editWebhook(editNotification: CampaignNotification) {
    let requestType = this.campaignLookups.requestTypes.find(r => r.desc.toLowerCase() === editNotification.requestType.toLowerCase());
    if (!requestType) requestType = this.campaignLookups.requestTypes.find(r => r.desc.toLowerCase() === 'post');
    let contentType = this.campaignLookups.contentTypes.find(c => c.desc.toLowerCase() === editNotification.contentType.toLowerCase());
    let responseValidation = editNotification.responseValidation;
    let status = this.parseResponse(responseValidation, 'field');
    let compare = this.parseResponse(responseValidation, 'operator');
    let responseValue = this.parseResponse(responseValidation, 'value');

    let statusItem = this.valResponses.find(v => v.value.toLowerCase() === status.toLowerCase());
    if (!statusItem) statusItem = this.valResponses[0];

    let compareItem = this.valComparer.find(v => v.value.toLowerCase() === compare.toLowerCase());
    if (!compareItem) compareItem = this.valComparer[0];

    this.webhookForm.patchValue({
      request: requestType,
      url: editNotification.requestURL,
      headers: editNotification.requestHeaders,
      contentType: contentType,
      responseStatus: statusItem,
      responeCompare: compareItem,
      responseValue: responseValue,
      postData: editNotification.postData
    });
  }

  parseResponse(responseValidation: string, field: string): string {
    let startIndex = responseValidation.indexOf(field);
    startIndex += field.length + 2;
    let endIndex = responseValidation.indexOf("\"", startIndex);
    let result = responseValidation.substring(startIndex, endIndex);
    return result;
  }

  createResponseValidation(responseStatus: DropdownString, responseCompare: DropdownString, responseValue: string): string | null {
    if (!responseStatus || !responseCompare || !responseValue) return null;
    return `<validation field="${responseStatus.value}" operator="${responseCompare.value}" value="${responseValue}" />`;
  }

  editServerAction(editNotification: CampaignNotification) {
    let action = this.campaignLookups.actions.find(a => a.desc === editNotification.emailSubject);
    if (action) this.selectedAction = action;
    this.actionParameters = editNotification.emailBody;
  }

  editSMS(editNotification: CampaignNotification) {
    this.smsBody = editNotification.emailBody;
    this.smsTo = editNotification.toEmail;
  }

  editEmail(editNotification: CampaignNotification) {
    this.emailFormGroup.patchValue({
      to: editNotification.toEmail,
      bcc: editNotification.emailBCC,
      subject: editNotification.emailSubject,
      attachment: editNotification.emailAttachement,
      body: editNotification.emailBody
    });
  }

  cancelNotification() {
    this.createForms();
    this.isGeneric = false;
    this.isMarketing = false;
    this.selectedType = null;
    this.notificationToEdit = null;
    this.notificationGuid = null;
    if (this.versions) this.selectedVersion = this.versions[0];
    this.notificationName = null;

    this.bcService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
    window.scrollTo(0, 0);
  }

  disableSave(): boolean {   
    if (!this.selectedType
      || !this.hasEditAccess      
      || !this.selectedVersion
      || this.showNotificationNameError) {
      return true;
    }

    switch (+this.selectedType.id) {
      case NotificationType.Email:
        if (this.emailFormGroup.pristine
          || (this.emailFormGroup.touched && !this.emailFormGroup.invalid)) return false;
        break;
      case NotificationType.SMS:
        if (this.smsBody
          && this.smsBody.length > 0
          && this.smsTo
          && this.smsTo.length > 0) {
          return false
        }
        break;
      case NotificationType.ServerAction:
        if (this.selectedAction) return false;
        break;
      case NotificationType.Webhook:
        if (this.webhookForm.pristine
          || (this.webhookForm.touched && !this.webhookForm.invalid)) return false;
        break;
      default:
        console.error("Unknown Notification Type. Cannot determine possible errors");
        return true;
        break;
    }

    return true;
  }

  saveNotification() {
    let saveNotification = new CampaignNotificationSave();
    saveNotification.notificationName = this.notificationName || "";
    if (this.selectedType) saveNotification.notificationTypeId = +this.selectedType.id;
    saveNotification.versionGuid = this.selectedVersion?.versionGUID || '';
    saveNotification.notificationGuid = this.notificationToEdit?.notificationGUID || null;
    saveNotification.customerGuid = this.portfolio?.customerGuid || '';
    saveNotification.campaignGuid = this.campaignGuid
    saveNotification.isMarketing = this.isMarketing;

    switch (saveNotification.notificationTypeId) {
      case NotificationType.Email:
        saveNotification.toEmail = this.emailFormGroup.value.to;
        saveNotification.emailBcc = this.emailFormGroup.value.bcc;
        saveNotification.emailSubject = this.emailFormGroup.value.subject;
        saveNotification.emailAttachment = this.emailFormGroup.value.attachment;
        saveNotification.emailBody = this.emailFormGroup.value.body;
        break;
      case NotificationType.SMS:
        if (this.smsBody) saveNotification.emailBody = this.smsBody;
        saveNotification.toEmail = this.smsTo;
        break;
      case NotificationType.ServerAction:
        if (this.selectedAction) saveNotification.emailSubject = this.selectedAction.desc;
        saveNotification.emailBody = this.actionParameters;
        break;
      case NotificationType.Webhook:
        saveNotification.requestType = this.webhookForm.value.request.desc;
        saveNotification.contentType = this.webhookForm.value.contentType.desc;
        saveNotification.responseValidation = this.createResponseValidation(this.webhookForm.value.responseStatus,
          this.webhookForm.value.responseCompare, this.webhookForm.value.responseValue);
        saveNotification.requestHeaders = this.webhookForm.value.headers;
        saveNotification.postData = this.webhookForm.value.postData;
        saveNotification.requestUrl = this.webhookForm.value.url;
        break;
      default:
        this.toastService.add({
          severity: 'error',
          summary: 'Unknown Type',
          detail: 'Unknown Notification Type. Unable to save'
        }, 'center');
        console.error("Unknown Notification Type. Unable to save.");
        return;
        break;
    }

    let body = {
      CustomerGuid: saveNotification.customerGuid,
      CampaignGuid: saveNotification.campaignGuid,
      NotificationGuid: saveNotification.notificationGuid,
      NotificationTypeId: saveNotification.notificationTypeId,
      NotificationName: saveNotification.notificationName,
      SmtpProfileId: saveNotification.smtpProfileId,
      ReturnPath: saveNotification.returnPath,
      ToEmail: saveNotification.toEmail,
      EmailCc: saveNotification.emailCc,
      EmailBcc: saveNotification.emailBcc,
      EmailSubject: saveNotification.emailSubject,
      EmailBody: saveNotification.emailBody,
      EmailAttachment: saveNotification.emailAttachment,
      RequestType: saveNotification.requestType,
      RequestUrl: saveNotification.requestUrl,
      RequestHeaders: saveNotification.requestHeaders,
      ContentType: saveNotification.contentType,
      PostData: saveNotification.postData,
      ResponseValidation: saveNotification.responseValidation,
      SendTest: saveNotification.sendTest,
      IsMarketing: saveNotification.isMarketing,
      MassEmailGuid: saveNotification.massEmailGuid,
      VersionGuid: saveNotification.versionGuid
    };

    let postSub = this.apiService.post(`campaign/notifications-post`, body)
      .subscribe({
        next: (notificationId: number) => {
          let msgUpdate = this.notificationToEdit ? 'updated' : 'added';
          if (!notificationId || notificationId == 0) {
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: `Unable to successfully ${msgUpdate} message`
            });
          }
          else {
            this.showNotificationData();
            this.bcService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
            this.toastService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Message has been successfully ${msgUpdate}`
            });
          }
        },
        error: (err: any) => { 
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Error attempting to save notification. See log for details.`
          });
          console.error(err); 
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  notificationNameBlur(event: any) {
    if (event || event.length > 0) this.showNotificationNameError = false;
    else this.showNotificationNameError = true;
  }

  smsToBlur(event: any) {
    if (event || event.length > 0) this.showSMSToError = false;
    else this.showSMSToError = true;
  }

  smsBodyBlur(event: any) {
    if (event || event.length > 0) this.showSMSBodyError = false;
    else this.showSMSBodyError = true;
  }

  testNotification() {
       
    if (!this.selectedType || (+this.selectedType.id == 3 || +this.selectedType.id == 4)) {
      this.toastService.underConstruction();
      return;
    }

    if (+this.selectedType.id == 1) {
      this.selectedTestSeed = null;
      this.sendTestEmailAddress = null;
      this.sendTestEmailModal = true;
    }
    else if (+this.selectedType.id == 2) {
      this.selectedTestSeed = null;
      this.sendTestSMSPhone = null;
      this.sendTestSMSModal = true;
    }
    
  }

  sendTestEmail() {
    if (!this.sendTestEmailAddress || this.sendTestEmailAddress.trim().length == 0) {
      this.toastService.add({
        severity: 'error',
        summary: 'Missing info',
        detail: 'Email address(es) missing. Please fill in and retry'
      });
      return;
    }
    let sendTest: SendTest = {
      campaignGuid: this.campaignGuid,
      customerGuid: this.portfolio?.customerGuid || '',
      notificationGuid: this.notificationToEdit?.notificationGUID || null,
      targetGuid: this.selectedTestSeed?.value || null,
      toEmails: this.sendTestEmailAddress,
      versionGuid: this.notificationToEdit?.versionGUID || '00000000-0000-0000-0000-000000000000'
    };
    this.sendTestComm(sendTest, 'email');
  }

  sendTestSMS() {
    if (!this.sendTestSMSPhone || this.sendTestSMSPhone.trim().length == 0) {
      this.toastService.add({
        severity: 'error',
        summary: 'Missing info',
        detail: 'Phone number(s) missing. Please fill in and retry'
      }, 'center');
      return;
    }
    let sendTest: SendTest = {
      campaignGuid: this.campaignGuid,
      customerGuid: this.portfolio?.customerGuid || '',
      notificationGuid: this.notificationToEdit?.notificationGUID || null,
      targetGuid: this.selectedTestSeed?.value || null,
      toEmails: this.sendTestSMSPhone,
      versionGuid: this.notificationToEdit?.versionGUID || '00000000-0000-0000-0000-000000000000'
    };
    this.sendTestComm(sendTest, 'sms');    
  }

  sendTestComm(sendTest: SendTest, type: string) {
    let sendSub = this.apiService.post(`campaign/notifications-send-test`, sendTest)
    .subscribe({
      next: (resp: number) => {
        if (resp < 1) {
          this.toastService.add({
            severity: 'error',
            summary: 'Send Failed',
            detail: `Sending ${type} failed. See log for details.`            
          });
          return;
        }

        this.toastService.add({
          severity: 'success',
          summary: 'Sent',
          detail: `Sent ${type} successfully.`            
        });
        
        if (type === 'email') this.sendTestEmailModal = false;
        else this.sendTestSMSModal = false;

      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Error attempting to send ${type}. See log for details.`            
        }, 'center');
        console.error(err);
      },
      complete: () => { sendSub.unsubscribe(); }
    });
  }

}