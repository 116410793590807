<span class="text-2xl font-medium">NLS Reports</span>
<div class="w-full mt-4 border-1 border-black-alpha-10">
  <div class="p-3 report-item-link" (click)="sendClick()">Trial Balance</div>
  <div class="p-3 report-item-link" (click)="sendClick()">
    Transaction Summary
  </div>
  <div class="p-3 report-item-link" (click)="sendClick()">
    Transaction Summary Payments
  </div>
  <div class="p-3 report-item-link" (click)="sendClick()">Aging</div>
  <div class="p-3 report-item-link" (click)="sendClick()">Cash Report</div>
  <div class="p-3 report-item-link" (click)="sendClick()">
    Running Trial Balance Summary
  </div>
  <div class="p-3 report-item-link" (click)="sendClick()">
    Running Trial Balance Detail
  </div>
</div>
