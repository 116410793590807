import { Component, OnInit } from '@angular/core';
import { Campaign } from 'src/app/models/campaign';
import { PortfolioItem } from 'src/app/models/customer-item';
import { CampaignService } from 'src/app/services/campaign.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-provider-menu',
  templateUrl: './provider-menu.component.html',
  styleUrls: ['./provider-menu.component.scss']
})
export class ProviderMenuComponent implements OnInit {

  portfolios: PortfolioItem[] = [];
  selectedPortfolio: PortfolioItem | null = null;

  campaigns: Campaign[] = [];
  selectedCampaign: Campaign | null = null;

  constructor(
    private portfolioService: PortfolioService,
    private userService: UserService,
    private campaignService: CampaignService
  ) { }

  ngOnInit(): void {
    this.portfolioService.portfolios$.subscribe(p => {
      if (p) {
        this.portfolios = p.portfolios;
        if (p.portfolios.length == 1) {
          this.portfolioService.portfolioSelected(p.portfolios[0]);
          this.userService.subscribeAccess();
        }
      }
      else this.portfolios = [];
    });

    this.portfolioService.portfolio$
      .subscribe((data: any) => {
        if (data) {
          this.selectedPortfolio = data as PortfolioItem;
        }
      });

    this.campaignService.campaigns$.subscribe(c => {
      this.campaigns = c != null ? c : [];
    });

    this.campaignService.selectedCampaign$.subscribe(c => {
      if (c) {
        this.selectedCampaign = c as Campaign;
      }
    });
  }

  setPortfolio(p: PortfolioItem) {
    this.portfolioService.portfolioSelected(p);
    this.userService.subscribeAccess();
    
  }

  setCampaign(c: Campaign) {
    this.campaignService.selectCampaign(c);
  }
}
