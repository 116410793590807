import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PortfolioItem } from 'src/app/models/customer-item';
import { LookupModel } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-new-predefined-lead',
  templateUrl: './new-predefined-lead.component.html',
  styleUrls: ['./new-predefined-lead.component.scss']
})
export class NewPredefinedLeadComponent implements OnInit {
  portfolioItem: PortfolioItem | null | undefined;
  lookups: any = {};
  disclosures: LookupModel[] = [{ id: '1', desc: 'Agreed to Disclosures' }, { id: '0', desc: 'Still need to see Disclosures' }];
  trueFalse: LookupModel[] = [{ id: '1', desc: 'True' }, { id: '0', desc: 'False' }];
  languages: LookupModel[] = [{ id: 'EN', desc: 'EN' }, { id: 'ES', desc: 'ES' }];
  appLinks: LookupModel[] = [{ id: 'Link', desc: 'Link' }, { id: 'Device', desc: 'Device' }];
  bcItems: MenuItem[] = [{ label: 'Partner Integration Dashboard  > New Predefined Lead' }];
  predefinedLeadId: string | null = null;
  predefinedLead = {};
  btnText = "Add New";

  leadForm = this.fb.group({
    BranchID: ['', Validators.required],
    PredefinedLeadID: [''],
    LeadName: ['', Validators.required],
    AgreeToSignDisclosures: ['0'],
    LeadPatientID: [''],
    AssignTo: [''],
    SendEmail: ['1'],
    SendSMS: ['1'],
    SendAppLink: ['Link'],
    Language: ['EN'],
    LeadFirstName: ['', Validators.required],
    LeadLastName: ['', Validators.required],
    LeadDateOfBirth: [''],
    LeadAddress: ['', Validators.required],
    LeadAddress2: [''],
    LeadCity: ['', Validators.required],
    LeadState: ['', Validators.required],
    LeadZip: ['', Validators.required],
    LeadEmailAddress: ['', Validators.required],
    LeadPhone: ['', Validators.required],
    LeadSSN: [''],
    RequestedAmount: [''],
    LoanType: ['I'],
    // PartnerName: [''],
    // PartnerAddress1: [''],
    // PartnerAddress2: [''],
    // PartnerCity: [''],
    // PartnerState: [''],
    // PartnerZipCode: [''],
    // PartnerVertical: ['']
  });
  procedureForm = this.fb.group({
    ProcedureId: ['1', Validators.required],
    ProcedureDate: [''],
    ProcedureAmount: [''],
    ProcedureName: ['', Validators.required],
    Doctor: ['']
  });

  acceptDisclosure = {
    AgreeToPrivacyPolicy: '',
    AgreeToServicingComms: '',
    AgreeToCreditCheckAuthorization: '',
    AgreeToEsignConsent: '',
    AgreeDateTime: {}
  };

  constructor(private api: ApiService,
    private fb: FormBuilder,
    private router: Router,
    private toast: ToastService,
    private activatedRoute: ActivatedRoute,
    private portfolioService: PortfolioService) { }

  ngOnInit(): void {
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      this.getLookups();
    });

    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.predefinedLeadId = paramMap.get('predefinedLeadId');
      this.getPredefinedLead();
    });
  }

  getPredefinedLead() {
    if (this.predefinedLeadId)
      this.api.get(`PartnerIntegration/predefinedLead/${this.portfolioItem?.customerGuid}/${this.predefinedLeadId}`).subscribe(data => {
        this.predefinedLead = data;
        this.createControls(data);
      });
  }

  getLookups() {
    if (this.portfolioItem && this.portfolioItem.customerGuid) {
      this.api.get(`PartnerIntegration/lookups/${this.portfolioItem.customerGuid}`).subscribe(data => {
        this.lookups = data;
        this.lookups.providerBranches?.unshift({ id: '', desc: '--Select--' });
        this.lookups.providerProducts?.unshift({ id: '', desc: '--Select--' });
      });
    } else {
      //toast error?
    }
  }

  createControls(lead: any){
    if (lead && lead.postLeadJson){
      this.btnText = "Update";
      var leadJson = JSON.parse(lead.postLeadJson);
      var info = leadJson?.LeadInformation;
      var contact = info?.LeadContactInfo;
      var loan = info?.LoanRequest;
      var procedure = info?.LoanRequest?.Procedures?.ProcedureService;

      this.leadForm = this.fb.group({
        BranchID: [info.BranchLocationGUID, Validators.required],
        PredefinedLeadID: [this.predefinedLeadId],
        LeadName: [lead.leadName, Validators.required],
        AgreeToSignDisclosures: ['0'],
        LeadPatientID: [info?.LeadPatientID],
        AssignTo: [''],
        SendEmail: ['1'],
        SendSMS: ['1'],
        SendAppLink: [info.SendAppLink],
        Language: [info.Language],
        LeadFirstName: [contact?.LeadFirstName, Validators.required],
        LeadLastName: [contact?.LeadLastName, Validators.required],
        LeadDateOfBirth: [contact?.LeadFirstName],
        LeadAddress: [contact?.LeadAddress, Validators.required],
        LeadAddress2: [contact?.LeadAddress2],
        LeadCity: [contact?.LeadCity, Validators.required],
        LeadState: [contact?.LeadState, Validators.required],
        LeadZip: [contact?.LeadZip, Validators.required],
        LeadEmailAddress: [contact?.LeadEmailAddress, Validators.required],
        LeadPhone: [contact?.LeadPhone, Validators.required],
        LeadSSN: [contact?.LeadSSN],
        RequestedAmount: [loan?.RequestedAmount],
        LoanType: [loan?.LoanType],
        // PartnerName: [info?.PartnerName],
        // PartnerAddress1: [info?.PartnerAddress1],
        // PartnerAddress2: [info?.PartnerAddress2],
        // PartnerCity: [info?.PartnerCity],
        // PartnerState: [info?.PartnerState],
        // PartnerZipCode: [info?.PartnerZipCode],
        // PartnerVertical: [info?.PartnerVertical]
      });
      this.procedureForm = this.fb.group({
        ProcedureId: [procedure?.ProcedureId, Validators.required],
        ProcedureDate: [procedure?.ProcedureDate],
        ProcedureAmount: [procedure?.ProcedureAmount],
        ProcedureName: [procedure?.ProcedureName, Validators.required],
        Doctor: [procedure?.Doctor]
      });
    }

  }

  cancel() {
    this.router.navigate(['/partner/lead']);
  }


  save() {
    if (this.leadForm.valid && this.procedureForm.valid) {
      if (this.leadForm.value.AgreeToSignDisclosures === '1') {
        this.acceptDisclosure.AgreeDateTime = new Date();
        this.acceptDisclosure.AgreeToPrivacyPolicy = 'true';
        this.acceptDisclosure.AgreeToServicingComms = 'true';
        this.acceptDisclosure.AgreeToEsignConsent = 'true';
        this.acceptDisclosure.AgreeToCreditCheckAuthorization = 'true';
      } else {
        this.acceptDisclosure.AgreeToPrivacyPolicy = 'false';
        this.acceptDisclosure.AgreeToServicingComms = 'false';
        this.acceptDisclosure.AgreeToEsignConsent = 'false';
        this.acceptDisclosure.AgreeToCreditCheckAuthorization = 'false';
      }


      let obj = {
        ...this.leadForm.value,
        ...this.acceptDisclosure,
        Procedures: {
          ProcedureService: [this.procedureForm.value]
        }
      };

      var model = {
        CustomerGuid: this.portfolioItem?.customerGuid,
        PredefinedLeadId: this.predefinedLeadId,
        LeadName: this.leadForm.value.LeadName,
        LeadJson: JSON.stringify(obj)
      }

      this.api.post(`PartnerIntegration/create-update`, JSON.stringify(model)).subscribe(data => {
        if (data) {
          this.toast.add({ severity: 'success', summary: 'Success', detail: 'Predefined lead successfully added.' });
        } else {
          this.toast.add({ severity: 'error', summary: 'Error', detail: 'Error occured. action cannot be completed' });
        }
      });

    } else {
      this.leadForm.markAsTouched();
      this.procedureForm.markAsTouched();
      this.leadForm.markAllAsTouched();
      this.procedureForm.markAllAsTouched();
      this.toast.add({ severity: 'error', summary: 'Error', detail: 'Please emter all the required fields.' });
    }
  }

}
