import { Component, Input, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-cc-loading',
  templateUrl: './cc-loading.component.html',
  styleUrls: ['./cc-loading.component.scss']
})
export class CcLoadingComponent implements OnInit {

  spinner = faSpinner as IconProp;
  @Input() loadingText: string = "Loading";
  constructor() { }

  ngOnInit(): void {
  }

}
