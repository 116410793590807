export class OfferMatrixRow {
    APR: string = '';
    "Credit Band": string = '';
    LoanAmount: string = '';
    LoanAmountMatrixGUID: string = '';
    MDR: string = '';
    "Max Pro": string = '';
    "Min Amt": string = '';
    "Min Down Amt": string = '';
    "Min Payment": string = '';
    Order: string = '';
    Procedure: string = '';
    Product: string = '';
    RowCount: string = '';
    RowNumber: string = '';
    SAC: string = '';
    State: string = '';
    Term: string = '';
}

export class OfferMatrixRowDB {
    offerProductTypeID: number = 0;
    loanGradeID: number | null = null;
    offerMatrixID: number = 0;
    mdr: number | null = null;
    loanProviderProductID: number | null = null;
    loanProviderTypeID: number | null = null;
    loanAmount: number = 0;
    loanInterest: number = 0;
    minProAmt: number = 0;
    maxProAmt: number = 0;
    downPaymentType: string = '';
    downPayIsPayment: boolean = false;
    minPaymentTypeID: number = 0;
    minPaymentAmt: number = 0;
    minDownPaymentAmt: number = 0;
    maxDownPaymentAmt: number = 0;
    downPaymentMinPct: number = 0;
    downPaymentMinAmt: number = 0;
    termBW: number | null = null;
    termSM: number | null = null;
    termSM14: number | null = null;
    termMO: number | null = null;
    state: string = '';
    sacTerm: number = 0;
    marketingMessage: string | null = null;
}

export class OfferMatrixRowUpload extends OfferMatrixRowDB {
    customerGuid: string = '';
    offerMatrixGuid: string = '';
    loanMatrixGuid: string = '';    
}