import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { EditProcedureEntryComponent } from '../edit-procedure-entry/edit-procedure-entry.component';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-edit-procedure-info',
  templateUrl: './edit-procedure-info.component.html',
  styleUrls: ['./edit-procedure-info.component.scss']
})
export class EditProcedureInfoComponent implements OnInit {

  procedures = [];
  iconPlus = faPlus as IconProp;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this.dialog.open(EditProcedureEntryComponent, {
      width: '340px',
      data: {name: ''}

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

}
