import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICustomerLookups } from '../models/customer-lookups';
import { ApiService } from './api.service';
import { PortfolioService } from './portfolio.service';
import { GuidService } from './guid.service';
import { ToastService } from './toast.service';
import { ProviderInfo } from '../models/provider-info';
import { LookupModel } from '../models/lookup-model';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  private _customerLookups: BehaviorSubject<ICustomerLookups> = new BehaviorSubject<ICustomerLookups>({} as ICustomerLookups);
  public readonly customerLookups$: Observable<ICustomerLookups> = this._customerLookups.asObservable(); 

  private _customerNames: BehaviorSubject<LookupModel []> = new BehaviorSubject<LookupModel[]>([]);
  public readonly customerNames$: Observable<LookupModel []> = this._customerNames.asObservable();

  private _infoSectionLookups: BehaviorSubject<ICustomerLookups | null> = new BehaviorSubject<ICustomerLookups | null>(null);
  public readonly infoSectionLookups$: Observable<ICustomerLookups | null> = this._infoSectionLookups.asObservable();

  private _infoSectionProvider: BehaviorSubject<ProviderInfo | null> = new BehaviorSubject<ProviderInfo | null>(null);
  public readonly infoSectionProvider$: Observable<ProviderInfo | null> = this._infoSectionProvider.asObservable();

  constructor(
    private apiService: ApiService, 
    private portfolioService: PortfolioService,
    private toastService: ToastService

    ) {
    this.loadInitialData();
  }

  loadCustomerNames(){
    this.apiService.get(`lookup/customers`)
    .subscribe(
      res => {
        this._customerNames.next(res);
      },
      err => console.error("Error retrieving _customerNames: ", err)
    );
  }

  loadInitialData() {
    this.portfolioService.portfolio$.subscribe(porfolio => {
      if (porfolio && porfolio.customerGuid) {
        this.apiService.get(`provider/lookups/${porfolio.customerGuid}`)
          .subscribe(
            res => {
              this._customerLookups.next(res);
            },
            err => console.error("Error retrieving lookups: ", err)
          );
      }
    })
  }

  loadInfoSectionLookups(providerGuid: string) {
    let path = 'provider/lookups';
    if (providerGuid || providerGuid.trim().length > 0) path += `/${providerGuid}`;
    this.apiService.get(path)
    .subscribe({
      next: (lookups: ICustomerLookups) => {
        this._infoSectionLookups.next(lookups);
      },
      error: (err: any) => {
        this._infoSectionLookups.next(null);
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get Customer information. See log for details'
        }, 'center');
        console.error(err);
      }
    });
  }

  loadInfoSectionProvider(providerGuid: string) {

    let path = `provider/${providerGuid}`;
    this.apiService.get(path)
    .subscribe({
      next: (provider: ProviderInfo) => {
        this._infoSectionProvider.next(provider);
      },
      error: (err: any) => {
        this._infoSectionProvider.next(null);
        this._infoSectionLookups.next(null);
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get Customer information. See log for details'
        }, 'center');
        console.error(err);
      }
    });
  }

  removeProviderInfo() {
    this._infoSectionProvider.next(null);
  }

  getRegions(customerGuid: string) {
    return this.apiService.get(`provider/regions/${customerGuid}`);
  }

  getAllSettlements(customerGuid: string | undefined) {
    return this.apiService.get(`provider/customer-settlements/${customerGuid}`);
  }

}
