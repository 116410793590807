import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PortfolioItem } from 'src/app/models/customer-item';
import { LookupModel } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';

@Component({
  selector: 'app-edituser-settings',
  templateUrl: './edituser-settings.component.html',
  styleUrls: ['./edituser-settings.component.scss']
})
export class EdituserSettingsComponent implements OnInit {

  @Input() userGuid = '';
  @Output() onEditComplete = new EventEmitter<string>();
  @Input()
  get user(): any { return this._user; }
  set user(user: any) {
    this._user = user;
    this.initForm();
  }
  private _user = '';
  customers: LookupModel[] = [];
  userTypes: LookupModel[] = [];
  assignedBranches: LookupModel[] = [];
  availableBranches: LookupModel[] = [];
  assigned: LookupModel[] = [];
  available: LookupModel[] = [];
  settingsForm: FormGroup = new FormGroup({});
  portfolioItem: PortfolioItem | null = null;
  selected: any = {
    assignClient: [],
    unassignClient: [],
    assignBranch: [],
    unassignBranch: []
  }
  constructor(private api: ApiService,
    private fb: FormBuilder,
    private portfolioService: PortfolioService) {
     }

  ngOnInit(): void {
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      this.getCustomers();
      this.getUserTypes();
      this.getAssignedBranches();
      this.getAvailableBranches(this.user.CustomerIDs);
    });
  }

  initForm() {
    this.settingsForm = this.fb.group({
      UserTypeID: [this.user?.UserTypeID.toString() || '', Validators.required],
      CustomerID: [this.user?.CustomerID.toString() || '', Validators.required]
    });
  }

  getCustomers() {
    this.api.get(`lookup/customers2/${this.portfolioItem?.customerGuid}`).subscribe(data => {
      this.customers = data;
      this.setAssignedCustomers();
      this.setAvalableCustomers();
    });
  }

  getUserTypes() {
    this.api.get(`lookup/user-types/${this.portfolioItem?.customerGuid}`).subscribe(data => {
      this.userTypes = data;
    });
  }

  getAssignedBranches() {
    this.api.get(`adminuser/assigned-branches/${this.portfolioItem?.customerGuid}/${this.userGuid}`).subscribe(data => {
      this.assignedBranches = data;
    });
  }

  getAvailableBranches(customerIds: string) {
    var json = JSON.stringify(customerIds);
    this.api.post(`adminuser/available-branches/${this.portfolioItem?.customerGuid}`, json).subscribe(data => {
      this.availableBranches = data;
    });
  }

  setAssignedCustomers() {
    this.assigned = [];
    let arr: string[] = this.user.CustomerIDs.indexOf(',') ?
      this.user.CustomerIDs.split(',') :
      this.user.CustomerIDs ? [this.user.CustomerIDs] : [];

    arr.forEach(item => {
      if (item) {
        var c = this.customers.find(x => x.value == item.trim());
        if (c) {
          this.assigned.push(c);
        }
      }
    });
  }

  moveBranch(mode: string) {
    if (mode === 'unassign') {
      if (this.selected?.unassignBranch?.length) {
        this.selected?.unassignBranch.forEach((item: any) => {
          var i = this.assignedBranches.findIndex(x => x.value == item.value);
          if (i != -1)
            this.assignedBranches.splice(i, 1);
          this.addItem(this.availableBranches, item);
        })
      }
    }

    if (mode === 'assign') {
      if (this.selected?.assignBranch?.length) {
        this.selected?.assignBranch.forEach((item: any) => {
          var i = this.availableBranches.findIndex(x => x.value == item.value);
          if (i != -1)
            this.availableBranches.splice(i, 1);
          this.addItem(this.assignedBranches, item);
        })
      }
    }

    this.availableBranches = [...this.availableBranches];
    this.assignedBranches = [...this.assignedBranches];
    this.user.BranchIDs = this.assignedBranches.map(ele=>ele.value).join(',');
  }

  moveCustomer(mode: string) {
    if (mode === 'unassign') {
      if (this.selected?.unassignClient?.length) {
        this.selected?.unassignClient.forEach((item: any) => {
          var i = this.assigned.findIndex(x => x.value == item.value);
          if (i != -1)
            this.assigned.splice(i, 1);
          this.addItem(this.available, item);
        })
      }
    }

    if (mode === 'assign') {
      if (this.selected?.assignClient?.length) {
        this.selected?.assignClient.forEach((item: any) => {
          var i = this.available.findIndex(x => x.value == item.value);
          if (i != -1)
            this.available.splice(i, 1);
          this.addItem(this.assigned, item);
        })
      }
    }

    this.available = [...this.available];
    this.assigned = [...this.assigned];
    let ids: string[] = [];
    this.assigned.forEach(item => {
      if (item.value) {
        ids.push(item.value);
      }
    });
    this.getAvailableBranches(ids.join(','));
    this.user.CustomerIDs = this.assigned.map(ele=>ele.value).join(',');
  }

  setAvalableCustomers() {
    this.available = [];
    let arr: string[] = this.user.CustomerIDs.indexOf(',') ?
      this.user.CustomerIDs.split(',') :
      this.user.CustomerIDs ? [this.user.CustomerIDs] : [];

    this.customers.forEach(item => {
      if (item) {
        var c = arr.find(x => x.trim() == item.value?.trim());
        if (!c) {
          this.available.push(item);
        }
      }
    });
  }

  onSelectChange(arr: [], propName: string) {
    this.selected[propName] = arr;
  }

  addItem(arr: LookupModel[], item: LookupModel) {
    var i = arr.findIndex(x => x.value == item.value);
    if (i == -1)
      arr.push(item);
  }

  onCustomerChange(ev:any){
    this.user.CustomerID = ev;
  }

  onUserTypeChange(ev: any){
    this.user.UserTypeID = ev;
  }

}
