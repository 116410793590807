import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PortfolioItem } from 'src/app/models/customer-item';
import { CampaignService } from 'src/app/services/campaign.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss']
})
export class NavHeaderComponent implements OnInit, AfterViewInit {

  showCampEdit: boolean = false;  
  profileDD: boolean = false;
  createDD: boolean = false;
  campaignDD: boolean = false;
  providerDD: boolean = false;
  showAddNew: boolean = false;

  campSeedPageId: number = 46;
  hasCampSeedAccess: boolean = false;

  portfolio: PortfolioItem | null = null;

  campAddEditCode = 'CAMPAIGN_ADD_EDIT_COPY_DELETE';
  headerIcon: string = 'assets/images/p3solved.ico';
  readonly defaultIcon: string = 'assets/images/p3solved.ico';

  constructor(
    private router: Router,
    private portfolioService: PortfolioService,
    private campaignService: CampaignService,
    private toastService: ToastService,
    private navService: NavService,
    private userService: UserService
    ) { }

  ngOnInit(): void {
    this.portfolioService.portfolio$
    .subscribe((data: any) => {
      if (data) {
        this.portfolio = data as PortfolioItem;
        if (this.portfolio.adminIcon) {
          this.headerIcon = this.portfolio.adminIcon;
        }
        else {
          this.setDefaultIcon();
        }
      }
    });
    this.campaignService.selectedCampaign$
    .subscribe(c => {
      this.showCampEdit = c != null && this.userService.hasWrite(this.campAddEditCode);
    });
  }
  
  ngAfterViewInit(): void {
    this.hasCampSeedAccess = this.userService.hasPageAccess(this.campSeedPageId);
  }

  setDefaultIcon() {
    this.headerIcon = this.defaultIcon;
  }

  goToHome() { 
    this.router.navigate(['/home']);
  }

  gotoCampaignPage(path: string) {
    this.navService.setLeftNavSelection(null);
    this.campaignDD = false;
    this.router.navigate([`/campaign/${path}`]);
  }

  toggleMenus(menu: string) {
    switch (menu) {
      case 'create':
        this.campaignDD = false;
        this.createDD = !this.createDD;
        this.profileDD = false;
        this.providerDD = false;
        break;
      case 'campaign':
        this.campaignDD = !this.campaignDD;
        this.createDD = false;
        this.profileDD = false;
        this.providerDD = false;
        break;
      case 'profile':
        this.campaignDD = false;
        this.createDD = false;
        this.profileDD = !this.profileDD;
        this.providerDD = false;
        break;
      case 'provider':
        this.campaignDD = false;
        this.createDD = false;
        this.profileDD = false;
        this.providerDD = !this.providerDD;
        break;
      default:
        this.campaignDD = false;
        this.createDD = false;
        this.profileDD = false;
        this.providerDD = false;
        break;
    }
  }

  underConstruction() { this.toastService.underConstruction(); }
}
