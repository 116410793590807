import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PortfolioItem } from 'src/app/models/customer-item';
import { LookupModel } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { emailRegex } from 'src/app/services/util.service';

@Component({
  selector: 'app-create-user-page',
  templateUrl: './create-user-page.component.html',
  styleUrls: ['./create-user-page.component.scss']
})
export class CreateUserPageComponent implements OnInit {

  saving = false;
  customers: LookupModel[] = [];
  userTypes: LookupModel[] = [];
  step = 1;
  stepForm3: FormGroup = this.fb.group({
    Password1: ['', Validators.required],
    Password2: ['', Validators.required],
    SendWelcomeEmail: [true, Validators.required]
  });
  stepForm2: FormGroup = this.fb.group({
    CustomerID: [null, Validators.required],
    UserTypeID: [null, Validators.required]
  });
  stepForm1: FormGroup = this.fb.group({
    FirstName: ['', Validators.required],
    LastName: ['', Validators.required],
    LoginEmail: ['', [Validators.required, Validators.pattern(emailRegex)]],
    TimeZoneID: [null, Validators.required]
  });
  portfolioItem: PortfolioItem | null | undefined;
  timeZones: LookupModel[] = [];
  bcItems: MenuItem[] = [{ label: 'Roles' }, { label: 'Users', url: 'users/dashboard' }, { label: 'Create User' }];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private api: ApiService,
    private toast: ToastService,
    private portfolioService: PortfolioService) { }

  ngOnInit(): void {
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      this.getTimeZones();
      this.getCustomers();
      this.getUserTypes();
    });
  }

  getTimeZones() {
    if (this.portfolioItem && this.portfolioItem.customerGuid) {
      this.api.get(`lookup/time-zones/${this.portfolioItem.customerGuid}`).subscribe(data => {
        this.timeZones = data;
      });
    } else {
      //toast error?
    }
  }

  getCustomers() {
    this.api.get(`lookup/customers2/${this.portfolioItem?.customerGuid}`).subscribe(data => {
      this.customers = data;
    });
  }

  getUserTypes() {
    this.api.get(`lookup/user-types/${this.portfolioItem?.customerGuid}`).subscribe(data => {
      this.userTypes = data;
    });
  }

  next() {
    if (this.step === 1) {
      this.doStep1();
    }

    if (this.step === 2) {
      if (this.stepForm2.valid){
        this.step++;
      }
    }
  }

  cancel() {
    this.router.navigate(['/users/dashboard/']);
  }

  previous() {
    this.step--;
  }

  createUser() {
    if (this.stepForm3.valid){
      if (this.stepForm3.value.Password1 !== this.stepForm3.value.Password2){
        this.toast.addError("Password must match.");
        return;
      }

      let obj = {
        Password: this.stepForm3.value.Password1,
        ...this.stepForm1.value,
        ...this.stepForm2.value,
        ...this.stepForm3.value,
      };
      this.saving = true;
      var json = JSON.stringify(JSON.stringify(obj));
      this.api.post(`adminuser/create`, json).subscribe(res => {
        this.saving = false;
        if (res){
          this.router.navigate(['/users/edit/' + res]);
        }else{
          this.toast.addError("Invalid Request. Server Error occured.");
        }
      }, err => {
        console.error(err);
        this.saving = false;
        this.toast.addError("Invalid form. Server Error occured.");
      });
    }
  }

  doStep1(){
    if (this.stepForm1.valid) {
      this.saving = true;
      this.api.get(`adminuser/validate-email/${this.stepForm1.value.LoginEmail}`)
      .subscribe(data => {
        this.saving = false;
        if (data){
          this.toast.addError("Email already in use.");
        }else{
          this.step++;
        }
      }, err => {
        this.saving = false;
      });
    }
  }

}
