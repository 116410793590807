import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-cc-file-upload',
  templateUrl: './cc-file-upload.component.html',
  styleUrls: ['./cc-file-upload.component.scss']
})
export class CcFileUploadComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() isRequired: boolean = false;
  @Input() id?: string;
  @Input() label: string = "";
  @Input() desc: string = "";
  @Input() val: string = "";
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  iconRequired = faAsterisk as IconProp;
  constructor() {
    var d = new Date();
    this.id = d.getTime().toString();
  }

  ngOnInit(): void {
  }
}
