
// export const nameOf = <T>(obj: T, expression: (x: { [Property in keyof T]: () => string }) => () => string): string => {
//     const res: { [Property in keyof T]: () => string } = {} as { [Property in keyof T]: () => string };

//     Object.keys(obj).map(k => res[k] = () => k);

//     return expression(res)();
//   };

  export function getPropertyName<T extends object>(obj: T, selector: (x: Record<keyof T, keyof T>) => keyof T): keyof T {
    const keyRecord = Object.keys(obj).reduce((res, key) => {
      const typedKey = key as keyof T
      res[typedKey] = typedKey
      return res
    }, {} as Record<keyof T, keyof T>)
    return selector(keyRecord)
  }

  export const phoneRegex = "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$";
  export const ssnRegex = '^(\\d{3}-?\\d{2}-?\\d{4}|XXX-XX-XXXX)$';
  export const emailRegex = '^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$';
  export const ipregex = '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$';

  export function fromISODate(dt: string){
    if (!dt) return dt;
    
    var res = dt.substring(0, 10);
    var arr = res.split('-');
    return `${arr[1]}/${arr[2]}/${arr[0]}`
  }