<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <p3solved-progress-bar [style]="{'height': '0.5rem'}"></p3solved-progress-bar>
</div>

<div *ngIf="!loading && !guidsLoaded">
    <div class="text-3xl text-red-500">
        You MUST select a Customer and Campaign before accessing this page.
    </div>
</div>

<div *ngIf="!loading && guidsLoaded">
    <span class="text-2xl font-medium">Campaign Settings</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-2">
        <div class="cds-card w-screen-fit p-2">
            <div *ngIf="loading" class="p-4">
                <div class="text-2xl">Loading...</div>
                <p3solved-progress-bar [style]="{'height': '0.5rem'}"></p3solved-progress-bar>
            </div>
            <div *ngIf="!loading">
                <div class="mb-2">
                    <form *ngIf="settingsFormLoaded" [formGroup]="settingsForm">
                        <div class="m-3 p-1">
                            <p3solved-form-text id="txtProduct1" placeholder="Name" formControlName="campaignName"
                                [required]="true">
                            </p3solved-form-text>
                        </div>
                        <div class="m-3 p-1">
                            <p3solved-dropdown id="ddlProvider" [items]="customers" optionLabel="customerName"
                                placeholder="Customer" optionValue="customerGUID" [required]="true"
                                formControlName="customerGuid">
                            </p3solved-dropdown>
                        </div>
                        <div class="m-3 p-1">
                            <p3solved-dropdown id="ddlProviders" [items]="listTypes" optionLabel="text"
                                placeholder="Marketing Channel" optionValue="value" [required]="true"
                                formControlName="listTypeId">
                            </p3solved-dropdown>
                        </div>
                        <div class="m-3 p-1">
                            <p3solved-form-text id="txtProduct2" placeholder="Domain" formControlName="domain"
                                [required]="true">
                            </p3solved-form-text>
                        </div>
                        <div class="m-3 p-1">
                            <p3solved-form-text id="txtProduct3" placeholder="Start URL" formControlName="startUrl">
                            </p3solved-form-text>
                        </div>
                        <div class="m-3 p-1">
                            <p3solved-datepicker id="dt1" placeholder="Start Date" formControlName="startDate"
                                panelStyleClass="dp-max">
                            </p3solved-datepicker>
                        </div>
                        <div class="m-3 p-1">
                            <p3solved-datepicker id="dt2" placeholder="End Date" formControlName="endDate">
                            </p3solved-datepicker>
                        </div>
                        <div class="m-3 p-1">
                            <p3solved-form-text id="txtProduct6" placeholder="Clarity Control File Name"
                                formControlName="clarityXmlControlFileName">
                            </p3solved-form-text>
                        </div>
                        <div class="m-3 p-1">
                            <p3solved-form-text id="txtProduct63" placeholder="Clarity Control Version #"
                                formControlName="clarityXmlControlFileVersionNumber">
                            </p3solved-form-text>
                        </div>
                    </form>
                </div>

                <div class="m-2">
                    <table class="table">
                        <thead>
                            <tr class="table-secondary">
                                <th scope="col">LMS Template Prefix</th>
                                <th scope="col">Weight Used</th>
                                <th scope="col">
                                    <p3solved-button id="btnAddNew" label="Add New" (buttonClick)="addTempOption()" [disabled]="!hasEditAccess">
                                    </p3solved-button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let o of templateOptions; index as i">
                                <td>{{o.text}}</td>
                                <td>{{o.value}}%</td>
                                <td>
                                    <div class="flex">
                                        <i class="pi pi-pencil text-primary cursor-pointer mr-4" title="Edit" aria-hidden="true"
                                            (click)="editTempOption(i);"></i>
                                        <i class="pi pi-trash text-primary cursor-pointer" title="Delete" aria-hidden="true"
                                            (click)="deleteTempOption(i);"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="m-3">
                    <div class="flex flex-row">
                        <div class="p-2">
                            <p3solved-button id="btnSaveOffer" (buttonClick)="save();"
                                [disabled]="!hasEditAccess || !settingsForm.valid || saving">
                                SAVE
                            </p3solved-button>
                        </div>
                        <div class="p-2">
                            <p3solved-button id="btnSaveOffer22" (buttonClick)="cancel();" [disabled]="saving"
                                class="outline">
                                Cancel
                            </p3solved-button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>

<p3solved-dialog [isVisible]="showTemplateOption" header="Add" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showTemplateOption=false;" [style]="{'width': '25vw'}">
    <div body>
        <form *ngIf="tplFormLoaded" [formGroup]="tplForm">
            <div class="m-2">
                <p3solved-form-text id="txtProduct1" placeholder="Prefix" [maxlength]="10" formControlName="text"
                    [required]="true">
                </p3solved-form-text>
            </div>
            <div class="m-2">
                <p3solved-input-number id="txtWeight" placeholder="Weight" [min]="1" [max]="100" formControlName="value"
                    [required]="true" mode="decimal">
                </p3solved-input-number>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <p3solved-button id="btnSaveOffer" (buttonClick)="saveTempOption();" [disabled]="!tplForm.valid">
                SAVE
            </p3solved-button>
            <p3solved-button id="btnCancelOption" label="Cancel" class="outline"
                (buttonClick)="showTemplateOption=false;"></p3solved-button>
        </div>
    </div>
</p3solved-dialog>