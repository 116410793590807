import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LookupModel } from 'src/app/models/lookup-model';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss']
})
export class CheckboxListComponent implements OnInit {

  selected: string[] = [];
  @Input()
  get items(): LookupModel[] { return this._items; }
  set items(items: LookupModel[]) {
    this._items = items;
    this.selected = [];
  }
  private _items: LookupModel[] = [];
  @Output() onSelect = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  dataChanged(ev:any){
    this.onSelect.emit(ev);
  }

}
