import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccessGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private _router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let data = route.data; 
      if (data && data['pageId']) {
        return new Promise((resolve) => {
          if (!this.userService.pageAccessLoaded) {
            this.userService.loadUserAdminPages()
            .then(() => {
              let hasAccess = this.userService.hasPageAccess(data['pageId']);              
              if (!hasAccess) {
                this._router.navigate(['not-authorized']);
              }
              resolve(hasAccess);      
            });
          }
          else {
            let hasAccess = this.userService.hasPageAccess(data['pageId']);              
              if (!hasAccess) {
                this._router.navigate(['not-authorized']);
              }
              resolve(hasAccess);
          }
        });
      }
      else return true;
  }
  
}
