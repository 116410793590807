<div class="card">
    <div class="card-body">
        <form [formGroup]="infoForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="providerName"
                        [group]="infoForm"
                        label="Customer Name">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-select 
                        controlName="providerType"
                        [group]="infoForm"
                        [isRequired]="true"
                        [options]="providerRelationships"
                        label="Customer Type">
                    </app-cc-select>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-select 
                        controlName="vertical"
                        [group]="infoForm"
                        [isRequired]="true"
                        [options]="providerTypes"
                        label="Vertical">
                    </app-cc-select>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-input
                        controlName="website"
                        [group]="infoForm"
                        label="Website">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-select 
                        controlName="country"
                        [group]="infoForm"
                        [options]="countries"
                        label="Country">
                    </app-cc-select>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-input
                        controlName="address1"
                        [group]="infoForm"
                        label="Address 1">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-input
                        controlName="address2"
                        [group]="infoForm"
                        label="Address 2">
                    </app-cc-input>

                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="city"
                        [group]="infoForm"
                        label="City">
                    </app-cc-input>

                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-select 
                    controlName="state"
                    [group]="infoForm"
                    [options]="states"
                    label="State">
                </app-cc-select>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="zip"
                        [group]="infoForm"
                        label="Zip">
                    </app-cc-input>

                </div>
            </div>
            <div class="clearfix"></div>
            <h3 class="mb-3">Main Contact</h3>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="contactName"
                        [group]="infoForm"
                        label="Contact Name">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="contactPhone"
                        [group]="infoForm"
                        label="Contact Phone">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="email"
                        [group]="infoForm"
                        label="Email">
                    </app-cc-input>
                </div>
            </div>
            <button class="btn btn-primary btn-main btn-large" type="submit">Create Customer</button>
        </form>
    </div>
</div>
