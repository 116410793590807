<div [formGroup]="group" class="mb-1">
    <mat-form-field appearance="standard" floatLabel="always">
        <mat-label>{{label}}
            <fa-icon 
            *ngIf="group && group.controls[controlName]?.errors?.required" 
            [icon]="iconRequired" 
            size="xs" 
            [styles]="{'color': 'red'}" 
            [classes]="['ms-1']">
        </fa-icon>
        </mat-label>
        <input matInput [formControlName]="controlName" type="number" [min]="min" [max]="max" class="dec-right-align" placeholder="0">
        <span matPrefix>$&nbsp;</span>
        <mat-error *ngIf="group && group.controls[controlName]?.invalid">{{errorMessage}}</mat-error>
      </mat-form-field>
  </div>