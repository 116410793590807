import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-edit-other',
  templateUrl: './edit-other.component.html',
  styleUrls: ['./edit-other.component.scss']
})
export class EditOtherComponent implements OnInit {
  otherInfoForm = this.fb.group({
    saveOnSingle: [false],
    processStatement: [false],
    allowMultiplePayments: [false],
    clarityFilename: [''],
    clarityVersionNum: [''],
    clarityLocId: [''],
    clarityPurposeType: [''],
    clarityTradelineType: [''],
    verificationAutoAssign: [false],
    verificationMaxApps: [2],
    verificationMinWait: [5],
    verificationInactivity: [10]
  });
  constructor(private fb: UntypedFormBuilder) {

   }

  ngOnInit(): void {
  }

  onSubmit(){
    console.warn(this.otherInfoForm.value);
  }
}
