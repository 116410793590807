<span class="text-2xl font-medium">User Edit</span>
<p-breadcrumb [model]="bcItems"></p-breadcrumb>
<div *ngIf="loading" class="m-5">
    <div class="text-2xl">Loading...</div>
    <p3solved-progress-bar [style]="{'height': '0.5rem'}"></p3solved-progress-bar>
</div>
<div *ngIf="!loading" class="mt-2">
    <div class="row">
        <div class="col-md-12 col-lg-4">
            <div class="cds-card w-screen-fit p-2">
                <div *ngIf="showEdit">
                    <app-edituser-info [userGuid]="userGuid" [user]="user" (onEditComplete)="onEditComplate($event)"></app-edituser-info>
                </div>
                <div *ngIf="!showEdit">
                    <div class="d-flex justify-content-center m-3">
                        <div class="p-2">
                            <p class="m-0 h3">
                                <span class="m-1">{{user.FirstName}}</span>
                                <span class="m-1">{{user.LastName}}</span>
                            </p>                            
                            <p class="m-1 text-muted">
                                {{user.Company}}
                            </p>
                        </div>
                    </div>
                    <hr class="m-3"/>
                    <div class="flex flex-row-reverse">
                        <div class="m-2">
                            <p3solved-button id="btnSaveRecs" label="EDIT" (buttonClick)="edit()">
                            </p3solved-button>
                        </div>
                    </div>
                    <div class="d-flex flex-column mb-3">
                        <div class="p-2">
                            <p class="m-0">
                                <span class="text-muted">Email</span><br/>
                                <strong>{{user.LoginEmail}}</strong>
                            </p>
                        </div>
                        <div class="p-2">
                            <p class="m-0">
                                <span class="text-muted">Cell Phone</span><br/>
                                <strong>{{user.Mobile}}</strong>
                            </p>
                        </div>
                        <div class="p-2">
                            <p class="m-0">
                                <span class="text-muted">Work Phone</span><br/>
                                <strong>{{user.BusinessPhone}}</strong>
                            </p>
                        </div>
                        <div class="p-2">
                            <p class="m-0">
                                <span class="text-muted">Time Zone</span><br/>
                                <strong>{{user.TimeZoneAbb}} - {{user.TimeZoneDescription}}</strong>
                            </p>
                        </div>
                      </div>
                </div>

            </div>
        </div>
        <div class="col-md-12 col-lg-8">
            <div class="cds-card w-screen-fit p-2">
                <div class="d-flex">
                    <div class="p-2 flex-grow-1">
                        <p-tabView>
                            <p-tabPanel header="User Settings">
                              <app-edituser-settings [userGuid]="userGuid" [user]="user" (onEditComplete)="onEditComplate($event)"></app-edituser-settings>
                            </p-tabPanel>
                            <p-tabPanel header="Security">
                              <app-edituser-security [userGuid]="userGuid" [user]="user" (onEditComplete)="onEditComplate($event)"></app-edituser-security>
                            </p-tabPanel>
                          </p-tabView>
                    </div>
                    <div class="p-2">
                        <p3solved-button id="btnSaveRecs" label="SAVE" (buttonClick)="save()">
                        </p3solved-button>
                    </div>
                  </div>

            </div>
        </div>
    </div>
</div>