<form [formGroup]="infoForm" (ngSubmit)="saveProviderInfo()">
    <div class="grid">

        <div class="col-6 pt-4">
            <p3solved-dropdown id="ddType" placeholder="Type" [items]="lookups.providerTypes" optionLabel="desc"
                optionValue="id" [showClear]="false" [required]="true" formControlName="type"
                [disabled]="provider != null">
            </p3solved-dropdown>
        </div>
        <div class="col-6 pt-4">
            <p3solved-dropdown id="ddVertical" placeholder="Vertical" [items]="lookups.providerRelationships"
                optionLabel="desc" optionValue="id" [showClear]="false" [required]="true" formControlName="vertical"
                [disabled]="provider != null">
            </p3solved-dropdown>
        </div>
        <div class="col-12 pt-4">
            <div class="w-6 pr-2">
                <p3solved-form-text id="txtName" placeholder="Customer Name" [required]="true" formControlName="name"
                [disabled]="provider != null" (blurEvent)="checkProviderName($event)">
                </p3solved-form-text>
            </div>
        </div>
        <div class="col-6 pt-4">
            <p3solved-form-text id="txtDisplayName" placeholder="Customer Display Name" [required]="true"
                formControlName="displayName">
            </p3solved-form-text>
        </div>
        <div class="col-6 pt-4">
            <p3solved-form-text id="txtWebsite" placeholder="Website" [required]="true" formControlName="website">
            </p3solved-form-text>
        </div>
        <div class="col-12 pt-4">
            <div class="w-6 pr-2">
                <p3solved-dropdown id="ddCountry" placeholder="Country" [items]="lookups.countries" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true"
                    formControlName="country" (optionSelected)="toggelShowState($event);">
                </p3solved-dropdown>
            </div>
        </div>
        <div class="col-6 pt-4">
            <p3solved-form-text id="txtAddress1" placeholder="Address" [required]="true" formControlName="address1">
            </p3solved-form-text>
        </div>
        <div class="col-6 pt-4">
            <p3solved-form-text id="txtAddress2" placeholder="Address 2" [required]="false" formControlName="address2">
            </p3solved-form-text>
        </div>
        <div class="col-4 pt-4">
            <p3solved-form-text id="txtCity" placeholder="City" [required]="true" formControlName="city">
            </p3solved-form-text>
        </div>
        <div class="col-4 pt-4">
            <p3solved-dropdown *ngIf="showStateDD" id="ddState" placeholder="State" [items]="states" 
                optionLabel="name" optionValue="abbr" [showClear]="false" [required]="true" formControlName="state">
            </p3solved-dropdown>
            <p3solved-form-text *ngIf="!showStateDD" id="txtState"
                placeholder="State / Province / Region" [required]="true" formControlName="state">
            </p3solved-form-text>
        </div>
        <div class="col-4 pt-4">
            <p3solved-form-text id="txtZip" placeholder="Zip" [required]="true" formControlName="zip">
            </p3solved-form-text>
        </div>
        <div class="col-12 mt-5 pr-2">
            <div class="pt-4 border-top-1 border-black-alpha-40 text-3xl font-bold">Main Contact</div>
        </div> 

        <div class="col-4 pt-4">
            <p3solved-form-text id="txtContactName" placeholder="Contact Name" [required]="true" formControlName="contactName">
            </p3solved-form-text>
        </div>
        <div class="col-4 pt-4">
            <p3solved-form-text id="txtPhone" placeholder="Phone" [required]="true" formControlName="phone">
            </p3solved-form-text>
        </div>
        <div class="col-4 pt-4">
            <p3solved-form-text id="txtEmail" placeholder="Email" [required]="true" formControlName="email">
            </p3solved-form-text>
        </div>

        <div class="col-12 flex flex-row-reverse">
            <p3solved-button *ngIf="provider" id="btnUpdate" buttonType="submit"><span class="px-4">Update</span></p3solved-button>
            <p3solved-button *ngIf="!provider" id="btnCreate" buttonType="submit"><span class="px-4">Create Customer</span></p3solved-button>
            <p3solved-button *ngIf="!provider" id="btnClear" class="mr-2 outline" (buttonClick)="infoForm.reset();"><span class="px-4">Clear Form</span></p3solved-button>
        </div>        

    </div>
</form>