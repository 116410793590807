import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { PortfolioItem } from 'src/app/models/customer-item';
import { ComplianceCriteriaFormGroup } from 'src/app/models/form-groups';
import { ComplianceLookups } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { ComplianceCriteriaDateValidator, ComplianceCriteriaFormValidator } from 'src/app/validators/compliance-validators';
import { CustomGridComponent } from '../custom-grid/custom-grid.component';

@Component({
  selector: 'app-compliance-payment-statement',
  templateUrl: './compliance-payment-statement.component.html',
  styleUrls: ['./compliance-payment-statement.component.scss']
})
export class CompliancePaymentStatementComponent implements OnInit, AfterViewInit {

  @Input() section: 'payment' | 'statement' = 'payment';
  @Input() selectedPortfolio: PortfolioItem | null = null;

  criteriaFormLoaded: boolean = false;

  searchType: 'criteria' | 'loan' | null = null;
  header: string = '';
  loanNumsSearch: string = '';
  pageBaseBc: string = '';
  dataSource: string = '';

  upcomingSelected: number = 0;

  bcItems: MenuItem[] = [];

  lookups: ComplianceLookups = {} as ComplianceLookups;
  criteriaForm: FormGroup<ComplianceCriteriaFormGroup> = {} as FormGroup;

  datePipe: DatePipe = new DatePipe('en-US');

  @ViewChild('cgComplianceData') cgComplianceData: CustomGridComponent = {} as CustomGridComponent;


  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private navService: NavService
  ) { }

  ngOnInit(): void {
    if (this.section == 'payment') {
      this.header = 'Payments';
      this.pageBaseBc = 'Upcoming Payments';
      this.dataSource = 'dsCompliance_UpcomingPayments';
    }
    else {
      this.header = 'Statements';
      this.pageBaseBc = 'Upcoming Statements';
      this.dataSource = 'dsCompliance_UpcomingStatements';
    }

    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Compliance & Audit', routerLink: ['/compliance/dashboard'], command: () => { this.navService.setLeftNavSelection('Compliance.Dashboard'); } },
      { label: this.pageBaseBc }
    ];
  }

  ngAfterViewInit(): void {    
    if (this.selectedPortfolio) {    
      this.waitForCustomGridLoad();  
    }
    else {
      console.log('selected portfolio not avail');
    }
  }

  waitForCustomGridLoad(attempts: number = 0) {
    if (this.cgComplianceData) {
      this.cgComplianceData.DataSourceName = this.dataSource;
      this.cgComplianceData.setCustomerInfo(this.selectedPortfolio?.customerGuid ?? null, null);
      this.initPage();
    }
    else if (attempts > 10) {
      console.error('Maximum attempt waiting on custom grid Compliance data');      
    }
    else {
      setTimeout(() => {
        attempts++;
        this.waitForCustomGridLoad(attempts);
      }, 100);
    }
  }

  initPage() {
    this.getLookups();
    this.initCriteriaForm();
    this.setGridDataSource();
  }

  getLookups() {
    let lookSub = this.apiService.get(`compliance/${this.selectedPortfolio?.customerGuid}/lookups`)
      .subscribe({
        next: (data: ComplianceLookups) => {
          this.lookups = data;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Unable to get Compliance Lookups. See log for details.`
          }, 'center');
          console.error(err);
        },
        complete: () => { lookSub.unsubscribe(); }
      });
  }

  initCriteriaForm() {
    this.criteriaForm = new FormGroup<ComplianceCriteriaFormGroup>({
      state: new FormControl<string | null>(null, { nonNullable: true }),
      legal: new FormControl<string | null>(null, { nonNullable: true }),
      commentCode: new FormControl<string | null>(null, { nonNullable: true }),
      commentCodes: new FormControl<string[] | null>(null, { nonNullable: true }),
      loanStatus: new FormControl<string | null>(null, { nonNullable: true }),
      appStatus: new FormControl<string | null>(null, { nonNullable: true }),
      channel: new FormControl<string | null>(null, { nonNullable: true }),
      startDate: new FormControl<Date | null>(null, { nonNullable: true }),
      endDate: new FormControl<Date | null>(null, { nonNullable: true }),
    }, {
      validators: [ComplianceCriteriaFormValidator(), ComplianceCriteriaDateValidator()]
    });

    this.criteriaFormLoaded = true;
  }

  getUpcoming(days: number) {
    this.upcomingSelected = days;
    this.setGridDataSource();
  }

  submitCriteriaForm() {
    this.searchType = 'criteria';
    this.setGridDataSource();
  }

  searchLoans() {
    this.searchType = 'loan';
    this.setGridDataSource();
  }

  async setGridDataSource() {
    let payDate = new Date();
    payDate.setDate(payDate.getDate() + this.upcomingSelected);
    let strPayDate = this.datePipe.transform(payDate, 'MM/dd/yyyy');

    let doc = document.implementation.createDocument('', 'params');
    let params = doc.querySelector('params');

    params?.appendChild(this.createFilterNode(doc, 'PaymentDate', strPayDate ?? ''));

    if (this.searchType && this.searchType == 'criteria') {
      if (this.criteriaForm.value.state) {
        params?.appendChild(this.createFilterNode(doc, 'StateID', this.criteriaForm.value.state));
      }

      if (this.criteriaForm.value.legal) {
        params?.appendChild(this.createFilterNode(doc, 'LegalStatusID', this.criteriaForm.value.legal));
      }

      if (this.criteriaForm.value.loanStatus) {
        params?.appendChild(this.createFilterNode(doc, 'LoanStatusID', this.criteriaForm.value.loanStatus));
      }

      if (this.criteriaForm.value.commentCode) {
        params?.appendChild(this.createFilterNode(doc, 'NoteCategoryID', this.criteriaForm.value.commentCode));
      }

      if (this.criteriaForm.value.startDate) {
        let strtDt = this.datePipe.transform(this.criteriaForm.value.startDate, 'MM/dd/yyyy');
        params?.appendChild(this.createFilterNode(doc, 'DateFrom', strtDt ?? ''));
      }

      if (this.criteriaForm.value.endDate) {
        let endDt = this.datePipe.transform(this.criteriaForm.value.endDate, 'MM/dd/yyyy');
        params?.appendChild(this.createFilterNode(doc, 'DateTo', endDt ?? ''));
      }
    }
    else if (this.searchType && this.loanNumsSearch.length > 0) {
      let loanNums: string[] = this.loanNumsSearch.split(',');
      let tempLoan: string[] = [];
      for (let i: number = 0; i < loanNums.length; i++) {
        let loanNumber: string = loanNums[i].trim();
        let isValid: boolean = await this.apiService.get(`compliance/${this.selectedPortfolio?.customerGuid}/loan/${loanNumber}/check`).toPromise<boolean>();
        if (isValid) {
          tempLoan.push(loanNumber);
        }
      }
      if (tempLoan.length > 0) {
        params?.appendChild(this.createFilterNode(doc, 'LoanNumber', tempLoan.join(',')));
      }
    }

    const serializer = new XMLSerializer();
    let criteriaXml = serializer.serializeToString(doc);

    this.setCustomGridXML(criteriaXml, 0);
  }

  setCustomGridXML(xml: string, attempt: number) {
    if (this.cgComplianceData) {
      this.cgComplianceData.SearchXML = xml;
      setTimeout(() => {
        this.cgComplianceData.getDataInfo();
      });

    }
    else {
      if (attempt > 10) {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to set filter on Compliance Data.'
        });
        console.error("Unable to set filter (SearchXML) on Compliance Data custom grid component!");
      }
      else {
        setTimeout(() => {
          attempt++;
          this.setCustomGridXML(xml, attempt);
        }, 100);
      }
    }
  }

  createFilterNode(parentNode: XMLDocument, name: string, value: string): HTMLElement {
    let child = parentNode.createElement('f');
    child.setAttribute('fn', name);
    child.setAttribute('fv', value);
    return child;
  }

}
