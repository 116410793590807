import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { P3SolvedAutocompleteComponent } from '@p3solved/p3solved-ui';
import { DERuleField } from 'src/app/models/decision/rule';
import { PgDataScriptFormGroup } from 'src/app/models/form-groups';
import { PageDataFunctionLk, PageDataScript } from 'src/app/models/page-data';

@Component({
  selector: 'app-page-data-script',
  templateUrl: './page-data-script.component.html',
  styleUrls: ['./page-data-script.component.scss']
})
export class PageDataScriptComponent implements OnInit {

  @Input() data: PageDataScript | null | undefined = null;
  @Input() pageFields: DERuleField[] = [];
  @Input() functions: PageDataFunctionLk[] = [];
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<any>();

  replaceResults: DERuleField[] = [];
  scriptForm: FormGroup<PgDataScriptFormGroup> = {} as FormGroup;
  scriptFormLoaded: boolean = false;
  emptyDataMsg: boolean = false;
  selectedFunction: PageDataFunctionLk | null = null;

  @ViewChild('acTags') acTags: P3SolvedAutocompleteComponent = {} as P3SolvedAutocompleteComponent;
  constructor() { }

  ngOnInit(): void {
    if (!this.data) {
      this.emptyDataMsg = true;
      return;
    }
    this.initForm();
    this.emptyDataMsg = false;
    this.scriptFormLoaded = true;
  }

  initForm() {
    this.scriptForm = new FormGroup<PgDataScriptFormGroup>({
      name: new FormControl<string>(this.data?.alias ?? '', { nonNullable: true }),
      objectId: new FormControl<string>(this.data?.name ?? '', { nonNullable: true, validators: [Validators.required] }),
      version: new FormControl<number | null>(this.data?.versionId ?? null, { nonNullable: true }),
      script: new FormControl<string>(this.data?.script ?? '', { nonNullable: true }),
    });
  }

  saveScriptForm() {
    if (this.data) {
      this.data.alias = this.scriptForm.value.name ?? this.data.alias;
      this.data.name = this.scriptForm.value.objectId ?? this.data.name;
      this.data.versionId = this.scriptForm.value.version ?? this.data.versionId;
      this.data.script = this.scriptForm.value.script ?? this.data.script;
    }
    let saveObj = {
      element: 'script',
      data: this.data
    };

    this.onSave.emit(saveObj);
  }

  cancelForm() {
    this.onCancel.emit();
  }

  searchTags(event: any) {
    let search = this.pageFields.filter(pf => pf.fieldName.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    if (search && search.length) this.replaceResults = search;
    else this.replaceResults = [];
  }

  insertTag() {
    let tagTxt = this.acTags.value?.fieldName ?? '';
    if (tagTxt.length) {
      let newVal = this.scriptForm.value.script + `{!${tagTxt}}`;
      this.scriptForm.patchValue({
        script: newVal
      });
    }
    this.acTags.value = null;
    this.acTags.innerValue = null;
  }

  functionSelected(funcId: number) {
    this.selectedFunction = this.functions.find(f => f.pageFunctionID == funcId) ?? null;
  }

  insertFunc() {
    if (this.selectedFunction) {
      let newVal = this.scriptForm.value.script + `.${this.selectedFunction.pageFunctionName}()`;
      this.scriptForm.patchValue({
        script: newVal
      });      
    }
  }

}
