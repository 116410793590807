import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableColumn } from '@p3solved/p3solved-ui';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { PortfolioItem } from 'src/app/models/customer-item';
import { NameValuePair } from 'src/app/models/name-value-pair';
import {
  AdminReportSettlementCheck, AdminReportSettlementFilterValue,
  AdminReportSettlementSummary,
  AdminReportSettlementTransaction
} from 'src/app/models/report/report-dashboard';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-settlement-summary',
  templateUrl: './settlement-summary.component.html',
  styleUrls: ['./settlement-summary.component.scss']
})
export class SettlementSummaryComponent implements OnInit, AfterViewInit, OnDestroy {

  processFeatureCode: string = 'SETTLEMENT_SUMMARY_PROCESS';
  testFeatureCode: string = 'SETTLEMENT_SUMMARY_SEND_TEST';

  hasProcessFeature: boolean = false;
  hasTestFeature: boolean = false;

  loading: boolean = false;
  showDeleteTransMsg: boolean = false;
  showFileAction: boolean = false;
  blnUpdateCSR: boolean = false;

  portfolioItem: PortfolioItem | null | undefined;

  bcItems: MenuItem[] = [{ label: 'Reports', routerLink: ['/report'] },
  { label: 'Past Settlements', routerLink: ['/report/settlement-listing'] },
  { label: 'Settlement ' }];
  subscriptions: Subscription[] = [];
  pastLogs: any[] = [];
  transactionColumns: TableColumn[] = [];
  selectedFilter: AdminReportSettlementFilterValue | null = null;
  filterFields: string[] = [];
  fileActionHeader: string = '';
  fileAction: 'create' | 'send' | null = null;
  fileActionVendor: AdminReportSettlementFilterValue | null = null;
  processRecord: ForceProcessingRecord | null = null;

  filterName: string | null = null;
  filterOp: string | null = null;
  filterValue: string | null = null;
  removeFilter: string | null = null;

  first: number = 0;
  rows: number = 100;
  heading: string = 'ACH Settlement';
  reportDate: Date | null = null;
  achVendors: ACHSettlementVendor = {} as ACHSettlementVendor;
  transactions: AdminReportSettlementTransaction[] = [];
  checks: AdminReportSettlementCheck[] = [];
  delTransaction: AdminReportSettlementTransaction | null = null;
  tranDelOptions: NameValuePair[] = [
    { name: 'Remove From File and NLS', value: 'fileandnls' }, { name: 'Remove From File', value: 'file' }
  ];
  selectedDelOption: string = this.tranDelOptions[0].value;
  nlsNotes: string | null = null;

  constructor(
    private portfolioService: PortfolioService,
    private userService: UserService,
    private apiService: ApiService,
    private toastService: ToastService,
    private settings: SettingService,
    private activatedRoute: ActivatedRoute) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.portfolioService.portfolio$.subscribe(p => {
        this.portfolioItem = p;
        if (p) this.checkQueryParams();
      }),
      this.userService.siteFeatures$.subscribe(features => {
        this.hasProcessFeature = features.find(f => f.code == this.processFeatureCode)?.allowWrite ?? false;
        this.hasTestFeature = features.find(f => f.code == this.testFeatureCode)?.allowWrite ?? false;
      })
    );
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  checkQueryParams() {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(query => {
        if (query && query.dt) {
          this.reportDate = new Date(new Date().setTime(query.dt));
          let formatter = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
          let strDate = formatter.format(this.reportDate);
          this.heading += ` - ${strDate}`;
          let bcItm = this.bcItems.pop();
          if (bcItm && bcItm.label) {
            bcItm.label += ` - ${strDate}`;
            this.bcItems.push(bcItm);
          }
        }
        else {
          this.reportDate = null;
        }
        this.getSettlementSummary();
      })
    );
  }

  getSettlementSummary() {
    let body = {
      customerGuid: this.portfolioItem?.customerGuid,
      campaignGuid: null,
      date: this.reportDate,
      filter: this.getFilter(),
      order: 'asc',
      startRow: 1,
      pageSize: 999999
    };

    let summSub = this.apiService.post(`adminreport/settlement/report/summary`, body)
      .subscribe({
        next: (data: AdminReportSettlementSummary) => {
          this.achVendors.vendors = [];
          this.achVendors.totals = data.values.find(v => v.vendorID == 999) ?? {} as AdminReportSettlementFilterValue;
          let uniqueVendors = [...new Set(data.values.map(v => v.vendorID).filter(v => v != 999))];
          let uniqueTypes = [...new Set(data.values.filter(v => v.vendorID != 999).map(v => v.transTypeID))];
          uniqueVendors.forEach(uv => {
            uniqueTypes.forEach(ut => {
              this.achVendors.vendors.push(data.values.filter(v => v.vendorID == uv && v.transTypeID == ut));
            });
          });

          data.transactions.forEach(t => {
            t.transAsDate = new Date(t.transactionDate);
          });
          this.transactions = data.transactions;

          this.checks = data.checks;
          this.checks.forEach(c => {
            c.description = c.description.replace('&gt;', '>').replace('&lt;', '<');
          })

          this.initColumns();
          this.loading = false;
        },
        error: (err: any) => {
          console.error(err);
        },
        complete: () => { summSub.unsubscribe(); }
      })
  }

  getFilter(): string {
    let doc = document.implementation.createDocument('', 'filter');
    let filter = doc.querySelector('filter');

    if (this.filterName) {
      let or = doc.createElement('or');

      if (this.selectedFilter) {
        let vAnd = doc.createElement('and');
        vAnd.setAttribute('fn', 'VendorName');
        vAnd.innerHTML = this.selectedFilter.vendorName;
        let tAnd = doc.createElement('and');
        tAnd.setAttribute('fn', 'TransTypeName');
        tAnd.innerHTML = this.selectedFilter.transTypeName;

        or.appendChild(vAnd);
        or.appendChild(tAnd);
      }

      let names: string[] = this.filterName.split(',');
      let ops: string[] = this.filterOp ? this.filterOp.split(',') : [];
      let values: string[] = this.filterValue ? this.filterValue.split(',') : [];

      for (let i = 0; i < names.length; i++) {
        let name = names[i];
        let op = ops.length > i ? ops[i] : null;
        let value = values.length > i ? values[i] : null;

        let nAnd = doc.createElement('and');
        nAnd.setAttribute('fn', name);
        if (op && op != '=') nAnd.setAttribute('op', op);
        if (value) nAnd.innerHTML = value;
        else nAnd.innerHTML = "1";

        or.appendChild(nAnd);
      }

      filter?.appendChild(or);
    }

    const serializer = new XMLSerializer();
    return serializer.serializeToString(doc);

  }

  filterVendor(v: AdminReportSettlementFilterValue, i: number) {
    this.selectedFilter = v;
    this.removeFilter = `Remove Filter (#vendorName#${this.achVendors.vendors.length > 1 ? ` from ${v.vendorName} - ${v.transTypeName}` : ''})`;
    switch (i) {
      case 1:
        this.filterName = v.filterName1;
        this.filterOp = v.filterOp1;
        this.filterValue = v.filterValue1;
        this.removeFilter = this.removeFilter.replace('#vendorName#', v.name1);
        break;
      case 2:
        this.filterName = v.filterName2;
        this.filterOp = v.filterOp2;
        this.filterValue = v.filterValue2;
        this.removeFilter = this.removeFilter.replace('#vendorName#', v.name2);
        break;
      case 3:
        this.filterName = v.filterName3;
        this.filterOp = v.filterOp3;
        this.filterValue = v.filterValue3;
        this.removeFilter = this.removeFilter.replace('#vendorName#', v.name3);
        break;
      case 4:
        this.filterName = v.filterName4;
        this.filterOp = v.filterOp4;
        this.filterValue = v.filterValue4;
        this.removeFilter = this.removeFilter.replace('#vendorName#', v.name4);
        break;
      default:
        this.filterName = null;
        this.filterOp = null;
        this.filterValue = null;
        this.selectedFilter = null;
        this.removeFilter = null;
        break;
    }
    this.getSettlementSummary();
  }

  filterCheck(c: AdminReportSettlementCheck) {
    this.removeFilter = `Remove Filter (${c.description})`;
    this.filterName = c.filterName;
    this.filterOp = c.filterOp;
    this.filterValue = c.filterValue;
    this.getSettlementSummary();
  }

  removeFilters() {
    this.filterName = null;
    this.filterOp = null;
    this.filterValue = null;
    this.selectedFilter = null;
    this.removeFilter = null;
    this.getSettlementSummary();
  }

  initColumns() {
    this.transactionColumns = [
      {
        field: 'acctRefNo',
        header: 'Acct Ref',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'loanNumber',
        header: 'Loan Number',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'fullName',
        header: 'Full Name',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'tranCode',
        header: 'Tran Code',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'tranRefNo',
        header: 'Tran Ref No',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'transAsDate',
        header: 'Trans Date',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'transactionDate',
        header: 'TransDate',
        clickable: false,
        show: false,
        sortable: false,
        class: null
      },
      {
        field: 'vendorName',
        header: 'Vendor Name',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'tranAmount',
        header: 'Amount',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      }
    ];
    this.filterFields = [...new Set(this.transactionColumns.map(c => c.field))];
  }

  getDupeTransType(transaction: AdminReportSettlementTransaction): string {
    let dupeStr: string = '';

    if (transaction.isROP > 0) dupeStr = "R";
    else if (transaction.isPushedPayment > 0) dupeStr = "P";

    return dupeStr;
  }

  confirmDeleteTransaction(transaction: AdminReportSettlementTransaction) {
    this.delTransaction = transaction;
    this.selectedDelOption = this.tranDelOptions[0].value;
    this.nlsNotes = null;
    this.blnUpdateCSR = true;
    this.showDeleteTransMsg = true;
  }

  deleteTransaction() {
    let body = {
      customerGuid: this.portfolioItem?.customerGuid,
      campaignGuid: null,
      transactionId: this.delTransaction?.transactionID,
      accountRefNo: this.delTransaction?.acctRefNo,
      deleteOption: this.selectedDelOption,
      nlsNotes: this.nlsNotes,
      updateCSR: this.blnUpdateCSR
    };

    let delSub = this.apiService.post(`adminreport/settlement/summary/delete`, body)
      .subscribe({
        next: () => {
          this.delTransaction = null;
          this.showDeleteTransMsg = false;
          this.getSettlementSummary();
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Transaction successfully deleted.'
          });
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to delete transaction. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { delSub.unsubscribe(); }
      });
  }

  sendTestAction(vendor: AdminReportSettlementFilterValue) {
    this.fileActionVendor = vendor;
    this.fileAction = 'create';
    this.fileActionHeader = `Are you sure you want to create the file (${vendor.vendorName} - ${vendor.transTypeName})`;
    this.showFileAction = true;
  }

  processAction(vendor: AdminReportSettlementFilterValue) {
    this.fileActionVendor = vendor;
    this.fileAction = 'send';
    this.fileActionHeader = `Are you sure you want to send the file (${vendor.vendorName} - ${vendor.transTypeName})`;
    this.showFileAction = true;
  }

  executeFileAction() {
    let proceed: boolean = false;
    if (this.fileAction == 'send') {
      let body: ForceProcessingRecord = {
        processed: false,
        action: '',
        vendorId: this.fileActionVendor?.vendorID ?? 0,
        transTypeId: this.fileActionVendor?.transTypeID ?? 0,
        customerGuid: this.portfolioItem?.customerGuid ?? ''
      };
      let fileSub = this.apiService.post(`adminreport/file/action/allow`, body)
      .subscribe({
        next: (res: any) => {
          if (res.result == 'ok') proceed = true;          
          else {
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: `Error processing AllowSend, "${res.result}". See log for details.`
            }, 'center');            
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Error getting file action status. See log for details.`
          }, 'center');          
          console.error(err);
        },
        complete: () => { 
          fileSub.unsubscribe(); 
        }
      });
    }

    if (proceed) this.setForceProcessing();
  }

  setForceProcessing() {
    let body: ForceProcessingRecord = {
      processed: false,
      action: this.fileAction ?? '',
      vendorId: this.fileActionVendor?.vendorID ?? 0,
      transTypeId: this.fileActionVendor?.transTypeID ?? 0,
      customerGuid: this.portfolioItem?.customerGuid ?? ''
    };

    let processSub = this.apiService.post(`adminreport/file/action`, body)
      .subscribe({
        next: () => {
          let actionStr = this.fileAction == 'create' ? 'created' : 'sent';
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: `File will be ${actionStr} soon!`
          });          
        },
        error: (err: any) => {
          let actionStr = this.fileAction == 'create' ? 'creating' : 'sending';
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Error in ${actionStr} file. See log for details.`
          }, 'center');          
          console.error(err);
        },
        complete: () => { 
          this.showFileAction = false;
          this.fileAction = null;
          this.fileActionVendor = null;
          this.fileActionHeader = '';
          processSub.unsubscribe(); 
        }
      });
  }

  goToTarget(transaction: AdminReportSettlementTransaction) {
    this.settings.csAppUrl()
    .then(csUrl => {
      let url = `${csUrl}/dashboard/${this.portfolioItem?.customerGuid}/${transaction.campaignGUID}/${transaction.targetGUID}`;  
      window.open(url, '_blank');
    });    
  }

}

export class ACHSettlementVendor {
  vendors: AdminReportSettlementFilterValue[][] = [];
  totals: AdminReportSettlementFilterValue = {} as AdminReportSettlementFilterValue;
}

export class ForceProcessingRecord {
  processed: boolean = false;
  action: string = '';
  vendorId: number = 0;
  transTypeId: number = 0;
  customerGuid: string = '';
}