<form [formGroup]="locationForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
            <app-cc-input
                controlName="locationId"
                [group]="locationForm"
                label="Location Id">
            </app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <app-cc-input
                controlName="locationName"
                [group]="locationForm"
                label="Location Name">
            </app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <app-cc-input
                controlName="address1"
                [group]="locationForm"
                label="Address 1">
            </app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <app-cc-input
                controlName="address2"
                [group]="locationForm"
                label="Address 2">
            </app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
            <app-cc-input
                controlName="city"
                [group]="locationForm"
                label="City">
            </app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
            <app-cc-select 
                controlName="state"
                [group]="locationForm"
                [options]="states"
                label="State">
            </app-cc-select>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
            <app-cc-input
                controlName="zip"
                [group]="locationForm"
                label="Zip">
            </app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <app-cc-select 
                controlName="country"
                [group]="locationForm"
                [options]="countries"
                label="Country">
            </app-cc-select>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <app-cc-input
                controlName="website"
                [group]="locationForm"
                label="Website">
            </app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <app-cc-input
                controlName="email"
                [group]="locationForm"
                label="Email">
            </app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <app-cc-input
                controlName="phone"
                [group]="locationForm"
                label="Phone">
            </app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <app-cc-input
                controlName="contactName"
                [group]="locationForm"
                label="Contact Name">
            </app-cc-input>
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <div class="p-2">
            <button class="btn btn-secondary" (click)="onCancel()" type="button">Cancel</button>
        </div>
        <div class="p-2">
            <button class="btn btn-primary btn-main" type="submit">Save Location</button>
        </div>
    </div>
    
</form>
