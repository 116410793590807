import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfigs } from '../configs/app-config';
import { Campaign } from '../models/campaign';
import { ApiService } from './api.service';
import { DataGridInfo, DataGridResult, GridDataService } from './grid-data.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private campaigns = new BehaviorSubject<Campaign[] | null>(null);
  private selectedCampaign = new BehaviorSubject<Campaign | null>(null);
  selectedCampaign$ = this.selectedCampaign.asObservable();
  campaigns$ = this.campaigns.asObservable();
  cachedCampaigns: Campaign[] = [];


  customerGuid: string = '';

  constructor(
    private gridDataService: GridDataService,
    private apiService: ApiService
    ) { 
    this.setCampaignFromStorage();
  }

  getCampaigns(customerGuid: string, campaignGuid?: string) {
    this.customerGuid = customerGuid;

    let gridData: DataGridInfo = {
      customerGuid: this.customerGuid,
      dataSourceName: "dsAdminCampaignList",
      remoteIPAddress: ""
    };

    this.gridDataService.getDataInfo(gridData)
    .then((result: DataGridResult) => {
      if (result.error) {
        return this.setError(result.error);
      }
      console.log('getCampaigns result: ', result);
      this.cachedCampaigns = [];
        for (let i: number = 0; i < result.data.length; i++) {
          this.cachedCampaigns.push({
            campaignId: result.data[i]['CampaignID'],
            active: result.data[i]['Active'].toLowerCase() === "true",
            campaignName: result.data[i]['CampaignName'],
            campaignGuid: result.data[i]['CampaignGUID'],
            clarity: result.data[i]['Clarity Test']?.toLowerCase() === "true",
            domain: result.data[i]['Domain'],
            provider: result.data[i]['Provider'],
            startDate: result.data[i]['Start Date'],
            targets: result.data[i]['Targets'],
            type: result.data[i]['Type'],
            vertical: result.data[i]['Vertical']
          });
        };
        this.campaigns.next(this.cachedCampaigns);
        if (campaignGuid && campaignGuid.length > 10) {
          // setTimeout(() => {
            
            this.selectCampaignbyGuid(campaignGuid);            
          // }, 500);
        }
        else if (this.cachedCampaigns.length == 1) {
          this.selectCampaignbyGuid(this.cachedCampaigns[0].campaignGuid);
        }
        else {          
          this.setCampaignFromStorage();
        }
    }, (err: any) => {
      this.setError(err);
    });    
  }

  setError(error: any): any {
    this.cachedCampaigns = [];
    this.campaigns.next(null);
    this.selectedCampaign.next(null);
    console.error(error);
  }

  selectCampaignbyGuid(campaignGuid:string) {
    let found = this.cachedCampaigns.find(c => c.campaignGuid === campaignGuid);
    if (!found) {
      this.selectedCampaign.next(null);
      return;
    }
    this.saveCampaignToStorage(found);
    this.selectedCampaign.next(found);
  }

  selectCampaign(campaign: Campaign) {
    let found = this.cachedCampaigns.find(c => c.campaignGuid === campaign.campaignGuid);
    if (!found) {
      this.selectedCampaign.next(null);
      return;
    }
    this.saveCampaignToStorage(found);
    this.selectedCampaign.next(found);
  }

  saveCampaignToStorage(campaign: Campaign) {
    localStorage.setItem(AppConfigs.selectedCampaignStorageKey, JSON.stringify(campaign));
  }

  setCampaignFromStorage() {
    let stored = localStorage.getItem(AppConfigs.selectedCampaignStorageKey);
    if (stored) {
      let camp: Campaign = JSON.parse(stored);
      let foundCamp = this.cachedCampaigns.find(c => c.campaignId == camp.campaignId);
      if (foundCamp) {
        this.selectedCampaign.next(camp);
      }
      else {
        this.selectedCampaign.next(null);  
      }
    }
    else {
      this.selectedCampaign.next(null);
    }
  }

  getBasicFields() {
    return this.apiService.get(`campaign/get-basic-fields/${this.customerGuid}/${this.selectedCampaign.getValue()?.campaignGuid}`);
  }

  getVersionTags() {
    return this.apiService.get(`campaign/get-custom-labels/${this.customerGuid}/${this.selectedCampaign.getValue()?.campaignGuid}`);
  }

  getCustomTags() {
    return this.apiService.get(`campaign/get-custom-tags/${this.customerGuid}/${this.selectedCampaign.getValue()?.campaignGuid}`);
  }

  getSeeds() {
    return this.apiService.get(`campaign/get-seeds/${this.customerGuid}/${this.selectedCampaign.getValue()?.campaignGuid}`);
  }

  getNotifications() {
    return this.apiService.get(`campaign/notifications-by-campaign/${this.customerGuid}/${this.selectedCampaign.getValue()?.campaignGuid}`);
  }

  getAlertByGuid(alertGuid: string) {
    return this.apiService.get(`alert/get-alert/${this.customerGuid}/${alertGuid}`);
  }
}
