import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PgDataHrefFormGroup } from 'src/app/models/form-groups';
import { NameValuePair } from 'src/app/models/name-value-pair';
import { PageDataHref } from 'src/app/models/page-data';

@Component({
  selector: 'app-page-data-href',
  templateUrl: './page-data-href.component.html',
  styleUrls: ['./page-data-href.component.scss']
})
export class PageDataHrefComponent implements OnInit {

  @Input() data: PageDataHref | null | undefined = null;
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<any>();

  hrefForm: FormGroup<PgDataHrefFormGroup> = {} as FormGroup;
  hrefFormLoaded: boolean = false;
  emptyDataMsg: boolean = false;

  targets: NameValuePair[] = [
    { name: '_self', value: '_self'}, 
    { name: '_blank', value: '_blank'}, 
    { name: '_parent', value: '_parent'}, 
    { name: '_search', value: '_search'}, 
    { name: '_top', value: '_top'}, 
  ];

  constructor() { }

  ngOnInit(): void {
    if (!this.data) {
      this.emptyDataMsg = true;
      return;
    }
    this.initForm();
    this.emptyDataMsg = false;
    this.hrefFormLoaded = true;
  }

  initForm() {
    this.hrefForm = new FormGroup<PgDataHrefFormGroup>({
      name: new FormControl<string>(this.data?.alias ?? '', { nonNullable: true }),
      objectId: new FormControl<string>(this.data?.name ?? '', { nonNullable: true, validators: [Validators.required] }),
      version: new FormControl<number | null>(this.data?.versionId ?? null, { nonNullable: true }),
      text: new FormControl<string>(this.data?.text ?? '', { nonNullable: true }),
      css: new FormControl<string>(this.data?.class ?? '', { nonNullable: true }),   
      href: new FormControl<string>(this.data?.hrf ?? '', { nonNullable: true }),   
      target: new FormControl<string>(this.data?.tgt ?? '', { nonNullable: true }),   
      client: new FormControl<string>(this.data?.onclick ?? '', { nonNullable: true }),   
      required: new FormControl<boolean>(this.data?.req == "1", { nonNullable: true })
    });    
  }

  saveHrefForm() {
    if (this.data) {
      this.data.alias = this.hrefForm.value.name ?? this.data.alias;
      this.data.name = this.hrefForm.value.objectId ?? this.data.name;
      this.data.versionId = this.hrefForm.value.version ?? this.data.versionId;
      this.data.text = this.hrefForm.value.text ?? this.data.text;
      this.data.hrf = this.hrefForm.value.href ?? this.data.hrf;
      this.data.tgt = this.hrefForm.value.target ?? this.data.tgt;
      this.data.onclick = this.hrefForm.value.client ?? this.data.onclick;      
      this.data.class = this.hrefForm.value.css ?? this.data.class;
      this.data.req = this.hrefForm.value.required ? '1' : '0';
    }
    let saveObj = {
      element: 'href',
      data: this.data
    };

    this.onSave.emit(saveObj);
  }

  cancelForm() {
    this.onCancel.emit();
  }
}
