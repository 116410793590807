<div class="card">
    <div class="card-body">
        <form [formGroup]="otherInfoForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-12">
                    <app-cc-toggle-input
                    controlName="saveOnSingle"
                    [group]="otherInfoForm"
                    label="Save Loan Details On Single Element"></app-cc-toggle-input>
                </div>
                <div class="col-md-12">
                    <app-cc-toggle-input
                    controlName="processStatement"
                    [group]="otherInfoForm"
                    label="Process Payment Statement"></app-cc-toggle-input>
                </div>
                <div class="col-md-12">
                    <app-cc-toggle-input
                    controlName="processStatement"
                    [group]="otherInfoForm"
                    label="Allow More Than 1 Payment Per Day"></app-cc-toggle-input>
                </div>
            </div>
            <h3 class="mb-3">Clarity</h3>
            <div class="d-flex">
                <div class="p-2 flex-fill">
                    <app-cc-input
                    controlName="clarityFilename"
                    [group]="otherInfoForm"
                    label="File Name">
                </app-cc-input>
                </div>
                <div class="p-2 flex-fill">
                    <app-cc-input
                    controlName="clarityVersionNum"
                    [group]="otherInfoForm"
                    label="File Version Number">
                </app-cc-input>
                </div>
                <div class="p-2 flex-fill">
                    <app-cc-input
                    controlName="clarityLocId"
                    [group]="otherInfoForm"
                    label="Location ID">
                </app-cc-input>
                </div>
                <div class="p-2 flex-fill">
                    <app-cc-input
                    controlName="clarityPurposeType"
                    [group]="otherInfoForm"
                    label="Inquiry Purpose Type">
                </app-cc-input>
                </div>
                <div class="p-2 flex-fill">
                    <app-cc-input
                    controlName="clarityTradelineType"
                    [group]="otherInfoForm"
                    label="Inquiry Tradeline Type">
                </app-cc-input>
                </div>
              </div>
            <h3 class="mb-3">Verifications Queue</h3>
            <div class="row">
                <div class="col-md-12">
                    <app-cc-toggle-input
                    controlName="verificationAutoAssign"
                    [group]="otherInfoForm"
                    label="Auto Assign Apps"></app-cc-toggle-input>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-4">
                    <app-cc-input
                    controlName="verificationMaxApps"
                    [group]="otherInfoForm"
                    label="Max Apps Per Rep">
                </app-cc-input>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-4">
                    <app-cc-input
                    controlName="verificationMinWait"
                    [group]="otherInfoForm"
                    label="Min Wait Reset App">
                </app-cc-input>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-4">
                    <app-cc-input
                    controlName="verificationInactivity"
                    [group]="otherInfoForm"
                    label="Rep Inactivity App">
                </app-cc-input>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <div class="p-2">
                    <button class="btn btn-primary btn-main" type="submit">Update</button>
                </div>
            </div>
        </form>
    </div>
</div>