<div>
    Page Background Color: &quot;background-color&quot;
</div>
<div class="grid">
    <div class="col-6 align-self-center text-center">
        <h3>Header CSS</h3>
    </div>
    <div class="col-6">
        <ul>
            <li>Background color: &quot;header-bg&quot;</li>
            <li>Text color: &quot;header-primary&quot;</li>
        </ul>
        Menus:
        <ul>
            <li>Background color: &quot;header-bg-neutral&quot;</li>
            <li>Background color on hover: &quot;header-bg&quot;</li>
            <li>Text color: &quot;header-primary&quot;</li>
        </ul>
    </div>
</div>

<div class="grid">
    <div class="col-6 align-self-center text-center">
        <h3>Left Nav CSS</h3>
    </div>
    <div class="col-6">
        <ul>
            <li>Background color: &quot;left-nav-bg&quot;</li>
            <li>Text/Icon color: &quot;left-nav-primary&quot;</li>
            <li>Text/Icon color on hover: &quot;left-nav-primary-hover&quot;</li>
        </ul>
    </div>
</div>

<div class="grid">
    <div class="col-6">
        <div class="text-2xl">Loading Example...</div>
        <p3solved-progress-bar [style]="{'height': '0.5rem'}"></p3solved-progress-bar>
    </div>
    <div class="col-6">
        Progress Bar:
        <ul>
            <li>Text color: &quot;text-color&quot;</li>
            <li>Progress Bar background: &quot;component-highlight-bg&quot;</li>
            <li>Progress Bar color: &quot;primary-color&quot;</li>
        </ul>
    </div>
</div>

<div class="grid">
    <div class="col-6 pt-4 text-5xl font-medium text-red-500">Error Heading Example</div>

    <div class="col-6">
        Page Error:
        <ul>
            <li>Text color: &quot;red-500&quot;</li>
        </ul>
    </div>
</div>

<div class="pt-4 grid">
    <div class="col-6">
        <span class="text-2xl font-medium">Standard Page Title</span>
    </div>
    <div class="col-6">
        Page Titles:
        <ul>
            <li>Text color: &quot;text-color&quot;</li>
        </ul>
    </div>
    <div class="col-6">
        <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    </div>
    <div class="col-6">
        Breadcrumb Menu:
        <ul>
            <li>Text color (link): &quot;primary-color&quot;</li>
            <li>Text color (last item): &quot;text-color-secondary&quot;</li>
            <li>Divider color (&gt;): &quot;text-color-secondary&quot;</li>
        </ul>
    </div>

    <div class="col-6">
        <div class="mt-4">
            <div class="cds-card p-4">
                <div class="grid">
                    <div class="col-12">
                        <p3solved-table [data]="testData" [globalFilterFields]="filterFields" [columns]="columns"
                            [rows]="100" [rowHover]="true" [allowRowSelect]="false" (editRow)="editRecord($event)"
                            (deleteRow)="confirmDelete($event)">
                        </p3solved-table>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <div class="col-6">
        Tables:
        <ul>
            <li>Text/Icon color: &quot;text-color&quot;</li>
            <li>Background: &quot;component-bg&quot;</li>
            <li>Background (hover): &quot;component-highlight-bg&quot;</li>
        </ul>
        Download button:
        <ul>
            <li>Text/Border color: &quot;primary-color&quot;</li>
            <li>Background: transparent (no color)</li>
            <li>Border radius: &quot;border-radius&quot;</li>
            <li>Background (hover/active): &quot;btn-outline-hover-rgb&quot;<sup>1</sup></li>
        </ul>
        Search box:
        <ul>
            <li>Text/Icon color: &quot;text-color&quot;</li>
            <li>Border (hover): &quot;text-color-secondary&quot;</li>
        </ul>
    </div>
</div>

<div class="pt-4">
    <div class="mt-4">
        <div class="cds-card p-4">
            <div>
                Cards:
                <ul>
                    <li>Background color: &quot;card-background&quot;</li>
                    <li>Shadow color: &quot;card-shadow-rgb&quot;<sup>1</sup></li>
                    <li>Border radius: &quot;card-border-radius&quot;</li>
                </ul>
            </div>
            <form [formGroup]="cssForm" (ngSubmit)="saveEmployee()">
                <div class="grid">
                    <div class="col-6">
                        <p3solved-form-text id="txtFN" placeholder="First Name" formControlName="firstName"
                            [required]="true">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!cssForm.controls.firstName.pristine && cssForm.controls.firstName.errors">
                                <p *ngIf="cssForm.controls.firstName.errors?.required">First Name is required</p>
                            </div>
                        </p3solved-form-text>
                        <p3solved-form-text id="txtLN" placeholder="Last Name" formControlName="lastName"
                            [required]="true">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!cssForm.controls.lastName.pristine && cssForm.controls.lastName.errors">
                                <p *ngIf="cssForm.controls.lastName.errors?.required">Last Name is required</p>
                            </div>
                        </p3solved-form-text>
                    </div>
                    <div class="col-6">
                        Text field:
                        <ul>
                            <li>Background color: &quot;component-bg&quot;</li>
                            <li>Border: &quot;component-border&quot;</li>
                            <li>Border (focused): &quot;primary-color-hover&quot;</li>
                            <li>Border (required with error): &quot;border-error&quot;</li>
                            <li>Red asterik (*): &quot;text-error&quot;</li>
                            <li>Error text: &quot;border-error&quot;</li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <p3solved-dropdown id="ddCity" placeholder="City" [items]="cities" optionLabel="cityName"
                            [required]="true" optionValue="cityName" formControlName="city"></p3solved-dropdown>
                        <p3solved-autocomplete id="acjob" placeholder="Job" emptyMessage="No jobs..." [required]="true"
                            [showEmptyMessage]="true" [suggestions]="jobSuggs" [completeMethod]="searchJobs"
                            optionLabel="jobName" [forceSelection]="true" formControlName="job">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!cssForm.controls.job.pristine && cssForm.controls.job.errors">
                                <p *ngIf="cssForm.controls.job.errors?.required">Job Title is required</p>
                            </div>
                        </p3solved-autocomplete>
                    </div>
                    <div class="col-6">
                        DropDown/Autocomplete Menus:
                        <ul>
                            <li>Menu background: &quot;component-bg&quot;</li>
                            <li>Menu hover background: &quot;component-highlight-bg&quot;</li>
                        </ul>
                    </div>
                    <div class="col-12">
                        <p3solved-form-textarea id="txtArea" placeholder="Text Area Component" [rows]="5">
                        </p3solved-form-textarea>
                    </div>
                    <div class="col-6">
                        <p3solved-button id="btnSave" class="pr-4" buttonType="submit" [disabled]="!cssForm.valid"><span
                                class="px-4">Save</span>
                        </p3solved-button>
                        <p3solved-button id="btnRst" class="outline" buttonType="reset"><span class="px-4">Cancel</span>
                        </p3solved-button>
                    </div>
                    <div class="col-6">
                        Primary button (Save):
                        <ul>
                            <li>Background/border: &quot;primary-color&quot;</li>
                            <li>Background/border (hover): &quot;primary-color-hover&quot;</li>
                            <li>Background/border (active): &quot;control-active-hover&quot;</li>
                            <li>Text color: &quot;text-color-inverse&quot;</li>
                        </ul>
                        Seconary button (Cancel):
                        <ul>
                            <li>Save as Table 'Download button' above</li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="pt-4">
    <div class="mt-4">
        <div class="cds-card p-4">
            Miscellaneous Controls
            <div class="grid">
                <div class="col-6 pt-4">
                    <p3solved-datepicker id="dtTest" placeholder="Select Date">
                    </p3solved-datepicker>
                </div>
                <div class="col-6 pt-4">
                    Date picker:
                    <ul>
                        <li>Header/hover: &quot;component-highlight-bg&quot;</li>
                    </ul>
                </div>
                <div class="col-6 pt-4">
                    <p3solved-input-number id="txtNum" placeholder="Number Input Only" mode="decimal">
                    </p3solved-input-number>
                </div>
                <div class="col-6 pt-4">
                    Number input: <i>Same as text input</i>
                </div>
                <div class="col-6 pt-4">
                    <p-checkbox [binary]="true" label="Checkbox">
                    </p-checkbox>
                </div>
                <div class="col-3 pt-4">
                    Checkbox (unchecked):
                    <ul>
                        <li>Border: &quot;component-border&quot;</li>
                        <li>Background: &quot;component-bg&quot;</li>
                        <li>Border radius: &quot;border-radius&quot;</li>
                        <li>Border (hover): &quot;text-color-secondary&quot;</li>
                    </ul>
                </div>
                <div class="col-3 pt-4">
                    Checkbox (checked):
                    <ul>
                        <li>Border: &quot;primary-color&quot;</li>
                        <li>Background: &quot;primary-color&quot;</li>
                        <li>Check mark color: &quot;text-color-inverse&quot;</li>
                        <li><i>Note: hover behaves like Primary button</i></li>
                    </ul>
                </div>
                <div class="col-6 pt-4">
                    <span class="font-bold text-black-alpha-90 mr-5">Radion Button List</span>
                    <p-radioButton name="rblTest" [value]="true" label="Yes" [(ngModel)]="rblTest" class="mr-7">
                    </p-radioButton>
                    <p-radioButton name="rblTest" [value]="false" label="No" [(ngModel)]="rblTest" class="mr-7">
                    </p-radioButton>
                </div>
                <div class="col-6 pt-4">
                    Radio button/button list:
                    <ul>
                        <li>Selected fill color: &quot;primary-color&quot;</li>
                    </ul>
                </div>
                <div class="col-6 pt-4">
                    Select Button Example:
                    <p-selectButton [options]="toggleOptions" optionLabel="label" optionValue="value"
                        [(ngModel)]="selBtnTest"></p-selectButton>
                </div>
                <div class="col-6 pt-4">
                    Select Button:
                    <ul>
                        <li><i>Selected uses same classes as Primary button</i></li>
                        <li><i>Un-Selected uses same classes as Secondary button</i></li>
                    </ul>
                </div>
                <div class="col-6 pt-4">
                    Input Switch Example
                    <p-inputSwitch [(ngModel)]="inputSwTest"></p-inputSwitch>
                </div>
                <div class="col-2 pt-4">
                    Slider:
                    <ul>
                        <li>Background color: &quot;component-bg&quot;</li>
                    </ul>
                </div>
                <div class="col-2 pt-4">
                    Not Selected "Off":
                    <ul>
                        <li>Background color: &quot;switch-off&quot;</li>
                    </ul>
                </div>
                <div class="col-2 pt-4">
                    Selected "On":
                    <ul>
                        <li>Background color: &quot;primary-color&quot;</li>
                    </ul>
                </div>
                <div class="col-12 pt-4">
                    <span class="text-2xl">Badges</span>
                    <p>
                        Success: <p-badge severity="success" value="10"></p-badge>
                        <span class="pl-2"><i>background: &quot;badge-success-bg&quot; text:
                                &quot;text-color-inverse&quot;</i></span>
                    </p>
                    <p>
                        Info: <p-badge severity="info" value="10"></p-badge>
                        <span class="pl-2"><i>background: &quot;badge-info-bg&quot; text:
                                &quot;text-color-inverse&quot;</i></span>
                    </p>
                    <p>
                        Warning: <p-badge severity="warning" value="10"></p-badge>
                        <span class="pl-2"><i>background: &quot;badge-warn-bg&quot; text:
                                &quot;text-color&quot;</i></span>
                    </p>
                    <p>
                        Danger: <p-badge severity="danger" value="10"></p-badge>
                        <span class="pl-2"><i>background: &quot;badge-danger-bg&quot; text:
                                &quot;text-color-inverse&quot;</i></span>
                    </p>
                </div>
                <div class="col-12 pt-4">
                    <p>
                        <i>Text button: Same as Secondary button (without border)</i>
                        <p3solved-button class="text" label="Text Button Example" (buttonClick)="textBtnClicked()">
                        </p3solved-button>
                    </p>
                    <p>
                        <i>Hyperlink: &quot;primary-color&quot; and &quot;primary-color-hover&quot;</i>
                        <span class="pl-4 cds-text-color cursor-pointer" (click)="hyperLnkClicked()">This is a clickable
                            link</span>
                    </p>
                </div>
                <div class="col-12">
                    Toast Messages (click to display): 
                    <p>
                        <span class="cds-text-color cursor-pointer" (click)="showSuccess()">Success: </span><i>Border: &quot;toast-success-border-color&quot;   Background: &quot;toast-success-bg&quot;   Text: &quot;toast-success-text&quot;</i>
                    </p>
                    <p>
                        <span class="cds-text-color cursor-pointer" (click)="showInfo()">Info: </span><i>Border: &quot;toast-info-border-color&quot;   Background: &quot;toast-info-bg&quot;   Text: &quot;toast-info-text&quot;</i>
                    </p>
                    <p>
                        <span class="cds-text-color cursor-pointer" (click)="showWarn()">Warn: </span><i>Border: &quot;toast-warn-border-color&quot;   Background: &quot;toast-warn-bg&quot;   Text: &quot;toast-warn-text&quot;</i>
                    </p>
                    <p>
                        <span class="cds-text-color cursor-pointer" (click)="showError()">Error: </span><i>Border: &quot;toast-error-border-color&quot;   Background: &quot;toast-error-bg&quot;   Text: &quot;toast-error-text&quot;</i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>



<p-confirmDialog key="CSS-Test-Confirm-Key" [style]="{width: '45vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>