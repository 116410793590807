import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { PortfolioItem } from 'src/app/models/customer-item';
import { IMenuLink } from 'src/app/models/imenu-link';
import { LookupModel } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { NavService, NavSettings } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss']
})
export class LeftNavComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  menus: IMenuLink[] = [];
  adminRoles: LookupModel[] = [];

  sideMenuOpen: boolean = false;
  sideMenuPinned: boolean = false;
  showText: boolean = false;
  portfolio: PortfolioItem | undefined;
  customerSettings: any | undefined;
  navSelectionSub: Subscription | null = null;

  private readonly navSvcId: string = '';
  constructor(
    private router: Router,
    private portfolioService: PortfolioService,
    private userService: UserService,
    private apiService: ApiService,
    private navService: NavService
  ) {
    this.setMenus();
  }

  ngOnInit(): void {
    this.getUserMenus();
    this.subscriptions.push(
      combineLatest([ this.portfolioService.customerSettings$, this.userService.userAdminPages$])
        .subscribe({
          next: ([c, p]) => {
            this.customerSettings = c;
            if (c && p && p.length > 0) {
              this.setMenus();
            }
          }
        }),
      this.navService.leftNaveSelection$
        .subscribe((navSettings: NavSettings) => {
          if (navSettings.referrer != this.navSvcId) {
            if (navSettings.navId) {
              let menu = this.findMenuById(navSettings.navId);
              if (menu.parent && menu.item) {
                this.childSelected(menu.item, menu.parent, false, navSettings.navigate);
              }
              else if (menu.item) {
                this.menuSelected(menu.item, false, false, navSettings.navigate);
              }
            }
            else {
              this.clearSelections(this.menus);
            }
          }
        })
    );

    let sideMenPin = localStorage.getItem('sideMenuPin');
    if (sideMenPin) this.sideMenuPinned = sideMenPin == '1';
    this.toggleSideMenu(this.sideMenuPinned ? 'open' : 'close');

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); });
    localStorage.removeItem('sideMenuPin');
    localStorage.removeItem('navId');
  }

  toggleSideMenuPin() {
    this.sideMenuPinned = !this.sideMenuPinned;
    localStorage.setItem('sideMenuPin', this.sideMenuPinned ? '1' : '0');
  }

  setMenus() {
    this.menus = [
      // {
      //   show: true, pageId: null, id: 'Client', selected: false, title: 'Provider', url: ``, icon: 'circle-user-regular', children: [
      //     { show: true, pageId: null, id: 'Client.Dashboard', selected: false, title: 'Dashboard', url: `/provider/dashboard`, icon: 'circle-dot', children: [] },
      //     { show: true,  pageId: null,id: 'Client.List', selected: false, title: 'List', url: '/provider/list', icon: 'circle-dot', children: [] },
      //     { show: true,  pageId: null,id: 'Client.Edit', selected: false, title: 'Add', url: '/provider/add', icon: 'circle-dot', children: [] },
      //     { show: true,  pageId: null,id: 'Client.OfferLookup', selected: false, title: 'Offer Lookup', url: '/provider/offer-lookup', icon: 'circle-dot', children: [] },
      //     { show: true,  pageId: null,id: 'Client.Pricing', selected: false, title: 'Pricing', url: '/provider/pricing', icon: 'circle-dot', children: [] },
      //     { show: true,  pageId: null,id: 'Client.Content', selected: false, title: 'Content', url: '/provider/content', icon: 'circle-dot', children: [] },
      //   ]
      // },
      {
        show: this.customerSettings?.ShowOriginator, pageId: null, id: 'Originator', selected: false, title: 'Originator', url: ``, icon: 'signs-post', children: [
          { show: this.customerSettings?.ShowOriginatorDashboard, pageId: null, id: 'Originator.Dashboard', selected: false, title: 'Dashboard', url: `/originator/dashboard`, icon: 'circle-dot', children: [] },
          { show: this.customerSettings?.ShowOriginatorList, pageId: null, id: 'Originator.List', selected: false, title: 'List', url: `/originator/list`, icon: 'circle-dot', children: [] },
          { show: this.customerSettings?.ShowOriginatorEdit, pageId: null, id: 'Originator.Edit', selected: false, title: 'Add', url: `/originator/add`, icon: 'circle-dot', children: [] },
        ]
      }
    ];

    if (this.hasTabAccess('Campaign')) {
      this.menus.push({ show: true, pageId: 5, id: 'Campaign', selected: false, title: 'Campaign', url: `/campaign`, icon: 'bullhorn', children: [] });      
    }
    if (this.hasTabAccess('DecisionEngine')) {
      this.menus.push({
        show: true, pageId: null, id: 'DecisionEngine', selected: false, title: 'Decision Engine', url: ``, icon: 'sliders', children: [
          this.getMenuItem(15, 'DecisionEngine.Dashboard', 'Dashboard', `/decision/dashboard`),
          this.getMenuItem(16, 'DecisionEngine.RuleSets', 'RuleSets', `/decision/rulesets`),
          this.getMenuItem(17, 'DecisionEngine.Rules', 'Rules', `/decision/rules`),
          this.getMenuItem(18, 'DecisionEngine.Reasons', 'Reasons', `/decision/reasons`),
          this.getMenuItem(19, 'DecisionEngine.LineMatrix', 'Line Matrixes', `/decision/line-matrixes`),
          this.getMenuItem(null, 'DecisionEngine.OfferMatrix', 'Offer Matrixes', `/decision/offer-matrixes`),
          this.getMenuItem(20, 'DecisionEngine.BlackLists', 'BlackLists', `/decision/black-list`),
          this.getMenuItem(35, 'DecisionEngine.CustomFields', 'Custom Fields', `/decision/custom-fields`)
        ]
      });      
    }
    if (this.hasTabAccess('Report')) {
      this.menus.push({ show: true, pageId: 6, id: 'Report', selected: false, title: 'Report', url: `/report`, icon: 'chart-bar', children: [] });      
    }
    if (this.hasTabAccess('Compliance')) {
      this.menus.push({
        show: true, pageId: null, id: 'Compliance', selected: false, title: 'Compliance & Audit', url: ``, icon: 'award', children: [
          this.getMenuItem(7, 'Compliance.Dashboard', 'Dashboard', `/compliance/dashboard`),
          this.getMenuItem(31, 'Compliance.Payments', 'Upcoming Payments', `/compliance/payments`),
          this.getMenuItem(32, 'Compliance.Statements', 'Upcoming Statements', `/compliance/statements`),
        ]
      });      
    }
    if (this.hasTabAccess('User')) {
      this.menus.push({
        show: true, pageId: null, id: 'User', selected: false, title: 'Users', url: ``, icon: 'users', children: [
          { show: true, pageId: 12, id: 'User.Dashboard', selected: false, title: 'Dashboard', url: `/users/dashboard`, icon: 'circle-dot', children: [] },
          this.getMenuItem(3, 'User.Roles', 'Roles', `/users/roles`)
        ]
      });      
    }
    if (this.hasTabAccess('System')) {
      this.menus.push({ show: true, pageId: null, id: 'System', selected: false, title: 'System', url: `/system`, icon: 'construction', children: [] });      
    }
    // if (this.hasTabAccess('PartnerIntegration')) {
    //   this.menus.push({
    //     show: true, pageId: null, id: 'PartnerIntegration', selected: false, title: 'Partner Integration', url: ``, icon: 'handshake', children: [
    //       this.getMenuItem(39, 'PartnerIntegration.Dashboard', 'Dashboard', `/partner/dashboard`),
    //       this.getMenuItem(40, 'PartnerIntegration.Instructions', 'Instructions', `/partner/instructions`),
    //       this.getMenuItem(41, 'PartnerIntegration.LeadTesting', 'Lead Testing', `/partner/lead`),
    //       this.getMenuItem(42, 'PartnerIntegration.Docs', 'Docs', `/partner/docs`),
    //       this.getMenuItem(43, 'PartnerIntegration.FAQ', 'FAQ', `/partner/faq`)
    //     ]
    //   });      
    // }
    
    let savedNavId = localStorage.getItem('navId');
    if (savedNavId) this.selectNavItem(savedNavId);

  }

  selectNavItem(navId: string) {
    for (let i = 0; i < this.menus.length; i++) {
      let item = this.menus[i];
      item.selected = item.id.indexOf(navId) >= 0;
      if (item.children.length > 0) {
        for (let j = 0; j < item.children.length; j++) {
          let child = item.children[j];
          if (child.id === navId) {
            item.selected = true;
            child.selected = true;
          }
        }
      }
    }
  }

  hasTabAccess(tabName: string): boolean {
    return this.userService.hasTabAccess(tabName);
  }

  getMenuItem(pageId: number|null, id: string, title: any, url: any, icon = 'circle-dot') {
    return {
      show: pageId == null ? true : this.userService.hasPageAccess(pageId),
      id: id,
      selected: false,
      title: title,
      url: url,
      icon: icon,
      pageId: pageId,
      children: []
    }
  }

  menuSelected(menu: IMenuLink, notifyService: boolean = true, toggleIfParent: boolean = false, navigateTo: boolean = true) {
    if (notifyService) this.navService.setLeftNavSelection(menu.id, this.navSvcId);
    if (toggleIfParent) {
      let currItem = this.menus.find(m => m.id === menu.id);
      if (currItem && currItem.children.length > 0) {
        currItem.selected = !currItem.selected;        
        this.menus.filter(m => m.id != menu.id).forEach(item => { item.selected = false; });
      }
      else if (currItem && currItem.children.length == 0) {
        this.clearSelections(this.menus);
        this.gotoMenu(currItem, navigateTo);
      }
    }
    else {
      this.clearSelections(this.menus);
      this.gotoMenu(menu, navigateTo);
    }
  }

  childSelected(menu: IMenuLink, parentMenu: IMenuLink, notifyService: boolean = true, navigateTo: boolean = true) {
    if (notifyService) this.navService.setLeftNavSelection(menu.id, this.navSvcId);
    this.menuSelected(parentMenu, true, false);
    this.gotoMenu(menu, navigateTo);
  }

  gotoMenu(menu: IMenuLink, navigateTo: boolean = true) {
    if (!menu.children.length) {
      localStorage.setItem('navId', menu.id);
      this.selectNavItem(menu.id);
      if (navigateTo) this.router.navigate([menu.url]);
    }
  }

  clearSelections(menus: IMenuLink[]) {
    menus.forEach((menu) => {
      menu.selected = false;
      if (menu.children.length) {
        this.clearSelections(menu.children);
      }
    })
  }

  toggleSideMenu(action?: string) {
    if (this.sideMenuPinned) {
      this.sideMenuOpen = true;
      // setTimeout(() => {
      this.showText = true;
      // }, 300);
    }
    else {
      if (action) {
        this.sideMenuOpen = action == 'open';
        // setTimeout(() => {
        this.showText = action == 'open';
        // }, 300);
      }
      else {
        this.sideMenuOpen = !this.sideMenuOpen;
        // setTimeout(() => {
        this.showText = !this.showText;
        // }, 300);
      }
    }
    this.navService.setLeftNavExpanded(this.sideMenuOpen);
  }

  getUserMenus() {
    this.apiService.get(`user/adminsite-tabs`)
      .subscribe((res: any) => {
        this.adminRoles = res || [];
        this.setShowMenus(this.menus);
      },
        (err: any) => {
          console.error(err);
        }
      );
  }

  setShowMenus(menus: IMenuLink[]) {
    menus.forEach(menu => {
      var id = menu.id.trim().toLocaleUpperCase();
      var index = this.adminRoles.findIndex(x => x.desc.trim().toLocaleUpperCase() === id);
      menu.show = index > -1 ? true : false;
      if (menu.children.length)
        this.setShowMenus(menu.children);
    })
  }

  getMenuInRoles() {
    return this.menus.filter(x => x.show);
  }

  findMenuById(id: string): MenuSearchResult {
    let menu: MenuSearchResult = {
      item: null,
      parent: null,
    }
    outerloop: for (let i = 0; i < this.menus.length; i++) {
      let item = this.menus[i];
      if (item.id === id) {
        menu.item = item;
        break;
      }
      else if (item.children.length > 0) {
        for (let j = 0; j < item.children.length; j++) {
          let child = item.children[j];
          if (child.id === id) {
            menu.parent = item;
            menu.item = child;
            break outerloop;
          }
        }
      }
    }
    return menu;
  }

}

export class MenuSearchResult {
  item: IMenuLink | null = null;
  parent: IMenuLink | null = null;
}
