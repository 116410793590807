import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-loan-app',
  templateUrl: './edit-loan-app.component.html',
  styleUrls: ['./edit-loan-app.component.scss']
})
export class EditLoanAppComponent implements OnInit {
 loanAppForm = this.fb.group({
    nlsLoanPortfolioName: ['', Validators.required],
    loanTemplatePrefix: ['', Validators.required],
    loanNumPrefix: ['', Validators.required],
    loanAmount: ['', Validators.required],
    campainName: ['', Validators.required],
    domain: ['', Validators.required],
    customFolder: ['', Validators.required],
    startUrl: [''],
    campaignFolder: [''],
    accountDomain: [''],
    invitationUrl: [''],
    invitationDomain: [''],
    accountDefaultPage: [''],
    adminDomain: [''],
    logoutTimeout: ['']
  });
  constructor(private fb: UntypedFormBuilder) {

   }

  ngOnInit(): void {
  }

  onSubmit(){
    console.warn(this.loanAppForm.value);
  }
}
