<div class="card">
    <div class="card-body">
        <form [formGroup]="settlementForm" (ngSubmit)="onSubmit()">
            <h3 class="mb-3">Funding</h3>
            <app-cc-select 
                controlName="facility"
                [group]="settlementForm"
                [options]="facilities"
                label="Facility">
            </app-cc-select>
            <h3 class="mb-3">Bank Details</h3>
            <div class="row">
                <div class="col-sm-12">
                    <app-cc-input
                        controlName="bankName"
                        [group]="settlementForm"
                        label="Bank Name">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-input
                        controlName="bankAccount"
                        [group]="settlementForm"
                        label="Bank Account Number">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <app-cc-input
                        controlName="bankRouting"
                        [group]="settlementForm"
                        label="Bank Routing Number">
                    </app-cc-input>
                </div>
                <div class="col-sm-12">
                    <app-cc-input
                        controlName="wireDetails"
                        [group]="settlementForm"
                        label="Wire Instructions">
                    </app-cc-input>
                </div>
            </div>
            <h3 class="mb-3">Contact Details</h3>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="contactName"
                        [group]="settlementForm"
                        label="Contact Name">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="contactPhone"
                        [group]="settlementForm"
                        label="Contact Phone">
                    </app-cc-input>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <app-cc-input
                        controlName="contactEmail"
                        [group]="settlementForm"
                        label="Contact Email">
                    </app-cc-input>
                </div>
            </div>
            <h3 class="mb-3">Lock Box</h3>
            <app-cc-input
                controlName="fullAddrress"
                [group]="settlementForm"
                label="Full Address">
            </app-cc-input>
            <button class="btn btn-primary btn-main btn-large" type="submit">Update Settlement</button>
        </form>
    </div>
</div>
