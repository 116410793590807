<div>
    <span class="text-2xl font-medium">Edit Line Matrixes</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div *ngIf="loading">
        <app-cc-loading loadingText="Taking a trip to the server..."></app-cc-loading>
    </div>
    <div *ngIf="isReady">
        <form [formGroup]="matrixForm" class="mb-4">
            <div class="mt-4">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="m-2">
                            <p3solved-form-text id="" placeholder="Name" formControlName="name" [required]="true">
                            </p3solved-form-text>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="m-2">
                            <p3solved-form-text id="" placeholder="Description" formControlName="desc" [required]="true">
                            </p3solved-form-text>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="my-4">
                            <app-de-expression-setup title="Vertical Axis" name="vertical"
                                (selectedField)="selectedField($event, 'vertical')"
                                (selectedDS)="selectedDs($event, 'vertical')"
                                [jsonModel]="lineMatrix?.linematrix?.va"></app-de-expression-setup>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="my-3">
                            <app-de-expression-setup title="Horizontal Axis" name="horizontal"
                                (selectedField)="selectedField($event, 'horizontal')"
                                (selectedDS)="selectedDs($event, 'horizontal')"
                                [jsonModel]="lineMatrix?.linematrix?.ha"></app-de-expression-setup>
                        </div>

                    </div>
                </div>
            </div>

            <div class="d-flex mb-3">
                <p3solved-button id="btnbtn" label="Build Matrix" (buttonClick)="buildMatrix()">
                </p3solved-button>
            </div>
        </form>
        <div *ngIf="showLineAssignment" class="mb-4">
            <div class="d-flex">
                <div class="left-matrix-header flex-fill d-flex">
                    <div class="align-self-center d-flex flex-column">
                        <p class="p-1"><strong>{{datasource.vertical}}</strong></p>
                        <p class="p-1"><strong>{{field.vertical}}</strong></p>
                    </div>
                </div>
                <div class="flex-fill">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="table-secondary">
                                <th colspan="100%" class="text-center">
                                    <p class="m-0">
                                        <strong class="text-center p-2">{{datasource.horizontal}}</strong>
                                        <strong class="text-center p-2">{{field.horizontal}}</strong>
                                    </p>
                                    
                                </th>
                            </tr>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col" *ngFor="let h of columns.horizontal">
                                    {{h.min}}-{{h.max}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let v of columns.vertical; let iv = index">
                                <th scope="row">{{v.min}}-{{v.max}}</th>
                                <td *ngFor="let h of columns.horizontal; let ih = index">
                                    <div class="input-group mb-1">
                                        <span class="input-group-text" id="basic-addon1">$</span>
                                        <input type="text" appNumericOnly decimals="6" class="form-control input-sm"
                                            [ngClass]="errorColumns && errorColumns['column' + iv + ih] ? 'is-invalid' : 'is-valid'"
                                            placeholder="0.00" [value]="getValue(iv, ih)"
                                            (change)="inputChanged($event, iv, ih)">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

        <div class="d-flex mb-3">
            <div class="m-3">
                <p3solved-button id="btnbtn" label="Save" (buttonClick)="saveMatrix()">
                </p3solved-button>
            </div>
            <div class="m-3">
                <p3solved-button id="btnbtn" label="Cancel" (buttonClick)="cancel()">
                </p3solved-button>
            </div>
        </div>
    </div>

</div>