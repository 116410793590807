import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-cc-date-picker',
  templateUrl: './cc-date-picker.component.html',
  styleUrls: ['./cc-date-picker.component.scss']
})
export class CcDatePickerComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() id?: string;
  @Input() label: string = "";
  @Input() val: string = "";
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  iconRequired = faAsterisk as IconProp;
  constructor() { }

  ngOnInit(): void {
  }

}
