import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { LookupModel } from 'src/app/models/lookup-model';
import { LookupService } from 'src/app/services/lookup.service';
import { ProviderService } from 'src/app/services/provider.service';

@Component({
  selector: 'app-edit-settlement-detail',
  templateUrl: './edit-settlement-detail.component.html',
  styleUrls: ['./edit-settlement-detail.component.scss']
})
export class EditSettlementDetailComponent implements OnInit {
  facilities: LookupModel[] = [];
  settlementForm = this.fb.group({
    facility: ['', Validators.required],
    bankName: ['', Validators.required],
    bankAccount: ['', Validators.required],
    bankRouting: ['', Validators.required],
    wireDetails: ['', Validators.required],
    contactName: ['', Validators.required],
    contactPhone: ['', Validators.required],
    contactEmail: ['', Validators.required],
    fullAddrress: ['', Validators.required]
  });
  constructor(private providerService: ProviderService, private fb: UntypedFormBuilder) {
    this.initLookups();
   }

  ngOnInit(): void {
  }

  initLookups(){
    this.providerService.customerLookups$.subscribe(
      (lookups: ICustomerLookups) => {
        this.facilities = lookups.tranches || [];
      });
  }

  onSubmit(){

    console.warn(this.settlementForm.value);
  }

}
