import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ProviderCampaign } from 'src/app/models/campaign';
import { PortfolioItem } from 'src/app/models/customer-item';
import { ApiService } from 'src/app/services/api.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { CustomGridComponent } from 'src/app/components/custom-grid/custom-grid.component';
import { UserService } from 'src/app/services/user.service';
import { CampaignCopyFormGroup } from 'src/app/models/form-groups';

@Component({
  selector: 'app-campaign-page',
  templateUrl: './campaign-page.component.html',
  styleUrls: ['./campaign-page.component.scss']
})
export class CampaignPageComponent implements OnInit, AfterViewInit {

  bcItems: MenuItem[] = [];
  loading = false;
  portfolioLoaded: boolean = true;
  campaigns: ProviderCampaign[] = [];
  first: number = 0;
  filterFields: string[] = ['customerName', 'campaignName', 'createDate', 'domain', 'targetCount'];
  portfolioItem: PortfolioItem | null = null;
  showCopy = false;
  copying = false;
  copyForm: FormGroup<CampaignCopyFormGroup> = {} as FormGroup;
  copyFormLoaded: boolean = false;

  @ViewChild('cgCampaigns') cgCampaigns: CustomGridComponent = {} as CustomGridComponent;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private router: Router,
    public userService: UserService,
    private toast: ToastService,
    private portfolioService: PortfolioService,
    private campaignService: CampaignService) {
    this.bcItems = [{ label: 'Home', routerLink: ['/home'] }, { label: 'Campaigns' }];
  }

  ngOnInit(): void {
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      if (p) {
        this.portfolioLoaded = true;
        this.setTableCustomer();
      }
      else {
        setTimeout(() => {
          if (!this.portfolioItem) {
            this.portfolioLoaded = false;
          }
        }, 500);
      }

    });
  }

  ngAfterViewInit(): void {
    if (this.portfolioItem && this.cgCampaigns) {
      this.cgCampaigns.setCustomerInfo(this.portfolioItem.customerGuid, null);
    }
  }

  setTableCustomer(count: number = 0) {
    try {
      if (this.portfolioItem && this.cgCampaigns) {
        this.cgCampaigns.setCustomerInfo(this.portfolioItem.customerGuid, null);
      }
    } catch (error) {
      count++;
      if (count > 10) return;
      else this.setTableCustomer(count);
    }
  }

  create() {

  }

  edit(row: any) {
    if (!this.userService.hasWrite('CAMPAIGN_ADD_EDIT_COPY_DELETE')) {
      this.toast.addError("You do not have permission to perform this action");
      return;
    }

    if (this.portfolioItem && this.portfolioItem.customerGuid) {
      this.campaignService.selectCampaignbyGuid(row.CampaignGUID);
      this.router.navigate([`/campaign/settings`]);
    }
    else {
      this.toast.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Cannot determine customer. Unable to Edit.'
      });
    }
  }

  copyCampaign(row: any) {
    if (!this.userService.hasWrite('CAMPAIGN_ADD_EDIT_COPY_DELETE')) {
      this.toast.addError("You do not have permission to perform this action");
      return;
    }
    this.initCopyForm(row);
    this.copyFormLoaded = true;
    this.showCopy = true;
    this.copying = false;
  }

  cancelCopy() {
    this.copyForm = {} as FormGroup;
    this.copyFormLoaded = false;
    this.showCopy = false;
    this.copying = false;
  }

  deleteCampaign(row: any) {
    if (!this.userService.hasWrite('CAMPAIGN_ADD_EDIT_COPY_DELETE')) {
      this.toast.addError("You do not have permission to perform this action");
      return;
    }
    var path = `Campaign/delete/${row.campaignGUID}`;
    this.copying = true;
    this.api.post(path, '').subscribe(data => {
      if (data) {
        this.toast.add({ severity: 'success', summary: 'Success', detail: `Campaign successfully deleted!` });
      } else {
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'ERROR deleting campaign, please contact admin!' });
      }
    },
      (err: any) => {
        console.error(err);
        this.copying = false;
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'ERROR deleting campaign, API has error response, please contact admin!' });
      });
  }

  saveCopy() {

    if (this.copying) return;

    if (!this.copyForm.valid) {
      this.copyForm.markAsTouched();
      this.copyForm.markAllAsTouched();
      this.toast.add({ severity: 'error', summary: 'Error', detail: 'Please emter all the required fields.' });
      return;
    }

    var json = JSON.stringify(this.copyForm.value);
    var path = `Campaign/copy`;
    this.copying = true;
    this.api.post(path, json).subscribe(data => {
      this.copying = false;
      if (data) {
        this.toast.add({ severity: 'success', summary: 'Success', detail: `Campaign successfully copied!` });
        this.cgCampaigns.getDataFromSource();
        this.cancelCopy();
      } else {
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'ERROR copying campaign, please contact admin!' });
      }
    },
      (err: any) => {
        console.error(err);
        this.copying = false;
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'ERROR copying campaign, API has error response, please contact admin!' });
      });
  }

  initCopyForm(sourceCampaign: any) {
    this.copyForm = new FormGroup<CampaignCopyFormGroup>({
      sourceCampaignGuid: new FormControl<string>(sourceCampaign["CampaignGUID"] ?? '', { nonNullable: true }),
      campaignName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      campaignFolder: new FormControl<string | null>(null, { nonNullable: true }),
      domain: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      startUrl: new FormControl<string | null>(null, { nonNullable: true }),
      startDate: new FormControl<Date|null>(null, { nonNullable: true, validators: [Validators.required] }),
      endDate: new FormControl<Date | null>(null, { nonNullable: true }),
      myAccountDomain: new FormControl<string | null>(null, { nonNullable: true }),
      invitationPageUrl: new FormControl<string | null>(null, { nonNullable: true }),
      copyVersions: new FormControl<boolean>(true, { nonNullable: true }),
      copyPages: new FormControl<boolean>(true, { nonNullable: true }),
      copyRuleSets: new FormControl<boolean>(true, { nonNullable: true }),
      copyAlerts: new FormControl<boolean>(true, { nonNullable: true }),
      copyAuditFlags: new FormControl<boolean>(true, { nonNullable: true }),
      copyLabels: new FormControl<boolean>(true, { nonNullable: true })
    });
  }

}
