import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableColumn } from '@p3solved/p3solved-ui';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { Campaign } from 'src/app/models/campaign';
import { PageGroup } from 'src/app/models/campaign-pages';
import { PortfolioItem } from 'src/app/models/customer-item';
import { DERuleField } from 'src/app/models/decision/rule';
import { NumValDropDown } from 'src/app/models/dropdown';
import { NameValuePair } from 'src/app/models/name-value-pair';
import {
  PageData, PageDataButton, PageDataCheckBox, PageDataDD, PageDataHref, PageDataLabel, PageDataLanguage,
  PageDataListItem, PageDataLookups, PageDataRadio, PageDataRow, PageDataScript, PageDataServerValidation,
  PageDataTableRow, PageDataText, PageDataUnsortList
} from 'src/app/models/page-data';
import { Validation } from 'src/app/models/validation';
import { ApiService } from 'src/app/services/api.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-campaign-page-data',
  templateUrl: './campaign-page-data.component.html',
  styleUrls: ['./campaign-page-data.component.scss']
})
export class CampaignPageDataComponent implements OnInit, AfterViewInit, OnDestroy {

  guidsLoaded: boolean = true;
  showPageDataCard: boolean = false;
  loading: boolean = true;
  showAddNew: boolean = false;

  bcItems: MenuItem[] = [];
  subscriptions: Subscription[] = [];
  columns: TableColumn[] = [];
  filterFields: string[] = [];
  pageFields: DERuleField[] = [];
  languages: PageDataLanguage[] = [];
  langSelects: number[] = [];
  lookups: PageDataLookups = {} as PageDataLookups;
  validations: Validation[] = [];
  serverVals: PageDataServerValidation[] = [];
  buttonActions: PageDataServerValidation[] = [];
  pages: PageGroup[] = [];
  newCompItems: NumValDropDown[] = [];
  newCompItemSel: number | null = null;
  newCompItemCnt: number = 1;

  pageData: PageData = {} as PageData;
  pageRowsMaster: PageDataTableRow[] = [];
  pageRows: PageDataTableRow[] = [];

  selectedPortfolio: PortfolioItem | null = null;
  selectedCampaign: Campaign | null = null;
  xmlDoc: XMLDocument = {} as XMLDocument;

  first: number = 0;
  selectedLanguage: number = 0;

  queryPageGuid: string = '';
  pageName: string = '';
  pageDataConfirmKey: string = 'PgDataConfirmSvcKey';

  @ViewChild('dt') dt: Table = {} as Table;

  private parser = new DOMParser();
  private readonly scriptDefault: string = '/* comment, js, css, etc... here */';
  private readonly labelDefault: string = 'default text';
  private readonly buttonDefault: string = 'button: press me';
  private readonly ddDefault: string = 'default label';
  private readonly pageBaseBc: string = "Page Data Edit";
  constructor(
    private portfolioService: PortfolioService,
    private router: Router,
    private campaignService: CampaignService,
    private apiService: ApiService,
    private confirmService: ConfirmationService,
    private toastService: ToastService,
    private navService: NavService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.subscriptions.push(
      combineLatest([this.portfolioService.portfolio$, this.campaignService.selectedCampaign$])
        .subscribe({
          next: ([p, c]) => {
            this.loading = true;
            
            if (this.selectedPortfolio && p && this.selectedPortfolio.customerId != p.customerId) {
              this.router.navigate(['/campaign/pages']);
            }
            this.selectedPortfolio = p;

            if (this.selectedCampaign && c && this.selectedCampaign.campaignId != c.campaignId) {
              this.router.navigate(['/campaign/pages']);
            }
            this.selectedCampaign = c;

            if (p && c) {
              this.guidsLoaded = true;
              this.checkQueryParams();
            }
            else {
              setTimeout(() => {                
                if (!this.selectedPortfolio || !this.selectedCampaign) {
                  this.loading = false;
                  this.guidsLoaded = false;
                }              
              }, 500);
            }
          }
        })
    );

    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Campaigns', routerLink: ['/campaign'], command: () => { this.navService.setLeftNavSelection('Campaign'); } },
      { label: 'Pages', routerLink: ['/campaign/pages'], command: () => { this.navService.setLeftNavSelection('Campaign', null, false); } },
      { label: this.pageBaseBc }
    ];

    this.newCompItems = [
      { text: 'Script', value: 1 },
      { text: 'Label', value: 2 },
      { text: 'A href', value: 3 },
      { text: 'Button', value: 4 },
      { text: 'Unsorted List', value: 5 },
      { text: 'Form: Textbox', value: 6 },
      { text: 'Form: Dropdown', value: 7 },
      { text: 'Form: Radio List', value: 8 },
      { text: 'Form: Checkbox List', value: 9 },
    ];
  }

  ngAfterViewInit(): void {
    this.navService.setLeftNavSelection('Campaign', null, false);
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      })
    }
  }

  checkQueryParams() {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params && params.pGuid) {
          this.queryPageGuid = params.pGuid;
          this.getLookups();
        }
        else {
          this.loading = false;
        }
      })
    );
  }

  getLookups() {
    const apiTags = forkJoin({
      fields: this.apiService.get(`decision/rule/fields/${this.selectedPortfolio?.customerGuid}/${this.selectedCampaign?.campaignGuid}/-1`),
      pages: this.apiService.get(`pages/page-groups/${this.selectedCampaign?.campaignGuid}`),
      langs: this.apiService.get(`pagedata/languages/${this.selectedPortfolio?.customerGuid}/${this.selectedCampaign?.campaignGuid}`),
      lookups: this.apiService.get(`pagedata/lookups/${this.selectedPortfolio?.customerGuid}`),
      validations: this.apiService.get(`lookup/validations`),
      serverVals: this.apiService.get(`pagedata/servervalidations/${this.selectedPortfolio?.customerGuid}`)
    }).subscribe(data => {
      this.pageFields = data.fields as DERuleField[];
      this.pages = data.pages as PageGroup[];
      this.languages = data.langs as PageDataLanguage[];
      this.lookups = data.lookups as PageDataLookups;
      this.validations = data.validations as Validation[];
      this.serverVals = (data.serverVals as PageDataServerValidation[]).filter(s => !s.buttonAction);
      this.buttonActions = (data.serverVals as PageDataServerValidation[]).filter(s => s.buttonAction);
      this.getPageData();
    }, (err: any) => {
      this.toastService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Unable to get Page Fields for Customer. See log for details.'
      }, 'center');
      console.error(err);
    });
  }

  getPageData() {
    let pgSub = this.apiService.get(`pagedata/${this.selectedCampaign?.campaignGuid}/${this.queryPageGuid}`)
      .subscribe({
        next: (pageData: PageDataRow[]) => {
          this.pageName = pageData[0].pageName;
          this.setupColumns();

          if (pageData && pageData.length > 0 && pageData[0].pageXml) {
            this.xmlDoc = this.parser.parseFromString(pageData[0].pageXml, 'application/xml');

            let page = this.xmlDoc.querySelector('page');
            if (page) {
              this.pageData.id = page.attributes.getNamedItem('id')?.value ?? '';
              this.pageData.pid = page.attributes.getNamedItem('pid')?.value ?? '';
              this.pageData.title = page.attributes.getNamedItem('title')?.value ?? '';
              this.pageData.pageUrl = page.attributes.getNamedItem('pageUrl')?.value ?? '';
            }

            this.pageRows = [];
            this.setupScripts();
            this.setupLabels();
            this.setupHrefs();
            this.setupButtons();
            this.setupUnsortLists();
            this.setupTextBoxes();
            this.setupDropdowns();
            this.setupRadioButtons();
            this.setupCheckBoxes();

            this.selectedLanguage = 0;
            this.pageRows = this.pageRowsMaster.filter(p => p.version == this.selectedLanguage);
            this.setupRowLanguages();
            this.loading = false;
            this.showPageDataCard = true;
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get page data. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { pgSub.unsubscribe(); }
      });

  }

  getPages(): PageGroup[] {
    return this.pages.filter(p => p.pageID != +this.pageData.pid);
  }

  setupColumns() {
    this.columns = [
      {
        field: 'id',
        header: '',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'name',
        header: 'Object Name',
        show: true,
        sortable: false,
        clickable: false,
        class: 'max-w-12rem'
      },
      {
        field: 'objectId',
        header: 'Object ID',
        show: true,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'element',
        header: 'Element',
        show: true,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'preview',
        header: 'Preview',
        show: true,
        sortable: false,
        clickable: false,
        class: 'max-w-30rem break-word'
      },
      {
        field: 'version',
        header: 'Version',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'searchContent',
        header: 'Searchable',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      }
    ];
    this.filterFields = this.columns.map(m => { return m.field; });
  }

  getColumns() {
    return this.columns.filter(c => c.show);
  }

  setupScripts() {
    this.pageData.scripts = [];
    this.xmlDoc.querySelectorAll('script').forEach(script => {
      let prev = this.cleanAndRenderPreview(script.innerHTML ?? '');
      let pgMstr: PageDataTableRow = {
        id: script.attributes.getNamedItem('cId')?.value ?? '',
        name: script.attributes.getNamedItem('alias')?.value ?? '',
        objectId: script.attributes.getNamedItem('name')?.value ?? '',
        element: 'Script',
        preview: prev,
        render: '',
        version: +(script.attributes.getNamedItem('versionId')?.value ?? 0),
        searchContent: prev,
        isSaved: true
      };
      let pgScript: PageDataScript = {
        x: script.attributes.getNamedItem('x')?.value ?? '0',
        y: script.attributes.getNamedItem('y')?.value ?? '0',
        h: script.attributes.getNamedItem('h')?.value ?? '0',
        w: script.attributes.getNamedItem('w')?.value ?? '0',
        m: script.attributes.getNamedItem('m')?.value ?? '0',
        bgc: script.attributes.getNamedItem('bgc')?.value ?? '0xFFFFFF',
        id: script.attributes.getNamedItem('id')?.value ?? '',
        name: script.attributes.getNamedItem('name')?.value ?? '',
        alias: script.attributes.getNamedItem('alias')?.value ?? '',
        c: script.attributes.getNamedItem('c')?.value ?? '',
        cId: script.attributes.getNamedItem('cId')?.value ?? '',
        mC: script.attributes.getNamedItem('mC')?.value ?? '',
        req: script.attributes.getNamedItem('req')?.value ?? '',
        class: script.attributes.getNamedItem('class')?.value ?? '',
        versionId: +(script.attributes.getNamedItem('versionId')?.value ?? 0),
        script: prev
      };
      pgMstr.searchContent = this.getScriptSearchContent(pgScript);

      this.pageRowsMaster.push(pgMstr);
      this.pageData.scripts.push(pgScript);

    });
  }

  getScriptSearchContent(pgScript: PageDataScript): string {
    let searchStrs: string[] = [];
    searchStrs.push(pgScript.id, pgScript.name, pgScript.alias, pgScript.cId, pgScript.class, pgScript.script);
    return searchStrs.join(" ");
  }

  setupLabels() {
    this.pageData.labels = [];
    this.xmlDoc.querySelectorAll('lbl').forEach(label => {
      let prev = this.cleanAndRenderPreview(label.innerHTML ?? '');
      let rowMstr: PageDataTableRow = {
        id: label.attributes.getNamedItem('cId')?.value ?? '',
        name: label.attributes.getNamedItem('alias')?.value ?? '',
        objectId: label.attributes.getNamedItem('name')?.value ?? '',
        element: 'Label',
        preview: prev,
        render: '',
        version: +(label.attributes.getNamedItem('versionId')?.value ?? 0),
        searchContent: prev,
        isSaved: true
      };
      let pgLabel: PageDataLabel = {
        x: label.attributes.getNamedItem('x')?.value ?? '0',
        y: label.attributes.getNamedItem('y')?.value ?? '0',
        h: label.attributes.getNamedItem('h')?.value ?? '0',
        w: label.attributes.getNamedItem('w')?.value ?? '0',
        m: label.attributes.getNamedItem('m')?.value ?? '0',
        bgc: label.attributes.getNamedItem('bgc')?.value ?? '0xFFFFFF',
        id: label.attributes.getNamedItem('id')?.value ?? '',
        name: label.attributes.getNamedItem('name')?.value ?? '',
        alias: label.attributes.getNamedItem('alias')?.value ?? '',
        c: label.attributes.getNamedItem('c')?.value ?? '',
        cId: label.attributes.getNamedItem('cId')?.value ?? '',
        mC: label.attributes.getNamedItem('mC')?.value ?? '',
        req: label.attributes.getNamedItem('req')?.value ?? '',
        class: label.attributes.getNamedItem('class')?.value ?? '',
        versionId: +(label.attributes.getNamedItem('versionId')?.value ?? 0),
        prefill: label.attributes.getNamedItem('prefill')?.value ?? '',
        label: prev
      };

      rowMstr.searchContent = this.getLabelSearchContent(pgLabel);

      this.pageRowsMaster.push(rowMstr);
      this.pageData.labels.push(pgLabel);

    });
  }

  getLabelSearchContent(pgLabel: PageDataLabel): string {
    let searchStrs: string[] = [];
    searchStrs.push(pgLabel.id, pgLabel.name, pgLabel.alias, pgLabel.cId, pgLabel.class, pgLabel.label);
    return searchStrs.join(" ");
  }

  setupHrefs() {
    this.pageData.hrefs = [];
    this.xmlDoc.querySelectorAll('a').forEach(href => {
      let rowMstr: PageDataTableRow = {
        id: href.attributes.getNamedItem('cId')?.value ?? '',
        name: href.attributes.getNamedItem('alias')?.value ?? '',
        objectId: href.attributes.getNamedItem('name')?.value ?? '',
        element: 'Href',
        preview: href.innerHTML,
        render: '',
        version: +(href.attributes.getNamedItem('versionId')?.value ?? 0),
        searchContent: href.innerHTML,
        isSaved: true
      };
      let pgHref: PageDataHref = {
        x: href.attributes.getNamedItem('x')?.value ?? '0',
        y: href.attributes.getNamedItem('y')?.value ?? '0',
        h: href.attributes.getNamedItem('h')?.value ?? '0',
        w: href.attributes.getNamedItem('w')?.value ?? '0',
        m: href.attributes.getNamedItem('m')?.value ?? '0',
        bgc: href.attributes.getNamedItem('bgc')?.value ?? '0xFFFFFF',
        id: href.attributes.getNamedItem('id')?.value ?? '',
        name: href.attributes.getNamedItem('name')?.value ?? '',
        alias: href.attributes.getNamedItem('alias')?.value ?? '',
        c: href.attributes.getNamedItem('c')?.value ?? '',
        cId: href.attributes.getNamedItem('cId')?.value ?? '',
        mC: href.attributes.getNamedItem('mC')?.value ?? '',
        req: href.attributes.getNamedItem('req')?.value ?? '',
        class: href.attributes.getNamedItem('class')?.value ?? '',
        versionId: +(href.attributes.getNamedItem('versionId')?.value ?? 0),
        hrf: href.attributes.getNamedItem('hrf')?.value ?? '',
        tgt: href.attributes.getNamedItem('tgt')?.value ?? '',
        img: href.attributes.getNamedItem('img')?.value ?? '',
        onclick: href.attributes.getNamedItem('onclick')?.value ?? '',
        text: href.innerHTML,
      };

      rowMstr.searchContent = this.getHrefSearchContent(pgHref);
      this.pageRowsMaster.push(rowMstr);
      this.pageData.hrefs.push(pgHref);

    });
  }

  getHrefSearchContent(pgHref: PageDataHref): string {
    let searchStrs: string[] = [];
    searchStrs.push(pgHref.id, pgHref.name, pgHref.alias, pgHref.cId, pgHref.class, pgHref.hrf, pgHref.tgt, pgHref.onclick, pgHref.text);
    return searchStrs.join(" ");
  }

  setupButtons() {
    this.pageData.buttons = [];
    this.xmlDoc.querySelectorAll('btn').forEach(btn => {
      let prev = this.cleanAndRenderPreview(btn.querySelector('value')?.innerHTML ?? '');
      let id = btn.attributes.getNamedItem('cId')?.value ?? '';
      let btnClass = btn.attributes.getNamedItem('class')?.value ?? '';
      let pgMstr: PageDataTableRow = {
        id: id,
        name: btn.attributes.getNamedItem('alias')?.value ?? '',
        objectId: btn.attributes.getNamedItem('name')?.value ?? '',
        element: 'Button',
        preview: prev,
        render: this.getButtonRender(btnClass, prev),
        version: +(btn.attributes.getNamedItem('versionId')?.value ?? 0),
        searchContent: prev,
        isSaved: true
      };
      let pgBtn: PageDataButton = {
        x: btn.attributes.getNamedItem('x')?.value ?? '0',
        y: btn.attributes.getNamedItem('y')?.value ?? '0',
        h: btn.attributes.getNamedItem('h')?.value ?? '0',
        w: btn.attributes.getNamedItem('w')?.value ?? '0',
        m: btn.attributes.getNamedItem('m')?.value ?? '0',
        bgc: btn.attributes.getNamedItem('bgc')?.value ?? '0xFFFFFF',
        id: btn.attributes.getNamedItem('id')?.value ?? '',
        name: btn.attributes.getNamedItem('name')?.value ?? '',
        alias: btn.attributes.getNamedItem('alias')?.value ?? '',
        c: btn.attributes.getNamedItem('c')?.value ?? '',
        cId: btn.attributes.getNamedItem('cId')?.value ?? '',
        mC: btn.attributes.getNamedItem('mC')?.value ?? '',
        req: btn.attributes.getNamedItem('req')?.value ?? '0',
        class: btnClass,
        versionId: +(btn.attributes.getNamedItem('versionId')?.value ?? 0),
        text: prev,
        npid: btn.attributes.getNamedItem('npid')?.value ?? '',
        link: btn.attributes.getNamedItem('link')?.value ?? '',
        ba: btn.attributes.getNamedItem('ba')?.value ?? '0',
        autoLogin: btn.attributes.getNamedItem('autoLogin')?.value ?? '0',
        bas: []
      };

      btn.querySelectorAll('baOp').forEach(ba => {
        pgBtn.bas.push({
          id: ba.attributes.getNamedItem('id')?.value ?? '',
          cId: ba.attributes.getNamedItem('cId')?.value ?? '',
          vId: ba.attributes.getNamedItem('vId')?.value ?? '',
          vName: ba.attributes.getNamedItem('vName')?.value ?? '',
          em: ba.attributes.getNamedItem('em')?.value ?? '',
        });
      });

      pgMstr.searchContent = this.getButtonSearchContent(pgBtn);

      this.pageRowsMaster.push(pgMstr);
      this.pageData.buttons.push(pgBtn);
    });
  }

  getButtonSearchContent(pgBtn: PageDataButton): string {
    let searchStrs: string[] = [];
    searchStrs.push(pgBtn.id, pgBtn.name, pgBtn.alias, pgBtn.cId, pgBtn.class, pgBtn.text, pgBtn.link, pgBtn.npid);
    pgBtn.bas.forEach(ba => { searchStrs.push(ba.vName, ba.em) });
    return searchStrs.join(" ");
  }

  getButtonRender(btnClass: string, prev: string) {
    return {
      label: prev,
      class: btnClass
    };
  }

  setupUnsortLists() {
    this.pageData.unsortLists = [];
    let i: number = 0;
    this.xmlDoc.querySelectorAll('ulist').forEach(ul => {
      let id = ul.attributes.getNamedItem('cId')?.value ?? '';
      let pgRowMstr: PageDataTableRow = {
        id: id,
        name: ul.attributes.getNamedItem('alias')?.value ?? '',
        objectId: ul.attributes.getNamedItem('name')?.value ?? '',
        element: 'Unsorted List',
        preview: '',
        render: {},
        version: +(ul.attributes.getNamedItem('versionId')?.value ?? 0),
        searchContent: '',
        isSaved: true
      };

      let pgDataUL: PageDataUnsortList = {
        x: ul.attributes.getNamedItem('x')?.value ?? '0',
        y: ul.attributes.getNamedItem('y')?.value ?? '0',
        h: ul.attributes.getNamedItem('h')?.value ?? '0',
        w: ul.attributes.getNamedItem('w')?.value ?? '0',
        m: ul.attributes.getNamedItem('m')?.value ?? '0',
        bgc: ul.attributes.getNamedItem('bgc')?.value ?? '0xFFFFFF',
        id: ul.attributes.getNamedItem('id')?.value ?? '',
        name: ul.attributes.getNamedItem('name')?.value ?? '',
        alias: ul.attributes.getNamedItem('alias')?.value ?? '',
        c: ul.attributes.getNamedItem('c')?.value ?? '',
        cId: ul.attributes.getNamedItem('cId')?.value ?? '',
        mC: ul.attributes.getNamedItem('mC')?.value ?? '',
        req: ul.attributes.getNamedItem('req')?.value ?? '',
        class: ul.attributes.getNamedItem('class')?.value ?? '',
        versionId: +(ul.attributes.getNamedItem('versionId')?.value ?? 0),
        list: []
      };

      ul.querySelectorAll('li').forEach(li => {
        pgDataUL.list.push({
          id: li.attributes.getNamedItem('id')?.value ?? '',
          cId: li.attributes.getNamedItem('cId')?.value ?? '',
          req: li.attributes.getNamedItem('req')?.value == '1',
          val: '',
          sel: false,
          npid: null,
          text: li.innerHTML ?? ''
        })
      });

      pgRowMstr.render = this.getUnsortListRender(pgDataUL.list);
      pgRowMstr.searchContent = this.getUnsortSearchContent(pgDataUL);
      this.pageRowsMaster.push(pgRowMstr);
      this.pageData.unsortLists.push(pgDataUL);
    });

  }

  getUnsortSearchContent(pgDataUL: PageDataUnsortList): string {
    let searchStrs: string[] = [];
    searchStrs.push(pgDataUL.id, pgDataUL.name, pgDataUL.alias, pgDataUL.cId, pgDataUL.class);
    pgDataUL.list.forEach(li => { searchStrs.push(li.cId, li.text) });
    return searchStrs.join(" ");
  }

  getUnsortListRender(lis: PageDataListItem[]) {
    let items: NameValuePair[] = [];

    lis.forEach(li => {
      items.push({
        name: li.text,
        value: li.val
      })
    });

    return {
      items: items
    };
  }

  setupTextBoxes() {
    this.pageData.textBoxes = [];
    let i: number = 0;
    this.xmlDoc.querySelectorAll('txt').forEach(text => {
      let prev = this.cleanAndRenderPreview(text.querySelector('value')?.innerHTML ?? '');
      let id = text.attributes.getNamedItem('cId')?.value ?? '';
      let lbl = text.attributes.getNamedItem('lbl')?.value ?? '';
      let pgMstr: PageDataTableRow = {
        id: text.attributes.getNamedItem('cId')?.value ?? '',
        name: text.attributes.getNamedItem('alias')?.value ?? '',
        objectId: text.attributes.getNamedItem('name')?.value ?? '',
        element: 'TextBox',
        preview: prev,
        render: this.getTextBoxRender(lbl, prev),
        version: +(text.attributes.getNamedItem('versionId')?.value ?? 0),
        searchContent: prev,
        isSaved: true
      };
      let valType = text.attributes.getNamedItem('v')?.value;

      let pgDataTxt: PageDataText = {
        x: text.attributes.getNamedItem('x')?.value ?? '0',
        y: text.attributes.getNamedItem('y')?.value ?? '0',
        h: text.attributes.getNamedItem('h')?.value ?? '0',
        w: text.attributes.getNamedItem('w')?.value ?? '0',
        m: text.attributes.getNamedItem('m')?.value ?? '0',
        bgc: text.attributes.getNamedItem('bgc')?.value ?? '0xFFFFFF',
        id: text.attributes.getNamedItem('id')?.value ?? '',
        name: text.attributes.getNamedItem('name')?.value ?? '',
        alias: text.attributes.getNamedItem('alias')?.value ?? '',
        c: text.attributes.getNamedItem('c')?.value ?? '',
        cId: text.attributes.getNamedItem('cId')?.value ?? '',
        mC: text.attributes.getNamedItem('mC')?.value ?? '',
        req: text.attributes.getNamedItem('req')?.value ?? '',
        class: text.attributes.getNamedItem('class')?.value ?? '',
        versionId: +(text.attributes.getNamedItem('versionId')?.value ?? 0),
        copy: prev,
        dcId: text.attributes.getNamedItem('dcId')?.value ?? '',
        lbl: text.attributes.getNamedItem('lbl')?.value ?? '',
        dn: text.attributes.getNamedItem('dn')?.value ?? '',
        for: text.querySelector('value')?.attributes.getNamedItem('for')?.value ?? '',
        cOrder: text.attributes.getNamedItem('cOrder')?.value ?? '',
        tm: text.attributes.getNamedItem('tm')?.value ?? '',
        type: text.attributes.getNamedItem('type')?.value ?? '',
        fReq: text.attributes.getNamedItem('fReq')?.value ?? '',
        crypt: text.attributes.getNamedItem('crypt')?.value ?? '',
        em: text.attributes.getNamedItem('em')?.value ?? '',
        dcEM: text.attributes.getNamedItem('dcEM')?.value ?? '',
        v: valType ? +valType : null,
        reem: text.attributes.getNamedItem('reem')?.value ?? '',
        disabled: text.attributes.getNamedItem('disabled')?.value ?? '',
        cv: text.attributes.getNamedItem('cv')?.value ?? '',
        cvv: text.attributes.getNamedItem('cvv')?.value ?? '',
        crhc: text.attributes.getNamedItem('crhc')?.value ?? '',
        crht: text.attributes.getNamedItem('crht')?.value ?? '',
        crv: text.attributes.getNamedItem('crv')?.value ?? '',
        sv: text.attributes.getNamedItem('sv')?.value ?? '',
        rgxValidations: [],
        serverValidations: []
      };

      text.querySelectorAll('crvOp').forEach(rgx => {
        pgDataTxt.rgxValidations.push({
          id: rgx.attributes.getNamedItem('id')?.value ?? '',
          cId: rgx.attributes.getNamedItem('cId')?.value ?? '',
          re: rgx.attributes.getNamedItem('re')?.value ?? '',
          em: rgx.attributes.getNamedItem('em')?.value ?? '',
          vc: rgx.attributes.getNamedItem('vc')?.value ?? '',
          ivc: rgx.attributes.getNamedItem('ivc')?.value ?? '',
        });
      });

      text.querySelectorAll('svOp').forEach(svr => {
        pgDataTxt.serverValidations.push({
          id: svr.attributes.getNamedItem('id')?.value ?? '',
          cId: svr.attributes.getNamedItem('cId')?.value ?? '',
          vId: svr.attributes.getNamedItem('vId')?.value ?? '',
          vName: svr.attributes.getNamedItem('vName')?.value ?? '',
          em: svr.attributes.getNamedItem('em')?.value ?? '',
        });
      });

      pgMstr.searchContent = this.getTextboxSearchContent(pgDataTxt);
      this.pageRowsMaster.push(pgMstr);
      this.pageData.textBoxes.push(pgDataTxt);
    });
  }

  getTextboxSearchContent(pgDataTxt: PageDataText): string {
    let searchStrs: string[] = [];
    searchStrs.push(pgDataTxt.id, pgDataTxt.name, pgDataTxt.alias, pgDataTxt.cId, pgDataTxt.class, pgDataTxt.em,
      pgDataTxt.dcEM, pgDataTxt.reem, pgDataTxt.copy, pgDataTxt.crht, pgDataTxt.crhc, pgDataTxt.cv, pgDataTxt.lbl);

    pgDataTxt.rgxValidations.forEach(r => { searchStrs.push(r.cId, r.re, r.em, r.vc, r.ivc) });
    pgDataTxt.serverValidations.forEach(s => { searchStrs.push(s.cId, s.em, s.vName) });

    return searchStrs.join(" ");
  }

  getTextBoxRender(lbl: string, prev: string) {
    return {
      placeholder: lbl,
      model: prev
    };
  }

  setupDropdowns() {
    this.pageData.dropdowns = [];
    let i: number = 0;
    this.xmlDoc.querySelectorAll('dd').forEach(dd => {
      let prev = this.cleanAndRenderPreview(dd.querySelector('value')?.innerHTML ?? '');
      let id = dd.attributes.getNamedItem('cId')?.value ?? '';
      let lbl = dd.attributes.getNamedItem('lbl')?.value ?? '';
      let pgRowMstr: PageDataTableRow = {
        id: id,
        name: dd.attributes.getNamedItem('alias')?.value ?? '',
        objectId: dd.attributes.getNamedItem('name')?.value ?? '',
        element: 'Dropdown',
        preview: lbl,
        render: '',
        version: +(dd.attributes.getNamedItem('versionId')?.value ?? 0),
        searchContent: prev,
        isSaved: true
      };
      let valType = dd.attributes.getNamedItem('v')?.value;

      let pgDataDD: PageDataDD = {
        x: dd.attributes.getNamedItem('x')?.value ?? '0',
        y: dd.attributes.getNamedItem('y')?.value ?? '0',
        h: dd.attributes.getNamedItem('h')?.value ?? '0',
        w: dd.attributes.getNamedItem('w')?.value ?? '0',
        m: dd.attributes.getNamedItem('m')?.value ?? '0',
        bgc: dd.attributes.getNamedItem('bgc')?.value ?? '0xFFFFFF',
        id: dd.attributes.getNamedItem('id')?.value ?? '',
        name: dd.attributes.getNamedItem('name')?.value ?? '',
        alias: dd.attributes.getNamedItem('alias')?.value ?? '',
        c: dd.attributes.getNamedItem('c')?.value ?? '',
        cId: dd.attributes.getNamedItem('cId')?.value ?? '',
        mC: dd.attributes.getNamedItem('mC')?.value ?? '',
        req: dd.attributes.getNamedItem('req')?.value ?? '',
        class: dd.attributes.getNamedItem('class')?.value ?? '',
        versionId: +(dd.attributes.getNamedItem('versionId')?.value ?? 0),
        lbl: dd.attributes.getNamedItem('lbl')?.value ?? '',
        dn: dd.attributes.getNamedItem('dn')?.value ?? '',
        for: dd.querySelector('value')?.attributes.getNamedItem('for')?.value ?? '',
        cOrder: dd.attributes.getNamedItem('cOrder')?.value ?? '',
        fReq: dd.attributes.getNamedItem('fReq')?.value ?? '',
        em: dd.attributes.getNamedItem('em')?.value ?? '',
        v: valType ? +valType : null,
        vre: dd.attributes.getNamedItem('vre')?.value ?? '',
        reqByFld: dd.attributes.getNamedItem('reqByFld')?.value ?? '',
        reem: dd.attributes.getNamedItem('reem')?.value ?? '',
        disabled: dd.attributes.getNamedItem('disabled')?.value ?? '',
        sv: dd.attributes.getNamedItem('sv')?.value ?? '',
        serverValidations: [],
        ddItems: []
      };

      dd.querySelectorAll('svOp').forEach(svr => {
        pgDataDD.serverValidations.push({
          id: svr.attributes.getNamedItem('id')?.value ?? '',
          cId: svr.attributes.getNamedItem('cId')?.value ?? '',
          vId: svr.attributes.getNamedItem('vId')?.value ?? '',
          vName: svr.attributes.getNamedItem('vName')?.value ?? '',
          em: svr.attributes.getNamedItem('em')?.value ?? '',
        });
      });

      dd.querySelectorAll('ops op').forEach(op => {
        let strNpid = op.attributes.getNamedItem('npid')?.value;
        pgDataDD.ddItems.push({
          id: op.attributes.getNamedItem('id')?.value ?? '',
          cId: op.attributes.getNamedItem('cId')?.value ?? '',
          req: op.attributes.getNamedItem('req')?.value == '1',
          val: op.attributes.getNamedItem('val')?.value ?? '',
          sel: op.attributes.getNamedItem('sel')?.value == '1',
          npid: strNpid != undefined ? +strNpid : null,
          text: op.innerHTML ?? ''
        })
      });

      pgRowMstr.render = this.getDropdownRender(lbl, pgDataDD.ddItems);
      pgRowMstr.searchContent = this.getDDSearchContent(pgDataDD);
      this.pageRowsMaster.push(pgRowMstr);
      this.pageData.dropdowns.push(pgDataDD);
    });
  }

  getDDSearchContent(pgDataDD: PageDataDD): string {
    let searchStrs: string[] = [];
    searchStrs.push(pgDataDD.id, pgDataDD.name, pgDataDD.alias, pgDataDD.cId, pgDataDD.class, pgDataDD.em,
      pgDataDD.reem, pgDataDD.lbl);

    pgDataDD.ddItems.forEach(itm => { searchStrs.push(itm.cId, itm.val, itm.text) });
    pgDataDD.serverValidations.forEach(s => { searchStrs.push(s.cId, s.em, s.vName) });

    return searchStrs.join(" ");
  }

  getDropdownRender(lbl: string, ops: PageDataListItem[]): any {
    let ddItems: NameValuePair[] = [];
    let selectedItem: string | null = null;

    ops.forEach(op => {
      if (op.sel) {
        selectedItem = op.val;
      }
      ddItems.push({
        name: op.text,
        value: op.val
      })
    });

    return {
      placeholder: lbl,
      items: ddItems,
      model: selectedItem
    };

  }

  setupRadioButtons() {
    this.pageData.radios = [];
    let i: number = 0;
    this.xmlDoc.querySelectorAll('rl').forEach(rl => {
      let prev = this.cleanAndRenderPreview(rl.querySelector('value')?.innerHTML ?? '');
      let id = rl.attributes.getNamedItem('cId')?.value ?? '';
      let lbl = rl.attributes.getNamedItem('lbl')?.value ?? '';
      let pgRowMstr: PageDataTableRow = {
        id: id,
        name: rl.attributes.getNamedItem('alias')?.value ?? '',
        objectId: rl.attributes.getNamedItem('name')?.value ?? '',
        element: 'RadioList',
        preview: lbl,
        render: '',
        version: +(rl.attributes.getNamedItem('versionId')?.value ?? 0),
        searchContent: prev,
        isSaved: true
      };
      let valType = rl.attributes.getNamedItem('v')?.value;

      let pgDataRdo: PageDataRadio = {
        x: rl.attributes.getNamedItem('x')?.value ?? '0',
        y: rl.attributes.getNamedItem('y')?.value ?? '0',
        h: rl.attributes.getNamedItem('h')?.value ?? '0',
        w: rl.attributes.getNamedItem('w')?.value ?? '0',
        m: rl.attributes.getNamedItem('m')?.value ?? '0',
        bgc: rl.attributes.getNamedItem('bgc')?.value ?? '0xFFFFFF',
        id: rl.attributes.getNamedItem('id')?.value ?? '',
        name: rl.attributes.getNamedItem('name')?.value ?? '',
        alias: rl.attributes.getNamedItem('alias')?.value ?? '',
        c: rl.attributes.getNamedItem('c')?.value ?? '',
        cId: rl.attributes.getNamedItem('cId')?.value ?? '',
        mC: rl.attributes.getNamedItem('mC')?.value ?? '',
        req: rl.attributes.getNamedItem('req')?.value ?? '',
        class: rl.attributes.getNamedItem('class')?.value ?? '',
        versionId: +(rl.attributes.getNamedItem('versionId')?.value ?? 0),
        lbl: rl.attributes.getNamedItem('lbl')?.value ?? '',
        dn: rl.attributes.getNamedItem('dn')?.value ?? '',
        for: rl.querySelector('value')?.attributes.getNamedItem('for')?.value ?? '',
        cOrder: rl.attributes.getNamedItem('cOrder')?.value ?? '',
        fReq: rl.attributes.getNamedItem('fReq')?.value ?? '',
        em: rl.attributes.getNamedItem('em')?.value ?? '',
        v: valType ? +valType : null,
        vre: rl.attributes.getNamedItem('vre')?.value ?? '',
        reqByFld: rl.attributes.getNamedItem('reqByFld')?.value ?? '',
        reem: rl.attributes.getNamedItem('reem')?.value ?? '',
        horiz: rl.attributes.getNamedItem('horiz')?.value ?? '0',
        disabled: rl.attributes.getNamedItem('disabled')?.value ?? '',
        radios: []
      };

      rl.querySelectorAll('ops op').forEach(op => {
        let strNpid = op.attributes.getNamedItem('npid')?.value;
        pgDataRdo.radios.push({
          id: op.attributes.getNamedItem('id')?.value ?? '',
          cId: op.attributes.getNamedItem('cId')?.value ?? '',
          req: op.attributes.getNamedItem('req')?.value == '1',
          val: op.attributes.getNamedItem('val')?.value ?? '',
          sel: op.attributes.getNamedItem('sel')?.value == '1',
          npid: strNpid != undefined ? +strNpid : null,
          text: op.innerHTML ?? ''
        })
      });

      pgRowMstr.render = this.getRadioListRender(lbl, pgDataRdo.radios, pgDataRdo.horiz == '1');
      pgRowMstr.searchContent = this.getRadioSearchContent(pgDataRdo);
      this.pageRowsMaster.push(pgRowMstr);
      this.pageData.radios.push(pgDataRdo);
    });
  }

  getRadioSearchContent(pgDataRdo: PageDataRadio): string {
    let searchStrs: string[] = [];
    searchStrs.push(pgDataRdo.id, pgDataRdo.name, pgDataRdo.alias, pgDataRdo.cId, pgDataRdo.class, pgDataRdo.em,
      pgDataRdo.reem, pgDataRdo.lbl, pgDataRdo.vre);

    pgDataRdo.radios.forEach(r => { searchStrs.push(r.cId, r.val, r.text) });

    return searchStrs.join(" ");
  }

  getRadioListRender(lbl: string, ops: PageDataListItem[], isHoriz: boolean): any {
    let items: NameValuePair[] = [];
    let selectedItem: string | null = null;

    ops.forEach(op => {
      if (op.sel) {
        selectedItem = op.val;
      }
      items.push({
        name: op.text,
        value: op.val
      })
    });

    return {
      label: lbl,
      items: items,
      model: selectedItem,
      class: isHoriz ? 'flex flex-wrap' : '',
      rdoClass: isHoriz ? 'w-2' : 'mb-2',
      lblClass: isHoriz ? 'w-12' : 'mb-2'
    };

  }

  setupCheckBoxes() {
    this.pageData.checkboxes = [];
    let i: number = 0;
    this.xmlDoc.querySelectorAll('ckl').forEach(ckl => {
      let prev = this.cleanAndRenderPreview(ckl.querySelector('value')?.innerHTML ?? '');
      let id = ckl.attributes.getNamedItem('cId')?.value ?? '';
      let lbl = ckl.attributes.getNamedItem('lbl')?.value ?? '';
      let pgRowMstr: PageDataTableRow = {
        id: id,
        name: ckl.attributes.getNamedItem('alias')?.value ?? '',
        objectId: ckl.attributes.getNamedItem('name')?.value ?? '',
        element: 'CheckBoxList',
        preview: lbl,
        render: '',
        version: +(ckl.attributes.getNamedItem('versionId')?.value ?? 0),
        searchContent: prev,
        isSaved: true
      };
      let valType = ckl.attributes.getNamedItem('v')?.value;

      let pgDataChk: PageDataCheckBox = {
        x: ckl.attributes.getNamedItem('x')?.value ?? '0',
        y: ckl.attributes.getNamedItem('y')?.value ?? '0',
        h: ckl.attributes.getNamedItem('h')?.value ?? '0',
        w: ckl.attributes.getNamedItem('w')?.value ?? '0',
        m: ckl.attributes.getNamedItem('m')?.value ?? '0',
        bgc: ckl.attributes.getNamedItem('bgc')?.value ?? '0xFFFFFF',
        id: ckl.attributes.getNamedItem('id')?.value ?? '',
        name: ckl.attributes.getNamedItem('name')?.value ?? '',
        alias: ckl.attributes.getNamedItem('alias')?.value ?? '',
        c: ckl.attributes.getNamedItem('c')?.value ?? '',
        cId: ckl.attributes.getNamedItem('cId')?.value ?? '',
        mC: ckl.attributes.getNamedItem('mC')?.value ?? '',
        req: ckl.attributes.getNamedItem('req')?.value ?? '',
        class: ckl.attributes.getNamedItem('class')?.value ?? '',
        versionId: +(ckl.attributes.getNamedItem('versionId')?.value ?? 0),
        lbl: ckl.attributes.getNamedItem('lbl')?.value ?? '',
        dn: ckl.attributes.getNamedItem('dn')?.value ?? '',
        for: ckl.querySelector('value')?.attributes.getNamedItem('for')?.value ?? '',
        cOrder: ckl.attributes.getNamedItem('cOrder')?.value ?? '',
        fReq: ckl.attributes.getNamedItem('fReq')?.value ?? '',
        em: ckl.attributes.getNamedItem('em')?.value ?? '',
        v: valType ? +valType : null,
        vre: ckl.attributes.getNamedItem('vre')?.value ?? '',
        reqByFld: ckl.attributes.getNamedItem('reqByFld')?.value ?? '',
        reem: ckl.attributes.getNamedItem('reem')?.value ?? '',
        horiz: ckl.attributes.getNamedItem('horiz')?.value ?? '0',
        disabled: ckl.attributes.getNamedItem('disabled')?.value ?? '',
        boxes: []
      };

      ckl.querySelectorAll('ops op').forEach(op => {
        let strNpid = op.attributes.getNamedItem('npid')?.value;
        pgDataChk.boxes.push({
          id: op.attributes.getNamedItem('id')?.value ?? '',
          cId: op.attributes.getNamedItem('cId')?.value ?? '',
          req: op.attributes.getNamedItem('req')?.value == '1',
          val: op.attributes.getNamedItem('val')?.value ?? '',
          sel: op.attributes.getNamedItem('sel')?.value == '1',
          npid: strNpid != undefined ? +strNpid : null,
          text: op.innerHTML ?? ''
        })
      });

      pgRowMstr.render = this.getCheckBoxListRender(lbl, pgDataChk.boxes, pgDataChk.horiz == '1');
      pgRowMstr.searchContent = this.getCheckboxSearchContent(pgDataChk);
      this.pageRowsMaster.push(pgRowMstr);
      this.pageData.checkboxes.push(pgDataChk);
    });
  }

  getCheckboxSearchContent(pgDataChk: PageDataCheckBox): string {
    let searchStrs: string[] = [];
    searchStrs.push(pgDataChk.id, pgDataChk.name, pgDataChk.alias, pgDataChk.cId, pgDataChk.class, pgDataChk.em,
      pgDataChk.reem, pgDataChk.lbl, pgDataChk.vre);

    pgDataChk.boxes.forEach(b => { searchStrs.push(b.cId, b.val, b.text) });

    return searchStrs.join(" ");
  }

  getCheckBoxListRender(lbl: string, ops: PageDataListItem[], isHoriz: boolean): any {
    let items: PageDataCheckBoxModel[] = [];

    ops.forEach(op => {
      items.push({
        label: op.text,
        value: op.val,
        selected: op.sel
      })
    });

    return {
      label: lbl,
      items: items,
      class: isHoriz ? 'flex flex-wrap' : '',
      chkClass: isHoriz ? 'w-2' : 'mb-2',
      lblClass: isHoriz ? 'w-12' : 'mb-2'
    };

  }

  confirmCopyRow(row: PageDataTableRow) {
    this.confirmService.confirm({
      key: this.pageDataConfirmKey,
      message: 'Are you sure that you want to copy this Page Data object?',
      header: 'Copy Confirmation',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        this.copyRow(row);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'error', summary: 'Declined', detail: 'Page Data object has not been copied.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });

  }

  copyRow(row: PageDataTableRow) {
    let newCid = uuidv4();
    let newCompItemSel: number = 0;
    let newObjId: string = `Page${this.pageData.id}`;
    switch (row.element.toLowerCase()) {
      case 'script':
        newCompItemSel = 1;
        newObjId += `Script${this.pageRows.filter(p => p.element == 'Script').length + 1}`;
        break;
      case 'label':
        newCompItemSel = 2;
        newObjId += `Label${this.pageRows.filter(p => p.element == 'Label').length + 1}`;
        break;
      case 'href':
        newCompItemSel = 3;
        newObjId += `Href${this.pageRows.filter(p => p.element == 'Href').length + 1}`;
        break;
      case 'button':
        newCompItemSel = 4;
        newObjId += `Button${this.pageRows.filter(p => p.element == 'Button').length + 1}`;
        break;
      case 'unsorted list':
        newCompItemSel = 5;
        newObjId += `UList${this.pageRows.filter(p => p.element == 'Unsorted List').length + 1}`;
        break;
      case 'textbox':
        newCompItemSel = 6;
        newObjId += `TextBox${this.pageRows.filter(p => p.element == 'TextBox').length + 1}`;
        break;
      case 'dropdown':
        newCompItemSel = 7;
        newObjId += `Dropdown${this.pageRows.filter(p => p.element == 'Dropdown').length + 1}`;
        break;
      case 'radiolist':
        newCompItemSel = 8;
        newObjId += `RadioList${this.pageRows.filter(p => p.element == 'RadioList').length + 1}`;
        break;
      case 'checkboxlist':
        newCompItemSel = 9;
        newObjId += `CheckBoxList${this.pageRows.filter(p => p.element == 'CheckBoxList').length + 1}`;
        break;
      default:
        break;
    }

    this.pageRowsMaster.filter(m => m.id == row.id).forEach(mRow => {
      let copyTblRow: PageDataTableRow = JSON.parse(JSON.stringify(mRow));
      copyTblRow.id = newCid;
      copyTblRow.objectId = newObjId;
      copyTblRow.isSaved = true;

      switch (mRow.element.toLocaleLowerCase()) {
        case 'script':
          let origScrObj = this.pageData.scripts.find(s => s.cId == mRow.id && s.versionId == mRow.version);
          if (origScrObj) {
            let scriptObj = JSON.parse(JSON.stringify(origScrObj)) as PageDataScript;
            scriptObj.name = newObjId;
            scriptObj.cId = newCid;
            scriptObj.id = mRow.version == 0 ? (this.pageData.scripts.filter(pd => pd.versionId == 0).length + 1).toString() : '0';
            copyTblRow.searchContent = this.getScriptSearchContent(scriptObj);
            this.pageData.scripts.push(scriptObj);
            this.addScriptNode(scriptObj, this.replaceTagGuids(scriptObj.script, 0));
          }
          break;
        case 'label':
          let origLblObj = this.pageData.labels.find(l => l.cId == mRow.id && l.versionId == mRow.version);
          if (origLblObj) {
            let labelObj = JSON.parse(JSON.stringify(origLblObj)) as PageDataLabel;
            labelObj.name = newObjId;
            labelObj.cId = newCid;
            labelObj.id = mRow.version == 0 ? (this.pageData.labels.filter(pd => pd.versionId == 0).length + 1).toString() : '0';
            copyTblRow.searchContent = this.getLabelSearchContent(labelObj);
            this.pageData.labels.push(labelObj);
            this.addLabelNode(labelObj, this.replaceTagGuids(labelObj.label, 0));
          }
          break;
        case 'href':
          let origHrefObj = this.pageData.hrefs.find(pg => pg.cId == mRow.id && pg.versionId == mRow.version);
          if (origHrefObj) {
            let hrefObj = JSON.parse(JSON.stringify(origHrefObj)) as PageDataHref;
            hrefObj.name = newObjId;
            hrefObj.cId = newCid;
            hrefObj.id = mRow.version == 0 ? (this.pageData.hrefs.filter(pd => pd.versionId == 0).length + 1).toString() : '0';
            copyTblRow.searchContent = this.getHrefSearchContent(hrefObj);
            this.pageData.hrefs.push(hrefObj);
            this.addHrefNode(hrefObj, this.replaceTagGuids(hrefObj.hrf, 0));
          }
          break;
        case 'button':
          let origBtnObj = this.pageData.buttons.find(pg => pg.cId == mRow.id && pg.versionId == mRow.version);
          if (origBtnObj) {
            let buttonObj = JSON.parse(JSON.stringify(origBtnObj)) as PageDataButton;
            buttonObj.name = newObjId;
            buttonObj.cId = newCid;
            buttonObj.id = mRow.version == 0 ? (this.pageData.buttons.filter(pd => pd.versionId == 0).length + 1).toString() : '0';
            copyTblRow.render = this.getButtonRender(buttonObj.class, buttonObj.text);
            copyTblRow.searchContent = this.getButtonSearchContent(buttonObj);
            this.pageData.buttons.push(buttonObj);
            this.addButtonNode(buttonObj, this.replaceTagGuids(buttonObj.link, 0));
          }
          break;
        case 'unsorted list':
          let origULObj = this.pageData.unsortLists.find(pg => pg.cId == mRow.id && pg.versionId == mRow.version);
          if (origULObj) {
            let unsortObj = JSON.parse(JSON.stringify(origULObj)) as PageDataUnsortList;
            unsortObj.name = newObjId;
            unsortObj.cId = newCid;
            unsortObj.id = mRow.version == 0 ? (this.pageData.unsortLists.filter(pd => pd.versionId == 0).length + 1).toString() : '0';
            copyTblRow.render = this.getUnsortListRender(unsortObj.list);
            copyTblRow.searchContent = this.getUnsortSearchContent(unsortObj);
            this.pageData.unsortLists.push(unsortObj);
            this.addUnsortNode(unsortObj);
          }
          break;
        case 'textbox':
          let origTxtObj = this.pageData.textBoxes.find(pg => pg.cId == mRow.id && pg.versionId == mRow.version);
          if (origTxtObj) {
            let textObj = JSON.parse(JSON.stringify(origTxtObj)) as PageDataText;
            textObj.name = newObjId;
            textObj.cId = newCid;
            textObj.id = mRow.version == 0 ? (this.pageData.textBoxes.filter(pd => pd.versionId == 0).length + 1).toString() : '0';
            copyTblRow.render = this.getTextBoxRender(textObj.lbl, textObj.copy);
            copyTblRow.searchContent = this.getTextboxSearchContent(textObj);
            this.pageData.textBoxes.push(textObj);
            this.addTextNode(textObj, this.replaceTagGuids(textObj.copy, 0));
          }
          break;
        case 'dropdown':
          let origDDObj = this.pageData.dropdowns.find(d => d.cId == mRow.id && d.versionId == mRow.version);
          if (origDDObj) {
            let ddObj = JSON.parse(JSON.stringify(origDDObj)) as PageDataDD;
            ddObj.name = newObjId;
            ddObj.cId = newCid;
            ddObj.id = mRow.version == 0 ? (this.pageData.dropdowns.filter(d => d.versionId == 0).length + 1).toString() : '0';
            copyTblRow.render = this.getDropdownRender(ddObj.lbl, ddObj.ddItems);
            copyTblRow.searchContent = this.getDDSearchContent(ddObj);
            this.pageData.dropdowns.push(ddObj);
            this.addDropdownNode(ddObj);
          }
          break;
        case 'radiolist':
          let origRdoObj = this.pageData.radios.find(r => r.cId == mRow.id && r.versionId == mRow.version);
          if (origRdoObj) {
            let radioObj = JSON.parse(JSON.stringify(origRdoObj)) as PageDataRadio;
            radioObj.name = newObjId;
            radioObj.cId = newCid;
            radioObj.id = mRow.version == 0 ? (this.pageData.radios.filter(d => d.versionId == 0).length + 1).toString() : '0';
            copyTblRow.render = this.getRadioListRender(radioObj.lbl, radioObj.radios, radioObj.horiz == '1');
            copyTblRow.searchContent = this.getRadioSearchContent(radioObj);
            this.pageData.radios.push(radioObj);
            this.addRadioNode(radioObj);
          }
          break;
        case 'checkboxlist':
          let origChkObj = this.pageData.checkboxes.find(r => r.cId == mRow.id && r.versionId == mRow.version);
          if (origChkObj) {
            let checkObj = JSON.parse(JSON.stringify(origChkObj)) as PageDataCheckBox;
            checkObj.name = newObjId;
            checkObj.cId = newCid;
            checkObj.id = mRow.version == 0 ? (this.pageData.checkboxes.filter(d => d.versionId == 0).length + 1).toString() : '0';
            copyTblRow.render = this.getCheckBoxListRender(checkObj.lbl, checkObj.boxes, checkObj.horiz == '1');
            copyTblRow.searchContent = this.getCheckboxSearchContent(checkObj);
            this.pageData.checkboxes.push(checkObj);
            this.addCheckBoxNode(checkObj);
          }
          break;
        default:
          break;
      }

      let mstrIdx: number = this.getMasterPageRowIndex(newCompItemSel);
      this.pageRowsMaster.splice(mstrIdx, 0, copyTblRow);

    });

    let mstrRow = this.pageRowsMaster.find(m => m.id == newCid && m.version == 0);
    if (mstrRow) {
      let idx: number = this.getPageRowIndex(newCompItemSel);
      this.pageRows.splice(idx, 0, mstrRow);
      this.langSelects.splice(idx, 0, 0);
    }

    this.savePageXML(false);
    this.toastService.add({
      severity: 'success',
      summary: 'Copied',
      detail: `Page Data object successfully copied. ${row.id}`
    });
  }

  addNewComponent() {
    if (this.newCompItemSel == null) {
      this.toastService.add({
        severity: 'error',
        summary: 'Select Component',
        detail: 'Must first select a component type.'
      }, 'center');
      return;
    }
    if (this.newCompItemCnt < 1) {
      this.toastService.add({
        severity: 'error',
        summary: 'Invalid Count',
        detail: 'Number must be greater than 0.'
      }, 'center');
      this.newCompItemCnt = 1;
      return;
    }

    this.addNewBlankComponent(this.newCompItemSel, this.newCompItemCnt);

    this.newCompItemCnt = 1;
    this.newCompItemSel = null;
    this.showAddNew = false;
  }

  addNewBlankComponent(newCompItemSel: number, newCompItemCnt: number) {
    for (let i: number = 0; i < newCompItemCnt; i++) {

      let data: any = {};
      let newTblRow: PageDataTableRow = {
        id: uuidv4(),
        name: '',
        objectId: `Page${this.pageData.id}`,
        element: '',
        preview: '',
        render: '',
        version: 0,
        searchContent: '',
        isSaved: true
      };

      switch (newCompItemSel) {
        case 1:
          newTblRow.element = 'Script';
          newTblRow.objectId += `Script${this.pageRows.filter(p => p.element == 'Script').length + 1}`;
          newTblRow.preview = this.getObjectDefaultPreview(newTblRow.element)
          data = this.createNewScriptObj(newTblRow);
          newTblRow.searchContent = this.getScriptSearchContent(data);
          this.pageData.scripts.push(data);
          this.addScriptNode(data, newTblRow.preview);
          break;
        case 2:
          newTblRow.element = 'Label';
          newTblRow.objectId += `Label${this.pageRows.filter(p => p.element == 'Label').length + 1}`;
          newTblRow.preview = this.getObjectDefaultPreview(newTblRow.element)
          newTblRow.searchContent = newTblRow.preview;
          data = this.createNewLabelObj(newTblRow);
          newTblRow.searchContent = this.getLabelSearchContent(data);
          this.pageData.labels.push(data);
          this.addLabelNode(data, newTblRow.preview);
          break;
        case 3:
          newTblRow.element = 'Href';
          newTblRow.objectId += `Href${this.pageRows.filter(p => p.element == 'Href').length + 1}`;
          newTblRow.preview = this.getObjectDefaultPreview(newTblRow.element)
          newTblRow.searchContent = newTblRow.preview;
          data = this.createNewHrefObj(newTblRow);
          newTblRow.searchContent = this.getHrefSearchContent(data);
          this.pageData.hrefs.push(data);
          this.addHrefNode(data, '');
          break;
        case 4:
          newTblRow.element = 'Button';
          newTblRow.objectId += `Button${this.pageRows.filter(p => p.element == 'Button').length + 1}`;
          newTblRow.preview = this.getObjectDefaultPreview(newTblRow.element)
          newTblRow.searchContent = newTblRow.preview;
          data = (this.createNewButtonObj(newTblRow)) as PageDataButton;
          let btnRender = this.getButtonRender('', this.buttonDefault);
          data.render = btnRender;
          newTblRow.render = btnRender;
          newTblRow.searchContent = this.getButtonSearchContent(data);
          this.pageData.buttons.push(data);
          this.addButtonNode(data, '');
          break;
        case 5:
          newTblRow.element = 'Unsorted List';
          newTblRow.objectId += `UList${this.pageRows.filter(p => p.element == 'Unsorted List').length + 1}`;
          newTblRow.preview = this.getObjectDefaultPreview(newTblRow.element)
          newTblRow.searchContent = newTblRow.preview;
          data = this.createNewUnsortObj(newTblRow);
          let ulRender = this.getUnsortListRender((data as PageDataUnsortList).list);
          data.render = ulRender;
          newTblRow.render = ulRender;
          newTblRow.searchContent = this.getUnsortSearchContent(data);
          this.pageData.unsortLists.push(data);
          this.addUnsortNode(data);
          break;
        case 6:
          newTblRow.element = 'TextBox';
          newTblRow.objectId += `TextBox${this.pageRows.filter(p => p.element == 'TextBox').length + 1}`;
          newTblRow.preview = this.getObjectDefaultPreview(newTblRow.element)
          newTblRow.searchContent = newTblRow.preview;
          data = this.createNewTextboxObj(newTblRow);
          let txtRender = this.getTextBoxRender((data as PageDataText).lbl, data.prev);
          data.render = txtRender;
          newTblRow.render = txtRender;
          newTblRow.searchContent = this.getTextboxSearchContent(data);
          this.pageData.textBoxes.push(data);
          this.addTextNode(data, newTblRow.preview);
          break;
        case 7:
          newTblRow.element = 'Dropdown';
          newTblRow.objectId += `Dropdown${this.pageRows.filter(p => p.element == 'Dropdown').length + 1}`;
          newTblRow.preview = this.getObjectDefaultPreview(newTblRow.element)
          newTblRow.searchContent = newTblRow.preview;
          data = this.createNewDropDownObj(newTblRow);
          let ddRender = this.getDropdownRender((data as PageDataDD).lbl, (data as PageDataDD).ddItems);
          data.render = ddRender;
          newTblRow.render = ddRender;
          newTblRow.searchContent = this.getDDSearchContent(data);
          this.pageData.dropdowns.push(data);
          this.addDropdownNode(data);
          break;
        case 8:
          newTblRow.element = 'RadioList';
          newTblRow.objectId += `RadioList${this.pageRows.filter(p => p.element == 'RadioList').length + 1}`;
          newTblRow.preview = this.getObjectDefaultPreview(newTblRow.element)
          newTblRow.searchContent = newTblRow.preview;
          data = this.createNewRadioObj(newTblRow);
          let dtRadio = data as PageDataRadio;
          let rdoRender = this.getRadioListRender(dtRadio.lbl, dtRadio.radios, dtRadio.horiz == '1');
          data.render = rdoRender;
          newTblRow.render = rdoRender;
          newTblRow.searchContent = this.getRadioSearchContent(data);
          this.pageData.radios.push(data);
          this.addRadioNode(data);
          break;
        case 9:
          newTblRow.element = 'CheckBoxList';
          newTblRow.objectId += `CheckBoxList${this.pageRows.filter(p => p.element == 'CheckBoxList').length + 1}`;
          newTblRow.preview = this.getObjectDefaultPreview(newTblRow.element)
          newTblRow.searchContent = newTblRow.preview;
          data = this.createNewCheckboxObj(newTblRow);
          let dtChk = data as PageDataCheckBox;
          let chkRender = this.getCheckBoxListRender(dtChk.lbl, dtChk.boxes, dtChk.horiz == '1');
          data.render = chkRender;
          newTblRow.render = chkRender;
          newTblRow.searchContent = this.getCheckboxSearchContent(data);
          this.pageData.checkboxes.push(data);
          this.addCheckBoxNode(data);
          break;
        default:
          break;
      }

      newTblRow = this.insertNewTableObject(newTblRow);

      let mstrIdx: number = this.getMasterPageRowIndex(newCompItemSel);
      let idx: number = this.getPageRowIndex(newCompItemSel);

      this.pageRowsMaster.splice(mstrIdx, 0, newTblRow);
      this.pageRows.splice(idx, 0, newTblRow);
      this.langSelects.splice(idx, 0, 0);
      this.savePageXML();

    }
  }

  getMasterPageRowIndex(newCompItemSel: number): number {
    let scriptIdx: number = -1;
    let labelIdx: number = -1;
    let hrefIdx: number = -1;
    let buttonIdx: number = -1;
    let unsortIdx: number = -1;
    let textIdx: number = -1;
    let ddIdx: number = -1;
    let radioIdx: number = -1;
    let checkIdx: number = -1;
    let masterIdx: number = -1;
    for (let i = 0; i < this.pageRowsMaster.length; i++) {
      let obj = this.pageRowsMaster[i];
      switch (obj.element.toLowerCase()) {
        case 'script':
          scriptIdx = i;
          break;
        case 'label':
          labelIdx = i;
          break;
        case 'href':
          hrefIdx = i;
          break;
        case 'button':
          buttonIdx = i;
          break;
        case 'unsorted list':
          unsortIdx = i;
          break;
        case 'textbox':
          textIdx = i;
          break;
        case 'dropdown':
          ddIdx = i;
          break;
        case 'radiolist':
          radioIdx = i;
          break;
        case 'checkboxlist':
          checkIdx = i;
          break;
        default:
          break;
      }
    }

    switch (newCompItemSel) {
      case 1:
        masterIdx = scriptIdx;
        break;
      case 2:
        masterIdx = labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 3:
        masterIdx = hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 4:
        masterIdx = buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 5:
        masterIdx = unsortIdx >= 0 ? unsortIdx : buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 6:
        masterIdx = textIdx >= 0 ? textIdx : unsortIdx >= 0 ? unsortIdx : buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 7:
        masterIdx = ddIdx >= 0 ? ddIdx : textIdx >= 0 ? textIdx : unsortIdx >= 0 ? unsortIdx : buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 8:
        masterIdx = radioIdx >= 0 ? radioIdx : ddIdx >= 0 ? ddIdx : textIdx >= 0 ? textIdx : unsortIdx >= 0 ? unsortIdx : buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 9:
        masterIdx = checkIdx >= 0 ? checkIdx : radioIdx >= 0 ? radioIdx : ddIdx >= 0 ? ddIdx : textIdx >= 0 ? textIdx : unsortIdx >= 0 ? unsortIdx : buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      default:
        break;
    }

    return (masterIdx + 1);
  }

  getPageRowIndex(newCompItemSel: number): number {
    let scriptIdx: number = -1;
    let labelIdx: number = -1;
    let hrefIdx: number = -1;
    let buttonIdx: number = -1;
    let unsortIdx: number = -1;
    let textIdx: number = -1;
    let ddIdx: number = -1;
    let radioIdx: number = -1;
    let checkIdx: number = -1;
    let pageRowIdx: number = -1;
    for (let i = 0; i < this.pageRows.length; i++) {
      let obj = this.pageRows[i];
      switch (obj.element.toLowerCase()) {
        case 'script':
          scriptIdx = i;
          break;
        case 'label':
          labelIdx = i;
          break;
        case 'href':
          hrefIdx = i;
          break;
        case 'button':
          buttonIdx = i;
          break;
        case 'unsorted list':
          unsortIdx = i;
          break;
        case 'textbox':
          textIdx = i;
          break;
        case 'dropdown':
          ddIdx = i;
          break;
        case 'radiolist':
          radioIdx = i;
          break;
        case 'checkboxlist':
          checkIdx = i;
          break;
        default:
          break;
      }
    }

    switch (newCompItemSel) {
      case 1:
        pageRowIdx = scriptIdx;
        break;
      case 2:
        pageRowIdx = labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 3:
        pageRowIdx = hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 4:
        pageRowIdx = buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 5:
        pageRowIdx = unsortIdx >= 0 ? unsortIdx : buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 6:
        pageRowIdx = textIdx >= 0 ? textIdx : unsortIdx >= 0 ? unsortIdx : buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 7:
        pageRowIdx = ddIdx >= 0 ? ddIdx : textIdx >= 0 ? textIdx : unsortIdx >= 0 ? unsortIdx : buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 8:
        pageRowIdx = radioIdx >= 0 ? radioIdx : ddIdx >= 0 ? ddIdx : textIdx >= 0 ? textIdx : unsortIdx >= 0 ? unsortIdx : buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      case 9:
        pageRowIdx = checkIdx >= 0 ? checkIdx : radioIdx >= 0 ? radioIdx : ddIdx >= 0 ? ddIdx : textIdx >= 0 ? textIdx : unsortIdx >= 0 ? unsortIdx : buttonIdx >= 0 ? buttonIdx : hrefIdx >= 0 ? hrefIdx : labelIdx >= 0 ? labelIdx : scriptIdx;
        break;
      default:
        break;
    }

    return (pageRowIdx + 1);
  }

  confirmDeleteRow(row: PageDataTableRow, index: number) {
    this.confirmService.confirm({
      key: this.pageDataConfirmKey,
      message: 'Are you sure that you want to delete this Page Data object?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        this.deleteRow(row, index);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'error', summary: 'Declined', detail: 'Page Data object has not been deleted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });

  }

  deleteRow(row: PageDataTableRow, index: number) {
    if (!row.isSaved) {
      let masterRow = this.pageRowsMaster.find(p => p.id == row.id && p.version == 0);
      if (masterRow) {
        this.langSelects[index] = 0;
        this.pageRows.splice(index, 1, masterRow);
        let masterRowIdx = this.pageRowsMaster.findIndex(p => p.id == row.id && p.version == row.version);
        if (masterRowIdx) this.pageRowsMaster.splice(masterRowIdx, 1);
      }
    }
    else {
      let nodes: NodeListOf<Element>;
      let rows: PageDataTableRow[] = this.pageRows.filter(r => r.id == row.id);
      let rowsMstr: PageDataTableRow[] = this.pageRowsMaster.filter(p => p.id == row.id);
      if (row.version == 0) {
        nodes = this.xmlDoc.querySelectorAll(`[cId="${row.id}"]`);
      }
      else {
        nodes = this.xmlDoc.querySelectorAll(`[cId="${row.id}"][versionId="${row.version}"]`);
        rowsMstr = rowsMstr.filter(r => r.version == row.version);
        rows = rows.filter(r => r.version = row.version);
      }

      rowsMstr.forEach(row => {
        let idx = this.pageRowsMaster.findIndex(p => p.id === row.id && p.version === row.version);
        if (idx > -1) {
          this.pageRowsMaster.splice(idx, 1);
        }
      });

      rows.forEach(row => {
        let idx = this.pageRows.findIndex(p => p.id === row.id);
        if (idx > -1) {
          this.pageRows.splice(idx, 1);
          this.langSelects.splice(idx, 1);
        }
      });

      nodes.forEach((node: Element) => {
        node.remove();
      });
      this.savePageXML();
    }
  }

  cleanAndRenderPreview(preview: string): string {
    if (!preview || preview.length == 0) return '';
    preview = preview.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
    preview = this.replaceTags(preview, 0);
    return preview;
  }

  replaceTags(preview: string, startIndex: number): string {
    if (preview.indexOf('{!', startIndex) > -1) {
      let endIdx = preview.indexOf('}', startIndex)
      let fieldGuid = preview.slice(preview.indexOf('{!', startIndex) + 2, endIdx);
      let fieldName = this.pageFields.find(p => p.fieldGUID == fieldGuid)?.fieldName;
      if (fieldName && fieldName.length > 0) {
        preview = preview.replace(`${fieldGuid}`, fieldName);
        endIdx = preview.indexOf('}', startIndex);
        return this.replaceTags(preview, endIdx + 1);
      }
    }
    return preview;
  }

  rowVersionChanged(versionId: number, pageData: PageDataTableRow, index: number) {
    let version = this.pageRowsMaster.find(p => p.id == pageData.id && p.version == versionId);
    if (version) {
      this.pageRows.splice(index, 1, version);
    }
    else {
      let newPg: PageDataTableRow = {
        id: pageData.id,
        name: '',
        objectId: pageData.objectId,
        element: pageData.element,
        preview: this.getObjectDefaultPreview(pageData.element),
        render: '',
        version: versionId,
        searchContent: '',
        isSaved: versionId == 0
      };

      newPg = this.insertNewTableObject(newPg);

      this.pageRows.splice(index, 1, newPg);
    }
  }

  getObjectDefaultPreview(element: string): string {
    let previewTxt: string = '';
    switch (element.toLowerCase()) {
      case 'script':
        previewTxt = this.scriptDefault;
        break;

      case 'label':
      case 'href':
      case 'textbox':
        previewTxt = this.labelDefault;
        break;

      case 'button':
        previewTxt = this.buttonDefault;
        break;

      case 'dropdown':
      case 'radiolist':
      case 'checkboxlist':
      case 'unsorted list':
        previewTxt = this.ddDefault;
        break;

      default:
        break;
    }

    return previewTxt;
  }

  insertNewTableObject(newPg: PageDataTableRow) {
    switch (newPg.element.toLowerCase()) {
      case 'script':
        this.insertNewTableScript(newPg);
        break;

      case 'label':
        this.insertNewTableLabel(newPg);
        break;

      case 'href':
        this.insertNewTableHref(newPg);
        break;

      case 'button':
        this.insertNewTableButton(newPg);
        break;

      case 'unsorted list':
        newPg = this.insertNewTableUnsort(newPg);
        break;

      case 'textbox':
        newPg = this.insertNewTableTextBox(newPg);
        break;

      case 'dropdown':
        newPg = this.insertNewTableDropDown(newPg);
        break;

      case 'radiolist':
        newPg = this.insertNewTableRadio(newPg);
        break;

      case 'checkboxlist':
        newPg = this.insertNewTableCheckboxes(newPg);
        break;

      default:
        break;
    }

    return newPg;
  }

  insertNewTableScript(newPg: PageDataTableRow) {
    let pgScript: PageDataScript = this.createNewScriptObj(newPg);
    this.pageData.scripts.push(pgScript);
  }

  createNewScriptObj(newPg: PageDataTableRow): PageDataScript {
    return {
      x: '0',
      y: '0',
      h: '0',
      w: '0',
      m: '0',
      bgc: '0xFFFFFF',
      id: newPg.version == 0 ? (this.pageData.scripts.length + 1).toString() : '0',
      name: newPg.objectId,
      alias: '',
      c: '1',
      cId: newPg.id,
      mC: '5000',
      req: '',
      class: '',
      versionId: newPg.version,
      script: newPg.preview
    };
  }

  insertNewTableLabel(newPg: PageDataTableRow) {
    let pgLabel: PageDataLabel = this.createNewLabelObj(newPg);
    this.pageData.labels.push(pgLabel);
  }

  createNewLabelObj(newPg: PageDataTableRow): PageDataLabel {
    return {
      x: '0',
      y: '0',
      h: '0',
      w: '0',
      m: '0',
      bgc: '0xFFFFFF',
      id: newPg.version == 0 ? (this.pageData.labels.length + 1).toString() : '0',
      name: newPg.objectId,
      alias: '',
      c: '1',
      cId: newPg.id,
      mC: '5000',
      req: '',
      class: '',
      versionId: newPg.version,
      prefill: '',
      label: newPg.preview
    };
  }

  insertNewTableHref(newPg: PageDataTableRow) {
    let pgHref: PageDataHref = this.createNewHrefObj(newPg);
    this.pageData.hrefs.push(pgHref);
  }

  createNewHrefObj(newPg: PageDataTableRow): PageDataHref {
    return {
      x: '0',
      y: '0',
      h: '0',
      w: '0',
      m: '0',
      bgc: '0xFFFFFF',
      id: newPg.version == 0 ? (this.pageData.hrefs.length + 1).toString() : '0',
      name: newPg.objectId,
      alias: '',
      c: '1',
      cId: newPg.id,
      mC: '5000',
      req: '',
      class: '',
      versionId: newPg.version,
      hrf: '#',
      tgt: '_blank',
      img: '',
      onclick: '',
      text: 'click here'
    };
  }

  insertNewTableButton(newPg: PageDataTableRow) {
    let pgBtn: PageDataButton = this.createNewButtonObj(newPg);
    this.pageData.buttons.push(pgBtn);
  }

  createNewButtonObj(newPg: PageDataTableRow): PageDataButton {
    return {
      x: '0',
      y: '0',
      h: '0',
      w: '0',
      m: '0',
      bgc: '0xFFFFFF',
      id: newPg.version == 0 ? (this.pageData.buttons.length + 1).toString() : '0',
      name: newPg.objectId,
      alias: '',
      c: '1',
      cId: newPg.id,
      mC: '5000',
      req: '0',
      class: '',
      versionId: newPg.version,
      text: this.buttonDefault,
      npid: '',
      link: '',
      ba: '0',
      autoLogin: '0',
      bas: []
    };
  }

  insertNewTableUnsort(newPg: PageDataTableRow): PageDataTableRow {
    let pgUL: PageDataUnsortList = this.createNewUnsortObj(newPg);

    newPg.render = this.getUnsortListRender(pgUL.list);
    this.pageData.unsortLists.push(pgUL);

    return newPg;
  }

  createNewUnsortObj(newPg: PageDataTableRow): PageDataUnsortList {
    return {
      x: '0',
      y: '0',
      h: '0',
      w: '0',
      m: '0',
      bgc: '0xFFFFFF',
      id: newPg.version == 0 ? (this.pageData.unsortLists.length + 1).toString() : '0',
      name: newPg.objectId,
      alias: '',
      c: '1',
      cId: newPg.id,
      mC: '',
      req: '1',
      class: '',
      versionId: newPg.version,
      list: []
    };
  }

  insertNewTableTextBox(newPg: PageDataTableRow): PageDataTableRow {
    let pgText: PageDataText = this.createNewTextboxObj(newPg);
    newPg.render = this.getTextBoxRender(pgText.lbl, pgText.copy);
    this.pageData.textBoxes.push(pgText);

    return newPg;
  }

  createNewTextboxObj(newPg: PageDataTableRow): PageDataText {
    return {
      x: '0',
      y: '0',
      h: '0',
      w: '0',
      m: '0',
      bgc: '0xFFFFFF',
      id: newPg.version == 0 ? (this.pageData.textBoxes.length + 1).toString() : '0',
      name: newPg.objectId,
      alias: '',
      c: '1',
      cId: newPg.id,
      mC: '',
      req: '1',
      class: '',
      versionId: newPg.version,
      copy: newPg.preview,
      dcId: '',
      lbl: '',
      dn: '',
      for: '',
      cOrder: '',
      tm: '0',
      type: 'text',
      fReq: '0',
      crypt: '0',
      em: '',
      dcEM: '',
      v: null,
      reem: '',
      disabled: '0',
      cvv: '0',
      cv: '',
      crhc: '',
      crht: '',
      sv: '0',
      crv: '0',
      rgxValidations: [],
      serverValidations: []
    };

  }

  insertNewTableDropDown(newPg: PageDataTableRow): PageDataTableRow {
    let pgDD: PageDataDD = this.createNewDropDownObj(newPg);
    this.pageData.dropdowns.push(pgDD);
    newPg.render = this.getDropdownRender(pgDD.lbl, pgDD.ddItems);
    return newPg;
  }

  createNewDropDownObj(newPg: PageDataTableRow): PageDataDD {
    return {
      x: '0',
      y: '0',
      h: '0',
      w: '0',
      m: '0',
      bgc: '0xFFFFFF',
      id: newPg.version == 0 ? (this.pageData.dropdowns.length + 1).toString() : '0',
      name: newPg.objectId,
      alias: '',
      c: '1',
      cId: newPg.id,
      mC: '',
      req: '1',
      class: '',
      versionId: newPg.version,
      lbl: '',
      dn: '',
      for: '',
      cOrder: '',
      fReq: '0',
      em: '',
      v: null,
      vre: '',
      reqByFld: '',
      reem: '',
      disabled: '0',
      sv: '0',
      serverValidations: [],
      ddItems: []
    };
  }

  insertNewTableRadio(newPg: PageDataTableRow): PageDataTableRow {
    let pgRdo: PageDataRadio = this.createNewRadioObj(newPg);
    this.pageData.radios.push(pgRdo);
    newPg.render = this.getRadioListRender(pgRdo.lbl, pgRdo.radios, pgRdo.horiz == '1');
    return newPg;
  }

  createNewRadioObj(newPg: PageDataTableRow): PageDataRadio {
    return {
      x: '0',
      y: '0',
      h: '0',
      w: '0',
      m: '0',
      bgc: '0xFFFFFF',
      id: newPg.version == 0 ? (this.pageData.radios.length + 1).toString() : '0',
      name: newPg.objectId,
      alias: '',
      c: '1',
      cId: newPg.id,
      mC: '',
      req: '1',
      class: '',
      versionId: newPg.version,
      lbl: '',
      dn: '',
      for: '',
      cOrder: '',
      fReq: '0',
      em: '',
      v: null,
      vre: '',
      reqByFld: '',
      reem: '',
      disabled: '0',
      horiz: '0',
      radios: []
    };
  }

  insertNewTableCheckboxes(newPg: PageDataTableRow): PageDataTableRow {
    let pgChk: PageDataCheckBox = this.createNewCheckboxObj(newPg);
    this.pageData.checkboxes.push(pgChk);
    newPg.render = this.getCheckBoxListRender(pgChk.lbl, pgChk.boxes, pgChk.horiz == '1');
    return newPg;
  }

  createNewCheckboxObj(newPg: PageDataTableRow): PageDataCheckBox {
    return {
      x: '0',
      y: '0',
      h: '0',
      w: '0',
      m: '0',
      bgc: '0xFFFFFF',
      id: newPg.version == 0 ? (this.pageData.checkboxes.length + 1).toString() : '0',
      name: newPg.objectId,
      alias: '',
      c: '1',
      cId: newPg.id,
      mC: '',
      req: '1',
      class: '',
      versionId: newPg.version,
      lbl: '',
      dn: '',
      for: '',
      cOrder: '',
      fReq: '0',
      em: '',
      v: null,
      vre: '',
      reqByFld: '',
      reem: '',
      disabled: '0',
      horiz: '0',
      boxes: []
    };
  }

  mainVersionChanged(event: number) {
    for (let i = 0; i < this.langSelects.length; i++) {
      this.langSelects[i] = event;
      this.rowVersionChanged(event, this.pageRows[i], i);
    }
  }

  setupRowLanguages() {
    this.langSelects = [];
    this.pageRows.forEach(pg => {
      this.langSelects.push(pg.version);
    });
  }

  getDataObject(type: string, page: PageDataTableRow) {

    switch (type) {
      case 'script':
        let script = this.pageData.scripts.find(s => s.cId == page.id && s.versionId == page.version);

        if (script) return script;
        else return null;
        break;

      case 'label':
        let label = this.pageData.labels.find(l => l.cId == page.id && l.versionId == page.version);

        if (label) return label;
        else return null;
        break;

      case 'href':
        let href = this.pageData.hrefs.find(h => h.cId == page.id && h.versionId == page.version);

        if (href) return href;
        else return null;
        break;

      case 'button':
        let btn = this.pageData.buttons.find(b => b.cId == page.id && b.versionId == page.version);

        if (btn) return btn;
        else return null;

        break;

      case 'unsortlist':
        let ul = this.pageData.unsortLists.find(u => u.cId == page.id && u.versionId == page.version);
        if (ul) return ul;
        else return null;
        break;

      case 'text':
        let txtBox = this.pageData.textBoxes.find(t => t.cId == page.id && t.versionId == page.version);
        if (txtBox) return txtBox;
        else return null;
        break;

      case 'dropdown':
        let dd = this.pageData.dropdowns.find(d => d.cId == page.id && d.versionId == page.version);
        if (dd) return dd;
        else return null;
        break;

      case 'radiolist':
        let rdo = this.pageData.radios.find(r => r.cId == page.id && r.versionId == page.version);
        if (rdo) return rdo;
        else return null;
        break;

      case 'checkboxes':
        let chk = this.pageData.checkboxes.find(c => c.cId == page.id && c.versionId == page.version);
        if (chk) return chk;
        else return null;
        break;

      default:
        return null;
        break;
    }
  }

  getTxtDupItems(pageData: PageDataTableRow) {
    let items = this.pageData.textBoxes.filter(t => t.versionId == 0 && t.cId != pageData.id);
    let dups: NameValuePair[] = items.map(item => {
      return {
        name: item.name,
        value: item.cId
      }
    });
    return dups;
  }

  cancelRowEdit(page: PageDataTableRow) {
    this.dt.expandedRowKeys[page.id] = false;
  }

  saveRow(dataObj: any) {
    switch (dataObj.element) {
      case 'script':
        this.saveScriptRow(dataObj.data);
        break;

      case 'label':
        this.saveLabelRow(dataObj.data);
        break;

      case 'href':
        this.saveHrefRow(dataObj.data);
        break;

      case 'button':
        this.saveButtonRow(dataObj.data);
        break;

      case 'unsorted list':
        this.saveUnsortRow(dataObj.data);
        break;

      case 'textbox':
        this.saveTextBoxRow(dataObj.data);
        break;

      case 'dropdown':
        this.saveDropdownRow(dataObj.data);
        break;

      case 'radiolist':
        this.saveRadioRow(dataObj.data);
        break;

      case 'checkboxlist':
        this.saveCheckboxRow(dataObj.data);
        break;

      default:
        break;
    }
  }

  saveScriptRow(data: PageDataScript) {
    let row = this.pageRows.find(r => r.id === data.cId);
    let rowMstr = this.pageRowsMaster.find(r => r.id === data.cId && r.version === data.versionId);

    if (row) {
      row.isSaved = true;
      row.objectId = data.name;
      row.name = data.alias;
      row.preview = data.script;
      row.searchContent = this.getScriptSearchContent(data);
    }
    if (rowMstr) {
      rowMstr.isSaved = true;
      rowMstr.objectId = data.name;
      rowMstr.name = data.alias;
      rowMstr.preview = data.script;
      rowMstr.searchContent = this.getScriptSearchContent(data);
    }

    let node: Element | null = null;
    if (data.versionId == 0) {
      node = this.xmlDoc.querySelector(`script[cId="${data.cId}"]`);
    }
    else {
      node = this.xmlDoc.querySelector(`versions script[cId="${data.cId}"][versionId="${data.versionId}"]`);
    }

    let scriptHtml = this.replaceTagGuids(data.script, 0);

    if (node) {
      node.setAttribute('name', data.name);
      node.setAttribute('alias', data.alias);
      node.innerHTML = scriptHtml;
    }
    else {
      this.addScriptNode(data, scriptHtml);
    }

    this.savePageXML();
    if (row) this.cancelRowEdit(row);
  }

  addScriptNode(data: PageDataScript, innerHtml: string) {
    let scriptNode = this.xmlDoc.createElement('script');
    scriptNode.setAttribute('x', data.x);
    scriptNode.setAttribute('y', data.y);
    scriptNode.setAttribute('h', data.h);
    scriptNode.setAttribute('w', data.w);
    scriptNode.setAttribute('m', data.m);
    scriptNode.setAttribute('fs', '0');
    scriptNode.setAttribute('bgc', data.bgc);
    scriptNode.setAttribute('id', data.id);
    scriptNode.setAttribute('name', data.name);
    scriptNode.setAttribute('alias', data.alias);
    scriptNode.setAttribute('c', data.c);
    scriptNode.setAttribute('cId', data.cId);
    scriptNode.setAttribute('mC', data.mC);
    scriptNode.setAttribute('req', data.req);
    scriptNode.setAttribute('class', data.class);
    scriptNode.innerHTML = innerHtml;

    let lastScript: Element | null | undefined = null;
    if (data.versionId == 0) {
      lastScript = Array.from(this.xmlDoc.querySelectorAll('page > script')).pop();
      if (lastScript) lastScript.insertAdjacentElement('afterend', scriptNode);
      else {
        let page = this.xmlDoc.querySelector('page');
        if (page) page.insertAdjacentElement('afterbegin', scriptNode);
      }
    }
    else {
      scriptNode.setAttribute('versionId', data.versionId.toString());
      lastScript = Array.from(this.xmlDoc.querySelectorAll('versions > script')).pop();
      if (lastScript) lastScript.insertAdjacentElement('afterend', scriptNode);
      else {
        let versions = this.xmlDoc.querySelector('page versions');
        if (versions) versions.insertAdjacentElement('afterbegin', scriptNode);
      }
    }
  }

  saveLabelRow(data: PageDataLabel) {
    let row = this.pageRows.find(r => r.id === data.cId);
    let rowMstr = this.pageRowsMaster.find(r => r.id === data.cId && r.version === data.versionId);

    if (row) {
      row.isSaved = true;
      row.objectId = data.name;
      row.name = data.alias;
      row.preview = data.label;
      row.searchContent = this.getLabelSearchContent(data);
    }
    if (rowMstr) {
      rowMstr.isSaved = true;
      rowMstr.objectId = data.name;
      rowMstr.name = data.alias;
      rowMstr.preview = data.label;
      rowMstr.searchContent = this.getLabelSearchContent(data);
    }

    let node: Element | null = null;
    if (data.versionId == 0) {
      node = this.xmlDoc.querySelector(`lbl[cId="${data.cId}"]`);
    }
    else {
      node = this.xmlDoc.querySelector(`versions lbl[cId="${data.cId}"][versionId="${data.versionId}"]`);
    }

    let labelHtml = this.replaceTagGuids(data.label, 0);

    if (node) {
      node.setAttribute('name', data.name);
      node.setAttribute('alias', data.alias);
      node.setAttribute('class', data.class);
      node.setAttribute('mC', data.mC);
      node.setAttribute('req', data.req);
      node.innerHTML = labelHtml;
    }
    else {
      this.addLabelNode(data, labelHtml);
    }

    this.savePageXML();
    if (row) this.cancelRowEdit(row);
  }

  addLabelNode(data: PageDataLabel, innerHtml: string) {
    let labelNode = this.xmlDoc.createElement('lbl');
    labelNode.setAttribute('x', data.x);
    labelNode.setAttribute('y', data.y);
    labelNode.setAttribute('h', data.h);
    labelNode.setAttribute('w', data.w);
    labelNode.setAttribute('m', data.m);
    labelNode.setAttribute('fs', '0');
    labelNode.setAttribute('bgc', data.bgc);
    labelNode.setAttribute('id', data.id);
    labelNode.setAttribute('name', data.name);
    labelNode.setAttribute('alias', data.alias);
    labelNode.setAttribute('c', data.c);
    labelNode.setAttribute('cId', data.cId);
    labelNode.setAttribute('mC', data.mC);
    labelNode.setAttribute('req', data.req);
    labelNode.setAttribute('class', data.class);
    labelNode.innerHTML = innerHtml;

    let lastLabel: Element | null | undefined = null;
    if (data.versionId == 0) {
      lastLabel = Array.from(this.xmlDoc.querySelectorAll('page > lbl')).pop();
      if (lastLabel) lastLabel.insertAdjacentElement('afterend', labelNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page > script')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page') ?? undefined;
          insPos = 'afterbegin'
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, labelNode);
      }
    }
    else {
      labelNode.setAttribute('versionId', data.versionId.toString());
      lastLabel = Array.from(this.xmlDoc.querySelectorAll('versions > lbl')).pop();
      if (lastLabel) lastLabel.insertAdjacentElement('afterend', labelNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page versions script')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page versions') ?? undefined;
          insPos = 'afterbegin';
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, labelNode);
      }
    }
  }

  saveHrefRow(data: PageDataHref) {
    let row = this.pageRows.find(r => r.id === data.cId);
    let rowMstr = this.pageRowsMaster.find(r => r.id === data.cId && r.version === data.versionId);

    if (row) {
      row.isSaved = true;
      row.objectId = data.name;
      row.name = data.alias;
      row.preview = data.text;
      row.searchContent = this.getHrefSearchContent(data);
    }
    if (rowMstr) {
      rowMstr.isSaved = true;
      rowMstr.objectId = data.name;
      rowMstr.name = data.alias;
      rowMstr.preview = data.text;
      rowMstr.searchContent = this.getHrefSearchContent(data);
    }

    let node: Element | null = null;
    if (data.versionId == 0) {
      node = this.xmlDoc.querySelector(`a[cId="${data.cId}"]`);
    }
    else {
      node = this.xmlDoc.querySelector(`versions a[cId="${data.cId}"][versionId="${data.versionId}"]`);
    }

    let hrefReplaced = this.replaceTagGuids(data.hrf, 0);

    if (node) {
      node.setAttribute('name', data.name);
      node.setAttribute('alias', data.alias);
      node.setAttribute('class', data.class);
      node.setAttribute('mC', data.mC);
      node.setAttribute('req', data.req);
      node.setAttribute('hrf', hrefReplaced);
      node.setAttribute('tgt', data.tgt);
      node.setAttribute('img', data.img);
      node.setAttribute('onclick', data.onclick);
      node.innerHTML = data.text;
    }
    else {
      this.addHrefNode(data, hrefReplaced);
    }

    this.savePageXML();
    if (row) this.cancelRowEdit(row);

  }

  addHrefNode(data: PageDataHref, hrefReplaced: string) {
    let hrefNode = this.xmlDoc.createElement('a');
    hrefNode.setAttribute('x', data.x);
    hrefNode.setAttribute('y', data.y);
    hrefNode.setAttribute('h', data.h);
    hrefNode.setAttribute('w', data.w);
    hrefNode.setAttribute('m', data.m);
    hrefNode.setAttribute('fs', '0');
    hrefNode.setAttribute('bgc', data.bgc);
    hrefNode.setAttribute('id', data.id);
    hrefNode.setAttribute('name', data.name);
    hrefNode.setAttribute('alias', data.alias);
    hrefNode.setAttribute('c', data.c);
    hrefNode.setAttribute('cId', data.cId);
    hrefNode.setAttribute('mC', data.mC);
    hrefNode.setAttribute('req', data.req);
    hrefNode.setAttribute('class', data.class);
    hrefNode.setAttribute('hrf', hrefReplaced);
    hrefNode.setAttribute('tgt', data.tgt);
    hrefNode.setAttribute('img', data.img);
    hrefNode.setAttribute('onclick', data.onclick);
    hrefNode.innerHTML = data.text;

    let lastHref: Element | null | undefined = null;
    if (data.versionId == 0) {
      lastHref = Array.from(this.xmlDoc.querySelectorAll('page > a')).pop();
      if (lastHref) lastHref.insertAdjacentElement('afterend', hrefNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page > lbl')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page') ?? undefined;
          insPos = 'afterbegin'
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, hrefNode);
      }
    }
    else {
      hrefNode.setAttribute('versionId', data.versionId.toString());
      lastHref = Array.from(this.xmlDoc.querySelectorAll('versions > a')).pop();
      if (lastHref) lastHref.insertAdjacentElement('afterend', hrefNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page versions lbl')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page versions') ?? undefined;
          insPos = 'afterbegin';
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, hrefNode);
      }
    }
  }

  saveButtonRow(data: PageDataButton) {
    let row = this.pageRows.find(r => r.id === data.cId);
    let rowMstr = this.pageRowsMaster.find(r => r.id === data.cId && r.version === data.versionId);

    if (row) {
      row.isSaved = true;
      row.objectId = data.name;
      row.name = data.alias;
      row.preview = data.text;
      row.render = this.getButtonRender(data.class, data.text);
      row.searchContent = this.getButtonSearchContent(data);
    }
    if (rowMstr) {
      rowMstr.isSaved = true;
      rowMstr.objectId = data.name;
      rowMstr.name = data.alias;
      rowMstr.preview = data.text;
      rowMstr.render = this.getButtonRender(data.class, data.text);
      rowMstr.searchContent = this.getButtonSearchContent(data);
    }

    let node: Element | null = null;
    if (data.versionId == 0) {
      node = this.xmlDoc.querySelector(`btn[cId="${data.cId}"]`);
    }
    else {
      node = this.xmlDoc.querySelector(`versions btn[cId="${data.cId}"][versionId="${data.versionId}"]`);
    }

    let destUrl = this.replaceTagGuids(data.link, 0);

    if (node) {
      node.setAttribute('id', data.id);
      node.setAttribute('name', data.name);
      node.setAttribute('alias', data.alias);
      node.setAttribute('c', data.c);
      node.setAttribute('cId', data.cId);
      node.setAttribute('mC', data.mC);
      node.setAttribute('req', data.req);
      node.setAttribute('class', data.class);
      node.setAttribute('npid', data.npid);
      node.setAttribute('link', destUrl);
      // node.setAttribute('img', data.img);
      node.setAttribute('ba', data.ba);
      node.setAttribute('autoLogin', data.autoLogin);

      let value = node.querySelector('value');
      if (value) value.remove();

      value = this.xmlDoc.createElement('value');
      value.innerHTML = data.text;

      node.appendChild(value);

      let bas = node.querySelector('bas');
      if (bas) bas.remove();

      if (data.ba == "1" && data.bas.length > 0) {
        bas = this.xmlDoc.createElement('bas');
        data.bas.forEach((ba: any) => {
          let baOp = this.xmlDoc.createElement('baOp');
          baOp.setAttribute('id', ba.id);
          baOp.setAttribute('cId', ba.cId);
          baOp.setAttribute('vId', ba.vId);
          baOp.setAttribute('vName', ba.vName);
          baOp.setAttribute('em', ba.em);
          bas?.append(baOp);
        });
        node.appendChild(bas);
      }
    }
    else {
      this.addButtonNode(data, destUrl);
    }

    this.savePageXML();
    if (row) this.cancelRowEdit(row);

  }

  addButtonNode(data: PageDataButton, destUrl: string) {
    let btnNode = this.xmlDoc.createElement('btn');
    btnNode.setAttribute('x', data.x);
    btnNode.setAttribute('y', data.y);
    btnNode.setAttribute('h', data.h);
    btnNode.setAttribute('w', data.w);
    btnNode.setAttribute('m', data.m);
    btnNode.setAttribute('bgc', data.bgc);
    btnNode.setAttribute('id', data.id);
    btnNode.setAttribute('name', data.name);
    btnNode.setAttribute('alias', data.alias);
    btnNode.setAttribute('c', data.c);
    btnNode.setAttribute('cId', data.cId);
    btnNode.setAttribute('mC', data.mC);
    btnNode.setAttribute('req', data.req);
    btnNode.setAttribute('class', data.class);
    btnNode.setAttribute('npid', data.npid);
    btnNode.setAttribute('link', destUrl);
    // btnNode.setAttribute('img', data.img);
    btnNode.setAttribute('ba', data.ba);
    btnNode.setAttribute('autoLogin', data.autoLogin);

    let value = this.xmlDoc.createElement('value');
    value.innerHTML = data.text;

    btnNode.appendChild(value);

    if (data.ba == "1" && data.bas.length > 0) {
      let bas = this.xmlDoc.createElement('bas');
      data.bas.forEach((ba: any) => {
        let baOp = this.xmlDoc.createElement('baOp');
        baOp.setAttribute('id', ba.id);
        baOp.setAttribute('cId', ba.cId);
        baOp.setAttribute('vId', ba.vId);
        baOp.setAttribute('vName', ba.vName);
        baOp.setAttribute('em', ba.em);
        bas?.append(baOp);
      });
      btnNode.appendChild(bas);
    }

    let lastBtn: Element | null | undefined = null;
    if (data.versionId == 0) {
      lastBtn = Array.from(this.xmlDoc.querySelectorAll('page > btn')).pop();
      if (lastBtn) lastBtn.insertAdjacentElement('afterend', btnNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page > lbl')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page') ?? undefined;
          insPos = 'afterbegin'
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, btnNode);
      }
    }
    else {
      btnNode.setAttribute('versionId', data.versionId.toString());
      lastBtn = Array.from(this.xmlDoc.querySelectorAll('versions > btn')).pop();
      if (lastBtn) lastBtn.insertAdjacentElement('afterend', btnNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page versions lbl')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page versions') ?? undefined;
          insPos = 'afterbegin';
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, btnNode);
      }
    }
  }

  saveUnsortRow(data: PageDataUnsortList) {
    let row = this.pageRows.find(r => r.id === data.cId);
    let rowMstr = this.pageRowsMaster.find(r => r.id === data.cId && r.version === data.versionId);

    if (row) {
      row.isSaved = true;
      row.objectId = data.name;
      row.name = data.alias;
      row.preview = 'Unsorted List';
      row.render = this.getUnsortListRender(data.list);
      row.searchContent = this.getUnsortSearchContent(data);
    }
    if (rowMstr) {
      rowMstr.isSaved = true;
      rowMstr.objectId = data.name;
      rowMstr.name = data.alias;
      rowMstr.preview = 'Unsorted List';
      rowMstr.render = this.getUnsortListRender(data.list);
      rowMstr.searchContent = this.getUnsortSearchContent(data);
    }

    let node: Element | null = null;
    if (data.versionId == 0) {
      node = this.xmlDoc.querySelector(`page > ulist[cId="${data.cId}"]`);
    }
    else {
      node = this.xmlDoc.querySelector(`versions ulist[cId="${data.cId}"][versionId="${data.versionId}"]`);
    }

    if (node) {
      node.setAttribute('id', data.id);
      node.setAttribute('name', data.name);
      node.setAttribute('alias', data.alias);
      node.setAttribute('c', data.c);
      node.setAttribute('cId', data.cId);
      node.setAttribute('mC', data.mC);
      node.setAttribute('req', data.req);
      node.setAttribute('class', data.class);

      let ulist = node.querySelector('ulist');
      if (ulist) ulist.remove();

      ulist = this.xmlDoc.createElement('ulist');
      for (let i: number = 0; i < data.list.length; i++) {
        let itm = data.list[i];
        let li = this.xmlDoc.createElement('li');
        li.setAttribute('id', i.toString());
        li.setAttribute('cId', itm.cId);
        li.setAttribute('req', '1');
        li.innerHTML = itm.text;
        ulist?.appendChild(li);
      }
      node.appendChild(ulist);
    }
    else {
      this.addUnsortNode(data);
    }

    this.savePageXML();
    if (row) this.cancelRowEdit(row);

  }

  addUnsortNode(data: PageDataUnsortList) {
    let ulNode = this.xmlDoc.createElement('ulist');
    ulNode.setAttribute('x', data.x);
    ulNode.setAttribute('y', data.y);
    ulNode.setAttribute('h', data.h);
    ulNode.setAttribute('w', data.w);
    ulNode.setAttribute('m', data.m);
    ulNode.setAttribute('bgc', data.bgc);
    ulNode.setAttribute('id', data.id);
    ulNode.setAttribute('name', data.name);
    ulNode.setAttribute('alias', data.alias);
    ulNode.setAttribute('c', data.c);
    ulNode.setAttribute('cId', data.cId);
    ulNode.setAttribute('mC', data.mC);
    ulNode.setAttribute('req', data.req);
    ulNode.setAttribute('class', data.class);

    let ulist = this.xmlDoc.createElement('ulist');
    for (let i: number = 0; i < data.list.length; i++) {
      let itm = data.list[i];
      let li = this.xmlDoc.createElement('li');
      li.setAttribute('id', i.toString());
      li.setAttribute('cId', itm.cId);
      li.setAttribute('req', itm.req ? '1' : '0');
      li.innerHTML = itm.text;
      ulist?.appendChild(li);
    }
    ulNode.appendChild(ulist);

    let lastUL: Element | null | undefined = null;
    if (data.versionId == 0) {
      lastUL = Array.from(this.xmlDoc.querySelectorAll('page > ulist')).pop();
      if (lastUL) lastUL.insertAdjacentElement('afterend', ulNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page > btn')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page') ?? undefined;
          insPos = 'afterbegin'
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, ulNode);
      }
    }
    else {
      ulNode.setAttribute('versionId', data.versionId.toString());
      lastUL = Array.from(this.xmlDoc.querySelectorAll('versions > ulist')).pop();
      if (lastUL) lastUL.insertAdjacentElement('afterend', ulNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page versions btn')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page versions') ?? undefined;
          insPos = 'afterbegin';
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, ulNode);
      }
    }
  }

  saveTextBoxRow(data: PageDataText) {
    let row = this.pageRows.find(r => r.id === data.cId);
    let rowMstr = this.pageRowsMaster.find(r => r.id === data.cId && r.version === data.versionId);

    if (row) {
      row.isSaved = true;
      row.objectId = data.name;
      row.name = data.alias;
      row.preview = data.copy;
      row.render = this.getTextBoxRender(data.lbl, data.copy);
      row.searchContent = this.getTextboxSearchContent(data);
    }
    if (rowMstr) {
      rowMstr.isSaved = true;
      rowMstr.objectId = data.name;
      rowMstr.name = data.alias;
      rowMstr.preview = data.copy;
      rowMstr.render = this.getTextBoxRender(data.lbl, data.copy);
      rowMstr.searchContent = this.getTextboxSearchContent(data);
    }

    let node: Element | null = null;
    if (data.versionId == 0) {
      node = this.xmlDoc.querySelector(`form txt[cId="${data.cId}"]`);
    }
    else {
      node = this.xmlDoc.querySelector(`versions txt[cId="${data.cId}"][versionId="${data.versionId}"]`);
    }

    let textCopy = this.replaceTagGuids(data.copy, 0);

    if (node) {
      node.setAttribute('lbl', data.lbl);
      node.setAttribute('lbltid', (data.lbl as string).length > 0 ? '1' : '-1');
      node.setAttribute('v', data.v?.toString() ?? '');
      node.setAttribute('vre', this.validations.find(v => v.validationID == data.v)?.regularExpression ?? '');
      node.setAttribute('reem', data.reem);
      node.setAttribute('dcId', data.dcId);
      node.setAttribute('dcEM', data.dcEM);
      node.setAttribute('em', data.em);
      node.setAttribute('dn', data.dn);
      node.setAttribute('fReq', data.fReq);
      node.setAttribute('crv', data.crv);
      node.setAttribute('crht', data.crht);
      node.setAttribute('crhc', data.crhc);
      node.setAttribute('sv', data.sv);
      node.setAttribute('crypt', data.crypt);
      node.setAttribute('cvv', data.cvv);
      node.setAttribute('cv', data.cv);
      node.setAttribute('type', data.type);
      node.setAttribute('disabled', data.disabled);
      node.setAttribute('cOrder', data.cOrder);
      node.setAttribute('id', data.id);
      node.setAttribute('name', data.name);
      node.setAttribute('alias', data.alias);
      node.setAttribute('c', data.c);
      node.setAttribute('cId', data.cId);
      node.setAttribute('mC', data.mC);
      node.setAttribute('req', data.req);
      node.setAttribute('class', data.class);

      let value = node.querySelector('value');
      if (value) value.remove();

      value = this.xmlDoc.createElement('value');
      value.setAttribute('for', data.for);
      value.innerHTML = textCopy;

      node.appendChild(value);

      let crvs = node.querySelector('crvs');
      if (crvs) crvs.remove();
      let svs = node.querySelector('svs');
      if (svs) svs.remove();

      if (data.crv == "1" && data.rgxValidations.length > 0) {
        crvs = this.xmlDoc.createElement('crvs');
        data.rgxValidations.forEach((rgx: any) => {
          let crvOp = this.xmlDoc.createElement('crvOp');
          crvOp.setAttribute('id', rgx.id);
          crvOp.setAttribute('cId', rgx.cId);
          crvOp.setAttribute('re', rgx.re);
          crvOp.setAttribute('em', rgx.em);
          crvOp.setAttribute('vc', rgx.vc);
          crvOp.setAttribute('ivc', rgx.ivc);
          crvs?.appendChild(crvOp);
        });
        node.appendChild(crvs);
      }

      if (data.sv == "1" && data.serverValidations.length > 0) {
        svs = this.xmlDoc.createElement('svs');
        data.serverValidations.forEach((sv: any) => {
          let svOp = this.xmlDoc.createElement('svOp');
          svOp.setAttribute('id', sv.id);
          svOp.setAttribute('cId', sv.cId);
          svOp.setAttribute('vId', sv.vId);
          svOp.setAttribute('vName', sv.vName);
          svOp.setAttribute('em', sv.em);
          svs?.append(svOp);
        });
        node.appendChild(svs);
      }
    }
    else {
      this.addTextNode(data, textCopy);
    }

    this.savePageXML();
    if (row) this.cancelRowEdit(row);
  }

  addTextNode(data: PageDataText, textCopy: string) {
    let textNode = this.xmlDoc.createElement('txt');
    textNode.setAttribute('lbl', data.lbl);
    textNode.setAttribute('lbltid', (data.lbl as string).length > 0 ? '1' : '-1');
    textNode.setAttribute('v', data.v?.toString() ?? '');
    textNode.setAttribute('vre', this.validations.find(v => v.validationID == data.v)?.regularExpression ?? '');
    textNode.setAttribute('reem', data.reem);
    textNode.setAttribute('dcId', data.dcId);
    textNode.setAttribute('dcEM', data.dcEM);
    textNode.setAttribute('em', data.em);
    textNode.setAttribute('dn', data.dn);
    textNode.setAttribute('fReq', data.fReq);
    textNode.setAttribute('crv', data.crv);
    textNode.setAttribute('crht', data.crht);
    textNode.setAttribute('crhc', data.crhc);
    textNode.setAttribute('sv', data.sv);
    textNode.setAttribute('crypt', data.crypt);
    textNode.setAttribute('cvv', data.cvv);
    textNode.setAttribute('cv', data.cv);
    textNode.setAttribute('type', data.type);
    textNode.setAttribute('disabled', data.disabled);
    textNode.setAttribute('cOrder', data.cOrder);
    textNode.setAttribute('x', data.x);
    textNode.setAttribute('y', data.y);
    textNode.setAttribute('h', data.h);
    textNode.setAttribute('w', data.w);
    textNode.setAttribute('m', data.m);
    textNode.setAttribute('bgc', data.bgc);
    textNode.setAttribute('id', data.id);
    textNode.setAttribute('name', data.name);
    textNode.setAttribute('alias', data.alias);
    textNode.setAttribute('c', data.c);
    textNode.setAttribute('cId', data.cId);
    textNode.setAttribute('mC', data.mC);
    textNode.setAttribute('req', data.req);
    textNode.setAttribute('class', data.class);
    let value = this.xmlDoc.createElement('value');
    value.setAttribute('for', data.for);
    value.innerHTML = textCopy;

    textNode.appendChild(value);

    if (data.crv == "1" && data.rgxValidations.length > 0) {
      let crvs = this.xmlDoc.createElement('crvs');
      data.rgxValidations.forEach((rgx: any) => {
        let crvOp = this.xmlDoc.createElement('crvOp');
        crvOp.setAttribute('id', rgx.id);
        crvOp.setAttribute('cId', rgx.cId);
        crvOp.setAttribute('re', rgx.re);
        crvOp.setAttribute('em', rgx.em);
        crvOp.setAttribute('vc', rgx.vc);
        crvOp.setAttribute('ivc', rgx.ivc);
        crvs?.appendChild(crvOp);
      });
      textNode.appendChild(crvs);
    }

    if (data.sv == "1" && data.serverValidations.length > 0) {
      let svs = this.xmlDoc.createElement('svs');
      data.serverValidations.forEach((sv: any) => {
        let svOp = this.xmlDoc.createElement('svOp');
        svOp.setAttribute('id', sv.id);
        svOp.setAttribute('cId', sv.cId);
        svOp.setAttribute('vId', sv.vId);
        svOp.setAttribute('vName', sv.vName);
        svOp.setAttribute('em', sv.em);
        svs?.append(svOp);
      });
      textNode.appendChild(svs);
    }



    let lastText: Element | null | undefined = null;
    if (data.versionId == 0) {
      lastText = Array.from(this.xmlDoc.querySelectorAll('page > form > txt')).pop();
      if (lastText) lastText.insertAdjacentElement('afterend', textNode);
      else {
        let form = this.xmlDoc.querySelector('page > form');
        if (form) form.insertAdjacentElement('afterbegin', textNode);
      }
    }
    else {
      textNode.setAttribute('versionId', data.versionId.toString());
      lastText = Array.from(this.xmlDoc.querySelectorAll('versions > txt')).pop();
      if (lastText) lastText.insertAdjacentElement('afterend', textNode);
      else {
        let versions = this.xmlDoc.querySelector('page versions');
        if (versions) versions.insertAdjacentElement('afterbegin', textNode);
      }
    }
  }

  saveDropdownRow(data: PageDataDD) {
    let row = this.pageRows.find(r => r.id === data.cId);
    let rowMstr = this.pageRowsMaster.find(r => r.id === data.cId && r.version === data.versionId);

    if (row) {
      row.isSaved = true;
      row.objectId = data.name;
      row.name = data.alias;
      row.preview = data.lbl;
      row.render = this.getDropdownRender(data.lbl, data.ddItems);
      row.searchContent = this.getDDSearchContent(data);
    }
    if (rowMstr) {
      rowMstr.isSaved = true;
      rowMstr.objectId = data.name;
      rowMstr.name = data.alias;
      rowMstr.preview = data.lbl;
      rowMstr.render = this.getDropdownRender(data.lbl, data.ddItems);
      rowMstr.searchContent = this.getDDSearchContent(data);
    }

    let node: Element | null = null;
    if (data.versionId == 0) {
      node = this.xmlDoc.querySelector(`form dd[cId="${data.cId}"]`);
    }
    else {
      node = this.xmlDoc.querySelector(`versions dd[cId="${data.cId}"][versionId="${data.versionId}"]`);
    }

    if (node) {
      node.setAttribute('id', data.id);
      node.setAttribute('name', data.name);
      node.setAttribute('alias', data.alias);
      node.setAttribute('c', data.c);
      node.setAttribute('cId', data.cId);
      node.setAttribute('mC', data.mC);
      node.setAttribute('req', data.req);
      node.setAttribute('class', data.class);
      node.setAttribute('lbl', data.lbl);
      node.setAttribute('v', data.v?.toString() ?? '0');
      node.setAttribute('vre', this.validations.find(v => v.validationID == data.v)?.regularExpression ?? '');
      node.setAttribute('reem', data.reem);
      node.setAttribute('em', data.em);
      node.setAttribute('dn', data.dn);
      node.setAttribute('fReq', data.fReq);
      node.setAttribute('reqByFld', data.reqByFld);
      node.setAttribute('sv', data.sv);
      node.setAttribute('disabled', data.disabled);
      node.setAttribute('cOrder', data.cOrder);

      let value = node.querySelector('value');
      if (value) value.remove();

      value = this.xmlDoc.createElement('value');
      value.setAttribute('for', data.for);
      value.innerHTML = `{!${data.dn}}`;

      node.appendChild(value);

      let svs = node.querySelector('svs');
      if (svs) svs.remove();

      if (data.sv == "1" && data.serverValidations.length > 0) {
        svs = this.xmlDoc.createElement('svs');
        data.serverValidations.forEach((sv: any) => {
          let svOp = this.xmlDoc.createElement('svOp');
          svOp.setAttribute('id', sv.id);
          svOp.setAttribute('cId', sv.cId);
          svOp.setAttribute('vId', sv.vId);
          svOp.setAttribute('vName', sv.vName);
          svOp.setAttribute('em', sv.em);
          svs?.append(svOp);
        });
        node.appendChild(svs);
      }

      let ops = node.querySelector('ops');
      if (ops) ops.remove();

      ops = this.xmlDoc.createElement('ops');
      for (let i: number = 0; i < data.ddItems.length; i++) {
        let itm = data.ddItems[i];
        let op = this.xmlDoc.createElement('op');
        op.setAttribute('id', i.toString());
        op.setAttribute('cId', itm.cId);
        op.setAttribute('req', itm.req ? '1' : '0');
        op.setAttribute('val', itm.val);
        op.setAttribute('sel', itm.sel ? '1' : '0');
        op.setAttribute('npid', itm.npid ? itm.npid.toString() : '');
        op.innerHTML = itm.text;
        ops?.appendChild(op);
      }
      node.appendChild(ops);
    }
    else {
      this.addDropdownNode(data);
    }

    this.savePageXML();
    if (row) this.cancelRowEdit(row);
  }

  addDropdownNode(data: PageDataDD) {
    let ddNode = this.xmlDoc.createElement('dd');
    ddNode.setAttribute('x', data.x);
    ddNode.setAttribute('y', data.y);
    ddNode.setAttribute('h', data.h);
    ddNode.setAttribute('w', data.w);
    ddNode.setAttribute('m', data.m);
    ddNode.setAttribute('bgc', data.bgc);
    ddNode.setAttribute('id', data.id);
    ddNode.setAttribute('name', data.name);
    ddNode.setAttribute('alias', data.alias);
    ddNode.setAttribute('c', data.c);
    ddNode.setAttribute('cId', data.cId);
    ddNode.setAttribute('mC', data.mC);
    ddNode.setAttribute('req', data.req);
    ddNode.setAttribute('class', data.class);
    ddNode.setAttribute('lbl', data.lbl);
    ddNode.setAttribute('v', data.v?.toString() ?? '');
    ddNode.setAttribute('vre', this.validations.find(v => v.validationID == data.v)?.regularExpression ?? '');
    ddNode.setAttribute('reem', data.reem);
    ddNode.setAttribute('em', data.em);
    ddNode.setAttribute('dn', data.dn);
    ddNode.setAttribute('fReq', data.fReq);
    ddNode.setAttribute('reqByFld', data.reqByFld);
    ddNode.setAttribute('sv', data.sv);
    ddNode.setAttribute('disabled', data.disabled);
    ddNode.setAttribute('cOrder', data.cOrder);

    let value = this.xmlDoc.createElement('value');
    value.setAttribute('for', data.for);
    value.innerHTML = `{!${data.dn}}`;

    ddNode.appendChild(value);

    if (data.sv == "1" && data.serverValidations.length > 0) {
      let svs = this.xmlDoc.createElement('svs');
      data.serverValidations.forEach((sv: any) => {
        let svOp = this.xmlDoc.createElement('svOp');
        svOp.setAttribute('id', sv.id);
        svOp.setAttribute('cId', sv.cId);
        svOp.setAttribute('vId', sv.vId);
        svOp.setAttribute('vName', sv.vName);
        svOp.setAttribute('em', sv.em);
        svs?.append(svOp);
      });
      ddNode.appendChild(svs);
    }

    let ops = this.xmlDoc.createElement('ops');
    for (let i: number = 0; i < data.ddItems.length; i++) {
      let itm = data.ddItems[i];
      let op = this.xmlDoc.createElement('op');
      op.setAttribute('id', i.toString());
      op.setAttribute('cId', itm.cId);
      op.setAttribute('req', itm.req ? '1' : '0');
      op.setAttribute('val', itm.val);
      op.setAttribute('sel', itm.sel ? '1' : '0');
      op.setAttribute('npid', itm.npid ? itm.npid.toString() : '');
      op.innerHTML = itm.text;
      ops?.appendChild(op);
    }
    ddNode.appendChild(ops);

    let lastDD: Element | null | undefined = null;
    if (data.versionId == 0) {
      lastDD = Array.from(this.xmlDoc.querySelectorAll('page > form > dd')).pop();
      if (lastDD) lastDD.insertAdjacentElement('afterend', ddNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page > form > txt')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page form') ?? undefined;
          insPos = 'afterbegin'
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, ddNode);
      }
    }
    else {
      ddNode.setAttribute('versionId', data.versionId.toString());
      lastDD = Array.from(this.xmlDoc.querySelectorAll('versions > dd')).pop();
      if (lastDD) lastDD.insertAdjacentElement('afterend', ddNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page versions txt')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page versions') ?? undefined;
          insPos = 'afterbegin';
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, ddNode);
      }
    }
  }

  saveRadioRow(data: PageDataRadio) {
    let row = this.pageRows.find(r => r.id === data.cId);
    let rowMstr = this.pageRowsMaster.find(r => r.id === data.cId && r.version === data.versionId);

    if (row) {
      row.isSaved = true;
      row.objectId = data.name;
      row.name = data.alias;
      row.preview = data.lbl;
      row.render = this.getRadioListRender(data.lbl, data.radios, data.horiz == '1');
      row.searchContent = this.getRadioSearchContent(data);
    }
    if (rowMstr) {
      rowMstr.isSaved = true;
      rowMstr.objectId = data.name;
      rowMstr.name = data.alias;
      rowMstr.preview = data.lbl;
      rowMstr.render = this.getRadioListRender(data.lbl, data.radios, data.horiz == '1');
      rowMstr.searchContent = this.getRadioSearchContent(data);
    }

    let node: Element | null = null;
    if (data.versionId == 0) {
      node = this.xmlDoc.querySelector(`form rl[cId="${data.cId}"]`);
    }
    else {
      node = this.xmlDoc.querySelector(`versions rl[cId="${data.cId}"][versionId="${data.versionId}"]`);
    }

    if (node) {
      node.setAttribute('id', data.id);
      node.setAttribute('name', data.name);
      node.setAttribute('alias', data.alias);
      node.setAttribute('c', data.c);
      node.setAttribute('cId', data.cId);
      node.setAttribute('mC', data.mC);
      node.setAttribute('req', data.req);
      node.setAttribute('class', data.class);
      node.setAttribute('lbl', data.lbl);
      node.setAttribute('v', data.v?.toString() ?? '0');
      node.setAttribute('vre', this.validations.find(v => v.validationID == data.v)?.regularExpression ?? '');
      node.setAttribute('reem', data.reem);
      node.setAttribute('em', data.em);
      node.setAttribute('dn', data.dn);
      node.setAttribute('fReq', data.fReq);
      node.setAttribute('horiz', data.horiz);
      node.setAttribute('reqByFld', data.reqByFld);
      node.setAttribute('disabled', data.disabled);
      node.setAttribute('cOrder', data.cOrder);

      let value = node.querySelector('value');
      if (value) value.remove();

      value = this.xmlDoc.createElement('value');
      value.setAttribute('for', data.for);
      value.innerHTML = `{!${data.dn}}`;

      node.appendChild(value);

      let ops = node.querySelector('ops');
      if (ops) ops.remove();

      ops = this.xmlDoc.createElement('ops');
      for (let i: number = 0; i < data.radios.length; i++) {
        let itm = data.radios[i];
        let op = this.xmlDoc.createElement('op');
        op.setAttribute('id', i.toString());
        op.setAttribute('cId', itm.cId);
        op.setAttribute('req', itm.req ? '1' : '0');
        op.setAttribute('val', itm.val);
        op.setAttribute('sel', itm.sel ? '1' : '0');
        op.setAttribute('npid', itm.npid ? itm.npid.toString() : '');
        op.innerHTML = itm.text;
        ops?.appendChild(op);
      }
      node.appendChild(ops);
    }
    else {
      this.addRadioNode(data);
    }

    this.savePageXML();
    if (row) this.cancelRowEdit(row);
  }

  addRadioNode(data: PageDataRadio) {
    let rdoNode = this.xmlDoc.createElement('rl');
    rdoNode.setAttribute('x', data.x);
    rdoNode.setAttribute('y', data.y);
    rdoNode.setAttribute('h', data.h);
    rdoNode.setAttribute('w', data.w);
    rdoNode.setAttribute('m', data.m);
    rdoNode.setAttribute('bgc', data.bgc);
    rdoNode.setAttribute('id', data.id);
    rdoNode.setAttribute('name', data.name);
    rdoNode.setAttribute('alias', data.alias);
    rdoNode.setAttribute('c', data.c);
    rdoNode.setAttribute('cId', data.cId);
    rdoNode.setAttribute('mC', data.mC);
    rdoNode.setAttribute('req', data.req);
    rdoNode.setAttribute('class', data.class);
    rdoNode.setAttribute('lbl', data.lbl);
    rdoNode.setAttribute('v', data.v?.toString() ?? '');
    rdoNode.setAttribute('vre', this.validations.find(v => v.validationID == data.v)?.regularExpression ?? '');
    rdoNode.setAttribute('reem', data.reem);
    rdoNode.setAttribute('em', data.em);
    rdoNode.setAttribute('dn', data.dn);
    rdoNode.setAttribute('fReq', data.fReq);
    rdoNode.setAttribute('horiz', data.horiz);
    rdoNode.setAttribute('reqByFld', data.reqByFld);
    rdoNode.setAttribute('disabled', data.disabled);
    rdoNode.setAttribute('cOrder', data.cOrder);

    let value = this.xmlDoc.createElement('value');
    value.setAttribute('for', data.for);
    value.innerHTML = `{!${data.dn}}`;

    rdoNode.appendChild(value);

    let ops = this.xmlDoc.createElement('ops');
    for (let i: number = 0; i < data.radios.length; i++) {
      let itm = data.radios[i];
      let op = this.xmlDoc.createElement('op');
      op.setAttribute('id', i.toString());
      op.setAttribute('cId', itm.cId);
      op.setAttribute('req', itm.req ? '1' : '0');
      op.setAttribute('val', itm.val);
      op.setAttribute('sel', itm.sel ? '1' : '0');
      op.setAttribute('npid', itm.npid ? itm.npid.toString() : '');
      op.innerHTML = itm.text;
      ops?.appendChild(op);
    }
    rdoNode.appendChild(ops);

    let lastRdo: Element | null | undefined = null;
    if (data.versionId == 0) {
      lastRdo = Array.from(this.xmlDoc.querySelectorAll('page > form > rl')).pop();
      if (lastRdo) lastRdo.insertAdjacentElement('afterend', rdoNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page > form > dd')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page form') ?? undefined;
          insPos = 'afterbegin'
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, rdoNode);
      }
    }
    else {
      rdoNode.setAttribute('versionId', data.versionId.toString());
      lastRdo = Array.from(this.xmlDoc.querySelectorAll('versions > rl')).pop();
      if (lastRdo) lastRdo.insertAdjacentElement('afterend', rdoNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page versions dd')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page versions') ?? undefined;
          insPos = 'afterbegin';
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, rdoNode);
      }
    }
  }

  saveCheckboxRow(data: PageDataCheckBox) {
    let row = this.pageRows.find(r => r.id === data.cId);
    let rowMstr = this.pageRowsMaster.find(r => r.id === data.cId && r.version === data.versionId);

    if (row) {
      row.isSaved = true;
      row.objectId = data.name;
      row.name = data.alias;
      row.preview = data.lbl;
      row.render = this.getCheckBoxListRender(data.lbl, data.boxes, data.horiz == '1');
      row.searchContent = this.getCheckboxSearchContent(data);
    }
    if (rowMstr) {
      rowMstr.isSaved = true;
      rowMstr.objectId = data.name;
      rowMstr.name = data.alias;
      rowMstr.preview = data.lbl;
      rowMstr.render = this.getCheckBoxListRender(data.lbl, data.boxes, data.horiz == '1');
      rowMstr.searchContent = this.getCheckboxSearchContent(data);
    }

    let node: Element | null = null;
    if (data.versionId == 0) {
      node = this.xmlDoc.querySelector(`form ckl[cId="${data.cId}"]`);
    }
    else {
      node = this.xmlDoc.querySelector(`versions ckl[cId="${data.cId}"][versionId="${data.versionId}"]`);
    }

    if (node) {
      node.setAttribute('id', data.id);
      node.setAttribute('name', data.name);
      node.setAttribute('alias', data.alias);
      node.setAttribute('c', data.c);
      node.setAttribute('cId', data.cId);
      node.setAttribute('mC', data.mC);
      node.setAttribute('req', data.req);
      node.setAttribute('class', data.class);
      node.setAttribute('lbl', data.lbl);
      node.setAttribute('v', data.v?.toString() ?? '0');
      node.setAttribute('vre', this.validations.find(v => v.validationID == data.v)?.regularExpression ?? '');
      node.setAttribute('reem', data.reem);
      node.setAttribute('em', data.em);
      node.setAttribute('dn', data.dn);
      node.setAttribute('fReq', data.fReq);
      node.setAttribute('horiz', data.horiz);
      node.setAttribute('reqByFld', data.reqByFld);
      node.setAttribute('disabled', data.disabled);
      node.setAttribute('cOrder', data.cOrder);

      let value = node.querySelector('value');
      if (value) value.remove();

      value = this.xmlDoc.createElement('value');
      value.setAttribute('for', data.for);
      value.innerHTML = `{!${data.dn}}`;

      node.appendChild(value);

      let ops = node.querySelector('ops');
      if (ops) ops.remove();

      ops = this.xmlDoc.createElement('ops');
      for (let i: number = 0; i < data.boxes.length; i++) {
        let itm = data.boxes[i];
        let op = this.xmlDoc.createElement('op');
        op.setAttribute('id', i.toString());
        op.setAttribute('cId', itm.cId);
        op.setAttribute('req', itm.req ? '1' : '0');
        op.setAttribute('val', itm.val);
        op.setAttribute('sel', itm.sel ? '1' : '0');
        op.setAttribute('npid', itm.npid ? itm.npid.toString() : '');
        op.innerHTML = itm.text;
        ops?.appendChild(op);
      }
      node.appendChild(ops);
    }
    else {
      this.addCheckBoxNode(data);
    }

    this.savePageXML();
    if (row) this.cancelRowEdit(row);

  }

  addCheckBoxNode(data: PageDataCheckBox) {
    let chkNode = this.xmlDoc.createElement('ckl');
    chkNode.setAttribute('x', data.x);
    chkNode.setAttribute('y', data.y);
    chkNode.setAttribute('h', data.h);
    chkNode.setAttribute('w', data.w);
    chkNode.setAttribute('m', data.m);
    chkNode.setAttribute('bgc', data.bgc);
    chkNode.setAttribute('id', data.id);
    chkNode.setAttribute('name', data.name);
    chkNode.setAttribute('alias', data.alias);
    chkNode.setAttribute('c', data.c);
    chkNode.setAttribute('cId', data.cId);
    chkNode.setAttribute('mC', data.mC);
    chkNode.setAttribute('req', data.req);
    chkNode.setAttribute('class', data.class);
    chkNode.setAttribute('lbl', data.lbl);
    chkNode.setAttribute('v', data.v?.toString() ?? '');
    chkNode.setAttribute('vre', this.validations.find(v => v.validationID == data.v)?.regularExpression ?? '');
    chkNode.setAttribute('reem', data.reem);
    chkNode.setAttribute('em', data.em);
    chkNode.setAttribute('dn', data.dn);
    chkNode.setAttribute('fReq', data.fReq);
    chkNode.setAttribute('horiz', data.horiz);
    chkNode.setAttribute('reqByFld', data.reqByFld);
    chkNode.setAttribute('disabled', data.disabled);
    chkNode.setAttribute('cOrder', data.cOrder);

    let value = this.xmlDoc.createElement('value');
    value.setAttribute('for', data.for);
    value.innerHTML = `{!${data.dn}}`;

    chkNode.appendChild(value);

    let ops = this.xmlDoc.createElement('ops');
    for (let i: number = 0; i < data.boxes.length; i++) {
      let itm = data.boxes[i];
      let op = this.xmlDoc.createElement('op');
      op.setAttribute('id', i.toString());
      op.setAttribute('cId', itm.cId);
      op.setAttribute('req', itm.req ? '1' : '0');
      op.setAttribute('val', itm.val);
      op.setAttribute('sel', itm.sel ? '1' : '0');
      op.setAttribute('npid', itm.npid ? itm.npid.toString() : '');
      op.innerHTML = itm.text;
      ops?.appendChild(op);
    }
    chkNode.appendChild(ops);

    let lastChk: Element | null | undefined = null;
    if (data.versionId == 0) {
      lastChk = Array.from(this.xmlDoc.querySelectorAll('page > form > ckl')).pop();
      if (lastChk) lastChk.insertAdjacentElement('afterend', chkNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page > form > rl')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page form') ?? undefined;
          insPos = 'afterbegin'
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, chkNode);
      }
    }
    else {
      chkNode.setAttribute('versionId', data.versionId.toString());
      lastChk = Array.from(this.xmlDoc.querySelectorAll('versions > ckl')).pop();
      if (lastChk) lastChk.insertAdjacentElement('afterend', chkNode);
      else {
        let insPos: InsertPosition = 'afterend';
        let lastNode = Array.from(this.xmlDoc.querySelectorAll('page versions rl')).pop();
        if (!lastNode) {
          lastNode = this.xmlDoc.querySelector('page versions') ?? undefined;
          insPos = 'afterbegin';
        }
        if (lastNode) lastNode.insertAdjacentElement(insPos, chkNode);
      }
    }
  }

  replaceTagGuids(script: string, startIndex: number): string {
    if (script.indexOf('{!', startIndex) > -1) {
      let endIdx = script.indexOf('}', startIndex);
      let fieldName = script.slice(script.indexOf('{!', startIndex) + 2, endIdx);
      let fieldGuid = this.pageFields.find(p => p.fieldName == fieldName)?.fieldGUID;
      if (fieldGuid && fieldGuid.length > 0) {
        script = script.replace(`${fieldName}`, fieldGuid);
        endIdx = script.indexOf('}', startIndex);
        return this.replaceTagGuids(script, endIdx + 1);
      }
    }
    return script;
  }

  savePageXML(showToast: boolean = true) {
    let body = {
      pageId: +(this.pageData.pid),
      campaignId: +(this.selectedCampaign?.campaignId ?? 0),
      customerGuid: this.selectedPortfolio?.customerGuid ?? '',
      pageXml: this.createPageXML()
    };

    let postSub = this.apiService.post(`pagedata/update/xml`, body)
      .subscribe({
        next: () => {
          if (showToast) {
            this.toastService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Page Data successfully added/updated.'
            });
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save page XML data. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }

  createPageXML(): string {
    const serializer = new XMLSerializer();
    return serializer.serializeToString(this.xmlDoc);
  }

}

export class PageDataCheckBoxModel {
  label: string = '';
  value: string = '';
  selected: boolean = false;
}
