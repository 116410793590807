import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-faq',
  templateUrl: './partner-faq.component.html',
  styleUrls: ['./partner-faq.component.scss']
})
export class PartnerFaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
