import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { combineLatest, Subscription } from 'rxjs';
import { Campaign } from 'src/app/models/campaign';
import { PortfolioItem } from 'src/app/models/customer-item';
import { CampaignSeedFormGroup } from 'src/app/models/form-groups';
import { LookupModel } from 'src/app/models/lookup-model';
import { CampaignSeedCustomGrid } from 'src/app/models/seeds';
import { State } from 'src/app/models/states';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { HelperService } from 'src/app/services/helper.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { ProviderService } from 'src/app/services/provider.service';
import { GuidService } from 'src/app/services/guid.service';
import { CustomGridComponent } from 'src/app/components/custom-grid/custom-grid.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-campaign-seed',
  templateUrl: './campaign-seed.component.html',
  styleUrls: ['./campaign-seed.component.scss']
})
export class CampaignSeedComponent implements OnInit, OnDestroy {

  editSeedPageId: number = 49;
  hasEditAccess: boolean = false;

  guidsLoaded: boolean = true;
  showList: boolean = false;
  showAddForm: boolean = false;
  seedFormLoaded: boolean = false;

  bcItems: MenuItem[] = [];
  subscriptions: Subscription[] = [];
  states: State[] = [];
  loanAmounts: LookupModel[] = [];
  
  selectedPortfolio: PortfolioItem | null = null;
  selectedCampaign: Campaign | null = null;
  selectedSeed: CampaignSeedCustomGrid | null = null;

  addSeedTitle: string = '';
  saveSeedBtn: string = '';

  seedForm: FormGroup<CampaignSeedFormGroup> = {} as FormGroup;

  @ViewChild('cgSeeds') cgSeeds: CustomGridComponent = {} as CustomGridComponent;

  private readonly pageBaseBc: string = 'Seeds';

  constructor(
    private portfolioService: PortfolioService,
    private campaignService: CampaignService,
    private providerService: ProviderService,
    private apiService: ApiService,
    private guidService: GuidService,
    private breadCrumbService: BreadcrumbService,
    private helperService: HelperService,
    private toastService: ToastService,
    private userService: UserService,
    private navService: NavService) { }

  ngOnInit(): void {
    this.states = this.helperService.usStates();

    this.subscriptions.push(
      combineLatest([this.portfolioService.portfolio$, this.campaignService.selectedCampaign$])
      .subscribe({
        next: ([p, c]) => {
          this.selectedPortfolio = p;
          this.selectedCampaign = c;

          if (p && c) {
            this.guidsLoaded = true;
            this.showList = true;
            this.getLookups();
          }
          else {
            setTimeout(() => {                
              if (!this.selectedPortfolio || !this.selectedCampaign) {                
                this.guidsLoaded = false;
              }              
            }, 500);
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get customer and/or campaign'
          });
          console.error(err);
        }
      })      
    );
    this.hasEditAccess = this.userService.hasPageAccess(this.editSeedPageId);

    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Campaigns', routerLink: ['/campaign'], command: () => { this.navService.setLeftNavSelection('Campaign'); } },
      { label: this.pageBaseBc }
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getLookups() {
    this.subscriptions.push(
      this.providerService.customerLookups$.subscribe({
      next: (lookups: any) => {
        this.loanAmounts = lookups.loanAmounts as LookupModel[];
      },
      error: (err: any) => {

      }
    })
    );    
  }

  editSeed(seed: CampaignSeedCustomGrid | null) {    
    if (!this.hasEditAccess) {
      this.toastService.add({
        severity: 'error',
        summary: 'Access Denied',
        detail: 'Invalid security access to edit Campaign Seed.'
      }, 'center');
      return;
    } 

    this.selectedSeed = seed;  

    let newLbl: string = '';
    if (seed == null) {
      this.addSeedTitle = 'Add New Seed';
      this.saveSeedBtn = 'Add Seed';
      newLbl = 'Seed Add';
    }
    else {
      this.addSeedTitle = 'Seed Edit';
      this.saveSeedBtn = 'Edit Seed';
      newLbl = 'Seed Edit';
    }

    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showAddForm = false; this.showList = true; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({
      label: newLbl
    });
    this.initSeedForm(seed);
    this.seedFormLoaded = true;
    this.showList = false;
    this.showAddForm = true;
  }

  initSeedForm(seed: CampaignSeedCustomGrid | null) {
    let dob: Date | null = null;
    if (seed?.DateOfBirth && seed.DateOfBirth.length > 0) dob = new Date(seed.DateOfBirth);

    let ssn7: number | null = null;
    if (seed?.SSN7 && seed.SSN7.length > 0) ssn7 = +seed.SSN7;

    this.seedForm = new FormGroup<CampaignSeedFormGroup>({
      firstName: new FormControl<string>(seed?.FirstName ?? '', { nonNullable: true, validators: [Validators.required]}),
      lastName: new FormControl<string>(seed?.LastName ?? '', { nonNullable: true, validators: [Validators.required]}),
      dateOfBirth: new FormControl<Date|null>(dob, { nonNullable: true, validators: [Validators.required]}),
      address1: new FormControl<string>('', { nonNullable: true, validators: [Validators.required]}),
      address2: new FormControl<string>('', { nonNullable: true }),
      city: new FormControl<string>('', { nonNullable: true, validators: [Validators.required]}),
      state: new FormControl<string|null>(seed?.State ?? null, { nonNullable: true, validators: [Validators.required]}),
      zip: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.minLength(5), Validators.maxLength(10)]}),
      ssn: new FormControl<number|null>(ssn7, { nonNullable: true, validators: [Validators.required, Validators.minLength(9), Validators.maxLength(9)]}),
      loanAmount: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      incFuture: new FormControl<boolean>(true, { nonNullable: true })
    });
  }

  cancelSeedForm() {
    this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);    
  }

  saveSeed() {
    let body = {
      customerGuid: this.selectedPortfolio?.customerGuid ?? this.guidService.emptyGuid(),
      seedGuid: this.selectedSeed?.TargetGUID ?? this.guidService.emptyGuid(),
      firstName: this.seedForm.value.firstName ?? '',
      lastName: this.seedForm.value.lastName ?? '',
      address1: this.seedForm.value.address1 ?? '',
      address2: this.seedForm.value.address2 ?? '',
      city: this.seedForm.value.city ?? '',
      state: this.seedForm.value.state ?? '',
      zip: this.seedForm.value.zip ?? '',
      ssn: (this.seedForm.value.ssn ?? '').toString(),
      dob: this.seedForm.value.dateOfBirth ?? new Date(),
      loanAmount: +(this.seedForm.value.loanAmount ?? 0),
      includeFuture: this.seedForm.value.incFuture ?? false
    };
    let seedSub = this.apiService.post(`campaign/seed`, body)
    .subscribe({
      next: () => {
        this.cancelSeedForm();
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Campaign Seed details successfully saved.'
        });
        this.reloadSeedList();
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save Seed data. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { seedSub.unsubscribe(); }
    });
  }

  reloadSeedList(count: number = 1) {
    if (this.cgSeeds) {
      this.cgSeeds.getDataFromSource();
    }
    else if (count > 10) {
      this.toastService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Unable to refresh Campaign Seed details. Please reload page and try again.'
      }, 'center');      
    }
    else {
      setTimeout(() => {
        this.reloadSeedList(count++);
      }, 100);
    }
  }

  removeBcCommand(label: string | undefined) {
    if (!label) return;
    let item = this.bcItems.find(b => b.label === label);
    if (item) {
      let index = this.bcItems.indexOf(item);
      item.command = undefined;
      this.bcItems.splice(index, 1, item);
    }
  }

}