<div *ngIf="!provider" class="pt-4">
    Please select or create Customer before updating this section.
</div>

<div *ngIf="provider" class="pt-4">
    <form [formGroup]="loanForm" (ngSubmit)="saveLoanApp();">
    <div class="grid">
        <div class="col-6">
            <p3solved-form-text id="txtNLSPortName" placeholder="NLS Portfolio Name"
                formControlName="nlsLoanPortfolioName" [disabled]="true">
            </p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtLoanTemmplPre" placeholder="Loan Template Prefix"
                formControlName="loanTemplatePrefix" [disabled]="true">
            </p3solved-form-text>
        </div>
        <div class="col-12 grid">
            <div class="col-6">
            <p3solved-form-text id="txtLoanNumPre" placeholder="Loan Number Prefix"
                formControlName="loanNumberPrefix" [disabled]="true">
            </p3solved-form-text>
            </div>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtLoanAmt" placeholder="Loan Amount"
                [required]="true" formControlName="loanAmountTemplate"></p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtCampName" placeholder="Campaign Name"
                [required]="true" formControlName="campaignName"></p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtDomain" placeholder="Domain"
                [required]="true" formControlName="domain"></p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtCtrlFldr" placeholder="Customer control(s) Folder"
                [required]="true" formControlName="campaignCustomFolder"></p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtStartUrl" placeholder="Start URL"
                formControlName="startURL"></p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtCampFldr" placeholder="Campaign Folder"
                formControlName="campaignFolder"></p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtAcctDomain" placeholder="My Account Domain"
                formControlName="campaignMyAccountDomain"></p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtInvPgUrl" placeholder="Invitation Page URL"
                formControlName="campaignInvitationPageURL"></p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtWebInvDom" placeholder="Web Invite Domain"
                formControlName="campaignWebInviteDomain"></p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtAcctDefPg" placeholder="My Account Default Page"
                formControlName="campaignMyAccountDefaultPage"></p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtAdminDom" placeholder="Admin Domain"
                formControlName="adminDomain"></p3solved-form-text>
        </div>
        <div class="col-6">
            <p3solved-input-number id="txtLogoutTime" placeholder="My Account Logout Timeout (Seconds)"
                mode="decimal" formControlName="logoutTimeout">
        </p3solved-input-number>
        </div>
        <div class="col-6">
            <p3solved-form-text id="txtMouseFlow" placeholder="Mouse Flow"
                formControlName="campaignMouseFlowTag"></p3solved-form-text>
        </div>
    </div>    
   
    <div class="flex flex-row-reverse">
        <p3solved-button id="btnSaveLoan" buttonType="submit" label="Save Loan App" [disabled]="loanForm.invalid"></p3solved-button>
    </div>
</form>
</div>