import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PageGroup } from 'src/app/models/campaign-pages';
import { PgDataButtonFormGroup, pgDataTextServerValidation } from 'src/app/models/form-groups';
import { PageDataButton, PageDataServerValidation } from 'src/app/models/page-data';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-page-data-button',
  templateUrl: './page-data-button.component.html',
  styleUrls: ['./page-data-button.component.scss']
})
export class PageDataButtonComponent implements OnInit {

  @Input() data: PageDataButton | null | undefined = null;
  @Input() buttonActions: PageDataServerValidation[] = [];
  @Input() pages: PageGroup[] = [];
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<any>();

  buttonForm: FormGroup<PgDataButtonFormGroup> = {} as FormGroup;
  buttonFormLoaded: boolean = false;
  emptyDataMsg: boolean = false;

  buttonActionList: PageDataServerValidation[] = [];

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (!this.data) {
      this.emptyDataMsg = true;
      return;
    }
    this.initForm();
    this.emptyDataMsg = false;
    this.buttonFormLoaded = true;
  }

  initForm() {
    this.buttonForm = this.formBuilder.group<PgDataButtonFormGroup>({
      name: new FormControl<string>(this.data?.alias ?? '', { nonNullable: true }),
      objectId: new FormControl<string>(this.data?.name ?? '', { nonNullable: true, validators: [Validators.required] }),
      version: new FormControl<number | null>(this.data?.versionId ?? null, { nonNullable: true }),
      text: new FormControl<string>(this.data?.text ?? '', { nonNullable: true }),
      destUrl: new FormControl<string>(this.data?.link ?? '', { nonNullable: true }),
      goToPg: new FormControl<number|null>(this.data?.npid ? +this.data.npid : null, { nonNullable: true }),
      css: new FormControl<string>(this.data?.class ?? '', { nonNullable: true }),   
      required: new FormControl<boolean>(this.data?.req == "1", { nonNullable: true }),
      useActions: new FormControl<boolean>(this.data?.ba == "1", { nonNullable: true }),
      autoLogin: new FormControl<boolean>(this.data?.autoLogin == "1", { nonNullable: true }),
      buttonActionDD: new FormControl<number|null>(null, { nonNullable: true }),
      buttonActions: this.formBuilder.array([
        this.formBuilder.group<pgDataTextServerValidation>({
          id: new FormControl<string>('', { nonNullable: true }),
          cId: new FormControl<string>('', { nonNullable: true }),
          valId: new FormControl<string>('', { nonNullable: true }),
          name: new FormControl<string>('', { nonNullable: true }),
          errMsg: new FormControl<string>('', { nonNullable: true }),
        })
      ])
    });  
    
    this.buttonForm.controls.buttonActions.removeAt(0);


    let baIds: number[] = [];
    if (this.data?.bas && this.data.bas.length > 0) {
      this.data.bas.forEach(ba => {
        baIds.push(+ba.vId);
        this.buttonForm.controls.buttonActions.push(
          this.formBuilder.group<pgDataTextServerValidation>({
            id: new FormControl<string>(ba.id ?? '', { nonNullable: true }),
            cId: new FormControl<string>(ba.cId ?? '', { nonNullable: true }),
            valId: new FormControl<string>(ba.vId ?? '', { nonNullable: true }),
            name: new FormControl<string>(ba.vName ?? '', { nonNullable: true }),
            errMsg: new FormControl<string>(ba.em ?? '', { nonNullable: true }),
          })
        );
      });
    }

    this.buttonActionList = this.buttonActions.filter(ba => !baIds.includes(ba.serverValidationID));

  }

  saveButtonForm() {
    if (this.data) {
      this.data.alias = this.buttonForm.value.name ?? this.data.alias;
      this.data.name = this.buttonForm.value.objectId ?? this.data.name;
      this.data.versionId = this.buttonForm.value.version ?? this.data.versionId;
      this.data.text = this.buttonForm.value.text ?? this.data.text;
      this.data.link = this.buttonForm.value.destUrl ?? this.data.link;
      this.data.npid = this.buttonForm.value.goToPg?.toString() ?? this.data.npid;
      this.data.class = this.buttonForm.value.css ?? this.data.class;
      this.data.req = this.buttonForm.value.required ? '1' : '0';
      this.data.autoLogin = this.buttonForm.value.autoLogin ? '1' : '0';
      this.data.ba = this.buttonForm.value.useActions ? '1' : '0';
      this.data.bas = [];
    }

    let idx: number = 0;
    this.buttonForm.controls.buttonActions.controls.forEach(ctrl => {
      idx++;
      this.data?.bas.push({
        id: idx.toString(),
        cId: ctrl.value.cId ?? '',
        vId: ctrl.value.valId ?? '',
        vName: ctrl.value.name ?? '',
        em: ctrl.value.errMsg ?? ''
      });
    });

    let saveObj = {
      element: 'button',
      data: this.data
    };

    this.onSave.emit(saveObj);
  }

  cancelForm() {
    this.onCancel.emit();
  }

  addButtonAction() {
    let bal = this.buttonActionList.find(s => s.serverValidationID == this.buttonForm.value.buttonActionDD);
    if (bal) {
      this.buttonForm.controls.buttonActions.push(
        this.formBuilder.group<pgDataTextServerValidation>({
          id: new FormControl<string>((this.buttonForm.controls.buttonActions.length + 1).toString(), { nonNullable: true }),
          cId: new FormControl<string>(uuidv4(), { nonNullable: true }),
          valId: new FormControl<string>(bal.serverValidationID.toString() ?? '', { nonNullable: true }),
          name: new FormControl<string>(bal.serverValidationName ?? '', { nonNullable: true }),
          errMsg: new FormControl<string>(bal.errorMessage ?? '', { nonNullable: true }),
        })
      );
      let baIdx = this.buttonActionList.indexOf(bal);
      if (baIdx) this.buttonActionList.splice(baIdx, 1);
      this.buttonForm.patchValue({
        buttonActionDD: null
      });
    }
  }

  deleteButtonAction(i: number) {
    if (this.buttonForm && this.buttonForm.value.buttonActions?.length) {
      let ba = this.buttonForm.value.buttonActions[i];
      let mstrBa = this.buttonActions.find(s => s.serverValidationID == +(ba.valId ?? -100));
      if (mstrBa && this.buttonActionList.indexOf(mstrBa) < 0) {
        this.buttonActionList.splice(this.buttonActionList.length, 0, mstrBa);
      }
    }

    this.buttonForm.controls.buttonActions.removeAt(i);
  }

}
