import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortfolioItem } from 'src/app/models/customer-item';
import { AdminReportDashboard } from 'src/app/models/report/report-dashboard';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss']
})
export class ReportPageComponent implements OnInit, OnDestroy {
  
  
  portfolioItem: PortfolioItem | null = null;
  dashboardData: AdminReportDashboard | null = null;
  currDate: Date = new Date();

  subscriptions: Subscription[] = [];
  
  
  constructor(
    private toastService: ToastService,
    private portfolioService: PortfolioService,
    private apiService: ApiService) {      
     }

  ngOnInit(): void {
    this.subscriptions.push(
      this.portfolioService.portfolio$.subscribe(p => {
        this.portfolioItem = p;
        if (p) {
          this.getCustomerDashboard();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getCustomerDashboard() {
    let dashSub = this.apiService.get(`adminreport/dashboard/${this.portfolioItem?.customerGuid}`)
    .subscribe({
      next: ((dashData: AdminReportDashboard) => {
        this.dashboardData = dashData;
      }),
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get Report Dashboard data. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { dashSub.unsubscribe(); }
    });
  }

  notWorkingMsg() {
    this.toastService.underConstruction();
  }
}
